export const ORG_SECRETS_HEADER = 'Organization Secrets';
export const DELETE_CONFIRMATION_MESSAGE = 'Are you sure you want to delete this secret?';
export const ADD_NEW_SECRET_BUTTON_LABEL = 'Add New Secret';

export const SECRET_ADDED_SUCCESS_MESSAGE = 'Secret added';
export const SECRET_DELETED_SUCCESS_MESSAGE = 'Secret removed';

export const CREATE_SECRET_MODAL_TITLE = 'Create Secret';

export const CREATE_SECRET_MODAL_NAME_LABEL = 'Name';
export const CREATE_SECRET_MODAL_SECRET_LABEL = 'Secret';

export const SECRETS_TABLE_COLUMNS_TITLE = {
  NAME: 'Name',
};
