import React, { ReactElement } from 'react';
import { WarningFilled } from '@ant-design/icons';
import { CREATED_BY_LABEL, DATE_LABEL, NAME_LABEL, STATUS_LABEL } from '@constants/pipelines';
import { ICreatedBy } from '@redux/types/commonTypes';
import { DeepsetCloudVersion, IAPIPaginationData, IPipeline } from '@redux/types/types';
import UserAvatar from '@components/common/userAvatar/userAvatar';
import DataTable from '@components/dataTable/DataTable';
import PipelineDeepsetCloudVersionTag from '@components/pipelines/pipelineDeepsetCloudVersionTag/PipelineDeepsetCloudVersionTag';
import PipelineStatusTag from '@components/pipelineStatusTag/PipelineStatusTag';
import styles from './pipelineSelectionTable.module.scss';

type ColumnKey = 'name' | 'created_at' | 'status' | 'created_by';

interface IPipelineSelectionTableProps {
  pipelines: IAPIPaginationData<IPipeline[]>;
  selectedPipelineName: string;
  border?: boolean;
  displayColumns?: ColumnKey[];
  disabledV2Pipelines?: boolean;
  onSelectPipeline: (pipeline: IPipeline) => void;
  getPipelines: (currentPage: number, pageSize: number, searchValue: string) => void;
  rowDisabled?: (pipeline: IPipeline) => boolean;
}

const PipelineSelectionTable = ({
  pipelines,
  selectedPipelineName,
  border = true,
  displayColumns = ['name', 'created_at'],
  disabledV2Pipelines = true,
  onSelectPipeline,
  getPipelines,
  rowDisabled,
}: IPipelineSelectionTableProps) => {
  const { data, total } = pipelines;

  const columns = [
    {
      title: NAME_LABEL,
      dataIndex: 'name',
      key: 'name',
      render: (_: any, row: IPipeline) => (
        <>
          <PipelineDeepsetCloudVersionTag deepsetCloudVersion={row.deepset_cloud_version} />
          {row.name}
        </>
      ),
    },
    {
      title: DATE_LABEL,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: STATUS_LABEL,
      dataIndex: 'indexing',
      key: 'status',
      render: (_: any, row: any) => {
        return <PipelineStatusTag pipeline={row} />;
      },
    },
    {
      title: CREATED_BY_LABEL,
      dataIndex: 'created_by',
      key: 'created_by',
      render: (createdBy: ICreatedBy) => <UserAvatar user={createdBy} />,
    },
  ].filter((column) => displayColumns.includes(column.key as ColumnKey));

  const isRowDisabled = (pipeline: IPipeline) => {
    if (rowDisabled) return rowDisabled(pipeline);
    return pipeline.deepset_cloud_version === DeepsetCloudVersion.V2 && disabledV2Pipelines;
  };

  return (
    <DataTable
      key="pipeline-selection-table"
      data={data}
      total={total}
      columns={columns}
      getData={getPipelines}
      rowSelection={{
        type: 'radio',
        selectedRowKeys: [selectedPipelineName],
        onSelect: onSelectPipeline,
        renderCell: (_: boolean, pipeline: IPipeline, __: number, originNode: ReactElement) => {
          if (isRowDisabled(pipeline)) return <WarningFilled className={styles.warningIcon} />;
          return originNode;
        },
      }}
      rowKey="name"
      searchAvailable={false}
      border={border}
      rowDisabled={(pipeline) => isRowDisabled(pipeline as unknown as IPipeline)}
    />
  );
};

export default PipelineSelectionTable;
