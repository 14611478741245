export const INVITE_USER_NOTIFICATION_SUCCESS = 'Invitation sent to the user.';
export const CHANGE_USER_ROLE_NOTIFICATION_SUCCESS = 'User role updated.';
export const REMOVE_USER_NOTIFICATION_SUCCESS = 'User removed.';
export const DELETE_API_KEY_NOTIFICATION_SUCCESS = 'API key deleted.';
export const ADD_TOKEN_NOTIFICATION_SUCCES =
  "You're now connected to %modelProvider%. You can use models from there.";
export const DELETE_TOKEN_NOTIFICATION_SUCCESS =
  "You're no longer connected to %modelProvider%. Your pipelines using models from this provider will stop working.";
export const ADD_WORKSPACE_NOTIFICATION_SUCCESS = 'New workspace created.';
export const DELETE_WORKSPACE_NOTIFICATION_SUCCESS = '%workspace% workspace deleted.';
