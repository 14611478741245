import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Divider } from 'antd';
import PeopleBlock from '@components/common/PeopleBlock/PeopleBlock';
import TopNavigation from '@components/settings/TopNavigation';
import { ORGANIZATION_DESCRIPTION, ORGANIZATION_LABEL } from '@src/constants/settings-page';
import {
  organizationMessageSelector,
  organizationSelector,
} from '@src/redux/selectors/organizationSelectors';
import { IMessage, IOrganizationData } from '@src/redux/types/types';
import '@styles/components/layout.scss';
import '@styles/components/card.scss';

function OrganizationPage() {
  const { organizationName }: IOrganizationData = useSelector(organizationSelector);
  const message: IMessage = useSelector(organizationMessageSelector);

  return (
    <div className="content-wrapper">
      <TopNavigation />
      {message && message.content && <Alert message={message.content} type={message.type} banner />}
      <div className="content-wrapper_padding">
        <div style={{ marginBottom: '20px' }}>
          <strong>{ORGANIZATION_LABEL}: </strong> {organizationName}
          <div>
            <small>{ORGANIZATION_DESCRIPTION}</small>
          </div>
        </div>
        <Divider />
        <div>
          <PeopleBlock withInvite />
        </div>
      </div>
    </div>
  );
}

export default OrganizationPage;
