import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { ANSWER_LABEL } from '@constants/common';
import TypingEffect from '@components/common/typingEffect/typingEffect';
import MarkdownViewer, { LinkRender } from '@components/MarkdownViewer/MarkdownViewer';
import styles from './formattedResultText.module.scss';
import SearchDocumentReferencePopover from '../searchDocumentReferencePopover/SearchDocumentReferencePopover';

const HIGHLIGHT_MARKER = '%highlight%';
const REFERENCE_MARKER = '%reference%';

interface IFormattedResultReferenceProp {
  id: string;
  startIdx: number;
  endIdx: number;
  content: string;
}

interface IFormattedResultProps {
  text: string;
  ellipses?: boolean;
  highlight?: {
    offset?: {
      start: number;
      end: number;
    };
    text: string;
    testId?: string;
  };
  references?: IFormattedResultReferenceProp[] | null;
  displayReferencesPopover?: boolean;
  withTypingEffect?: boolean;
  onViewReference?: (referenceId?: string) => void;
}

const FormattedResultText = ({
  text,
  ellipses,
  highlight,
  references,
  withTypingEffect = false,
  displayReferencesPopover,
  onViewReference,
}: IFormattedResultProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [formatText, setFormatText] = useState('');

  // References

  const getTextWithAddedReferences = () => {
    if (!references?.length) return text;
    const { processedText: textWithRef } = references.reduce(
      (acc, reference, idx: number) => {
        const { processedText, countAddChar } = acc;
        const { startIdx, endIdx } = reference;

        const startTextIdx = startIdx + countAddChar;
        const endTextIdx = endIdx + countAddChar;
        const refLinkText = `[${idx}](${REFERENCE_MARKER})`;
        const withRefLink =
          processedText.slice(startTextIdx, endTextIdx) +
          refLinkText +
          processedText.slice(endTextIdx);
        const newTotalCount = countAddChar + refLinkText.length;

        if (startTextIdx === 0) return { processedText: withRefLink, countAddChar: newTotalCount };

        return {
          processedText: processedText.slice(0, startTextIdx) + withRefLink,
          countAddChar: newTotalCount,
        };
      },
      { processedText: text, countAddChar: 0 },
    );

    return textWithRef;
  };

  // Highlighting

  const getMarkedHighlightText = () => {
    if (!highlight) return '';
    return `${HIGHLIGHT_MARKER}${highlight.text}${HIGHLIGHT_MARKER}`;
  };

  const getTextWithMarkedHighlight = () => {
    if (!highlight) return text;
    const { start, end } = highlight.offset || {};
    return text.slice(0, start) + getMarkedHighlightText() + text.slice(end, text.length);
  };

  const addLabelToLastHighlight = (container: HTMLDivElement): void => {
    const containerHighlights = container.querySelectorAll(`.${styles.highlight}`);
    const lastHighlight = containerHighlights[containerHighlights.length - 1];
    if (lastHighlight) {
      const span = document.createElement('span');
      span.textContent = ANSWER_LABEL;
      lastHighlight.appendChild(span);
    }
  };

  const highlightText = (container: HTMLDivElement) => {
    let isHighlighting = false;
    let highlightSpan: HTMLSpanElement | null = null;

    const createHighlightSpan = () => {
      const span = document.createElement('span');
      span.className = styles.highlight;
      if (highlight?.testId) span.setAttribute('data-testid', highlight.testId);
      return span;
    };

    const walker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT, null);
    const nodesToProcess = [];

    while (walker.nextNode()) {
      nodesToProcess.push(walker.currentNode);
    }

    nodesToProcess.forEach((node) => {
      let nodeText = node.nodeValue || '';
      const parent = node.parentNode;
      const docFragment = document.createDocumentFragment();
      let startIndex: number;
      let endIndex: number;

      while (nodeText.length > 0) {
        if (!isHighlighting) {
          startIndex = nodeText.indexOf(HIGHLIGHT_MARKER);
          if (startIndex === -1) {
            docFragment.appendChild(document.createTextNode(nodeText));
            break;
          }

          docFragment.appendChild(document.createTextNode(nodeText.substring(0, startIndex)));
          nodeText = nodeText.substring(startIndex + HIGHLIGHT_MARKER.length);
          isHighlighting = true;
          highlightSpan = createHighlightSpan();
        }

        if (isHighlighting) {
          endIndex = nodeText.indexOf(HIGHLIGHT_MARKER);
          if (endIndex === -1) {
            highlightSpan?.appendChild(document.createTextNode(nodeText));
            docFragment.appendChild(highlightSpan!);
            break;
          }

          highlightSpan?.appendChild(document.createTextNode(nodeText.substring(0, endIndex)));
          docFragment.appendChild(highlightSpan!);
          nodeText = nodeText.substring(endIndex + HIGHLIGHT_MARKER.length);
          isHighlighting = false;
        }
      }

      parent?.replaceChild(docFragment, node);
    });

    addLabelToLastHighlight(container);
  };

  // Effects

  useEffect(() => {
    if (highlight) {
      setFormatText(getTextWithMarkedHighlight());
      return;
    }

    if (references) {
      setFormatText(getTextWithAddedReferences());
      return;
    }

    setFormatText(text);
  }, [text]);

  // TOFIX: This destroys navigating between items for extractive pipelines
  useEffectUpdateOnly(() => {
    if (highlight) {
      highlightText(containerRef.current!);
    }
  }, [formatText]);

  // Wrappers

  const withTypingEffectWrapper = (children: ReactNode) => {
    if (!withTypingEffect) return <>{children}</>;
    return <TypingEffect>{children}</TypingEffect>;
  };

  // Renders

  // Avoid re-render, as the parent-child reference will be lost
  // only happens on when running the app locally
  const renderCustomLink = useCallback(
    ({
      href,
      children,
    }: React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >) => {
      if (!children) return null;
      if (href?.includes(REFERENCE_MARKER)) {
        const [refIdx] = children as string[];
        const refIdxAsNumber = +refIdx;
        const linkLabel = `[${refIdxAsNumber + 1}]`;
        const { id, content } = references?.[refIdxAsNumber] || { content: '' };

        return (
          <SearchDocumentReferencePopover
            content={content}
            buttonLabel={linkLabel}
            onViewReference={() => onViewReference && onViewReference(id)}
            displayPopover={displayReferencesPopover}
          />
        );
      }

      return <LinkRender href={href}>{children}</LinkRender>;
    },
    [],
  );

  const renderFormattedText = () => {
    return (
      <div
        className={`${styles.formattedResult} ${ellipses ? styles.withEllipses : ''}`}
        ref={containerRef}
      >
        <MarkdownViewer customComponents={{ a: renderCustomLink }}>{formatText}</MarkdownViewer>
      </div>
    );
  };

  return withTypingEffectWrapper(renderFormattedText());
};

export default FormattedResultText;
