import React, { ReactNode, useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Divider, Input, Modal, Select, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { isArray, isEmpty, isNil, isNumber, isObject, isPlainObject, isString } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import {
  ADD_NEW_BUTTON_LABEL,
  CANCEL_BUTTON_LABEL,
  DELETE_BUTTON_LABEL,
  DONE_BUTTON_LABEL,
  EDIT_BUTTON_LABEL,
  METADATA_MODAL_TITLE,
  SAVE_BUTTON_LABEL,
} from '@constants/common';
import {
  ADD_METADATA_BUTTON_LABEL,
  METADATA_EDIT_VALIDATION_ERROR_MESSAGE,
  METADATA_KEY_HEADER_LABEL,
  METADATA_TYPE_HEADER_LABEL,
  METADATA_VALUE_HEADER_LABEL,
  METADATA_VALUE_TYPE_OPTIONS_LABELS,
} from '@constants/data-page';
import { MetadataValueType } from '@redux/types/types';
import styles from './metadataModal.module.scss';

const { TextArea } = Input;

const PLACEHOLDER_KEY = '%PLACEHOLDER%';

interface IMetadataModalProps {
  data: Record<string, unknown> | null;
  open: boolean;
  nonEditableFields?: string[];
  updating?: boolean;
  onOk: () => void;
  onCancel: () => void;
  onEdit?: ((data: Record<string, unknown>) => void) | null;
}

const MetadataModal = ({
  data,
  open,
  nonEditableFields = [],
  updating = false,
  onOk,
  onCancel,
  onEdit,
}: IMetadataModalProps) => {
  const [formData, setFormData] = useState<
    Record<
      string,
      {
        key: string;
        value: unknown;
        type: MetadataValueType;
      }
    >
  >({});
  const [formValidationErrors, setFormValidationErrors] = useState<
    Record<
      string,
      {
        key?: boolean;
        value?: boolean;
      }
    >
  >({});
  const [isEditing, setIsEditing] = useState(false);

  const cleanData = Object.entries(data || {}).reduce((acc, [key, value]) => {
    if (isNil(value)) return acc;
    return { ...acc, [key]: value };
  }, {} as Record<string, unknown>);
  const isEditable =
    !isEmpty(formData) && Object.keys(formData).some((key) => !nonEditableFields.includes(key));

  const getValueType = (value: unknown) => {
    if (isArray(value)) return MetadataValueType.LIST;
    if (isString(value) && Number.isNaN(Number(value)) && dayjs(value, undefined, true).isValid())
      return MetadataValueType.DATE;
    if (isString(value)) return MetadataValueType.KEYWORD;
    if (isNumber(value)) return MetadataValueType.NUMERICAL;
    return MetadataValueType.KEYWORD;
  };

  const getFormattedFormValue = (
    key: string,
    value: string | number | string[],
    type?: MetadataValueType,
  ): any => {
    if (type === MetadataValueType.NUMERICAL) return Number(value);
    if (!type && value && !Number.isNaN(Number(value))) return Number(value);

    if (type === MetadataValueType.LIST && isArray(value))
      return value.map((v) => getFormattedFormValue(key, v));

    const originalValue = cleanData[key];
    if (isObject(originalValue) && isString(value)) {
      try {
        return JSON.parse(value);
      } catch {}
    }

    return value;
  };

  const initFormData = () => {
    setFormData(
      Object.keys(cleanData).reduce((acc, key) => {
        return {
          ...acc,
          [key]: {
            value: cleanData[key],
            type: getValueType(cleanData[key]),
            key,
          },
        };
      }, {}),
    );
  };

  useEffect(() => {
    if (!isEmpty(cleanData)) {
      initFormData();
    }
  }, [data]);

  useEffectUpdateOnly(() => {
    if (!updating) setIsEditing(false);
  }, [updating]);

  const validatedForm = () => {
    const errors = Object.entries(formData).reduce(
      (acc, [key, { key: newKey, value, type }]) => {
        const fieldErrors = { ...acc };
        const existingErrors = fieldErrors[key] || {};

        const isInvalidList = type === MetadataValueType.LIST && !isArray(value);
        const isInvalidKey = !isString(newKey) || newKey.trim() === '';

        // Check for invalid value input
        if (isInvalidList) {
          fieldErrors[key] = {
            ...existingErrors,
            value: true,
          };
        }

        // Check for invalid key input
        if (isInvalidKey) {
          fieldErrors[key] = {
            ...existingErrors,
            key: true,
          };
        }

        return fieldErrors;
      },
      {} as Record<
        string,
        {
          key?: boolean;
          value?: boolean;
        }
      >,
    );

    setFormValidationErrors(errors);
    return isEmpty(errors);
  };

  const handleSave = () => {
    if (!onEdit) return;
    if (!validatedForm()) return;

    const values = Object.entries(formData).reduce((acc, [key, { value, key: newKey }]) => {
      if (nonEditableFields.includes(key)) return acc;
      if (key.includes(PLACEHOLDER_KEY)) return { ...acc, [newKey]: value };
      if (key !== newKey) return { ...acc, [newKey]: value, [key]: null };
      return {
        ...acc,
        [key]: value,
      };
    }, {});
    // To delete metadata field, we need to send them as null
    const deletedValues = Object.keys(cleanData).reduce((acc, key) => {
      if (!formData[key]) return { ...acc, [key]: null };
      return acc;
    }, {});

    onEdit({ ...values, ...deletedValues });
    setFormValidationErrors({});
  };

  const handleCancelEdit = () => {
    initFormData();
    setIsEditing(false);
    setFormValidationErrors({});
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormValidationErrors({});
    onCancel();
  };

  const handleFormChange = (key: string, field: string, value: string | number | string[]) => {
    // Reset validation errors when form changes, if there are any active for that field
    if (formValidationErrors[key]?.[field as 'value' | 'key']) validatedForm();

    setFormData((prevData) => {
      return {
        ...prevData,
        [key]: {
          ...prevData[key],
          [field]: value,
        },
      };
    });
  };

  const handleFieldTypeChange = (key: string, value: MetadataValueType) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [key]: {
          ...prevData[key],
          type: value,
          value: null,
        },
      };
    });
  };

  const handleDeleteField = (key: string) => {
    setFormData((prevData) => {
      const { [key]: ignore, ...rest } = prevData;
      return rest;
    });
  };

  const handleAddNewField = () => {
    const placeholderKey = `${PLACEHOLDER_KEY}_${uuidv4()}`;
    setFormData((prevData) => {
      return {
        ...prevData,
        [placeholderKey]: {
          key: '',
          value: null,
          type: MetadataValueType.KEYWORD,
        },
      };
    });
  };

  const getInputType = (type: MetadataValueType) => {
    const inputTypeMap = {
      [MetadataValueType.KEYWORD]: 'text',
      [MetadataValueType.NUMERICAL]: 'number',
      [MetadataValueType.DATE]: 'date',
      [MetadataValueType.LIST]: 'text',
    };
    return inputTypeMap[type];
  };

  const getDisplayValue = (value: unknown) => {
    if (isObject(value)) return JSON.stringify(value);
    return value as string | number;
  };

  const isInputEditable = (key: string) => !nonEditableFields.includes(key);

  const isFieldTypeInputEditable = (key: string) => {
    if (!isInputEditable(key)) return false;
    if (key === formData[key].key) return false;
    return true;
  };

  // Renders

  const renderValueInput = (
    key: string,
    inputData: { value: unknown; type: MetadataValueType },
  ) => {
    const { value, type } = inputData;

    if (type === MetadataValueType.LIST) {
      return (
        <Select
          mode="tags"
          disabled={!isInputEditable(key) || updating}
          tokenSeparators={[',']}
          value={value as string[]}
          status={formValidationErrors[key]?.value ? 'error' : ''}
          onChange={(v) => handleFormChange(key, 'value', getFormattedFormValue(key, v, type))}
        />
      );
    }

    if (type === MetadataValueType.DATE) {
      return (
        <DatePicker
          value={value ? dayjs(value as string) : null}
          disabled={!isInputEditable(key) || updating}
          status={formValidationErrors[key]?.value ? 'error' : ''}
          onChange={(date) => handleFormChange(key, 'value', date.toISOString())}
        />
      );
    }

    if (type === MetadataValueType.KEYWORD) {
      return (
        <TextArea
          disabled={!isInputEditable(key) || updating}
          value={getDisplayValue(value)}
          status={formValidationErrors[key]?.value ? 'error' : ''}
          onChange={(e) =>
            handleFormChange(key, 'value', getFormattedFormValue(key, e.target.value, type))
          }
          autoSize
        />
      );
    }

    return (
      <Input
        disabled={!isInputEditable(key) || updating}
        value={getDisplayValue(value)}
        type={getInputType(type)}
        status={formValidationErrors[key]?.value ? 'error' : ''}
        onChange={(e) =>
          handleFormChange(key, 'value', getFormattedFormValue(key, e.target.value, type))
        }
      />
    );
  };

  const renderFormView = () => {
    const metaTypeSelectOptions = [
      {
        value: MetadataValueType.KEYWORD,
        label: METADATA_VALUE_TYPE_OPTIONS_LABELS[MetadataValueType.KEYWORD],
      },
      {
        value: MetadataValueType.NUMERICAL,
        label: METADATA_VALUE_TYPE_OPTIONS_LABELS[MetadataValueType.NUMERICAL],
      },
      {
        value: MetadataValueType.DATE,
        label: METADATA_VALUE_TYPE_OPTIONS_LABELS[MetadataValueType.DATE],
      },
      {
        value: MetadataValueType.LIST,
        label: METADATA_VALUE_TYPE_OPTIONS_LABELS[MetadataValueType.LIST],
      },
    ];

    return (
      <div className={styles.formContainer}>
        <div className={styles.formHeader}>
          <div className={styles.formHeader_label}>{METADATA_TYPE_HEADER_LABEL}</div>
          <div className={styles.formHeader_label}>{METADATA_KEY_HEADER_LABEL}</div>
          <div className={styles.formHeader_label}>{METADATA_VALUE_HEADER_LABEL}</div>
        </div>
        {Object.keys(formData).map((key) => (
          <div className={styles.inputsRow} key={key}>
            <Select
              disabled={!isFieldTypeInputEditable(key) || updating}
              options={metaTypeSelectOptions}
              value={formData[key].type}
              onChange={(value) => handleFieldTypeChange(key, value)}
            />
            <Input
              disabled={!isInputEditable(key) || updating}
              value={formData[key].key}
              status={formValidationErrors[key]?.key ? 'error' : ''}
              onChange={(e) => handleFormChange(key, 'key', e.target.value)}
            />
            <div className={styles.inputsRow_withAction}>
              {renderValueInput(key, formData[key])}
              <Tooltip title={DELETE_BUTTON_LABEL} placement="top">
                <Button
                  type="text"
                  disabled={updating}
                  icon={<DeleteOutlined />}
                  className={!isInputEditable(key) ? styles.hidden : ''}
                  onClick={() => handleDeleteField(key)}
                />
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderDisplayValue = (value: unknown): string | ReactNode => {
    if (isArray(value)) return value.map((v) => <div key={v}>{renderDisplayValue(v)}</div>);

    if (isObject(value)) {
      return (
        <div className={styles.nestedFieldWrapper}>
          {Object.entries(value).map(([key, v]) => (
            <div className={styles.nestedField} key={key}>
              <div className={styles.nestedField_label}>{key}</div>
              <div className={styles.nestedField_value}>{renderDisplayValue(v)}</div>
            </div>
          ))}
          <Divider className={styles.divider} />
        </div>
      );
    }

    return getDisplayValue(value);
  };

  const renderDisplayField = (key: string, value: unknown) => {
    const isNestedValue =
      isPlainObject(value) || (isArray(value) && value.some((v) => isObject(v)));

    return (
      <div
        key={key}
        className={`${styles.field} ${isNestedValue ? styles.nestedFieldContainer : ''}`}
      >
        <div className={styles.field_label}>{key}</div>
        <div className={styles.field_value}>{renderDisplayValue(value)}</div>
      </div>
    );
  };

  const renderDisplayView = () => {
    return (
      <div className={styles.fieldsContainer}>
        {Object.entries(cleanData).map(([key, value]) => renderDisplayField(key, value))}
      </div>
    );
  };

  const renderfooter = () => {
    if (isEditing)
      return (
        <div className={styles.footerWrapper}>
          <Button
            disabled={updating}
            type="link"
            onClick={handleAddNewField}
            icon={<PlusOutlined />}
          >
            {ADD_NEW_BUTTON_LABEL}
          </Button>
          <div className={styles.footerWrapper_buttonsContainer}>
            <Button disabled={updating} onClick={handleCancelEdit}>
              {CANCEL_BUTTON_LABEL}
            </Button>
            <Button disabled={updating} loading={updating} type="primary" onClick={handleSave}>
              {SAVE_BUTTON_LABEL}
            </Button>
          </div>
        </div>
      );

    return (
      <div className={styles.footerWrapper}>
        <div className={styles.footerWrapper_buttonsContainer}>
          {!!onEdit && (
            <Button
              icon={isEditable ? <EditOutlined /> : <PlusOutlined />}
              onClick={() => {
                setIsEditing(true);
                if (!isEditable) handleAddNewField();
              }}
            >
              {isEditable ? EDIT_BUTTON_LABEL : ADD_METADATA_BUTTON_LABEL}
            </Button>
          )}
          <Button type="primary" onClick={onOk}>
            {DONE_BUTTON_LABEL}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={METADATA_MODAL_TITLE}
      className={styles.modal}
      width="50%"
      open={open}
      onCancel={handleCancel}
      footer={renderfooter()}
    >
      {!isEmpty(formValidationErrors) && isEditing && (
        <Alert
          type="error"
          showIcon
          banner
          closable
          message={METADATA_EDIT_VALIDATION_ERROR_MESSAGE}
        />
      )}
      <div className={styles.body}>{isEditing ? renderFormView() : renderDisplayView()}</div>
    </Modal>
  );
};

export default MetadataModal;
