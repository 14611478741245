import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import {
  getResolveCustomerAwsMarketplaceApi,
  sendSubscriptionDataAwsMarketplaceApi,
} from '@api/external/aws-marketplace';
import {
  AWS_MARKETPLACE_REGISTER_DESCRIPTION,
  AWS_MARKETPLACE_REGISTER_THANKS_MESSAGE,
  AWS_MARKETPLACE_REGISTER_TITLE,
  AWS_MARKETPLACE_SUCCESS_DESCRIPTION,
  AWS_MARKETPLACE_SUCCESS_THANKS_MESSAGE,
  AWS_MARKETPLACE_SUCCESS_TITLE,
  AWS_SUBMISSION_FAILED,
  AWS_TOKEN_NEEED_ERROR,
  AWS_TOKEN_VALIDATION_ERROR,
  AwsMarketplaceLocation,
  BUSINESS_NEEDS_FIELDS,
  IAwsMarketplaceSubscriptionData,
  IBusinessNeedsValues,
  IPersonalInfoValues,
  REGISTER_BUTTON_LABEL,
} from '@constants/aws-marketplace';
import { NEXT_BUTTON_LABEL } from '@constants/common';
import { HTTPStatusCode } from '@constants/enum/common';
import { NotificationDuration } from '@redux/types/types';
import AwsMarketplaceHeader from './components/AwsMarketplaceHeader';
import AwsMarketplaceBusinessNeeds from './components/steps/businessNeeds/BusinessNeeds';
import AwsMarketplacePersonalInfo from './components/steps/personalInfo/PersonalInfo';
import AwsMarketplaceRegistrationSuccessful from './components/steps/registrationSuccessful/RegistrationSuccessful';
import styles from './awsMarketplace.module.scss';

enum OfferTypes {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

const AwsMarketplacePage = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(2);
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<IAwsMarketplaceSubscriptionData>({
    name: '',
    lastName: '',
    email: '',
    companyName: '',
    systemDescription: '',
    timeline: '',
    professionalService: '',
    dataType: '',
    offerType: '',
    awsMarketplaceToken: '',
    location: '',
  });

  const [messageApi, contextHolder] = message.useMessage();

  const [searchParams] = useSearchParams();
  const offerType = searchParams.get('offer');
  const location = searchParams.get('location');
  const awsMarketplaceToken = searchParams.get('x-amzn-marketplace-token');

  useEffect(() => {
    if (offerType === OfferTypes.PRIVATE) {
      setTotalSteps(1);
    }
    setSubscriptionData({
      ...subscriptionData,
      offerType: offerType ?? '',
      location: location ?? AwsMarketplaceLocation.US,
      awsMarketplaceToken: awsMarketplaceToken ?? '',
    });
  }, [offerType, location, awsMarketplaceToken]);

  const showMissingTokenError = (error: string) => {
    messageApi.open({
      type: 'error',
      content: error,
      duration: NotificationDuration.long,
    });
  };

  const getResolveCustomerAwsMarketplace = async () => {
    if (awsMarketplaceToken) {
      const response = await getResolveCustomerAwsMarketplaceApi(
        awsMarketplaceToken,
        location || AwsMarketplaceLocation.US,
      );
      if (response?.statusCode === HTTPStatusCode.OK) {
        return true;
      }
      messageApi.open({
        type: 'error',
        content: response?.body || AWS_TOKEN_VALIDATION_ERROR,
        duration: NotificationDuration.long,
      });
    } else showMissingTokenError(AWS_TOKEN_NEEED_ERROR);
    return false;
  };

  const onNextButtonPressPersonalInfo = async (formValues: IPersonalInfoValues) => {
    setSubscriptionData({ ...subscriptionData, ...formValues });
    if (!awsMarketplaceToken) {
      showMissingTokenError(AWS_TOKEN_NEEED_ERROR);
      return;
    }
    setLoading(true);

    if (offerType === OfferTypes.PRIVATE) {
      const awsTokenIsValid = await getResolveCustomerAwsMarketplace();
      if (awsTokenIsValid) {
        const response = await sendSubscriptionDataAwsMarketplaceApi(subscriptionData);
        if (response?.status === HTTPStatusCode.OK) setCurrentStep(currentStep + 2);
        else showMissingTokenError(AWS_SUBMISSION_FAILED);
      } else setCurrentStep(1);
    } else setCurrentStep(currentStep + 1);
    setLoading(false);
  };

  const onNextButtonPressBusinessNeeds = async (formValues: IBusinessNeedsValues) => {
    if (!awsMarketplaceToken) {
      showMissingTokenError(AWS_TOKEN_NEEED_ERROR);
      return;
    }
    setLoading(true);

    const payloadBussinessNeeds = {
      systemDescription: `${BUSINESS_NEEDS_FIELDS.systemDescription.label} ${formValues.systemDescription}`,
      timeline: `${BUSINESS_NEEDS_FIELDS.timeline.label} ${formValues.timeline}`,
      professionalService: `${BUSINESS_NEEDS_FIELDS.professionalService.label} ${formValues.professionalService}`,
      dataType: `${BUSINESS_NEEDS_FIELDS.dataType.label} ${formValues.dataType}`,
    };

    const awsTokenIsValid = await getResolveCustomerAwsMarketplace();
    if (awsTokenIsValid) {
      const response = await sendSubscriptionDataAwsMarketplaceApi({
        ...subscriptionData,
        ...payloadBussinessNeeds,
      });
      if (response?.status === HTTPStatusCode.OK) setCurrentStep(currentStep + 1);
      else showMissingTokenError(AWS_SUBMISSION_FAILED);
    } else setCurrentStep(1);

    setLoading(false);
    setSubscriptionData({ ...subscriptionData, ...formValues });
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <AwsMarketplaceHeader />
      <div className={styles.content}>
        <div className={styles.content_registerBanner}>
          <h2>
            {currentStep === 3 ? AWS_MARKETPLACE_SUCCESS_TITLE : AWS_MARKETPLACE_REGISTER_TITLE}
          </h2>
          <div className={styles.content_registerBanner_description}>
            {currentStep === 3
              ? AWS_MARKETPLACE_SUCCESS_THANKS_MESSAGE
              : AWS_MARKETPLACE_REGISTER_THANKS_MESSAGE}
          </div>
          <div className={styles.content_registerBanner_description}>
            {currentStep === 3
              ? AWS_MARKETPLACE_SUCCESS_DESCRIPTION
              : AWS_MARKETPLACE_REGISTER_DESCRIPTION}
          </div>
        </div>
        <div className={styles.content_registrationForm}>
          {currentStep === 1 && (
            <AwsMarketplacePersonalInfo
              currentStep={currentStep}
              totalSteps={totalSteps}
              subscriptionData={subscriptionData}
              onNextButtonPress={(formValues: IPersonalInfoValues) =>
                onNextButtonPressPersonalInfo(formValues)
              }
              nextButtonLabel={
                offerType === OfferTypes.PRIVATE ? REGISTER_BUTTON_LABEL : NEXT_BUTTON_LABEL
              }
              loading={loading}
            />
          )}
          {currentStep === 2 && (
            <AwsMarketplaceBusinessNeeds
              currentStep={currentStep}
              totalSteps={totalSteps}
              subscriptionData={subscriptionData}
              onBackButtonPress={(formValues: IBusinessNeedsValues) => {
                setSubscriptionData({ ...subscriptionData, ...formValues });
                setCurrentStep(currentStep - 1);
              }}
              onNextButtonPress={(formValues: IBusinessNeedsValues) =>
                onNextButtonPressBusinessNeeds(formValues)
              }
              nextButtonLabel={REGISTER_BUTTON_LABEL}
              loading={loading}
            />
          )}
          {currentStep === 3 && (
            <AwsMarketplaceRegistrationSuccessful login={() => navigate(`/login`)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AwsMarketplacePage;
