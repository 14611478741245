import React from 'react';
import Icon from '@ant-design/icons';
import BookmarkFilledSVG from '@assets/icons/BookmarkFilled.svg?react';
import BookmarkOutlinedSVG from '@assets/icons/BookmarkOutlined.svg?react';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import { StatusCodes } from '@constants/enum/common';
import {
  FEEDBACK_SAVE_FOR_LATER_BUTTON_TOOLTIP,
  FEEDBACK_SAVED_FOR_LATER_BUTTON_LABEL,
} from '@constants/pipeline-feedback';
import SearchActionButton from '@components/search/molecules/searchActionButton/SearchActionButton';

interface ISearchResultBookmarkProps {
  resultId: string;
  queryId: string;
  pipelineId: string;
  withLabel?: boolean;
  isExternal?: boolean;
}

const SearchResultBookmark = ({
  resultId,
  queryId,
  pipelineId,
  withLabel = true,
  isExternal,
}: ISearchResultBookmarkProps) => {
  const { bookmarkSearchResult, providedPipelineFeedbackStatus, providedFeedbackByResultId } =
    usePipelineFeedback();

  const isProvidingFeedback = providedPipelineFeedbackStatus === StatusCodes.IN_PROGRESS;
  const providedFeedbackForResult = providedFeedbackByResultId[resultId];
  const { bookmarked } = providedFeedbackForResult ?? {};

  const handleBookmarking = (isBookmarked: boolean) => {
    bookmarkSearchResult({
      resultId,
      queryId,
      pipelineId,
      bookmarked: isBookmarked,
      isExternal,
    });
  };

  // Renders

  return (
    <SearchActionButton
      label={!!bookmarked && withLabel ? FEEDBACK_SAVED_FOR_LATER_BUTTON_LABEL : ''}
      tooltipTitle={FEEDBACK_SAVE_FOR_LATER_BUTTON_TOOLTIP}
      disabled={isProvidingFeedback}
      active={!!bookmarked}
      onClick={() => handleBookmarking(!bookmarked)}
      icon={<Icon component={BookmarkOutlinedSVG} />}
      activeIcon={<Icon component={BookmarkFilledSVG} />}
      testId="saveFroLater"
    />
  );
};

export default SearchResultBookmark;
