import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { UserRoles } from '@src/constants/enum/common';
import { userSelector } from '@src/redux/selectors/userSelectors';
import { IUserData } from '@src/redux/types/types';

interface PrivateRouteProps {
  component: React.ComponentType;
  roles: Array<UserRoles>;
}

const PrivateRoute = ({ component, roles, ...args }: PrivateRouteProps) => {
  const { role }: IUserData = useSelector(userSelector);
  const { user } = useAuth0();

  if (!user || !roles.includes(role)) return <Navigate to="/login" />;

  const AuthenticatedComponent = withAuthenticationRequired(component, args);

  return <AuthenticatedComponent />;
};

export default PrivateRoute;
