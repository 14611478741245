import { setTwoToneColor } from '@ant-design/icons';
import { ThemeConfig } from 'antd';
import { TableToken } from 'antd/es/table/style';

const BRAND_COLOR = '#3a61c2';
const WHITE_COLOR = '#ffffff';
const BLACK_COLOR = '#091023';

setTwoToneColor(BRAND_COLOR);

const defaultTheme: ThemeConfig = {
  hashed: false, // Activate if we introduce micro-frontends with diferent version of antd
  components: {
    Layout: {
      siderBg: '#f5f5f6',
      bodyBg: WHITE_COLOR,
    },
    Table: {
      tableHeaderCellSplitColor: 'none',
      tableHeaderTextColor: 'rgba(9,16,35,.58)', // BLACK_COLOR + 58% opacity
    } as TableToken,
    Tooltip: {
      colorBgSpotlight: WHITE_COLOR,
      colorTextLightSolid: BLACK_COLOR,
    },
    Select: {
      colorTextPlaceholder: 'rgba(9,16,35,.58)', // BLACK_COLOR + 58% opacity
    },
    Input: {
      colorTextPlaceholder: 'rgba(9,16,35,.58)', // BLACK_COLOR + 58% opacity
    },
    Menu: {
      itemHoverBg: '#f0f7ff',
    },
  },
  token: {
    wireframe: true,
    fontSize: 14,
    borderRadius: 4,
    colorPrimary: BRAND_COLOR,
    colorWhite: WHITE_COLOR,
    colorTextBase: BLACK_COLOR,
    colorTextSecondary: 'rgba(9,16,35,.58)', // BLACK_COLOR + 58% opacity
    colorBgBase: WHITE_COLOR,
    colorBgMask: 'rgba(9,16,35,.45)', // BLACK_COLOR + 45% opacity
    colorLink: BRAND_COLOR,
    colorSuccess: '#13B391',
    colorWarning: '#CC8300',
    colorError: '#D40B29',
    colorInfo: BRAND_COLOR,
    colorLinkHover: '#5f82cf',
    fontFamily:
      "system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji','Segoe UI Emoji', 'Segoe UI Symbol'",
  },
};

export default defaultTheme;
