import React from 'react';
import styles from './threeDots.module.scss';

const ThreeDots = () => {
  return (
    <div className={styles.threeDots}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  );
};

export default ThreeDots;
