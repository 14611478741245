import React from 'react';
import { Button } from 'antd';
import { VIEW_DOCUMENT_LINK_LABEL } from '@constants/search';
import styles from './searchDocumentLink.module.scss';

interface ISearchDocumentLink {
  file?: {
    name: string;
    id: string;
  };
  onViewDocument:
    | ((params: { fileName: string; fileId: string; isDocumentPreview: boolean }) => void)
    | null;
}

const SearchDocumentLink = ({ file, onViewDocument }: ISearchDocumentLink) => {
  if (!onViewDocument) return null;

  const { name = '', id: fileId = '' } = file || {};

  return (
    <Button
      className={styles.viewDocumentLink}
      type="link"
      data-testid="searchResult_document_link"
      onClick={() =>
        onViewDocument({
          fileName: name,
          fileId,
          isDocumentPreview: true,
        })
      }
    >
      {VIEW_DOCUMENT_LINK_LABEL}
    </Button>
  );
};

export default SearchDocumentLink;
