import { createLink, interpolateString } from '@utils/string';
import { EditorYamlTabsKeys } from '@redux/types/types';
import { DOCS_URL } from './constant';

export const SAVE_BUTTON_LABEL = 'Save';
export const RESET_BUTTON_LABEL = 'Reset';
export const DEPLOY_BUTTON_LABEL = 'Deploy';
export const DEPLOYING_BUTTON_LABEL = 'Deploying';
export const UNDEPLOY_BUTTON_LABEL = 'Undeploy';
export const DRAFT_LABEL = 'Draft';
export const RESET_TOOLTIP_MESSAGE = 'Brings back the last saved version.';
export const CODE_EDITOR_TAB = 'Code Editor';
export const VISUALIZER_TAB = 'Pipeline Preview';
export const DIFF_SWITCH_LABEL = 'Show diff';
export const STUDIO_ROUTE_NAME = 'Pipeline Studio';
export const ATTEMPT_READ_ONLY_EDIT = {
  productionPipelines: interpolateString(
    'Editing production pipelines is not allowed. To edit this pipeline, change its service level to "development". After saving your changes, %link%.',
    {
      link: createLink(
        `${DOCS_URL}reference/update_pipeline_api_v1_workspaces__workspace_name__pipelines__pipeline_name__patch`,
        'promote it back to "production"',
      ),
    },
  ),
};
export const PREVENT_PAGE_EXIT_MESSAGE = `You'll lose your changes if you leave without saving. Do you want to stay on the page and save your changes?`;
export const DATE_DRIVEN_METAFIELD_MISSING_MESSAGE_DESCRIPTION = `The date_meta_field name must be updated to reflect the metadata in your files or your files must be updated to reflect the date_meta_field name.`;
export const OPENAI_KEY_MISSING_MESSAGE_DESCRIPTION = `The OpenAI API key is missing or invalid. Go to %connections% and paste the key there.`;

export const EDITOR_YAML_TABS_LABELS = [
  { key: EditorYamlTabsKeys.INDEXING_YAML, label: 'Indexing Pipeline' },
  { key: EditorYamlTabsKeys.QUERY_YAML, label: 'Query Pipeline' },
];
