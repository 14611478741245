import { NUMBER_OF_USERS_PER_PAGE } from '@constants/common';
import { http } from './base-http';

/* Get all users in the organization */
export interface IAllUsersData {
  limit?: number;
  after?: string;
  page_number?: number;
}

export const getAllUsersApi = (params?: IAllUsersData) => {
  const paramsMap = {
    limit: params?.limit || NUMBER_OF_USERS_PER_PAGE,
    ...(params?.after && { after: params?.after }),
    ...(params?.page_number && { page_number: params?.page_number }),
  };

  return http.get(`/api/v1/users`, { params: paramsMap });
};

/* Get details of current user */
export const getUserDetailsApi = () => {
  return http.get(`/api/v1/me`);
};

export const getUserApi = (userId: string) => {
  return http.get(`api/v1/users/${userId}`);
};

export const deleteUserApi = (userId: string) => {
  return http.delete(`api/v1/users/${userId}`);
};
