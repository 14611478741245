import React from 'react';
import { useSelector } from 'react-redux';
import { ThunderboltFilled } from '@ant-design/icons';
import { Button } from 'antd';
import {
  BY_USER_LABEL,
  UPGRADE_POPOVER_TEMPLATE_CONTENT,
  USE_TEMPLATE_BUTTON_LABEL,
  VIEW_DETAILS_BUTTON_LABEL,
} from '@constants/pipeline-templates';
import { userSelector } from '@redux/selectors/userSelectors';
import { IPipelineTemplate } from '@redux/types/types';
import DcLogoAvatar from '@components/common/dcLogoAvatar/DcLogoAvatar';
import UpgradePopover from '@components/upgradePopover/UpgradePopover';
import styles from './templateCard.module.scss';

const DESCRIPTION_CHARACTER_LIMIT = 250;

interface ITemplatesCardProps {
  template: IPipelineTemplate;
  onViewDetails?: () => void;
  onUseTemplate?: () => void;
}

const TemplatesCard = ({ template, onViewDetails, onUseTemplate }: ITemplatesCardProps) => {
  const {
    author,
    name,
    description,
    available_to_all_organization_types: availableToAll,
  } = template;

  const { isLimitedUser } = useSelector(userSelector);

  const parseDescription = () => {
    if (!description) return '';
    if (description.length < DESCRIPTION_CHARACTER_LIMIT) return description;
    return `${description.slice(0, DESCRIPTION_CHARACTER_LIMIT)}...`;
  };

  return (
    <div className={styles.card} data-testid={template.pipeline_name}>
      <div className={styles.card_body}>
        <h6>
          {isLimitedUser && !availableToAll && <ThunderboltFilled className={styles.upgradeLogo} />}
          {name}
        </h6>
        <div className={styles.card_body_description}>{parseDescription()}</div>
      </div>
      <div className={styles.card_createdBy}>
        <DcLogoAvatar size="small" />
        <span>
          {BY_USER_LABEL} {author}
        </span>
      </div>
      <div className={styles.card_actionButtons}>
        <Button
          size="small"
          block
          onClick={onViewDetails}
          disabled={isLimitedUser && !availableToAll}
        >
          {VIEW_DETAILS_BUTTON_LABEL}
        </Button>
        {isLimitedUser && !availableToAll ? (
          <UpgradePopover description={UPGRADE_POPOVER_TEMPLATE_CONTENT}>
            <Button size="small" type="primary" block>
              {USE_TEMPLATE_BUTTON_LABEL}
            </Button>
          </UpgradePopover>
        ) : (
          <Button size="small" type="primary" block onClick={onUseTemplate}>
            {USE_TEMPLATE_BUTTON_LABEL}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TemplatesCard;
