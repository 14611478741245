import React, { useEffect, useState } from 'react';
import { Tour, TourProps } from 'antd';
import { TourStepProps } from 'antd/lib';
import { SKIP_TOUR_BUTTON_LABEL } from '@constants/common';
import styles from './newFeatureTour.module.scss';

const RENDER_DELAY = 500;

interface INewFeatureTour {
  openTour: boolean;
  steps: TourStepProps[];
  onClose: () => void;
  mask?: boolean;
  placement?: TourProps['placement'];
}

const NewFeatureTour = (props: INewFeatureTour) => {
  const { openTour, steps, placement, mask, onClose } = props;

  const [isReady, setIsReady] = useState(false);

  // Workaround, wait at least 1 render cycle before displaying
  // the tour to avoid displaying on an element
  // that hasn't finished its transition
  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, RENDER_DELAY);
  }, []);

  const getSteps = () => {
    return steps.map((step, index) => {
      if (steps.length <= 2 || index === steps.length - 1)
        return { ...step, prevButtonProps: { style: { display: 'none' } } };
      return {
        ...step,
        prevButtonProps: {
          onClick: onClose,
          children: SKIP_TOUR_BUTTON_LABEL,
          type: 'link',
        },
      };
    });
  };

  const renderIndicators = (current: number, total: number) => {
    return (
      <div className={styles.indicatorsContainer}>
        {current + 1} of {total}
      </div>
    );
  };

  if (!isReady) return null;

  return (
    <Tour
      open={openTour}
      steps={getSteps()}
      mask={mask}
      placement={placement}
      indicatorsRender={renderIndicators}
      onClose={onClose}
    />
  );
};

export default NewFeatureTour;
