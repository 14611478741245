import React from 'react';
import { theme } from 'antd';

const { useToken } = theme;

const AnimatedDeployingPipelineSVG = () => {
  const { token } = useToken();
  return (
    <svg
      id="eXH8VvtSnlq1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 300 300"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <path
        id="eXH8VvtSnlq2"
        d="M45.7638,208.914c-6.6618,4.966-8.5498,13.703-8.5498,13.703s8.9128.686,15.5746-4.28s8.5499-13.703,8.5499-13.703-8.9129-.686-15.5747,4.28Z"
        transform="translate(5 0)"
        fill="#f1f1f1"
      />
      <path
        id="eXH8VvtSnlq3"
        d="M48.5388,211.245c-3.2017,7.668-11.2204,11.619-11.2204,11.619s-2.8276-8.48.3742-16.148c3.2017-7.667,11.2203-11.618,11.2203-11.618s2.8276,8.48-.3741,16.147Z"
        transform="matrix(.866025-.5 0.5 0.866025-98.714098 49.555976)"
        fill="#f1f1f1"
      />
      <path
        id="eXH8VvtSnlq4"
        d="M240.204,189.08c-.708-4.768-.895-9.599-.558-14.408l.558-7.958c-3.073,6.115-2.378,14.163,0,22.366v0Z"
        transform="translate(0 22)"
        fill="#cacaca"
      />
      <path
        id="eXH8VvtSnlq5"
        d="M242.6,190.411c-.34-2.845-.43-5.715-.268-8.576l.268-4.737c-1.478,3.64-1.144,8.43,0,13.313Z"
        transform="translate(0 20)"
        fill="#cacaca"
        fillOpacity="0.7"
      />
      <path
        d="M253.252,196.269v2.663c-.001.211-.086.414-.235.563-.15.15-.352.234-.564.235h-1.331c-.035,0-.07.007-.102.02-.033.014-.062.033-.087.058s-.044.054-.057.087c-.014.032-.021.067-.02.102v21.832c-.002.212-.086.414-.236.564-.149.149-.352.234-.563.235h-16.242c-.211,0-.414-.084-.564-.234s-.234-.353-.234-.565v-21.832c-.001-.071-.029-.139-.079-.188-.049-.05-.117-.078-.188-.079h-1.597c-.212,0-.415-.084-.565-.234-.149-.15-.234-.353-.234-.564v-2.663c0-.105.021-.209.061-.306s.099-.185.173-.259.162-.133.259-.173.201-.061.306-.061h21.3c.212.001.415.085.564.235.15.15.234.352.235.564Z"
        fill="#f2f2f2"
      />
      <path d="M250.879,200.289h-17.838v.799h17.838v-.799Z" fill="#e6e6e6" />
      <path
        d="M250.856,212.043c-5.97,2.47-12.004,2.498-18.106,0v-5.627c5.874-1.973,12.232-1.973,18.106,0v5.627Z"
        fill="#e6e6e6"
      />
      <g id="eXH8VvtSnlq9" transform="translate(.000001 0)">
        <path
          d="M94.1097,185.252c-.1738,1.257-.1913,2.531-.0521,3.793l1.2485,17.93c.1172,1.69.2326,3.375.3912,5.061.2996,3.271.7471,6.517,1.3025,9.758.0125.46.2071.896.541,1.213s.7799.488,1.24.477c5.4962,1.162,11.1742,1.116,16.7832.799c8.559-.478,30.455-1.381,31.793-3.119s.558-4.549-1.186-5.954-30.64-4.831-30.64-4.831c.282-2.238,1.134-4.345,1.942-6.469c1.451-3.768,2.807-7.66,2.833-11.696s-1.495-8.28-4.801-10.592c-2.72-1.898-6.222-2.25-9.536-2.172-2.411.069-6.5779-.509-8.7804.434-1.7429.768-2.7533,3.661-3.0789,5.368Z"
          transform="translate(0-.315369)"
          fill="#fbbebe"
        />
        <path
          d="M101.388,153.368c-.423.443-.751.968-.964,1.542-3.512,7.915-5.5862,16.392-6.1259,25.033.0095.422-.0876.839-.2823,1.213-.192.248-.4057.479-.6387.69-.203.241-.3567.519-.4522.82-.0955.3-.1309.616-.1043.93.0266.313.1148.619.2595.899.1447.279.3431.528.5839.731.0394.033.08.065.1215.096.7168-1.349,2.4328-1.79,3.9581-1.907c7.3034-.578,14.4364,2.82,21.7614,2.563-.517-1.785-1.26-3.501-1.682-5.309-1.863-8.024,2.785-16.761-.065-24.49-.569-1.546-1.52-3.067-3.019-3.745-.621-.25-1.27-.422-1.933-.511-1.851-.335-5.536-1.764-7.325-1.191-.661.213-.921.834-1.468,1.21-.832.531-1.936.726-2.625,1.426Z"
          fill={token.colorPrimary}
        />
      </g>
      <g id="eXH8VvtSnlq12" transform="translate(0 0.000001)">
        <path
          d="M202.811,179.884c-2.202-.943-6.368-.365-8.78-.434-3.314-.078-6.816.274-9.536,2.172-3.306,2.312-4.827,6.556-4.801,10.592.027,4.036,1.382,7.928,2.833,11.696.808,2.124,1.66,4.231,1.942,6.469c0,0-28.896,3.426-30.64,4.831s-2.524,4.216-1.186,5.954s43.08,3.482,48.576,2.32c.46.011.906-.16,1.24-.477s.529-.753.541-1.213c.556-3.241,1.003-6.487,1.303-9.758.158-1.686.274-3.371.391-5.061l1.248-17.93c.14-1.262.122-2.536-.052-3.793-.325-1.707-1.336-4.6-3.079-5.368Z"
          fill="#fbbebe"
        />
        <path
          d="M195.988,151.942c-.548-.376-.808-.997-1.469-1.21-1.789-.573-5.474.856-7.325,1.191-.663.089-1.312.261-1.933.511-1.499.678-2.45,2.199-3.019,3.745-2.85,7.729,1.798,16.466-.065,24.49-.422,1.808-1.165,3.524-1.682,5.309c7.325.257,14.458-3.141,21.761-2.563c1.526.117,3.242.558,3.958,1.907.042-.031.083-.063.122-.096.241-.203.439-.452.584-.731.145-.28.233-.586.259-.899.027-.314-.008-.63-.104-.93-.095-.301-.249-.579-.452-.82-.233-.211-.447-.442-.639-.69-.194-.374-.292-.791-.282-1.213-.54-8.641-2.614-17.118-6.126-25.033-.213-.574-.541-1.099-.964-1.542-.689-.7-1.793-.895-2.624-1.426Z"
          fill={token.colorPrimary}
        />
      </g>
      <path
        d="M202.759,163.921c-.829-4.992-1.685-10.079-4.005-14.576-1.525-2.945-3.945-5.752-7.208-6.334-.642-.057-1.274-.204-1.877-.435-.956-.46-13.802-7.794-15.844-9.071-1.753-1.097-4.521-3.127-5.916-3.127-1.404-.031-6.787,1.222-27.798-.42c0,0-5.772,2.275-10.239,5.033-.067-.044-21.807,11.63-22.624,11.599-1.547-.065-2.984.925-3.871,2.172-.886,1.248-1.302,2.791-1.738,4.284c4.749,10.575,9.093,21.162,13.842,31.737.222.398.34.846.343,1.302-.095.474-.297.919-.59,1.303-2.329,3.741-2.255,8.446-2.001,12.842.255,4.397.57,9.041-1.395,12.982-.535,1.082-1.23,2.072-1.738,3.154-1.19,2.451-1.621,10.288-.925,12.921l87.108,2.495c-1.873-5.148,6.476-67.861,6.476-67.861Z"
        transform="translate(0-1.532704)"
        fill={token.colorPrimary}
      />
      <path
        d="M143.72,224.34c3.597,0,6.513-2.916,6.513-6.512c0-3.597-2.916-6.513-6.513-6.513-3.596,0-6.512,2.916-6.512,6.513c0,3.596,2.916,6.512,6.512,6.512Z"
        fill="#fbbebe"
      />
      <path
        d="M158.14,224.34c3.597,0,6.512-2.916,6.512-6.512c0-3.597-2.915-6.513-6.512-6.513-3.596,0-6.512,2.916-6.512,6.513c0,3.596,2.916,6.512,6.512,6.512Z"
        fill="#fbbebe"
      />
      <path
        d="M198.339,222.502h-89.468c-1.045-.001-2.046-.417-2.785-1.155-.739-.739-1.154-1.74-1.155-2.785v-54.277c.001-1.045.416-2.046,1.155-2.785s1.74-1.154,2.785-1.155h89.468c1.044.001,2.046.416,2.784,1.155.739.739,1.154,1.74,1.156,2.785v54.277c-.002,1.045-.417,2.046-1.156,2.785-.738.738-1.74,1.154-2.784,1.155Z"
        fill="#3f3d56"
      />
      <path
        d="M153.388,204.03c1.439,0,2.606-1.167,2.606-2.607c0-1.439-1.167-2.606-2.606-2.606-1.44,0-2.607,1.167-2.607,2.606c0,1.44,1.167,2.607,2.607,2.607Z"
        fill="#fff"
      />
      <path
        d="M300,221.554h-300v9.219h17.7447v2.143h264.5173v-2.143h17.738v-9.219Z"
        fill="#f1f1f1"
      />
      <g id="eXH8VvtSnlq21" transform="translate(.000001 0)">
        <path
          d="M150.866,126.003c13.326,0,24.129-10.803,24.129-24.128c0-13.3261-10.803-24.1287-24.129-24.1287-13.325,0-24.128,10.8026-24.128,24.1287c0,13.325,10.803,24.128,24.128,24.128Z"
          fill="#feb8b8"
        />
        <path
          d="M173.402,81.0587c-3.607-5.8578-9.254-10.1742-15.853-12.1183l-2.962,2.1616v-2.8067c-1.585-.2426-3.189-.336-4.791-.2791l-2.556,2.3123v-2.1202c-3.783.4702-7.429,1.7192-10.706,3.6684s-6.114,4.5563-8.333,7.6568c-5.55,8.0007-6.487,19.1314-1.028,27.1965c1.498-4.605,3.317-8.9261,4.815-13.5307c1.178.1606,2.372.1664,3.551.0173l1.823-4.2539.509,4.0738c5.651-.4921,14.032-1.5731,19.389-2.5634l-.521-3.1254l3.117,2.597c1.641-.3778,2.615-.7206,2.535-.9825c3.984,6.423,8.86,10.5252,12.843,16.9478c1.513-9.0564,3.255-16.5868-1.832-24.8513Z"
          fill="#2f2e41"
        />
        <path
          d="M128.679,110.868c-3.877,0-7.02-3.399-7.02-7.59v-3.7955c0-4.1919,3.143-7.59,7.02-7.59v18.9755v0Z"
          fill={token.colorPrimary}
        />
        <path
          d="M173.484,91.8925c3.877,0,7.02,3.3982,7.02,7.59v3.7955c0,4.191-3.143,7.59-7.02,7.59v-18.9755Z"
          fill={token.colorPrimary}
        />
        <path
          d="M177.162,100.079h-1.113c0-15.2727-11.492-27.698-25.617-27.698s-25.617,12.4253-25.617,27.698h-1.114c0-15.9368,11.991-28.9022,26.731-28.9022c14.739,0,26.73,12.9654,26.73,28.9022Z"
          fill={token.colorPrimary}
        />
      </g>
    </svg>
  );
};

export default AnimatedDeployingPipelineSVG;
