/* eslint-disable no-await-in-loop */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RcFile } from 'antd/lib/upload';
import { normalizeErrorMessage } from '@utils/error';
import { HTTPStatusCode } from '@constants/enum/common';
import { finishUploading } from '@redux/actions/uploadActions';
import {
  isUploadingCompletedSelector,
  isUploadingStartedSelector,
  uploadDataSelector,
} from '@redux/selectors/uploadSelectors';
import { ExtendedUploadFile, FileUploadStatus, IUploadData } from '@redux/types/types';

const UPLOAD_BATCH = 20;

type FailedUpload = Pick<IUploadData, 'uploadFn' | 'afterUploadFn'> & { uid: string };

export const useUploadData = () => {
  const dispatch = useDispatch();

  const uploadData = useSelector<{}, IUploadData[]>(uploadDataSelector);
  const [uploadFiles, setUploadFiles] = useState<ExtendedUploadFile[]>([]);
  const [failedUploads, setFailedUploads] = useState<Record<string, FailedUpload>>({});
  const [failedFilesToRetry, setFailedFilesToRetry] = useState<ExtendedUploadFile[]>([]);
  const [isRetrying, setIsRetrying] = useState(false);
  const [singleFileName, setSingleFileName] = useState('');
  // This is only to store query_set_id so we can use in after upload (for now)
  const responseID = useRef('');
  const isUploadingCompleted = useSelector(isUploadingCompletedSelector);
  const isUploadingStarted = useSelector(isUploadingStartedSelector);

  useEffect(() => {
    if (!uploadData?.length || !isUploadingStarted) return;

    setUploadFiles(
      uploadData.reduce((acc: ExtendedUploadFile[], { files }) => [...acc, ...files], []),
    );
  }, [uploadData.length, !!isUploadingStarted]);

  useEffect(() => {
    responseID.current = '';
    if (!isRetrying)
      setFailedFilesToRetry(uploadFiles.filter((f) => f.uploadStatus === FileUploadStatus.error));

    if (uploadFiles.length === 1) {
      const [{ name }] = uploadFiles;
      setSingleFileName(name);
    }
  }, [uploadFiles]);

  const updateFileStatus = (
    fileId: string,
    status: FileUploadStatus,
    errorMessage?: string,
    errorCode?: number,
  ) => {
    const tmpFiles: ExtendedUploadFile[] = uploadFiles;
    const fileToUpdate = tmpFiles.find((file: ExtendedUploadFile) => file.uid === fileId);
    if (!fileToUpdate) return;
    fileToUpdate.uploadStatus = status;
    if (errorMessage) {
      fileToUpdate.errorMessage = errorMessage;
      fileToUpdate.errorCode = errorCode;
    }
    setUploadFiles([...tmpFiles]);
  };

  const uploadFile = (
    file: ExtendedUploadFile,
    uploadFn: IUploadData['uploadFn'],
    afterUploadFn: IUploadData['afterUploadFn'],
  ): Promise<void> => {
    const formUploadData = new FormData();
    formUploadData.append('file_name', file.name);
    formUploadData.append('file', file as RcFile);
    const config = {
      'Content-Type': 'multipart/form-data',
    };

    updateFileStatus(file.uid, FileUploadStatus.uploading);

    return uploadFn(formUploadData, config)
      .then((response: any) => {
        // This is only to store query_set_id so we can use in after upload (for now)
        if (response?.data?.query_set_id) responseID.current = response.data.query_set_id;
        updateFileStatus(file.uid, FileUploadStatus.success);
      })
      .catch((error) => {
        responseID.current = '';
        updateFileStatus(
          file.uid,
          FileUploadStatus.error,
          normalizeErrorMessage(error),
          error.response?.status || HTTPStatusCode.INTERNAL_SERVER_ERROR,
        );
        setFailedUploads((prevFailedUploads) => ({
          ...prevFailedUploads,
          [file.uid]: {
            uid: file.uid,
            uploadFn,
            afterUploadFn,
          },
        }));
      });
  };

  const uploadFilesInBatch = async (
    fileList: ExtendedUploadFile[],
    uploadFn: IUploadData['uploadFn'],
    afterUploadFn: IUploadData['afterUploadFn'],
  ) => {
    let position = 0;

    while (position < fileList.length) {
      const batch = fileList.slice(position, position + UPLOAD_BATCH);
      await Promise.all(
        batch.map((f: ExtendedUploadFile) => uploadFile(f, uploadFn, afterUploadFn)),
      );
      position += UPLOAD_BATCH;
    }
  };

  const uploadFilesData = async () => {
    await Promise.all(
      uploadData.map(async ({ files, uploadFn, afterUploadFn }) => {
        const unUplodedFiles = files.filter((f) => !f.uploadStatus);

        if (!unUplodedFiles?.length) return;

        await uploadFilesInBatch(unUplodedFiles, uploadFn, afterUploadFn);
        afterUploadFn(responseID.current);
      }),
    );
    dispatch(finishUploading);
  };

  const retrySingleFile = async (fileId: string) => {
    if (!failedUploads[fileId]) return;
    const { uploadFn, afterUploadFn } = failedUploads[fileId];
    const file = uploadFiles.find((f) => f.uid === fileId);

    await uploadFile(file!, uploadFn, afterUploadFn);
    afterUploadFn();
  };

  const retryFailedFiles = async () => {
    setIsRetrying(true);

    await Promise.all(
      failedFilesToRetry.map(async (file) => {
        const { uploadFn, afterUploadFn } = failedUploads[file.uid];
        await uploadFile(file!, uploadFn, afterUploadFn);
      }),
    );
    setIsRetrying(false);
  };

  return {
    uploadFiles,
    setUploadFiles,
    failedUploads,
    failedFilesToRetry,
    setFailedUploads,
    singleFileName,
    isRetrying,
    uploadFilesData,
    retrySingleFile,
    retryFailedFiles,
    isUploadingCompleted,
    isUploadingStarted,
  };
};
