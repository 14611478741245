import { validate as uuidValidate } from 'uuid';
import { FilterType, SelectedFilterItem } from '@constants/data-table';

export const normalizeODataStringsField = (field: string): string => {
  if (/\s/.test(field)) return `'${field}'`;
  return field;
};

const parseODataValue = (value: unknown) => {
  if (typeof value === 'string' && !uuidValidate(value)) {
    return `'${value}'`;
  }
  return value;
};

export const getODataEQFilterFrom = (field: string, value: unknown): string => {
  return `${normalizeODataStringsField(field)} eq ${parseODataValue(value)}`;
};

export const getODataRangeFilterFrom = (field: string, value: number[]): string =>
  `${normalizeODataStringsField(field)} ge ${parseODataValue(
    value[0],
  )} and ${normalizeODataStringsField(field)} le ${parseODataValue(value[1])}`;

export const getODataDateFilterFrom = (field: string, value: unknown): string =>
  `${normalizeODataStringsField(field)} ge '${value}'`;

export const getODataSearchFilterFrom = (field: string, value: string): string =>
  `contains(tolower(${normalizeODataStringsField(field)}), tolower('${value}'))`;

export const getODataMultiSelectFilterFrom = (field: string, value: string[]): string =>
  value.length === 1
    ? `${normalizeODataStringsField(field)} eq ${parseODataValue(value[0])}`
    : `${normalizeODataStringsField(field)} in (${value.map(parseODataValue).join(', ')})`;

const parseFilterByType = (field: string, filter: SelectedFilterItem[]) => {
  const [{ type }] = filter;
  const parseByTypeMap = {
    [FilterType.TOGGLE]: () => {
      if (!filter[0].value) return '';
      return getODataEQFilterFrom(field, filter[0].value);
    },
    [FilterType.SELECT]: () => getODataEQFilterFrom(field, filter[0].value),
    [FilterType.RANGE]: () => getODataRangeFilterFrom(field, filter[0].value as number[]),
    [FilterType.DATE]: () => getODataDateFilterFrom(field, filter[0].value),
    [FilterType.MULTI_SELECT]: () =>
      getODataMultiSelectFilterFrom(field, filter.map(({ value }) => value) as string[]),
  };

  return parseByTypeMap[type]();
};

export const getODataFilterFrom = <T extends Record<string, SelectedFilterItem[]>>(
  filters: T,
): string => {
  const filterKeys = Object.keys(filters);

  if (!filterKeys.length) return '';

  return filterKeys.reduce((parsedFilters, filterKey) => {
    const isFirstFilter = !parsedFilters;
    const filter = filters[filterKey];

    if (!filter.length) return parsedFilters;

    const parsedFilter = parseFilterByType(filterKey, filter);

    if (isFirstFilter) return parsedFilter;

    return `${parsedFilters} and ${parsedFilter}`;
  }, '');
};

export const getODataConjunctionFilterFrom = (
  ...filters: (string | null | undefined)[]
): string => {
  const validFilters = filters.filter((filter) => filter && filter.trim() !== '');
  if (!validFilters?.length) return '';
  return validFilters.join(' and ');
};
