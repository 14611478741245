import React from 'react';
import { Form, Input } from 'antd';
import {
  BUSINESS_NEEDS_FIELDS,
  BUSINESS_NEEDS_STEP_SUBTITLE,
  BUSINESS_NEEDS_STEP_TITLE,
  IAwsMarketplaceSubscriptionData,
  IBusinessNeedsValues,
} from '@constants/aws-marketplace';
import StepCard from '@components/stepCard/StepCard';
import styles from './businessNeeds.module.scss';

interface IAwsMarketplaceBusinessNeedsProps {
  currentStep: number;
  totalSteps: number;
  subscriptionData: IAwsMarketplaceSubscriptionData;
  onBackButtonPress: (formValues: IBusinessNeedsValues) => void;
  onNextButtonPress: (formValues: IBusinessNeedsValues) => void;
  nextButtonLabel?: string;
  loading?: boolean;
}

const AwsMarketplaceBusinessNeeds = (props: IAwsMarketplaceBusinessNeedsProps) => {
  const {
    totalSteps,
    currentStep,
    subscriptionData,
    onBackButtonPress,
    onNextButtonPress,
    nextButtonLabel,
    loading,
  } = props;

  const [form] = Form.useForm();

  const initialValues: IBusinessNeedsValues = {
    systemDescription: subscriptionData.systemDescription,
    timeline: subscriptionData.timeline,
    professionalService: subscriptionData.professionalService,
    dataType: subscriptionData.dataType,
  };

  return (
    <StepCard
      title={BUSINESS_NEEDS_STEP_TITLE}
      subtitle={BUSINESS_NEEDS_STEP_SUBTITLE}
      currentStep={currentStep}
      totalSteps={totalSteps}
      onNextButtonPress={() => onNextButtonPress(form.getFieldsValue())}
      onBackButtonPress={() => onBackButtonPress(form.getFieldsValue())}
      isNextButtonLoading={loading}
      nextButtonLabel={nextButtonLabel}
    >
      <Form form={form} name="awsRegisterBusinessNeedsForm" initialValues={initialValues}>
        <div className={styles.formContainer}>
          <div className={styles.formContainer_inputField}>
            <div>{BUSINESS_NEEDS_FIELDS.systemDescription.label}</div>
            <Form.Item name="systemDescription">
              <Input placeholder={BUSINESS_NEEDS_FIELDS.systemDescription.placeholder} />
            </Form.Item>
          </div>
          <div className={styles.formContainer_inputField}>
            <div>{BUSINESS_NEEDS_FIELDS.timeline.label}</div>
            <Form.Item name="timeline">
              <Input placeholder={BUSINESS_NEEDS_FIELDS.timeline.placeholder} />
            </Form.Item>
          </div>
          <div className={styles.formContainer_inputField}>
            <div>{BUSINESS_NEEDS_FIELDS.professionalService.label}</div>
            <Form.Item name="professionalService">
              <Input placeholder={BUSINESS_NEEDS_FIELDS.professionalService.placeholder} />
            </Form.Item>
          </div>
          <div className={styles.formContainer_inputField}>
            <div>{BUSINESS_NEEDS_FIELDS.dataType.label}</div>
            <Form.Item name="dataType">
              <Input placeholder={BUSINESS_NEEDS_FIELDS.dataType.placeholder} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </StepCard>
  );
};

export default AwsMarketplaceBusinessNeeds;
