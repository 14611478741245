import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_REQUEST_HISTORY, GET_SEARCH_COUNT, GET_WORKSPACE_STATS } from '@redux/types/types';
import {
  getRequestsHistoryApi,
  getSearchCountPerDayApi,
  getWorkspaceStatisticApi,
} from '@src/api/dashboard';

export const getSearchCount = createAsyncThunk(GET_SEARCH_COUNT, async () => {
  const response = await getSearchCountPerDayApi();
  return response.data;
});

export const getWorkSpaceStatistic = createAsyncThunk(GET_WORKSPACE_STATS, async () => {
  const response = await getWorkspaceStatisticApi();
  return response.data;
});

export const getRequestHistory = createAsyncThunk(GET_REQUEST_HISTORY, async () => {
  const response = await getRequestsHistoryApi();
  return response.data;
});
