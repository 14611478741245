import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const dashboardStoreSelector = (state: RootState) => state.dashboardStore;

export const dashboardWorkspaceStatsSelector = createSelector(
  dashboardStoreSelector,
  (dashboardStore) => dashboardStore.workspaceStats,
);

export const dashboardSearchCountSelector = createSelector(
  dashboardStoreSelector,
  (dashboardStore) => dashboardStore.searchCount,
);

export const dashboardRequestHistorySelector = createSelector(
  dashboardStoreSelector,
  (dashboardStore) => dashboardStore.requestHistory,
);

export const dashboardLoadingRequestHistorySelector = createSelector(
  dashboardStoreSelector,
  (dashboardStore) => dashboardStore.loadingRequestHistory,
);
