import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import { auth } from '@utils/auth';
import { API_URL, baseHttpStaticText } from '@constants/constant';
import { CustomHTTPHeaders } from '@constants/http';
import { logout } from '@redux/actions/userActions';
import { store } from '@redux/store';

const http = axios.create({
  baseURL: API_URL,
  headers: {
    [CustomHTTPHeaders.X_CLIENT_SOURCE]: 'deepset-cloud-ui',
  },
});

const errorMsg = baseHttpStaticText.sessionExpMsg;

http.interceptors.request.use(
  async (config) => {
    const localConfig = config;
    if (!config.headers || !config.headers.Authorization) {
      const token = await auth.getAccessTokenSilently({});
      if (token) {
        localConfig.headers.Authorization = `Bearer ${token}`;
      }
    }

    if (localConfig.url) localConfig.url = encodeURI(localConfig.url);

    return localConfig;
  },
  (error) => Promise.reject(error),
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (
      _get(error, 'response.status') === 401 ||
      _get(error, 'response.data.errors[0]') === baseHttpStaticText.compareErrMsg ||
      _get(error, 'response.data.errors[0]') === baseHttpStaticText.notAuth
    ) {
      setTimeout(() => {
        message.error(errorMsg);
      }, 1000);
      setTimeout(() => {
        message.destroy();
      }, 3000);
      return store.dispatch(logout() as any);
    }

    // Some authorization (401) errors appear as network errors and do not have a response, probably due to CORS
    // https://github.com/axios/axios/issues/4921
    // if (error instanceof AxiosError && error.message === 'Network Error') {
    //   return store.dispatch(logout() as any);
    // }

    // Handle error messsages for blob response
    if (
      error.request?.responseType === 'blob' &&
      error.response?.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          error.response!.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(error));
        };
        reader.onerror = () => reject(error);
        reader.readAsText(error.response!.data as Blob);
      });
    }

    return Promise.reject(error);
  },
);

export { http };
