import { useCallback, useEffect, useState } from 'react';

type Value<T> = T | null;

const useReadLocalStorage = <T>(key: string): Value<T> => {
  const [storedValue, setStoredValue] = useState<Value<T>>(null);

  const deserializer = useCallback<(value: string) => T>((value) => {
    if (value === 'undefined') {
      return undefined as unknown as T;
    }
    return JSON.parse(value);
  }, []);

  const readValue = useCallback((): Value<T> => {
    try {
      const raw = window.localStorage.getItem(key);
      return raw ? deserializer(raw) : null;
    } catch (error) {
      return null;
    }
  }, [key, deserializer]);

  useEffect(() => {
    setStoredValue(readValue());
  }, [key]);

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue],
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  return storedValue;
};

export default useReadLocalStorage;
