import React, { useState } from 'react';
import { MenuProps } from 'antd';
import { StatusCodes } from '@constants/enum/common';
import {
  IPipelineQueryPromptTemplatesParams,
  ISearchResultAnswer,
  ISearchResultDocument,
} from '@redux/types/types';
import PromptExplorerResult from './PromptExplorerResult';

interface IConversationalResultContainerProps {
  isExternal?: boolean;
  searchResult: ISearchResultAnswer;
  documents?: ISearchResultDocument[];
  documentsGroupedByFileId?: Record<string, ISearchResultDocument[]>;
  searchStatus: StatusCodes;
  pipelineId: string;
  pipelineName: string;
  promptTemplates?: IPipelineQueryPromptTemplatesParams;
  moreOptionsDropdownMenuItems?: MenuProps['items'];
  displayFeedbackOptions?: boolean;
  displayFileOptions?: boolean;
  displayFileSources?: boolean;
  displayMoreOptions?: boolean;
  displayTypingEffect?: boolean;
  displayReferencesPopover?: boolean;
  styleCustomization?: {
    answerContainer?: string;
  };
  sourcesDefaultExpanded?: boolean;
  queryId: string;
  query: string;
  errorMessage?: string;
  onRepeatQueryOptionClick?: (query: string) => void;
  onSaveQueryPromptTemplateOptionClick?: (prompt: string) => void;
  onMoreOptionsDropdownMenuItemClick: (info: { key: string }) => void;
}

const ConversationalResultContainer = (props: IConversationalResultContainerProps) => {
  const {
    isExternal,
    searchResult,
    documents,
    queryId,
    pipelineId,
    pipelineName,
    documentsGroupedByFileId,
    searchStatus,
    promptTemplates,
    moreOptionsDropdownMenuItems,
    displayFeedbackOptions = true,
    displayFileOptions = true,
    displayFileSources = true,
    displayMoreOptions = true,
    displayTypingEffect = true,
    displayReferencesPopover = true,
    styleCustomization,
    sourcesDefaultExpanded,
    query,
    errorMessage,
    onRepeatQueryOptionClick,
    onSaveQueryPromptTemplateOptionClick,
    onMoreOptionsDropdownMenuItemClick,
  } = props;
  const [sourcesCollapsed, setSourcesCollapsed] = useState(sourcesDefaultExpanded);

  const searching = searchStatus === StatusCodes.IN_PROGRESS;

  return (
    <PromptExplorerResult
      result={searchResult}
      queryId={queryId}
      pipelineId={pipelineId}
      pipelineName={pipelineName}
      promptTemplates={promptTemplates}
      query={query}
      searching={searching}
      displayFeedbackOptions={displayFeedbackOptions}
      displayFileSources={displayFileSources}
      displayFileOptions={displayFileOptions}
      displayMoreOptions={displayMoreOptions}
      displayTypingEffect={displayTypingEffect}
      displayReferencesPopover={displayReferencesPopover}
      isExternal={isExternal}
      documents={documents}
      documentsGroupedByFileId={documentsGroupedByFileId}
      moreOptionsDropdownMenuItems={moreOptionsDropdownMenuItems}
      onMoreOptionsDropdownMenuItemClick={onMoreOptionsDropdownMenuItemClick}
      sourcesCollapsed={sourcesCollapsed}
      setSourcesCollapsed={setSourcesCollapsed}
      onRepeatQueryOptionClick={onRepeatQueryOptionClick}
      onSaveQueryPromptTemplateOptionClick={onSaveQueryPromptTemplateOptionClick}
      errorMessage={errorMessage}
      styleCustomization={styleCustomization}
    />
  );
};

export default ConversationalResultContainer;
