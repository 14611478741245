import React from 'react';
import {
  AreaChartOutlined,
  BarChartOutlined,
  LineChartOutlined,
  PieChartTwoTone,
} from '@ant-design/icons';
import { ChartExportType, DateRangeType, ObservabilityChartType } from '@redux/types/types';
import { DOCS_URL } from './constant';

export const QUERIES_LABEL = 'Queries';
export const SELECT_PIPELINE_PLACEHOLDER = 'Select Pipeline';
export const EXPORT_TOOLTIP = 'Export chart';
export const EXPAND_TOOLTIP = 'Expand view';
export const COLLAPSE_TOOLTIP = 'Collapse view';
export const REFERECES_LABEL = 'References';
export const RANK_LABEL = 'Rank';
export const DATA_TABLE_TOOLTIP = 'Data table';

export const GROUNDEDNESS_SCORE_DATA_TABLE_LABEL = 'Groundedness score table';
export const GROUNDEDNESS_SCORE_LABEL = 'Groundedness Score';
export const GROUNDEDNESS_SCORE_TOOLTIP =
  'Indicates how well generated answers are grounded in the source documents. A higher score is better.';
export const DOCUMENTS_REFERENCED_DATA_TABLE_LABEL = 'Documents referenced table';
export const DOCUMENTS_REFERENCED_LABEL = 'Documents Referenced';
export const DOCUMENTS_REFERENCED_TOOLTIP = 'Shows documents ranked by reference frequency.';

export const SCORE_STAT_TAGS = {
  VERY_GOOD: {
    label: 'Very Good',
    status: 'success',
  },
  GOOD: {
    label: 'Good',
    status: 'success',
  },
  FAIR: {
    label: 'Fair',
    status: 'warning',
  },
  POOR: {
    label: 'Poor',
    status: 'error',
  },
  VERY_POOR: {
    label: 'Very Poor',
    status: 'error',
  },
  NOT_AVAILABLE: {
    label: 'Score not available',
    status: 'default',
  },
};

export const DESCRIPTION_TITLE = 'Welcome to the Groundedness Observability';
export const DESCRIPTION_SUBTITLE =
  'Select a RAG pipeline from the select menu to observe the performance and detect hallucinations to help improve your pipelines.';
export const DESCRIPTION_OPTIONS = [
  {
    title: 'Groundedness Statistics',
    description:
      "The groundedness score tells you if your RAG pipeline's responses align with your data.",
    icon: <AreaChartOutlined />,
  },
  {
    title: 'Reference Analysis',
    description: 'Analyze references to optimize the number of documents you feed into the prompt.',
    icon: <PieChartTwoTone />,
  },
];

export const EMPTY_GROUNDEDNESS_CHART = {
  title: 'This is where the groundedness chart will appear',
  description:
    "This pipeline wasn't used so the score couldn't be calculated. Run some queries to get your score.",
  icon: <AreaChartOutlined />,
};

export const EMPTY_DOCUMENTS_REFERENCES_CHART = {
  title: 'This is where the referenced documents information will appear.',
  description:
    "The pipeline wasn't used so there's no data available. Run a few queries to see the documents referenced in the answers.",
  icon: <BarChartOutlined />,
};

export const DATE_RANGE_OPTIONS = [
  {
    label: 'Today',
    value: DateRangeType.TODAY,
  },
  {
    label: '7d',
    value: DateRangeType.SEVEN_DAYS,
  },
  {
    label: '14d',
    value: DateRangeType.FOURTEEN_DAYS,
  },
  {
    label: '30d',
    value: DateRangeType.THIRTY_DAYS,
  },
  {
    label: '60d',
    value: DateRangeType.SIXTY_DAYS,
  },
];

export const GROUNDEDNESS_SCORE_CHART_OPTIONS = [
  {
    label: 'Area chart',
    key: ObservabilityChartType.AREA,
    icon: <AreaChartOutlined />,
  },
  {
    label: 'Line chart',
    key: ObservabilityChartType.LINE,
    icon: <LineChartOutlined />,
  },
  {
    label: 'Bar chart',
    key: ObservabilityChartType.BAR,
    icon: <BarChartOutlined />,
  },
];

export const EXPORT_CHART_OPTIONS = [
  {
    label: 'Export as CSV',
    key: ChartExportType.CSV,
  },
  {
    label: 'Export as PNG',
    key: ChartExportType.PNG,
  },
  {
    label: 'Export as JPEG',
    key: ChartExportType.JPEG,
  },
  {
    label: 'Export as PDF',
    key: ChartExportType.PDF,
  },
];

export const EMPTY_PAGE_SECTION = {
  TITLE: 'Groundedness',
  DESCRIPTION: [
    {
      paragraph:
        'Keep your AI aligned with your data using the Groundedness Dashboard. It helps you track how closely your AI’s responses match your data, and shows the sources used, so you can optimize the documents you feed into the model. Use the Dashboard to ensure your app is both reliable and verifiable—essential for applications where accuracy builds trust.',
    },
    {
      paragraph:
        'Start monitoring now by using your pipeline. For more details, see the %documentationLink%.',
    },
  ],
};
export const DOCS_GROUNDEDNESS_LINK = `${DOCS_URL}docs/use-groundedness-observability`;
