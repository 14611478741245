import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import usePipelineQuery from '@hooks/usePipelineQuery';
import {
  selectMetaDataFilterValues,
  setAppliedMetaDataFilterValues,
} from '@redux/actions/metadataFiltersActions';
import ConversationalSearch from '@components/search/organisms/search/ConversationalSearch';
import styles from './chatPage.module.scss';

const ChatPage = () => {
  const dispatch = useDispatch();
  const { resetChatResults } = usePipelineQuery();
  const { pipelineName } = useParams() as {
    pipelineName: string;
  };

  useEffect(() => {
    resetChatResults();
    dispatch(selectMetaDataFilterValues({}));
    dispatch(setAppliedMetaDataFilterValues({}));
  }, []);

  return (
    <div className={styles.container}>
      <ConversationalSearch pipelineName={pipelineName} displayMetaDataFilters />
    </div>
  );
};

export default ChatPage;
