import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FileTextFilled, RiseOutlined } from '@ant-design/icons';
import { Button, Collapse, Drawer, Tabs, Tooltip } from 'antd';
import { cloneArrayBuffer, parseFileContentToViewer } from '@utils/file';
import { formatOrdinals } from '@utils/math';
import { getFileBlobApi, getFileMetaApi } from '@api/data';
import { getFileBlobApi as getFileBlobExternalApi } from '@api/external/data';
import ArrowUpDownSVG from '@assets/arrow-up-down.svg?react';
import CollapseSVG from '@assets/icons/collapse.svg?react';
import ExpandSVG from '@assets/icons/expand.svg?react';
import { useUserEvent } from '@hooks/useUserEvent';
import {
  FILE_DRAWER_COLLAPSE_TABS,
  REFERENCE_LABEL,
  VIEW_METADATA_BUTTON_LABEL,
} from '@constants/common';
import { UploadFileType } from '@constants/constant';
import { StatusCodes, SupportedViewerLanguage } from '@constants/enum/common';
import { EventControlComponent, EventControlElement, UserEventType } from '@constants/event';
import { PipelineType } from '@constants/pipelines';
import {
  ANSWER_TITLE,
  COLLAPSE_ALL_BUTTON_LABEL,
  DOCUMENT_RANK_HEADER,
  EXPAND_ALL_BUTTON_LABEL,
  REFERENCE_HEADER,
  REFERENCES_HEADER,
  SCROLL_TO_REFERENCE_TITLE,
} from '@constants/search';
import { searchResultSelector } from '@redux/selectors/searchSelectors';
import { sharedPrototypeTokenDataSelector } from '@redux/selectors/sharedPrototypeSelectors';
import {
  FileDrawerCollapseTabOptions,
  IFilePreview,
  IHighlightData,
  IMappedReferencesMetaAnnotation,
  ISearchResult,
  SearchResultMeta,
} from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import HighlightContent from '@components/highlightContent/HighlightContent';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import MetadataModal from '@components/metadataModal/MetadataModal';
import useReferences from '@components/search/hooks/useReferences';
import ResultContainer from '@components/search/organisms/result/ResultContainer';
import styles from './referenceDrawer.module.scss';

const PDFHighlighter = React.lazy(() => import('@components/pdf/PDFHighligther'));
const CodeViewer = React.lazy(() => import('@components/codeViewer/CodeViewer'));

const { Panel } = Collapse;

interface IReferenceDrawerProps {
  resultExt?: ISearchResult | null;
  isExternal?: boolean;
}

const ReferenceDrawer = (props: IReferenceDrawerProps) => {
  // Only to support shared prototype
  const { resultExt, isExternal } = props;
  const [isMetaModalVisible, setIsMetaModalVisible] = useState(false);

  const { trackUserEvent } = useUserEvent();
  const resultInt = useSelector(searchResultSelector);
  const {
    activeReference,
    referenceDrawerVisible,
    collapseActiveKeys,
    setCollapseActiveKeys,
    getSearchResultReferences,
    currentActiveCollapseTab,
    setCurrentActiveCollapseTab,
    findActiveReferenceAnswer,
    resetReferenceDrawer,
  } = useReferences();
  const sharedPrototypeTokenData = useSelector(sharedPrototypeTokenDataSelector);
  const [selectedFileID, setSelectedFileID] = useState('');
  const [loadingFileContent, setLoadingFileContent] = useState<boolean>(false);
  const [filePreviewById, setFilePreviewById] = useState<Record<string, IFilePreview>>({});
  const [selectedMeta, setSelectedMeta] = useState<SearchResultMeta | null>(null);
  const [fileMetaById, setFileMetaById] = useState<Record<string, SearchResultMeta>>({});
  const highlightContentRefs = useRef<React.ElementRef<typeof HighlightContent>[]>([]);

  // TODO: Add a hook for files
  const getFileContentById = async (fileId: string) => {
    try {
      setLoadingFileContent(true);

      const fileBlobApiCall = isExternal ? getFileBlobExternalApi : getFileBlobApi;
      // TODO: Support getting meta for external files
      const fileMetaApiCall = getFileMetaApi;
      let workspace: string | undefined;

      if (isExternal) {
        const { workspaceName } = sharedPrototypeTokenData || {};
        workspace = workspaceName;
      }

      const { data } = await fileBlobApiCall(fileId, workspace);
      const { data: metaData } = !isExternal
        ? await fileMetaApiCall(fileId, workspace)
        : { data: {} };
      const parsedFileContent = await parseFileContentToViewer(data);

      setFilePreviewById({ ...filePreviewById, [fileId]: parsedFileContent });
      setFileMetaById({ ...fileMetaById, [fileId]: { ...metaData, file_id: fileId } });
      setLoadingFileContent(false);
    } catch {
      setLoadingFileContent(false);
    }
  };

  useEffect(() => {
    if (activeReference?.referenceId) setCollapseActiveKeys([activeReference.referenceId]);
  }, [activeReference, activeReference?.referenceId]);

  useEffect(() => {
    if (
      currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE &&
      !filePreviewById[selectedFileID]
    )
      getFileContentById(selectedFileID);
  }, [currentActiveCollapseTab, selectedFileID, collapseActiveKeys.length]);

  const searchResult = resultExt || resultInt;

  const resultReferences = getSearchResultReferences(searchResult);

  // Functions

  const showScrollToReferenceButton = (fileName: string) => {
    return fileName?.includes(`.${UploadFileType.txt}`);
  };

  const closeDrawer = () => {
    resetReferenceDrawer();
  };

  const onCloseMetaModal = () => {
    setIsMetaModalVisible(false);
  };

  const onExpandAllRefs = () => {
    setCollapseActiveKeys(resultReferences?.map((ref, idx) => `${ref.result_id}_${idx}`) ?? []);
  };

  const onCollapseAllRefs = () => {
    setCollapseActiveKeys([]);
  };

  // Renders

  const renderPanelHeader = (referenceNo: number, fileName: string) => (
    <div className={styles.panelHeader}>
      <div className={styles.panelHeader_title}>
        <FileTextFilled />
        {REFERENCE_HEADER} [{referenceNo}]
      </div>
      <span className={styles.panelHeader_fileName}>{fileName}</span>
    </div>
  );

  const renderSubHeader = (rank: number) => (
    <div className={styles.subHeader}>
      <RiseOutlined />
      <span>
        {DOCUMENT_RANK_HEADER} {formatOrdinals(rank)}
      </span>
    </div>
  );

  const renderPDFContent = useCallback(
    (reference: IMappedReferencesMetaAnnotation, content: ArrayBuffer) => {
      const { meta } = reference;

      // V1 pipelines use page, V2 pipelines use page_number
      const pageNumber = meta?.page || meta?.page_number || 1;

      // Clone array buffer to avoid detaching it when rendering the same file on a different collapse tab
      return (
        <React.Suspense fallback={<LoadingIndicator />}>
          <PDFHighlighter
            pdfData={cloneArrayBuffer(content)}
            search={{
              term: '',
              context: '',
              page: pageNumber,
            }}
          />
        </React.Suspense>
      );
    },
    [],
  );

  const renderMarkdownContent = (content: string) => {
    return <MarkdownViewer>{content}</MarkdownViewer>;
  };

  const renderCodeContent = (content: string, codeLanguage: SupportedViewerLanguage) => {
    return (
      <React.Suspense fallback={<LoadingIndicator />}>
        <CodeViewer code={content} codeLanguage={codeLanguage} />
      </React.Suspense>
    );
  };

  const renderTextContent = (
    content: string,
    highlightData: IHighlightData,
    referenceNumber: number,
  ) => {
    if (highlightData)
      return (
        <HighlightContent
          content={content}
          highlightData={[highlightData]}
          ref={(ref) => {
            if (ref && highlightContentRefs.current) {
              highlightContentRefs.current[referenceNumber - 1] = ref;
            }
          }}
        />
      );

    return (
      <div className={styles.content}>
        <pre>{content as string}</pre>
      </div>
    );
  };

  const renderMetadataModal = () => {
    return (
      <MetadataModal
        data={selectedMeta}
        nonEditableFields={['file_id']}
        open={isMetaModalVisible}
        onCancel={onCloseMetaModal}
        onOk={onCloseMetaModal}
      />
    );
  };

  const renderAnswer = () => {
    const answer = findActiveReferenceAnswer(searchResult.answers);
    const { documents } = searchResult;

    if (!answer) return null;
    return (
      <div className={styles.section}>
        <div className={styles.section_subheader}>
          <h5>{ANSWER_TITLE}</h5>
        </div>
        <div className={styles.answerContainer}>
          <ResultContainer
            pipelineId=""
            queryId=""
            searchStatus={StatusCodes.IDLE}
            searchResult={answer}
            documents={documents}
            pipelineName="pipelineName"
            pipelineType={PipelineType.GENERATIVE_QUESTION_ANSWERING}
            displayFeedbackOptions={false}
            displayFileOptions
            displayFileSources={false}
            displayMoreOptions={false}
            displayReferencesPopover={false}
          />
        </div>
      </div>
    );
  };

  const renderFileDocumentReference = (
    reference: IMappedReferencesMetaAnnotation,
    referenceNumber: number,
  ) => {
    if (!selectedFileID) return null;
    if (loadingFileContent || !filePreviewById[reference.file_id])
      return (
        <div className={styles.file_content}>
          <LoadingIndicator />
        </div>
      );

    const { content, displayLanguage } = filePreviewById[reference.file_id];
    const highlightLabel = `${REFERENCE_LABEL} ${referenceNumber}`;
    const referenceText = reference.content.slice(reference.doc_start_idx, reference.doc_end_idx);

    const isArrayBufferType = content instanceof ArrayBuffer;
    if (isArrayBufferType)
      return <div className={styles.file_content}>{renderPDFContent(reference, content)}</div>;

    if (displayLanguage === SupportedViewerLanguage.PLAIN_TEXT)
      return (
        <div className={styles.file_content}>
          {renderTextContent(
            content,
            {
              context: reference.content,
              answer: referenceText,
              label: highlightLabel,
              contentId: reference.file_id,
            },
            referenceNumber,
          )}
        </div>
      );
    if (displayLanguage === SupportedViewerLanguage.MARKDOWN)
      return <div className={styles.file_content}>{renderMarkdownContent(content)}</div>;

    return (
      <div className={styles.file_content}>
        {renderCodeContent(content, displayLanguage || SupportedViewerLanguage.PLAIN_TEXT)}
      </div>
    );
  };

  const renderDocumentReference = (
    reference: IMappedReferencesMetaAnnotation,
    referenceNumber: number,
  ) => {
    const highlightLabel = `${REFERENCE_LABEL} ${referenceNumber}`;
    return (
      <>
        {renderSubHeader(reference.document_position)}
        {reference.content &&
          renderTextContent(
            reference.content,
            {
              offsetsInDocument: [
                {
                  start: reference.doc_start_idx,
                  end: reference.doc_end_idx,
                },
              ],
              label: highlightLabel,
              contentId: reference.document_id,
            },
            referenceNumber,
          )}
        <Button
          size="small"
          className={styles.viewMetadata_button}
          onClick={() => {
            setSelectedMeta(reference.meta);
            setIsMetaModalVisible(true);
          }}
        >
          {VIEW_METADATA_BUTTON_LABEL}
        </Button>
      </>
    );
  };

  const renderReferences = () => {
    return (
      <div className={styles.section}>
        <div className={styles.section_header}>
          <h5>{REFERENCES_HEADER}</h5>
          <div className={styles.buttonsWrapper}>
            <Button size="small" onClick={onExpandAllRefs} icon={<ExpandSVG />}>
              {EXPAND_ALL_BUTTON_LABEL}
            </Button>
            <Button size="small" onClick={onCollapseAllRefs} icon={<CollapseSVG />}>
              {COLLAPSE_ALL_BUTTON_LABEL}
            </Button>
          </div>
        </div>
        <Collapse
          expandIconPosition="end"
          activeKey={collapseActiveKeys}
          onChange={(key) => {
            setCollapseActiveKeys(key as string[]);
          }}
        >
          {resultReferences?.map((reference, index) => (
            <Panel
              header={renderPanelHeader(index + 1, reference.file_name)}
              // eslint-disable-next-line react/no-array-index-key
              key={`${reference.result_id}_${index}`}
            >
              <div className={styles.panelTabs}>
                <Tabs
                  activeKey={currentActiveCollapseTab}
                  items={FILE_DRAWER_COLLAPSE_TABS}
                  tabBarGutter={16}
                  onChange={(activeKey) => {
                    setCurrentActiveCollapseTab(activeKey);
                    setSelectedFileID(reference.file_id);

                    trackUserEvent({
                      type: UserEventType.CLICK,
                      control: `${EventControlComponent.REFERENCE_DRAWER}/${EventControlElement.TAB}`,
                      properties: {
                        tab: activeKey,
                        result_id: reference.result_id,
                        file_id: reference.file_id,
                        document_id: reference.document_id,
                      },
                    });
                  }}
                />
                {currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE && (
                  <div className={styles.buttonWrapper}>
                    <Button
                      size="small"
                      className={styles.viewMetadata_button}
                      onClick={() => {
                        setSelectedMeta(fileMetaById[reference.file_id]);
                        setIsMetaModalVisible(true);
                      }}
                    >
                      {VIEW_METADATA_BUTTON_LABEL}
                    </Button>
                    {showScrollToReferenceButton(reference.file_name) && (
                      <Tooltip title={SCROLL_TO_REFERENCE_TITLE}>
                        <Button
                          size="small"
                          icon={<ArrowUpDownSVG />}
                          onClick={() => highlightContentRefs?.current?.[index].scrollToHighlight()}
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
              {currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE
                ? renderFileDocumentReference(reference, index + 1)
                : renderDocumentReference(reference, index + 1)}
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  };

  if (!resultReferences) return null;

  return (
    <div>
      <Drawer
        title={REFERENCES_HEADER}
        placement="right"
        open={referenceDrawerVisible}
        closable
        onClose={closeDrawer}
        width={800}
      >
        <div className={styles.body}>
          {renderAnswer()}
          {renderReferences()}
        </div>
      </Drawer>
      {renderMetadataModal()}
    </div>
  );
};

export default ReferenceDrawer;
