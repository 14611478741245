import { useDispatch } from 'react-redux';
import { updatePipeline } from '@redux/actions/pipelineActions';
import { PipelineServiceLevel } from '@redux/types/types';

const usePipelineServiceLevelSwitch = (
  serviceLevel: string,
  pipelineName: string,
  afterSwitch?: () => void,
) => {
  const dispatch = useDispatch();

  const changeServiceLevelHandler = async (newServiceLevel: PipelineServiceLevel) => {
    const payload = {
      pipelineName,
      serviceLevel: newServiceLevel,
    };
    await dispatch(updatePipeline(payload));
    if (afterSwitch) afterSwitch();
  };

  const changeServiceLevel = () => {
    if (serviceLevel === PipelineServiceLevel.PRODUCTION)
      changeServiceLevelHandler(PipelineServiceLevel.DEVELOPMENT);
    else if (serviceLevel === PipelineServiceLevel.DEVELOPMENT)
      changeServiceLevelHandler(PipelineServiceLevel.PRODUCTION);
  };

  return { changeServiceLevel };
};

export default usePipelineServiceLevelSwitch;
