import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Spin, Tag } from 'antd';
import { RUN_STATUS_TAG_MAP } from '@constants/common';
import { RunState } from '@redux/types/types';

interface IRunStatusTagProps {
  status: RunState;
}

const RunStatusTag = ({ status }: IRunStatusTagProps) => {
  const { label, color } = RUN_STATUS_TAG_MAP[status];

  return (
    <Tag style={{ fontSize: 12 }} color={color}>
      {status === RunState.STARTED && (
        <Spin
          size="small"
          style={{ display: 'inline-flex', margin: '-2px 4px 0 0 ' }}
          indicator={<SyncOutlined style={{ fontSize: 12 }} spin />}
        />
      )}
      {label}
    </Tag>
  );
};

export default RunStatusTag;
