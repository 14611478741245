import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MORE_TEMPLATES_LABEL } from '@constants/pipeline-templates';
import {
  getMoreLikeThisPipelineTemplates,
  resetMoreTemplatesLikeThis,
} from '@redux/actions/pipelineTemplatesActions';
import {
  moreTemplatesLikeThisSelector,
  moreTemplatesLikeThisStatusSelector,
} from '@redux/selectors/pipelineTemplatesSelectors';
import { IPipelineTemplate, ITag } from '@redux/types/types';
import TemplatesCard from '@pages/pipelineTemplates/components/TemplateCard';
import styles from './pipelineTemplateMoreLikeThis.module.scss';

interface IPipelineTemplateMoreLikeThisProps {
  template: IPipelineTemplate | null;
  setNewTemplate: (template: IPipelineTemplate) => void;
  sendMetrics: (pipelineName: string) => void;
}

const enum ExcludedTags {
  CATEGORY = 'category:',
  LANGUAGE = 'language:',
}

const cardListGap = 16;

const enum ScrollListDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

const scrollBehavior = 'smooth';

const PipelineTemplateMoreLikeThis = (props: IPipelineTemplateMoreLikeThisProps) => {
  const { template, setNewTemplate, sendMetrics } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const moreTemplatesLikeThis = useSelector(moreTemplatesLikeThisSelector);
  const moreTemplatesLikeThisStatus = useSelector(moreTemplatesLikeThisStatusSelector);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [contentWrapperWidth, setContentWrapperWidth] = useState(0);
  const [contentCardWrapperWidth, setContentCardWrapperWidth] = useState(0);
  const contentWrapper = useRef<HTMLDivElement>(null);
  const contentCardWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentWrapper.current) {
      setContentWrapperWidth(contentWrapper.current.scrollWidth);
    }
    if (contentCardWrapper.current) {
      setContentCardWrapperWidth(contentCardWrapper.current.offsetWidth + cardListGap);
    }
  }, [moreTemplatesLikeThisStatus, contentWrapper.current, contentCardWrapper.current]);

  useEffect(() => {
    if (template) {
      let tags: ITag[] = [];

      template.tags.forEach((tag) => {
        if (
          !tag.name.includes(ExcludedTags.CATEGORY) &&
          !tag.name.includes(ExcludedTags.LANGUAGE)
        ) {
          tags = [...tags, { ...tag }];
        }
      });

      if (tags.length > 0)
        dispatch(
          getMoreLikeThisPipelineTemplates({
            tags,
            deepsetCloudVersion: template.deepset_cloud_version,
          }),
        );
    }

    return () => {
      dispatch(resetMoreTemplatesLikeThis);
    };
  }, [template]);

  const onViewDetails = (pipelineName: string) => {
    navigate(`/pipelines/templates/${pipelineName}`);
    sendMetrics(pipelineName);
  };

  const onUseTemplate = (templateToUse: IPipelineTemplate) => {
    setNewTemplate(templateToUse);
    sendMetrics(templateToUse.pipeline_name);
  };

  const sideScroll = (direction: string) => {
    let nextPosition = currentScroll;
    if (direction === ScrollListDirection.LEFT)
      nextPosition = Math.max(0, currentScroll - contentCardWrapperWidth);
    else if (direction === ScrollListDirection.RIGHT)
      nextPosition = Math.min(currentScroll + contentCardWrapperWidth, contentWrapperWidth);
    contentWrapper.current?.scrollTo({ left: nextPosition, behavior: scrollBehavior });
    setCurrentScroll(nextPosition);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header_title}>{MORE_TEMPLATES_LABEL}</div>
        <div className={styles.header_actions}>
          <Button
            size="small"
            icon={<LeftOutlined />}
            disabled={currentScroll === 0}
            onClick={() => {
              sideScroll(ScrollListDirection.LEFT);
            }}
          />
          <Button
            size="small"
            icon={<RightOutlined />}
            disabled={currentScroll >= contentWrapperWidth - contentWrapper.current?.offsetWidth!}
            onClick={() => {
              sideScroll(ScrollListDirection.RIGHT);
            }}
          />
        </div>
      </div>
      <div className={styles.list} ref={contentWrapper}>
        {moreTemplatesLikeThis.map((moreTemplate) => (
          <div
            key={moreTemplate.pipeline_template_id}
            className={styles.list_item}
            ref={contentCardWrapper}
          >
            <TemplatesCard
              template={moreTemplate}
              onViewDetails={() => onViewDetails(moreTemplate.pipeline_name)}
              onUseTemplate={() => onUseTemplate(moreTemplate)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PipelineTemplateMoreLikeThis;
