import React from 'react';
import { DislikeFilled, LikeFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import { FEEDBACK_TYPE_LABELS } from '@constants/pipeline-feedback';
import { FeedbackType } from '@redux/types/types';
import styles from './pipelineFeedbackRatingTag.module.scss';

interface IPipelineFeedbackRatingProps {
  type: FeedbackType;
}

const PipelineFeedbackRatingTag = ({ type }: IPipelineFeedbackRatingProps) => {
  const getColor = () => {
    const mappedColorByType = {
      [FeedbackType.ACCURATE]: 'success',
      [FeedbackType.FAIRLY_ACCURATE]: 'warning',
      [FeedbackType.INACCURATE]: 'error',
    };

    return mappedColorByType[type];
  };

  const getIcon = () => {
    const mappedIconsByType = {
      [FeedbackType.ACCURATE]: <LikeFilled />,
      [FeedbackType.FAIRLY_ACCURATE]: <LikeFilled className={styles.neutralThumb_icon} />,
      [FeedbackType.INACCURATE]: <DislikeFilled />,
    };

    return mappedIconsByType[type];
  };

  return (
    <Tag bordered={false} icon={getIcon()} color={getColor()}>
      {FEEDBACK_TYPE_LABELS[type]}
    </Tag>
  );
};

export default PipelineFeedbackRatingTag;
