import React, { useEffect, useState } from 'react';
import { Input, Slider } from 'antd';
import { SliderBaseProps } from 'antd/lib/slider';
import { useScreen } from '@hooks/useScreen';
import styles from './rangeSlider.module.scss';

enum RangeType {
  MIN = 'min',
  MAX = 'max',
}
interface ISliderProps {
  values?: [number, number];
  min: number;
  max: number;
  marks?: SliderBaseProps['marks'];
  step?: number;
  onChange: (values: [number, number]) => void;
}

const DEFAULT_STEP_VALUE = 1;

const RangeSlider = ({ values, min, max, marks, step, onChange }: ISliderProps) => {
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;

  const [rangeValues, setRangeValues] = useState({
    min: values?.[0] || min,
    max: values?.[1] || max,
  });

  useEffect(() => {
    setRangeValues({
      min: values?.[0] || min,
      max: values?.[1] || max,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max, values]);

  const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>, type: RangeType) => {
    const value = +event.currentTarget.value;
    if (value > max || value < min) return;

    const isMinType = type === RangeType.MIN;
    const opositeType = isMinType ? RangeType.MAX : RangeType.MIN;

    if (isMinType && value > rangeValues[opositeType]) return;
    if (!isMinType && value < rangeValues[opositeType]) return;

    const newRangevalues = { ...rangeValues, [type]: value };
    setRangeValues(newRangevalues);
    onChange(Object.values(newRangevalues) as [number, number]);
  };

  return (
    <div className={styles.rangeSlider}>
      <div className={styles.rangeSlider_inputs}>
        <Input
          size={isSmallerScreen ? 'large' : 'middle'}
          type="number"
          value={rangeValues.min}
          onChange={(event) => onInputValueChange(event, RangeType.MIN)}
        />
        <span>to</span>
        <Input
          size={isSmallerScreen ? 'large' : 'middle'}
          type="number"
          value={rangeValues.max}
          onChange={(event) => onInputValueChange(event, RangeType.MAX)}
        />
      </div>
      <Slider
        range
        min={min}
        max={max}
        marks={marks}
        tooltip={{ open: false }}
        value={Object.values(rangeValues) as [number, number]}
        step={step || DEFAULT_STEP_VALUE}
        onChange={([minValue, maxValue]) => {
          setRangeValues({ min: minValue, max: maxValue });
          onChange([minValue, maxValue]);
        }}
      />
    </div>
  );
};

export default RangeSlider;
