import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import styles from './listCarousel.module.scss';

const SCROLL_SPEED = 15;
const SCROLL_LEFT_STEP = -10;
const SCROLL_RIGHT_STEP = 10;

interface IPipelineStatisticsComponent {
  options: { key: string; label: string | ReactNode; value: string | number | ReactNode }[];
  hideBorderBottom?: boolean;
}

const ListCarousel = ({ options, hideBorderBottom }: IPipelineStatisticsComponent) => {
  const contentWrapper = useRef<HTMLDivElement>(null);
  const [showArrowButtons, setShowArrowButtons] = useState(false);
  const [slideLeft, setSlideLeft] = useState(0);
  const [scrollDistance, setScrollDistance] = useState(0);
  const [contentWrapperWidth, setContentWrapperWidth] = useState(0);

  const handleResize = () => {
    if (contentWrapper.current) {
      setScrollDistance(contentWrapper.current.offsetWidth - 50);
      if (contentWrapper.current.offsetWidth < contentWrapper.current.scrollWidth) {
        setShowArrowButtons(true);
      } else {
        setShowArrowButtons(false);
      }
      setContentWrapperWidth(
        contentWrapper.current.scrollWidth - contentWrapper.current.offsetWidth,
      );
    }
  };

  useEffect(() => {
    handleResize();
    if (contentWrapper.current) contentWrapper.current.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    return () => {
      if (contentWrapper.current)
        contentWrapper.current.removeEventListener('resize', handleResize);
    };
  }, []);

  const sideScroll = (element: HTMLDivElement, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      // eslint-disable-next-line no-param-reassign
      element.scrollLeft += step;
      setSlideLeft(element.scrollLeft);
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <div className={styles.listCarousel}>
      <Divider />
      <div className={styles.listCarousel_options} ref={contentWrapper}>
        {options.map(({ key, label, value }, index) => (
          <React.Fragment key={key}>
            <div className={styles.listCarousel_options_content}>
              <small className={styles.listCarousel_options_content_label}>{label}</small>
              <span className={styles.listCarousel_options_content_value}>{value}</span>
            </div>
            {index < options.length - 1 && <Divider type="vertical" />}
          </React.Fragment>
        ))}
      </div>
      {showArrowButtons && (
        <>
          {slideLeft > 0 && (
            <Button
              icon={<ArrowLeftOutlined />}
              className={styles.listCarousel_arrowButtons_leftButton}
              onClick={() => {
                sideScroll(contentWrapper.current!, SCROLL_SPEED, scrollDistance, SCROLL_LEFT_STEP);
              }}
            />
          )}
          {slideLeft < contentWrapperWidth && (
            <Button
              icon={<ArrowRightOutlined />}
              className={styles.listCarousel_arrowButtons_rightButton}
              onClick={() => {
                sideScroll(
                  contentWrapper.current!,
                  SCROLL_SPEED,
                  scrollDistance,
                  SCROLL_RIGHT_STEP,
                );
              }}
            />
          )}
        </>
      )}
      {!hideBorderBottom && <Divider />}
    </div>
  );
};

export default ListCarousel;
