import { COMMON_FORM_ERRORS } from './common';

export const GUIDED_WORKFLOW_TOTAL_STEPS = 5;

export enum GuidedWorkflowStep {
  SELECT_TASK = 1,
  UPLOAD_DATA = 2,
  SELECT_PIPELINE = 3,
  NAME_PIPELINE = 4,
  DEPLOY_PIPELINE = 5,
}
export const STEPCARD_STEPS_TITLE = 'Step %currentStep% of %totalSteps%';

export const STEPS_INFO_SECTION = {
  SELECT_TASK: {
    title: 'Pipelines',
    description: [
      {
        subTitle: 'Question Answering',
        paragraph:
          'Choose this task if you need a system that can answer questions by highlighting text passages as answers (extractive QA) or generating novel text (generative QA).',
      },
      {
        subTitle: 'Document Search',
        paragraph: 'Use this pipeline type to retrieve a list of documents relevant to the query.',
      },
      {
        subTitle: 'Chat',
        paragraph:
          'Choose this type if you need a question answering system where human-like, conversational interaction is key.',
      },
      {
        subTitle: 'Text Analysis',
        paragraph:
          'These pipelines handle tasks such as file similarity, summarization, or named entity recognition. Choose this type to create a system that can extract meaningful insights from text data.',
      },
    ],
  },
  UPLOAD_DATA: {
    title: 'Files in deepset Cloud',
    description: [
      {
        paragraph:
          'Upload the files you want to use for your search. deepset Cloud supports .txt and .pdf file formats. The search runs on all files that exist in your workspace.',
      },
      {
        paragraph:
          'After you create a pipeline, the last step is to deploy it. When you deploy a pipeline, your files are turned into documents and indexed into a DocumentStore. Documents are small chunks of text that your pipeline processes to find the best answer.',
      },
    ],
  },
  SELECT_PIPELINE: {
    title: 'Template',
    description: [
      {
        paragraph:
          "In deepset Cloud there is a set of templates for question answering and document retrieval pipelines. A template is everything you need to build your first pipeline. It defines the nodes in a pipeline and how they're connected with each other.",
      },
    ],
  },
  NAME_PIPELINE: {
    title: 'Naming',
    description: [
      {
        paragraph:
          'Give your pipeline a name by which you can easily identify it later on. Make sure you only use alphanumeric characters and underscores (_) or dashes (-).',
      },
    ],
  },
  DEPLOY_PIPELINE: {
    title: 'Deploying a pipeline',
    description: [
      {
        paragraph:
          "You must deploy a pipeline to use it for search. Don't worry though, we handle all the complicated parts of the deployment. When you deploy a pipeline, we provision the infrastructure for you and ensure that it scales with your users.",
      },
      {
        paragraph:
          'During deployment, all the files in your workspace get turned into documents and are indexed into the DocumentStore. The pipeline then uses the documents from the DocumentStore to look for an answer.',
      },
      {
        paragraph: "When a pipeline's deployed and its status is Indexed, it's ready for search.",
      },
    ],
  },
};

export const STEPS = {
  SELECT_TASK: {
    title: 'Choose a task',
    subtitle:
      'In deepset Cloud, you can build two types of pipelines: question answering and document retrieval. Choose which one you want to build.',
    inputPlaceholder: 'Select task',
    info: STEPS_INFO_SECTION.SELECT_TASK,
    summaryTitle: 'Task',
  },
  UPLOAD_DATA: {
    title: 'Choose your data',
    subtitle:
      'It looks like you haven’t uploaded any data in deepset Cloud yet, so go ahead and add the files on which you want to run your search.',
    subtitleWithData:
      'Use the files that are already in deepset Cloud or upload additional files. Either way, the search runs on all files in your workspace.',
    buttonLabel: 'Upload Files',
    extra: 'You already have %totalFiles% %filesAnchor% in your workspace.',
    info: STEPS_INFO_SECTION.UPLOAD_DATA,
    summaryTitle: 'Files',
  },
  SELECT_PIPELINE: {
    title: 'Choose the pipeline template',
    subtitle:
      'These templates define pipelines that you can use out of the box. Select a template for your %pipelineType% pipeline.',
    buttonLabel: 'Next',
    note: 'Want to change your pipeline type? Switch to %pipelineTypes% instead.',
    info: STEPS_INFO_SECTION.SELECT_PIPELINE,
    summaryTitle: 'Pipeline template',
  },
  NAME_PIPELINE: {
    title: 'Name your pipeline',
    subtitle: 'Give your pipeline a meaningful name.',
    inputPlaceholder: 'Enter your unique pipeline name here',
    info: STEPS_INFO_SECTION.NAME_PIPELINE,
    summaryTitle: 'Pipeline name',
  },
  DEPLOY_PIPELINE: {
    title: 'Deploy your pipeline',
    subtitle: 'Congratulations, your pipeline is ready! Deploy it now to test how it works.',
    buttonLabel: 'Deploy Pipeline',
    info: STEPS_INFO_SECTION.DEPLOY_PIPELINE,
  },
};

export const PIPELINE_STATUS = {
  DEPLOYMENT_IN_PROGRESS: {
    title: 'Deploying your pipeline...',
    description:
      "Your pipeline “%pipelineName%” is being deployed. We're doing some heavy lifting here so it may take a couple of minutes. Once it's deployed, it will start indexing your files.",
  },
  INDEXING_IN_PROGRESS: {
    title: 'Indexing files...',
    description:
      'Your pipeline “%pipelineName%” is now indexing your files so that you can search on them.',
  },
  DEPLOYMENT_FAILED: {
    title: 'Failed to deploy',
    description:
      'Your pipeline “%pipelineName%” failed to deploy. Try deploying it again and if the issue persists, contact the solution engineer assigned to your organization.',
  },
  FAILED_INDEXING: {
    title: 'Failed to index',
    description:
      "Your pipeline “%pipelineName%” failed to index. It seems like we couldn't index your files. This might happen sometimes. Try again and see if it works.",
  },
  DEPLOYED: {
    title: 'You did it!',
    description:
      'You successfully created and deployed the pipeline “%pipelineName%”. You can now try it out or share with others to collect their feedback.',
  },
};

export const PIPELINE_STATUS_INFO_SECTION = {
  DEPLOYMENT_IN_PROGRESS: {
    title: 'Pipeline Deployment',
    description: [
      {
        paragraph: 'After you create a pipeline, you must deploy it to use it for search.',
      },
      {
        paragraph:
          'Pipeline deployment means we provision the infrastructure so that your pipeline is always available. This includes scaling up when more people use your pipeline.',
      },
      {
        paragraph:
          "Once the pipeline's deployed, it's ready for search. Use the Search page in deepset Cloud or search through API.",
      },
    ],
  },
  INDEXING_IN_PROGRESS: {
    title: 'Indexing',
    description: [
      {
        paragraph:
          'Your files must be indexed before the pipeline can search through them. Wait a while until at least some of the files finish indexing. You can try out the pipeline once the first files are indexed.',
      },
      {
        paragraph:
          "We index files in batches. It doesn't take long to index one batch of files. Depending on the amount of files you have, it might take a while to index all of them.",
      },
    ],
  },
  DEPLOYMENT_FAILED: {
    title: 'Pipeline Deployment',
    description: [
      {
        paragraph:
          "It may happen that deploying your pipeline fails. In that case, the files don't get indexed and you can't use this pipeline for search.",
      },
      {
        paragraph:
          'Deploying the pipeline again might solve this issue. If the issue persists, see our pipeline deployment Troubleshooting Guide.',
      },
    ],
  },
  FAILED_INDEXING: {
    title: 'Indexing',
    description: [
      {
        paragraph:
          'No files processed yet. Your pipeline is currently indexing. Before you can search, wait for a moment until at least some of your files are indexed.',
      },
    ],
  },
  DEPLOYED: {
    title: 'What’s next?',
    description: [
      {
        paragraph: "Your pipeline's ready for search! Try it out to see what it can do.",
      },
      {
        paragraph:
          'We recommend sharing the pipeline as a prototype with test users to get feedback. You can analyze it on the pipeline details page.',
      },
      {
        paragraph:
          'If you are happy with the results, you can integrate the pipeline into your application and move to production.',
      },
      {
        paragraph:
          'In our experience, users who collect feedback frequently and work in small iterations are the most successful. Building the best pipeline is a team effort!',
      },
    ],
  },
};

export const EDIT_STEP_BUTTON_LABEL = 'Edit';
export const TRY_PIPELINE_BUTTON_LABEL = 'Try Pipeline';
export const TRY_AGAIN_BUTTON_LABEL = 'Try Again';
export const SHARE_PROTOTYPE_BUTTON_LABEL = 'Share Prototype';
export const FILES_LABEL = 'files';

export const VALIDATION_ERRORS = {
  INVALID_CHARACTERS: COMMON_FORM_ERRORS.INVALID_CHARACTERS,
  PIPELINE_NAME_ALREADY_USED: 'Use a different name as this one is taken.',
};

export const SUCCESSFUL_UPLOAD_HEADER = '%successfulUploads% files uploaded';
