import produce from 'immer';
import { FiltersProp } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import { DEFAULT_FEEDBACK_SORTING_KEY } from '@constants/prompt-explorer';
import {
  CREATE_CUSTOM_PROMPT_TEMPLATE,
  DELETE_CUSTOM_PROMPT_TEMPLATE,
  FULFILLED,
  GET_CUSTOM_PROMPT_TEMPLATES,
  GET_PROMPT_TEMPLATES_FILTER_TAGS,
  GET_PROMPT_TEMPLATES_FILTER_USER,
  GET_PUBLIC_PROMPT_TEMPLATES,
  IAPIPaginationData,
  ITag,
  PENDING,
  PromptTemplate,
  PublicPromptTemplate,
  REJECTED,
  SELECT_PROMPT_TEMPLATES_SORT_VALUE,
  UPDATE_CUSTOM_PROMPT_TEMPLATE,
  UPDATE_PIPELINE_PROMPT,
} from '@redux/types/types';

interface IInitialStateProps {
  promptTemplates: IAPIPaginationData<PromptTemplate[]>;
  promptTemplatesStatus: StatusCodes;
  createPromptTemplateStatus: StatusCodes;
  updatePromptTemplateStatus: StatusCodes;
  promptTemplatesSortValue: string;
  filtersValues: Record<string, FiltersProp | []>;
  updatePipelinePromptStatus: StatusCodes;
}

export const initialState: IInitialStateProps = {
  promptTemplates: {
    data: [],
    has_more: false,
    total: 0,
  },
  promptTemplatesStatus: StatusCodes.IDLE,
  createPromptTemplateStatus: StatusCodes.IDLE,
  updatePromptTemplateStatus: StatusCodes.IDLE,
  promptTemplatesSortValue: DEFAULT_FEEDBACK_SORTING_KEY,
  filtersValues: {
    tags: [],
    users: [],
  },
  updatePipelinePromptStatus: StatusCodes.IDLE,
};

const mapPromptTemplatesTags = (tags: ITag[]): string[] => tags.map(({ name }) => name);

const promptExplorerReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case `${GET_PUBLIC_PROMPT_TEMPLATES}/${PENDING}`:
      case `${GET_CUSTOM_PROMPT_TEMPLATES}/${PENDING}`: {
        const { fetchMore } = action.meta.arg;
        if (fetchMore) break;
        localDraft.promptTemplatesStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${GET_CUSTOM_PROMPT_TEMPLATES}/${FULFILLED}`: {
        const { fetchMore } = action.meta.arg;
        localDraft.promptTemplatesStatus = StatusCodes.SUCCESS;

        const parsedTemplates: PromptTemplate[] = action.payload.data.map(
          (template: { tags: ITag[] }) => ({
            ...template,
            tags: mapPromptTemplatesTags(template.tags),
          }),
        );

        if (fetchMore)
          localDraft.promptTemplates = {
            ...action.payload,
            data: [...localDraft.promptTemplates.data, ...parsedTemplates],
          };
        else
          localDraft.promptTemplates = {
            ...action.payload,
            data: parsedTemplates,
          };
        break;
      }
      case `${GET_PUBLIC_PROMPT_TEMPLATES}/${FULFILLED}`: {
        localDraft.promptTemplatesStatus = StatusCodes.SUCCESS;
        const { fetchMore } = action.meta.arg;

        const parsedTemplates: PromptTemplate[] = action.payload.data.map(
          (template: PublicPromptTemplate) => ({
            ...template,
            prompt_template_id: template.public_prompt_template_id,
            user: {
              given_name: (template.meta?.authors as string[])?.[0] || '',
              family_name: '',
              user_id: '',
            },
            tags: mapPromptTemplatesTags(template.tags),
          }),
        );

        if (fetchMore)
          localDraft.promptTemplates = {
            ...action.payload,
            data: [...localDraft.promptTemplates.data, ...parsedTemplates],
          };
        else
          localDraft.promptTemplates = {
            ...action.payload,
            data: parsedTemplates,
          };
        break;
      }
      case `${CREATE_CUSTOM_PROMPT_TEMPLATE}/${PENDING}`: {
        localDraft.createPromptTemplateStatus = StatusCodes.IDLE;
        break;
      }
      case `${CREATE_CUSTOM_PROMPT_TEMPLATE}/${FULFILLED}`: {
        localDraft.createPromptTemplateStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${CREATE_CUSTOM_PROMPT_TEMPLATE}/${REJECTED}`: {
        localDraft.createPromptTemplateStatus = StatusCodes.ERROR;
        break;
      }
      case `${DELETE_CUSTOM_PROMPT_TEMPLATE}/${PENDING}`:
      case `${UPDATE_CUSTOM_PROMPT_TEMPLATE}/${PENDING}`: {
        localDraft.updatePromptTemplateStatus = StatusCodes.IDLE;
        break;
      }
      case `${DELETE_CUSTOM_PROMPT_TEMPLATE}/${FULFILLED}`:
      case `${UPDATE_CUSTOM_PROMPT_TEMPLATE}/${FULFILLED}`: {
        localDraft.updatePromptTemplateStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${DELETE_CUSTOM_PROMPT_TEMPLATE}/${REJECTED}`:
      case `${UPDATE_CUSTOM_PROMPT_TEMPLATE}/${REJECTED}`: {
        localDraft.updatePromptTemplateStatus = StatusCodes.ERROR;
        break;
      }
      case SELECT_PROMPT_TEMPLATES_SORT_VALUE:
        localDraft.promptTemplatesSortValue = action.payload;
        break;
      case `${GET_PROMPT_TEMPLATES_FILTER_TAGS}/${FULFILLED}`:
        localDraft.filtersValues = {
          ...localDraft.filtersValues,
          tags: action.payload.map(({ name: label, tag_id: key }: ITag) => ({ key, label })),
        };
        break;
      case `${GET_PROMPT_TEMPLATES_FILTER_USER}/${FULFILLED}`:
        localDraft.filtersValues = {
          ...localDraft.filtersValues,
          users: action.payload.map(
            ({
              given_name: firstName,
              family_name: lastName,
              user_id: key,
            }: PromptTemplate['user']) => ({
              key,
              label: `${firstName} ${lastName}`,
            }),
          ),
        };
        break;
      case `${UPDATE_PIPELINE_PROMPT}/${PENDING}`:
        localDraft.updatePipelinePromptStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${UPDATE_PIPELINE_PROMPT}/${FULFILLED}`:
        localDraft.updatePipelinePromptStatus = StatusCodes.SUCCESS;
        break;
      case `${UPDATE_PIPELINE_PROMPT}/${REJECTED}`:
        localDraft.updatePipelinePromptStatus = StatusCodes.ERROR;
        break;
      default:
        break;
    }

    return localDraft;
  });
};

export default promptExplorerReducer;
