import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Image } from 'antd';
import { interpolateString } from '@utils/string';
import deepsetLogo from '@assets/deepset-cloud-logo-darkblue.svg';
import { REDIRECTING_AUTH_BUTTON_LABEL, REDIRECTING_AUTH_LABEL } from '@constants/auth';
import { logout } from '@src/redux/actions/userActions';
import '@styles/components/layout.scss';

const LogoutComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <div className="auth-content" data-testid="logoutPage">
      <Image width={375} style={{ marginBottom: 32 }} src={deepsetLogo} preview={false} />
      <span>
        {interpolateString(REDIRECTING_AUTH_LABEL, {
          hereButton: (
            <Button type="link" onClick={() => dispatch(logout())} style={{ padding: 0 }}>
              {REDIRECTING_AUTH_BUTTON_LABEL}
            </Button>
          ),
        })}
      </span>
    </div>
  );
};

export default LogoutComponent;
