import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Select, Tag } from 'antd';
import { NEW_TAG_LABEL } from '@constants/common';
import styles from './tagInput.module.scss';

interface ITagInput {
  options?: string[];
  disabled?: boolean;
  onConfirm: (tagValue: string) => void;
}

const TagInput = ({ onConfirm, options = [], disabled }: ITagInput) => {
  const [tagFormValue, setTagFormValue] = useState('');
  const [isTagInputVisible, setIsTagInputVisible] = useState(false);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (isTagInputVisible) inputRef.current?.focus();
  }, [isTagInputVisible]);

  const showTagInput = () => {
    setIsTagInputVisible(true);
  };

  const handleTagInputChange = (value: string) => {
    setTagFormValue(value);
  };

  const handleTagInputConfirm = ({ value }: { value: string }) => {
    onConfirm(value || tagFormValue);
    setIsTagInputVisible(false);
    setTagFormValue('');
  };

  return (
    <span className={styles.tagInput}>
      {isTagInputVisible ? (
        <Select
          ref={inputRef}
          showSearch
          size="small"
          mode="tags"
          className={styles.tagInput_input}
          popupMatchSelectWidth={false}
          disabled={disabled}
          onSearch={handleTagInputChange}
          onSelect={handleTagInputConfirm}
          options={options.map((option) => ({ value: option, label: option }))}
        />
      ) : (
        <Tag onClick={showTagInput} className={styles.tagInput_tag}>
          <PlusOutlined /> {NEW_TAG_LABEL}
        </Tag>
      )}
    </span>
  );
};

export default TagInput;
