import React from 'react';
import UnexpectedErrorSVG from '@assets/unexpected-error.svg?react';
import { UNEXPECTED_ERROR_DESCRIPTION, UNEXPECTED_ERROR_LABEL } from '@constants/common';
import styles from './errorPage.module.scss';

const ErrorPage = () => (
  <div className={styles.container}>
    <UnexpectedErrorSVG />
    <div className={styles.title}>{UNEXPECTED_ERROR_LABEL}</div>
    <div className={styles.subtitle}>{UNEXPECTED_ERROR_DESCRIPTION}</div>
  </div>
);

export default ErrorPage;
