import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { fetchPipelines } from '@redux/actions/pipelineActions';
import { getUsageLimits, getUsageMetricValue } from '@redux/actions/usageActions';
import { pipelinesSelector } from '@redux/selectors/pipelineSelectors';
import { usageLimitsSelector, usageMetricsSelector } from '@redux/selectors/usageSelectors';
import useUsageDateRange from './useUsageDateRange';

interface UseMeteringUsageProps {
  disregardDate: boolean;
}

const useMeteringUsage = (props?: UseMeteringUsageProps) => {
  const dispatch = useDispatch();
  const { disregardDate } = props || {};
  const { selectedDateRange } = useUsageDateRange();
  const usageMetrics = useSelector(usageMetricsSelector);
  const usageLimits = useSelector(usageLimitsSelector);
  const { total } = useSelector((state) => pipelinesSelector(state, ''));

  useEffect(() => {
    const dateRangeValues = {
      from: disregardDate ? dayjs('2024-01-01') : selectedDateRange?.from,
      to: disregardDate ? dayjs() : selectedDateRange?.to,
    };
    dispatch(
      fetchPipelines({
        currentPage: 1,
        pageSize: 1,
        searchValue: '',
      }),
    );
    dispatch(getUsageLimits());
    dispatch(getUsageMetricValue({ dateRange: dateRangeValues }));
  }, []);

  return { usageMetrics, usageLimits, totalPipelines: total };
};

export default useMeteringUsage;
