import React from 'react';
import { NO_RELEVANT_ANSWER_LABEL } from '@constants/common';
import { IBaseSearchResultComponentProps, ISearchResultAnswer } from '@redux/types/types';
import SearchResultScore from '@components/search/molecules/searchResultScore/SearchResultScore';
import styles from './resultContainer.module.scss';
import SearchResultActions from '../resultActions/SearchResultActions';

interface INoAnswerResultProps
  extends Pick<
    IBaseSearchResultComponentProps<ISearchResultAnswer>,
    | 'result'
    | 'displayFeedbackOptions'
    | 'displayRelevanceScore'
    | 'pipelineId'
    | 'pipelineName'
    | 'queryId'
  > {
  isExternal?: boolean;
}

const NoAnswerResult = ({
  result,
  queryId,
  pipelineId,
  pipelineName,
  displayFeedbackOptions,
  displayRelevanceScore,
  isExternal,
}: INoAnswerResultProps) => {
  const renderFeedbackButtons = () => {
    return (
      <div className={styles.result_actions}>
        <SearchResultActions
          resultId={result.result_id}
          queryId={queryId}
          pipelineId={pipelineId}
          pipelineName={pipelineName}
          isExternal={isExternal}
        />
      </div>
    );
  };
  return (
    <div className={styles.result}>
      <div className={styles.result_body}>
        {NO_RELEVANT_ANSWER_LABEL}
        {displayRelevanceScore && <SearchResultScore score={result.score} />}
      </div>
      {displayFeedbackOptions && renderFeedbackButtons()}
    </div>
  );
};

export default NoAnswerResult;
