import produce from 'immer';
import { normalizeErrorMessage } from '@utils/error';
import { MessageCodes, StatusCodes } from '@constants/enum/common';
import {
  DELETE_EVALSET,
  DELETE_MULTIPLE_EVALSETS,
  EXPORT_EVALSET,
  EXPORT_EVALSET_CSV,
  FULFILLED,
  GET_EVALSET_CONTENT,
  GET_WORKSPACE_EVALSETS,
  IAPIPaginationData,
  IEvalset,
  ILabel,
  IMessage,
  IMPORT_EVALSET,
  PENDING,
  REJECTED,
  RESET_EVALSET_MESSAGE,
} from '@redux/types/types';

interface IInitialStateProps {
  evalsets: IAPIPaginationData<IEvalset[]>;
  labels: ILabel[];
  message: IMessage;
  actionStatus: StatusCodes;
  fetchStatus: StatusCodes;
}

export const initialState: IInitialStateProps = {
  evalsets: {
    data: [],
    has_more: false,
    total: 0,
  },
  labels: [],
  message: {
    type: MessageCodes.INFO,
    content: '',
  },
  actionStatus: StatusCodes.IDLE,
  fetchStatus: StatusCodes.IDLE,
};

function evalsetReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_EVALSET_MESSAGE:
        localDraft.message = initialState.message;
        localDraft.actionStatus = StatusCodes.IDLE;
        localDraft.fetchStatus = StatusCodes.IDLE;
        break;
      case `${DELETE_EVALSET}/${PENDING}`:
      case `${DELETE_MULTIPLE_EVALSETS}/${PENDING}`:
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        localDraft.message = {
          type: MessageCodes.INFO,
          content: 'Labels are being deleted.',
        };
        break;
      case `${GET_WORKSPACE_EVALSETS}/${PENDING}`:
        localDraft.fetchStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${EXPORT_EVALSET}/${PENDING}`:
      case `${EXPORT_EVALSET_CSV}/${PENDING}`:
      case `${GET_EVALSET_CONTENT}/${PENDING}`:
        localDraft.message = initialState.message;
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${IMPORT_EVALSET}/${PENDING}`:
        localDraft.message = {
          type: MessageCodes.INFO,
          content: 'Uploading evaluation sets.',
        };
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        break;

      case `${GET_WORKSPACE_EVALSETS}/${FULFILLED}`:
        localDraft.fetchStatus = StatusCodes.SUCCESS;
        localDraft.evalsets = action.payload;
        break;
      case `${EXPORT_EVALSET}/${FULFILLED}`:
      case `${EXPORT_EVALSET_CSV}/${FULFILLED}`:
      case `${GET_EVALSET_CONTENT}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        break;
      case `${DELETE_EVALSET}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Evaluation set deleted successfully.',
        };
        break;
      case `${DELETE_MULTIPLE_EVALSETS}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Evaluation sets deleted successfully.',
        };
        break;
      case `${IMPORT_EVALSET}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Evaluation sets uploaded successfully.',
        };
        break;

      case `${GET_WORKSPACE_EVALSETS}/${REJECTED}`:
        localDraft.fetchStatus = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;

      case `${GET_EVALSET_CONTENT}/${REJECTED}`:
      case `${EXPORT_EVALSET}/${REJECTED}`:
      case `${EXPORT_EVALSET_CSV}/${REJECTED}`:
      case `${DELETE_EVALSET}/${REJECTED}`:
      case `${DELETE_MULTIPLE_EVALSETS}/${REJECTED}`:
      case `${IMPORT_EVALSET}/${REJECTED}`:
        localDraft.actionStatus = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;

      default:
        break;
    }

    return localDraft;
  });
}

export default evalsetReducer;
