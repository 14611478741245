import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const usageStoreSelector = (state: RootState) => state.usageStore;

export const usageLimitsSelector = createSelector(
  usageStoreSelector,
  (usageStore) => usageStore.usageLimits,
);

export const usageMetricsSelector = createSelector(
  usageStoreSelector,
  (usageStore) => usageStore.usageMetrics,
);

export const pipelineHoursUsageSelector = createSelector(
  usageStoreSelector,
  (usageStore) => usageStore.pipelineUsageHours,
);

export const pipelineUsageMetricsSelector = createSelector(
  usageStoreSelector,
  (usageStore) => usageStore.pipelineUsageMetrics,
);

export const fetchingPipelineUsageMetricsStatusSelector = createSelector(
  usageStoreSelector,
  (usageStore) => usageStore.fetchingPipelineUsageMetricsStatus,
);

export const selectedDateRangeSelector = createSelector(
  usageStoreSelector,
  (usageStore) => usageStore.selectedDateRange,
);

export const pipelineUsageMetricsSortValueSelector = createSelector(
  usageStoreSelector,
  (usageStore) => usageStore.pipelineUsageMetricsSortValue,
);

export const pipelineUsageMetricsFiltersValuesSelector = createSelector(
  usageStoreSelector,
  (usageStore) => usageStore.pipelineUsageMetricsFiltersValues,
);
