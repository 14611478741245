import React from 'react';
import styles from './experimentsEmptyStateSVG.module.scss';

interface IExperimentsEmptyStateSVGProps {
  isUploadFilesCompleted?: boolean;
  isEvalSetCompleted?: boolean;
  isCreatePipelineCompleted?: boolean;
  onUploadFileSectionClick?: () => void;
  onPrepareEvalSetSectionClick?: () => void;
  onCreatePipelineSectionClick?: () => void;
}

const ExperimentsEmptyStateSVG = ({
  isUploadFilesCompleted = false,
  isEvalSetCompleted = false,
  isCreatePipelineCompleted = false,
  onUploadFileSectionClick,
  onPrepareEvalSetSectionClick,
  onCreatePipelineSectionClick,
}: IExperimentsEmptyStateSVGProps) => {
  const allStepsCompleted =
    isUploadFilesCompleted && isEvalSetCompleted && isCreatePipelineCompleted;
  const progressBarStyle = allStepsCompleted ? styles.completedFill : styles.defaultFill;

  const assignIf = <T extends unknown>(condition: boolean, value: T) =>
    condition ? value : undefined;

  const UploadFileSection = () => {
    const fillStyle = isUploadFilesCompleted ? styles.completedFill : styles.defaultFill;

    return (
      <svg>
        <svg className={fillStyle} id="uploadFile-text">
          <path d="M3.41113 36H5.47559V26.1357H3.41797L0.868164 27.9062V29.7656L3.28809 28.084H3.41113V36Z" />
          <path d="M24.168 36.2324C26.6152 36.2324 28.0781 34.7559 28.0781 32.6094V26.1357H26.8477V32.5273C26.8477 34.0449 25.877 35.0977 24.168 35.0977C22.459 35.0977 21.4746 34.0449 21.4746 32.5273V26.1357H20.2441V32.6094C20.2441 34.7559 21.7207 36.2324 24.168 36.2324Z" />
          <path d="M30.4297 38.4609H31.6191V34.8379H31.7285C32.1318 35.624 33.0137 36.1299 34.0254 36.1299C35.8984 36.1299 37.1152 34.6328 37.1152 32.3223V32.3086C37.1152 30.0117 35.8916 28.501 34.0254 28.501C33 28.501 32.1797 28.9863 31.7285 29.8066H31.6191V28.6309H30.4297V38.4609ZM33.752 35.0771C32.4121 35.0771 31.5918 34.0244 31.5918 32.3223V32.3086C31.5918 30.6064 32.4121 29.5537 33.752 29.5537C35.0986 29.5537 35.8984 30.5928 35.8984 32.3086V32.3223C35.8984 34.0381 35.0986 35.0771 33.752 35.0771Z" />
          <path d="M39.043 36H40.2324V25.7051H39.043V36Z" />
          <path d="M45.5439 36.1299C47.6426 36.1299 48.9414 34.6807 48.9414 32.3223V32.3086C48.9414 29.9434 47.6426 28.501 45.5439 28.501C43.4453 28.501 42.1465 29.9434 42.1465 32.3086V32.3223C42.1465 34.6807 43.4453 36.1299 45.5439 36.1299ZM45.5439 35.0771C44.1494 35.0771 43.3633 34.0586 43.3633 32.3223V32.3086C43.3633 30.5654 44.1494 29.5537 45.5439 29.5537C46.9385 29.5537 47.7246 30.5654 47.7246 32.3086V32.3223C47.7246 34.0586 46.9385 35.0771 45.5439 35.0771Z" />
          <path d="M52.8105 36.1299C53.8018 36.1299 54.5742 35.6992 55.0391 34.9131H55.1484V36H56.3379V30.9551C56.3379 29.4238 55.333 28.501 53.5352 28.501C51.9629 28.501 50.8418 29.2803 50.6504 30.4355L50.6436 30.4766H51.833L51.8398 30.4561C52.0312 29.8818 52.6123 29.5537 53.4941 29.5537C54.5947 29.5537 55.1484 30.0459 55.1484 30.9551V31.625L53.0361 31.7549C51.3203 31.8574 50.3496 32.6162 50.3496 33.9287V33.9424C50.3496 35.2822 51.4092 36.1299 52.8105 36.1299ZM51.5664 33.915V33.9014C51.5664 33.1699 52.0586 32.7734 53.1797 32.7051L55.1484 32.582V33.252C55.1484 34.3047 54.2666 35.0977 53.0566 35.0977C52.2021 35.0977 51.5664 34.6602 51.5664 33.915Z" />
          <path d="M61.2324 36.1299C62.2578 36.1299 63.0781 35.6445 63.5293 34.8242H63.6387V36H64.8281V25.7051H63.6387V29.793H63.5293C63.126 29.0068 62.2441 28.501 61.2324 28.501C59.3594 28.501 58.1426 29.998 58.1426 32.3086V32.3223C58.1426 34.6191 59.3662 36.1299 61.2324 36.1299ZM61.5059 35.0771C60.1592 35.0771 59.3594 34.0381 59.3594 32.3223V32.3086C59.3594 30.5928 60.1592 29.5537 61.5059 29.5537C62.8457 29.5537 63.666 30.6064 63.666 32.3086V32.3223C63.666 34.0244 62.8457 35.0771 61.5059 35.0771Z" />
          <path d="M71.1992 36H72.4297V31.7275H76.7979V30.6338H72.4297V27.2432H77.1875V26.1357H71.1992V36Z" />
          <path d="M79.6895 27.209C80.1406 27.209 80.5098 26.8398 80.5098 26.3887C80.5098 25.9375 80.1406 25.5684 79.6895 25.5684C79.2383 25.5684 78.8691 25.9375 78.8691 26.3887C78.8691 26.8398 79.2383 27.209 79.6895 27.209ZM79.0879 36H80.2773V28.6309H79.0879V36Z" />
          <path d="M82.5879 36H83.7773V25.7051H82.5879V36Z" />
          <path d="M89.0684 36.1299C90.8047 36.1299 91.8574 35.1455 92.1104 34.1475L92.124 34.0928H90.9346L90.9072 34.1543C90.709 34.5986 90.0938 35.0703 89.0957 35.0703C87.7832 35.0703 86.9424 34.1816 86.9082 32.6572H92.2129V32.1924C92.2129 29.9912 90.9961 28.501 88.9932 28.501C86.9902 28.501 85.6914 30.0596 85.6914 32.3359V32.3428C85.6914 34.6533 86.9629 36.1299 89.0684 36.1299ZM88.9863 29.5605C90.0732 29.5605 90.8799 30.251 91.0029 31.707H86.9287C87.0586 30.3057 87.8926 29.5605 88.9863 29.5605Z" />
          <path d="M96.5879 36.1299C98.2627 36.1299 99.541 35.2207 99.541 33.9082V33.8945C99.541 32.8418 98.8711 32.2402 97.4834 31.9053L96.3486 31.6318C95.4805 31.4199 95.1113 31.1055 95.1113 30.6064V30.5928C95.1113 29.9434 95.7539 29.4922 96.6289 29.4922C97.5176 29.4922 98.0918 29.8955 98.249 30.4766H99.418C99.2539 29.2734 98.1807 28.501 96.6357 28.501C95.0703 28.501 93.8945 29.4238 93.8945 30.6475V30.6543C93.8945 31.7139 94.5166 32.3154 95.8975 32.6436L97.0391 32.917C97.9482 33.1357 98.3242 33.4844 98.3242 33.9834V33.9971C98.3242 34.667 97.6201 35.1387 96.6289 35.1387C95.6855 35.1387 95.0977 34.7354 94.8994 34.1201H93.6826C93.8193 35.3369 94.9473 36.1299 96.5879 36.1299Z" />
        </svg>
        <svg
          id="uploadFile-circleContent"
          className={assignIf(!isUploadFilesCompleted, styles.cursorPointer)}
          onClick={assignIf(!isUploadFilesCompleted, onUploadFileSectionClick)}
        >
          <rect x="25.5" y="82.5" width="81" height="81" rx="40.5" fill="white" />
          <svg className={fillStyle} id="uploadFile-circleContent-icon">
            {isUploadFilesCompleted ? (
              <path d="M81.6242 110.422H78.8937C78.5109 110.422 78.1476 110.598 77.9132 110.898L61.8078 131.301L54.0851 121.516C53.9683 121.367 53.8194 121.247 53.6496 121.165C53.4797 121.082 53.2934 121.039 53.1046 121.039H50.3742C50.1124 121.039 49.9679 121.34 50.1281 121.543L60.8273 135.098C61.3273 135.73 62.2882 135.73 62.7921 135.098L81.8703 110.922C82.0304 110.723 81.8859 110.422 81.6242 110.422V110.422Z" />
            ) : (
              <>
                <path
                  opacity="0.1"
                  d="M65.5 108C57.2177 108 50.5 114.718 50.5 123C50.5 131.282 57.2177 138 65.5 138C73.7823 138 80.5 131.282 80.5 123C80.5 114.718 73.7823 108 65.5 108Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M50 123C50 114.164 57.1643 107 66 107C74.8357 107 82 114.164 82 123C82 131.836 74.8357 139 66 139C57.1643 139 50 131.836 50 123ZM52.7143 123C52.7143 130.336 58.6643 136.286 66 136.286C73.3357 136.286 79.2857 130.336 79.2857 123C79.2857 115.664 73.3357 109.714 66 109.714C58.6643 109.714 52.7143 115.664 52.7143 123ZM64.8794 119.482H62.7059C62.5088 119.482 62.4 119.263 62.5206 119.112L65.8147 115.087C65.8367 115.06 65.8648 115.038 65.8969 115.023C65.929 115.008 65.9643 115 66 115C66.0357 115 66.071 115.008 66.1031 115.023C66.1352 115.038 66.1633 115.06 66.1853 115.087L69.4794 119.115C69.6 119.263 69.4912 119.482 69.2941 119.482H67.1147V126.091C67.1147 126.216 67.0088 126.319 66.8794 126.319H65.1147C64.9853 126.319 64.8794 126.216 64.8794 126.091V119.482ZM72 124.096H73.7647C73.8941 124.096 74 124.199 74 124.324V130.091C74 130.594 73.5794 131 73.0588 131H58.9412C58.4206 131 58 130.594 58 130.091V124.324C58 124.199 58.1059 124.096 58.2353 124.096H60C60.1294 124.096 60.2353 124.199 60.2353 124.324V129H71.7647V124.324C71.7647 124.199 71.8706 124.096 72 124.096Z"
                />
              </>
            )}
          </svg>
          <rect x="25.5" y="82.5" width="81" height="81" rx="40.5" stroke="#F0F0F0" />
        </svg>
        <path
          className={progressBarStyle}
          id="uploadFile-progressBar"
          d="M108.548 108.349C106.392 102.086 102.879 96.3764 98.261 91.6276C93.6429 86.8787 88.0334 83.2079 81.8329 80.8773L80.2496 85.0896C85.8301 87.1872 90.8786 90.4908 95.0349 94.7648C99.1911 99.0388 102.353 104.178 104.294 109.814L108.548 108.349Z"
        />
        <path
          className={fillStyle}
          id="uploadFile-divider"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 102L13 -8.74231e-08L15 0L15 102L13 102Z"
        />
      </svg>
    );
  };

  const PrepareEvalSetSection = () => {
    const fillStyle = isEvalSetCompleted ? styles.completedFill : styles.defaultFill;

    return (
      <svg>
        <svg className={fillStyle} id="prepareEvalSet-text">
          <path d="M171.998 205H179.114V203.339H174.767V203.182L176.592 201.479C178.39 199.818 178.971 198.889 178.971 197.768V197.747C178.971 196.045 177.549 194.883 175.519 194.883C173.358 194.883 171.882 196.182 171.882 198.075L171.889 198.103H173.796V198.068C173.796 197.132 174.459 196.489 175.423 196.489C176.366 196.489 176.94 197.077 176.94 197.918V197.938C176.94 198.629 176.564 199.128 175.177 200.475L171.998 203.612V205Z" />
          <path d="M83.5723 205H84.8027V201.479H87.291C89.1436 201.479 90.4561 200.174 90.4561 198.301V198.287C90.4561 196.414 89.1436 195.136 87.291 195.136H83.5723V205ZM86.9766 196.229C88.3848 196.229 89.1982 197.002 89.1982 198.301V198.314C89.1982 199.613 88.3848 200.386 86.9766 200.386H84.8027V196.229H86.9766Z" />
          <path d="M92.3223 205H93.5117V200.434C93.5117 199.354 94.3184 198.636 95.4531 198.636C95.7129 198.636 95.9385 198.663 96.1846 198.704V197.549C96.0684 197.528 95.8154 197.501 95.5898 197.501C94.5918 197.501 93.9014 197.952 93.6211 198.725H93.5117V197.631H92.3223V205Z" />
          <path d="M100.457 205.13C102.193 205.13 103.246 204.146 103.499 203.147L103.513 203.093H102.323L102.296 203.154C102.098 203.599 101.482 204.07 100.484 204.07C99.1719 204.07 98.3311 203.182 98.2969 201.657H103.602V201.192C103.602 198.991 102.385 197.501 100.382 197.501C98.3789 197.501 97.0801 199.06 97.0801 201.336V201.343C97.0801 203.653 98.3516 205.13 100.457 205.13ZM100.375 198.561C101.462 198.561 102.269 199.251 102.392 200.707H98.3174C98.4473 199.306 99.2812 198.561 100.375 198.561Z" />
          <path d="M105.447 207.461H106.637V203.838H106.746C107.149 204.624 108.031 205.13 109.043 205.13C110.916 205.13 112.133 203.633 112.133 201.322V201.309C112.133 199.012 110.909 197.501 109.043 197.501C108.018 197.501 107.197 197.986 106.746 198.807H106.637V197.631H105.447V207.461ZM108.77 204.077C107.43 204.077 106.609 203.024 106.609 201.322V201.309C106.609 199.606 107.43 198.554 108.77 198.554C110.116 198.554 110.916 199.593 110.916 201.309V201.322C110.916 203.038 110.116 204.077 108.77 204.077Z" />
          <path d="M116.016 205.13C117.007 205.13 117.779 204.699 118.244 203.913H118.354V205H119.543V199.955C119.543 198.424 118.538 197.501 116.74 197.501C115.168 197.501 114.047 198.28 113.855 199.436L113.849 199.477H115.038L115.045 199.456C115.236 198.882 115.817 198.554 116.699 198.554C117.8 198.554 118.354 199.046 118.354 199.955V200.625L116.241 200.755C114.525 200.857 113.555 201.616 113.555 202.929V202.942C113.555 204.282 114.614 205.13 116.016 205.13ZM114.771 202.915V202.901C114.771 202.17 115.264 201.773 116.385 201.705L118.354 201.582V202.252C118.354 203.305 117.472 204.098 116.262 204.098C115.407 204.098 114.771 203.66 114.771 202.915Z" />
          <path d="M121.717 205H122.906V200.434C122.906 199.354 123.713 198.636 124.848 198.636C125.107 198.636 125.333 198.663 125.579 198.704V197.549C125.463 197.528 125.21 197.501 124.984 197.501C123.986 197.501 123.296 197.952 123.016 198.725H122.906V197.631H121.717V205Z" />
          <path d="M129.852 205.13C131.588 205.13 132.641 204.146 132.894 203.147L132.907 203.093H131.718L131.69 203.154C131.492 203.599 130.877 204.07 129.879 204.07C128.566 204.07 127.726 203.182 127.691 201.657H132.996V201.192C132.996 198.991 131.779 197.501 129.776 197.501C127.773 197.501 126.475 199.06 126.475 201.336V201.343C126.475 203.653 127.746 205.13 129.852 205.13ZM129.77 198.561C130.856 198.561 131.663 199.251 131.786 200.707H127.712C127.842 199.306 128.676 198.561 129.77 198.561Z" />
          <path d="M141.397 205.055C141.63 205.055 141.855 205.027 142.088 204.986V203.975C141.869 203.995 141.753 204.002 141.541 204.002C140.775 204.002 140.475 203.653 140.475 202.785V198.615H142.088V197.631H140.475V195.724H139.244V197.631H138.082V198.615H139.244V203.086C139.244 204.494 139.88 205.055 141.397 205.055Z" />
          <path d="M143.934 205H145.123V200.639C145.123 199.347 145.868 198.554 147.044 198.554C148.22 198.554 148.767 199.189 148.767 200.516V205H149.956V200.229C149.956 198.479 149.033 197.501 147.379 197.501C146.292 197.501 145.602 197.959 145.232 198.738H145.123V194.705H143.934V205Z" />
          <path d="M155.117 205.13C156.854 205.13 157.906 204.146 158.159 203.147L158.173 203.093H156.983L156.956 203.154C156.758 203.599 156.143 204.07 155.145 204.07C153.832 204.07 152.991 203.182 152.957 201.657H158.262V201.192C158.262 198.991 157.045 197.501 155.042 197.501C153.039 197.501 151.74 199.06 151.74 201.336V201.343C151.74 203.653 153.012 205.13 155.117 205.13ZM155.035 198.561C156.122 198.561 156.929 199.251 157.052 200.707H152.978C153.107 199.306 153.941 198.561 155.035 198.561Z" />
          <path d="M66.4961 223H72.6074V221.893H67.7266V218.502H72.3545V217.408H67.7266V214.243H72.6074V213.136H66.4961V223Z" />
          <path d="M76.7705 223H77.9736L80.7012 215.631H79.4434L77.4268 221.701H77.3174L75.3008 215.631H74.043L76.7705 223Z" />
          <path d="M84.0234 223.13C85.0146 223.13 85.7871 222.699 86.252 221.913H86.3613V223H87.5508V217.955C87.5508 216.424 86.5459 215.501 84.748 215.501C83.1758 215.501 82.0547 216.28 81.8633 217.436L81.8564 217.477H83.0459L83.0527 217.456C83.2441 216.882 83.8252 216.554 84.707 216.554C85.8076 216.554 86.3613 217.046 86.3613 217.955V218.625L84.249 218.755C82.5332 218.857 81.5625 219.616 81.5625 220.929V220.942C81.5625 222.282 82.6221 223.13 84.0234 223.13ZM82.7793 220.915V220.901C82.7793 220.17 83.2715 219.773 84.3926 219.705L86.3613 219.582V220.252C86.3613 221.305 85.4795 222.098 84.2695 222.098C83.415 222.098 82.7793 221.66 82.7793 220.915Z" />
          <path d="M89.793 223H90.9824V212.705H89.793V223Z" />
          <path d="M95.7334 223.13C96.8135 223.13 97.5586 222.686 97.9209 221.899H98.0303V223H99.2197V215.631H98.0303V219.992C98.0303 221.284 97.3398 222.077 96.041 222.077C94.8652 222.077 94.3867 221.441 94.3867 220.115V215.631H93.1973V220.402C93.1973 222.146 94.0586 223.13 95.7334 223.13Z" />
          <path d="M103.465 223.13C104.456 223.13 105.229 222.699 105.693 221.913H105.803V223H106.992V217.955C106.992 216.424 105.987 215.501 104.189 215.501C102.617 215.501 101.496 216.28 101.305 217.436L101.298 217.477H102.487L102.494 217.456C102.686 216.882 103.267 216.554 104.148 216.554C105.249 216.554 105.803 217.046 105.803 217.955V218.625L103.69 218.755C101.975 218.857 101.004 219.616 101.004 220.929V220.942C101.004 222.282 102.063 223.13 103.465 223.13ZM102.221 220.915V220.901C102.221 220.17 102.713 219.773 103.834 219.705L105.803 219.582V220.252C105.803 221.305 104.921 222.098 103.711 222.098C102.856 222.098 102.221 221.66 102.221 220.915Z" />
          <path d="M111.784 223.055C112.017 223.055 112.242 223.027 112.475 222.986V221.975C112.256 221.995 112.14 222.002 111.928 222.002C111.162 222.002 110.861 221.653 110.861 220.785V216.615H112.475V215.631H110.861V213.724H109.631V215.631H108.469V216.615H109.631V221.086C109.631 222.494 110.267 223.055 111.784 223.055Z" />
          <path d="M114.881 214.209C115.332 214.209 115.701 213.84 115.701 213.389C115.701 212.938 115.332 212.568 114.881 212.568C114.43 212.568 114.061 212.938 114.061 213.389C114.061 213.84 114.43 214.209 114.881 214.209ZM114.279 223H115.469V215.631H114.279V223Z" />
          <path d="M120.739 223.13C122.838 223.13 124.137 221.681 124.137 219.322V219.309C124.137 216.943 122.838 215.501 120.739 215.501C118.641 215.501 117.342 216.943 117.342 219.309V219.322C117.342 221.681 118.641 223.13 120.739 223.13ZM120.739 222.077C119.345 222.077 118.559 221.059 118.559 219.322V219.309C118.559 217.565 119.345 216.554 120.739 216.554C122.134 216.554 122.92 217.565 122.92 219.309V219.322C122.92 221.059 122.134 222.077 120.739 222.077Z" />
          <path d="M125.982 223H127.172V218.639C127.172 217.347 127.917 216.554 129.093 216.554C130.269 216.554 130.815 217.189 130.815 218.516V223H132.005V218.229C132.005 216.479 131.082 215.501 129.428 215.501C128.341 215.501 127.65 215.959 127.281 216.738H127.172V215.631H125.982V223Z" />
          <path d="M141.479 223.232C143.681 223.232 145.123 222.084 145.123 220.238V220.231C145.123 218.81 144.31 217.982 142.252 217.524L141.158 217.278C139.818 216.984 139.278 216.451 139.278 215.665V215.658C139.278 214.626 140.229 214.045 141.459 214.038C142.744 214.031 143.564 214.674 143.701 215.542L143.715 215.631H144.945L144.938 215.535C144.836 214.065 143.462 212.903 141.493 212.903C139.456 212.903 138.027 214.059 138.021 215.692V215.699C138.021 217.128 138.875 218.037 140.851 218.475L141.944 218.714C143.298 219.015 143.865 219.575 143.865 220.396V220.402C143.865 221.407 142.888 222.098 141.548 222.098C140.126 222.098 139.101 221.476 139.012 220.491L139.005 220.416H137.774L137.781 220.491C137.925 222.098 139.319 223.232 141.479 223.232Z" />
          <path d="M150.031 223.13C151.768 223.13 152.82 222.146 153.073 221.147L153.087 221.093H151.897L151.87 221.154C151.672 221.599 151.057 222.07 150.059 222.07C148.746 222.07 147.905 221.182 147.871 219.657H153.176V219.192C153.176 216.991 151.959 215.501 149.956 215.501C147.953 215.501 146.654 217.06 146.654 219.336V219.343C146.654 221.653 147.926 223.13 150.031 223.13ZM149.949 216.561C151.036 216.561 151.843 217.251 151.966 218.707H147.892C148.021 217.306 148.855 216.561 149.949 216.561Z" />
          <path d="M157.64 223.055C157.872 223.055 158.098 223.027 158.33 222.986V221.975C158.111 221.995 157.995 222.002 157.783 222.002C157.018 222.002 156.717 221.653 156.717 220.785V216.615H158.33V215.631H156.717V213.724H155.486V215.631H154.324V216.615H155.486V221.086C155.486 222.494 156.122 223.055 157.64 223.055Z" />
        </svg>
        <svg
          id="prepareEvalSet-circleContent"
          className={assignIf(!isEvalSetCompleted, styles.cursorPointer)}
          onClick={assignIf(!isEvalSetCompleted, onPrepareEvalSetSectionClick)}
        >
          <rect x="170.5" y="82.5" width="81" height="81" rx="40.5" fill="white" />
          <svg className={fillStyle} id="prepareEvalSet-circleContent-icon">
            {isEvalSetCompleted ? (
              <path d="M226.624 110.422H223.894C223.511 110.422 223.148 110.598 222.913 110.898L206.808 131.301L199.085 121.516C198.968 121.367 198.819 121.247 198.65 121.165C198.48 121.082 198.293 121.039 198.105 121.039H195.374C195.112 121.039 194.968 121.34 195.128 121.543L205.827 135.098C206.327 135.73 207.288 135.73 207.792 135.098L226.87 110.922C227.03 110.723 226.886 110.422 226.624 110.422Z" />
            ) : (
              <>
                <path
                  opacity="0.1"
                  d="M211.688 118V111.25H202.25V134.75H219.75V119.312H213C212.652 119.312 212.318 119.174 212.072 118.928C211.826 118.682 211.688 118.348 211.688 118V118Z"
                />
                <path d="M221.706 116.019L214.981 109.294C214.794 109.106 214.541 109 214.275 109H201C200.447 109 200 109.447 200 110V136C200 136.553 200.447 137 201 137H221C221.553 137 222 136.553 222 136V116.728C222 116.462 221.894 116.206 221.706 116.019ZM213.812 111.306L219.694 117.188H213.812V111.306ZM219.75 134.75H202.25V111.25H211.688V118C211.688 118.348 211.826 118.682 212.072 118.928C212.318 119.174 212.652 119.312 213 119.312H219.75V134.75Z" />
                <path d="M216.761 122H215.111C214.958 122 214.812 122.072 214.722 122.19L210.212 128.183L208.278 125.616C208.188 125.498 208.045 125.426 207.889 125.426H206.239C206.045 125.426 205.932 125.639 206.045 125.792L209.823 130.81C209.868 130.869 209.926 130.917 209.994 130.95C210.061 130.983 210.136 131 210.212 131C210.287 131 210.362 130.983 210.43 130.95C210.497 130.917 210.556 130.869 210.6 130.81L216.955 122.369C217.068 122.213 216.955 122 216.761 122Z" />
                <path d="M367.5 110.5H344.5C343.947 110.5 343.5 110.947 343.5 111.5V134.5C343.5 135.053 343.947 135.5 344.5 135.5H367.5C368.053 135.5 368.5 135.053 368.5 134.5V111.5C368.5 110.947 368.053 110.5 367.5 110.5ZM366.25 133.25H345.75V112.75H366.25V133.25Z" />
              </>
            )}
          </svg>
          <rect x="170.5" y="82.5" width="81" height="81" rx="40.5" stroke="#F0F0F0" />
        </svg>
        <path
          className={progressBarStyle}
          id="prepareEvalSet-progressBar"
          d="M210 168C215.909 168 221.761 166.836 227.221 164.575C232.68 162.313 237.641 158.998 241.82 154.82C245.998 150.641 249.313 145.68 251.575 140.221C253.836 134.761 255 128.909 255 123C255 117.091 253.836 111.239 251.575 105.779C249.313 100.32 245.998 95.3588 241.82 91.1802C237.641 87.0016 232.68 83.6869 227.221 81.4254C221.761 79.164 215.909 78 210 78L210 82.5C215.319 82.5 220.585 83.5476 225.499 85.5829C230.412 87.6182 234.877 90.6014 238.638 94.3622C242.399 98.1229 245.382 102.588 247.417 107.501C249.452 112.415 250.5 117.681 250.5 123C250.5 128.319 249.452 133.585 247.417 138.499C245.382 143.412 242.399 147.877 238.638 151.638C234.877 155.399 230.412 158.382 225.499 160.417C220.585 162.452 215.319 163.5 210 163.5L210 168Z"
        />
        <path
          className={fillStyle}
          id="prepareEvalSet-divider"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M165 254L165 152L167 152L167 254L165 254Z"
        />
      </svg>
    );
  };

  const CreatePipelineSection = () => {
    const fillStyle = isCreatePipelineCompleted ? styles.completedFill : styles.defaultFill;

    return (
      <svg>
        <svg className={fillStyle} id="createPipeline-text">
          <path d="M292.683 36.2188C294.986 36.2188 296.524 35.0088 296.524 33.2178V33.2041C296.524 31.8643 295.567 31.0166 294.098 30.8799V30.8389C295.253 30.5996 296.155 29.7998 296.155 28.5693V28.5557C296.155 26.9834 294.788 25.917 292.669 25.917C290.598 25.917 289.21 27.0654 289.066 28.8291L289.06 28.9111H290.96L290.967 28.8496C291.049 28.0293 291.698 27.5029 292.669 27.5029C293.64 27.5029 294.207 28.0088 294.207 28.8291V28.8428C294.207 29.6426 293.537 30.1895 292.498 30.1895H291.397V31.6592H292.525C293.722 31.6592 294.426 32.1787 294.426 33.1221V33.1357C294.426 33.9697 293.735 34.5576 292.683 34.5576C291.616 34.5576 290.912 34.0107 290.823 33.2451L290.816 33.1699H288.841L288.848 33.2588C288.984 35.0225 290.454 36.2188 292.683 36.2188Z" />
          <path d="M314.229 36.2324C316.328 36.2324 317.887 35.0635 318.215 33.2588V33.2451H316.971L316.957 33.2588C316.649 34.3936 315.604 35.0977 314.229 35.0977C312.329 35.0977 311.14 33.5459 311.14 31.0781V31.0645C311.14 28.5967 312.329 27.0381 314.223 27.0381C315.583 27.0381 316.636 27.8379 316.964 29.1025L316.971 29.1162H318.208L318.215 29.1025C317.921 27.209 316.301 25.9033 314.223 25.9033C311.543 25.9033 309.875 27.8857 309.875 31.0645V31.0781C309.875 34.25 311.55 36.2324 314.229 36.2324Z" />
          <path d="M320.129 36H321.318V31.4336C321.318 30.3535 322.125 29.6357 323.26 29.6357C323.52 29.6357 323.745 29.6631 323.991 29.7041V28.5488C323.875 28.5283 323.622 28.501 323.396 28.501C322.398 28.501 321.708 28.9521 321.428 29.7246H321.318V28.6309H320.129V36Z" />
          <path d="M328.264 36.1299C330 36.1299 331.053 35.1455 331.306 34.1475L331.319 34.0928H330.13L330.103 34.1543C329.904 34.5986 329.289 35.0703 328.291 35.0703C326.979 35.0703 326.138 34.1816 326.104 32.6572H331.408V32.1924C331.408 29.9912 330.191 28.501 328.188 28.501C326.186 28.501 324.887 30.0596 324.887 32.3359V32.3428C324.887 34.6533 326.158 36.1299 328.264 36.1299ZM328.182 29.5605C329.269 29.5605 330.075 30.251 330.198 31.707H326.124C326.254 30.3057 327.088 29.5605 328.182 29.5605Z" />
          <path d="M335.277 36.1299C336.269 36.1299 337.041 35.6992 337.506 34.9131H337.615V36H338.805V30.9551C338.805 29.4238 337.8 28.501 336.002 28.501C334.43 28.501 333.309 29.2803 333.117 30.4355L333.11 30.4766H334.3L334.307 30.4561C334.498 29.8818 335.079 29.5537 335.961 29.5537C337.062 29.5537 337.615 30.0459 337.615 30.9551V31.625L335.503 31.7549C333.787 31.8574 332.816 32.6162 332.816 33.9287V33.9424C332.816 35.2822 333.876 36.1299 335.277 36.1299ZM334.033 33.915V33.9014C334.033 33.1699 334.525 32.7734 335.646 32.7051L337.615 32.582V33.252C337.615 34.3047 336.733 35.0977 335.523 35.0977C334.669 35.0977 334.033 34.6602 334.033 33.915Z" />
          <path d="M343.597 36.0547C343.829 36.0547 344.055 36.0273 344.287 35.9863V34.9746C344.068 34.9951 343.952 35.002 343.74 35.002C342.975 35.002 342.674 34.6533 342.674 33.7852V29.6152H344.287V28.6309H342.674V26.7236H341.443V28.6309H340.281V29.6152H341.443V34.0859C341.443 35.4941 342.079 36.0547 343.597 36.0547Z" />
          <path d="M348.963 36.1299C350.699 36.1299 351.752 35.1455 352.005 34.1475L352.019 34.0928H350.829L350.802 34.1543C350.604 34.5986 349.988 35.0703 348.99 35.0703C347.678 35.0703 346.837 34.1816 346.803 32.6572H352.107V32.1924C352.107 29.9912 350.891 28.501 348.888 28.501C346.885 28.501 345.586 30.0596 345.586 32.3359V32.3428C345.586 34.6533 346.857 36.1299 348.963 36.1299ZM348.881 29.5605C349.968 29.5605 350.774 30.251 350.897 31.707H346.823C346.953 30.3057 347.787 29.5605 348.881 29.5605Z" />
          <path d="M359.914 36.1299C360.905 36.1299 361.678 35.6992 362.143 34.9131H362.252V36H363.441V30.9551C363.441 29.4238 362.437 28.501 360.639 28.501C359.066 28.501 357.945 29.2803 357.754 30.4355L357.747 30.4766H358.937L358.943 30.4561C359.135 29.8818 359.716 29.5537 360.598 29.5537C361.698 29.5537 362.252 30.0459 362.252 30.9551V31.625L360.14 31.7549C358.424 31.8574 357.453 32.6162 357.453 33.9287V33.9424C357.453 35.2822 358.513 36.1299 359.914 36.1299ZM358.67 33.915V33.9014C358.67 33.1699 359.162 32.7734 360.283 32.7051L362.252 32.582V33.252C362.252 34.3047 361.37 35.0977 360.16 35.0977C359.306 35.0977 358.67 34.6602 358.67 33.915Z" />
          <path d="M369.553 38.4609H370.742V34.8379H370.852C371.255 35.624 372.137 36.1299 373.148 36.1299C375.021 36.1299 376.238 34.6328 376.238 32.3223V32.3086C376.238 30.0117 375.015 28.501 373.148 28.501C372.123 28.501 371.303 28.9863 370.852 29.8066H370.742V28.6309H369.553V38.4609ZM372.875 35.0771C371.535 35.0771 370.715 34.0244 370.715 32.3223V32.3086C370.715 30.6064 371.535 29.5537 372.875 29.5537C374.222 29.5537 375.021 30.5928 375.021 32.3086V32.3223C375.021 34.0381 374.222 35.0771 372.875 35.0771Z" />
          <path d="M378.727 27.209C379.178 27.209 379.547 26.8398 379.547 26.3887C379.547 25.9375 379.178 25.5684 378.727 25.5684C378.275 25.5684 377.906 25.9375 377.906 26.3887C377.906 26.8398 378.275 27.209 378.727 27.209ZM378.125 36H379.314V28.6309H378.125V36Z" />
          <path d="M381.557 38.4609H382.746V34.8379H382.855C383.259 35.624 384.141 36.1299 385.152 36.1299C387.025 36.1299 388.242 34.6328 388.242 32.3223V32.3086C388.242 30.0117 387.019 28.501 385.152 28.501C384.127 28.501 383.307 28.9863 382.855 29.8066H382.746V28.6309H381.557V38.4609ZM384.879 35.0771C383.539 35.0771 382.719 34.0244 382.719 32.3223V32.3086C382.719 30.6064 383.539 29.5537 384.879 29.5537C386.226 29.5537 387.025 30.5928 387.025 32.3086V32.3223C387.025 34.0381 386.226 35.0771 384.879 35.0771Z" />
          <path d="M393.109 36.1299C394.846 36.1299 395.898 35.1455 396.151 34.1475L396.165 34.0928H394.976L394.948 34.1543C394.75 34.5986 394.135 35.0703 393.137 35.0703C391.824 35.0703 390.983 34.1816 390.949 32.6572H396.254V32.1924C396.254 29.9912 395.037 28.501 393.034 28.501C391.031 28.501 389.732 30.0596 389.732 32.3359V32.3428C389.732 34.6533 391.004 36.1299 393.109 36.1299ZM393.027 29.5605C394.114 29.5605 394.921 30.251 395.044 31.707H390.97C391.1 30.3057 391.934 29.5605 393.027 29.5605Z" />
          <path d="M398.168 36H399.357V25.7051H398.168V36Z" />
          <path d="M402.27 27.209C402.721 27.209 403.09 26.8398 403.09 26.3887C403.09 25.9375 402.721 25.5684 402.27 25.5684C401.818 25.5684 401.449 25.9375 401.449 26.3887C401.449 26.8398 401.818 27.209 402.27 27.209ZM401.668 36H402.857V28.6309H401.668V36Z" />
          <path d="M405.1 36H406.289V31.6387C406.289 30.3467 407.034 29.5537 408.21 29.5537C409.386 29.5537 409.933 30.1895 409.933 31.5156V36H411.122V31.2285C411.122 29.4785 410.199 28.501 408.545 28.501C407.458 28.501 406.768 28.959 406.398 29.7383H406.289V28.6309H405.1V36Z" />
          <path d="M416.283 36.1299C418.02 36.1299 419.072 35.1455 419.325 34.1475L419.339 34.0928H418.149L418.122 34.1543C417.924 34.5986 417.309 35.0703 416.311 35.0703C414.998 35.0703 414.157 34.1816 414.123 32.6572H419.428V32.1924C419.428 29.9912 418.211 28.501 416.208 28.501C414.205 28.501 412.906 30.0596 412.906 32.3359V32.3428C412.906 34.6533 414.178 36.1299 416.283 36.1299ZM416.201 29.5605C417.288 29.5605 418.095 30.251 418.218 31.707H414.144C414.273 30.3057 415.107 29.5605 416.201 29.5605Z" />
        </svg>
        <svg
          id="createPipeline-circleContent"
          className={assignIf(!isCreatePipelineCompleted, styles.cursorPointer)}
          onClick={assignIf(!isCreatePipelineCompleted, onCreatePipelineSectionClick)}
        >
          <rect x="315.5" y="82.5" width="81" height="81" rx="40.5" fill="white" />
          <svg className={fillStyle} id="createPipeline-circleContent-icon">
            {isCreatePipelineCompleted ? (
              <path d="M371.624 110.422H368.894C368.511 110.422 368.148 110.598 367.913 110.898L351.808 131.301L344.085 121.516C343.968 121.367 343.819 121.247 343.65 121.165C343.48 121.082 343.293 121.039 343.105 121.039H340.374C340.112 121.039 339.968 121.34 340.128 121.543L350.827 135.098C351.327 135.73 352.288 135.73 352.792 135.098L371.87 110.922C372.03 110.723 371.886 110.422 371.624 110.422Z" />
            ) : (
              <>
                <path
                  opacity="0.1"
                  d="M345.75 133.25H366.25V112.75H345.75V133.25ZM360.5 115.75C360.5 115.612 360.612 115.5 360.75 115.5H363.25C363.388 115.5 363.5 115.612 363.5 115.75V123.75C363.5 123.888 363.388 124 363.25 124H360.75C360.612 124 360.5 123.888 360.5 123.75V115.75ZM354.5 115.75C354.5 115.612 354.612 115.5 354.75 115.5H357.25C357.388 115.5 357.5 115.612 357.5 115.75V121.5C357.5 121.638 357.388 121.75 357.25 121.75H354.75C354.612 121.75 354.5 121.638 354.5 121.5V115.75ZM348.5 115.75C348.5 115.612 348.612 115.5 348.75 115.5H351.25C351.388 115.5 351.5 115.612 351.5 115.75V130.25C351.5 130.388 351.388 130.5 351.25 130.5H348.75C348.612 130.5 348.5 130.388 348.5 130.25V115.75Z"
                />
                <path d="M348.75 130.5H351.25C351.388 130.5 351.5 130.388 351.5 130.25V115.75C351.5 115.612 351.388 115.5 351.25 115.5H348.75C348.612 115.5 348.5 115.612 348.5 115.75V130.25C348.5 130.388 348.612 130.5 348.75 130.5ZM354.75 121.75H357.25C357.388 121.75 357.5 121.638 357.5 121.5V115.75C357.5 115.612 357.388 115.5 357.25 115.5H354.75C354.612 115.5 354.5 115.612 354.5 115.75V121.5C354.5 121.638 354.612 121.75 354.75 121.75ZM360.75 124H363.25C363.388 124 363.5 123.888 363.5 123.75V115.75C363.5 115.612 363.388 115.5 363.25 115.5H360.75C360.612 115.5 360.5 115.612 360.5 115.75V123.75C360.5 123.888 360.612 124 360.75 124Z" />
              </>
            )}
          </svg>
          <rect x="315.5" y="82.5" width="81" height="81" rx="40.5" stroke="#F0F0F0" />
        </svg>
        <path
          className={progressBarStyle}
          id="createPipeline-progressBar"
          d="M319.594 149.45C324.12 155.679 330.168 160.643 337.16 163.866C344.153 167.09 351.855 168.465 359.531 167.861C367.206 167.257 374.599 164.694 381.001 160.416C387.403 156.139 392.6 150.29 396.095 143.43C399.591 136.569 401.268 128.927 400.965 121.233C400.663 113.54 398.392 106.052 394.369 99.4876C390.346 92.9227 384.706 87.4998 377.988 83.7377C371.27 79.9755 363.699 78 356 78L356 82.5C362.93 82.5 369.743 84.278 375.789 87.6639C381.835 91.0498 386.911 95.9304 390.532 101.839C394.153 107.747 396.197 114.486 396.469 121.41C396.741 128.334 395.232 135.212 392.086 141.387C388.94 147.561 384.262 152.825 378.501 156.675C372.739 160.524 366.086 162.831 359.178 163.375C352.269 163.919 345.337 162.681 339.044 159.78C332.751 156.879 327.308 152.411 323.235 146.805L319.594 149.45Z"
        />
        <path
          className={fillStyle}
          id="createPipeline-divider"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M303 102L303 -8.74231e-08L305 0L305 102L303 102Z"
        />
      </svg>
    );
  };

  const CreateExperimentSection = () => (
    <svg>
      <svg className={styles.defaultFill} id="createExperiment-text">
        <path d="M466.571 205H468.54V203.188H469.825V201.534H468.54V195.136H465.642C464.329 197.159 462.989 199.347 461.738 201.568V203.188H466.571V205ZM463.598 201.589V201.479C464.486 199.887 465.546 198.171 466.503 196.701H466.612V201.589H463.598Z" />
        <path d="M379.229 205.232C381.328 205.232 382.887 204.063 383.215 202.259V202.245H381.971L381.957 202.259C381.649 203.394 380.604 204.098 379.229 204.098C377.329 204.098 376.14 202.546 376.14 200.078V200.064C376.14 197.597 377.329 196.038 379.223 196.038C380.583 196.038 381.636 196.838 381.964 198.103L381.971 198.116H383.208L383.215 198.103C382.921 196.209 381.301 194.903 379.223 194.903C376.543 194.903 374.875 196.886 374.875 200.064V200.078C374.875 203.25 376.55 205.232 379.229 205.232Z" />
        <path d="M385.129 205H386.318V200.434C386.318 199.354 387.125 198.636 388.26 198.636C388.52 198.636 388.745 198.663 388.991 198.704V197.549C388.875 197.528 388.622 197.501 388.396 197.501C387.398 197.501 386.708 197.952 386.428 198.725H386.318V197.631H385.129V205Z" />
        <path d="M393.264 205.13C395 205.13 396.053 204.146 396.306 203.147L396.319 203.093H395.13L395.103 203.154C394.904 203.599 394.289 204.07 393.291 204.07C391.979 204.07 391.138 203.182 391.104 201.657H396.408V201.192C396.408 198.991 395.191 197.501 393.188 197.501C391.186 197.501 389.887 199.06 389.887 201.336V201.343C389.887 203.653 391.158 205.13 393.264 205.13ZM393.182 198.561C394.269 198.561 395.075 199.251 395.198 200.707H391.124C391.254 199.306 392.088 198.561 393.182 198.561Z" />
        <path d="M400.277 205.13C401.269 205.13 402.041 204.699 402.506 203.913H402.615V205H403.805V199.955C403.805 198.424 402.8 197.501 401.002 197.501C399.43 197.501 398.309 198.28 398.117 199.436L398.11 199.477H399.3L399.307 199.456C399.498 198.882 400.079 198.554 400.961 198.554C402.062 198.554 402.615 199.046 402.615 199.955V200.625L400.503 200.755C398.787 200.857 397.816 201.616 397.816 202.929V202.942C397.816 204.282 398.876 205.13 400.277 205.13ZM399.033 202.915V202.901C399.033 202.17 399.525 201.773 400.646 201.705L402.615 201.582V202.252C402.615 203.305 401.733 204.098 400.523 204.098C399.669 204.098 399.033 203.66 399.033 202.915Z" />
        <path d="M408.597 205.055C408.829 205.055 409.055 205.027 409.287 204.986V203.975C409.068 203.995 408.952 204.002 408.74 204.002C407.975 204.002 407.674 203.653 407.674 202.785V198.615H409.287V197.631H407.674V195.724H406.443V197.631H405.281V198.615H406.443V203.086C406.443 204.494 407.079 205.055 408.597 205.055Z" />
        <path d="M413.963 205.13C415.699 205.13 416.752 204.146 417.005 203.147L417.019 203.093H415.829L415.802 203.154C415.604 203.599 414.988 204.07 413.99 204.07C412.678 204.07 411.837 203.182 411.803 201.657H417.107V201.192C417.107 198.991 415.891 197.501 413.888 197.501C411.885 197.501 410.586 199.06 410.586 201.336V201.343C410.586 203.653 411.857 205.13 413.963 205.13ZM413.881 198.561C414.968 198.561 415.774 199.251 415.897 200.707H411.823C411.953 199.306 412.787 198.561 413.881 198.561Z" />
        <path d="M424.914 205.13C425.905 205.13 426.678 204.699 427.143 203.913H427.252V205H428.441V199.955C428.441 198.424 427.437 197.501 425.639 197.501C424.066 197.501 422.945 198.28 422.754 199.436L422.747 199.477H423.937L423.943 199.456C424.135 198.882 424.716 198.554 425.598 198.554C426.698 198.554 427.252 199.046 427.252 199.955V200.625L425.14 200.755C423.424 200.857 422.453 201.616 422.453 202.929V202.942C422.453 204.282 423.513 205.13 424.914 205.13ZM423.67 202.915V202.901C423.67 202.17 424.162 201.773 425.283 201.705L427.252 201.582V202.252C427.252 203.305 426.37 204.098 425.16 204.098C424.306 204.098 423.67 203.66 423.67 202.915Z" />
        <path d="M430.615 205H431.805V200.639C431.805 199.347 432.55 198.554 433.726 198.554C434.901 198.554 435.448 199.189 435.448 200.516V205H436.638V200.229C436.638 198.479 435.715 197.501 434.061 197.501C432.974 197.501 432.283 197.959 431.914 198.738H431.805V197.631H430.615V205Z" />
        <path d="M375.258 223H381.369V221.893H376.488V218.502H381.116V217.408H376.488V214.243H381.369V213.136H375.258V223Z" />
        <path d="M382.859 223H384.192L385.942 220.218H386.052L387.795 223H389.189L386.653 219.268L389.155 215.631H387.822L386.093 218.372H385.983L384.233 215.631H382.832L385.382 219.315L382.859 223Z" />
        <path d="M390.789 225.461H391.979V221.838H392.088C392.491 222.624 393.373 223.13 394.385 223.13C396.258 223.13 397.475 221.633 397.475 219.322V219.309C397.475 217.012 396.251 215.501 394.385 215.501C393.359 215.501 392.539 215.986 392.088 216.807H391.979V215.631H390.789V225.461ZM394.111 222.077C392.771 222.077 391.951 221.024 391.951 219.322V219.309C391.951 217.606 392.771 216.554 394.111 216.554C395.458 216.554 396.258 217.593 396.258 219.309V219.322C396.258 221.038 395.458 222.077 394.111 222.077Z" />
        <path d="M402.342 223.13C404.078 223.13 405.131 222.146 405.384 221.147L405.397 221.093H404.208L404.181 221.154C403.982 221.599 403.367 222.07 402.369 222.07C401.057 222.07 400.216 221.182 400.182 219.657H405.486V219.192C405.486 216.991 404.27 215.501 402.267 215.501C400.264 215.501 398.965 217.06 398.965 219.336V219.343C398.965 221.653 400.236 223.13 402.342 223.13ZM402.26 216.561C403.347 216.561 404.153 217.251 404.276 218.707H400.202C400.332 217.306 401.166 216.561 402.26 216.561Z" />
        <path d="M407.332 223H408.521V218.434C408.521 217.354 409.328 216.636 410.463 216.636C410.723 216.636 410.948 216.663 411.194 216.704V215.549C411.078 215.528 410.825 215.501 410.6 215.501C409.602 215.501 408.911 215.952 408.631 216.725H408.521V215.631H407.332V223Z" />
        <path d="M413.293 214.209C413.744 214.209 414.113 213.84 414.113 213.389C414.113 212.938 413.744 212.568 413.293 212.568C412.842 212.568 412.473 212.938 412.473 213.389C412.473 213.84 412.842 214.209 413.293 214.209ZM412.691 223H413.881V215.631H412.691V223Z" />
        <path d="M416.123 223H417.312V218.434C417.312 217.395 418.044 216.554 419.008 216.554C419.938 216.554 420.539 217.121 420.539 217.996V223H421.729V218.263C421.729 217.326 422.405 216.554 423.431 216.554C424.47 216.554 424.969 217.094 424.969 218.181V223H426.158V217.907C426.158 216.362 425.317 215.501 423.813 215.501C422.795 215.501 421.954 216.014 421.558 216.793H421.448C421.106 216.027 420.409 215.501 419.411 215.501C418.447 215.501 417.75 215.959 417.422 216.752H417.312V215.631H416.123V223Z" />
        <path d="M431.312 223.13C433.049 223.13 434.102 222.146 434.354 221.147L434.368 221.093H433.179L433.151 221.154C432.953 221.599 432.338 222.07 431.34 222.07C430.027 222.07 429.187 221.182 429.152 219.657H434.457V219.192C434.457 216.991 433.24 215.501 431.237 215.501C429.234 215.501 427.936 217.06 427.936 219.336V219.343C427.936 221.653 429.207 223.13 431.312 223.13ZM431.23 216.561C432.317 216.561 433.124 217.251 433.247 218.707H429.173C429.303 217.306 430.137 216.561 431.23 216.561Z" />
        <path d="M436.303 223H437.492V218.639C437.492 217.347 438.237 216.554 439.413 216.554C440.589 216.554 441.136 217.189 441.136 218.516V223H442.325V218.229C442.325 216.479 441.402 215.501 439.748 215.501C438.661 215.501 437.971 215.959 437.602 216.738H437.492V215.631H436.303V223Z" />
        <path d="M447.097 223.055C447.329 223.055 447.555 223.027 447.787 222.986V221.975C447.568 221.995 447.452 222.002 447.24 222.002C446.475 222.002 446.174 221.653 446.174 220.785V216.615H447.787V215.631H446.174V213.724H444.943V215.631H443.781V216.615H444.943V221.086C444.943 222.494 445.579 223.055 447.097 223.055Z" />
      </svg>
      <svg id="createExperiment-circleContent">
        <rect x="460.5" y="82.5" width="81" height="81" rx="40.5" fill="white" />
        <path
          opacity="0.1"
          d="M502.247 123.032C502.859 123.032 503.369 122.588 503.475 122.007C503.416 122.288 503.262 122.54 503.039 122.721C502.816 122.902 502.537 123.001 502.25 123.001C501.922 123.001 501.607 122.871 501.373 122.641C501.139 122.411 501.005 122.098 501 121.769V121.785C501 122.472 501.559 123.032 502.247 123.032ZM508.5 128.494L508.491 128.485C507.584 127.938 506.544 127.647 505.459 127.647C504.056 127.647 502.734 128.138 501.678 129.016C500.234 130.227 498.409 130.89 496.525 130.888C495.237 130.888 493.994 130.582 492.878 130.013L491.578 133.375H510.422L508.55 128.526L508.5 128.494Z"
          fill="#3A61C2"
        />
        <path
          d="M512.469 132.778L506.759 118V112.562H509V110.438H493V112.562H495.24V118L489.531 132.778C489.444 133.009 489.397 133.253 489.397 133.5C489.397 134.603 490.294 135.5 491.397 135.5H510.603C510.85 135.5 511.094 135.453 511.325 135.366C512.356 134.969 512.869 133.809 512.469 132.778V132.778ZM497.365 118.397V112.625H504.634V118.397L507.475 125.75C506.828 125.584 506.159 125.5 505.478 125.5C503.565 125.5 501.753 126.172 500.312 127.375C499.249 128.263 497.907 128.749 496.522 128.747C495.5 128.747 494.519 128.488 493.653 128.006L497.365 118.397V118.397ZM491.578 133.375L492.878 130.013L492.881 130.006C493.997 130.572 495.237 130.875 496.525 130.875C498.437 130.875 500.25 130.203 501.69 129C502.75 128.119 504.075 127.628 505.481 127.628C506.575 127.628 507.619 127.925 508.531 128.475L508.55 128.525L510.422 133.375H491.578Z"
          fill="#3A61C2"
        />
        <path
          d="M502.25 123C502.853 123 503.356 122.575 503.475 122.006C503.494 121.922 503.5 121.837 503.5 121.75C503.5 121.059 502.941 120.5 502.25 120.5C501.559 120.5 501 121.059 501 121.75V121.769C501.005 122.097 501.139 122.41 501.373 122.641C501.607 122.871 501.922 123 502.25 123Z"
          fill="#3A61C2"
        />
        <rect x="460.5" y="82.5" width="81" height="81" rx="40.5" stroke="#F0F0F0" />
      </svg>
      <path
        className={styles.defaultFill}
        id="createExperiment-progressBar"
        d="M458.202 109.094C455.266 118.132 455.266 127.868 458.202 136.906C461.139 145.944 466.862 153.82 474.55 159.406C482.238 164.992 491.497 168 501 168C510.503 168 519.762 164.992 527.45 159.406C535.138 153.82 540.861 145.944 543.798 136.906C546.734 127.868 546.734 118.132 543.798 109.094C540.861 100.056 535.138 92.18 527.45 86.5942C519.762 81.0085 510.503 78 501 78L501 82.5C509.553 82.5 517.886 85.2076 524.805 90.2348C531.725 95.262 536.875 102.351 539.518 110.485C542.161 118.619 542.161 127.381 539.518 135.515C536.875 143.649 531.725 150.738 524.805 155.765C517.886 160.792 509.553 163.5 501 163.5C492.447 163.5 484.114 160.792 477.195 155.765C470.275 150.738 465.125 143.649 462.482 135.515C459.839 127.381 459.839 118.619 462.482 110.485L458.202 109.094Z"
      />
      <path
        className={styles.defaultFill}
        id="createExperiment-divider"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M455 254L455 152L457 152L457 254L455 254Z"
      />
    </svg>
  );

  return (
    <svg
      width="546"
      height="254"
      viewBox="0 0 546 254"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <UploadFileSection />
      <PrepareEvalSetSection />
      <CreatePipelineSection />
      <CreateExperimentSection />
    </svg>
  );
};

export default ExperimentsEmptyStateSVG;
