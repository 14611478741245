import { Node, parseDocument, stringify, YAMLMap, YAMLSeq } from 'yaml';

// Haystack specific utils

const PROMPT_TEMPLATE_SPECIAL_CHAR_ALIAS = {
  new_line: '\n',
  tab: '\t',
  double_quote: '"',
  carriage_return: '\r',
};

const convertPromptSpecialTokens = (value: string): string => {
  return Object.entries(PROMPT_TEMPLATE_SPECIAL_CHAR_ALIAS).reduce(
    (currentValue, [token, char]) => {
      return currentValue.replace(new RegExp(`\\{${token}\\}`, 'g'), char);
    },
    value,
  );
};

const traverseAndConvertPromptSpecialTokens = (node: any): void => {
  if (node instanceof YAMLMap) {
    node.items.forEach((item) => {
      if (item.key.value === 'prompt' && typeof item.value.value === 'string') {
        // Replace the special characters in the string value of 'prompt'
        const currentItem = item;
        currentItem.value.value = convertPromptSpecialTokens(item.value.value);
      } else if (item.value instanceof YAMLMap || item.value instanceof YAMLSeq) {
        // Recursively process the next node in the AST
        traverseAndConvertPromptSpecialTokens(item.value);
      }
    });
  } else if (node instanceof YAMLSeq) {
    node.items.forEach((item) => {
      // Recursively process each item in the sequence
      traverseAndConvertPromptSpecialTokens(item);
    });
  }
};

// General yaml utils

const splitKeyValue = (keyValueString: string): string[] =>
  keyValueString.split(':').map((s) => s.trim());

const findIndexOfMapByKeyValue = (sequence: YAMLSeq, keyName: string, keyValue: string): number => {
  const index = sequence.items.findIndex(
    (item: any) => item instanceof YAMLMap && item.get(keyName) === keyValue,
  );
  return index;
};

// Inserts a value at the given path within the node.
const insertAtPath = (node: YAMLSeq | YAMLMap, path: string[], newValue: any): Node => {
  if (path.length === 0) throw new Error('Path must not be empty');

  const [key, ...remainingPath] = path;
  const isKeyValue = key.includes(':');

  if (isKeyValue) {
    const [keyName, keyValue] = splitKeyValue(key);
    if (!(node instanceof YAMLSeq))
      throw new Error(`Expected a sequence for the key '${keyName}', but got ${typeof node}`);

    const index = findIndexOfMapByKeyValue(node, keyName, keyValue);
    let targetMap: YAMLMap;

    if (index === -1) {
      targetMap = new YAMLMap();
      targetMap.set(keyName, keyValue);
      node.items.push(targetMap);
    } else {
      targetMap = node.items[index] as YAMLMap;
    }

    if (remainingPath.length === 0) {
      Object.entries(newValue).forEach(([nestedKey, nestedValue]) => {
        targetMap.set(nestedKey, nestedValue);
      });
      return node;
    }

    return insertAtPath(targetMap, remainingPath, newValue);
  }

  // For direct keys, insert the value or recurse deeper.
  let nextNode = node.get(key) as YAMLMap;
  if (!nextNode) {
    nextNode = new YAMLMap();
    node.set(key, nextNode);
  }
  if (remainingPath.length === 0) {
    Object.entries(newValue).forEach(([nestedKey, nestedValue]) => {
      nextNode.set(nestedKey, nestedValue);
    });
  } else {
    return insertAtPath(nextNode as YAMLMap, remainingPath, newValue);
  }

  return node;
};

// Retrieves the value at the given path within the node.
const getValueAtPath = (node: YAMLSeq | YAMLMap, path: string[]): null | Node => {
  if (path.length === 0) return node; // Returns the node if path is exhausted.

  const [key, ...remainingPath] = path;
  const isKeyValue = key.includes(':');

  // If the path is a key:value pair, process as a sequence item.
  if (isKeyValue) {
    const [keyName, keyValue] = splitKeyValue(key);
    if (!(node instanceof YAMLSeq)) return null;

    const index = findIndexOfMapByKeyValue(node, keyName, keyValue);
    if (index === -1) return null; // Returns null if the key:value pair is not found.
    if (remainingPath.length === 0) return node.items[index] as YAMLMap;
    return getValueAtPath(node.items[index] as YAMLMap, remainingPath);
  }

  // For direct keys, retrieve the value or recurse deeper.
  const nextNode = node.get(key);
  if (!nextNode || remainingPath.length === 0) return nextNode as YAMLMap;
  return getValueAtPath(nextNode as YAMLMap, remainingPath);
};

// Updates the value at the given path within the node.
const updateAtPath = (node: YAMLSeq | YAMLMap, path: string[], newValue: any): Node => {
  if (path.length === 0) return newValue;

  const [key, ...remainingPath] = path;
  const isKeyValue = key.includes(':');
  const currentNode = node;

  // If the path is a key:value pair, process as a sequence item.
  if (isKeyValue) {
    const [keyName, keyValue] = splitKeyValue(key);
    if (!(currentNode instanceof YAMLSeq)) {
      throw new Error(
        `Expected a sequence for the key '${keyName}', but got ${typeof currentNode}`,
      );
    }

    const index = findIndexOfMapByKeyValue(currentNode, keyName, keyValue);
    if (index === -1)
      throw new Error(`No element with '${keyName}: ${keyValue}' found in sequence`);
    currentNode.items[index] = updateAtPath(
      currentNode.items[index] as YAMLMap,
      remainingPath,
      newValue,
    );
    return currentNode;
  }

  // For direct keys, update the value or recurse deeper.
  if (remainingPath.length === 0) {
    currentNode.set(key, newValue);
  } else {
    const nextNode = currentNode.get(key);
    if (!nextNode) {
      throw new Error(`Key '${key}' not found.`);
    }
    currentNode.set(key, updateAtPath(nextNode as YAMLMap, remainingPath, newValue));
  }

  return currentNode;
};

export const modifyYaml = (
  yamlContent: string,
  path: string[],
  newValue: string,
  options: { convertHaystackSpecialCharacters: boolean } = {
    convertHaystackSpecialCharacters: false,
  },
): string => {
  const doc = parseDocument(yamlContent);
  if (!doc.contents) throw new Error('Failed to parse YAML content.');

  // Haystack uses folded block quotes (>) for multi-line strings, which is problematic when
  // serializing YAML with special characters (e.g., new lines). In the folded style, new lines
  // are converted to spaces, which can result in prompts displaying incorrectly as single lines.
  // Haystack utilizes its own special character tokens (e.g., {new_line}) to represent new lines.
  // To address this issue, this workaround converts Haystack's special characters to their actual
  // character form and uses literal block quotes (|) during serialization. This ensures
  // that multi-line strings with special characters are preserved correctly in the output.
  if (options.convertHaystackSpecialCharacters) traverseAndConvertPromptSpecialTokens(doc.contents);

  const updatedContents = updateAtPath(doc.contents as YAMLSeq | YAMLMap, path, newValue);
  if (!updatedContents) throw new Error(`Path "${path.join(' -> ')}" not found in YAML.`);

  return stringify(doc, {
    indent: 2,
    blockQuote: 'literal',
    lineWidth: 0,
  });
};

export const findYamlValue = (yamlContent: string, path: string[]): string | null => {
  const doc = parseDocument(yamlContent);
  if (!doc.contents) return null;

  const value = getValueAtPath(doc.contents as YAMLSeq | YAMLMap, path);
  if (!value) return null;

  return value.toString();
};

export const insertYamlValue = (
  yamlContent: string,
  path: string[],
  newValue: Record<string, unknown>,
): string => {
  const doc = parseDocument(yamlContent);
  if (!doc.contents) throw new Error('Failed to parse YAML content.');

  insertAtPath(doc.contents as YAMLSeq | YAMLMap, path, newValue);

  return stringify(doc, {
    indent: 2,
    blockQuote: 'literal',
    lineWidth: 0,
  });
};
