import React, { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import { StatusCodes } from '@constants/enum/common';
import {
  SETTINGS_FEEDBACK_TAGS_DESCRIPTION,
  SETTINGS_FEEDBACK_TAGS_LABEL,
  SETTINGS_FEEDBACK_TAGS_TITLE,
} from '@constants/pipeline-details';
import TagInput from '@components/tagInput/TagInput';
import styles from './pipelineSettings.module.scss';

interface IPipelineSettingsProps {
  pipelineId: string;
}

const PipelineSettings = ({ pipelineId }: IPipelineSettingsProps) => {
  const {
    pipelineFeedbackTagsByPipelineId,
    createPipelineFeedbackTagStatus,
    deletePipelineFeedbackTagStatus,
    getFeedbackTags,
    resetFeedbackTags,
    createFeedbackTag,
    deleteFeedbackTag,
  } = usePipelineFeedback();

  const { data: tags } = pipelineFeedbackTagsByPipelineId[pipelineId] ?? { data: [] };
  const isCreatingTag = createPipelineFeedbackTagStatus === StatusCodes.IN_PROGRESS;
  const isDeletingTag = deletePipelineFeedbackTagStatus === StatusCodes.IN_PROGRESS;

  useEffect(() => {
    resetFeedbackTags();
  }, []);

  useEffect(() => {
    if (!pipelineId) return;
    getFeedbackTags({ pipelineId });
  }, [pipelineId]);

  useEffectUpdateOnly(() => {
    if (
      createPipelineFeedbackTagStatus === StatusCodes.SUCCESS ||
      deletePipelineFeedbackTagStatus === StatusCodes.SUCCESS
    ) {
      getFeedbackTags({ pipelineId });
    }
  }, [createPipelineFeedbackTagStatus, deletePipelineFeedbackTagStatus]);

  const removeTag = (tagId: string) => {
    deleteFeedbackTag(pipelineId, tagId);
  };

  const handleAddingTag = (tagName: string) => {
    createFeedbackTag(pipelineId, tagName);
  };

  const renderFeedbackTags = () => {
    return (
      <div className={styles.formSection}>
        <div className={styles.formSection_header}>
          <h5>{SETTINGS_FEEDBACK_TAGS_TITLE}</h5>
          <div className={styles.formSection_header}>{SETTINGS_FEEDBACK_TAGS_DESCRIPTION}</div>
        </div>
        <div className={styles.formSection_tags}>
          <div className={styles.formSection_tags_label}>{SETTINGS_FEEDBACK_TAGS_LABEL}:</div>
          <TagInput
            disabled={isCreatingTag}
            onConfirm={(tagValue: string) => {
              handleAddingTag(tagValue);
            }}
          />
          {tags.map(({ name, tag_id: tagId }) => (
            <Tag
              className={styles.formTemplateItem_tags_tag}
              key={tagId}
              closable
              closeIcon={isDeletingTag ? <LoadingOutlined /> : null}
              onClose={(event) => {
                event.preventDefault();
                removeTag(tagId);
              }}
            >
              {name}
            </Tag>
          ))}
        </div>
      </div>
    );
  };

  return <div className={styles.container}>{renderFeedbackTags()}</div>;
};

export default PipelineSettings;
