import { DEFAULT_HISTORY_LIMIT } from '@constants/dashboard';
import { getWorkspace } from '@src/utils/store';
import { http } from './base-http';

/* Get workspace query statistics */
export function getWorkspaceStatisticApi(workspaceName: string = getWorkspace()) {
  return http.get(`/api/v1/workspaces/${workspaceName}/stats`);
}

/* Get day-wise count of search queries for the last month */
export function getSearchCountPerDayApi(workspaceName: string = getWorkspace()) {
  return http.get(`/api/v1/workspaces/${workspaceName}/search_count`);
}

/* Get history of search queries */
export interface IHistoryRequestData {
  workspaceName?: string;
  limit?: number;
}

export function getRequestsHistoryApi(params?: IHistoryRequestData) {
  const workspaceName = params?.workspaceName || getWorkspace();
  const paramsMap = {
    limit: params?.limit || DEFAULT_HISTORY_LIMIT,
  };

  return http.get(`/api/v1/workspaces/${workspaceName}/search_history`, { params: paramsMap });
}
