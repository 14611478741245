import React, { ReactNode, useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { formatNumberToLocaleString } from '@utils/math';
import { interpolateString, transformToTitleCase } from '@utils/string';
import {
  INDEXING_HEADERS,
  INDEXING_HEADERS_POPOVER_CONTENT,
  LOGS_LINK,
  STATISTIC_HEADERS,
} from '@constants/details-header';
import { PipelineDetailsTabsKeys } from '@constants/pipeline-details';
import { IPipelineIndexingData, IPipelineStatistics } from '@redux/types/types';
import ListCarousel from '@components/common/ListCarousel/ListCarousel';
import styles from './pipelineStatistics.module.scss';

interface IIndexingList {
  pending_tasks: number;
  failed_files: number;
  skipped_files: number;
  indexed_files: number;
}

interface IPipelineStatisticsComponent {
  pipelineStatistics: IPipelineStatistics;
  pipelineIndexingData: IPipelineIndexingData;
  setActiveTabPipelineDetailsPage?: (tab: string) => void;
}

const PipelineStatistics = (props: IPipelineStatisticsComponent) => {
  const { pipelineStatistics, pipelineIndexingData, setActiveTabPipelineDetailsPage } = props;
  const [statisticsOptions, setStatisticsOptions] = useState<
    { key: string; label: string | ReactNode; value: string | number | ReactNode }[]
  >([]);
  const [indexingOptions, setIndexingOptions] = useState<
    { key: string; label: string | ReactNode; value: string | number | ReactNode }[]
  >([]);

  useEffect(() => {
    const {
      pending_file_count: pendingFileCount,
      failed_file_count: failedFileCount,
      indexed_no_documents_file_count: indexedNoDocumentsFileCount,
      indexed_file_count: indexedFileCount,
    } = pipelineIndexingData;

    const indexingList: IIndexingList = {
      pending_tasks: pendingFileCount,
      failed_files: failedFileCount,
      skipped_files: indexedNoDocumentsFileCount,
      indexed_files: indexedFileCount,
    };

    const statistics: {
      key: string;
      label: string | ReactNode;
      value: string | number | ReactNode;
    }[] = [];
    const indexing: {
      key: string;
      label: string | ReactNode;
      value: string | number | ReactNode;
    }[] = [];

    Object.keys(indexingList).forEach((indexingItem) => {
      const option = {
        key: indexingItem,
        label: (
          <span className={styles.optionContent}>
            {INDEXING_HEADERS[indexingItem as keyof typeof INDEXING_HEADERS] ||
              transformToTitleCase(indexingItem)}
            <Popover
              rootClassName={styles.popover}
              content={interpolateString(
                INDEXING_HEADERS_POPOVER_CONTENT[
                  indexingItem as keyof typeof INDEXING_HEADERS_POPOVER_CONTENT
                ],
                {
                  logs: (
                    <Button
                      type="link"
                      className={styles.alertLink}
                      onClick={() => {
                        if (setActiveTabPipelineDetailsPage)
                          setActiveTabPipelineDetailsPage(PipelineDetailsTabsKeys.LOGS);
                      }}
                    >
                      {LOGS_LINK}
                    </Button>
                  ),
                },
              )}
            >
              <InfoCircleOutlined className={styles.optionContent_icon} />
            </Popover>
          </span>
        ),
        value: formatNumberToLocaleString(
          indexingList[indexingItem as keyof IIndexingList] as number,
          true,
        ),
      };
      indexing.push(option);
    });

    Object.keys(pipelineStatistics).forEach((statistic) => {
      if (statistic !== 'indexed_files' && statistic !== 'total_documents') {
        const option = {
          key: statistic,
          label:
            STATISTIC_HEADERS[statistic as keyof typeof STATISTIC_HEADERS] ||
            transformToTitleCase(statistic),
          value: formatNumberToLocaleString(
            pipelineStatistics[statistic as keyof IPipelineStatistics],
            true,
          ),
        };
        statistics.push(option);
      } else if (statistic === 'total_documents') {
        const option = {
          key: statistic,
          label: (
            <span className={styles.optionContent}>
              {INDEXING_HEADERS[statistic as keyof typeof INDEXING_HEADERS] ||
                transformToTitleCase(statistic)}
              <Popover
                rootClassName={styles.popover}
                content={
                  INDEXING_HEADERS_POPOVER_CONTENT[
                    statistic as keyof typeof INDEXING_HEADERS_POPOVER_CONTENT
                  ]
                }
              >
                <InfoCircleOutlined className={styles.optionContent_icon} />
              </Popover>
            </span>
          ),
          value: formatNumberToLocaleString(
            pipelineStatistics[statistic as keyof IPipelineStatistics],
            true,
          ),
        };
        indexing.push(option);
      }
    });
    setStatisticsOptions(statistics);
    setIndexingOptions(indexing);
  }, [pipelineStatistics, pipelineIndexingData]);

  return (
    <section className={styles.section}>
      <ListCarousel options={indexingOptions} hideBorderBottom />
      <ListCarousel options={statisticsOptions} />
    </section>
  );
};

export default PipelineStatistics;
