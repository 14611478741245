import React from 'react';
import { ButtonProps } from 'antd';

export enum FilterType {
  MULTI_SELECT = 'MULTI_SELECT',
  SELECT = 'SELECT',
  TOGGLE = 'TOGGLE',
  RANGE = 'RANGE',
  DATE = 'DATE',
}

export enum TitleActionOption {
  BUTTON = 'button',
  DROPDOWN = 'dropdown',
}

export type SelectedFilterItem<T extends FilterType = FilterType> = {
  label: string;
  key: string;
  value: T extends FilterType.RANGE
    ? number[]
    : T extends FilterType.SELECT
    ? unknown
    : T extends FilterType.TOGGLE
    ? boolean
    : string;
  labelPrefix?: string;
  type: FilterType;
};

export type SelectedFilters = Record<string, SelectedFilterItem[]>;

export type MultiSelectFilter = {
  type: FilterType.MULTI_SELECT;
  options: {
    key: string;
    label: string;
  }[];
};
export type SelectFilter = {
  type: FilterType.SELECT;
  options: {
    key: string;
    value?: unknown;
    label: string;
  }[];
  listHeight?: number;
  search?: boolean;
};
export type DateFilter = {
  type: FilterType.DATE;
  options: {
    key: string;
    value?: unknown;
    label: string;
  }[];
};
export type ToggleFilter = {
  type: FilterType.TOGGLE;
  option: {
    key: string;
    label: string;
  };
};
export type RangeFilter = {
  type: FilterType.RANGE;
  rangeBoundaries: { min: number; max: number };
  rangeStep?: number;
  option: {
    key: string;
    label: string;
  };
};
type BaseFilter = {
  key: string;
  title: string;
  style?: React.CSSProperties;
};

export type FilterProp<T extends FilterType = FilterType> = BaseFilter &
  (T extends FilterType.RANGE
    ? RangeFilter
    : T extends FilterType.SELECT
    ? SelectFilter
    : T extends FilterType.TOGGLE
    ? ToggleFilter
    : T extends FilterType.MULTI_SELECT
    ? MultiSelectFilter
    : DateFilter);

export type FiltersProp<T extends FilterType = FilterType> = FilterProp<T>[];

export type SelectAction = {
  type: ButtonProps['type'];
  label: string;
  danger?: boolean;
  onClick: (rowKeys: string[]) => void;
  isVisible?: (rowKeys: string[]) => boolean;
  popconfirm?: {
    title: string;
    cancelText?: string;
  };
};
