import React from 'react';
import NotFoundErrorSVG from '@assets/not-found-error.svg?react';
import {
  NOT_FOUND_PROTOTYPE_ERROR_DESCRIPTION,
  NOT_FOUND_PROTOTYPE_ERROR_LABEL,
} from '@constants/common';
import styles from './notFoundPrototypePage.module.scss';

const NotFoundPrototypePage = () => (
  <div className={styles.notFoundPrototypePage}>
    <div className={styles.notFoundPrototypePage_wrapper}>
      <NotFoundErrorSVG />
      <h3 className={styles.notFoundPrototypePage_wrapper_title}>
        {NOT_FOUND_PROTOTYPE_ERROR_LABEL}
      </h3>
      <div className={styles.notFoundPrototypePage_wrapper_subtitle}>
        {NOT_FOUND_PROTOTYPE_ERROR_DESCRIPTION}
      </div>
    </div>
  </div>
);

export default NotFoundPrototypePage;
