import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { LineChart as eLineChart, LineSeriesOption } from 'echarts/charts';
import {
  DataZoomComponent,
  DataZoomComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { getTimeSeriesChartTooltip, mergeTimeSeriesByDate } from '@utils/chart';
import { pngToPDF } from '@utils/file';
import { ChartTimeSeriesEntry, EChartAxisType } from '@redux/types/types';
import defaultTheme from '@styles/themes/default';
import styles from './chart.module.scss';

echarts.use([
  CanvasRenderer,
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  eLineChart,
  TooltipComponent,
  UniversalTransition,
]);

type EChartsOption = echarts.ComposeOption<
  | DataZoomComponentOption
  | GridComponentOption
  | LegendComponentOption
  | LineSeriesOption
  | TooltipComponentOption
>;

interface ILineChartProps {
  series: {
    label: string;
    data: ChartTimeSeriesEntry[] | null;
    color?: string;
  }[];
  complementaryData?: {
    data: ChartTimeSeriesEntry[] | null;
    label: string;
  };
  withArea?: boolean;
}

interface ILineChartExposedMethods {
  resize: () => void;
  download: (type: 'png' | 'jpeg' | 'pdf', fileName?: string) => void;
}

// eslint-disable-next-line react/display-name
const LineChart = forwardRef<ILineChartExposedMethods, ILineChartProps>(
  ({ series, complementaryData, withArea = true }: ILineChartProps, ref) => {
    const chartDivRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<echarts.ECharts>();

    const handleResize = () => {
      chartRef?.current?.resize();
    };

    useImperativeHandle(ref, () => ({
      resize() {
        handleResize();
      },
      download(type: 'png' | 'jpeg' | 'pdf', fileName?: string) {
        const dataUrlType = type === 'pdf' ? 'png' : type;
        const exportFileName = fileName || `lineChart`;
        const dataURL = chartRef.current?.getDataURL({
          type: dataUrlType,
          pixelRatio: 4,
          backgroundColor: defaultTheme!.token!.colorWhite!,
          excludeComponents: ['dataZoom'],
        });

        if (!dataURL) return;

        if (type === 'pdf') {
          const canvas = chartRef.current?.getDom();
          if (!canvas) return;
          const { clientWidth, clientHeight } = canvas;
          pngToPDF(dataURL, exportFileName, { width: clientWidth, height: clientHeight });
          return;
        }

        const a = document.createElement('a');
        a.href = dataURL!;
        a.download = `${exportFileName}.${type}`;
        a.click();
      },
    }));

    const getAreaStyle = () => {
      if (!withArea)
        return {
          color: 'none',
        };
      return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: defaultTheme!.token!.colorPrimary!,
          },
          {
            offset: 1,
            color: defaultTheme!.token!.colorWhite!,
          },
        ]),
      };
    };

    const initSeries = () => {
      return series.map(({ label, data, color }) => {
        return {
          name: label,
          type: 'line' as const,
          smooth: true,
          lineStyle: {
            color: color || defaultTheme!.token!.colorPrimary,
            width: 3,
          },
          symbolSize: 6,
          color: color || defaultTheme!.token!.colorPrimary,
          data: mergeTimeSeriesByDate(data, complementaryData?.data || null),
          areaStyle: getAreaStyle(),
        };
      });
    };

    const leftMargin = 0;
    const rightMargin = 10;
    const options: EChartsOption = {
      legend: {
        data: series.map(({ label }) => label),
        orient: 'horizontal',
        align: 'left',
        left: leftMargin,
        textStyle: {
          color: defaultTheme!.token!.colorTextSecondary,
        },
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => getTimeSeriesChartTooltip(params, complementaryData?.label),
      },
      dataZoom: [
        {
          start: 0,
          end: 100,
          showDetail: false,
          left: leftMargin,
          right: rightMargin,
        },
      ],
      xAxis: {
        type: EChartAxisType.TIME,
        axisLabel: {
          formatter: {
            day: '{MMM} {d}',
            month: '{MMMM}',
          },
        },
      },
      yAxis: {
        type: EChartAxisType.VALUE,
      },
      grid: {
        left: leftMargin,
        right: rightMargin,
        containLabel: true,
      },
      series: initSeries(),
    };

    useEffect(() => {
      echarts.use([eLineChart]);
      if (chartDivRef.current) chartRef.current = echarts.init(chartDivRef.current);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.setOption(options);
      }
    }, [series, withArea]);

    return <div ref={chartDivRef} className={styles.chart} />;
  },
);

export default LineChart;
