import React, { useMemo } from 'react';
import { Button, Select } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { debounce } from 'lodash';
import { CLEAR_FILTER_LABLE } from '@constants/data-page';
import { FilterType, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import RangeSlider from '@components/common/rangeSlider/RangeSlider';
import styles from './rangeFilter.module.scss';

const APPLY_FILTER_DEBOUNCE_DELAY = 400;

interface IRangeFilterProps {
  filterKey: string;
  title: string;
  filterValues: SelectedFilters;
  style?: React.CSSProperties;
  rangeBoundaries: { min: number; max: number };
  rangeStep?: number;
  option: {
    key: string;
    label: string;
  };
  onChange: (items: SelectedFilterItem[]) => void;
  showClearButton?: boolean;
}

const RangeFilter = ({
  filterKey,
  title,
  filterValues,
  option,
  style,
  showClearButton = false,
  onChange,
  rangeBoundaries,
  rangeStep,
}: IRangeFilterProps) => {
  const onApplyFilter = (rangeValues: [number, number]) => {
    const { key, label } = option;
    const [min, max] = rangeValues;

    onChange([{ key, label, value: [min, max], type: FilterType.RANGE }]);
  };

  const debouncedOnApplyFilter = useMemo(() => {
    return debounce((rangeValues) => onApplyFilter(rangeValues), APPLY_FILTER_DEBOUNCE_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearValues = () => {
    onChange([]);
  };

  const marks: SliderMarks = {
    [rangeBoundaries.min]: rangeBoundaries.min,
    [rangeBoundaries.max]: rangeBoundaries.max,
  };

  const { value: activeValue } = filterValues[filterKey]?.[0] || {};

  return (
    <Select
      key={filterKey}
      style={style}
      className={styles.rangeFilter}
      placeholder={title}
      dropdownStyle={{ minWidth: '260px', padding: 0 }}
      dropdownRender={() => (
        <div className={styles.rangeFilter_dropdown}>
          {showClearButton && (
            <Button
              className={styles.rangeFilter_dropdown_clear_button}
              type="link"
              onClick={onClearValues}
            >
              {CLEAR_FILTER_LABLE}
            </Button>
          )}
          <RangeSlider
            marks={marks}
            min={rangeBoundaries.min}
            max={rangeBoundaries.max}
            onChange={debouncedOnApplyFilter}
            values={activeValue as [number, number]}
            step={rangeStep}
          />
        </div>
      )}
    />
  );
};

export default RangeFilter;
