import { useDispatch, useSelector } from 'react-redux';
import {
  resetActiveTabYamlEditor as resetActiveTabYamlEditorAction,
  resetEditedCode as resetEditedCodeAction,
  setActiveTabYamlEditor as setActiveTabYamlEditorAction,
  setIndexingCode as setIndexingCodeAction,
  setIndexingYamlEditorDiff as setIndexingYamlEditorDiffAction,
  setQueryCode as setQueryCodeAction,
  setQueryYamlEditorDiff as setQueryYamlEditorDiffAction,
} from '@redux/actions/pipelineActions';
import {
  activeTabYamlEditorSelector,
  indexingCodeSelector,
  indexingYamlEditorDiffSelector,
  queryCodeSelector,
  queryYamlEditorDiffSelector,
} from '@redux/selectors/pipelineSelectors';
import { EditorYamlTabsKeys } from '@redux/types/types';

const useCodeEditor = () => {
  const dispatch = useDispatch();
  const activeTabYamlEditor: EditorYamlTabsKeys = useSelector(activeTabYamlEditorSelector);
  const indexingCode: string = useSelector(indexingCodeSelector);
  const queryCode: string = useSelector(queryCodeSelector);
  const indexingYamlEditorDiff: boolean = useSelector(indexingYamlEditorDiffSelector);
  const queryYamlEditorDiff: boolean = useSelector(queryYamlEditorDiffSelector);

  const setActiveTabYamlEditor = (activeTab: EditorYamlTabsKeys) => {
    dispatch(setActiveTabYamlEditorAction(activeTab));
  };

  const resetActiveTabYamlEditor = () => {
    dispatch(resetActiveTabYamlEditorAction);
  };

  const setIndexingCode = (code: string | null) => {
    dispatch(setIndexingCodeAction(code));
  };

  const setQueryCode = (code: string | null) => {
    dispatch(setQueryCodeAction(code));
  };

  const resetEditedCode = () => {
    dispatch(resetEditedCodeAction);
  };

  const setIndexingYamlEditorDiff = (diff: boolean) => {
    dispatch(setIndexingYamlEditorDiffAction(diff));
  };

  const setQueryYamlEditorDiff = (diff: boolean) => {
    dispatch(setQueryYamlEditorDiffAction(diff));
  };

  return {
    setActiveTabYamlEditor,
    resetActiveTabYamlEditor,
    activeTabYamlEditor,
    indexingCode,
    setIndexingCode,
    queryCode,
    setQueryCode,
    resetEditedCode,
    indexingYamlEditorDiff,
    setIndexingYamlEditorDiff,
    queryYamlEditorDiff,
    setQueryYamlEditorDiff,
  };
};

export default useCodeEditor;
