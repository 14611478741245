import React, { memo, ReactNode, useEffect, useRef, useState } from 'react';

interface TypingEffectProps {
  children: ReactNode;
  delay?: number;
}

const TypingEffect = ({ children, delay = 4 }: TypingEffectProps) => {
  const [displayedContent, setDisplayedContent] = useState<ReactNode>('');
  const typingProgress = useRef<number>(0);
  const lastContentLength = useRef<number>(0);

  const computeTotalLength = (node: ReactNode): number => {
    if (typeof node === 'string') {
      return node.length;
    }

    if (React.isValidElement(node) && node.props.children) {
      let total = 0;
      React.Children.forEach(node.props.children, (child) => {
        total += computeTotalLength(child);
      });
      return total;
    }

    return 0;
  };

  const recursiveClone = (node: ReactNode, index: number): [ReactNode, number] => {
    if (typeof node === 'string') {
      return [node.slice(0, index), index];
    }

    if (React.isValidElement(node) && node.props.children) {
      const newChildren: ReactNode[] = [];
      let remaining = index;

      React.Children.forEach(node.props.children, (child) => {
        if (remaining <= 0) return;
        const [childElement, used] = recursiveClone(child, remaining);
        newChildren.push(childElement);
        remaining -= used;
      });

      return [React.cloneElement(node, node.props, ...newChildren), index - remaining];
    }

    return [node, 0];
  };

  useEffect(() => {
    const totalLength = computeTotalLength(children);

    if (typingProgress.current < totalLength) {
      const intervalId = setInterval(() => {
        if (typingProgress.current >= totalLength) {
          clearInterval(intervalId);
          return;
        }
        typingProgress.current++;
        const [content] = recursiveClone(children, typingProgress.current);
        setDisplayedContent(content);
      }, delay);

      return () => clearInterval(intervalId);
    }
  }, [children, delay]);

  useEffect(() => {
    const currentLength = computeTotalLength(children);
    if (currentLength > lastContentLength.current) {
      typingProgress.current = lastContentLength.current;
      lastContentLength.current = currentLength;
    }
  }, [children]);

  return <>{displayedContent}</>;
};

export default memo(TypingEffect);
