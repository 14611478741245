import { createSelector } from 'reselect';
import { IWorkspaceItem } from '@redux/reducers/organizationReducer';

export const organizationStoreSelector = (state: any) => state.organizationStore;

export const organizationSelector = createSelector(
  organizationStoreSelector,
  (organizationStore) => organizationStore.organization,
);

export const organizationUsersSelector = createSelector(
  organizationStoreSelector,
  (organizationStore) => organizationStore.allUsers,
);

export const organizationMessageSelector = createSelector(
  organizationStoreSelector,
  (organizationStore) => organizationStore.message,
);

export const organizationConnectionSelector = createSelector(
  organizationStoreSelector,
  (organizationStore) => organizationStore.connections,
);

export const workspaceSelector = createSelector(organizationStoreSelector, (organizationStore) => ({
  workspaces: organizationStore.workspaces,
  currentWorkspace: organizationStore.currentWorkspace,
  currentWorkspaceId: organizationStore.workspaces.find(
    (workspace: IWorkspaceItem) => workspace.name === organizationStore.currentWorkspace,
  )?.workspace_id,
}));

export const addWorkspaceStatusSelector = createSelector(
  organizationStoreSelector,
  (organizationStore) => organizationStore.addWorkspaceStatus,
);

export const currentDeletingWorkspacesSelector = createSelector(
  organizationStoreSelector,
  (organizationStore) => organizationStore.currentDeletingWorkspaces,
);

export const currentWorkspaceDefaultIdleTimeoutSelector = createSelector(
  organizationStoreSelector,
  (organizationStore) => organizationStore.currentWorkspaceDefaultIdleTimeout,
);
