import React from 'react';
import { Button, Input } from 'antd';
import {
  WORKSPACE_CREATE_BUTTON_LABEL,
  WORKSPACE_INPUT_PLACEHOLDER,
} from '@constants/sidebar-menu';
import styles from './newWorkspaceMenuItem.module.scss';

interface INewWorkspaceMenuItemProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onAdd: () => void;
}

const NewWorkspaceMenuItem = ({ value, onChange, onAdd }: INewWorkspaceMenuItemProps) => {
  return (
    <div className={styles.newWorkspace}>
      <Input placeholder={WORKSPACE_INPUT_PLACEHOLDER} value={value} onChange={onChange} />
      <Button
        type="primary"
        className={styles.newWorkspace_addButton}
        disabled={value.length < 3}
        onClick={onAdd}
      >
        {WORKSPACE_CREATE_BUTTON_LABEL}
      </Button>
    </div>
  );
};

export default NewWorkspaceMenuItem;
