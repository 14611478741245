import React from 'react';
import { theme } from 'antd';

const { useToken } = theme;

const SearchSVG = () => {
  const { token } = useToken();

  return (
    <svg
      width="301"
      height="301"
      viewBox="0 0 301 301"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_512_21831)">
        <path
          d="M206.5 213.363H90.9805C81.2704 213.363 73.3708 205.464 73.3708 195.754V80.2344C73.3708 70.5243 81.2704 62.6248 90.9805 62.6248H206.5C216.21 62.6248 224.109 70.5243 224.109 80.2344V195.754C224.109 205.464 216.21 213.363 206.5 213.363V213.363ZM90.9805 65.4423C82.811 65.4423 76.1884 72.0649 76.1884 80.2344V195.754C76.1884 203.923 82.811 210.546 90.9805 210.546H171.899C199.178 210.546 221.292 188.432 221.292 161.153V80.2344C221.292 72.0649 214.669 65.4423 206.5 65.4423H90.9805V65.4423Z"
          fill="#F0F2F5"
        />
        <path
          d="M103.435 77.455C97.9672 77.455 93.5194 81.9027 93.5194 87.3702V102.89C93.5194 108.357 97.9672 112.805 103.435 112.805H118.954C124.422 112.805 128.869 108.357 128.869 102.89V87.3702C128.869 81.9027 124.422 77.455 118.954 77.455H103.435Z"
          fill="#F0F2F5"
        />
        <path
          d="M103.435 121.144C97.9672 121.144 93.5194 125.592 93.5194 131.06V146.579C93.5194 152.046 97.9672 156.494 103.435 156.494H118.954C124.422 156.494 128.869 152.046 128.869 146.579V131.06C128.869 125.592 124.422 121.144 118.954 121.144H103.435V121.144Z"
          fill="#F0F2F5"
        />
        <path
          d="M103.435 164.834C97.9672 164.834 93.5194 169.281 93.5194 174.749V190.268C93.5194 195.736 97.9672 200.184 103.435 200.184H118.954C124.422 200.184 128.869 195.736 128.869 190.268V174.749C128.869 169.281 124.422 164.834 118.954 164.834H103.435Z"
          fill="#F0F2F5"
        />
        <path
          d="M113.726 39.5909C106.837 39.5909 101.233 45.1953 101.233 52.0844V71.6394C101.233 78.5285 106.837 84.1329 113.726 84.1329H133.281C140.17 84.1329 145.775 78.5285 145.775 71.6394V52.0844C145.775 45.1953 140.17 39.5909 133.281 39.5909H113.726V39.5909Z"
          fill={token.colorPrimary}
        />
        <path
          d="M178.106 94.239C171.217 94.239 165.613 99.8434 165.613 106.732V126.287C165.613 133.177 171.217 138.781 178.106 138.781H197.661C204.55 138.781 210.155 133.177 210.155 126.287V106.732C210.155 99.8434 204.55 94.239 197.661 94.239H178.106Z"
          fill={token.colorPrimary}
        />
        <path
          d="M150.117 291.068L146.144 291.034L144.386 275.354L150.249 275.403L150.117 291.068Z"
          fill="#A0616A"
        />
        <path
          d="M137.392 297.385C137.384 298.363 138.172 299.168 139.153 299.177L147.052 299.243L148.434 296.44L148.942 299.257L151.922 299.284L151.17 289.236L150.133 289.167L145.904 288.876L144.539 288.784L144.515 291.627L138.17 295.931C137.688 296.259 137.397 296.802 137.392 297.385H137.392Z"
          fill="#2F2E41"
        />
        <path
          d="M170.329 291.068L166.357 291.034L164.598 275.354L170.461 275.403L170.329 291.068Z"
          fill="#A0616A"
        />
        <path
          d="M157.604 297.385C157.596 298.363 158.384 299.168 159.365 299.177L167.264 299.243L168.647 296.44L169.154 299.257L172.135 299.284L171.382 289.236L170.345 289.167L166.116 288.876L164.752 288.784L164.728 291.627L158.383 295.931C157.9 296.259 157.609 296.802 157.604 297.385V297.385Z"
          fill="#2F2E41"
        />
        <path
          d="M134.528 164.293L150.631 164.802L165.886 168.192C165.886 168.192 164.361 170.056 169.446 175.142C169.446 175.142 176.226 187.007 171.48 198.533L169.446 244.639C169.446 244.639 175.377 277.14 170.845 287.484L164.856 287.858L154.723 245.014L149.783 198.872L147.41 244.3C147.41 244.3 156.489 275.48 150.756 287.858L144.27 287.484L133.172 244.3C133.172 244.3 128.426 194.465 129.104 184.973C129.782 175.481 134.528 164.293 134.528 164.293H134.528Z"
          fill="#2F2E41"
        />
        <path
          d="M188.605 91.4395C186.404 92.0094 185.082 94.2561 185.652 96.4574C185.913 97.4661 186.528 98.2867 187.328 98.8288L187.328 98.8344L187.015 106.398L192.804 109.077L193.266 97.4374L193.225 97.4386C193.727 96.5445 193.901 95.4642 193.623 94.3932C193.053 92.1919 190.807 90.8695 188.605 91.4395V91.4395Z"
          fill="#A0616A"
        />
        <path
          d="M156.254 118.435C155.038 117.23 154.333 115.595 154.306 113.818C154.271 111.435 155.466 109.271 157.502 108.031C159.847 106.603 162.779 106.747 164.973 108.399L183.149 122.084L186.388 102.369L193.341 104.607L191.682 130.279C191.566 132.075 190.551 133.657 188.967 134.511C187.383 135.365 185.504 135.343 183.94 134.454L157.672 119.509C157.145 119.209 156.67 118.847 156.254 118.435V118.435Z"
          fill="#3F3D56"
        />
        <path
          d="M128.538 80.4118C129.7 82.3664 129.058 84.8929 127.103 86.0549C126.208 86.5873 125.193 86.7367 124.243 86.5588L124.239 86.5629L119.136 92.1543L113.139 89.9813L121.006 81.3901L121.034 81.4193C121.307 80.431 121.944 79.5418 122.895 78.9765C124.85 77.8145 127.376 78.4571 128.538 80.4118V80.4118Z"
          fill="#A0616A"
        />
        <path
          d="M130.743 122.124L101.567 114.243C99.8299 113.773 98.4804 112.465 97.9568 110.744C97.4331 109.022 97.8263 107.184 99.008 105.827L115.904 86.4285L122.418 89.7345L110.838 106.015L133.381 109.094C136.101 109.465 138.285 111.427 138.945 114.093C139.518 116.407 138.843 118.785 137.14 120.452C135.87 121.696 134.218 122.36 132.506 122.359C131.921 122.359 131.329 122.282 130.743 122.124H130.743Z"
          fill="#3F3D56"
        />
        <path
          d="M172.338 171.076L131.539 172.948C130.419 171.246 130.722 168.687 132.291 165.483C138.216 153.386 133.717 130.5 130.69 118.583C129.84 115.24 132.12 111.916 135.553 111.572L139.4 111.188L142.956 100.333H155.409L160.576 106.236L169.344 111.562C167.748 128.001 160.948 151.215 170.635 162.856C172.754 165.402 173.355 168.156 172.338 171.076V171.076Z"
          fill="#3F3D56"
        />
        <path
          d="M147.598 100.584C154.298 100.584 159.729 95.1528 159.729 88.4535C159.729 81.7541 154.298 76.3232 147.598 76.3232C140.899 76.3232 135.468 81.7541 135.468 88.4535C135.468 95.1528 140.899 100.584 147.598 100.584Z"
          fill="#A0616A"
        />
        <path
          d="M161.325 80.689C163.012 74.5358 144.618 69.2594 141.044 74.2145C140.547 73.5101 138.71 73.0822 137.867 73.2983C137.024 73.5144 136.315 74.0546 135.624 74.5776C134.673 75.308 133.688 76.0644 133.104 77.1146C132.516 78.1606 132.426 79.5869 133.217 80.4945C133.843 81.2162 134.944 85.0517 135.887 85.2419C136.544 85.3759 137.097 85.484 137.572 85.5617C137.996 84.9437 139.076 84.1673 138.998 83.4196C139.638 83.8432 139.404 84.5884 139.275 85.35C138.846 87.8861 129.171 107.518 134.712 101.733C135.261 102.057 135.939 102.36 136.722 102.636C138.049 100.635 139.138 98.2753 139.855 95.8074L139.86 95.853C140.107 97.9977 141.675 99.7575 143.748 100.358C152.066 102.768 158.795 99.232 161.109 92.8553C160.262 91.1351 159.893 91.322 159.969 91.2708C161.026 90.5579 162.48 91.0242 162.935 92.2159C163.069 92.5673 163.191 92.8673 163.296 93.093C164.502 88.7796 165.936 89.4022 161.325 80.689H161.325Z"
          fill="#2F2E41"
        />
        <path
          d="M155.586 76.2247L154.737 71.8808C154.665 71.5156 154.597 71.1195 154.762 70.7858C154.974 70.3571 155.517 70.1794 155.991 70.2477C156.464 70.3161 156.818 70.7483 157.288 70.8315C158.925 71.1208 161.089 69.5042 161.675 73.5941C161.92 75.2993 164.641 75.4794 165.549 76.9429C166.458 78.4064 166.57 80.5188 165.329 81.7144C164.339 82.6693 162.748 82.7795 161.462 82.2917C160.175 81.8039 159.143 80.8187 158.241 79.7797C157.339 78.7407 156.523 77.6163 155.482 76.7159"
          fill="#2F2E41"
        />
        <path
          d="M161.973 82.8316C158.653 82.3679 156.391 81.1608 155.248 79.2439C153.752 76.7347 154.754 73.9719 154.798 73.8555L155.499 74.1163C155.49 74.1417 154.581 76.6708 155.895 78.8671C156.917 80.5755 158.997 81.6601 162.076 82.0903L161.973 82.8316V82.8316Z"
          fill={token.colorPrimary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M159.851 18.1978C159.851 27.9557 167.791 35.8955 177.549 35.8955C181.178 35.8955 184.556 34.7973 187.367 32.9157L202.359 46.9653C203.041 47.6065 203.911 47.9228 204.778 47.9228C205.722 47.9228 206.664 47.5477 207.36 46.8028C208.698 45.377 208.625 43.1371 207.198 41.8011L192.352 27.8879C194.181 25.1024 195.247 21.7721 195.247 18.1978C195.247 8.43979 187.307 0.5 177.549 0.5C167.791 0.5 159.851 8.43979 159.851 18.1978ZM166.93 18.1978C166.93 12.3423 171.693 7.57911 177.549 7.57911C183.404 7.57911 188.167 12.3423 188.167 18.1978C188.167 24.0532 183.404 28.8164 177.549 28.8164C171.693 28.8164 166.93 24.0532 166.93 18.1978Z"
          fill={token.colorPrimary}
        />
      </g>
      <defs>
        <clipPath id="clip0_512_21831">
          <rect width="300" height="300" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchSVG;
