import React, { useEffect } from 'react';
import { Alert, Switch, Tabs } from 'antd';
import { DIFF_SWITCH_LABEL, EDITOR_YAML_TABS_LABELS } from '@constants/pipeline-designer';
import { EditorYamlTabsKeys } from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import MultipleErrorsPopup from '@components/multipleErrosPopup/MultipleErrorsPopup';
import usePipelineEditor from '@pages/pipelineEditorV2/hooks/usePipelineEditor';
import styles from './codeEditor.module.scss';
import useCodeEditor from '../../hooks/useCodeEditor';

const YamlEditor = React.lazy(
  () => import(/* webpackChunkName: "YamlEditor" */ '@components/yamlEditor/YamlEditor'),
);

function CodeEditor() {
  const {
    indexingYaml,
    queryYaml,
    editedIndexingYaml,
    editedQueryYaml,
    activeTabYamlEditor,
    warningMessage,
    onDidAttemptReadOnlyEditCode,
    isDesignerReadOnly,
    pipelineErrors,
    storeCode,
    selectedYaml,
    setSelectedYaml,
    getEditorCode,
    getOriginalCode,
  } = usePipelineEditor();

  const {
    setActiveTabYamlEditor,
    setIndexingCode,
    setQueryCode,
    indexingYamlEditorDiff,
    setIndexingYamlEditorDiff,
    queryYamlEditorDiff,
    setQueryYamlEditorDiff,
  } = useCodeEditor();

  useEffect(() => {
    if (indexingYaml && activeTabYamlEditor === EditorYamlTabsKeys.INDEXING_YAML)
      setSelectedYaml(indexingYaml);
    else if (queryYaml && activeTabYamlEditor === EditorYamlTabsKeys.QUERY_YAML)
      setSelectedYaml(queryYaml);
  }, [activeTabYamlEditor, indexingYaml, queryYaml]);

  useEffect(() => {
    setIndexingCode(indexingYaml);
    setIndexingYamlEditorDiff(false);
    setQueryCode(queryYaml);
    setQueryYamlEditorDiff(false);
  }, [indexingYaml, queryYaml]);

  return (
    <>
      <div className={styles.yaml}>
        <div className={styles.tabContainer}>
          <Tabs
            className={styles.tabs}
            activeKey={activeTabYamlEditor}
            onChange={(activeKey) => setActiveTabYamlEditor(activeKey as EditorYamlTabsKeys)}
            items={EDITOR_YAML_TABS_LABELS.map((tab) => {
              return {
                label: tab.label,
                key: tab.key,
              };
            })}
            type="card"
            tabBarExtraContent={
              <>
                {editedIndexingYaml && activeTabYamlEditor === EditorYamlTabsKeys.INDEXING_YAML && (
                  <span className={styles.diffButton}>
                    {DIFF_SWITCH_LABEL}
                    <Switch
                      checked={indexingYamlEditorDiff}
                      size="small"
                      onChange={() => setIndexingYamlEditorDiff(!indexingYamlEditorDiff)}
                    />
                  </span>
                )}
                {editedQueryYaml && activeTabYamlEditor === EditorYamlTabsKeys.QUERY_YAML && (
                  <span className={styles.diffButton}>
                    {DIFF_SWITCH_LABEL}
                    <Switch
                      checked={queryYamlEditorDiff}
                      size="small"
                      onChange={() => setQueryYamlEditorDiff(!queryYamlEditorDiff)}
                    />
                  </span>
                )}
              </>
            }
          />
          {warningMessage.content && (
            <Alert
              message={warningMessage.content}
              type={warningMessage.type}
              banner
              className={styles.warningMessage}
            />
          )}

          <div className={styles.yaml_codeEditor}>
            <React.Suspense fallback={<LoadingIndicator />}>
              <YamlEditor
                originalCode={getOriginalCode() ? selectedYaml : ''}
                code={getEditorCode()}
                onStore={storeCode}
                readOnly={isDesignerReadOnly()}
                isV2
                onDidAttemptReadOnlyEditCode={() => onDidAttemptReadOnlyEditCode()}
                newEditorTab={setActiveTabYamlEditor as unknown as string}
              />
            </React.Suspense>
            {pipelineErrors.length > 0 && <MultipleErrorsPopup errors={pipelineErrors} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default CodeEditor;
