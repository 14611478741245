/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { DownOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  MenuProps,
  PaginationProps,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { isEmpty, isNil } from 'lodash';
import { initFiltersValues, resetFilterValues } from '@utils/dataFilters';
import { interpolateString } from '@utils/string';
import { CacheKey, getUserPreference, setUserPreference } from '@utils/userPreferences';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import {
  CANCEL_BUTTON_LABEL,
  CONFIRM_BUTTON_LABEL,
  EDIT_COLUMNS_TOOLTIP,
  MAX_NUMBER_OF_PAGES_ON_PAGINATION,
  TOTAL_ITEMS_TABLE,
  TOTAL_ITEMS_TABLE_PERPAGE,
} from '@constants/data-page';
import {
  FiltersProp,
  SelectAction,
  SelectedFilterItem,
  SelectedFilters,
  TitleActionOption,
} from '@constants/data-table';
import { MetadataFiltersType, PipelineServiceLevel } from '@redux/types/types';
import AppliedFiltersList from '@components/appliedFilterList/AppliedFiltersList';
import DataHeaderActions from '@components/dataHeaderActions/DataHeaderActions';
import styles from './dataTable.module.scss';

const AFTER_NEW_ROW_ANIMATION_DELAY = 1000;

interface IDataTableProps {
  data: any;
  columns: any[];
  border?: boolean;
  id?: string;
  total?: number;
  loading?: boolean;
  refetch?: boolean;
  getData?: (
    currentPage: number,
    pageSize: number,
    searchValue: string,
    sortValue?: string,
    filterValues?: SelectedFilters,
    after?: Record<string, unknown>,
    metadataFilterValues?: Record<string, MetadataFiltersType>,
  ) => void;
  primaryAction?: {
    label: string;
    onClick: () => void;
    secondary?: boolean;
    icon?: React.ReactNode;
    disabled?: boolean;
    testid?: string;
  };
  filtersAction?: {
    label?: string;
    onClick: (payload?: {
      currentPage: number;
      pageSize: number;
      searchValue: string;
      sortValue?: string;
      filterValues?: SelectedFilters;
      after?: Record<string, unknown>;
    }) => void;
    filtersApplied?: boolean;
    secondary?: boolean;
    icon?: React.ReactNode;
    tooltipMessage?: string;
    disabled?: boolean;
    testid?: string;
    style?: React.CSSProperties;
  };
  appliedMetaFilterValues?: Record<string, MetadataFiltersType>;
  cleanedMetaFilterValues?: boolean;
  searchPlaceholder?: string;
  onRowClick?: (idx: number | undefined) => void;
  rowKey?: string;
  rowSelection?: any; // TODO: Add type, as rowSelection cannot be boolean
  rowDisabled?: (record: Record<string, unknown>) => boolean;
  scroll?: any;
  searchAvailable?: boolean;
  locale?: any;
  columnsConfig?: {
    optionalColumns: { key: string; title: string }[];
    defaultColumns: string[];
    cacheLocation?: CacheKey;
  };
  pagination?: {
    pageSize: number;
    cursorPagination?: boolean;
    currentPageNumber?: number;
  };
  polling?: {
    enabled: boolean;
    startPolling: (
      currentPage: number,
      pageSize: number,
      searchValue: string,
      sortValue?: string,
      filterValues?: SelectedFilters,
    ) => void;
    stopPolling: () => void;
  };
  sorting?: {
    selectedValue?: string;
    options: {
      key: string;
      label: string;
    }[];
    allowClear?: boolean;
  };
  filters?: FiltersProp;
  selectedFiltersValues?: SelectedFilters;
  selectActions?: SelectAction[];
  userEventsTrackingHandlers?: {
    onSearch?: (value: string) => void;
    onPageChange?: (page: number) => void;
    onSort?: (value: string) => void;
    onFilter?: (filterKey: string) => void;
    onClearFilter?: (filterKey: string) => void;
    onClearAllFilters?: () => void;
  };
  testId?: string;
  titleLabel?: string;
  titleAction?: {
    label: string;
    onClick?: () => void;
    actionType?: TitleActionOption;
    secondary?: boolean;
    icon?: React.ReactNode;
    disabled?: boolean;
    testid?: string;
    dropdownMenu?: { label: string; key: string }[];
    handleMenuClick?: (menuKey: string) => void;
  };
  setTotalSelectedItems?: (total: number) => void;
  rowAnimation?: {
    newRowName?: string;
    afterNewRowAnimation?: () => void;
  };
  surSecondaryActions?: React.ReactNode;
}

// TODO: Refactor logic (to many entangled use effects), keep component generic - https://github.com/deepset-ai/haystack-hub-ui/issues/2434
// TODO: Add component tests - https://github.com/deepset-ai/haystack-hub-ui/issues/2433
const DataTable = (props: IDataTableProps) => {
  const {
    data,
    columns,
    id = '',
    total = 0,
    loading = false,
    refetch,
    border = false,
    getData,
    primaryAction,
    filtersAction,
    appliedMetaFilterValues,
    cleanedMetaFilterValues,
    searchPlaceholder = 'Type to search',
    onRowClick,
    rowKey = 'id',
    rowSelection = null,
    rowDisabled,
    scroll,
    searchAvailable = true,
    selectActions,
    locale = {},
    columnsConfig = {
      optionalColumns: [],
      defaultColumns: [],
    },
    pagination = {
      pageSize: 10,
      cursorPagination: false,
    },
    polling = {
      enabled: false,
      startPolling: () => {},
      stopPolling: () => {},
    },
    sorting,
    filters,
    selectedFiltersValues,
    userEventsTrackingHandlers,
    testId,
    titleLabel,
    titleAction,
    setTotalSelectedItems,
    rowAnimation,
    surSecondaryActions,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [pageSize, setPageSize] = useState(pagination!.pageSize);
  const [selectedRowKeys, setSelectedRowKeys]: any[] = useState([]);
  const [hiddenColumns, setHiddenColumns]: any[] = useState(
    columnsConfig!.optionalColumns
      .map((optionalColumn) => optionalColumn.key)
      .filter((optionalColumnKey) => !columnsConfig!.defaultColumns.includes(optionalColumnKey)),
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortValue, setSortValue] = useState<string>('');
  const [filterValues, setFilterValues] = useState<SelectedFilters>(selectedFiltersValues || {});
  const [columnFilterVisible, setColumnFilterVisible] = useState(false);
  const [lastElementPerPage, setLastElementPerPage] = useState<Record<number, any>>({});
  const [previousData, setPreviousData] = useState<any[]>([]);
  const [loadingCursorBasedPagination, setLoadingCursorBasedPagination] = useState(false);
  const loadingState = loading || loadingCursorBasedPagination;

  const getMetadataFilters = () => {
    return appliedMetaFilterValues && Object.keys(appliedMetaFilterValues).length
      ? appliedMetaFilterValues
      : undefined;
  };

  const getDataCursorPagination = async (jumps: number, lastData: any, prevPage: number) => {
    setLoadingCursorBasedPagination(true);
    let pageNumber = prevPage;
    const after = lastData[lastData.length - 1];
    const metadataFilterValues = getMetadataFilters();

    if (getData && jumps > 0) {
      const dataResponse: any = await getData(
        currentPage,
        pageSize,
        searchValue,
        sortValue,
        filterValues,
        after,
        metadataFilterValues,
      );
      const newData = dataResponse.payload.data;
      if (!newData || newData.length === 0) {
        setLoadingCursorBasedPagination(false);
        return;
      }
      pageNumber += 1;
      setLastElementPerPage((prev) => ({
        ...prev,
        [pageNumber]: { ...newData[newData.length - 1] },
      }));
      getDataCursorPagination(jumps - 1, newData, pageNumber);
    } else setLoadingCursorBasedPagination(false);
  };

  const getSimpleDataCursorPagination = () => {
    const after = lastElementPerPage[currentPage - 1];
    const metadataFilterValues = getMetadataFilters();

    if (!loading && getData)
      getData(
        currentPage,
        pageSize,
        searchValue,
        sortValue,
        filterValues,
        after,
        metadataFilterValues,
      );
  };

  useEffect(() => {
    if (setTotalSelectedItems) setTotalSelectedItems(selectedRowKeys.length);
  }, [selectedRowKeys]);

  useEffect(() => {
    if (pagination?.currentPageNumber) setCurrentPage(pagination.currentPageNumber);
  }, [pagination?.currentPageNumber]);

  useEffect(() => {
    if (pagination?.cursorPagination)
      setLastElementPerPage((prev) => ({
        ...prev,
        [currentPage]: { ...data[data.length - 1] },
      }));
  }, [data]);

  useEffect(() => {
    if (!loading && getData) {
      const jumps = currentPage - previousPage;
      const isFirstPage = currentPage === 1 || !pagination?.cursorPagination;
      const useCursorPaginationForward =
        pagination?.cursorPagination && currentPage > 1 && jumps > 0;
      const useCursorPaginationBack = jumps < 0 && pagination?.cursorPagination;
      const metadataFilterValues = getMetadataFilters();
      let after;

      if (isFirstPage) {
        getData(
          currentPage,
          pageSize,
          searchValue,
          sortValue,
          filterValues,
          after,
          metadataFilterValues,
        );
        return;
      }

      if (useCursorPaginationForward) {
        if (lastElementPerPage[currentPage - 1]) {
          getSimpleDataCursorPagination();
        } else {
          setPreviousData(data);
          getDataCursorPagination(jumps, data, previousPage);
        }
        return;
      }

      if (useCursorPaginationBack) {
        getSimpleDataCursorPagination();
      }
    }
  }, [currentPage, searchValue, sortValue, filterValues]);

  useEffectUpdateOnly(() => {
    if (refetch && getData) {
      const metadataFilterValues = getMetadataFilters();
      let after;
      getData(
        currentPage,
        pageSize,
        searchValue,
        sortValue,
        filterValues,
        after,
        metadataFilterValues,
      );
    }
  }, [refetch]);

  useEffectUpdateOnly(() => {
    const metadataFilterValues = getMetadataFilters();
    let after;

    if (currentPage === 1 && getData) {
      getData(
        currentPage,
        pageSize,
        searchValue,
        sortValue,
        filterValues,
        after,
        metadataFilterValues,
      );
    }
    setCurrentPage(1);
  }, [id]);

  useEffectUpdateOnly(() => {
    const metadataFilterValues = getMetadataFilters();
    let after;
    setLastElementPerPage({});
    setCurrentPage(1);
    if (getData)
      getData(
        currentPage,
        pageSize,
        searchValue,
        sortValue,
        filterValues,
        after,
        metadataFilterValues,
      );
  }, [pageSize]);

  // Polling
  useEffect(() => {
    const { enabled, startPolling, stopPolling } = polling!;
    if (enabled) {
      startPolling(currentPage, pageSize, searchValue, sortValue, filterValues);
    }
    return () => {
      stopPolling();
    };
  }, [currentPage, pageSize, searchValue, filterValues, sortValue]);

  // Filters
  useEffect(() => {
    const initFilterValuesAux = initFiltersValues(filters, filterValues);
    if (isEmpty(initFilterValuesAux)) return;
    setFilterValues({ ...initFilterValuesAux });
  }, [filters?.length, id]);

  useEffect(() => {
    setSortValue('');
  }, [sorting]);

  useEffect(() => {
    if (appliedMetaFilterValues || cleanedMetaFilterValues) setCurrentPage(1);
  }, [appliedMetaFilterValues, cleanedMetaFilterValues]);

  // Check if the last item deleted on the table
  useEffect(() => {
    if (total && total > 0 && data.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [data && data.length, total]);

  const onPageChange = (page: number) => {
    setPreviousPage(currentPage);
    setCurrentPage(page);
    setSelectedRowKeys([]);

    if (userEventsTrackingHandlers?.onPageChange) userEventsTrackingHandlers.onPageChange(page);
  };

  const onShowSizeChange = (current: number, newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onSelectChange = (keys: any) => {
    setSelectedRowKeys(keys);
  };

  const defaultRowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onClickSearch = (value: string) => {
    setCurrentPage(1);
    setSearchValue(value);

    if (userEventsTrackingHandlers?.onSearch) userEventsTrackingHandlers.onSearch(value);
  };

  const onSortSelectChange = (value: string) => {
    setCurrentPage(1);
    setSortValue(value);

    if (userEventsTrackingHandlers?.onSort) userEventsTrackingHandlers.onSort(value);
  };

  const onFilterSelectChange = (filterKey: string, items: SelectedFilterItem[]) => {
    setCurrentPage(1);
    setFilterValues((values) => ({ ...values, [filterKey]: items }));

    if (userEventsTrackingHandlers?.onClearFilter && !items.length) {
      userEventsTrackingHandlers.onClearFilter(filterKey);
      return;
    }
    if (userEventsTrackingHandlers?.onFilter) userEventsTrackingHandlers.onFilter(filterKey);
  };

  const onClearAllFilters = () => {
    const resetFilterValuesAux = resetFilterValues(filters);
    setFilterValues({ ...resetFilterValuesAux });

    if (userEventsTrackingHandlers?.onClearAllFilters)
      userEventsTrackingHandlers.onClearAllFilters();
  };

  const handleOnClickMetadataFilters = () => {
    filtersAction?.onClick({
      currentPage: 1,
      pageSize,
      searchValue: '',
      sortValue,
      filterValues,
    });
  };

  const getRowProps = (record: Record<string, unknown>, idx?: number) => {
    if (rowDisabled && rowDisabled(record))
      return {
        className: styles.disabledRow,
        onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
          event.stopPropagation();
        },
      };

    return {
      onClick: () => onRowClick && onRowClick(idx),
      className: onRowClick && styles.cursorPointer,
    };
  };

  const getRowStyles = (idx: number) => {
    const afterNewRowAnimationHandler = () => {
      if (rowAnimation?.afterNewRowAnimation) rowAnimation.afterNewRowAnimation();
    };

    if (rowAnimation && data[idx]?.name === rowAnimation?.newRowName) {
      setTimeout(() => {
        afterNewRowAnimationHandler();
      }, AFTER_NEW_ROW_ANIMATION_DELAY);
      return styles.fadeInRow;
    }

    if (data[idx]?.service_level && data[idx].service_level === PipelineServiceLevel.PRODUCTION)
      return styles.greenRow;
    return '';
  };

  const renderTitleLabel = () => {
    const handleMenuDropdownClick: MenuProps['onClick'] = (e) => {
      if (titleAction && titleAction.handleMenuClick) titleAction.handleMenuClick(e.key);
    };

    return titleLabel ? (
      <div>
        <div className={styles.titleLabel_container}>
          <span className={styles.titleLabel_text}>{titleLabel}</span>
          {titleAction && !titleAction.actionType && (
            <Button
              type={titleAction.secondary ? 'default' : 'primary'}
              onClick={titleAction.onClick}
              icon={titleAction.icon ? titleAction.icon : <PlusOutlined />}
              disabled={titleAction.disabled}
              data-testid={titleAction.testid}
            >
              {titleAction.label}
            </Button>
          )}
          {titleAction && titleAction.actionType === TitleActionOption.DROPDOWN && (
            <Dropdown
              disabled={titleAction.disabled}
              data-testid={titleAction.testid}
              menu={{
                items: titleAction.dropdownMenu,
                onClick: handleMenuDropdownClick,
              }}
              trigger={['click']}
            >
              <Button type={titleAction.secondary ? 'default' : 'primary'}>
                <Space>
                  {titleAction.label}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
        </div>
        <Divider className={styles.horizontalDivider} />
      </div>
    ) : null;
  };

  const renderTotalItemsHeader = () => {
    return total && data.length > 0 ? (
      <div className={styles.totalItems}>
        <div className={styles.totalItems_label}>
          <span className={styles.totalItems_label_subtitle}>
            {interpolateString(TOTAL_ITEMS_TABLE_PERPAGE, {
              totalPerPage: data.length > 0 ? data.length : '',
            })}
          </span>
          <span className={styles.totalItems_label_total}>
            {interpolateString(TOTAL_ITEMS_TABLE, {
              totalItems: total || '',
            })}
          </span>
        </div>
      </div>
    ) : null;
  };

  const columnOptions = () => {
    const { optionalColumns } = columnsConfig!;
    if (!optionalColumns?.length) return null;

    const handleVisibleChange = (flag: boolean, info: { source: 'trigger' | 'menu' }) => {
      const { source } = info;
      if (source !== 'menu') setColumnFilterVisible(flag);
    };

    const updateDefaultColumns = (key: string) => {
      const { defaultColumns, cacheLocation } = columnsConfig!;
      if (!cacheLocation) return;
      let currentColumns = getUserPreference(cacheLocation) || defaultColumns;
      if (currentColumns.includes(key)) {
        currentColumns = currentColumns.filter((column: string) => column !== key);
      } else {
        currentColumns.push(key);
      }

      setUserPreference(cacheLocation, currentColumns);
    };

    const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
      updateDefaultColumns(key);
      if (hiddenColumns.includes(key)) {
        setHiddenColumns(hiddenColumns.filter((column: string) => column !== key));
        return;
      }
      setHiddenColumns([...hiddenColumns, key]);
    };

    const menuItems = optionalColumns.map((column) => ({
      key: column.key,
      label: (
        <Checkbox checked={!hiddenColumns.includes(column.key)}>
          <span onClick={(e) => e.stopPropagation()}>{column.title}</span>
        </Checkbox>
      ),
    }));

    return (
      <Dropdown
        menu={{
          items: menuItems,
          onClick: handleMenuClick,
        }}
        trigger={['click']}
        onOpenChange={handleVisibleChange}
        open={columnFilterVisible}
      >
        <Tooltip placement="left" title={EDIT_COLUMNS_TOOLTIP}>
          <Button type="text" icon={<EditOutlined />} />
        </Tooltip>
      </Dropdown>
    );
  };

  const actionColumn = columns.find((a) => a.key === 'action');

  const newColumns = [
    ...columns.filter((a) => a.key !== 'action'),
    ...(actionColumn
      ? [
          {
            ...actionColumn,
            title: () => columnOptions(),
          },
        ]
      : []),
  ];

  const tableHeader = () => (
    <>
      {renderTitleLabel()}
      <DataHeaderActions
        columnOptions={columnOptions != null}
        filters={filters}
        filterValues={filterValues}
        loading={loading}
        onSearch={onClickSearch}
        onFilterSelectChange={onFilterSelectChange}
        onSortSelectChange={onSortSelectChange}
        primaryAction={primaryAction}
        filtersAction={filtersAction}
        onClickShowMoreFilters={handleOnClickMetadataFilters}
        searchAvailable={searchAvailable}
        searchPlaceholder={searchPlaceholder}
        sorting={sorting}
      />
      {total && data.length > 0 ? <Divider className={styles.horizontalDivider} /> : null}
      <div className={styles.surMenu}>
        <div className={styles.surMenu_wrapper}>
          {renderTotalItemsHeader()}
          <AppliedFiltersList
            filterValues={filterValues}
            onClearAllFilters={onClearAllFilters}
            onFilterSelectChange={onFilterSelectChange}
          />
        </div>
        <div className={styles.surMenu_wrapper}>{surSecondaryActions && surSecondaryActions}</div>
      </div>
    </>
  );

  const SelectedActionsButtons = () => (
    <div className={styles.selectedActions}>
      {selectActions!.map(
        ({ isVisible, onClick: onActionClick, type, danger, label, popconfirm }) => {
          if (!isNil(isVisible) && !isVisible(selectedRowKeys)) return null;

          const onButtonActionClick = () => {
            setSelectedRowKeys([]);
            onActionClick(selectedRowKeys);
          };
          const ActionButton = ({ onClick }: { onClick?: () => void }) => (
            <Button size="small" type={type} danger={danger} onClick={onClick}>
              {label}
            </Button>
          );

          if (!popconfirm) return <ActionButton key={label} onClick={onButtonActionClick} />;

          return popconfirm.title ? (
            <Popconfirm
              key={label}
              title={popconfirm.title}
              placement="right"
              onConfirm={onButtonActionClick}
              okText={CONFIRM_BUTTON_LABEL}
              cancelText={popconfirm.cancelText ? popconfirm.cancelText : CANCEL_BUTTON_LABEL}
            >
              <ActionButton />
            </Popconfirm>
          ) : (
            <ActionButton />
          );
        },
      )}
    </div>
  );

  const withSelectedActionsButtons = (tableColumns: { title: string }[], hasSelected: boolean) => {
    if (!hasSelected || !selectActions?.length) return tableColumns;

    const withoutTheFirstElement = tableColumns.slice(1);
    const firstElemt = {
      ...tableColumns[0],
      title: <SelectedActionsButtons />,
    };

    return [firstElemt, ...withoutTheFirstElement];
  };

  const renderCursorPagination: PaginationProps['itemRender'] = (page, type, originalElement) => {
    if (!total || !pagination?.cursorPagination) return originalElement;

    const numberOfPages = total / pageSize;
    const isLastPageHidden =
      type === 'page' &&
      page - numberOfPages >= 0 &&
      currentPage < numberOfPages - MAX_NUMBER_OF_PAGES_ON_PAGINATION;

    if (isLastPageHidden) {
      document.querySelector(`[title="${page}"]`)?.classList.add(`${styles.hideElement}`);
    } else {
      document.querySelector(`[title="${page}"]`)?.classList.remove(`${styles.hideElement}`);
    }
    return originalElement;
  };

  const hasSelected = selectedRowKeys.length > 0;
  const titleAvailable = searchAvailable || primaryAction;

  const mappedColumns =
    columnsConfig!.optionalColumns.length > 0
      ? newColumns.filter((column) => !hiddenColumns.includes(column.key))
      : newColumns;

  return (
    <section>
      <Table
        data-testid={testId}
        onRow={getRowProps}
        rowClassName={(_, idx) => (idx >= 0 ? getRowStyles(idx) : '')}
        rowSelection={rowSelection === null ? defaultRowSelection : rowSelection}
        scroll={scroll}
        columns={withSelectedActionsButtons(mappedColumns, hasSelected)}
        dataSource={loadingCursorBasedPagination ? previousData : data}
        rowKey={rowKey}
        loading={loadingState && data && data.length > 0}
        title={titleAvailable ? tableHeader : undefined}
        className={`${styles.table} ${border ? styles.withBorder : ''}`}
        locale={{
          emptyText: loadingState ? (
            <Skeleton title={false} paragraph={{ rows: 3, width: '100%' }} active />
          ) : (
            locale.emptyText
          ),
        }}
        pagination={
          total
            ? {
                current: currentPage,
                total,
                position: ['bottomLeft'],
                pageSize,
                onChange: onPageChange,
                onShowSizeChange,
                itemRender: renderCursorPagination,
                disabled: loading,
              }
            : false
        }
      />
    </section>
  );
};

export default DataTable;
