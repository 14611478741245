import { IFeedbackBody, IFeedbackData, IUpdateFeedbackBody } from '@api/pipeline-feedback';
import { httpExt } from './base-http-external';

export const createFeedbackExternalApi = (
  pipelineId: string,
  payload: IFeedbackBody,
  workspaceId?: string,
) => {
  return httpExt.post(
    `/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/feedback`,
    payload,
  );
};

export const updateFeedbackExternalApi = (
  pipelineId: string,
  feedbackId: string,
  payload: IUpdateFeedbackBody,
  workspaceId?: string,
) => {
  return httpExt.patch(
    `/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/feedback/${feedbackId}`,
    payload,
  );
};

export const getPipelineFeedbackTagsExternalApi = (
  pipelineId: string,
  params: IFeedbackData,
  workspaceId?: string,
) => {
  const parameterMap = {
    limit: params?.limit || 50,
    ...(params?.after && { after: params.after }),
    ...(params?.page_number && { page_number: params.page_number }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
    ...(params?.filter && { filter: params.filter }),
  };
  return httpExt.get(`/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/tags`, {
    params: parameterMap,
  });
};
