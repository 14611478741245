import produce from 'immer';
import { normalizeErrorMessage } from '@utils/error';
import { HTTPStatusCode, MessageCodes, StatusCodes } from '@constants/enum/common';
import { GuidedWorkflowStep, VALIDATION_ERRORS } from '@constants/guided-workflow';
import { PipelineTemplateType } from '@constants/pipelineTemplates';
import {
  FULFILLED,
  IMessage,
  IPipelineTemplate,
  PENDING,
  REJECTED,
  RESET_PIPELINE_NAME_VALIDATION_STATUS,
  RESET_STATE,
  SAVE_AND_DEPLOY_GUIDED_WORKFLOW_PIPELINE,
  SET_CURRENT_STEP,
  SET_PIPELINE_NAME,
  SET_PIPELINE_TEMPLATE,
  SET_TASK,
  TRY_AGAIN_DEPLOY_GUIDED_WORKFLOW_PIPELINE,
  VALIDATE_PIPELINE_NAME,
} from '@redux/types/types';

interface IInitialStateProps {
  task: PipelineTemplateType;
  currentStep: number;
  pipelineTemplate: IPipelineTemplate | null;
  pipelineName: string;
  pipelineNameValidationStatus: StatusCodes;
  pipelineNameValidationMessage: IMessage;
  deployPipelineStatus: StatusCodes;
  saveAndDeployPipelineMessage: IMessage;
}

export const initialState: IInitialStateProps = {
  task: PipelineTemplateType.QuestionAnswering,
  currentStep: GuidedWorkflowStep.SELECT_TASK,
  pipelineTemplate: null,
  pipelineName: '',
  pipelineNameValidationStatus: StatusCodes.IDLE,
  pipelineNameValidationMessage: {
    type: MessageCodes.INFO,
    content: '',
  },
  deployPipelineStatus: StatusCodes.IDLE,
  saveAndDeployPipelineMessage: {
    type: MessageCodes.INFO,
    content: '',
  },
};

const guidedWorkflowReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case SET_TASK:
        localDraft.task = action.payload;
        break;
      case SET_CURRENT_STEP:
        localDraft.currentStep = action.payload;
        break;
      case RESET_PIPELINE_NAME_VALIDATION_STATUS:
        localDraft.pipelineNameValidationStatus = StatusCodes.IDLE;
        break;
      case SET_PIPELINE_NAME:
        localDraft.pipelineName = action.payload;
        localDraft.pipelineNameValidationStatus = StatusCodes.IDLE;
        break;
      case SET_PIPELINE_TEMPLATE:
        localDraft.pipelineTemplate = action.payload;
        break;
      case `${VALIDATE_PIPELINE_NAME}/${PENDING}`:
        localDraft.pipelineNameValidationStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${VALIDATE_PIPELINE_NAME}/${FULFILLED}`:
        localDraft.pipelineNameValidationStatus = StatusCodes.SUCCESS;
        break;
      case `${VALIDATE_PIPELINE_NAME}/${REJECTED}`: {
        const { response, content } = action.payload;
        localDraft.pipelineNameValidationStatus = StatusCodes.ERROR;

        if (content) {
          localDraft.pipelineNameValidationMessage = {
            type: MessageCodes.ERROR,
            content,
          };
          break;
        }

        const { status } = response;

        if (status === HTTPStatusCode.CONFLICT) {
          localDraft.pipelineNameValidationMessage = {
            type: MessageCodes.ERROR,
            content: VALIDATION_ERRORS.PIPELINE_NAME_ALREADY_USED,
          };
          break;
        }

        localDraft.pipelineNameValidationMessage = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;
      }
      case `${TRY_AGAIN_DEPLOY_GUIDED_WORKFLOW_PIPELINE}/${PENDING}`:
      case `${SAVE_AND_DEPLOY_GUIDED_WORKFLOW_PIPELINE}/${PENDING}`:
        localDraft.deployPipelineStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${TRY_AGAIN_DEPLOY_GUIDED_WORKFLOW_PIPELINE}/${FULFILLED}`:
      case `${SAVE_AND_DEPLOY_GUIDED_WORKFLOW_PIPELINE}/${FULFILLED}`:
        localDraft.deployPipelineStatus = StatusCodes.SUCCESS;
        break;
      case `${TRY_AGAIN_DEPLOY_GUIDED_WORKFLOW_PIPELINE}/${REJECTED}`:
      case `${SAVE_AND_DEPLOY_GUIDED_WORKFLOW_PIPELINE}/${REJECTED}`: {
        localDraft.deployPipelineStatus = StatusCodes.ERROR;
        localDraft.saveAndDeployPipelineMessage = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;
      }
      default:
        break;
    }

    return localDraft;
  });
};

export default guidedWorkflowReducer;
