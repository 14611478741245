import produce from 'immer';
import { StatusCodes } from '@constants/enum/common';
import {
  ADD_ORGANIZATION_SECRET,
  DELETE_ORGANIZATION_SECRET,
  FULFILLED,
  GET_ORGANIZATION_SECRETS,
  IAPIPaginationData,
  ISecret,
  PENDING,
  REJECTED,
  RESET_SECRETS_MANAGEMENT_STATUSES,
} from '@redux/types/types';

interface IInitialStateProps {
  organizationSecrets: IAPIPaginationData<ISecret[]>;
  fetchOrganizationSecretsStatus: StatusCodes;
  addOrganizationSecretStatus: StatusCodes;
  deleteOrganizationSecretStatus: StatusCodes;
}

export const initialState: IInitialStateProps = {
  organizationSecrets: {
    data: [],
    has_more: false,
    total: 0,
  },
  fetchOrganizationSecretsStatus: StatusCodes.IDLE,
  addOrganizationSecretStatus: StatusCodes.IDLE,
  deleteOrganizationSecretStatus: StatusCodes.IDLE,
};

const secretsManagementReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_SECRETS_MANAGEMENT_STATUSES:
        localDraft.fetchOrganizationSecretsStatus = initialState.fetchOrganizationSecretsStatus;
        localDraft.addOrganizationSecretStatus = initialState.addOrganizationSecretStatus;
        localDraft.deleteOrganizationSecretStatus = initialState.deleteOrganizationSecretStatus;
        break;

      case `${GET_ORGANIZATION_SECRETS}/${PENDING}`:
        localDraft.fetchOrganizationSecretsStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_ORGANIZATION_SECRETS}/${FULFILLED}`:
        localDraft.fetchOrganizationSecretsStatus = StatusCodes.SUCCESS;
        localDraft.organizationSecrets = action.payload;
        break;
      case `${GET_ORGANIZATION_SECRETS}/${REJECTED}`:
        localDraft.fetchOrganizationSecretsStatus = StatusCodes.ERROR;
        break;

      case `${ADD_ORGANIZATION_SECRET}/${PENDING}`:
        localDraft.addOrganizationSecretStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${ADD_ORGANIZATION_SECRET}/${FULFILLED}`:
        localDraft.addOrganizationSecretStatus = StatusCodes.SUCCESS;
        break;
      case `${ADD_ORGANIZATION_SECRET}/${REJECTED}`:
        localDraft.addOrganizationSecretStatus = StatusCodes.ERROR;
        break;

      case `${DELETE_ORGANIZATION_SECRET}/${PENDING}`:
        localDraft.deleteOrganizationSecretStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${DELETE_ORGANIZATION_SECRET}/${FULFILLED}`:
        localDraft.deleteOrganizationSecretStatus = StatusCodes.SUCCESS;
        break;
      case `${DELETE_ORGANIZATION_SECRET}/${REJECTED}`:
        localDraft.deleteOrganizationSecretStatus = StatusCodes.ERROR;
        break;

      default:
        break;
    }
  });
};

export default secretsManagementReducer;
