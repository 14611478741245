import React from 'react';
import { Button, Tooltip } from 'antd';
import styles from './searchActionButton.module.scss';

interface ISearchActionButtonProps {
  label?: string;
  tooltipTitle: string;
  active: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  testId?: string;
  danger?: boolean;
  disabled?: boolean;
}

// TODO: Maybe move to atoms
const SearchActionButton = ({
  label,
  tooltipTitle,
  active,
  onClick,
  icon,
  activeIcon,
  testId,
  disabled,
  danger = false,
}: ISearchActionButtonProps) => (
  <Tooltip title={tooltipTitle} placement="top">
    <Button
      className={active ? styles.activeButton : ''}
      icon={active ? activeIcon : icon}
      onClick={onClick}
      data-testid={`feedbackButton_${testId}`}
      danger={danger}
      size="small"
      type="text"
      disabled={disabled}
    >
      {label || ''}
    </Button>
  </Tooltip>
);

export default SearchActionButton;
