import React, { ReactElement, useEffect } from 'react';
import {
  CompressOutlined,
  DownloadOutlined,
  DownOutlined,
  ExpandOutlined,
  InfoCircleOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Tooltip } from 'antd';
import {
  COLLAPSE_TOOLTIP,
  DATA_TABLE_TOOLTIP,
  EXPAND_TOOLTIP,
  EXPORT_TOOLTIP,
} from '@constants/observability-dashboard';
import { ChartExportType, ObservabilityChartType } from '@redux/types/types';
import styles from './observabilityVizCard.module.scss';

interface IObservabilityVizCardProps {
  headerLabel: string;
  tooltipLabel?: string;
  chartOptions?: {
    label: string;
    key: ObservabilityChartType;
    icon: ReactElement;
  }[];
  exportOptions?: {
    label: string;
    key: ChartExportType;
  }[];
  selectedChartType?: ObservabilityChartType;
  displayHeaderActions?: boolean;
  onViewDataTable?: () => void;
  onExport?: (key: ChartExportType) => void;
  onResize: () => void;
  onChartSelection?: (chart: ObservabilityChartType) => void;
  children: React.ReactNode | React.ReactNode[];
}

const ObservabilityVizCard = (props: IObservabilityVizCardProps) => {
  const {
    headerLabel,
    tooltipLabel,
    chartOptions,
    exportOptions,
    selectedChartType,
    onExport,
    displayHeaderActions = true,
    onViewDataTable,
    onResize,
    onChartSelection,
    children,
  } = props;
  const [cardExpanded, setCardExpanded] = React.useState(false);

  const handleExpand = () => {
    setCardExpanded(!cardExpanded);
    if (!document.fullscreenElement) document.documentElement.requestFullscreen();
    else document.exitFullscreen();
    onResize();
  };

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) return;
    setCardExpanded(false);
    setTimeout(() => {
      onResize();
    });
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange, false);
    };
  }, []);

  // Renders

  const renderChartSelection = () => {
    const selectedChart = chartOptions?.find(({ key }) => key === selectedChartType);

    return (
      <Dropdown
        menu={{
          items: chartOptions,
          onClick: ({ key }) => onChartSelection && onChartSelection(key as ObservabilityChartType),
          selectable: true,
          defaultSelectedKeys: [selectedChartType || ''],
        }}
        placement="bottomRight"
        trigger={['click']}
      >
        <Button icon={selectedChart?.icon} size="small" onClick={(e) => e.preventDefault()}>
          {selectedChart?.label}
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const renderChartExport = () => (
    <Tooltip title={EXPORT_TOOLTIP}>
      <Dropdown
        menu={{
          items: exportOptions,
          onClick: ({ key }) => onExport && onExport(key as ChartExportType),
          selectable: false,
        }}
        trigger={['click']}
      >
        <Button icon={<DownloadOutlined />} size="small" onClick={(e) => e.preventDefault()} />
      </Dropdown>
    </Tooltip>
  );

  return (
    <div className={`${styles.container} ${cardExpanded ? styles.container_expanded : ''}`}>
      <div className={styles.header}>
        <h5>
          {headerLabel}
          {tooltipLabel && (
            <Tooltip className={styles.header_tooltip} title={tooltipLabel}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </h5>
        {displayHeaderActions && (
          <span className={styles.header_actions}>
            {!!chartOptions && renderChartSelection()}
            {!!onViewDataTable && (
              <Tooltip title={DATA_TABLE_TOOLTIP}>
                <Button size="small" icon={<TableOutlined />} onClick={onViewDataTable} />
              </Tooltip>
            )}
            {!!exportOptions && !!onExport && renderChartExport()}
            <Tooltip title={cardExpanded ? COLLAPSE_TOOLTIP : EXPAND_TOOLTIP}>
              <Button
                size="small"
                icon={cardExpanded ? <CompressOutlined /> : <ExpandOutlined />}
                onClick={handleExpand}
              />
            </Tooltip>
          </span>
        )}
      </div>
      <div className={`${styles.childContainer} ${cardExpanded ? 'expanded' : ''}`}>{children}</div>
    </div>
  );
};

export default ObservabilityVizCard;
