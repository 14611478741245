/* eslint-disable react/prop-types */
import React, { CSSProperties } from 'react';
import Markdown, { Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import styles from './markdownViewer.module.scss';

interface IMarkdownViewerProps {
  children: string;
  customComponents?: Components;
  customCodeStyle?: Record<string, CSSProperties>;
}

export const LinkRender = ({
  href,
  children,
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export const CodeRender = ({
  className,
  children,
  customStyle = {},
  ...rest
}: CodeProps & { customStyle: { [key: string]: CSSProperties } }) => {
  const [, language] = /language-(\w+)/.exec(className || '') || [];

  if (!language)
    return (
      <code {...rest} className={className}>
        {children}
      </code>
    );

  const style = {
    ...coy,
    ...customStyle,
  };

  return (
    <SyntaxHighlighter style={style} language={language} PreTag="div">
      {children as string}
    </SyntaxHighlighter>
  );
};

const MarkdownViewer = ({
  children,
  customComponents = {},
  customCodeStyle = {},
}: IMarkdownViewerProps) => {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        a: LinkRender,
        code: (props) => <CodeRender {...props} customStyle={customCodeStyle} />,
        ...customComponents,
      }}
      className={styles.container}
    >
      {children}
    </Markdown>
  );
};

export default MarkdownViewer;
