import React from 'react';
import { Popover, PopoverProps } from 'antd';
import { UPGRADE_POPOVER_CONTENT, UPGRADE_POPOVER_TITLE } from '@constants/settings-page';
import UpgradeButton from '@components/upgradeButton/UpgradeButton';

interface UpgradeButtonProps extends PopoverProps {
  children: React.ReactNode;
  description?: string;
}

const UpgradePopover: React.FC<UpgradeButtonProps> = ({ description, children, ...props }) => {
  return (
    <Popover
      content={
        <>
          <h6>{UPGRADE_POPOVER_TITLE}</h6>
          <p>{description ?? UPGRADE_POPOVER_CONTENT}</p>
          <UpgradeButton />
        </>
      }
      {...props}
    >
      {children}
    </Popover>
  );
};

export default UpgradePopover;
