import React, { useState } from 'react';
import { message as antMessage, Modal, Radio, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RadioChangeEvent } from 'antd/lib';
import { UploadFile } from 'antd/lib/upload/interface';
import { v4 as uuidv4 } from 'uuid';
import { getFileExtension } from '@utils/common';
import { interpolateString } from '@utils/string';
import {
  CONATCT_SUPPORT_FILE_TYPE_ERROR_ALERT,
  CONTACT_SUPPORT_ARE_YOU_BLOCKED,
  CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS,
  CONTACT_SUPPORT_DESCRIPTION,
  CONTACT_SUPPORT_DESCRIPTION_PLACEHOLDER,
  CONTACT_SUPPORT_FILES_LIMIT_LABEL,
  CONTACT_SUPPORT_MODAL_DESCRIPTION,
  CONTACT_SUPPORT_MODAL_SUBTITLE,
  CONTACT_SUPPORT_REQUEST_TYPE,
  CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS,
  CONTACT_SUPPORT_REQUIRED_FIELD,
  CONTACT_SUPPORT_SCREENSHOTS,
  CONTACT_SUPPORT_UPLOAD_DESCRIPTION,
  CONTACT_SUPPORT_UPLOAD_HINT,
  FileTypesImages,
  uploadImageTypes,
} from '@constants/contact-support';
import FileUploadList from '@components/uploadData/fileUploadList/FileUploadList';
import UploadFiles from '@components/uploadFiles/UploadFiles';
import styles from './contactSupportModal.module.scss';

interface IContactSupportModalProps {
  title: string;
  okButtonText: string;
  open: boolean;
  setOpenContactSupportModal: (open: boolean) => void;
}

const RENDER_LIMIT = 12;

const ContactSupportModal = ({
  title,
  okButtonText,
  open,
  setOpenContactSupportModal,
}: IContactSupportModalProps) => {
  const [requestTypeValue, setRequestTypeValue] = useState(null);
  const [areYouBlockedValue, setAreYouBlockedValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [currentFileList, setCurrentFileList] = useState<UploadFile[]>([]);
  const [listLength, setListLength] = useState(RENDER_LIMIT);
  const [exceededFilesAmount, setExceededFilesAmount] = useState(false);
  const [isABug, setIsABug] = useState(false);
  const fileTypes = [FileTypesImages.png, FileTypesImages.jpg];

  const onChangeRequestType = (e: RadioChangeEvent) => {
    setRequestTypeValue(e.target.value);
    if (e.target.value === CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS.bug.key) setIsABug(true);
    else setIsABug(false);
  };

  const onChangeAreYouBlocked = (e: RadioChangeEvent) => {
    setAreYouBlockedValue(e.target.value);
  };

  const getFormattedTypeText = (text: string) =>
    interpolateString(text, {
      fileFormats: fileTypes.map((fileType) => fileType.toUpperCase()).join(' and '),
    });

  const isValidFileFormat = (file: File) => {
    let isValidFileExtesion = true;

    if (file.type) {
      isValidFileExtesion = !!fileTypes.find(
        (fileType) => file.type === uploadImageTypes[fileType],
      );
    } else {
      const fileExtension = getFileExtension(file);
      isValidFileExtesion = !!fileTypes.find((fileType) => fileExtension === fileType);
    }

    return isValidFileExtesion;
  };

  const createCurrentList = (files: FileList) => {
    let chosenFiles = Array.prototype.slice.call(files);

    if (chosenFiles.length > RENDER_LIMIT) {
      setExceededFilesAmount(true);
      chosenFiles = chosenFiles.slice(0, RENDER_LIMIT);
    } else setExceededFilesAmount(false);

    const filteredFiles = chosenFiles.filter((file: File, index: number) => {
      if (currentFileList.find((currentFile) => currentFile.name === file.name)) return false;
      if (!isValidFileFormat(file)) {
        antMessage.error(getFormattedTypeText(CONATCT_SUPPORT_FILE_TYPE_ERROR_ALERT));
        return false;
      }
      chosenFiles[index].uid = uuidv4();
      return true;
    });

    setCurrentFileList([...currentFileList, ...filteredFiles]);
  };

  const getInputAcceptAttributeFromFileTypes = () =>
    fileTypes.map((fileType) => `.${fileType}`).join(', ');

  const enableSubmitButton = () => {
    if (requestTypeValue && descriptionValue) {
      if (isABug && !areYouBlockedValue) return true;
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setOpenContactSupportModal(false);
    setRequestTypeValue(null);
    setAreYouBlockedValue(null);
    setDescriptionValue('');
    setCurrentFileList([]);
    setExceededFilesAmount(false);
    setIsABug(false);
  };

  return (
    <Modal
      title={title}
      centered
      open={open}
      onOk={() => setOpenContactSupportModal(false)}
      onCancel={() => closeModal()}
      okText={okButtonText}
      okButtonProps={{ disabled: enableSubmitButton() }}
      width={1000}
      className={styles.contactSupportModal}
    >
      <h3>{CONTACT_SUPPORT_MODAL_SUBTITLE}</h3>
      <span>{CONTACT_SUPPORT_MODAL_DESCRIPTION}</span>
      <div className={styles.contactSupportSections}>
        <div className={styles.contactSupportSections_section}>
          <h6>
            <span className={styles.contactSupportSections_section_required}>
              {CONTACT_SUPPORT_REQUIRED_FIELD}
            </span>
            {CONTACT_SUPPORT_REQUEST_TYPE}
          </h6>
          <Radio.Group onChange={onChangeRequestType} value={requestTypeValue}>
            <Space direction="vertical">
              <Radio value={CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS.bug.key}>
                {CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS.bug.label}
              </Radio>
              <Radio value={CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS.featureRequest.key}>
                {CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS.featureRequest.label}
              </Radio>
              <Radio value={CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS.other.key}>
                {CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS.other.label}
              </Radio>
            </Space>
          </Radio.Group>
        </div>

        <div className={styles.contactSupportSections_section}>
          <h6>
            {isABug && (
              <span className={styles.contactSupportSections_section_required}>
                {CONTACT_SUPPORT_REQUIRED_FIELD}
              </span>
            )}
            {CONTACT_SUPPORT_ARE_YOU_BLOCKED}
          </h6>
          <Radio.Group onChange={onChangeAreYouBlocked} value={areYouBlockedValue}>
            <Space direction="vertical">
              <Radio value={CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS.option1.key}>
                {CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS.option1.label}
              </Radio>
              <Radio value={CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS.option2.key}>
                {CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS.option2.label}
              </Radio>
              <Radio value={CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS.option3.key}>
                {CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS.option3.label}
              </Radio>
              <Radio value={CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS.option4.key}>
                {CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS.option4.label}
              </Radio>
            </Space>
          </Radio.Group>
        </div>

        <div className={styles.contactSupportSections_section}>
          <h6>
            <span className={styles.contactSupportSections_section_required}>
              {CONTACT_SUPPORT_REQUIRED_FIELD}
            </span>
            {CONTACT_SUPPORT_DESCRIPTION}
          </h6>
          <TextArea
            value={descriptionValue}
            onChange={(e) => setDescriptionValue(e.target.value)}
            rows={8}
            placeholder={CONTACT_SUPPORT_DESCRIPTION_PLACEHOLDER}
          />
        </div>

        <div className={styles.contactSupportSections_section}>
          <h6>{CONTACT_SUPPORT_SCREENSHOTS}</h6>
          <div className={styles.contactSupportSections_section_screenshots}>
            <UploadFiles
              createCurrentList={createCurrentList}
              buttonDescription={CONTACT_SUPPORT_UPLOAD_DESCRIPTION}
              hintDescription={
                exceededFilesAmount
                  ? CONTACT_SUPPORT_UPLOAD_HINT + CONTACT_SUPPORT_FILES_LIMIT_LABEL
                  : CONTACT_SUPPORT_UPLOAD_HINT
              }
              getInputAcceptAttributeFromFileTypes={getInputAcceptAttributeFromFileTypes}
              size="small"
              expanded={currentFileList.length === 0}
            />
            <div className={styles.uploadFileList}>
              <FileUploadList
                filesList={currentFileList}
                listLength={30}
                viewOnly
                onLoadMore={() => setListLength(listLength + RENDER_LIMIT)}
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactSupportModal;
