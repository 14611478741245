import { DOCS_URL } from '@constants/constant';

export const FILE_TYPE_ERROR_ALERT = 'Only %fileFormats% file formats are supported.';
export const ERRORED_ALL_UPLOAD_HEADER = `Some of the files didn't get uploaded`;
export const ERRORED_ALL_UPLOAD_DESCRIPTION = `%retryButton% or contact your deepset Cloud representative if it continues to fail.`;
export const ERRORED_SINGLE_UPLOAD_DESCRIPTION = `Try again or contact your deepset Cloud representative if it continues to fail.`;
export const ERRORED_WRONG_SINGLE_FILE_TYPE = `You're trying to upload an unsupported file type. Check the %documentation% for supported types.`;
export const DOCUMENTATION_BUTTON_LABEL = ' Documentation';
export const RETRY_FAILED_BUTTON_LABEL = 'Retry Failed';
export const LARGE_AMOUNT_FILES_HEADER = 'Large number of files are being uploaded';
export const LARGE_AMOUNT_FILES_DESCRIPTION =
  'You are currently attempting to upload a large number of files which can take some time. Try %uploadWithPythoSDK% for faster upload.';
export const SUCCESSFUL_UPLOAD_HEADER = '%successfulUploads% files uploaded.';
export const SUCCESSFUL_UPLOAD_HEADER_SINGULAR = 'File uploaded.';
export const IN_PROGRESS_UPLOAD_HEADER = 'Uploading %completedFiles% of %inProgressFiles%';
export const UPLOAD_COMPLETED_HEADER = 'Upload finished';
export const UPLOAD_DESCRIPTION = 'Drag and drop your files here or click to upload.';
export const UPLOAD_HINT = 'Supports %fileFormats% files';
export const FILE_TYPES_DOCS_URL = `${DOCS_URL}docs/upload-files`;
export const UPLOAD_HINT_MULTIPLE_FILE_TYPES = 'For supported file types, see %documentation%.';
export const UPLOAD_BUTTON = 'Upload';
export const UPLOAD_CANCEL_BUTTON = 'Cancel';
export const UPLOAD_MODAL_TITLE = 'Upload Files';
export const RETRY_UPLOAD_BUTTON = 'Try again';
export const FILE_SIZE_LIMIT_ERROR = 'File size exceeds the limit (%fileSize%MB)';
export const UPLOADING_WITH_PYTHON_SDK_LABEL = 'uploading with the Python SDK';
