import React, { memo, useEffect, useRef } from 'react';
import { theme } from 'antd';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ISearchCountData } from '@utils/transformation/dashboard';
import { CHART_TITLE } from '@constants/dashboard';
import styles from './dashboard.module.scss';

const { useToken } = theme;

export interface INumberOfRequestsProps {
  data: ISearchCountData[];
  loading: boolean;
}

const NumberOfRequests = (props: INumberOfRequestsProps) => {
  const { data, loading } = props;
  const { token } = useToken();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<any>(null);

  const chartData = {
    labels: data.map((row) => row.date),
    datasets: [
      {
        data: data.map((row) => row.count),
        borderColor: token.colorPrimary,
        backgroundColor: '#f0f7ff',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grace: '11%',
        grid: {
          display: false,
        },
        ticks: {
          display: false,
          stepSize: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    if (canvasRef.current && !loading) {
      const ctx = canvasRef.current.getContext('2d');
      Chart.register(ChartDataLabels);

      chartRef.current = new Chart(ctx!, {
        type: 'line',
        options: {
          ...chartOptions,
          plugins: {
            datalabels: {
              color: token.colorPrimary,
              anchor: 'end',
              align: 'top',
            },
            legend: {
              display: false,
            },
          },
        },
        data: chartData,
      });
    }

    return () => {
      chartRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    chartRef.current.data = chartData;
    chartRef.current.update();
  }, [data]);

  return (
    <section>
      <div className={styles.blockTitle}>{CHART_TITLE}</div>
      <div className={styles.chartContainer}>
        <canvas ref={canvasRef} />
      </div>
    </section>
  );
};

export default memo(NumberOfRequests);
