export interface IBreadcrumb {
  label: string;
  link: string;
}

export interface IRoutesConfiguration {
  withSidebar?: boolean;
  sideBar: {
    activeKey?: string;
    tooltipText?: string;
  };
  path: string;
  header: {
    title?: string;
    backButtonLink?: string | number;
    withUserMenu?: boolean;
    withSmallContainer?: boolean;
    rightSideButton?: {
      text: string;
      type: 'link' | 'text' | 'default' | 'primary' | 'dashed' | undefined;
      redirectPath?: string;
    };
    // We could use this instead to create breadcrumbs https://reactrouter.com/en/main/hooks/use-matches
    // but it requires chaging the routes configuration and still have to define crumbs manually
    crumbs?: IBreadcrumb[];
  };
}

const SEARCH_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Playground',
    },
    path: '/playground',
    header: {
      title: 'Playground',
      crumbs: [
        {
          label: 'Playground',
          link: '/playground',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Playground',
    },
    path: '/playground/:pipelineName',
    header: {
      title: 'Playground',
      crumbs: [
        {
          label: 'Playground',
          link: '/playground',
        },
        {
          label: ':pipelineName',
          link: '/playground/:pipelineName',
        },
      ],
    },
  },
];

const SETTINGS_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/personal-details',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/personal-details',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/organization',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/organization',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/connections',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/connections',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/workspaces',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/workspaces',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/usage',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/usage',
        },
      ],
    },
  },
];

const PIPELINES_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines',
    header: {
      title: 'Pipelines',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines-landing',
    header: {
      title: 'Pipelines Landing',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines-landing',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines/designer',
    header: {
      title: 'Pipeline Designer',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: 'Designer',
          link: '/pipelines/designer',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines/designer/:pipelineName',
    header: {
      title: 'Pipeline Designer',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: ':pipelineName',
          link: '/pipelines/:pipelineName',
        },
        {
          label: 'Designer',
          link: '/pipelines/designer',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines/studio',
    header: {
      title: 'Pipeline Studio',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: 'Studio',
          link: '/pipelines/studio',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines/:pipelineName/studio',
    header: {
      title: 'Pipeline Studio',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: ':pipelineName',
          link: '/pipelines/:pipelineName',
        },
        {
          label: 'Studio',
          link: '/pipelines/:pipelineName/studio',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines/editor',
    header: {
      title: 'Pipeline Editor',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: 'Editor',
          link: '/pipelines/designer',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines/editor/:pipelineName',
    header: {
      title: 'Pipeline Editor',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: ':pipelineName',
          link: '/pipelines/:pipelineName',
        },
        {
          label: 'Editor',
          link: '/pipelines/editor',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/pipelines/:pipelineName',
    header: {
      title: 'Pipeline Details',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: ':pipelineName',
          link: '/pipelines/:pipelineName',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipeline Templates',
    },
    path: '/pipelines/templates',
    header: {
      title: 'Pipeline Templates',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: 'Templates',
          link: '/pipelines/templates',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipeline Templates',
    },
    path: '/pipelines/templates/:templateName',
    header: {
      title: 'Template',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: 'Templates',
          link: '/pipelines/templates',
        },
        {
          label: ':templateName',
          link: '/pipelines/templates/:templateName',
        },
      ],
    },
  },
];

const EXPERIMENTS_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Experiments',
    },
    path: '/experiments',
    header: {
      title: 'Experiments',
      crumbs: [
        {
          label: 'Experiments',
          link: '/experiments',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Experiments',
    },
    path: '/experiments/:experimentName',
    header: {
      title: 'Experiment Details',
      crumbs: [
        {
          label: 'Experiments',
          link: '/experiments',
        },
        {
          label: ':experimentName',
          link: '/experiments/:experimentName',
        },
      ],
    },
  },
];

const PROMPT_EXPLORER_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Prompt Studio',
    },
    path: '/prompt-studio/:pipelineName',
    header: {
      title: 'Prompt Studio',
      crumbs: [
        {
          label: 'Prompt Studio',
          link: '/prompt-studio',
        },
        {
          label: ':pipelineName',
          link: '/prompt-studio/:pipelineName',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Prompt Studio',
    },
    path: '/prompt-studio',
    header: {
      title: 'Prompt Studio',
      crumbs: [
        {
          label: 'Prompt Studio',
          link: '/prompt-studio',
        },
      ],
    },
  },
];

const OBSERVABILITY_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Groundedness',
    },
    path: '/groundedness',
    header: {
      title: 'Groundedness Observability',
      crumbs: [
        {
          label: 'Groundedness Observability',
          link: '/groundedness',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Groundedness',
    },
    path: '/groundedness/:pipelineName',
    header: {
      title: 'Groundedness Observability',
      crumbs: [
        {
          label: 'Groundedness Observability',
          link: '/groundedness',
        },
        {
          label: ':pipelineName',
          link: '/groundedness/:pipelineName',
        },
      ],
    },
  },
];

// TODO: Use routes configuration to dynamically create routes
export const ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/home',
    header: {
      title: 'Home',
    },
  },
  {
    sideBar: {
      activeKey: 'Files',
    },
    path: '/files',
    header: {
      title: 'Files',
      crumbs: [
        {
          label: 'Files',
          link: '/files',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Evaluation Sets',
    },
    path: '/evalset',
    header: {
      title: 'Evaluation Sets',
      crumbs: [
        {
          label: 'Evaluation Sets',
          link: '/evalset',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Jobs',
    },
    path: '/jobs',
    header: {
      title: 'Jobs',
      crumbs: [
        {
          label: 'Jobs',
          link: '/jobs',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Jobs',
    },
    path: '/jobs/:jobId',
    header: {
      title: 'Jobs',
      crumbs: [
        {
          label: 'Jobs',
          link: '/jobs',
        },
        {
          label: ':jobId',
          link: '/jobs/:jobId',
        },
      ],
    },
  },
  {
    withSidebar: false,
    path: '/shared_job_results',
    header: {},
    sideBar: {},
  },
  {
    withSidebar: false,
    path: '/shared_prototypes',
    header: {},
    sideBar: {},
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/upgrade',
    header: {
      title: 'Upgrade',
      crumbs: [
        {
          label: 'Upgrade',
          link: '/upgrade',
        },
      ],
    },
  },
  ...EXPERIMENTS_ROUTES_CONFIGURATION,
  ...PROMPT_EXPLORER_ROUTES_CONFIGURATION,
  ...PIPELINES_ROUTES_CONFIGURATION,
  ...SEARCH_ROUTES_CONFIGURATION,
  ...SETTINGS_ROUTES_CONFIGURATION,
  ...OBSERVABILITY_ROUTES_CONFIGURATION,
];
