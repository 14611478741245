import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThunderboltFilled } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import styles from './upgradeButton.module.scss';

interface UpgradeButtonProps extends ButtonProps {
  label?: string;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ label, ...props }) => {
  const navigate = useNavigate();
  return (
    <Button
      icon={<ThunderboltFilled />}
      type="primary"
      className={styles.button}
      onClick={() => navigate('/upgrade')}
      {...props}
    >
      {label ?? 'Upgrade'}
    </Button>
  );
};

export default UpgradeButton;
