import { useDispatch, useSelector } from 'react-redux';
import AbortControllerManager from '@utils/abortController';
import { PipelineOutputType } from '@constants/pipelines';
import {
  addPromptExplorerInfoResult as addPromptExplorerInfoResultAction,
  queryPromptExplorer as queryPromptExplorerAction,
  resetChatResults as resetChatResultsAction,
  resetResults,
  resetSearchResultsPromptExplorer as resetSearchResultsPromptExplorerAction,
  search as searchAction,
  sendChatQuery,
} from '@redux/actions/searchActions';
import {
  chatQueryStatusSelector,
  chatResultsSelector,
  searchResultSelector,
  searchStatusSelector,
} from '@redux/selectors/searchSelectors';
import { IPipelineQueryParams, MetadataFiltersType, RequestController } from '@redux/types/types';
import usePipelineFeedback from './usePipelineFeedback';

interface ISharedQueryParams {
  pipelineName: string;
  query: string;
  filters?: Record<string, MetadataFiltersType>;
  params?: IPipelineQueryParams;
  sessionId?: string;
  viewPrompts?: boolean;
  isExternal?: boolean;
  isV2?: boolean;
}

// TODO: Maybe move each query type logic into it's own hook
const usePipelineQuery = () => {
  const dispatch = useDispatch();
  const { resetProvidedFeedbackStatus } = usePipelineFeedback();

  // Search
  const searchResult = useSelector(searchResultSelector);
  const searchStatus = useSelector(searchStatusSelector);

  const resetSearchResults = () => {
    AbortControllerManager.getInstance().abortAll();
    dispatch(resetResults);
    resetProvidedFeedbackStatus();
  };

  // Chat
  const chatResults = useSelector(chatResultsSelector);
  const chatQueryStatus = useSelector(chatQueryStatusSelector);

  const resetChatResults = () => {
    AbortControllerManager.getInstance().abortAll();
    dispatch(resetChatResultsAction);
    resetProvidedFeedbackStatus();
  };

  // Prompt Explorer
  const resetSearchResultsPromptExplorer = (playgroundId: string) => {
    AbortControllerManager.getInstance().abort(
      `${RequestController.PROMPT_EXPLORER_QUERY}_${playgroundId}`,
    );
    dispatch(resetSearchResultsPromptExplorerAction(playgroundId));
    resetProvidedFeedbackStatus();
  };

  // Query methods

  const search = async (
    params: ISharedQueryParams & {
      pipelineOutputType?: PipelineOutputType;
    },
  ) => {
    const { pipelineOutputType } = params;
    const enableStreaming =
      pipelineOutputType === PipelineOutputType.CHAT ||
      pipelineOutputType === PipelineOutputType.GENERATIVE;

    dispatch(searchAction({ streaming: enableStreaming, ...params }));
  };

  const chat = async (
    params: ISharedQueryParams & {
      sessionId: string;
    },
  ) => {
    dispatch(sendChatQuery({ streaming: true, viewPrompts: true, ...params }));
  };

  const queryPromptExplorer = async (
    params: ISharedQueryParams & {
      pipelineOutputType: PipelineOutputType;
      sessionId: string;
      playgroundId: string;
    },
  ) => {
    dispatch(queryPromptExplorerAction({ streaming: true, viewPrompts: true, ...params }));
  };

  // TODO: Restructure query result into type based results (e.g ResultType, InfoType, ErrorType)
  const addPromptExplorerInfoResult = ({
    playgroundId,
    infoText,
  }: {
    playgroundId: string;
    infoText: string;
  }) => {
    dispatch(addPromptExplorerInfoResultAction({ playgroundId, infoText }));
  };

  return {
    // Search
    search,
    searchResult,
    searchStatus,
    resetSearchResults,
    // Chat
    chat,
    resetChatResults,
    chatResults,
    chatQueryStatus,
    // Prompt Explorer
    queryPromptExplorer,
    addPromptExplorerInfoResult,
    resetSearchResultsPromptExplorer,
  };
};

export default usePipelineQuery;
