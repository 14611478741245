import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Input } from 'antd';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { UserRolesMap } from '@constants/enum/common';
import {
  FIRST_NAME_ERROR,
  FIRST_NAME_LABEL,
  LAST_NAME_ERROR,
  LAST_NAME_LABEL,
} from '@constants/settings-page';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';
import TopNavigation from '@components/settings/TopNavigation';
import '@styles/components/layout.scss';

interface IValues {
  firstName: string;
  lastName: string;
}

const PersonalDetailsPage = () => {
  const { firstName, lastName, role }: IUserData = useSelector(userSelector);
  const [form] = Form.useForm();

  const initialValues: IValues = {
    firstName,
    lastName,
  };

  const formProps = {
    form,
    name: 'changePersonalDetails',
    requiredMark: false,
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
    labelAlign: 'left' as FormLabelAlign,
    initialValues,
  };

  return (
    <div className="content-wrapper">
      <TopNavigation />
      <div
        className="content-wrapper_padding"
        style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
      >
        <div>
          <Form {...formProps}>
            <Form.Item
              colon={false}
              name="firstName"
              label={FIRST_NAME_LABEL}
              rules={[{ required: true, message: FIRST_NAME_ERROR }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              colon={false}
              name="lastName"
              label={LAST_NAME_LABEL}
              rules={[{ required: true, message: LAST_NAME_ERROR }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item colon={false} name="role" label="Role">
              <div>{UserRolesMap[role]}</div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetailsPage;
