import React, { useState } from 'react';
import { Avatar, Button, Divider, Tooltip } from 'antd';
import { NO_RELEVANT_ANSWER_LABEL } from '@constants/common';
import { SHOW_LESS_LABEL, SHOW_MORE_LABEL, TITLE } from '@constants/pipeline-latest-queries';
import {
  ISearchHistoryResponse,
  PipelineLatestQueries as PipelineLatestQueriesType,
} from '@redux/types/types';
import styles from './pipelineLatestQueries.module.scss';

interface IPipelineLatestQueriesComponent {
  latestQueries: PipelineLatestQueriesType;
  getLatestQueries: (limit: number) => void;
  loading: boolean;
}

const PipelineLatestQueries = (props: IPipelineLatestQueriesComponent) => {
  const { latestQueries, getLatestQueries, loading } = props;
  const [showMore, setShowMore] = useState(true);

  const onShowMoreLess = (showType: boolean, limit: number) => {
    setShowMore(showType);
    getLatestQueries(limit);
  };

  const getQueryContentFromSearchHistoryResponse = (response: ISearchHistoryResponse) => {
    if (!response) return NO_RELEVANT_ANSWER_LABEL;
    const { result, documents } = response;
    if (!result) return documents[0]?.content;
    const { answer } = result;
    if (!answer) return NO_RELEVANT_ANSWER_LABEL;
    return answer;
  };

  return (
    <section className={styles.section}>
      <h5> {TITLE} </h5>
      <div className={styles.latestQueriesContainer}>
        {latestQueries.data.map((latestQuery, index) => {
          const { given_name: givenName, family_name: familyName } = latestQuery.user;
          const { query } = latestQuery.request;
          const content = getQueryContentFromSearchHistoryResponse(latestQuery.response[0]);

          return (
            <React.Fragment key={latestQuery.search_history_id}>
              <div className={styles.latestQueriesList}>
                <div className={styles.latestQueriesList_avatar}>
                  <Tooltip title={`${givenName} ${familyName}`}>
                    <Avatar>{givenName[0] + familyName[0]}</Avatar>
                  </Tooltip>
                </div>
                <div className={styles.latestQueriesList_queryInfo}>
                  <div className={styles.latestQueriesList_queryData}>
                    <div className={styles.latestQueriesList_queryQuery}>{query}</div>
                    <div className={styles.latestQueriesList_queryAnswer}>{content}</div>
                  </div>
                  <div className={styles.latestQueriesList_queryDate}>
                    {new Date(latestQuery.time).toLocaleString()}
                  </div>
                </div>
              </div>
              <div className={styles.latestQueriesList_divider}>
                {index < latestQueries.data.length - 1 && <Divider />}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className={styles.section_showMore}>
        {showMore ? (
          latestQueries.has_more && (
            <Button loading={loading} onClick={() => onShowMoreLess(false, 15)}>
              {SHOW_MORE_LABEL}
            </Button>
          )
        ) : (
          <Button loading={loading} onClick={() => onShowMoreLess(true, 5)}>
            {SHOW_LESS_LABEL}
          </Button>
        )}
      </div>
    </section>
  );
};

export default PipelineLatestQueries;
