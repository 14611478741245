import { useDispatch, useSelector } from 'react-redux';
import { ISecretPayload } from '@api/secrets';
import {
  addOrganizationSecret as addOrganizationSecretAction,
  deleteOrganizationSecret as deleteOrganizationSecretAction,
  getOrganizationSecrets as getOrganizationSecretsAction,
  resetSecretsManagementStatuses as resetSecretsManagementStatusesAction,
} from '@redux/actions/secretsManagementActions';
import {
  addOrganizationSecretStatusSelector,
  deleteOrganizationSecretStatusSelector,
  fetchOrganizationSecretsStatusSelector,
  organizationSecretsSelector,
} from '@redux/selectors/secretsManagementSelectors';

const useSecretsManagement = () => {
  const dispatch = useDispatch();
  const organizationSecrets = useSelector(organizationSecretsSelector);
  const fetchOrganizationSecretsStatus = useSelector(fetchOrganizationSecretsStatusSelector);
  const addOrganizationSecretStatus = useSelector(addOrganizationSecretStatusSelector);
  const deleteOrganizationSecretStatus = useSelector(deleteOrganizationSecretStatusSelector);

  const resetSecretsManagementStatuses = () => {
    dispatch(resetSecretsManagementStatusesAction);
  };

  const getOrganizationSecrets = ({
    limit,
    pageNumber,
  }: {
    limit?: number;
    pageNumber?: number;
  } = {}) => {
    dispatch(getOrganizationSecretsAction({ limit, pageNumber }));
  };

  const handleAddNewSecret = (secret: ISecretPayload) => {
    dispatch(addOrganizationSecretAction(secret));
  };

  const handleDeleteSecret = (secretId: string) => {
    dispatch(deleteOrganizationSecretAction(secretId));
  };

  return {
    organizationSecrets,
    fetchOrganizationSecretsStatus,
    addOrganizationSecretStatus,
    deleteOrganizationSecretStatus,
    getOrganizationSecrets,
    handleAddNewSecret,
    handleDeleteSecret,
    resetSecretsManagementStatuses,
  };
};

export default useSecretsManagement;
