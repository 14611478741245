import React from 'react';
import styles from './textHighlight.module.scss';

interface ITextHighlightProps {
  offsetStart: number;
  offsetEnd: number;
  text: string;
  highlight?: string | null;
  label?: string;
  ellipses?: boolean;
  testId?: string;
}

const TextHighlight = ({
  offsetStart,
  offsetEnd,
  text,
  highlight,
  label,
  ellipses,
  testId,
}: ITextHighlightProps) => {
  const preText = text.slice(0, offsetStart);
  const postText = text.slice(offsetEnd, text.length);
  const highlightText = text.slice(offsetStart, offsetEnd);

  return (
    <>
      {ellipses && '...'}
      {preText}
      <span className={styles.highlight} data-testid={testId}>
        {highlight || highlightText}
        {label && <span>{label}</span>}
      </span>
      {postText}
      {ellipses && '...'}
    </>
  );
};

export default TextHighlight;
