import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import { isNil } from 'lodash';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import { StatusCodes, UserRoles } from '@constants/enum/common';
import { FEEDBACK_TYPE_LABELS } from '@constants/pipeline-feedback';
import { userSelector } from '@redux/selectors/userSelectors';
import { FeedbackType, IUserData } from '@redux/types/types';
import CopyButton from '@components/common/copyButton/copyButton';
import SearchActionButton from '@components/search/molecules/searchActionButton/SearchActionButton';
import SearchFeedbackRatingModal from '@components/search/molecules/searchFeedbackRatingModal/SearchFeedbackRatingModal';
import styles from './searchResultActions.module.scss';
import SearchResultBookmark from '../searchResultBookmark/SearchResultBookmark';

interface ISearchResultActionsProps {
  resultId: string;
  queryId: string;
  pipelineId: string;
  pipelineName: string;
  copytext?: string;
  isExternal?: boolean;
  withBookmark?: boolean;
}

const SearchResultActions = ({
  resultId,
  queryId,
  pipelineId,
  pipelineName,
  copytext,
  isExternal,
  withBookmark = true,
}: ISearchResultActionsProps) => {
  // TODO: Move to a hook
  const { role }: IUserData = useSelector(userSelector);
  const {
    providedSearchResultFeedback,
    pipelineFeedbackTagsByPipelineId,
    providedPipelineFeedbackStatus,
    updatedPipelineFeedbackStatus,
    providedFeedbackByResultId,
  } = usePipelineFeedback();
  const [showRatingModal, setShowRatingModal] = useState(false);

  const { data: tags } = pipelineFeedbackTagsByPipelineId[pipelineId] ?? {
    data: [],
  };
  const isProvidingFeedback = providedPipelineFeedbackStatus === StatusCodes.IN_PROGRESS;
  const providedFeedbackForResult = providedFeedbackByResultId[resultId];
  const {
    score: feedbackType,
    tags: providedTags,
    comment: providedComment,
  } = providedFeedbackForResult ?? {};

  useEffectUpdateOnly(() => {
    if (isNil(feedbackType)) return;
    setShowRatingModal(true);
  }, [feedbackType]);

  useEffectUpdateOnly(() => {
    if (updatedPipelineFeedbackStatus === StatusCodes.ERROR) {
      setShowRatingModal(true);
    }
  });

  const handleProvidingFeedback = (type: FeedbackType) => {
    if (type === feedbackType) return;
    providedSearchResultFeedback({ type: type, resultId, queryId, pipelineId, isExternal });
  };

  const handleRatingSubmit = ({ comment, tagsId }: { comment: string; tagsId: string[] }) => {
    if (!providedFeedbackForResult) return;

    providedSearchResultFeedback({
      type: feedbackType,
      resultId,
      queryId,
      pipelineId,
      comment,
      tags: tagsId,
      isExternal,
    });
    setShowRatingModal(false);
  };

  // Renders

  const renderFeedbackButtons = () => {
    return (
      <>
        <SearchActionButton
          tooltipTitle={FEEDBACK_TYPE_LABELS[FeedbackType.ACCURATE]}
          disabled={isProvidingFeedback}
          active={feedbackType === FeedbackType.ACCURATE}
          onClick={() => handleProvidingFeedback(FeedbackType.ACCURATE)}
          icon={<LikeOutlined className={styles.like_icon} />}
          activeIcon={<LikeFilled className={styles.like_icon_active} />}
          testId="accurate"
        />
        <SearchActionButton
          tooltipTitle={FEEDBACK_TYPE_LABELS[FeedbackType.FAIRLY_ACCURATE]}
          disabled={isProvidingFeedback}
          active={feedbackType === FeedbackType.FAIRLY_ACCURATE}
          onClick={() => handleProvidingFeedback(FeedbackType.FAIRLY_ACCURATE)}
          icon={<LikeOutlined className={styles.neutralThumb_icon} />}
          activeIcon={<LikeFilled className={styles.neutralThumb_icon_active} />}
          testId="fairlyAccurate"
        />
        <SearchActionButton
          tooltipTitle={FEEDBACK_TYPE_LABELS[FeedbackType.INACCURATE]}
          disabled={isProvidingFeedback}
          active={feedbackType === FeedbackType.INACCURATE}
          onClick={() => handleProvidingFeedback(FeedbackType.INACCURATE)}
          icon={<DislikeOutlined className={styles.dislike_icon} />}
          activeIcon={<DislikeFilled className={styles.dislike_icon_active} />}
          testId="notAccurate"
        />
      </>
    );
  };

  return (
    <div className={styles.container} data-testid="searchResult_feedback_options">
      <div className={styles.buttonWrapper}>
        {renderFeedbackButtons()}
        {!!copytext && <CopyButton copytext={copytext} />}
        {withBookmark && (
          <SearchResultBookmark
            resultId={resultId}
            queryId={queryId}
            pipelineId={pipelineId}
            isExternal={isExternal}
          />
        )}
      </div>
      <SearchFeedbackRatingModal
        pipelineName={pipelineName}
        isAdmin={role == UserRoles.ADMIN}
        open={showRatingModal}
        tags={tags}
        defaultComment={providedComment}
        defaultTags={providedTags}
        loading={updatedPipelineFeedbackStatus === StatusCodes.IN_PROGRESS}
        onSubmit={handleRatingSubmit}
        onClose={() => {
          setShowRatingModal(false);
        }}
      />
    </div>
  );
};

export default SearchResultActions;
