import React from 'react';
import { DownOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Dropdown, Tooltip } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { isEmpty, isString } from 'lodash';
import { DISABLED_EXTERNAL_SOURCES_FILES_TOOLTIP, SOURCES_FILE_LABEL } from '@constants/search';
import { ISearchResultDocument, SearchFileAction, SearchResultMeta } from '@redux/types/types';
import EllipsisMiddle from '@components/common/ellipsisMiddle/EllipsisMiddle';
import styles from './searchResultFileSource.module.scss';

const { Panel } = Collapse;

interface ISearchResultMultiFileSourceProps {
  resultId: string;
  file?: {
    id: string;
    name: string;
  };
  meta: SearchResultMeta;
  documentsGroupedByFileId?: Record<string, ISearchResultDocument[]>;
  isFileActionTakingPlace: Record<string, boolean>;
  searching: boolean;
  isExternal?: boolean;
  fileDropdownMenuItems: MenuProps['items'];
  onFileDropdownItemClick: (params: { key: SearchFileAction; id: string; name: string }) => void;
  displayFileOptions?: boolean;
  sourcesCollapsed?: boolean;
  setSourcesCollapsed: (value: boolean) => void;
}

const SearchResultMultiFileSource = ({
  resultId,
  fileDropdownMenuItems,
  onFileDropdownItemClick,
  documentsGroupedByFileId,
  sourcesCollapsed,
  setSourcesCollapsed,
  isExternal,
  displayFileOptions,
  isFileActionTakingPlace,
  searching,
}: ISearchResultMultiFileSourceProps) => {
  if (!documentsGroupedByFileId || isEmpty(documentsGroupedByFileId)) return null;

  const getFileName = (fileId: string) => documentsGroupedByFileId[fileId][0].file.name;

  const withTooltip = (element: React.ReactElement) =>
    isExternal && !displayFileOptions ? (
      <Tooltip title={DISABLED_EXTERNAL_SOURCES_FILES_TOOLTIP} placement="top">
        {element}
      </Tooltip>
    ) : (
      element
    );

  return (
    <Collapse
      ghost
      className={styles.sourceCollapsable}
      defaultActiveKey={sourcesCollapsed ? resultId : ''}
      onChange={(activePanels) => {
        if (isString(activePanels)) return;
        setSourcesCollapsed(activePanels.filter(Boolean).length > 0);
      }}
    >
      <Panel
        data-testid="searchResult_multi_file_source"
        showArrow={false}
        key={resultId}
        header={
          <Button type="link" className={styles.sourceCollapsable_header_button}>
            {SOURCES_FILE_LABEL}
            {sourcesCollapsed ? <MinusOutlined /> : <PlusOutlined />}
          </Button>
        }
      >
        {Object.keys(documentsGroupedByFileId).map((fileId) => (
          <Dropdown
            key={fileId}
            disabled={!displayFileOptions}
            menu={{
              items: fileDropdownMenuItems,
              onClick: ({ key }) =>
                onFileDropdownItemClick({
                  key: key as SearchFileAction,
                  id: fileId,
                  name: getFileName(fileId),
                }),
            }}
            trigger={['click']}
          >
            {withTooltip(
              <Button
                className={styles.sourceCollapsable_fileOptions}
                type="default"
                onClick={(e) => e.preventDefault()}
                loading={isFileActionTakingPlace[fileId]}
                disabled={searching || !displayFileOptions}
              >
                <EllipsisMiddle>{getFileName(fileId)}</EllipsisMiddle>
                {displayFileOptions && <DownOutlined />}
              </Button>,
            )}
          </Dropdown>
        ))}
      </Panel>
    </Collapse>
  );
};

export default SearchResultMultiFileSource;
