import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const experimentStoreSelector = (state: RootState) => state.experimentStore;

export const experimentsSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.experiments,
);

export const experimentMessageSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.message,
);

export const newExperimentMessageSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.newEvalRunMessage,
);

export const experimentStatusSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.status,
);

export const experimentActionStatusSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.actionStatus,
);

export const newExperimentStatusSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.newEvalRunStatus,
);

export const experimentCurrentSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.current,
);

export const tagsSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.workspaceTags,
);

export const filtersValuesSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.filtersValues,
);

export const sortValueSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.sortValue,
);

export const predictionsSortValueByNodeSelector = createSelector(
  experimentStoreSelector,
  (experimentStore) => experimentStore.predictionsSortValueByNode,
);
