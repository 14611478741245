import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDocumentsGroupedByFileId,
  getResultsByType,
  groupParsedDuplicateReferences,
} from '@utils/search';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { REFERENCE_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import { PipelineType } from '@constants/pipelines';
import {
  PREVIEW_DOCUMENT_DRAWER_TITLE,
  PREVIEW_DOCUMENTS_DRAWER_TITLE,
  PREVIEW_FILE_DRAWER_TITLE,
} from '@constants/search';
import { getFileContent, selectFileDocument } from '@redux/actions/fileActions';
import {
  fileContentSelector,
  getFileContentStatusSelector,
  selectedFileDocumentSelector,
} from '@redux/selectors/fileSelectors';
import {
  IHighlightData,
  ISearchResult,
  ISearchResultAnswer,
  ISearchResultDocument,
} from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import useReferences from '@components/search/hooks/useReferences';

const DocumentsPreview = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DocumentsPreview" */ '@components/documentsPreview/DocumentsPreview'
    ),
);

interface ISearchDocumentPreviewProps {
  pipelineType: PipelineType;
  searchResults: ISearchResult | null;
  isExternal?: boolean;
}

// TODO: Separate logic for document preview and file preview,
// too much coupling as we keep extending the functionality
const SearchDocumentPreview = ({
  pipelineType,
  searchResults,
  isExternal,
}: ISearchDocumentPreviewProps) => {
  const dispatch = useDispatch();
  const selectedFileDocument = useSelector(selectedFileDocumentSelector);
  const fileContent = useSelector(fileContentSelector);
  const getFileContentStatus = useSelector(getFileContentStatusSelector);
  const [currentActiveSourceIndex, setCurrentActiveSourceIndex] = useState<number>(0);
  const { getSearchResultReferences } = useReferences();

  useEffect(() => {
    dispatch(selectFileDocument(null));
  }, []);

  useEffectUpdateOnly(() => {
    if (!selectedFileDocument || !fileContent) return;
    if (getFileContentStatus === StatusCodes.SUCCESS) {
      dispatch(
        selectFileDocument({
          ...selectedFileDocument,
          contents: [{ value: fileContent, contentId: selectedFileDocument.fileId }],
        }),
      );
    }
  }, [getFileContentStatus]);

  const closeDocumentPreview = () => {
    dispatch(selectFileDocument(null));
    setCurrentActiveSourceIndex(0);
  };

  const getDocuments = (
    result: ISearchResultAnswer | ISearchResultDocument,
  ): ISearchResultDocument[] => {
    const { documents = [] } = searchResults || {};
    if (pipelineType === PipelineType.DOCUMENT_RETRIEVAL)
      return [result] as ISearchResultDocument[];
    if (!documents.length) return documents;

    if (!('document_ids' in result) || !result?.document_ids) return [];

    return result.document_ids.reduce((acc: ISearchResultDocument[], id: string) => {
      const document = documents.find((doc: { id: string }) => doc.id === id);
      if (document) return [...acc, document];
      return acc;
    }, []);
  };

  const getResultByType = (
    searchResultId: string,
  ): ISearchResultAnswer | ISearchResultDocument | null => {
    const resultsByType: Array<ISearchResultAnswer | ISearchResultDocument> | null =
      getResultsByType(searchResults);
    return resultsByType?.find(({ result_id: resultId }) => resultId === searchResultId) ?? null;
  };

  const getSearchResultDocumentsById = (searchResultId: string) => {
    const result = getResultByType(searchResultId);
    if (!result) return [];
    return getDocuments(result);
  };

  const getDocumentsContent = (docs: ISearchResultDocument[]) => {
    if (!docs?.length) return [];
    return docs.map(({ content, id }) => ({
      value: content,
      contentId: id,
    }));
  };

  const getDocumentsMeta = (docs: { meta: Record<string, unknown> }[]) => {
    if (!docs?.length) return {};
    return docs.map(({ meta }: { meta: Record<string, unknown> }) => meta);
  };

  // Highlight data

  const getReferencesHighlightData = (): IHighlightData[] | null => {
    if (!searchResults || !selectedFileDocument?.resultId) return null;

    const { resultId, isDocumentPreview } = selectedFileDocument;

    const references = getSearchResultReferences(searchResults, resultId);
    if (!references?.length) return null;

    const groupedDuplicates = groupParsedDuplicateReferences(references, REFERENCE_LABEL);

    return groupedDuplicates.map((ref) => {
      const referenceText = ref.content.slice(ref.doc_start_idx, ref.doc_end_idx);
      const offsetsInDocumentValue = isDocumentPreview
        ? [
            {
              start: ref.doc_start_idx,
              end: ref.doc_end_idx,
            },
          ]
        : null;

      return {
        offsetsInDocument: offsetsInDocumentValue,
        answer: referenceText,
        context: !isDocumentPreview ? ref.content : null,
        label: ref.highlightLabel,
        contentId: !isDocumentPreview ? ref.file_id : ref.document_id,
      };
    });
  };

  const getExtractiveQAHighlightData = (
    answerResult: ISearchResultAnswer,
  ): IHighlightData[] | null => {
    if (!selectedFileDocument?.resultId) return null;

    const { isDocumentPreview } = selectedFileDocument;

    const {
      answer,
      offsets_in_document: offsetsInDocument,
      context,
      files,
      document_ids: documentIds,
      meta: { page, page_number: pageNumber },
    } = answerResult;
    const [{ id: fileId }] = files;
    const [documentId] = documentIds ?? [];
    const contextValue = !isDocumentPreview ? context : null;
    const offsetsInDocumentValue =
      !isDocumentPreview || !offsetsInDocument.length ? null : offsetsInDocument;
    const contentIdValue = !isDocumentPreview ? fileId : documentId;

    return [
      {
        answer,
        offsetsInDocument: offsetsInDocumentValue,
        context: contextValue,
        page: page || pageNumber,
        contentId: contentIdValue,
      },
    ];
  };

  const getDocRetrievalHighlightData = (
    docResult: ISearchResultDocument,
  ): IHighlightData[] | null => {
    if (!selectedFileDocument?.resultId) return null;

    const { isDocumentPreview } = selectedFileDocument;

    const {
      content,
      file,
      meta: { page, page_number: pageNumber },
    } = docResult;
    const contextValue = !isDocumentPreview ? content : null;
    const contentIdValue = !isDocumentPreview ? file.id : '';

    return [
      {
        context: contextValue,
        page: page || pageNumber,
        contentId: contentIdValue,
      },
    ];
  };

  const getHighlightData = (): IHighlightData[] | null => {
    if (!selectedFileDocument?.resultId) return null;

    const { resultId } = selectedFileDocument;

    const result = getResultByType(resultId);
    if (!result) return null;

    if ('answer' in result) {
      // Reference highlights
      if (pipelineType === PipelineType.GENERATIVE_QUESTION_ANSWERING) {
        return getReferencesHighlightData();
      }

      // Extractive QA answer highlight
      return getExtractiveQAHighlightData(result);
    }

    // Doc retrieval content highlight
    return getDocRetrievalHighlightData(result);
  };

  useEffectUpdateOnly(() => {
    if (!selectedFileDocument?.resultId || !getResultByType(selectedFileDocument.resultId)) return;

    const { fileName, fileId, resultId, isDocumentPreview } = selectedFileDocument;

    const searchResultDocuments = getSearchResultDocumentsById(resultId);
    const documentsGroupedByFileId = getDocumentsGroupedByFileId(searchResultDocuments);

    setCurrentActiveSourceIndex(Object.keys(documentsGroupedByFileId).indexOf(fileId));

    dispatch(
      selectFileDocument({
        fileName,
        fileId,
        resultId,
        isDocumentPreview,
        contents: isDocumentPreview ? getDocumentsContent(documentsGroupedByFileId[fileId]) : [],
        meta: isDocumentPreview ? getDocumentsMeta(documentsGroupedByFileId[fileId]) : undefined,
      }),
    );

    // Get file content for file previews
    if (!isDocumentPreview) dispatch(getFileContent({ fileId, fileName, isExternal }));
  }, [selectedFileDocument?.fileId]);

  const getDocumentsPreviewTitle = () => {
    if (!selectedFileDocument?.isDocumentPreview) return PREVIEW_FILE_DRAWER_TITLE;
    if (pipelineType === PipelineType.GENERATIVE_QUESTION_ANSWERING)
      return PREVIEW_DOCUMENTS_DRAWER_TITLE;

    return PREVIEW_DOCUMENT_DRAWER_TITLE;
  };

  const onBrowsingSourceClick = async ({ isNextButton }: { isNextButton: boolean }) => {
    if (!selectedFileDocument?.resultId || !getResultByType(selectedFileDocument.resultId)) return;

    const nextIndex = currentActiveSourceIndex + (isNextButton ? 1 : -1);

    const currentPreviewSourcesDocuments = getSearchResultDocumentsById(
      selectedFileDocument.resultId,
    );
    const documentsGroupedByFileId = getDocumentsGroupedByFileId(currentPreviewSourcesDocuments);
    const nextFileId = Object.keys(documentsGroupedByFileId)[nextIndex];
    const nextFileName = documentsGroupedByFileId[nextFileId][0].file.name;

    const defaultParams = {
      ...selectedFileDocument,
      fileName: nextFileName,
      fileId: nextFileId,
    };

    if (!selectedFileDocument.isDocumentPreview) {
      dispatch(
        selectFileDocument({
          ...defaultParams,
          contents: [],
        }),
      );
      dispatch(getFileContent({ fileId: nextFileId, fileName: nextFileName, isExternal }));
    } else {
      dispatch(
        selectFileDocument({
          ...defaultParams,
          contents: getDocumentsContent(documentsGroupedByFileId[nextFileId]),
        }),
      );
    }

    setCurrentActiveSourceIndex(nextIndex);
  };

  const isNextItemButtonDisabled = () => {
    if (!selectedFileDocument?.resultId) return true;

    const currentPreviewSourcesDocuments = getSearchResultDocumentsById(
      selectedFileDocument.resultId,
    );
    const documentsGroupedByFileId = getDocumentsGroupedByFileId(currentPreviewSourcesDocuments);
    return currentActiveSourceIndex === Object.keys(documentsGroupedByFileId).length - 1;
  };

  if (!selectedFileDocument?.resultId || !getResultByType(selectedFileDocument.resultId))
    return null;

  return (
    <React.Suspense fallback={<LoadingIndicator />}>
      <DocumentsPreview
        title={getDocumentsPreviewTitle()}
        loading={getFileContentStatus === StatusCodes.IN_PROGRESS}
        contents={selectedFileDocument.contents}
        meta={selectedFileDocument.meta}
        highlightData={getHighlightData()}
        source={selectedFileDocument.fileName}
        displaySourceLabel={selectedFileDocument.isDocumentPreview}
        closeDocumentPreview={closeDocumentPreview}
        displayFooterButtons={pipelineType === PipelineType.GENERATIVE_QUESTION_ANSWERING}
        onNextClick={() => onBrowsingSourceClick({ isNextButton: true })}
        onPreviousClick={() => onBrowsingSourceClick({ isNextButton: false })}
        isPreviousItemButtonDisabled={currentActiveSourceIndex <= 0}
        isNextItemButtonDisabled={isNextItemButtonDisabled()}
      />
    </React.Suspense>
  );
};

export default SearchDocumentPreview;
