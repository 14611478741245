import axios from 'axios';
import {
  AwsMarketplaceLocation,
  IAwsMarketplaceSubscriptionData,
} from '@constants/aws-marketplace';

const AWS_MARKETPLACE_URL_US = import.meta.env.VITE_AWS_MARKETPLACE_URL_US;
const AWS_MARKETPLACE_URL_EU = import.meta.env.VITE_AWS_MARKETPLACE_URL_EU;

export const getResolveCustomerAwsMarketplaceApi = async (token: string, location: string) => {
  let urlRequest = `${AWS_MARKETPLACE_URL_US}/ResolveCustomer`;

  if (location === AwsMarketplaceLocation.EU) {
    urlRequest = `${AWS_MARKETPLACE_URL_EU}/ResolveCustomer`;
  }

  const response = await axios.post(
    urlRequest,
    {
      RegistrationToken: token,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return response.data;
};

export const sendSubscriptionDataAwsMarketplaceApi = async (
  subscriptionData: IAwsMarketplaceSubscriptionData,
) => {
  let urlRequest = `${AWS_MARKETPLACE_URL_US}/Submission`;

  if (subscriptionData.location === AwsMarketplaceLocation.EU) {
    urlRequest = `${AWS_MARKETPLACE_URL_EU}/Submission`;
  }

  const response = await axios.post(
    urlRequest,
    {
      ...subscriptionData,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return response;
};
