import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { DATE_LABEL } from '@constants/pipelines';
import { IAPIPaginationData, IEvalset } from '@redux/types/types';
import DataTable from '@components/dataTable/DataTable';
import styles from './evalSetSelectionTable.module.scss';

interface IEvalSetSelectionTableProps {
  title: string;
  evalSets: IAPIPaginationData<IEvalset[]>;
  evalSetName: string;
  onSelectEvalSet: (evalset: IEvalset) => void;
  onBackButtonPressed?: () => void;
  getEvalSets: (currentPage: number, pageSize: number, searchValue: string) => void;
}

const EvalSetSelectionTable = ({
  title,
  evalSets,
  evalSetName,
  onSelectEvalSet,
  onBackButtonPressed,
  getEvalSets,
}: IEvalSetSelectionTableProps) => {
  const { data, total } = evalSets;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: 'Labels',
      dataIndex: 'total_labels',
      key: 'total_labels',
      width: '20%',
    },
    {
      title: DATE_LABEL,
      dataIndex: 'created_at',
      key: 'created_at',
      width: '30%',
      render: (date: string) => new Date(date).toLocaleString(),
    },
  ];

  const rowSelection = {
    type: 'radio',
    selectedRowKeys: [evalSetName],
    onSelect: onSelectEvalSet,
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.header}>
        {!!onBackButtonPressed && (
          <Button icon={<ArrowLeftOutlined />} type="text" onClick={onBackButtonPressed} />
        )}
        {title}
      </h4>
      <DataTable
        key="evalset-selection-table"
        data={data}
        total={total}
        columns={columns}
        getData={getEvalSets}
        rowSelection={rowSelection}
        rowKey="name"
        searchAvailable={false}
        border
      />
    </div>
  );
};

export default EvalSetSelectionTable;
