class AbortControllerManager {
  private static instance: AbortControllerManager;

  private controllers: Map<string, AbortController> = new Map();

  static getInstance(): AbortControllerManager {
    if (!AbortControllerManager.instance)
      AbortControllerManager.instance = new AbortControllerManager();

    return AbortControllerManager.instance;
  }

  create(key: string): AbortController {
    if (this.controllers.has(key)) this.abort(key);

    const controller = new AbortController();
    this.controllers.set(key, controller);
    return controller;
  }

  get(key: string): AbortController | undefined {
    return this.controllers.get(key);
  }

  abort(key: string): void {
    const controller = this.controllers.get(key);
    if (controller) {
      controller.abort();
      this.controllers.delete(key);
    }
  }

  abortAll(): void {
    this.controllers.forEach((controller) => controller.abort());
    this.controllers.clear();
  }
}

export default AbortControllerManager;
