import { getWorkspace } from '@utils/store';
import { NUMBER_OF_FILES_PER_PAGE } from '@constants/common';
import { MIMETypes } from '@constants/enum/common';
import { IAPIPaginationData } from '@redux/types/types';
import { http } from './base-http';

export interface IAllExperimentRunsData {
  limit?: number;
  page_number?: number;
  after?: string;
  filter?: string;
  select?: string;
  field?: string;
  order?: string;
}

/* Get experiment evaluation run */
export function getEvalRunApi(name: string, workspaceName: string = getWorkspace()) {
  return http.get(`/api/v1/workspaces/${workspaceName}/eval_runs/${name}`);
}

/* Get experiment evaluation runs */
export function getEvalRunsApi<T>(
  params?: IAllExperimentRunsData,
  workspaceName: string = getWorkspace(),
) {
  const parameterMap = {
    limit: params?.limit || NUMBER_OF_FILES_PER_PAGE,
    ...(params?.after && { after: params?.after }),
    ...(params?.page_number && { page_number: params?.page_number }),
    ...(params?.filter && { filter: params?.filter }),
    ...(params?.select && { select: params?.select }),
    ...(params?.field && { field: params?.field }),
    ...(params?.order && { order: params?.order }),
  };
  return http.get<T>(`/api/v1/workspaces/${workspaceName}/eval_runs`, { params: parameterMap });
}

/* Get experiment evaluation runs recursively */
export const getRecursivelyEvalRunsApi = async <T extends unknown[]>(
  params?: IAllExperimentRunsData,
  workspaceName: string = getWorkspace(),
): Promise<T | unknown[]> => {
  const {
    data: { data, has_more: hasMore },
  } = await getEvalRunsApi<IAPIPaginationData<T>>(params, workspaceName);

  if (!hasMore) return data;

  const nextPage = params?.page_number ? params.page_number + 1 : 2;
  const nextParams = { ...params, page_number: nextPage };

  return [...data, ...(await getRecursivelyEvalRunsApi(nextParams, workspaceName))];
};

/* Create a experiment evaluation run */
export function createEvalRunApi(
  name: string,
  pipelineName: string,
  evalsetName: string,
  tags: string[],
  workspaceName: string = getWorkspace(),
) {
  return http.post(`/api/v1/workspaces/${workspaceName}/eval_runs`, {
    name,
    pipeline_name: pipelineName,
    evaluation_set_name: evalsetName,
    tags,
    debug: true, // TOFIX: Remove after api deployed https://deepset-ai.slack.com/archives/C02LJMXL53R/p1698223926755859
  });
}

/* Patch a experiment evaluation run */
export function updateEvalRunApi(
  name: string,
  pipelineName?: string,
  evalSetName?: string,
  comment?: string,
  tags?: string[],
  workspaceName: string = getWorkspace(),
) {
  return http.patch(`/api/v1/workspaces/${workspaceName}/eval_runs/${name}`, {
    ...(pipelineName && { pipeline_name: pipelineName }),
    ...(evalSetName && { evaluation_set_name: evalSetName }),
    ...(comment && { comment }),
    ...(tags && { tags }),
  });
}

/* Patch a experiment evaluation run comment */
export function updateEvalRunCommentApi(
  name: string,
  comment: string,
  workspaceName: string = getWorkspace(),
) {
  return http.patch(`/api/v1/workspaces/${workspaceName}/eval_runs/${name}`, {
    comment,
  });
}

/* Create an experiment evaluation runs */
export function startEvalRunApi(runName: string, workspaceName: string = getWorkspace()) {
  return http.post(`/api/v1/workspaces/${workspaceName}/eval_runs/${runName}/start`);
}

/* Delete an experiment evaluation run */
export function deleteEvalRunApi(runName: string, workspaceName: string = getWorkspace()) {
  return http.delete(`/api/v1/workspaces/${workspaceName}/eval_runs/${runName}`);
}

/* Get an experiment evaluation run predictions */
export function getRunPredictionsApi(
  runName: string,
  nodeName: string,
  params?: IAllExperimentRunsData,
  workspaceName: string = getWorkspace(),
) {
  const parameterMap = {
    limit: params?.limit || NUMBER_OF_FILES_PER_PAGE,
    ...(params?.page_number && { page_number: params?.page_number }),
    ...(params?.field && { field: params?.field }),
    ...(params?.order && { order: params?.order }),
    ...(params?.filter && { filter: params?.filter }),
  };
  return http.get(
    `/api/v1/workspaces/${workspaceName}/eval_runs/${runName}/nodes/${nodeName}/predictions`,
    { params: parameterMap },
  );
}

/* Get an experiment evaluation run predictions as CSV */
export function getEvalRunCSVApi(
  runName: string,
  nodeName: string,
  workspaceName: string = getWorkspace(),
) {
  return http.get(
    `/api/v1/workspaces/${workspaceName}/eval_runs/${runName}/nodes/${nodeName}/predictions`,
    {
      headers: {
        Accept: MIMETypes.CSV,
      },
      responseType: 'blob',
    },
  );
}
