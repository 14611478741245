export enum CustomHTTPHeaders {
  X_CLIENT_SOURCE = 'X-Client-Source',
  X_CLIENT_SOURCE_PATH = 'X-Client-Source-Path',
}

export enum ClientSourcePathHeaderOptions {
  PROMPT_EXPLORER = 'prompt-explorer',
  DOC_CHAT = 'doc-chat',
  CHAT = 'chat',
}
