import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalizeErrorMessage } from '@utils/error';
import {
  createOrgSecretApi,
  deleteOrgSecretApi,
  getOrgSecretsApi,
  ISecretPayload,
} from '@api/secrets';
import {
  SECRET_ADDED_SUCCESS_MESSAGE,
  SECRET_DELETED_SUCCESS_MESSAGE,
} from '@constants/secrets-management';
import {
  ADD_ORGANIZATION_SECRET,
  DELETE_ORGANIZATION_SECRET,
  GET_ORGANIZATION_SECRETS,
  NotificationType,
  RESET_SECRETS_MANAGEMENT_STATUSES,
} from '@redux/types/types';
import { addNotification } from './notificationActions';

export const resetSecretsManagementStatuses = { type: RESET_SECRETS_MANAGEMENT_STATUSES };

export const getOrganizationSecrets = createAsyncThunk(
  GET_ORGANIZATION_SECRETS,
  async (
    { limit, pageNumber }: { limit?: number; pageNumber?: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const params = {
        limit,
        page_number: pageNumber,
      };
      const { data } = await getOrgSecretsApi(params);
      return data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const addOrganizationSecret = createAsyncThunk(
  ADD_ORGANIZATION_SECRET,
  async (secret: ISecretPayload, { rejectWithValue, dispatch }) => {
    try {
      await createOrgSecretApi(secret);
      dispatch(
        addNotification({ content: SECRET_ADDED_SUCCESS_MESSAGE, type: NotificationType.Success }),
      );
    } catch (error) {
      dispatch(
        addNotification({ content: normalizeErrorMessage(error), type: NotificationType.Error }),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteOrganizationSecret = createAsyncThunk(
  DELETE_ORGANIZATION_SECRET,
  async (secretId: string, { rejectWithValue, dispatch }) => {
    try {
      await deleteOrgSecretApi(secretId);
      dispatch(
        addNotification({
          content: SECRET_DELETED_SUCCESS_MESSAGE,
          type: NotificationType.Success,
        }),
      );
    } catch (error) {
      dispatch(
        addNotification({ content: normalizeErrorMessage(error), type: NotificationType.Error }),
      );
      return rejectWithValue(error);
    }
  },
);
