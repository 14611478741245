import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Alert, Button, Empty, Space, Tooltip } from 'antd';
import { interpolateString } from '@utils/string';
import { importEvalsetApi } from '@api/data';
import { DOCUMENTATION_LINK_LABEL } from '@constants/common';
import { UploadFileType } from '@constants/constant';
import {
  DECLINE_BUTTON_LABEL,
  DELETE_BUTTON_LABEL,
  DELETE_FILE_CONFIRMATION_MESSAGE,
  DELETE_SELECTED_EVALSET_PLURAL_LABEL,
  DELETE_SELECTED_EVALSET_SINGULAR_LABEL,
  DELETE_SELECTED_FILE_MESSAGE,
  DOWNLOAD_BUTTON_LABEL_EVAL_SET,
  DOWNLOAD_CSV_BUTTON_LABEL,
  EMPTY_EVALSET_PAGE_SECTION,
  EMPTY_EVALSET_TABLE_MESSAGE,
  EVALSET_DOCS_LINK,
  LABELS_WARNING_TOOLTIP,
  UPLOAD_BUTTON_LABEL,
  UPLOAD_EVALSET_BUTTON_LABEL,
} from '@constants/data-page';
import { StatusCodes } from '@constants/enum/common';
import {
  deleteEvalset,
  deleteMultipleEvalsets,
  exportEvalset,
  exportEvalsetCSV,
  getWorkspaceEvalsets,
  resetEvalsetMessage,
} from '@redux/actions/evalsetActions';
import {
  evalsetActionStatusSelector,
  evalsetFetchStatusSelector,
  evalsetMessageSelector,
  evalsetSelector,
} from '@redux/selectors/evalsetSelectors';
import { IMessage } from '@redux/types/types';
import DataTable from '@components/dataTable/DataTable';
import DataTableActions from '@components/dataTable/DataTableActions';
import EmptyPageDescription from '@components/emptyPageDescription/EmptyPageDescription';
import UploadFileModal from '@components/uploadModal/UploadFileModal';
import styles from './evalsetPage.module.scss';

const EvalsetLandingSVG = React.lazy(() => import('@assets/empty/evalset-landing.svg?react'));

const EvalsetPage = () => {
  const dispatch = useDispatch();
  const message: IMessage = useSelector(evalsetMessageSelector);
  const actionStatus: StatusCodes = useSelector(evalsetActionStatusSelector);
  const fetchStatus: StatusCodes = useSelector(evalsetFetchStatusSelector);
  const { data, total }: any = useSelector(evalsetSelector);
  const [evalsetToDelete, setEvalsetToDelete] = useState('');
  const [totalSelectedItems, setTotalSelectedItems] = useState(0);
  const [isUploadModalVisible, setIsUploadModalVisibleTo] = useState(false);
  const loading =
    actionStatus === StatusCodes.IN_PROGRESS || fetchStatus === StatusCodes.IN_PROGRESS;

  enum MenuActions {
    Delete = 'DELETE',
    Download = 'DOWNLOAD',
    DownloadCSV = 'DOWNLOAD_CSV',
  }

  useEffect(() => {
    dispatch(resetEvalsetMessage);
  }, [dispatch]);

  const getEvalsets = (currentPage: number, pageSize: number, searchValue: string) => {
    dispatch(getWorkspaceEvalsets({ currentPage, pageSize, searchValue }));
  };

  const onDeleteEvalset = async (evalsetName: string) => {
    setEvalsetToDelete('');
    dispatch(deleteEvalset(evalsetName));
  };

  const onDeleteMultipleEvalsets = (evalsetNames: string[]) => {
    dispatch(deleteMultipleEvalsets(evalsetNames));
  };

  const onImport = () => {
    setIsUploadModalVisibleTo(true);
  };

  const handleMoreActionClick = (key: string, name: string) => {
    if (key === MenuActions.Delete) setEvalsetToDelete(name);
    if (key === MenuActions.Download) dispatch(exportEvalset(name));
    if (key === MenuActions.DownloadCSV) dispatch(exportEvalsetCSV(name));
  };

  const onUpload = () => {
    setIsUploadModalVisibleTo(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Labels',
      dataIndex: 'total_labels',
      key: 'total_labels',
      width: '10%',
      render: (text: any, record: any) => (
        <Space>
          {record.total_labels}
          {record.matched_labels < record.total_labels && (
            <Tooltip placement="top" title={LABELS_WARNING_TOOLTIP}>
              <WarningOutlined className={styles.warningTooltip} />
            </Tooltip>
          )}
        </Space>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',
      render: (created_at: string) => new Date(created_at).toLocaleString(),
    },
    {
      key: 'action',
      width: '10%',
      align: 'right' as const,
      render: (_: any, record: any) => {
        const items = [
          {
            label: DOWNLOAD_BUTTON_LABEL_EVAL_SET,
            key: MenuActions.Download,
          },
          {
            label: DOWNLOAD_CSV_BUTTON_LABEL,
            key: MenuActions.DownloadCSV,
          },
          {
            label: DELETE_BUTTON_LABEL,
            key: MenuActions.Delete,
            danger: true,
            icon: <DeleteOutlined />,
          },
        ];
        return (
          <DataTableActions
            menu={{ items, onClick: ({ key }) => handleMoreActionClick(key, record.name) }}
            item={record.name}
            itemToDelete={evalsetToDelete}
            onDelete={onDeleteEvalset}
            onCancelDelete={() => setEvalsetToDelete('')}
            deleteConfirmationMessage={DELETE_FILE_CONFIRMATION_MESSAGE}
            cancelButtonLabel={DECLINE_BUTTON_LABEL}
          />
        );
      },
    },
  ];

  const emptyEvalSetTableMessage = interpolateString(EMPTY_EVALSET_TABLE_MESSAGE, {
    uploadEvalSetsButton: (
      <Button key="uploadFile" type="link" onClick={() => onUpload()} style={{ padding: 0 }}>
        {UPLOAD_BUTTON_LABEL}
      </Button>
    ),
  });

  const renderEmptyEvalsetState = () => {
    return (
      <EmptyPageDescription
        title={EMPTY_EVALSET_PAGE_SECTION.TITLE}
        description={EMPTY_EVALSET_PAGE_SECTION.DESCRIPTION.map(({ paragraph }) => ({
          paragraph: interpolateString(paragraph, {
            documentationLink: (
              <a href={EVALSET_DOCS_LINK} target="_blank" rel="noreferrer">
                {DOCUMENTATION_LINK_LABEL}
              </a>
            ),
          }),
        }))}
        image={
          <a href={EVALSET_DOCS_LINK} target="_blank" rel="noreferrer">
            <EvalsetLandingSVG />
          </a>
        }
        action={
          <Button
            icon={<CloudUploadOutlined />}
            key="uploadFile"
            type="primary"
            onClick={() => onUpload()}
          >
            {UPLOAD_EVALSET_BUTTON_LABEL}
          </Button>
        }
      />
    );
  };

  if (total === 0) return renderEmptyEvalsetState();

  return (
    <>
      {isUploadModalVisible && (
        <UploadFileModal
          title={UPLOAD_EVALSET_BUTTON_LABEL}
          multiple
          fileTypes={[UploadFileType.csv]}
          showUploadList={false}
          helpLink={EVALSET_DOCS_LINK}
          onUpload={importEvalsetApi}
          onCancel={() => setIsUploadModalVisibleTo(false)}
          afterUpload={() => getEvalsets(1, 10, '')}
          showHintDescription
        />
      )}
      {message && message.content && <Alert message={message.content} type={message.type} banner />}
      <div className="content-wrapper">
        <DataTable
          data={data}
          total={total}
          loading={loading}
          refetch={actionStatus === StatusCodes.SUCCESS}
          columns={columns}
          getData={getEvalsets}
          primaryAction={{
            label: UPLOAD_EVALSET_BUTTON_LABEL,
            onClick: onImport,
            icon: <UploadOutlined />,
          }}
          rowKey="name"
          selectActions={[
            {
              type: 'default',
              danger: true,
              label: (totalSelectedItems === 1
                ? interpolateString(DELETE_SELECTED_EVALSET_SINGULAR_LABEL, {
                    total: totalSelectedItems,
                  })
                : interpolateString(DELETE_SELECTED_EVALSET_PLURAL_LABEL, {
                    total: totalSelectedItems,
                  })) as string,
              onClick: onDeleteMultipleEvalsets,
              popconfirm: {
                title: DELETE_SELECTED_FILE_MESSAGE,
                cancelText: DECLINE_BUTTON_LABEL,
              },
            },
          ]}
          setTotalSelectedItems={(totalFilesSelected) => setTotalSelectedItems(totalFilesSelected)}
          locale={{
            emptyText: (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyEvalSetTableMessage} />
            ),
          }}
        />
      </div>
    </>
  );
};

export default EvalsetPage;
