import React from 'react';
import styles from './conversationalInfoText.module.scss';

interface IConversationalInfoTextProps {
  text: string;
}

const ConversationalInfoText = ({ text }: IConversationalInfoTextProps) => (
  <div className={styles.container}>{text}</div>
);

export default ConversationalInfoText;
