import React from 'react';
import { theme } from 'antd';

const { useToken } = theme;

const SearchAnimationSVG = () => {
  const { token } = useToken();
  return (
    <svg
      id="e8XFCI5qgUD1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 300 300"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <path
        d="M206,212.863h-115.5195c-9.71,0-17.6096-7.899-17.6096-17.609v-115.5197c0-9.71,7.8996-17.6096,17.6096-17.6096h115.5195c9.71,0,17.609,7.8996,17.609,17.6096v115.5197c0,9.71-7.899,17.609-17.609,17.609ZM90.4805,64.9422c-8.1694,0-14.792,6.6227-14.792,14.7921v115.5197c0,8.169,6.6226,14.792,14.792,14.792h80.9185c27.279,0,49.393-22.114,49.393-49.393v-80.9187c0-8.1694-6.623-14.7921-14.792-14.7921h-115.5195v0Z"
        fill="#f0f2f5"
      />
      <path
        id="e8XFCI5qgUD3"
        d="M102.935,76.955c-5.4678,0-9.9156,4.4478-9.9156,9.9152v15.5198c0,5.467,4.4478,9.915,9.9156,9.915h15.519c5.468,0,9.915-4.448,9.915-9.915v-15.5198c0-5.4674-4.447-9.9152-9.915-9.9152h-15.519Z"
        fill="#f0f2f5"
      />
      <path
        id="e8XFCI5qgUD4"
        d="M102.935,120.644c-5.4678,0-9.9156,4.448-9.9156,9.916v15.519c0,5.467,4.4478,9.915,9.9156,9.915h15.519c5.468,0,9.915-4.448,9.915-9.915v-15.519c0-5.468-4.447-9.916-9.915-9.916h-15.519Z"
        fill="#f0f2f5"
      />
      <path
        id="e8XFCI5qgUD5"
        d="M102.935,164.334c-5.4678,0-9.9156,4.447-9.9156,9.915v15.519c0,5.468,4.4478,9.916,9.9156,9.916h15.519c5.468,0,9.915-4.448,9.915-9.916v-15.519c0-5.468-4.447-9.915-9.915-9.915h-15.519Z"
        transform="translate(-.004202 0)"
        fill="#f0f2f5"
      />
      <g id="e8XFCI5qgUD6" transform="matrix(.999387 0.035007-.035007 0.999387 10.135088-5.224431)">
        <g transform="translate(.000001 0.000001)">
          <path
            d="M149.617,290.568l-3.973-.034-1.758-15.68l5.863.049-.132,15.665Z"
            fill="#a0616a"
          />
          <path
            d="M136.892,296.885c-.009.978.78,1.783,1.761,1.792l7.899.065l1.382-2.802.508,2.817l2.98.027-.752-10.048-1.037-.069-4.229-.291-1.365-.092-.024,2.843-6.345,4.304c-.482.328-.773.871-.778,1.454v0Z"
            fill="#2f2e41"
          />
        </g>
        <g transform="translate(0 0.000001)">
          <path
            d="M169.829,290.568l-3.972-.034-1.759-15.68l5.863.049-.132,15.665Z"
            fill="#a0616a"
          />
          <path
            d="M157.104,296.885c-.008.978.78,1.783,1.761,1.792l7.899.065l1.383-2.802.507,2.817l2.981.027-.753-10.048-1.037-.069-4.229-.291-1.364-.092-.024,2.843-6.345,4.304c-.483.328-.774.871-.779,1.454Z"
            fill="#2f2e41"
          />
        </g>
      </g>
      <path
        id="e8XFCI5qgUD13"
        d="M134.028,163.793l16.103.509l15.255,3.39c0,0-1.525,1.864,3.56,6.95c0,0,6.78,11.865,2.034,23.391l-2.034,46.106c0,0,5.93,32.501,1.399,42.845l-5.989.374-10.133-42.844-4.94-46.142L146.91,243.8c0,0,9.079,31.18,3.346,43.558l-6.486-.374L132.672,243.8c0,0-4.746-49.835-4.068-59.327s5.424-20.68,5.424-20.68v0Z"
        fill="#2f2e41"
      />
      <g id="e8XFCI5qgUD14" transform="translate(.000001 0)">
        <g id="e8XFCI5qgUD15" transform="translate(.997841 2.000001)">
          <path
            d="M113.226,39.0909c-6.889,0-12.493,5.6044-12.493,12.4935v19.555c0,6.8891,5.604,12.4935,12.493,12.4935h19.555c6.889,0,12.494-5.6044,12.494-12.4935v-19.555c0-6.8891-5.605-12.4935-12.494-12.4935h-19.555Z"
            fill={token.colorPrimary}
          />
          <g transform="translate(.000001 0)">
            <path
              d="M128.038,79.9118c1.162,1.9546.52,4.4811-1.435,5.6431-.896.5324-1.91.6818-2.86.5039l-.004.0041-5.103,5.5914-5.997-2.173l7.867-8.5912.028.0292c.273-.9883.91-1.8775,1.861-2.4428c1.955-1.162,4.481-.5194,5.643,1.4353Z"
              fill="#a0616a"
            />
            <path
              d="M130.243,121.624l-29.176-7.881c-1.7372-.47-3.0867-1.778-3.6103-3.499-.5236-1.722-.1305-3.56,1.0513-4.917l16.896-19.3985l6.514,3.306-11.58,16.2805l22.543,3.079c2.72.371,4.904,2.333,5.564,4.999.573,2.314-.102,4.692-1.805,6.359-1.27,1.244-2.922,1.908-4.634,1.907-.585,0-1.177-.077-1.763-.235v0Z"
              fill="#3f3d56"
            />
          </g>
        </g>
        <g id="e8XFCI5qgUD20" transform="translate(.000001 0.000001)">
          <path
            d="M177.606,93.739c-6.889,0-12.493,5.6043-12.493,12.493v19.555c0,6.89,5.604,12.494,12.493,12.494h19.555c6.889,0,12.493-5.604,12.493-12.494v-19.555c0-6.8887-5.604-12.493-12.493-12.493h-19.555Z"
            fill={token.colorPrimary}
          />
          <g>
            <path
              d="M188.105,90.9395c-2.201.5699-3.524,2.8166-2.954,5.0179.262,1.0087.877,1.8293,1.677,2.3714l-.001.0056-.312,7.5636l5.789,2.679.462-11.6396-.041.0012c.502-.8941.676-1.9744.398-3.0454-.57-2.2013-2.816-3.5237-5.018-2.9537v0Z"
              fill="#a0616a"
            />
            <path
              d="M155.754,117.935c-1.216-1.205-1.921-2.84-1.948-4.617-.035-2.383,1.159-4.547,3.196-5.787c2.345-1.428,5.277-1.284,7.471.368l18.176,13.685l3.239-19.715l6.953,2.238-1.659,25.672c-.116,1.796-1.131,3.378-2.715,4.232s-3.463.832-5.027-.057l-26.268-14.945c-.527-.3-1.003-.662-1.418-1.074v0Z"
              fill="#3f3d56"
            />
          </g>
        </g>
        <path
          d="M171.838,170.576l-40.799,1.872c-1.12-1.702-.817-4.261.752-7.465c5.925-12.097,1.426-34.983-1.601-46.9-.85-3.343,1.43-6.667,4.863-7.011l3.847-.384l3.556-10.855h12.453l5.167,5.903l8.768,5.326c-1.596,16.439-8.396,39.653,1.291,51.294c2.119,2.546,2.72,5.3,1.703,8.22Z"
          fill="#3f3d56"
        />
        <g transform="translate(.000001 0.000001)">
          <path
            d="M147.098,100.084c6.7,0,12.13-5.4312,12.13-12.1305c0-6.6994-5.43-12.1303-12.13-12.1303-6.699,0-12.13,5.4309-12.13,12.1303c0,6.6993,5.431,12.1305,12.13,12.1305Z"
            fill="#a0616a"
          />
          <path
            d="M160.825,80.1889c1.687-6.1531-16.707-11.4295-20.281-6.4744-.497-.7045-2.334-1.1324-3.177-.9163s-1.552.7564-2.243,1.2793c-.951.7305-1.936,1.4868-2.52,2.5371-.588,1.0459-.678,2.4722.113,3.3798.626.7218,1.727,4.5573,2.67,4.7475.657.1339,1.21.242,1.685.3198.424-.6181,1.504-1.3944,1.426-2.1421.64.4235.406,1.1687.277,1.9303-.429,2.5361-10.104,22.1681-4.563,16.3831.549.324,1.227.627,2.01.903c1.327-2.001,2.416-4.3607,3.133-6.8286l.005.0456c.247,2.1446,1.815,3.9044,3.888,4.5051c8.318,2.4099,15.047-1.1262,17.361-7.5029-.847-1.7201-1.216-1.5333-1.14-1.5844c1.057-.7129,2.511-.2467,2.966.9451.134.3513.256.6513.361.8771c1.206-4.3135,2.64-3.6909-1.971-12.4041v0Z"
            fill="#2f2e41"
          />
          <path
            d="M155.086,75.7247l-.849-4.3439c-.072-.3652-.14-.7613.025-1.095.212-.4287.755-.6064,1.229-.5381.473.0684.827.5006,1.297.5838c1.637.2893,3.801-1.3273,4.387,2.7626.245,1.7052,2.966,1.8853,3.874,3.3488.909,1.4635,1.021,3.5759-.22,4.7715-.99.9549-2.581,1.0651-3.867.5773-1.287-.4878-2.319-1.473-3.221-2.512s-1.718-2.1634-2.759-3.0638"
            fill="#2f2e41"
          />
          <path
            d="M161.473,82.3316c-3.32-.4637-5.582-1.6708-6.725-3.5876-1.496-2.5092-.494-5.2721-.45-5.3885l.701.2608c-.009.0254-.918,2.5545.396,4.7508c1.022,1.7085,3.102,2.793,6.181,3.2232l-.103.7413Z"
            fill={token.colorPrimary}
          />
        </g>
      </g>
      <path
        id="e8XFCI5qgUD31"
        d="M159.351,17.6978c0,9.7579,7.94,17.6977,17.698,17.6977c3.629,0,7.007-1.0982,9.818-2.9798l14.992,14.0496c.682.6412,1.552.9575,2.419.9575.944,0,1.886-.3751,2.582-1.12c1.338-1.4258,1.265-3.6657-.162-5.0017L191.852,27.3879c1.829-2.7855,2.895-6.1159,2.895-9.6901C194.747,7.93979,186.807,0,177.049,0s-17.698,7.93979-17.698,17.6978Zm7.079,0c0-5.8555,4.763-10.61869,10.619-10.61869c5.855,0,10.618,4.76319,10.618,10.61869s-4.763,10.6186-10.618,10.6186c-5.856,0-10.619-4.7632-10.619-10.6186Z"
        transform="translate(-.004364-.001399)"
        clipRule="evenodd"
        fill={token.colorPrimary}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SearchAnimationSVG;
