import { AxiosResponse } from 'axios';
import _get from 'lodash/get';

import { transformDateFormat } from '@utils/common';
import { formatNumberToLocaleString } from '@utils/math';

export interface IWorkspaceStatisticData {
  fileCount: number;
  documentCount: number;
  averageResponseTime: number;
  searchRequestCount: number;
}

export function transformStatistic(statisticResponse: AxiosResponse<any>): IWorkspaceStatisticData {
  const fileCount = _get(statisticResponse, 'file_count', 0);
  const documentCount = _get(statisticResponse, 'document_count', 0);
  const searchRequestCount = _get(statisticResponse, 'search_request_count', 0);
  const averageResponseTime =
    Math.round(_get(statisticResponse, 'average_response_time', 0) * 100) / 100;

  return {
    fileCount,
    documentCount,
    averageResponseTime,
    searchRequestCount,
  };
}

export interface ISearchCountData {
  count: number;
  date: string;
}

export function transformSearchCount(searchCountResponse: AxiosResponse<any>): ISearchCountData[] {
  const data = _get(searchCountResponse, 'data', []);
  const transformedData = data.map((item: ISearchCountData) => {
    return {
      date: transformDateFormat(item.date),
      count: item.count,
    };
  });

  return transformedData;
}

export interface IHistoryData {
  key: string;
  query: string;
  topAnswer: string;
  topFiles: string[];
  pipeline: string;
  user: string;
  time: number;
}

export function transformHistory(historyResponse: AxiosResponse<any>): IHistoryData[] {
  const data = _get(historyResponse, 'data', []);
  const getTopFiles = (item: any) => {
    const resultFileName = _get(item, 'response[0].result.files', null)?.map(
      (file: any) => file.name,
    );
    const documentsFileName = _get(item, 'response[0].documents[0].file.name', null);
    if (resultFileName) return resultFileName;
    if (documentsFileName) return [documentsFileName];
    return ['-'];
  };
  const transformedData: IHistoryData[] = data.map((item: any) => {
    return {
      key: _get(item, 'search_history_id', '-'),
      query: _get(item, 'request.query', '-'),
      topAnswer: _get(item, 'response[0].result.answer', '-'),
      topFiles: getTopFiles(item),
      pipeline: _get(item, 'pipeline.name', '-'),
      user: `${_get(item, 'user.given_name', '')} ${_get(item, 'user.family_name', '')}`,
      time: `${formatNumberToLocaleString(_get(item, 'duration', '-'), true)}s`,
    };
  });

  return transformedData;
}
