import React from 'react';
import { Divider } from 'antd';
import {
  getDocumentsGroupedByFileId,
  getResultsByType,
  getSearchResultPipelineType,
} from '@utils/search';
import { interpolateString } from '@utils/string';
import { StatusCodes } from '@constants/enum/common';
import { PipelineType } from '@constants/pipelines';
import {
  MORE_OPTIONS_VIEW_PROMPT_LABEL,
  RESULTS_NUM_INFO_BY_PIPELINE_TYPE,
} from '@constants/search';
import {
  ISearchResult,
  ISearchResultAnswer,
  ISearchResultDocument,
  SearchResultMoreOption,
} from '@redux/types/types';
import ReferenceDrawer from '@components/referenceDrawer/ReferenceDrawer';
import PromptModal from '@components/search/features/PromptModal/PromptModal';
import usePromptHook from '@components/search/features/PromptModal/usePromptHook';
import styles from './searchResultsContainer.module.scss';
import SearchDocumentPreview from '../documentPreview/SearchDocumentPreview';
import { IExposedDocumentResultProps } from '../result/DocumentResult';
import ResultContainer from '../result/ResultContainer';

interface ISearchResultsContainer {
  isExternal?: boolean;
  results: ISearchResult;
  status: StatusCodes;
  query: string;
  pipelineName: string;
  pipelineId: string;
  header?: React.ReactElement;
  resultFeedback?: (
    result: ISearchResultAnswer | ISearchResultDocument,
    idx: number,
  ) => React.ReactElement | null;
  displayFileOptions?: boolean;
  displayMoreOptions?: boolean;
  displayRelevanceScore?: boolean;
  displayMetadata?: boolean;
  displayReferencesPopover?: boolean;
  documentResultOptions?: IExposedDocumentResultProps;
}

// TODO: Group customization props
const SearchResultsContainer = ({
  isExternal,
  displayFileOptions,
  displayMoreOptions,
  displayMetadata,
  displayRelevanceScore,
  displayReferencesPopover,
  documentResultOptions,
  results,
  status,
  query,
  pipelineId,
  pipelineName,
  header,
  resultFeedback,
}: ISearchResultsContainer) => {
  const { openPromptModal } = usePromptHook();

  const pipelineType = getSearchResultPipelineType(results);

  const getDocuments = (
    result: ISearchResultAnswer | ISearchResultDocument,
  ): ISearchResultDocument[] => {
    const { documents = [] } = results;
    if (pipelineType === PipelineType.DOCUMENT_RETRIEVAL)
      return [result] as ISearchResultDocument[];
    if (!documents.length) return documents;

    if (!('document_ids' in result) || !result?.document_ids) return [];

    return result.document_ids.reduce((acc: ISearchResultDocument[], id: string) => {
      const document = documents.find((doc: { id: string }) => doc.id === id);
      if (document) return [...acc, document];
      return acc;
    }, []);
  };

  const onMoreOptionsDropdownMenuItemClick = ({ key }: { key: string }) => {
    if (key === SearchResultMoreOption.VIEW_PROMPT) openPromptModal(results.prompts);
  };

  const getMoreOptionsDropdownMenuItems = () => {
    if (pipelineType !== PipelineType.GENERATIVE_QUESTION_ANSWERING) return [];
    if (!results.prompts) return [];

    return [
      {
        key: SearchResultMoreOption.VIEW_PROMPT,
        label: MORE_OPTIONS_VIEW_PROMPT_LABEL,
      },
    ];
  };

  const renderSearchResultsInfoBar = () => {
    const searchResults = getResultsByType(results);
    const numOfResults = searchResults.length;

    return (
      <div className={styles.results_infoBar}>
        <div className={styles.results_infoBar_answers}>
          {interpolateString(RESULTS_NUM_INFO_BY_PIPELINE_TYPE[pipelineType], {
            numResults: <span>{numOfResults}</span>,
          })}
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    if (header) return header;
    return (
      <>
        <h6 className={styles.results_queryTitle}>{query}</h6>
        <Divider className={styles.results_divider} />
        {renderSearchResultsInfoBar()}
        <Divider className={styles.results_divider} />
        <Divider className={styles.results_divider} />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <div className={styles.results_list}>
        {getResultsByType(results).map((result, idx: number) => {
          const documents = getDocuments(result);
          return (
            <ResultContainer
              key={result.result_id}
              isExternal={isExternal}
              searchResult={result}
              searchStatus={status}
              documentsGroupedByFileId={getDocumentsGroupedByFileId(documents)}
              documents={documents}
              queryId={results.query_id}
              pipelineId={pipelineId}
              pipelineName={pipelineName}
              pipelineType={pipelineType}
              displayFileOptions={displayFileOptions}
              displayMoreOptions={displayMoreOptions}
              displayRelevanceScore={displayRelevanceScore}
              displayMetadata={displayMetadata}
              displayReferencesPopover={displayReferencesPopover}
              documentResultOptions={documentResultOptions}
              sourcesDefaultExpanded={idx === 0}
              feedback={resultFeedback ? () => resultFeedback(result, idx) : null}
              moreOptionsDropdownMenuItems={getMoreOptionsDropdownMenuItems()}
              onMoreOptionsDropdownMenuItemClick={onMoreOptionsDropdownMenuItemClick}
            />
          );
        })}
      </div>
      <SearchDocumentPreview
        pipelineType={pipelineType}
        searchResults={results}
        isExternal={isExternal}
      />
      <ReferenceDrawer resultExt={results} isExternal={isExternal} />
      <PromptModal />
    </>
  );
};

export default SearchResultsContainer;
