import { useState } from 'react';
import { SupportedViewerLanguage } from '@constants/enum/common';

const useFilePreviewFormat = () => {
  const [showRawCodeFormat, setShowRawCodeFormat] = useState(false);
  const supportedRawFilePreviewLanguagues = [SupportedViewerLanguage.MARKDOWN];

  return { supportedRawFilePreviewLanguagues, showRawCodeFormat, setShowRawCodeFormat };
};

export default useFilePreviewFormat;
