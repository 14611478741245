import React, { useEffect, useState } from 'react';
import { Button, Drawer, Select } from 'antd';
import { isNil } from 'lodash';
import {
  CANCEL_BUTTON_LABEL,
  DONE_BUTTON_LABEL,
  EDIT_BUTTON_LABEL,
  EDIT_EVALUATION_SECTION_HEADER,
  EDIT_EXPERIMENT_HEADER,
  EDIT_PIPELINE_SECTION_HEADER,
  EVALUATION_SECTION_TITLE,
  PIPELINE_SECTION_TITLE,
  SAVE_BUTTON_LABEL,
  TAGS_SELECT_LABEL,
  TAGS_SELECT_PLACEHOLDER,
} from '@constants/experiments';
import {
  IAPIPaginationData,
  IEvalset,
  IExperiment,
  IPipeline,
  ITag,
  RunState,
} from '@redux/types/types';
import EvalSetSelectionTable from '@components/evalRuns/evalSetSelectionTable/EvalSetSelectionTable';
import PipelineSelectionTable from '@components/pipelineSelectionTable/PipelineSelectionTable';
import styles from './editEvalRun.module.scss';

enum EditEvalRunContentState {
  DEFAULT = 'DEFAULT',
  EVAL_SET_EDIT = 'EVAL_SET_EDIT',
  PIPELINE_EDIT = 'PIPELINE_EDIT',
}

interface IEditEvalRunProps {
  experiment: IExperiment;
  isVisible: boolean;
  pipelines: IAPIPaginationData<IPipeline[]>;
  evalSets: IAPIPaginationData<IEvalset[]>;
  workspaceTags: ITag[];
  onClose: () => void;
  onSave: (name: string, tags: string[], pipelineName?: string, evalSetName?: string) => void;
  getEvalsets: (currentPage: number, pageSize: number, searchValue: string) => void;
  getPipelines: (currentPage: number, pageSize: number, searchValue: string) => void;
}

const EditEvalRun = ({
  isVisible,
  onClose,
  experiment,
  pipelines,
  evalSets,
  workspaceTags,
  onSave,
  getEvalsets,
  getPipelines,
}: IEditEvalRunProps) => {
  const { Option } = Select;

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedEvalSet, setSelectedEvalSet] = useState<IEvalset>();
  const [selectedPipeline, setSelectedPipeline] = useState<IPipeline>();
  const [newSelectedEvalSet, setNewSelectedEvalSet] = useState<IEvalset>();
  const [newSelectedPipeline, setNewSelectedPipeline] = useState<IPipeline>();
  const [contentState, setContentState] = useState<EditEvalRunContentState>(
    EditEvalRunContentState.DEFAULT,
  );
  const isExperimentHasNeverBeenRun = experiment.status === RunState.CREATED;

  useEffect(() => {
    setSelectedTags(experiment.tags.map((tag) => tag.name));

    if (isExperimentHasNeverBeenRun) {
      setSelectedEvalSet(
        evalSets.data.find((evalSet) => evalSet.name === experiment.parameters.evaluation_set_name),
      );
      setSelectedPipeline(
        pipelines.data.find((pipeline) => pipeline.name === experiment.parameters.pipeline_name),
      );
    }
  }, []);

  const onDoneButtonPressed = () => {
    onSave(experiment.name, selectedTags, selectedPipeline?.name, selectedEvalSet?.name);
    onClose();
  };

  const onSelectPipeline = (pipeline: IPipeline) => {
    setNewSelectedPipeline(pipeline);
  };

  const onSelectEvalset = (evalset: IEvalset) => {
    setNewSelectedEvalSet(evalset);
  };

  const onSaveChangesButtonPressed = () => {
    if (contentState === EditEvalRunContentState.EVAL_SET_EDIT)
      setSelectedEvalSet(newSelectedEvalSet);
    if (contentState === EditEvalRunContentState.PIPELINE_EDIT)
      setSelectedPipeline(newSelectedPipeline);

    setContentState(EditEvalRunContentState.DEFAULT);
  };

  const onCancelButtonPressed = () => {
    setContentState(EditEvalRunContentState.DEFAULT);
  };

  const isSaveChangesButtonDisbaled = () => {
    if (contentState === EditEvalRunContentState.EVAL_SET_EDIT)
      return !newSelectedEvalSet || newSelectedEvalSet.name === selectedEvalSet?.name;

    return !newSelectedPipeline || newSelectedPipeline.name === selectedPipeline?.name;
  };

  const EditItemSection = ({
    title,
    name,
    date,
    numLabels,
    onClick,
  }: {
    title: string;
    name: string;
    date: string;
    numLabels?: number;
    onClick: () => void;
  }) => (
    <div className={styles.editItem}>
      <span>{title}</span>
      <div className={styles.editItem_card}>
        <span className={styles.editItem_nameData}>{name}</span>
        {!isNil(numLabels) && <span>{numLabels}</span>}
        <span>{new Date(date).toLocaleString()}</span>
        <Button type="link" onClick={onClick}>
          {EDIT_BUTTON_LABEL}
        </Button>
      </div>
    </div>
  );

  const DefaultContent = () => (
    <div className={styles.drawer_contentWrapper}>
      <div>
        <label htmlFor="selectTags">{TAGS_SELECT_LABEL}</label>
        <Select
          id="selectTags"
          mode="tags"
          className={styles.drawer_contentWrapper_selectTags}
          placeholder={TAGS_SELECT_PLACEHOLDER}
          value={selectedTags}
          onChange={(values) => setSelectedTags(values)}
        >
          {workspaceTags.map((tag) => (
            <Option key={tag.tag_id} value={tag.name}>
              {tag.name}
            </Option>
          ))}
        </Select>
      </div>
      {selectedPipeline && (
        <EditItemSection
          title={PIPELINE_SECTION_TITLE}
          name={selectedPipeline.name}
          date={selectedPipeline.created_at}
          onClick={() => setContentState(EditEvalRunContentState.PIPELINE_EDIT)}
        />
      )}
      {selectedEvalSet && (
        <EditItemSection
          title={EVALUATION_SECTION_TITLE}
          name={selectedEvalSet.name}
          date={selectedEvalSet.created_at}
          numLabels={selectedEvalSet.total_labels}
          onClick={() => setContentState(EditEvalRunContentState.EVAL_SET_EDIT)}
        />
      )}
    </div>
  );

  const getContentComponentByState = (state: EditEvalRunContentState) => {
    const mappedContentByState = {
      [EditEvalRunContentState.DEFAULT]: <DefaultContent />,
      [EditEvalRunContentState.EVAL_SET_EDIT]: (
        <EvalSetSelectionTable
          title={EDIT_EVALUATION_SECTION_HEADER}
          evalSets={evalSets}
          evalSetName={newSelectedEvalSet?.name || selectedEvalSet?.name || ''}
          onSelectEvalSet={onSelectEvalset}
          getEvalSets={getEvalsets}
        />
      ),
      [EditEvalRunContentState.PIPELINE_EDIT]: (
        <div>
          <h4>{EDIT_PIPELINE_SECTION_HEADER}</h4>
          <PipelineSelectionTable
            pipelines={pipelines}
            selectedPipelineName={newSelectedPipeline?.name || selectedPipeline?.name || ''}
            onSelectPipeline={onSelectPipeline}
            getPipelines={getPipelines}
          />
        </div>
      ),
    };

    return mappedContentByState[state];
  };

  return (
    <Drawer
      title={EDIT_EXPERIMENT_HEADER}
      size="large"
      onClose={onClose}
      open={isVisible}
      extra={
        <div className={styles.drawer_extra_container}>
          {contentState === EditEvalRunContentState.DEFAULT && (
            <Button type="primary" onClick={onDoneButtonPressed}>
              {DONE_BUTTON_LABEL}
            </Button>
          )}
          {contentState !== EditEvalRunContentState.DEFAULT && (
            <>
              <Button
                type="default"
                onClick={onCancelButtonPressed}
                className={styles.drawer_header_cancelButton}
              >
                {CANCEL_BUTTON_LABEL}
              </Button>
              <Button
                type="primary"
                onClick={onSaveChangesButtonPressed}
                disabled={isSaveChangesButtonDisbaled()}
              >
                {SAVE_BUTTON_LABEL}
              </Button>
            </>
          )}
        </div>
      }
    >
      {getContentComponentByState(contentState)}
    </Drawer>
  );
};

export default EditEvalRun;
