import { DEFAULT_WORKSPACE } from '@constants/workspace';
import { IWorkspaceItem } from '@redux/reducers/organizationReducer';
import { store } from '@redux/store';

export const getWorkspace = () => {
  const state = store.getState() as any;
  const workspace = state.organizationStore.currentWorkspace;
  return workspace || DEFAULT_WORKSPACE;
};

export const getCurrentWorkspaceId = () => {
  const state = store.getState() as any;
  const { workspaces, currentWorkspace } = state.organizationStore;
  const { workspace_id: workspaceId } =
    workspaces.find((worskpace: IWorkspaceItem) => worskpace.name === currentWorkspace) || {};
  return workspaceId;
};
