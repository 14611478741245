import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useEffectUpdateOnly = (fn: EffectCallback, deps?: DependencyList) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return () => {};
    }
    return fn();
  }, deps);
};

export default useEffectUpdateOnly;
