import { DOCS_URL } from '@constants/constant';

export const AWS_MARKETPLACE_REGISTER_TITLE = 'Register now to deepset Cloud';
export const AWS_MARKETPLACE_REGISTER_THANKS_MESSAGE =
  'Thank you for purchasing deepset Cloud through the AWS Marketplace.';
export const AWS_MARKETPLACE_REGISTER_DESCRIPTION =
  'To set up your account, fill in the form below.';
export const AWS_MARKETPLACE_CONTACT_BUTTON = 'Contact us';
export const PERSONAL_INFO_STEP_TITLE = 'Your personal information';
export const PERSONAL_INFO_STEP_SUBTITLE = `We're delighted to have you with us. We'd like to learn more about you to customize your deepset Cloud experience.`;
export const PERSONAL_INFO_FIELDS = {
  name: {
    label: ' Name',
    placeholder: 'Jane',
  },
  lastName: {
    label: ' Last name',
    placeholder: 'Doe',
  },
  email: {
    label: ' Email',
    placeholder: 'jane.doe@company.com',
  },
  companyName: {
    label: ' Company name',
    placeholder: 'Company',
  },
};
export const PERSONAL_INFO_FIELDS_ERROR_MESSAGE = {
  name: 'Name is required.',
  lastName: 'Last name is required.',
  email: 'Email is required.',
  emailInvalid: 'The input is not a valid email address.',
  companyName: 'Company name is required.',
};
export const BUSINESS_NEEDS_STEP_TITLE = 'Your business needs';
export const BUSINESS_NEEDS_STEP_SUBTITLE = `Tell us more about your use case so that we can understand how deepset Cloud can help you. This step is optional.`;
export const BUSINESS_NEEDS_FIELDS = {
  systemDescription: {
    label: 'What kind of a system would you like to build?',
    placeholder: 'Describe the system you want to build with deepset Cloud.',
  },
  timeline: {
    label: 'When would you like to start your project? If started, what have you done so far?',
    placeholder: `Give a timeline of when you're ready to start or describe what you've already built.`,
  },
  professionalService: {
    label: 'Do you have a dedicated technical team or do you require a professional service? ',
    placeholder: 'Tell us if you need help with your project.',
  },
  dataType: {
    label: 'What type of data do you want to use?',
    placeholder: 'Specify the format and topic area.',
  },
};
export const REGISTER_BUTTON_LABEL = 'Register';
export const AWS_MARKETPLACE_SUCCESS_TITLE = 'Welcome aboard!';
export const AWS_MARKETPLACE_SUCCESS_THANKS_MESSAGE =
  'Thank you for registering! Please wait until you receive your account ';
export const AWS_MARKETPLACE_SUCCESS_DESCRIPTION = `details and you'll be all set to explore deepset Cloud shortly.`;
export const REGISTRATION_SUCCESS_TITLE = 'Registration completed';
export const REGISTRATION_SUCCESS_DESCRIPTION = `We're setting up deepset Cloud for you and you'll be able to use it within 2 business days. We'll send you an email with your login details when everything is ready. In the meantime, you can always reach out to us.`;
export const CONTACT_SUPPORT_TITLE = 'Contact support';
export const ALREADY_HAVE_ACCOUNT_TITLE = 'Already have an account?';
export const ALREADY_HAVE_ACCOUNT_DESCRIPTION =
  'If you already have your login details provided by our team, you can access deepset cloud now.';
export const LOGIN_BUTTON_LABEL = 'Log in now';
export const AWS_TOKEN_VALIDATION_ERROR = 'Error occurred while validating token';
export const AWS_TOKEN_NEEED_ERROR = 'AWS Marketplace token is required';
export const AWS_SUBMISSION_FAILED = 'Failed to submit the form. Please try again.';
export const BY_DEEPSET_LABEL = 'by deepset';
export const DEEPSET_SUCCESS_SUGGESTIONS = [
  {
    title: 'Talk to Your Docs, RAG Style',
    description:
      'How we built a generative AI assistant for easier navigation of our technical documentation',
    link: `https://www.deepset.ai/blog/talk-to-your-docs-rag-style`,
    buttonLabel: 'Read article',
  },
  {
    title: 'RAG QA for Enterprise Search',
    description:
      'In this webinar, will explore the capabilities and potential of Retrieval Augmented Generative Question Answering (RAG QA) in the enterprise sector.',
    link: `https://www.youtube.com/watch?v=DlgPllpbW5I`,
    buttonLabel: 'Watch webinar',
  },
  {
    title: 'Dive Into the deepset Cloud Documentation',
    description:
      'Here, you will find everything you need to get started, as well as help if you get stuck. Get ready to build your AI-powered app.',
    link: DOCS_URL,
    buttonLabel: 'Open documentation',
  },
];

export interface IPersonalInfoValues {
  name: string;
  lastName: string;
  email: string;
  companyName: string;
}

export interface IBusinessNeedsValues {
  systemDescription: string;
  timeline: string;
  professionalService: string;
  dataType: string;
}

export interface IAwsMarketplaceSubscriptionData extends IPersonalInfoValues, IBusinessNeedsValues {
  offerType: string;
  awsMarketplaceToken: string;
  location: string;
}

export enum AwsMarketplaceLocation {
  US = 'us',
  EU = 'eu',
}
