import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CloseCircleFilled,
  CopyOutlined,
  DownloadOutlined,
  InfoCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Empty,
  message,
  Skeleton,
  Switch,
  Tabs,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { resetFilterValues } from '@utils/dataFilters';
import { interpolateString, transformToTitleCase } from '@utils/string';
import {
  FilterProp,
  FiltersProp,
  FilterType,
  SelectedFilterItem,
  SelectedFilters,
} from '@constants/data-table';
import {
  CAUSED_BY_LABEL,
  CLEAR_ALL_FILTERS_BUTTON_LABEL,
  COPY_TO_CLIPBOARD_BUTTON_LABEL,
  COPY_TO_CLIPBOARD_MESSAGE_ALERT,
  DOWNLOAD_LOGS_BUTTON_LABEL,
  EMPTY_LOGS_DEPLOYED_PIPELINE_LABEL,
  EMPTY_LOGS_UNDEPLOYED_PIPELINE_LABEL,
  EMPTY_LOGS_WITH_FILTERS_LABEL,
  EMPTY_LOGS_WITH_SEARCH_VALUE_LABEL,
  ENABLE_AUTOREFRESH_BUTTON_LABEL,
  FETCH_PIPELINE_LOGS_LIMIT,
  LINE_LABEL,
  LogLevels,
  LOGS_FORMATTED_TAB_LABEL,
  LOGS_RAW_TAB_LABEL,
  LogTabsKeys,
  PIPELINE_LOGS_FILTER_DATE_LABEL,
  PIPELINE_LOGS_FILTER_DATE_OPTIONS,
  PIPELINE_LOGS_FILTER_LEVEL_LABEL,
  PIPELINE_LOGS_FILTER_LEVEL_OPTIONS,
  PIPELINE_LOGS_FILTER_ORIGIN_LABEL,
  PIPELINE_LOGS_FILTER_ORIGIN_OPTIONS,
  PIPELINE_LOGS_FILTERS_FIELD_KEYS,
  PipelineDetailsTabsKeys,
  SEARCH_LOGS_PLACEHOLDER,
  STACK_TRACE_LABEL,
  TOTAL_LOGS,
  TOTAL_LOGS_PERPAGE,
} from '@constants/pipeline-details';
import { PipelineStatusCodes } from '@constants/pipelines';
import { IPipelineLog, IPipelineLogsParams } from '@redux/types/types';
import AppliedFiltersList from '@components/appliedFilterList/AppliedFiltersList';
import DataHeaderActions from '@components/dataHeaderActions/DataHeaderActions';
import styles from './pipelineLogs.module.scss';

const { Panel } = Collapse;

interface IPipelineLogsComponent {
  pipelineLogs: IPipelineLog[];
  pipelineLogsListHasMore: boolean;
  pipelineTotalNumberLogs: number;
  pipelineName: string;
  filterDateLastDeployedAt: SelectedFilterItem;
  getPipelineLogs: (payload: IPipelineLogsParams) => void;
  startPollingPipelineLogs: (payload: IPipelineLogsParams) => void;
  stopPollingPipelineLogs: () => void;
  pipelineStatus: PipelineStatusCodes;
  downloadLogsCSV: () => void;
  loading?: boolean;
  activeTab?: string;
}

const SELECT_DATE_FILTER_HEIGHT = 200;
const POLL_LOGS_INTERVAL_MILLISECONDS = 8000;
const SPACE_CHARACTERS_STRINGIFY = 2;

const PipelineLogs = (props: IPipelineLogsComponent) => {
  const {
    pipelineLogs,
    pipelineTotalNumberLogs,
    pipelineLogsListHasMore,
    pipelineName,
    filterDateLastDeployedAt,
    getPipelineLogs,
    startPollingPipelineLogs,
    stopPollingPipelineLogs,
    pipelineStatus,
    downloadLogsCSV,
    loading,
    activeTab,
  } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [searchValue, setSearchValue] = useState<string>('');
  const [emptyLogsMessage, setEmptyLogsMessage] = useState<string>('');
  const [enableAutorefresh, setEnableAutorefresh] = useState(false);

  const getFilters = (): FiltersProp | [] => {
    const levelFilter = {
      type: FilterType.MULTI_SELECT,
      key: PIPELINE_LOGS_FILTERS_FIELD_KEYS.LEVEL,
      title: PIPELINE_LOGS_FILTER_LEVEL_LABEL,
      style: { minWidth: '87px' },
      options: PIPELINE_LOGS_FILTER_LEVEL_OPTIONS,
    } as FilterProp<FilterType.MULTI_SELECT>;
    const originFilter = {
      type: FilterType.MULTI_SELECT,
      key: PIPELINE_LOGS_FILTERS_FIELD_KEYS.ORIGIN,
      title: PIPELINE_LOGS_FILTER_ORIGIN_LABEL,
      style: { minWidth: '87px' },
      options: PIPELINE_LOGS_FILTER_ORIGIN_OPTIONS,
    } as FilterProp<FilterType.MULTI_SELECT>;
    const dateFilter = {
      type: FilterType.SELECT,
      key: PIPELINE_LOGS_FILTERS_FIELD_KEYS.DATE_ADDED,
      title: PIPELINE_LOGS_FILTER_DATE_LABEL,
      style: { minWidth: '120px', maxWidth: '260px' },
      listHeight: SELECT_DATE_FILTER_HEIGHT,
      options: PIPELINE_LOGS_FILTER_DATE_OPTIONS,
    } as FilterProp<FilterType.SELECT>;

    return [levelFilter, originFilter, dateFilter];
  };

  const [filters] = useState<FiltersProp>(getFilters());
  const [filterValues, setFilterValues] = useState<SelectedFilters>({});

  useEffect(() => {
    if (pipelineStatus === PipelineStatusCodes.DEPLOYED)
      setEmptyLogsMessage(EMPTY_LOGS_DEPLOYED_PIPELINE_LABEL);
    else setEmptyLogsMessage(EMPTY_LOGS_UNDEPLOYED_PIPELINE_LABEL);
  }, [pipelineStatus]);

  const getPipelineLogsParamsApi = () => {
    const dateItem = filterValues && filterValues.logged_at && filterValues.logged_at.length > 0;
    return {
      pipelineName,
      searchValue,
      filterValues: dateItem
        ? filterValues
        : { ...filterValues, logged_at: [filterDateLastDeployedAt] },
      limit: FETCH_PIPELINE_LOGS_LIMIT,
    };
  };

  useEffect(() => {
    if (filterDateLastDeployedAt.value) getPipelineLogs(getPipelineLogsParamsApi());
    if (
      enableAutorefresh &&
      filterDateLastDeployedAt.value &&
      activeTab === PipelineDetailsTabsKeys.LOGS
    ) {
      stopPollingPipelineLogs();
      startPollingPipelineLogs(getPipelineLogsParamsApi());
    } else stopPollingPipelineLogs();

    return () => {
      stopPollingPipelineLogs();
    };
  }, [filterValues, searchValue, enableAutorefresh, activeTab, filterDateLastDeployedAt.value]);

  const getDateFilter = (filterKey: string, item: SelectedFilterItem) => {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ[Z]';
    let selectedDate = '';
    if (item.key.includes('date')) {
      const selectedDateItems = item.key.split('_');
      if (selectedDateItems[2] === 'm') {
        selectedDate = dayjs().subtract(Number(selectedDateItems[1]), 'minute').format(dateFormat);
      }
      if (selectedDateItems[2] === 'h') {
        selectedDate = dayjs().subtract(Number(selectedDateItems[1]), 'hour').format(dateFormat);
      }
      if (selectedDateItems[2] === 'd') {
        selectedDate = dayjs().subtract(Number(selectedDateItems[1]), 'day').format(dateFormat);
      }
      const newItem = [{ ...item, value: selectedDate, type: FilterType.DATE }];
      setFilterValues((values) => ({ ...values, [filterKey]: newItem }));
    } else {
      setFilterValues((values) => ({ ...values, [filterKey]: [item] }));
    }
  };

  const onFilterSelectChange = (filterKey: string, items: SelectedFilterItem[]) => {
    if (filterKey === 'logged_at' && items.length > 0) getDateFilter(filterKey, items[0]);
    else setFilterValues((values) => ({ ...values, [filterKey]: items }));
  };

  const onClearAllFilters = () => {
    const resetFilterValuesAux = resetFilterValues(filters);
    setFilterValues({ ...resetFilterValuesAux });
  };

  const getLogIcon = (logLevel: string) => {
    if (logLevel === LogLevels.WARNING)
      return <WarningFilled className={styles.pipelineLogs_warningIcon} />;
    if (logLevel === LogLevels.ERROR || logLevel === LogLevels.CRITICAL)
      return <CloseCircleFilled className={styles.pipelineLogs_errorIcon} />;
    return <InfoCircleFilled className={styles.pipelineLogs_infoIcon} />;
  };

  const getLogBackground = (logLevel: string) => {
    if (logLevel === LogLevels.WARNING) return styles.pipelineLogs_warningBackground;
    if (logLevel === LogLevels.ERROR || logLevel === LogLevels.CRITICAL)
      return styles.pipelineLogs_errorBackground;
    return styles.pipelineLogs_infoBackground;
  };

  const copyTraceToClipboard = (log: IPipelineLog) => {
    const reversedExceptions =
      log.exceptions && log.exceptions.length ? [...log.exceptions].reverse() : [];
    let exceptionsText = '';
    let extraFieldsText = '';

    reversedExceptions.forEach((exception, index) => {
      let exceptionTracesText = '';
      exception.trace.forEach((trace) => {
        exceptionTracesText = `${exceptionTracesText}${
          trace.filename
        }, ${LINE_LABEL} ${trace.line_number.toString()}, ${trace.name}\n`;
      });

      if (reversedExceptions.length === 1) {
        exceptionsText = `${log.message}${STACK_TRACE_LABEL}\n${exceptionTracesText}`;
      } else {
        if (index > 0) exceptionsText = `${exceptionsText}\n${CAUSED_BY_LABEL}\n`;
        exceptionsText = `${exceptionsText}${exception.type}: ${exception.value}\n${exceptionTracesText}`;
      }
    });

    if (Object.keys(log.extra_fields).length > 0) {
      Object.keys(log.extra_fields).forEach((extraField) => {
        extraFieldsText = `${extraFieldsText}${transformToTitleCase(extraField)}: ${
          JSON.stringify(log.extra_fields[extraField], null, SPACE_CHARACTERS_STRINGIFY) || ''
        }\n`;
      });
    }

    let rawLogInfo = '';
    if (reversedExceptions.length === 1) rawLogInfo = exceptionsText;
    else rawLogInfo = `${log.message}\n\n${exceptionsText}\n\n${extraFieldsText}`;
    navigator.clipboard.writeText(rawLogInfo);
    messageApi.success(COPY_TO_CLIPBOARD_MESSAGE_ALERT);
  };

  const renderSkeletonLogItem = () => (
    <div className={styles.pipelineLogSkeleton}>
      <Skeleton paragraph={{ rows: 1, width: '100%' }} active title={false} />
    </div>
  );

  const renderEmptyLogsWithFilters = () => {
    return pipelineLogs.length === 0 && searchValue !== '' ? (
      <div className={styles.emptyLogs}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{EMPTY_LOGS_WITH_SEARCH_VALUE_LABEL}</span>}
        />
      </div>
    ) : (
      <div className={styles.emptyLogs}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{EMPTY_LOGS_WITH_FILTERS_LABEL}</span>}
        />
        <Button type="primary" onClick={() => onClearAllFilters()}>
          {CLEAR_ALL_FILTERS_BUTTON_LABEL}
        </Button>
      </div>
    );
  };

  const getFadeInAnimation = (log: IPipelineLog) => {
    const dateDiff = dayjs().diff(dayjs(log.logged_at));
    if (dateDiff < POLL_LOGS_INTERVAL_MILLISECONDS && enableAutorefresh) return styles.fadeInLog;
    return '';
  };

  const renderCollapseContent = (log: IPipelineLog) => {
    const extraFields = () => {
      return Object.keys(log.extra_fields).length > 0
        ? Object.keys(log.extra_fields).map((extraField) => {
            return (
              <div key={extraField}>{`${transformToTitleCase(extraField)}: ${
                JSON.stringify(log.extra_fields[extraField], null, SPACE_CHARACTERS_STRINGIFY) || ''
              }`}</div>
            );
          })
        : null;
    };

    if (log.level === LogLevels.INFO)
      return (
        <>
          <p className={styles.pipelineLogs_collapse_infoLog}>{log.message}</p>
          {extraFields()}
        </>
      );

    const reversedExceptions = log.exceptions?.length ? [...log.exceptions].reverse() : [];

    const getFormattedLog = () => {
      return (
        <div className={styles.pipelineLogs_collapse_tabItem}>
          {log.level === LogLevels.ERROR ||
          log.level === LogLevels.CRITICAL ||
          log.level === LogLevels.WARNING ? (
            <Alert
              message={
                reversedExceptions.length === 1 ? log.message + STACK_TRACE_LABEL : log.message
              }
              type={log.level === LogLevels.WARNING ? 'warning' : 'error'}
              className={styles.pipelineLogs_collapse_alert}
            />
          ) : null}
          {reversedExceptions.map((exception, index) => {
            if (reversedExceptions.length === 1) {
              return (
                <div key={uuidv4()}>
                  {exception.trace.map((trace, indexTrace) => {
                    return (
                      <div key={uuidv4()}>
                        <p>{`${trace.filename}, ${LINE_LABEL} ${trace.line_number}, ${trace.name}`}</p>
                        {indexTrace < exception.trace.length - 1 && (
                          <Divider className={styles.pipelineLogs_collapse_divider} />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            }
            return (
              <div key={uuidv4()}>
                {index > 0 && (
                  <div className={styles.pipelineLogs_collapse_causedBy}>{CAUSED_BY_LABEL}</div>
                )}
                <p>{`${exception.type}: ${exception.value}`}</p>
                {exception.trace.map((trace) => {
                  return (
                    <p
                      key={uuidv4()}
                    >{`${trace.filename}, ${LINE_LABEL} ${trace.line_number}, ${trace.name}`}</p>
                  );
                })}
                {index < log.exceptions.length - 1 && (
                  <Divider className={styles.pipelineLogs_collapse_divider} />
                )}
              </div>
            );
          })}
        </div>
      );
    };

    const getRawLog = () => {
      return (
        <div className={styles.pipelineLogs_collapse_tabItem}>
          {reversedExceptions.length === 1 ? (
            <div>{log.message + STACK_TRACE_LABEL}</div>
          ) : (
            <p>{log.message}</p>
          )}
          {reversedExceptions.map((exception, index) => {
            if (reversedExceptions.length === 1) {
              return (
                <div key={uuidv4()}>
                  {exception.trace.map((trace) => {
                    return (
                      <div
                        key={uuidv4()}
                      >{`${trace.filename}, ${LINE_LABEL} ${trace.line_number}, ${trace.name}`}</div>
                    );
                  })}
                </div>
              );
            }
            return (
              <div className={styles.pipelineLogs_collapse_rawSection} key={uuidv4()}>
                {index > 0 && <div>{CAUSED_BY_LABEL}</div>}
                <div>{`${exception.type}: ${exception.value}`}</div>
                {exception.trace.map((trace) => {
                  return (
                    <div
                      key={uuidv4()}
                    >{`${trace.filename}, ${LINE_LABEL} ${trace.line_number}, ${trace.name}`}</div>
                  );
                })}
              </div>
            );
          })}
          {extraFields()}
        </div>
      );
    };

    const tabsItems = [
      {
        label: LOGS_FORMATTED_TAB_LABEL,
        key: LogTabsKeys.FORMATTED,
        children: getFormattedLog(),
      },
      {
        label: LOGS_RAW_TAB_LABEL,
        key: LogTabsKeys.RAW,
        children: getRawLog(),
      },
    ];

    return (
      <>
        <Tabs
          defaultActiveKey="formatted"
          items={tabsItems}
          tabBarExtraContent={
            <Tooltip title={COPY_TO_CLIPBOARD_BUTTON_LABEL}>
              <Button
                size="small"
                icon={<CopyOutlined />}
                onClick={() => copyTraceToClipboard(log)}
              />
            </Tooltip>
          }
        />
      </>
    );
  };

  const renderLogsList = useMemo(
    () => (
      <>
        <div className={styles.pipelineLogs}>
          <div className={styles.pipelineLogs_firstAction}>
            <DataHeaderActions
              onSearch={(value) => setSearchValue(value)}
              filters={filters}
              filterValues={filterValues}
              loading={loading}
              onFilterSelectChange={onFilterSelectChange}
              primaryAction={{
                label: DOWNLOAD_LOGS_BUTTON_LABEL,
                onClick: () => downloadLogsCSV(),
                disabled: pipelineLogs?.length === 0,
                secondary: true,
                icon: <DownloadOutlined />,
              }}
              searchAvailable
              searchPlaceholder={SEARCH_LOGS_PLACEHOLDER}
            />
          </div>
          <Divider className={styles.filtersDivider} />
          <div className={styles.pipelineLogs_secondAction}>
            <div className={styles.totalItems}>
              <div className={styles.totalItems_label}>
                <span className={styles.totalItems_label_subtitle}>
                  {interpolateString(TOTAL_LOGS_PERPAGE, {
                    totalPerPage: pipelineLogs?.length,
                  })}
                </span>
                <span className={styles.totalItems_label_total}>
                  {interpolateString(TOTAL_LOGS, {
                    totalItems: pipelineTotalNumberLogs,
                  })}
                </span>
              </div>
              <AppliedFiltersList
                filterValues={filterValues}
                onClearAllFilters={onClearAllFilters}
                onFilterSelectChange={onFilterSelectChange}
              />
            </div>
            <div className={styles.pipelineLogs_secondAction_enableAutorefresh}>
              <span>{ENABLE_AUTOREFRESH_BUTTON_LABEL}</span>
              <Switch size="small" onChange={(e) => setEnableAutorefresh(e)} />
            </div>
          </div>
          <Divider className={styles.horizontalDivider} />
          <div className={styles.pipelineLogs_collapse}>
            <InfiniteScroll
              dataLength={pipelineLogs?.length}
              next={() =>
                getPipelineLogs({
                  pipelineName,
                  filterValues,
                  after: pipelineLogs ? pipelineLogs[pipelineLogs.length - 1].logged_at : undefined,
                  limit: FETCH_PIPELINE_LOGS_LIMIT,
                  fetchMore: true,
                })
              }
              loader={renderSkeletonLogItem()}
              hasMore={pipelineLogsListHasMore}
              scrollableTarget="contentAppSection"
            >
              <Collapse bordered={false} size="small" expandIconPosition="end" collapsible="header">
                {pipelineLogs?.length > 0
                  ? pipelineLogs?.map((log) => {
                      return (
                        <Panel
                          className={`${getLogBackground(log.level)} ${getFadeInAnimation(log)}`}
                          header={
                            <>
                              {getLogIcon(log.level)}
                              <span className={styles.pipelineLogs_logTitle}>{log.message}</span>
                            </>
                          }
                          extra={
                            <span className={styles.pipelineLogs_logDate}>
                              {new Date(log.logged_at).toLocaleString()}
                            </span>
                          }
                          key={log.log_id}
                        >
                          {contextHolder}
                          {renderCollapseContent(log)}
                        </Panel>
                      );
                    })
                  : renderEmptyLogsWithFilters()}
              </Collapse>
            </InfiniteScroll>
          </div>
        </div>
      </>
    ),
    [pipelineLogs, filterValues, filters],
  );

  return !pipelineLogs ||
    (pipelineLogs.length === 0 && searchValue === '' && Object.keys(filterValues).length === 0) ||
    pipelineStatus === PipelineStatusCodes.UNDEPLOYED ? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyLogsMessage}</span>} />
  ) : (
    renderLogsList
  );
};

export default PipelineLogs;
