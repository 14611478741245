export const CHAT_DESCRIPTION = 'Use the search to ask questions about deepset Cloud. For example:';
export const CLEAR_BUTTON_LABEL = 'Clear';
export const SEARCH_PLACEHOLDER = 'Enter your question';
export const DOCUMENTATION_LINK_LABEL = 'Go to documentation';

interface ISampleQuestions {
  [key: string]: string[];
}

export const sampleQuestions: ISampleQuestions = {
  common: [
    "What's a workspace?",
    'How are documents and files different?',
    "What's RAG?",
    'Can I use a private model?',
  ],
  pipelines: [
    'What is a pipeline?',
    'How do I edit a pipeline?',
    'How do I fix a "failed to deploy" pipeline?',
    "What's the difference between deployed and in development pipelines?",
  ],
  experiments: [
    'How do I evaluate my pipeline?',
    "What's an experiment?",
    'Can other people test my pipeline?',
  ],
  promptExplorer: [
    'How do I start working on a prompt?',
    "What's prompt studio?",
    'How do I test my prompt?',
  ],
  dataFile: [
    'What type of files can I upload?',
    'How do I upload files?',
    "What's the maximum file size?",
  ],
  dataEvalset: [
    "What's an evaluation set?",
    'How do I create an evaluation set?',
    'Why do I need an evaluation set?',
    "What's the required format of the evaluation set?",
  ],
  search: ['How do I add search filters?', 'What queries work?', 'How do I search for a document?'],
};
