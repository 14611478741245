export default `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<g clip-path="url(#clip0_1133_22268)">
<path d="M6 0.75C3.10078 0.75 0.75 3.10078 0.75 6C0.75 8.89922 3.10078 11.25 6 11.25C8.89922 11.25 11.25 8.89922 11.25 6C11.25 3.10078 8.89922 0.75 6 0.75ZM6 10.3594C3.59297 10.3594 1.64062 8.40703 1.64062 6C1.64062 3.59297 3.59297 1.64062 6 1.64062C8.40703 1.64062 10.3594 3.59297 10.3594 6C10.3594 8.40703 8.40703 10.3594 6 10.3594Z" fill="#70747F"/>
<path d="M7.30781 3.71133C6.95625 3.40313 6.49219 3.23438 6 3.23438C5.50781 3.23438 5.04375 3.4043 4.69219 3.71133C4.32656 4.03125 4.125 4.46133 4.125 4.92188V5.01094C4.125 5.0625 4.16719 5.10469 4.21875 5.10469H4.78125C4.83281 5.10469 4.875 5.0625 4.875 5.01094V4.92188C4.875 4.40508 5.38008 3.98438 6 3.98438C6.61992 3.98438 7.125 4.40508 7.125 4.92188C7.125 5.28633 6.86719 5.62031 6.46758 5.77383C6.21914 5.86875 6.0082 6.03516 5.85703 6.25312C5.70352 6.47578 5.62383 6.74297 5.62383 7.01367V7.26562C5.62383 7.31719 5.66602 7.35938 5.71758 7.35938H6.28008C6.33164 7.35938 6.37383 7.31719 6.37383 7.26562V6.99961C6.37443 6.88585 6.40931 6.7749 6.4739 6.68125C6.5385 6.5876 6.62981 6.51559 6.73594 6.47461C7.42734 6.20859 7.87383 5.59922 7.87383 4.92188C7.875 4.46133 7.67344 4.03125 7.30781 3.71133ZM5.53125 8.57812C5.53125 8.70245 5.58064 8.82167 5.66854 8.90958C5.75645 8.99749 5.87568 9.04688 6 9.04688C6.12432 9.04688 6.24355 8.99749 6.33146 8.90958C6.41936 8.82167 6.46875 8.70245 6.46875 8.57812C6.46875 8.4538 6.41936 8.33458 6.33146 8.24667C6.24355 8.15876 6.12432 8.10938 6 8.10938C5.87568 8.10938 5.75645 8.15876 5.66854 8.24667C5.58064 8.33458 5.53125 8.4538 5.53125 8.57812Z" fill="#70747F"/>
</g>
<defs>
<clipPath id="clip0_1133_22268">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg>`;
