import React from 'react';
import useSearchFileActions from '@hooks/useSearchFileActions';
import { fileDropdownMenuItems } from '@constants/search';
import { IBaseSearchResultComponentProps, ISearchResultDocument } from '@redux/types/types';
import SearchResultSingleFileSource from '@components/search/molecules/files/SearchResultSingleFileSource';
import FormattedResultText from '@components/search/molecules/formattedResultText/FormattedResultText';
import SearchDocumentLink from '@components/search/molecules/searchDocumentLink/SearchDocumentLink';
import SearchDocumentMetadata from '@components/search/molecules/searchDocumentMetadata/SearchDocumentMetadata';
import SearchResultScore from '@components/search/molecules/searchResultScore/SearchResultScore';
import styles from './resultContainer.module.scss';
import SearchResultActions from '../resultActions/SearchResultActions';

const MAX_CONTENT_LENGTH = 1000;

export interface IExposedDocumentResultProps {
  displayFullDocument?: boolean;
}

interface IDocumentResultProps
  extends IBaseSearchResultComponentProps<ISearchResultDocument>,
    IExposedDocumentResultProps {
  searching: boolean;
  isExternal?: boolean;
}

const DocumentResult = ({
  result,
  queryId,
  pipelineId,
  pipelineName,
  displayRelevanceScore,
  displayFeedbackOptions,
  displayFileSources,
  displayFileOptions,
  displayMetadata,
  displayFullDocument = false,
  searching,
  feedback,
  isExternal,
}: IDocumentResultProps) => {
  const { result_id: resultId, content, file, score, meta } = result;
  const { onFileDropdownItemClick, onDocumentPreview, isFileActionTakingPlace } =
    useSearchFileActions({
      resultId,
      isExternal,
    });

  const renderResultBody = () => {
    const parsedContent = displayFullDocument ? content : content.slice(0, MAX_CONTENT_LENGTH);

    return (
      <div className={styles.result_body} data-testid="searchResult_content">
        <FormattedResultText text={parsedContent} />
        {!displayFullDocument && parsedContent.length === MAX_CONTENT_LENGTH && <>...</>}
        {!displayFullDocument && displayFileSources && (
          <SearchDocumentLink
            file={file}
            onViewDocument={() => onDocumentPreview(file.id, file.name)}
          />
        )}
        {displayRelevanceScore && <SearchResultScore score={score} />}
      </div>
    );
  };

  const renderFeedbackButtons = () => {
    if (feedback) return <div className={styles.result_actions}>{feedback()}</div>;

    return (
      <div className={styles.result_actions}>
        <SearchResultActions
          resultId={resultId}
          queryId={queryId}
          pipelineId={pipelineId}
          pipelineName={pipelineName}
          isExternal={isExternal}
          copytext={content}
        />
      </div>
    );
  };

  return (
    <div className={styles.result}>
      {renderResultBody()}
      {displayMetadata && <SearchDocumentMetadata metadata={meta} />}
      {displayFeedbackOptions && renderFeedbackButtons()}
      {displayFileSources && (
        <SearchResultSingleFileSource
          resultId={resultId}
          file={file}
          meta={meta}
          searching={searching}
          isFileActionTakingPlace={isFileActionTakingPlace}
          onFileDropdownItemClick={onFileDropdownItemClick}
          fileDropdownMenuItems={fileDropdownMenuItems}
          displayFileOptions={displayFileOptions}
        />
      )}
    </div>
  );
};

export default DocumentResult;
