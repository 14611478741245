import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClockCircleFilled, ClockCircleOutlined } from '@ant-design/icons';
import { Divider, Empty, List, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import styles from './queryHistoryList.module.scss';

const NUM_SKELETON_HISTORY_ITEMS = 10;

export interface IListItem {
  id: string;
  createdAt: string;
  query: string;
}

interface IQueryHistoryListProps {
  total: number;
  hasMore: boolean;
  onNext: () => void;
  scrollableTarget: string;
  dataGroupedByDate: Record<string, IListItem[]>;
  isLoading: boolean;
  isSelected: (item: IListItem) => boolean;
  onItemSelect: (item: IListItem) => void;
}

const QueryHistoryList = ({
  total,
  hasMore,
  onNext,
  scrollableTarget,
  dataGroupedByDate,
  isLoading,
  isSelected,
  onItemSelect,
}: IQueryHistoryListProps) => {
  const dataGroupedByDateEntries = Object.entries(dataGroupedByDate);

  const renderSkeletonHistoryItem = () => (
    <div className={styles.listSkeletonItem} key={uuidv4()}>
      <Skeleton title paragraph={false} active />
    </div>
  );

  if (isLoading)
    return (
      <div className={styles.list}>
        {Array.from({ length: NUM_SKELETON_HISTORY_ITEMS }).map(renderSkeletonHistoryItem)}
      </div>
    );

  return (
    <div className={styles.list}>
      <InfiniteScroll
        dataLength={total}
        next={onNext}
        hasMore={hasMore}
        loader={renderSkeletonHistoryItem()}
        scrollableTarget={scrollableTarget}
      >
        {!dataGroupedByDateEntries.length && <Empty />}
        {dataGroupedByDateEntries.map(([groupDay, groupedSession], idx) => {
          return (
            <div className={styles.list_wrapper} key={groupDay}>
              <div className={styles.list_title}>{groupDay}</div>
              <List
                dataSource={groupedSession}
                renderItem={(item) => {
                  const { id, createdAt, query } = item;
                  const isSelectedItem = isSelected(item);
                  return (
                    <>
                      <List.Item key={id}>
                        <div
                          className={`${styles.list_card} ${
                            isSelectedItem ? styles.list_cardActive : ''
                          }`}
                          onClick={() => onItemSelect(item)}
                          onKeyDown={() => onItemSelect(item)}
                          role="button"
                          tabIndex={0}
                        >
                          <span className={styles.list_card_time}>
                            {isSelectedItem ? <ClockCircleFilled /> : <ClockCircleOutlined />}{' '}
                            {dayjs(createdAt).format('HH:mm')}
                          </span>
                          <span className={styles.list_card_query}>{query}</span>
                        </div>
                      </List.Item>
                    </>
                  );
                }}
              />
              {idx !== dataGroupedByDateEntries.length - 1 && <Divider />}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default QueryHistoryList;
