import React from 'react';
import { Col, Row } from 'antd';
import styles from './contentCenter.module.scss';

interface IContentCenter {
  children: React.ReactNode;
}

const ContentCenter = (props: IContentCenter) => {
  const { children } = props;
  return (
    <Row className={styles.row} justify="center">
      <Col className="centered-content">{children}</Col>
    </Row>
  );
};

export default ContentCenter;
