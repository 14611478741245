import { createSelector } from 'reselect';
import { PipelineDesiredStatusCodes } from '@src/constants/pipelines';

export const pipelineStoreSelector = (state: any) => state.pipelineStore;

export const pipelinesSelector = createSelector(
  [pipelineStoreSelector, (_, desiredStatus?) => desiredStatus],
  (pipelineStore, desiredStatus) => {
    if (desiredStatus === PipelineDesiredStatusCodes.DEPLOYED)
      return pipelineStore.deployedPipelines;
    if (desiredStatus === PipelineDesiredStatusCodes.UNDEPLOYED)
      return pipelineStore.draftPipelines;
    return pipelineStore.pipelines;
  },
);

export const pipelineSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipeline,
);

export const pipelineMessageSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.message,
);

export const pipelineStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.status,
);

export const pipelineActionStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.actionStatus,
);

export const fetchPipelinesListStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.fetchPipelinesListStatus,
);

export const pipelineCreateUpdatePipelineStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.createUpdatePipelineStatus,
);

export const pipelineIndexingDetailsSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipeline.indexing_details,
);

export const pipelineStatisticsSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipelineStatistics,
);

export const pipelineLatestQueriesSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipelineLatestQueries,
);

export const loadingLatestQueriesStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.loadingLatestQueriesStatus,
);

export const pipelineStatisticsAnswersSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipelineStatisticsAnswers,
);

export const pipelineFeedbackSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipelineFeedback,
);

export const sharedPrototypeSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.currentSharedPrototype,
);

export const fetchingSharedPrototypeStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.loadingSharedPrototypeStatus,
);

export const generateSharedPrototypeStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.generateSharedPrototypeStatus,
);

export const deleteSharedPrototypeStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.deleteSharedPrototypeStatus,
);

export const pipelineLogsSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipelineLogs,
);

export const pipelineLogsStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipelineLogsStatus,
);

export const pipelineLogsUnhealthySelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipelineLogsUnhealthy,
);

export const renamePipelineNameStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.renamePipelineNameStatus,
);

export const activeTabPipelineDetailsPageSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.activeTabPipelineDetailsPage,
);

export const pipelineErrorsSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.pipelineErrors,
);

export const newPipelineNameSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.newPipelineName,
);

export const fetchPipelineYamlStatusSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.fetchPipelineYamlStatus,
);

export const activeTabPipelinesLandingPageSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.activeTabPipelinesLandingPage,
);

export const activeTabYamlEditorSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.activeTabYamlEditor,
);

export const indexingCodeSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.indexingCode,
);

export const queryCodeSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.queryCode,
);

export const indexingYamlEditorDiffSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.indexingYamlEditorDiff,
);

export const queryYamlEditorDiffSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.queryYamlEditorDiff,
);

export const sortValuePipelinesListSelector = createSelector(
  pipelineStoreSelector,
  (pipelineStore) => pipelineStore.sortValuePipelinesList,
);
