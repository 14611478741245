/* eslint-disable no-await-in-loop */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFileExtension } from '@utils/common';
import { normalizeErrorMessage } from '@utils/error';
import { getODataRangeFilterFrom, normalizeODataStringsField } from '@utils/odata';
import { getFilePreviewBlobByTypeApi as getFilePreviewBlobByTypeExternalApi } from '@api/external/data';
import { UploadFileType } from '@constants/constant';
import { FILE_META_UPDATED_MESSAGE, FILE_SORTING_PARAMS_BY_KEY } from '@constants/data-page';
import { initialState } from '@redux/rootReducer';
import {
  DELETE_ALL_FILES,
  DELETE_FILE,
  DELETE_MULTIPLE_FILES,
  DOWNLOAD_FILE,
  GET_FILE_CONTENT,
  GET_SAMPLE_DATASET_LIST,
  GET_WORKSPACE_FILES,
  IMPORT_SAMPLE_DATASET,
  ISelectedFileDocument,
  MetadataFiltersType,
  NotificationType,
  RESET_FILE_CONTENT,
  RESET_FILES_MESSAGE,
  SELECT_FILE_DOCUMENT,
  SELECT_FILE_SORT_VALUE,
  UPDATE_FILE_META,
} from '@redux/types/types';
import {
  deleteAllWorkspaceFilesApi,
  deleteWorkspaceFilesApi,
  getFileBlobApi,
  getFilePreviewBlobByTypeApi,
  getSampleDatasetsApi,
  getWorkspaceFilesApi,
  IAllFilesData,
  importSampleDatasetApi,
  updateFileMetaApi,
} from '@src/api/data';
import { downloadBlob } from '@src/utils/file';
import { addNotification, addSequentialNotification } from './notificationActions';

export const resetFilesMessage = {
  type: RESET_FILES_MESSAGE,
};

export const resetFileContent = {
  type: RESET_FILE_CONTENT,
};

export const selectSortValue = (value: string) => ({
  type: SELECT_FILE_SORT_VALUE,
  payload: value,
});

export const selectFileDocument = (payload: ISelectedFileDocument | null) => ({
  type: SELECT_FILE_DOCUMENT,
  payload,
});

export const getWorkspaceFiles = createAsyncThunk(
  GET_WORKSPACE_FILES,
  async (
    {
      currentPage,
      pageSize,
      searchValue,
      sortValue,
      after,
      metadataFilterValues = {},
    }: {
      currentPage: number;
      pageSize: number;
      searchValue: string;
      sortValue?: string;
      after?: {
        value: string;
        fileId: string;
      };
      metadataFilterValues?: Record<string, MetadataFiltersType>;
    },
    { rejectWithValue, getState, dispatch },
  ) => {
    const { sortValue: defaultSortValue } = (getState() as typeof initialState).fileStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof FILE_SORTING_PARAMS_BY_KEY;
    dispatch(selectSortValue(sortingKey));
    const { field, order } = FILE_SORTING_PARAMS_BY_KEY[sortingKey] || {};

    const metadataFilteringLogic = Object.keys(metadataFilterValues).reduce(
      (parsedFilters, filterKey) => {
        const isFirstFilter = !parsedFilters;
        let parsedFilter = '';

        if (
          (metadataFilterValues[filterKey] as any).min ||
          (metadataFilterValues[filterKey] as any).max
        ) {
          parsedFilter = getODataRangeFilterFrom(filterKey, [
            (metadataFilterValues[filterKey] as any).min,
            (metadataFilterValues[filterKey] as any).max,
          ]);
        } else {
          const filter = metadataFilterValues[filterKey] as unknown[];
          filter.forEach((filterValue, index) => {
            if (index === 0 && filter.length > 1 && Object.keys(metadataFilterValues).length > 1)
              parsedFilter = parsedFilter.concat(`(`);
            // FIXME: Not using `getODataEQFilterFrom` func as the files endpoint throws an error when
            // an UUID is passed without '' on a OData filter
            parsedFilter = parsedFilter.concat(
              `${normalizeODataStringsField(filterKey)} eq '${filterValue}'`,
            );
            if (index < Object.keys(filter).length - 1) parsedFilter = parsedFilter.concat(` or `);
            else if (filter.length > 1 && Object.keys(metadataFilterValues).length > 1)
              parsedFilter = parsedFilter.concat(`)`);
          }, '');
        }

        if (isFirstFilter) return parsedFilter;

        return `${parsedFilters} and ${parsedFilter}`;
      },
      '',
    );

    const allFilesData: IAllFilesData = {
      page_number: currentPage,
      limit: pageSize,
      name: searchValue,
      after,
      field,
      order,
      filters: metadataFilteringLogic,
    };
    try {
      const response = await getWorkspaceFilesApi(allFilesData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadFile = createAsyncThunk(
  DOWNLOAD_FILE,
  async (
    { fileId, fileName }: { fileId: string; fileName: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await getFileBlobApi(fileId);
      downloadBlob(fileName, response.data);
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const getFileContent = createAsyncThunk(
  GET_FILE_CONTENT,
  async (
    {
      fileId,
      fileName,
      isExternal = false,
    }: { fileId: string; fileName?: string; isExternal?: boolean },
    { dispatch, rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? getFilePreviewBlobByTypeExternalApi : getFilePreviewBlobByTypeApi;
    let workspace: string | undefined;
    const fileType = fileName ? (getFileExtension(fileName) as UploadFileType) : null;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }

    try {
      const response = await apiCall(fileId, fileType, workspace);
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteFile = createAsyncThunk(
  DELETE_FILE,
  async (fileId: string, { rejectWithValue }) => {
    try {
      const response = await deleteWorkspaceFilesApi(fileId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMultipleFiles = createAsyncThunk(
  DELETE_MULTIPLE_FILES,
  async (fileIDs: string[], { rejectWithValue }) => {
    try {
      let position = 0;
      const batchSize = 20;

      while (position < fileIDs.length) {
        const batch = fileIDs.slice(position, position + batchSize);
        await Promise.all(batch.map((fileId: string) => deleteWorkspaceFilesApi(fileId)));
        position += batchSize;
      }

      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteAllFiles = createAsyncThunk(DELETE_ALL_FILES, async (_, { rejectWithValue }) => {
  try {
    await deleteAllWorkspaceFilesApi();
    return true;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateFileMeta = createAsyncThunk(
  UPDATE_FILE_META,
  async (
    { fileId, meta }: { fileId: string; meta: Record<string, unknown> },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await updateFileMetaApi(fileId, meta);
      dispatch(
        addNotification({
          content: FILE_META_UPDATED_MESSAGE,
          type: NotificationType.Success,
        }),
      );
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const getSampleDatasets = createAsyncThunk(
  GET_SAMPLE_DATASET_LIST,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSampleDatasetsApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const importSampleDataset = createAsyncThunk(
  IMPORT_SAMPLE_DATASET,
  async (dataset: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(
        addSequentialNotification({
          content: 'Importing sample files to workspace.',
          type: NotificationType.Loading,
          duration: 0,
        }),
      );

      const response = await importSampleDatasetApi(dataset);

      dispatch(
        addSequentialNotification({
          content: 'Sample files imported successfully.',
          type: NotificationType.Success,
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addSequentialNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);
