import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloadBlobFile } from '@utils/file';
import {
  deleteWorkspaceEvalsetApi,
  getEvalsetApi,
  getEvalsetCSVApi,
  getWorkspaceEvalsetsApi,
} from '@api/data';
import { MIMETypes } from '@constants/enum/common';
import {
  DELETE_EVALSET,
  DELETE_MULTIPLE_EVALSETS,
  EXPORT_EVALSET,
  EXPORT_EVALSET_CSV,
  GET_WORKSPACE_EVALSETS,
  RESET_EVALSET_MESSAGE,
} from '@redux/types/types';

export const resetEvalsetMessage = {
  type: RESET_EVALSET_MESSAGE,
};

export const getWorkspaceEvalsets = createAsyncThunk(
  GET_WORKSPACE_EVALSETS,
  async (
    {
      currentPage,
      pageSize,
      searchValue,
    }: {
      currentPage: number;
      pageSize: number;
      searchValue: string;
    },
    { rejectWithValue },
  ) => {
    const allFilesData = {
      page_number: currentPage,
      limit: pageSize,
      name: searchValue,
    };
    try {
      const response = await getWorkspaceEvalsetsApi(allFilesData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const exportEvalset = createAsyncThunk(
  EXPORT_EVALSET,
  async (evalsetName: string, { rejectWithValue }) => {
    try {
      const response = await getEvalsetApi(evalsetName);
      const jsonData = JSON.stringify(response.data);
      downloadBlobFile(`${evalsetName}.json`, jsonData, 'application/json');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const exportEvalsetCSV = createAsyncThunk(
  EXPORT_EVALSET_CSV,
  async (evalsetName: string, { rejectWithValue }) => {
    try {
      const response = await getEvalsetCSVApi(evalsetName);
      downloadBlobFile(evalsetName, response.data, MIMETypes.CSV);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteEvalset = createAsyncThunk(
  DELETE_EVALSET,
  async (evalsetName: string, { rejectWithValue }) => {
    try {
      const response = await deleteWorkspaceEvalsetApi(evalsetName);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMultipleEvalsets = createAsyncThunk(
  DELETE_MULTIPLE_EVALSETS,
  async (evalsetNames: string[], { rejectWithValue }) => {
    try {
      const promises = evalsetNames.map((evalsetName) => deleteWorkspaceEvalsetApi(evalsetName));
      await Promise.all(promises);
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
