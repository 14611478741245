import { createAsyncThunk } from '@reduxjs/toolkit';
import { CanceledError } from 'axios';
import AbortControllerManager from '@utils/abortController';
import { getStatusCode, normalizeErrorMessage } from '@utils/error';
import {
  getParsedMetadataFiltersRequest,
  getParsedMetadataFiltersV2Request,
  parseQueryStreamChunks,
} from '@utils/search';
import {
  chatQueryExternalApi,
  chatQueryStreamExternalApi,
  createSearchSessionExternalApi,
  searchExternalApi,
  searchStreamExternalApi,
} from '@api/external/query';
import {
  chatQueryApi,
  chatQueryStreamApi,
  createSearchSessionApi,
  getSearchSessionsApi,
  searchApi,
  searchStreamApi,
} from '@api/search';
import { HTTPStatusCode } from '@constants/enum/common';
import { ClientSourcePathHeaderOptions, CustomHTTPHeaders } from '@constants/http';
import { PipelineOutputType } from '@constants/pipelines';
import { initialState } from '@redux/rootReducer';
import {
  ADD_PROMPT_EXPLORER_INFO_RESULT,
  APPEND_CHAT_STREAM,
  APPEND_PROMPT_EXPLORER_QUERY_STREAM,
  APPEND_SEARCH_STREAM,
  CREATE_SEARCH_SESSION,
  GET_SEARCH_SESSIONS,
  IPipelineQueryParams,
  IQueryStreamMessageData,
  MetadataFiltersType,
  NotificationDuration,
  NotificationType,
  OPEN_REFERENCE_DRAWER,
  QUERY_PROMPT_EXPLORER,
  RequestController,
  RESET_CHAT_RESULTS,
  RESET_QUERY_RESULT_PROMPT_EXPLORER,
  RESET_REFERENCE_DRAWER,
  RESET_SEARCH_RESULT,
  SEARCH,
  SEND_CHAT_QUERY,
  SET_SEARCH_PIPELINE,
  TOGGLE_DEBUG_MODE,
  TOGGLE_PROMPT_MODAL,
} from '@redux/types/types';
import { addNotification } from './notificationActions';

export const resetResults = {
  type: RESET_SEARCH_RESULT,
};

export const resetChatResults = {
  type: RESET_CHAT_RESULTS,
};

export const openReferenceDrawer = (resultId?: string, referenceId?: string) => ({
  type: OPEN_REFERENCE_DRAWER,
  payload: {
    resultId,
    referenceId,
  },
});

export const resetReferenceDrawer = {
  type: RESET_REFERENCE_DRAWER,
};

export const togglePromptModal = (prompts?: Record<string, string> | null) => ({
  type: TOGGLE_PROMPT_MODAL,
  payload: prompts,
});

export const resetSearchResultsPromptExplorer = (playgroundId: string) => ({
  type: RESET_QUERY_RESULT_PROMPT_EXPLORER,
  payload: playgroundId,
});

export const setSearchPipeline = (name: string) => {
  return {
    type: SET_SEARCH_PIPELINE,
    payload: name,
  };
};

export const appendSearchStreamData = (data: IQueryStreamMessageData & { query: string }) => {
  return {
    type: APPEND_SEARCH_STREAM,
    payload: data,
  };
};

export const search = createAsyncThunk(
  SEARCH,
  async (
    {
      pipelineName,
      query,
      viewPrompts,
      filters = {},
      params,
      sessionId,
      isExternal,
      isV2,
      streaming,
    }: {
      pipelineName: string;
      query: string;
      viewPrompts?: boolean;
      filters?: Record<string, MetadataFiltersType>;
      params?: Record<string, unknown>;
      sessionId?: string;
      isExternal?: boolean;
      isV2?: boolean;
      streaming?: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? searchExternalApi : searchApi;
    const apiStreamCall = isExternal ? searchStreamExternalApi : searchStreamApi;
    let workspace: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }

    const { debugMode } = (getState() as typeof initialState).searchStore;

    const defaultPayload = {
      filters: isV2
        ? getParsedMetadataFiltersV2Request(filters)
        : getParsedMetadataFiltersRequest(filters),
      params,
      search_session_id: sessionId,
      view_prompts: viewPrompts,
      debug: !!debugMode,
    };

    try {
      if (!streaming) {
        const searchPayload = {
          ...defaultPayload,
          queries: [query],
        };
        const response = await apiCall(pipelineName, searchPayload, {}, workspace);
        return response.data;
      }

      const abortController = AbortControllerManager.getInstance().create(
        RequestController.SEARCH_QUERY,
      );

      const streamPayload = {
        ...defaultPayload,
        query,
      };
      const handleStream = async () => {
        await new Promise<void>((_, reject) => {
          apiStreamCall(
            pipelineName,
            streamPayload,
            (chunks) => {
              const parsedChunk = parseQueryStreamChunks(chunks);
              if (!parsedChunk?.query_id) return;
              if (parsedChunk?.error) return reject(parsedChunk.error);

              dispatch(appendSearchStreamData({ ...parsedChunk, query }));
            },
            {
              signal: abortController.signal,
            },
            workspace,
          ).catch(reject);
        });
      };

      await handleStream();
    } catch (error) {
      if (error instanceof CanceledError) return;
      const statusCode = getStatusCode(error);
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          duration: NotificationDuration.long,
          type:
            statusCode === HTTPStatusCode.UNDEPLOYED_PIPELINE_ERROR_STATUS
              ? NotificationType.Warning
              : NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const createSearchSession = createAsyncThunk(
  CREATE_SEARCH_SESSION,
  async (
    { pipelineId, isExternal }: { pipelineId: string; isExternal?: boolean },
    { rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? createSearchSessionExternalApi : createSearchSessionApi;
    let workspace: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }

    try {
      const body = {
        pipeline_id: pipelineId,
      };
      const response = await apiCall(body, workspace);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSearchSessions = createAsyncThunk(
  GET_SEARCH_SESSIONS,
  async (
    params: { limit?: number; pageNumber: number; filter?: string; fetchMore?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = await getSearchSessionsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const appendChatStreamData = (data: IQueryStreamMessageData & { query: string }) => {
  return {
    type: APPEND_CHAT_STREAM,
    payload: data,
  };
};

// TODO: Remove non-streaming once we support streaming on v2
export const sendChatQuery = createAsyncThunk(
  SEND_CHAT_QUERY,
  async (
    {
      pipelineName,
      query,
      sessionId,
      viewPrompts,
      filters = {},
      params,
      isExternal,
      isV2,
      streaming,
    }: {
      pipelineName: string;
      query: string;
      sessionId: string;
      viewPrompts?: boolean;
      filters?: Record<string, MetadataFiltersType>;
      params?: Record<string, unknown>;
      isExternal?: boolean;
      isV2?: boolean;
      streaming?: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? chatQueryExternalApi : chatQueryApi;
    const apiStreamCall = isExternal ? chatQueryStreamExternalApi : chatQueryStreamApi;
    let workspace: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }

    const { debugMode } = (getState() as typeof initialState).searchStore;

    const defaultPayload = {
      search_session_id: sessionId,
      filters: isV2
        ? getParsedMetadataFiltersV2Request(filters)
        : getParsedMetadataFiltersRequest(filters),
      params,
      view_prompts: viewPrompts,
      debug: !!debugMode,
    };
    const requestHeaders = {
      [CustomHTTPHeaders.X_CLIENT_SOURCE_PATH]: ClientSourcePathHeaderOptions.CHAT,
    };

    try {
      if (!streaming) {
        const chatPayload = {
          ...defaultPayload,
          queries: [query],
        };
        const { data } = await apiCall(pipelineName, chatPayload, requestHeaders, workspace);
        return data;
      }

      const abortController = AbortControllerManager.getInstance().create(
        RequestController.CHAT_QUERY,
      );

      const streamPayload = {
        ...defaultPayload,
        query,
      };
      const handleStream = async () => {
        await new Promise<void>((_, reject) => {
          apiStreamCall(
            pipelineName,
            streamPayload,
            (chunks) => {
              const parsedChunk = parseQueryStreamChunks(chunks);
              if (!parsedChunk?.query_id) return;
              if (parsedChunk?.error) return reject(parsedChunk.error);

              dispatch(appendChatStreamData({ ...parsedChunk, query }));
            },
            { headers: requestHeaders, signal: abortController.signal },
            workspace,
          ).catch(reject);
        });
      };

      await handleStream();
    } catch (error) {
      if (error instanceof CanceledError) return;

      const statusCode = getStatusCode(error);
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          duration: NotificationDuration.long,
          type:
            statusCode === HTTPStatusCode.UNDEPLOYED_PIPELINE_ERROR_STATUS
              ? NotificationType.Warning
              : NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const appendPromptExplorerQueryStreamData = (
  data: IQueryStreamMessageData & {
    query: string;
    playgroundId: string;
    params?: IPipelineQueryParams;
  },
) => {
  return {
    type: APPEND_PROMPT_EXPLORER_QUERY_STREAM,
    payload: data,
  };
};

// TODO: Remove non-streaming once we support streaming on v2
export const queryPromptExplorer = createAsyncThunk(
  QUERY_PROMPT_EXPLORER,
  async (
    {
      pipelineName,
      pipelineOutputType,
      query,
      sessionId,
      filters = {},
      viewPrompts,
      params,
      isV2,
      streaming,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      playgroundId,
    }: {
      pipelineName: string;
      pipelineOutputType: PipelineOutputType;
      query: string;
      sessionId: string;
      playgroundId: string;
      viewPrompts?: boolean;
      filters?: Record<string, MetadataFiltersType>;
      params?: IPipelineQueryParams;
      isV2?: boolean;
      streaming?: boolean;
    },
    { dispatch, rejectWithValue },
  ) => {
    const requestHeaders = {
      [CustomHTTPHeaders.X_CLIENT_SOURCE_PATH]: ClientSourcePathHeaderOptions.PROMPT_EXPLORER,
    };
    const queryApiCall = pipelineOutputType !== PipelineOutputType.CHAT ? searchApi : chatQueryApi;
    const queryStreamApiCall =
      pipelineOutputType !== PipelineOutputType.CHAT ? searchStreamApi : chatQueryStreamApi;

    const defaultPayload = {
      search_session_id: sessionId,
      filters: isV2
        ? getParsedMetadataFiltersV2Request(filters)
        : getParsedMetadataFiltersRequest(filters),
      params,
      view_prompts: viewPrompts,
    };

    try {
      if (!streaming) {
        const queryPayload = {
          ...defaultPayload,
          queries: [query],
        };
        const { data } = await queryApiCall(pipelineName, queryPayload, requestHeaders);
        return data;
      }

      const abortController = AbortControllerManager.getInstance().create(
        `${RequestController.PROMPT_EXPLORER_QUERY}_${playgroundId}`,
      );

      const streamPayload = {
        ...defaultPayload,
        query,
      };

      const handleStream = async () => {
        await new Promise<void>((_, reject) => {
          queryStreamApiCall(
            pipelineName,
            streamPayload,
            (chunks) => {
              const parsedChunk = parseQueryStreamChunks(chunks);
              if (!parsedChunk?.query_id) return;
              if (parsedChunk?.error) return reject(parsedChunk.error);

              dispatch(
                appendPromptExplorerQueryStreamData({
                  ...parsedChunk,
                  query,
                  playgroundId,
                  params,
                }),
              );
            },
            { headers: requestHeaders, signal: abortController.signal },
          ).catch(reject);
        });
      };

      await handleStream();
    } catch (error) {
      if (error instanceof CanceledError) return;

      const statusCode = getStatusCode(error);
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          duration: NotificationDuration.long,
          type:
            statusCode === HTTPStatusCode.UNDEPLOYED_PIPELINE_ERROR_STATUS
              ? NotificationType.Warning
              : NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const addPromptExplorerInfoResult = (data: { playgroundId: string; infoText: string }) => ({
  type: ADD_PROMPT_EXPLORER_INFO_RESULT,
  payload: data,
});

export const toggleDebugMode = (enabled: boolean) => ({
  type: TOGGLE_DEBUG_MODE,
  payload: enabled,
});
