import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { FILE_DRAWER_COLLAPSE_TABS } from '@constants/common';
import { resetReferenceDrawer as resetReferenceDrawerAction } from '@redux/actions/searchActions';
import {
  activeReferenceSelector,
  referenceDrawerVisibleSelector,
} from '@redux/selectors/searchSelectors';
import {
  FileDrawerCollapseTabOptions,
  Groundedness,
  IMappedReferencesMetaAnnotation,
  IReferencesMetaAnnotation,
  ISearchResult,
  ISearchResultAnswer,
} from '@redux/types/types';

const useReferences = () => {
  const dispatch = useDispatch();
  const referenceDrawerVisible = useSelector(referenceDrawerVisibleSelector);
  const activeReference = useSelector(activeReferenceSelector);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    activeReference?.referenceId || '',
  ]);
  const [currentActiveCollapseTab, setCurrentActiveCollapseTab] = useState<
    FileDrawerCollapseTabOptions | string
  >(FILE_DRAWER_COLLAPSE_TABS[1].key);

  // TODO: Revisit search result type, as answers could be null when coming from prompt explorer
  const findActiveReferenceAnswer = (
    answers: ISearchResultAnswer[],
    referenceResultId?: string,
  ) => {
    return (
      answers?.find(
        (result) => result.result_id === (referenceResultId || activeReference?.resultId),
      ) ?? null
    );
  };

  const getSearchResultReferences = (searchResult: ISearchResult, referenceResultId?: string) => {
    const { answers, documents } = searchResult;

    const answer = findActiveReferenceAnswer(answers, referenceResultId);

    if (!answer) return null;

    const { meta, result_id: resultId } = answer;
    const references = get(meta, '_references', []) as IReferencesMetaAnnotation[];

    const augmentedReferences = references
      .filter((reference) => reference.label === Groundedness.GROUNDED)
      .map((reference: IReferencesMetaAnnotation) => {
        const document = documents.find((result) => result.id === reference.document_id);
        return {
          ...reference,
          content: document?.content || null,
          file_name: document?.file?.name || null,
          file_id: document?.file?.id || null,
          result_id: resultId,
          meta: document?.meta || null,
        } as IMappedReferencesMetaAnnotation;
      });

    return augmentedReferences;
  };

  const resetReferenceDrawer = () => {
    dispatch(resetReferenceDrawerAction);
  };

  return {
    referenceDrawerVisible,
    activeReference,
    getSearchResultReferences,
    collapseActiveKeys,
    setCollapseActiveKeys,
    currentActiveCollapseTab,
    setCurrentActiveCollapseTab,
    findActiveReferenceAnswer,
    resetReferenceDrawer,
  };
};

export default useReferences;
