import { datadogRum } from '@datadog/browser-rum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { auth } from '@utils/auth';
import { Navigator } from '@utils/navigator';
import { transformUserProfile } from '@utils/transformation/user';
import { getUserApi, getUserDetailsApi } from '@api/users';
import { UserRoles } from '@constants/enum/common';
import { persistor } from '@redux/store';
import {
  FETCH_USER_INFO,
  GET_USER,
  IAuthUserData,
  IUserData,
  IUserProfileData,
  USER_LOGIN,
  USER_LOGOUT,
} from '@redux/types/types';
import { setOrganizationInfo } from './organizationActions';

export const fetchUserInfo = createAsyncThunk(
  FETCH_USER_INFO,
  async (
    { authUser, redirect }: { authUser: IAuthUserData; redirect?: boolean; location?: string },
    { dispatch },
  ) => {
    const response = await getUserDetailsApi();
    const userProfile: IUserProfileData = response.data;
    const user: IUserData = transformUserProfile(authUser, userProfile);

    // TODO: Refactor organizations
    dispatch(
      setOrganizationInfo({
        organizationName: _get(userProfile, 'organization.name', ''),
        organizationId: _get(userProfile, 'organization.organization_id', ''),
        maxWorkspaces: _get(userProfile, 'organization.max_workspaces', 0),
      }),
    );

    if (redirect) {
      if (user.role === UserRoles.SEARCH_USER) Navigator.navigate('/playground');
      else if (user.role == UserRoles.ADMIN || user.role == UserRoles.PIPELINE_STUDIO)
        Navigator.navigate('/');
      else Navigator.navigate('/logout');
    }

    datadogRum.setUser({
      id: user.userID,
      role: user.role,
      is_deepset_user: user.email.includes('deepset'),
    });
    return user;
  },
);

export const getUser = createAsyncThunk(GET_USER, async (userId: string, { rejectWithValue }) => {
  try {
    const { data } = await getUserApi(userId);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const login = () => {
  persistor.purge().then(() => {
    return persistor.flush();
  });
  auth.loginWithRedirect({});

  return {
    type: USER_LOGIN,
  };
};

export const logout = () => {
  persistor.purge().then(() => {
    return persistor.flush();
  });
  auth.logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
  return {
    type: USER_LOGOUT,
  };
};
