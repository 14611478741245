import React, { ReactElement } from 'react';
import { isNil } from 'lodash';

type StringFormater = Record<string, string | number | ReactElement | HTMLElement>;

export const interpolateString = (
  string: string,
  formaters: StringFormater,
  returnJSXElement: boolean = true,
  specialCharacter: string = '%',
) => {
  const splittedStringBySpecialCharacter = string.split(specialCharacter);
  const result = splittedStringBySpecialCharacter.map((item) => {
    if (isNil(formaters[item])) return item;
    if (typeof formaters[item] === 'object')
      return { ...(formaters[item] as ReactElement), key: item };
    return formaters[item];
  });

  if (returnJSXElement) return <>{result}</>;
  return result.join('');
};

export const transformToTitleCase = (name: string) => {
  if (name.includes('-') || name.includes('_'))
    return name.split(/[-_]/).reduce((transformedWord, word) => {
      const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
      if (!transformedWord) return capitalized;
      return `${transformedWord} ${capitalized}`;
    }, '');
  const result = name.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const trimContent = (content: string, trimLenght: number = 300) => {
  if (!content) return '';
  let trimmedContent = content.slice(0, trimLenght);
  if (content.length > trimLenght) trimmedContent = `${trimmedContent}...`;
  return trimmedContent;
};

export const createLink = (href: string, label: string) =>
  React.createElement(
    'a',
    {
      href,
      target: '_blank',
      rel: 'noreferrer',
    },
    label,
  );

export const isValidDataName = (name: string | null) => {
  if (!name) return false;
  const regex = new RegExp('^[a-zA-Z0-9._-]*$');
  return regex.test(name);
};

export const getStringsInitials = (...strings: string[]) =>
  strings.reduce((initials, string) => {
    if (!string) return initials;
    return initials + string[0];
  }, '');
