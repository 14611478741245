import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloadBlobFile } from '@utils/file';
import { getODataFilterFrom, getODataSearchFilterFrom } from '@utils/odata';
import {
  createEvalRunApi,
  deleteEvalRunApi,
  getEvalRunApi,
  getEvalRunCSVApi,
  getEvalRunsApi,
  getRecursivelyEvalRunsApi,
  getRunPredictionsApi,
  startEvalRunApi,
  updateEvalRunApi,
  updateEvalRunCommentApi,
} from '@api/experiment';
import { getTagsApi } from '@api/workspace';
import { SelectedFilters } from '@constants/data-table';
import { MIMETypes } from '@constants/enum/common';
import { SORTING_PARAMS_BY_KEY } from '@constants/experiments';
import { initialState } from '@redux/rootReducer';
import {
  CREATE_EVALUATION_RUN,
  DELETE_EVALUATION_RUN,
  DELETE_MULTIPLE_EVALUATION_RUNS,
  DOWNLOAD_EVALUATION_RUN,
  GET_EVALUATION_RUN,
  GET_EXPERIMENTS,
  GET_FILTER_STATUSES,
  GET_FILTER_TAGS,
  GET_FILTER_USERS,
  GET_RUN_PREDICTIONS,
  GET_TAGS,
  IAPIPaginationData,
  IExperiment,
  IStatusFilter,
  ITag,
  IUserFilter,
  RESET_CURRENT_EXPERIMENT,
  RESET_EXPERIMENTS_MESSAGE,
  SAVE_AND_START_EVALUATION_RUN,
  SELECT_PREDICTIONS_SORT_VALUE,
  SELECT_SORT_VALUE,
  START_EVALUATION_RUN,
  START_MULTIPLE_EVALUATION_RUNS,
  START_POLLING_RUN_STATUS,
  START_POLLING_RUNS_STATUS,
  STOP_POLLING_RUN_STATUS,
  STOP_POLLING_RUNS_STATUS,
  UPDATE_EVALUATION_RUN,
  UPDATE_EVALUATION_RUN_COMMENT,
} from '@redux/types/types';

let interval: any;
let intervalDetails: any;

export const resetExperimentsMessage = {
  type: RESET_EXPERIMENTS_MESSAGE,
};

export const resetCurrentExperimentData = {
  type: RESET_CURRENT_EXPERIMENT,
};

export const selectSortValue = (value: string) => ({
  type: SELECT_SORT_VALUE,
  payload: value,
});

export const selectPredictionsSortValue = (nodeType: string, value: string) => ({
  type: SELECT_PREDICTIONS_SORT_VALUE,
  payload: { nodeType, value },
});

export const getEvalRunsFilterTags = createAsyncThunk(
  GET_FILTER_TAGS,
  async (_, { rejectWithValue }) => {
    const query = {
      select: 'tags/name, tags/tag_id',
    };
    try {
      const data = await getRecursivelyEvalRunsApi<ITag[]>(query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getEvalRunsFilterStatuses = createAsyncThunk(
  GET_FILTER_STATUSES,
  async (_, { rejectWithValue }) => {
    const query = {
      select: 'status',
    };
    try {
      const data = await getRecursivelyEvalRunsApi<IStatusFilter[]>(query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getEvalRunsFilterUsers = createAsyncThunk(
  GET_FILTER_USERS,
  async (_, { rejectWithValue }) => {
    const query = {
      select: 'created_by/user_id, created_by/given_name',
    };
    try {
      const data = await getRecursivelyEvalRunsApi<IUserFilter[]>(query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getEvalRun = createAsyncThunk(
  GET_EVALUATION_RUN,
  async (name: string, { rejectWithValue }) => {
    try {
      const response = await getEvalRunApi(name);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getEvalRuns = createAsyncThunk(
  GET_EXPERIMENTS,
  async (
    {
      currentPage,
      pageSize,
      searchValue,
      sortValue,
      filterValues,
    }: {
      currentPage: number;
      pageSize: number;
      searchValue: string;
      sortValue?: string;
      filterValues?: SelectedFilters;
    },
    { rejectWithValue, getState, dispatch },
  ) => {
    const { sortValue: defaultSortValue } = (getState() as typeof initialState).experimentStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof SORTING_PARAMS_BY_KEY;

    dispatch(selectSortValue(sortingKey));

    const { field, order } = SORTING_PARAMS_BY_KEY[sortingKey] || {};
    const valueFilters = filterValues && getODataFilterFrom(filterValues);
    const searchFilter = searchValue && getODataSearchFilterFrom('name', searchValue);
    const filter = searchFilter
      ? `${valueFilters ? `${valueFilters} AND ` : ''}${searchFilter}`
      : valueFilters;

    const query = {
      page_number: currentPage,
      limit: pageSize,
      field,
      order,
      filter,
    };
    try {
      const response = await getEvalRunsApi<IAPIPaginationData<IExperiment[]>>(query);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createEvalRun = createAsyncThunk(
  CREATE_EVALUATION_RUN,
  async (
    {
      name,
      pipelineName,
      evalsetName,
      tags,
    }: {
      name: string;
      pipelineName: string;
      evalsetName: string;
      tags: string[];
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await createEvalRunApi(name, pipelineName, evalsetName, tags);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteEvalRun = createAsyncThunk(
  DELETE_EVALUATION_RUN,
  async (evalRun: string, { rejectWithValue }) => {
    try {
      const response = await deleteEvalRunApi(evalRun);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMultipleEvalRuns = createAsyncThunk(
  DELETE_MULTIPLE_EVALUATION_RUNS,
  async (evalRunsNames: string[], { rejectWithValue }) => {
    try {
      const requests = evalRunsNames.map((name) => deleteEvalRunApi(name));
      const responses = await Promise.all(requests);
      return responses;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startEvalRun = createAsyncThunk(
  START_EVALUATION_RUN,
  async (evalRun: string, { rejectWithValue }) => {
    try {
      const response = await startEvalRunApi(evalRun);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startMultipleEvalRuns = createAsyncThunk(
  START_MULTIPLE_EVALUATION_RUNS,
  async (evalRunsNames: string[], { rejectWithValue }) => {
    try {
      const requests = evalRunsNames.map((name) => startEvalRunApi(name));
      const responses = await Promise.all(requests);
      return responses;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveAndStartEvalRun = createAsyncThunk(
  SAVE_AND_START_EVALUATION_RUN,
  async (
    {
      name,
      pipelineName,
      evalsetName,
      tags,
    }: {
      name: string;
      pipelineName: string;
      evalsetName: string;
      tags: string[];
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await createEvalRunApi(name, pipelineName, evalsetName, tags);
      const response = await startEvalRunApi(data.data.name);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startPollingRunsStatus =
  (
    currentPage: number,
    pageSize: number,
    searchValue: string,
    sortValue?: string,
    filterValues?: SelectedFilters,
  ) =>
  (dispatch: any) => {
    interval = setInterval(
      () => dispatch(getEvalRuns({ currentPage, pageSize, searchValue, sortValue, filterValues })),
      10000,
    );
    return {
      type: START_POLLING_RUNS_STATUS,
    };
  };

export const stopPollingRunsStatus = () => {
  clearInterval(interval);
  return {
    type: STOP_POLLING_RUNS_STATUS,
  };
};

export const downloadEvalRunCSV = createAsyncThunk(
  DOWNLOAD_EVALUATION_RUN,
  async ({ evalRun, nodeName }: { evalRun: string; nodeName: string }, { rejectWithValue }) => {
    try {
      const readerResponse = await getEvalRunCSVApi(evalRun, nodeName);
      downloadBlobFile(`${evalRun}_${nodeName}`, readerResponse.data, MIMETypes.CSV);
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateEvalRun = createAsyncThunk(
  UPDATE_EVALUATION_RUN,
  async (
    {
      name,
      pipelineName,
      evalSetName,
      comment,
      tags,
    }: {
      name: string;
      pipelineName?: string;
      evalSetName?: string;
      comment?: string;
      tags?: string[];
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await updateEvalRunApi(name, pipelineName, evalSetName, comment, tags);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateEvalRunNotes = createAsyncThunk(
  UPDATE_EVALUATION_RUN_COMMENT,
  async (
    {
      name,
      comment,
    }: {
      name: string;
      comment: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await updateEvalRunCommentApi(name, comment);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startPollingRunStatus = (name: string) => (dispatch: any) => {
  clearInterval(intervalDetails);
  intervalDetails = setInterval(() => dispatch(getEvalRun(name)), 10000);
  return {
    type: START_POLLING_RUN_STATUS,
  };
};

export const stopPollingRunStatus = () => {
  clearInterval(intervalDetails);
  return {
    type: STOP_POLLING_RUN_STATUS,
  };
};

export const getTags = createAsyncThunk(GET_TAGS, async (_, { rejectWithValue }) => {
  try {
    const response = await getTagsApi();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRunPredictions = createAsyncThunk(
  GET_RUN_PREDICTIONS,
  async (
    {
      evalRun,
      nodeName,
      nodeType,
      currentPage,
      pageSize,
      searchValue,
      sortValue,
      filterValues,
    }: {
      evalRun: string;
      nodeName: string;
      nodeType: string;
      currentPage: number;
      pageSize: number;
      searchValue: string;
      sortValue?: string;
      filterValues?: SelectedFilters;
    },
    { rejectWithValue, getState, dispatch },
  ) => {
    const { predictionsSortValueByNode: defaultSortValueByNode } = (
      getState() as typeof initialState
    ).experimentStore;

    const sortingKey = (sortValue ||
      defaultSortValueByNode[nodeType]) as keyof typeof SORTING_PARAMS_BY_KEY;

    dispatch(selectPredictionsSortValue(nodeType, sortingKey));

    const parsedODataFilers = filterValues && getODataFilterFrom(filterValues);
    const searchFilter = searchValue && getODataSearchFilterFrom('query', searchValue);
    const filter = searchFilter
      ? `${parsedODataFilers ? `${parsedODataFilers} and ` : ''}${searchFilter}`
      : parsedODataFilers;

    const { field, order } = SORTING_PARAMS_BY_KEY[sortingKey] || {};
    const query = {
      page_number: currentPage,
      limit: pageSize,
      filter,
      field,
      order,
    };
    try {
      const response = await getRunPredictionsApi(evalRun, nodeName, query);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
