import React from 'react';
import {
  CheckCircleOutlined,
  DislikeOutlined,
  FieldTimeOutlined,
  InfoCircleOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { formatNumberToLocaleString } from '@utils/math';
import { transformToTitleCase } from '@utils/string';
import { FEEDBACK_TYPE_LABELS } from '@constants/pipeline-feedback';
import { ACCURACY_TOOLTIP, NOT_APPLICABLE_LABEL } from '@constants/pipelines';
import { FeedbackType, IPipelineStatisticsAnswers } from '@redux/types/types';
import styles from './pipelineStatisticsAnswers.module.scss';

interface IPipelineStatistic {
  key: string;
  label: string;
  icon: React.ReactNode | null;
  value: string | null;
}

interface IPipelineStatisticsAnswersComponent {
  statisticsAnswers: IPipelineStatisticsAnswers;
}

const PipelineStatisticsAnswers = (props: IPipelineStatisticsAnswersComponent) => {
  const { statisticsAnswers } = props;

  const getStatIcon = (statistic: string) => {
    if (statistic === 'correct_answers') {
      return <LikeOutlined />;
    }
    if (statistic === 'wrong_answers') {
      return <DislikeOutlined />;
    }
    if (statistic === 'wrong_answers_with_correct_document') {
      return (
        <span>
          <DislikeOutlined />
          <LikeOutlined />
        </span>
      );
    }
    if (statistic === 'latency') {
      return <FieldTimeOutlined />;
    }
    if (statistic === 'accuracy_of_answers') {
      return <CheckCircleOutlined />;
    }
    return null;
  };

  const getStatLabel = (stat: string) => {
    const mappedLabels = {
      correct_answers: FEEDBACK_TYPE_LABELS[FeedbackType.ACCURATE],
      wrong_answers: FEEDBACK_TYPE_LABELS[FeedbackType.INACCURATE],
      wrong_answers_with_correct_document: FEEDBACK_TYPE_LABELS[FeedbackType.FAIRLY_ACCURATE],
    };

    return (
      mappedLabels[
        stat as 'correct_answers' | 'wrong_answers' | 'wrong_answers_with_correct_document'
      ] || transformToTitleCase(stat)
    );
  };

  const statisticsAnswersItems = Object.keys(statisticsAnswers).map((statistic) => ({
    key: statistic,
    label: getStatLabel(statistic),
    icon: getStatIcon(statistic),
    value:
      typeof statisticsAnswers[statistic as keyof IPipelineStatisticsAnswers] === 'number'
        ? formatNumberToLocaleString(
            statisticsAnswers[statistic as keyof IPipelineStatisticsAnswers],
            true,
          )
        : null,
  }));

  const getValue = (stat: IPipelineStatistic) => {
    if (stat.value && stat.key === 'accuracy_of_answers') {
      return formatNumberToLocaleString(+stat.value * 100, true);
    }
    return stat.value;
  };

  return (
    <section className={styles.section}>
      <div className={styles.statisticsAnswers}>
        {statisticsAnswersItems.map((statisticsAnswer) => {
          return (
            <div key={statisticsAnswer.key} className={styles.statisticsAnswers_item}>
              <p className={styles.statisticsAnswers_itemTitle}>
                {statisticsAnswer.label}
                {statisticsAnswer.key === 'accuracy_of_answers' && (
                  <Tooltip placement="top" title={ACCURACY_TOOLTIP}>
                    <InfoCircleOutlined className={styles.statisticsAnswers_itemTitle_infoIcon} />
                  </Tooltip>
                )}
              </p>
              <div className={styles.statisticsAnswers_itemStat}>
                <div className={styles.statisticsAnswers_itemStat_icon}>
                  {statisticsAnswer.icon}
                </div>
                <div className={styles.statisticsAnswers_itemStat_value}>
                  {statisticsAnswer.value ? getValue(statisticsAnswer) : NOT_APPLICABLE_LABEL}
                  {statisticsAnswer.key === 'latency' && <span>s</span>}
                  {statisticsAnswer.key === 'accuracy_of_answers' && statisticsAnswer.value && (
                    <span>%</span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default PipelineStatisticsAnswers;
