import React, { useState } from 'react';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Radio } from 'antd';
import {
  ADD_NEW_FILTER_BUTTON_LABEL,
  DATASET_SECTION_DESCRIPTION,
  NEW_EVAL_RUN_DATASET_SELECTION_ITEMS,
  NewEvalRunDatasetSelectionOption,
} from '@constants/experiments';
import styles from './datasetSelectionFilters.module.scss';

// TODO: TMP, remove once API implemented - https://github.com/deepset-ai/haystack-hub-api/issues/3359
const metadataFieldsOptions = [
  { key: 'date', label: 'Date' },
  { key: 'Categories', label: 'Categories' },
];

interface IDatasetSelectionFiltersProps {
  title: string;
  onOptionSelect?: (option: NewEvalRunDatasetSelectionOption) => void;
  onBackButtonPressed: () => void;
}

const DatasetSelectionFilters = ({
  title,
  onBackButtonPressed,
  onOptionSelect,
}: IDatasetSelectionFiltersProps) => {
  const [activeOption, setActiveOption] = useState(NewEvalRunDatasetSelectionOption.ALL_FILES);

  const onOptionChange = (option: NewEvalRunDatasetSelectionOption) => {
    setActiveOption(option);
    if (onOptionSelect) onOptionSelect(option);
  };

  const renderRadioSelection = ({
    label,
    key,
    active = false,
    onClick,
  }: {
    label: string;
    key: NewEvalRunDatasetSelectionOption;
    active: boolean;
    onClick: (key: NewEvalRunDatasetSelectionOption) => void;
  }) => {
    return (
      <div
        className={`${styles.radioSelectionCard} ${active ? styles.radioSelectionCard_active : ''}`}
        key={key}
        onKeyDown={() => onClick(key)}
        onClick={() => onClick(key)}
        role="button"
        tabIndex={0}
      >
        <div className={styles.radioSelectionCard_radioWrapper}>
          <Radio checked={active} />
        </div>
        <div className={styles.radioSelectionCard_labelRadio}>
          <h6>{label}</h6>
        </div>
      </div>
    );
  };

  const renderFilterSelectionDropwdown = () => {
    return (
      <Dropdown
        className={styles.filterSelection}
        menu={{
          items: metadataFieldsOptions,
          onClick: () => {},
        }}
        trigger={['click']}
      >
        <Button
          className={styles.filterSelection_button}
          type="link"
          icon={<PlusOutlined />}
          onClick={(e) => e.preventDefault()}
        >
          {ADD_NEW_FILTER_BUTTON_LABEL}
        </Button>
      </Dropdown>
    );
  };

  return (
    <div className={styles.datasetSelectionFilters}>
      <div className={styles.datasetSelectionFilters_header}>
        <h4 className={styles.datasetSelectionFilters_header_title}>
          <Button icon={<ArrowLeftOutlined />} type="text" onClick={onBackButtonPressed} />
          {title}
        </h4>
        <div className={styles.datasetSelectionFilters_header_subTitle}>
          {DATASET_SECTION_DESCRIPTION}
        </div>
      </div>
      <div className={styles.datasetSelectionFilters_optionsSelection}>
        {NEW_EVAL_RUN_DATASET_SELECTION_ITEMS.map(({ label, key }) => {
          return renderRadioSelection({
            label,
            key,
            active: activeOption === key,
            onClick: onOptionChange,
          });
        })}
      </div>
      {activeOption === NewEvalRunDatasetSelectionOption.SUBSET && (
        <div className={styles.datasetSelectionFilters_filterSelection}>
          {renderFilterSelectionDropwdown()}
        </div>
      )}
    </div>
  );
};

export default DatasetSelectionFilters;
