import { getCurrentWorkspaceId } from '@utils/store';
import { FeedbackType, IAPIPaginationData } from '@redux/types/types';
import { http } from './base-http';

export interface IFeedbackBody {
  score?: FeedbackType | null;
  comment?: string;
  tags?: string[];
  bookmarked?: boolean;
}

export type IUpdateFeedbackBody = Omit<IFeedbackBody, 'search_history'>;

export interface IFeedbackData {
  limit?: number;
  page_number?: number;
  after?: string;
  field?: string;
  order?: string;
  filter?: string;
  select?: string;
}

export interface IFeedbackTagBody {
  name: string;
}

export const createFeedbackApi = (
  pipelineId: string,
  payload: IFeedbackBody,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const body = {
    ...payload,
    comment: payload.comment || '',
  };
  return http.post(`/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/feedback`, body);
};

export const updateFeedbackApi = (
  pipelineId: string,
  feedbackId: string,
  payload: IUpdateFeedbackBody,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.patch(
    `/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/feedback/${feedbackId}`,
    payload,
  );
};

export const getFeedbackDataApi = <T>(
  pipelineId: string,
  params: IFeedbackData,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const parameterMap = {
    limit: params?.limit || 10,
    ...(params?.after && { after: params.after }),
    ...(params?.page_number && { page_number: params.page_number }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
    ...(params?.filter && { filter: params.filter }),
    ...(params?.select && { select: params.select }),
  };
  return http.get<T>(`/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/feedback`, {
    params: parameterMap,
  });
};

export const getRecursivelyFeedbackDataApi = async <T extends unknown[]>(
  pipelineId: string,
  params: IFeedbackData,
  workspaceId: string = getCurrentWorkspaceId(),
): Promise<T | unknown[]> => {
  const {
    data: { data, has_more: hasMore },
  } = await getFeedbackDataApi<IAPIPaginationData<T>>(pipelineId, params, workspaceId);

  if (!hasMore) return data;

  const nextPage = params?.page_number ? params.page_number + 1 : 2;
  const nextParams = { ...params, page_number: nextPage };

  return [...data, ...(await getRecursivelyFeedbackDataApi(pipelineId, nextParams, workspaceId))];
};

export const getPipelineFeedbackDataCSVApi = (
  pipelineId: string,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/feedback/export`, {
    responseType: 'blob',
  });
};

export const getPipelineFeedbackTagsApi = (
  pipelineId: string,
  params: IFeedbackData,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const parameterMap = {
    limit: params?.limit || 50,
    ...(params?.after && { after: params.after }),
    ...(params?.page_number && { page_number: params.page_number }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
    ...(params?.filter && { filter: params.filter }),
    ...(params?.select && { select: params.select }),
  };
  return http.get(`/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/tags`, {
    params: parameterMap,
  });
};

export const createPipelineFeedbackTagApi = (
  pipelineId: string,
  payload: IFeedbackTagBody,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.post(`/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/tags`, payload);
};

export const deletePipelineFeedbackTagApi = (
  pipelineId: string,
  tagId: string,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.delete(`/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/tags/${tagId}`);
};
