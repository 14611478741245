import React from 'react';
import CodeEditor from './features/CodeEditor/CodeEditor';
import PipelineEditorHeader from './features/PipelineHeader/PipelineHeader';
import styles from './pipelineEditorV2.module.scss';

function PipelineEditor() {
  return (
    <div className={styles.container}>
      <PipelineEditorHeader />
      <CodeEditor />
    </div>
  );
}

export default PipelineEditor;
