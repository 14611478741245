import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Tag, theme } from 'antd';
import { formatDecimalPlaces, formatNumberToLocaleString } from '@utils/math';
import { interpolateString } from '@utils/string';
import { SWITCHED_WORKSPACE_ALERT } from '@constants/common';
import { FilterProp, FiltersProp, FilterType } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import {
  PIPELINE_USAGE_CYCLE_HEADER,
  PIPELINE_USAGE_METRICS_FILTERS_FIELD_KEYS,
  PIPELINE_USAGE_METRICS_FILTERS_SERVICE_LEVEL_LABEL,
  PIPELINE_USAGE_METRICS_SERVICE_LEVEL_FILTERS_LABELS,
  PIPELINE_USAGE_METRICS_SORTING_OPTIONS,
  PIPELINE_USAGE_SERVICE_LEVEL_TAGS_LABELS,
  PIPELINES_TABLE_COLUMNS_TITLE,
  PIPELINES_TABLE_SEARCH_PLACEHOLDER,
} from '@constants/metering';
import { addNotification } from '@redux/actions/notificationActions';
import { setWorkspace } from '@redux/actions/organizationActions';
import { IWorkspaceItem } from '@redux/reducers/organizationReducer';
import { workspaceSelector } from '@redux/selectors/organizationSelectors';
import { IPipelineUsage, PipelineUsageServiceLevel } from '@redux/types/types';
import DataTable from '@components/dataTable/DataTable';
import useUsageDateRange from '@pages/metering/hooks/useUsageDateRange';
import styles from './pipelinesUsage.module.scss';
import usePipelinesUsage from '../../hooks/usePipelinesUsage';

const { useToken } = theme;

const PAGE_SIZE = 5;

const PipelinesUsage = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    pipelineUsageMetrics,
    pipelineUsageMetricsSortValue,
    getPipelineUsageMetrics,
    fetchingPipelineUsageMetricsStatus,
  } = usePipelinesUsage();
  const { currentUsageCycleText } = useUsageDateRange();
  const { currentWorkspace, workspaces } = useSelector(workspaceSelector);

  const getPipelineUsageFilters = (): FiltersProp<FilterType.SELECT> => {
    const serviceLevelFilter = {
      type: FilterType.SELECT,
      search: false,
      key: PIPELINE_USAGE_METRICS_FILTERS_FIELD_KEYS.SERVICE_LEVEL,
      title: PIPELINE_USAGE_METRICS_FILTERS_SERVICE_LEVEL_LABEL,
      options: [
        {
          key: 'service_level_draft',
          label: PIPELINE_USAGE_METRICS_SERVICE_LEVEL_FILTERS_LABELS.DRAFT,
          value: PipelineUsageServiceLevel.DRAFT,
        },
        {
          key: 'service_level_development',
          label: PIPELINE_USAGE_METRICS_SERVICE_LEVEL_FILTERS_LABELS.DEVELOPMENT,
          value: PipelineUsageServiceLevel.DEVELOPMENT,
        },
        {
          key: 'service_level_production',
          label: PIPELINE_USAGE_METRICS_SERVICE_LEVEL_FILTERS_LABELS.PRODUCTION,
          value: PipelineUsageServiceLevel.PRODUCTION,
        },
      ],
      style: { minWidth: '140px' },
    } as FilterProp<FilterType.SELECT>;

    return [serviceLevelFilter];
  };

  const onPipelineNameClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    rowItem: IPipelineUsage,
  ) => {
    event.preventDefault();

    const { workspace_name: workspaceName, pipeline_name: pipelineName } = rowItem;
    if (currentWorkspace !== workspaceName) {
      const workspaceToChange = workspaces.find(
        (workspace: IWorkspaceItem) => workspace.name === workspaceName,
      );
      if (!workspaceToChange) return;
      dispatch(setWorkspace(workspaceToChange.name));
      dispatch(
        addNotification({
          content: interpolateString(SWITCHED_WORKSPACE_ALERT, {
            workspace: workspaceToChange.name,
          }) as string,
        }),
      );
    }

    navigate(`/pipelines/${pipelineName}`);
  };

  // TODO: We might need to move to it's own component
  // to reuse it in the pipeline page
  const renderPipelineEnv = (env: PipelineUsageServiceLevel) => {
    const mappedEnvElements = {
      [PipelineUsageServiceLevel.PRODUCTION]: {
        label: PIPELINE_USAGE_SERVICE_LEVEL_TAGS_LABELS.production,
        color: token.colorSuccess,
      },
      [PipelineUsageServiceLevel.DEVELOPMENT]: {
        label: PIPELINE_USAGE_SERVICE_LEVEL_TAGS_LABELS.development,
        color: token.colorPrimary,
      },
      [PipelineUsageServiceLevel.DRAFT]: {
        label: PIPELINE_USAGE_SERVICE_LEVEL_TAGS_LABELS.draft,
        color: token.colorTextDisabled,
      },
    };

    if (!env || !mappedEnvElements[env]) return null;

    return (
      <div className={styles.envTag}>
        <Badge color={mappedEnvElements[env].color} />
        <span>{mappedEnvElements[env].label}</span>
      </div>
    );
  };

  const renderHoursValue = (hours: number) => `${formatDecimalPlaces(hours)}h`;

  const columns = [
    {
      title: PIPELINES_TABLE_COLUMNS_TITLE.NAME,
      dataIndex: 'pipeline_name',
      key: 'pipeline_name',
      width: '40%',
      render: (name: string, rowItem: IPipelineUsage) => (
        <Link
          to={`/pipelines/${name}`}
          onClick={(event) => onPipelineNameClick(event, rowItem)}
          className={styles.pipelineNameLink}
        >
          {name}
        </Link>
      ),
    },
    {
      title: PIPELINES_TABLE_COLUMNS_TITLE.DEV_HOURS,
      dataIndex: 'development_hours',
      key: 'development_hours',
      width: '10%',
      render: (hours: number) => renderHoursValue(hours),
    },
    {
      title: PIPELINES_TABLE_COLUMNS_TITLE.PROD_HOURS,
      dataIndex: 'production_hours',
      key: 'production_hours',
      width: '10%',
      render: (hours: number) => renderHoursValue(hours),
    },
    {
      title: PIPELINES_TABLE_COLUMNS_TITLE.STORAGE_UNITS,
      dataIndex: 'document_storage_units',
      key: 'document_storage_units',
      width: '10%',
      render: (number: number) => formatNumberToLocaleString(number, true),
    },
    {
      title: PIPELINES_TABLE_COLUMNS_TITLE.DOCUMENTS,
      dataIndex: 'documents',
      key: 'documents',
      width: '10%',
      render: (number: number) => formatNumberToLocaleString(number, true),
    },
    {
      title: PIPELINES_TABLE_COLUMNS_TITLE.WORKSPACE_NAME,
      dataIndex: 'workspace_name',
      key: 'workspace_name',
      width: '10%',
    },
    {
      title: PIPELINES_TABLE_COLUMNS_TITLE.SERVICE_LEVEL,
      dataIndex: 'service_level',
      key: 'service_level',
      width: '10%',
      render: (env: PipelineUsageServiceLevel) => renderPipelineEnv(env),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h5>{PIPELINE_USAGE_CYCLE_HEADER}</h5>
        {currentUsageCycleText && <Tag>{currentUsageCycleText}</Tag>}
      </div>
      <DataTable
        data={pipelineUsageMetrics.data}
        total={pipelineUsageMetrics.total}
        rowKey="pipeline_id"
        rowDisabled={(rowItem: Record<string, unknown>) => {
          return rowItem.service_level === PipelineUsageServiceLevel.DRAFT;
        }}
        getData={(currentPage, pageSize, searchValue, sortValue, filterValues) =>
          getPipelineUsageMetrics({
            pageNumber: currentPage,
            limit: pageSize,
            searchValue,
            sortValue,
            filterValues,
          })
        }
        sorting={{
          selectedValue: pipelineUsageMetricsSortValue,
          options: PIPELINE_USAGE_METRICS_SORTING_OPTIONS,
        }}
        loading={fetchingPipelineUsageMetricsStatus === StatusCodes.IN_PROGRESS}
        filters={getPipelineUsageFilters()}
        columns={columns}
        searchAvailable
        searchPlaceholder={PIPELINES_TABLE_SEARCH_PLACEHOLDER}
        rowSelection={false}
        pagination={{ pageSize: PAGE_SIZE }}
      />
    </div>
  );
};

export default PipelinesUsage;
