import { getWorkspace } from '@utils/store';
import { http } from './base-http';

export interface ITagsData {
  filter?: string;
}

/* Get all tags in workspace */
export function getTagsApi(params: ITagsData = {}, workspaceName: string = getWorkspace()) {
  return http.get(`/api/v1/workspaces/${workspaceName}/tags`, { params });
}

/* Create new tag in workspace */
export function createTagApi(name: string, workspaceName: string = getWorkspace()) {
  return http.post(`/api/v1/workspaces/${workspaceName}/tags`, name);
}

/* Delete a tag in workspace */
export function deleteTagApi(name: string, workspaceName: string = getWorkspace()) {
  return http.delete(`/api/v1/workspaces/${workspaceName}/tags/${name}`);
}
