import { useDispatch } from 'react-redux';
import { matchRoutes, useLocation, useParams } from 'react-router-dom';
import { ROUTES_CONFIGURATION } from '@constants/routes';
import {
  resetRouteName as resetRouteNameAction,
  updateRouteName,
} from '@redux/actions/layoutActions';

export const useCurrentRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const routeParams = useParams();
  const fallbackRoute = [{ route: ROUTES_CONFIGURATION[0] }];
  const [{ route }] = matchRoutes(ROUTES_CONFIGURATION, location) || fallbackRoute;

  const setRouteName = (name: string) => {
    dispatch(updateRouteName(name));
  };
  const resetRouteName = () => {
    dispatch(resetRouteNameAction());
  };

  const searchParams: Record<string, string> = {};
  new URLSearchParams(location.search).forEach((value, key) => {
    searchParams[key] = value;
  });

  return { ...route, location, searchParams, routeParams, setRouteName, resetRouteName };
};
