import React from 'react';
import useSearchFileActions from '@hooks/useSearchFileActions';
import { fileDropdownMenuItems } from '@constants/search';
import { IBaseSearchResultComponentProps, ISearchResultAnswer } from '@redux/types/types';
import SearchResultSingleFileSource from '@components/search/molecules/files/SearchResultSingleFileSource';
import FormattedResultText from '@components/search/molecules/formattedResultText/FormattedResultText';
import SearchDocumentLink from '@components/search/molecules/searchDocumentLink/SearchDocumentLink';
import SearchResultScore from '@components/search/molecules/searchResultScore/SearchResultScore';
import styles from './resultContainer.module.scss';
import SearchResultActions from '../resultActions/SearchResultActions';

interface IExtractiveQAResultProps extends IBaseSearchResultComponentProps<ISearchResultAnswer> {
  searching: boolean;
  isExternal?: boolean;
}

const ExtractiveQAResult = ({
  result,
  queryId,
  pipelineId,
  pipelineName,
  displayRelevanceScore,
  displayFeedbackOptions,
  displayFileSources,
  displayFileOptions,
  searching,
  isExternal,
}: IExtractiveQAResultProps) => {
  const {
    result_id: resultId,
    context,
    answer,
    files,
    offsets_in_context: offsetsInContext,
    meta,
    score,
  } = result;
  const [firstFile] = files;
  const { onFileDropdownItemClick, onDocumentPreview, isFileActionTakingPlace } =
    useSearchFileActions({
      resultId,
      isExternal,
    });

  const renderResultBody = () => {
    return (
      <div className={styles.result_body} data-testid="searchResult_content">
        <FormattedResultText
          text={context || ''}
          ellipses
          highlight={{
            text: answer as string,
            offset: offsetsInContext[0] as { start: number; end: number },
            testId: 'searchResult_content_answer',
          }}
        />
        {displayFileSources && (
          <SearchDocumentLink
            file={firstFile}
            onViewDocument={() => onDocumentPreview(firstFile.id, firstFile.name)}
          />
        )}
        {displayRelevanceScore && <SearchResultScore score={score} />}
      </div>
    );
  };

  const renderFeedbackButtons = () => {
    return (
      <div className={styles.result_actions}>
        <SearchResultActions
          resultId={resultId}
          queryId={queryId}
          pipelineId={pipelineId}
          pipelineName={pipelineName}
          isExternal={isExternal}
          copytext={context || ''}
        />
      </div>
    );
  };

  return (
    <div className={styles.result}>
      {renderResultBody()}
      {displayFeedbackOptions && renderFeedbackButtons()}
      {displayFileSources && (
        <SearchResultSingleFileSource
          resultId={resultId}
          file={firstFile}
          meta={meta}
          searching={searching}
          isFileActionTakingPlace={isFileActionTakingPlace}
          onFileDropdownItemClick={onFileDropdownItemClick}
          fileDropdownMenuItems={fileDropdownMenuItems}
          displayFileOptions={displayFileOptions}
        />
      )}
    </div>
  );
};

export default ExtractiveQAResult;
