/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { interpolateString } from '@utils/string';
import { useUploadData } from '@hooks/useUploadData';
import { IN_PROGRESS_UPLOAD_HEADER, UPLOAD_COMPLETED_HEADER } from '@constants/upload';
import { resetUploadData } from '@redux/actions/uploadActions';
import { FileUploadStatus } from '@redux/types/types';
import FileUploadAlert from '@components/uploadData/fileUploadAlerts/FileUploadAlert';
import FileUploadList from '@components/uploadData/fileUploadList/FileUploadList';
import styles from './uploadPreview.module.scss';

const RENDER_LIMIT = 50;

const UploadPreviewModal = () => {
  const dispatch = useDispatch();
  const {
    uploadFiles,
    setUploadFiles,
    uploadFilesData,
    failedFilesToRetry,
    singleFileName,
    isRetrying,
    retrySingleFile,
    retryFailedFiles,
    isUploadingCompleted,
    isUploadingStarted,
  } = useUploadData();
  const [listLength, setListLength] = useState(RENDER_LIMIT);

  useEffect(() => {
    dispatch(resetUploadData);
    setUploadFiles([]);
  }, []);

  useEffect(() => {
    if (!uploadFiles?.length) return;

    uploadFilesData();
  }, [uploadFiles.length]);

  const handleCancel = () => {
    dispatch(resetUploadData);
    setUploadFiles([]);
  };

  const renderHeader = () => {
    if (isUploadingCompleted && !isRetrying) return UPLOAD_COMPLETED_HEADER;

    const files = !isRetrying ? uploadFiles : failedFilesToRetry;
    const completedFiles = files.filter(
      (file) => file.uploadStatus === FileUploadStatus.success,
    ).length;

    return interpolateString(IN_PROGRESS_UPLOAD_HEADER, {
      completedFiles,
      inProgressFiles: files.length,
    });
  };

  if (!isUploadingStarted) return null;

  return (
    <div className={styles.uploadPreview} data-testid="uploadPreview">
      <div className={styles.uploadPreview_header}>
        {renderHeader()}
        <Button icon={<CloseOutlined />} size="small" type="text" onClick={() => handleCancel()} />
      </div>
      <FileUploadAlert
        filesList={uploadFiles}
        isUploadingCompleted={isUploadingCompleted}
        onRetryFailedFiles={retryFailedFiles}
        isRetrying={isRetrying}
        fileName={singleFileName}
      />
      <div className={styles.uploadPreview_content}>
        <FileUploadList
          filesList={uploadFiles}
          listLength={listLength}
          onLoadMore={() => {
            setListLength(listLength + RENDER_LIMIT);
          }}
          onRetrySingleFile={retrySingleFile}
          isUploadingCompleted={isUploadingCompleted}
        />
      </div>
    </div>
  );
};

export default UploadPreviewModal;
