import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { auth } from '@utils/auth';
import { IUserEventData, sendUserEventApi } from '@api/event';
import { sendUserEventExternalApi } from '@api/external/event';
import { workspaceSelector } from '@redux/selectors/organizationSelectors';
import { useCurrentRoute } from './useCurrentRoute';

export const useUserEvent = (initialEventProperties?: IUserEventData['properties']) => {
  const eventProperties = useRef<IUserEventData['properties']>(initialEventProperties || {});

  const { path } = useCurrentRoute();
  const { currentWorkspace, currentWorkspaceId } = useSelector(workspaceSelector);

  const defaultEventProperties = {
    path,
    workspace_name: currentWorkspace,
    workspace_id: currentWorkspaceId,
  };

  const isExternalUser = async () => {
    try {
      const token = auth.getAccessTokenSilently({});
      return !token;
    } catch (err) {
      return true;
    }
  };

  const trackUserEvent = async (params: IUserEventData) => {
    const apiCall = (await isExternalUser()) ? sendUserEventExternalApi : sendUserEventApi;
    apiCall({
      ...params,
      properties: {
        ...defaultEventProperties,
        ...eventProperties.current,
        ...params.properties,
      },
    });
  };

  const setEventProperties = (eventProps: IUserEventData['properties']) => {
    eventProperties.current = {
      ...defaultEventProperties,
      ...eventProps,
    };
  };

  const appendEventProperties = (eventProps: IUserEventData['properties']) => {
    eventProperties.current = {
      ...defaultEventProperties,
      ...eventProperties.current,
      ...eventProps,
    };
  };

  return { trackUserEvent, setEventProperties, appendEventProperties };
};
