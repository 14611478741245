import React, { useState } from 'react';
import { Divider, MenuProps } from 'antd';
import { StatusCodes } from '@constants/enum/common';
import { PipelineType } from '@constants/pipelines';
import { ISearchResultAnswer, ISearchResultDocument } from '@redux/types/types';
import DocumentResult, { IExposedDocumentResultProps } from './DocumentResult';
import ExtractiveQAResult from './ExtractiveQAResult';
import GenerativeResult from './GenerativeResult';
import NoAnswerResult from './NoAnswerResult';
import styles from './resultContainer.module.scss';

interface IResultContainerProps {
  isExternal?: boolean;
  searchResult: ISearchResultAnswer | ISearchResultDocument;
  documentsGroupedByFileId?: Record<string, ISearchResultDocument[]>;
  documents?: ISearchResultDocument[];
  queryId: string;
  pipelineId: string;
  pipelineName: string;
  pipelineType: PipelineType;
  searchStatus: StatusCodes;
  moreOptionsDropdownMenuItems?: MenuProps['items'];
  displayFeedbackOptions?: boolean;
  displayFileOptions?: boolean;
  displayFileSources?: boolean;
  displayMoreOptions?: boolean;
  displayRelevanceScore?: boolean;
  displayMetadata?: boolean;
  displayReferencesPopover?: boolean;
  documentResultOptions?: IExposedDocumentResultProps;
  sourcesDefaultExpanded?: boolean;
  withGenerativeTypingEffect?: boolean;
  feedback?: (() => React.ReactElement | null) | null;
  onMoreOptionsDropdownMenuItemClick?: (info: { key: string }) => void;
}

const ResultContainer = (props: IResultContainerProps) => {
  const {
    isExternal,
    searchResult,
    documentsGroupedByFileId,
    documents,
    searchStatus,
    moreOptionsDropdownMenuItems,
    displayFeedbackOptions = true,
    displayFileOptions = true,
    displayFileSources = true,
    displayMoreOptions = true,
    displayRelevanceScore = true,
    displayMetadata = false,
    displayReferencesPopover = true,
    withGenerativeTypingEffect,
    documentResultOptions,
    queryId,
    pipelineType,
    pipelineId,
    pipelineName,
    sourcesDefaultExpanded,
    feedback,
    onMoreOptionsDropdownMenuItemClick,
  } = props;
  const [sourcesCollapsed, setSourcesCollapsed] = useState(
    sourcesDefaultExpanded && pipelineType === PipelineType.GENERATIVE_QUESTION_ANSWERING,
  );
  const searching = searchStatus === StatusCodes.IN_PROGRESS;

  const noAnswer =
    pipelineType !== PipelineType.DOCUMENT_RETRIEVAL &&
    !(searchResult as ISearchResultAnswer).answer;

  const renderResultByPipelineType = () => {
    const commonProps = {
      displayRelevanceScore,
      displayFeedbackOptions,
      displayFileSources,
      displayFileOptions,
      displayMetadata,
      searching,
      feedback,
      isExternal,
      queryId,
      pipelineId,
      pipelineName,
    };

    switch (pipelineType) {
      case PipelineType.DOCUMENT_RETRIEVAL:
        return (
          <DocumentResult
            result={searchResult as ISearchResultDocument}
            {...commonProps}
            {...documentResultOptions}
          />
        );
      case PipelineType.EXTRACTIVE_QUESTION_ANSWERING:
        return <ExtractiveQAResult result={searchResult as ISearchResultAnswer} {...commonProps} />;
      case PipelineType.GENERATIVE_QUESTION_ANSWERING:
        return (
          <GenerativeResult
            result={searchResult as ISearchResultAnswer}
            {...commonProps}
            withTypingEffect={withGenerativeTypingEffect}
            displayMoreOptions={displayMoreOptions}
            displayFileOptions={displayFileOptions}
            displayReferencesPopover={displayReferencesPopover}
            documentsGroupedByFileId={documentsGroupedByFileId}
            documents={documents}
            moreOptionsDropdownMenuItems={moreOptionsDropdownMenuItems}
            onMoreOptionsDropdownMenuItemClick={onMoreOptionsDropdownMenuItemClick ?? (() => {})}
            sourcesCollapsed={sourcesCollapsed}
            setSourcesCollapsed={setSourcesCollapsed}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!noAnswer ? (
        renderResultByPipelineType()
      ) : (
        <NoAnswerResult
          result={searchResult as ISearchResultAnswer}
          queryId={queryId}
          pipelineId={pipelineId}
          pipelineName={pipelineName}
          displayFeedbackOptions={displayFeedbackOptions}
          displayRelevanceScore={displayRelevanceScore}
          isExternal={isExternal}
        />
      )}
      <Divider className={styles.result_divider} />
    </>
  );
};

export default ResultContainer;
