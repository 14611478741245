import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const notificationStoreSelector = (state: RootState) => state.notificationStore;

export const notificationsSelector = createSelector(
  notificationStoreSelector,
  (notificationStore) => notificationStore.notifications,
);

export const sequentialNotificationsSelector = createSelector(
  notificationStoreSelector,
  (notificationStore) => notificationStore.sequentialNotifications,
);
