import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSampleDatasets,
  getWorkspaceFiles,
  importSampleDataset,
} from '@redux/actions/fileActions';
import { sampleDataSelector } from '@redux/selectors/fileSelectors';

const useSampleFile = () => {
  const dispatch = useDispatch();
  const datasetList = useSelector(sampleDataSelector);

  useEffect(() => {
    dispatch(getSampleDatasets());
  }, []);

  const importDataset = async (category: string) => {
    await dispatch(importSampleDataset(category));
    await dispatch(getWorkspaceFiles({ currentPage: 1, pageSize: 10, searchValue: '' }));
    return;
  };

  return { datasetList, importDataset };
};

export default useSampleFile;
