/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import DOMPurify from 'dompurify';
import Quill from 'quill';
import styles from './textEditor.module.scss';
import 'quill/dist/quill.snow.css';

interface ITextEditorProps {
  disabled?: boolean;
  onTextChangeByUser?: () => void;
  resizable?: boolean;
}

interface ITextEditorExposedMethods {
  getHTMLContent: () => string;
  setHTMLContent: (content: string) => void;
}

const TextEditor = forwardRef<ITextEditorExposedMethods, ITextEditorProps>(
  ({ disabled = false, onTextChangeByUser, resizable = true }: ITextEditorProps, ref) => {
    const editor = useRef<Quill>();
    const descriptionEl = useRef<HTMLDivElement>(null);

    const isEmpty = () => {
      if ((editor.current?.getContents().ops || []).length !== 1) return false;
      return editor.current?.getText().trim().length === 0;
    };

    useImperativeHandle(
      ref,
      () => ({
        getHTMLContent() {
          if (isEmpty()) return '';
          return editor.current!.root.innerHTML;
        },
        setHTMLContent(content: string) {
          const sanitizedContent = DOMPurify.sanitize(content);
          editor.current!.clipboard.dangerouslyPasteHTML(sanitizedContent);
        },
      }),
      [],
    );

    useEffect(() => {
      if (editor.current) return;

      editor.current = new Quill(descriptionEl.current!, {
        modules: {
          toolbar: true,
        },
        theme: 'snow',
        bounds: descriptionEl.current!,
      });
    }, []);

    useEffect(() => {
      if (!onTextChangeByUser || !editor.current) return () => {};

      editor.current.on('text-change', (_, __, source) => {
        if (onTextChangeByUser && source === 'user') onTextChangeByUser();
      });

      return () => {
        editor.current!.off('text-change', onTextChangeByUser);
      };
    }, [onTextChangeByUser]);

    useEffect(() => {
      if (!editor.current) return;

      editor.current!.enable(!disabled);
    }, [disabled]);

    return (
      <div
        className={`${styles.textEditor} ${resizable ? styles.textEditorResizable : ''}`}
        ref={descriptionEl}
      />
    );
  },
);

export default TextEditor;
