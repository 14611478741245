import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getPipelineUsageHours } from '@redux/actions/usageActions';
import { pipelineHoursUsageSelector } from '@redux/selectors/usageSelectors';
import useUsageDateRange from './useUsageDateRange';

const usePipelineHours = () => {
  const dispatch = useDispatch();
  const { selectedDateRange } = useUsageDateRange();
  const { devHours, prodHours } = useSelector(pipelineHoursUsageSelector);

  useEffect(() => {
    const dateRangeValues = {
      from: selectedDateRange?.from,
      to: dayjs(),
    };
    dispatch(
      getPipelineUsageHours({ dateRange: dateRangeValues, interval: selectedDateRange?.interval }),
    );
  }, []);

  return { devHours, prodHours };
};

export default usePipelineHours;
