import { useEffect, useState } from 'react';
import { CacheKey, getFeatureTourVisit, setFeatureTourVisit } from '@utils/userPreferences';

const useFeatureTour = (cacheKey: CacheKey) => {
  const localStorageTourState = getFeatureTourVisit(cacheKey);
  const [tourDismissed, setTourDismissed] = useState<boolean>(localStorageTourState ?? false);

  useEffect(() => {
    setFeatureTourVisit(cacheKey, tourDismissed);
  }, [tourDismissed]);

  const handleTourDismissed = (): void => setTourDismissed(true);

  return {
    tourDismissed,
    setTourDismissed: handleTourDismissed,
  };
};

export default useFeatureTour;
