import React, { ReactNode, useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useDeviceDetection } from '@hooks/useDeviceDetection';
import { CANCEL_BUTTON_LABEL } from '@constants/common';
import { SEARCH_BUTTON, SEARCHBAR_PLACEHOLDER } from '@constants/search';
import styles from './searchQueryInput.module.scss';

const { TextArea } = Input;

interface ISearchQueryInputProps {
  query?: string;
  loading?: boolean;
  disabled?: boolean;
  onQuerySubmit: (query: string) => void;
  enterButton?: ReactNode | string;
  activatingPipeline?: boolean;
}

const SearchQueryInput = ({
  query,
  loading = false,
  disabled = false,
  enterButton,
  onQuerySubmit,
}: ISearchQueryInputProps) => {
  const { isDesktop } = useDeviceDetection();

  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    if (query) setSearchQuery(query);
  }, [query]);

  const onSearch = () => {
    if (searchQuery) onQuerySubmit(searchQuery);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.metaKey && !event.ctrlKey && !event.shiftKey) {
      event.preventDefault();
      onSearch();
    }
  };

  return (
    <div className={styles.searchQueryContainer}>
      <TextArea
        className={`${!isDesktop ? styles.tabletMobileInput : ''}`}
        placeholder={SEARCHBAR_PLACEHOLDER}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        size="large"
        disabled={disabled}
        allowClear={isDesktop}
        autoFocus
        data-testid="searchBar_input"
        autoSize={{ maxRows: 2 }}
        onKeyDown={onKeyDown}
      />
      <Button
        type="primary"
        size="large"
        onClick={onSearch}
        data-testid="searchBar_button"
        disabled={disabled || !searchQuery}
        loading={loading}
        icon={<SearchOutlined />}
      >
        {enterButton || SEARCH_BUTTON}
      </Button>
      {!isDesktop && searchQuery && (
        <Button className={styles.cancelButton} type="link" onClick={() => setSearchQuery('')}>
          {CANCEL_BUTTON_LABEL}
        </Button>
      )}
    </div>
  );
};

export default SearchQueryInput;
