import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEV_HOURS, EXPORT_CHART_OPTIONS, PIPELINE_HOURS, PROD_HOURS } from '@constants/metering';
import { exportChart } from '@redux/actions/observabilityActions';
import { layoutSidebarSelector } from '@redux/selectors/layoutSelectors';
import { ChartExportType } from '@redux/types/types';
import LineChart from '@components/echarts/lineChart';
import ObservabilityVizCard from '@components/observabilityVizCard/ObservabilityVizCard';
import usePipelineHours from '@pages/metering/hooks/usePipelineHours';
import defaultTheme from '@styles/themes/default';
import styles from './pipelinesUsageGraph.module.scss';

const PipelinesUsageGraph = () => {
  const dispatch = useDispatch();
  const { devHours, prodHours } = usePipelineHours();
  const chartRef = useRef<React.ElementRef<typeof LineChart>>(null);
  const { collapsed } = useSelector(layoutSidebarSelector);

  const resizeChart = () => {
    chartRef?.current?.resize();
  };

  useEffect(() => {
    setTimeout(() => {
      resizeChart();
    }, 250); // The transition time of the sidebar is 200ms
  }, [collapsed]);

  const handleExportChart = (key: ChartExportType) => {
    if (chartRef?.current)
      dispatch(
        exportChart({
          chartObj: chartRef.current as any,
          mode: key,
          extraInfo: { name: PIPELINE_HOURS },
        }),
      );
  };

  const renderChart = () => {
    return (
      <div className={styles.container}>
        <LineChart
          ref={chartRef}
          series={[
            { label: DEV_HOURS, data: devHours },
            { label: PROD_HOURS, data: prodHours, color: defaultTheme.token?.colorSuccess },
          ]}
          withArea={false}
        />
      </div>
    );
  };

  return (
    <ObservabilityVizCard
      headerLabel={PIPELINE_HOURS}
      onResize={resizeChart}
      exportOptions={EXPORT_CHART_OPTIONS}
      onExport={handleExportChart}
    >
      {renderChart()}
    </ObservabilityVizCard>
  );
};

export default PipelinesUsageGraph;
