import { useDispatch, useSelector } from 'react-redux';
import { Dayjs } from 'dayjs';
import { SelectedFilters } from '@constants/data-table';
import { getPipelineUsageMetrics as getPipelineUsageMetricsAction } from '@redux/actions/usageActions';
import {
  fetchingPipelineUsageMetricsStatusSelector,
  pipelineUsageMetricsFiltersValuesSelector,
  pipelineUsageMetricsSelector,
  pipelineUsageMetricsSortValueSelector,
} from '@redux/selectors/usageSelectors';
import useUsageDateRange from './useUsageDateRange';

const usePipelinesUsage = () => {
  const dispatch = useDispatch();
  const { selectedDateRange } = useUsageDateRange();
  const pipelineUsageMetrics = useSelector(pipelineUsageMetricsSelector);
  const fetchingPipelineUsageMetricsStatus = useSelector(
    fetchingPipelineUsageMetricsStatusSelector,
  );
  const pipelineUsageMetricsSortValue = useSelector(pipelineUsageMetricsSortValueSelector);
  const pipelineUsageMetricsFiltersValues = useSelector(pipelineUsageMetricsFiltersValuesSelector);

  const getPipelineUsageMetrics = ({
    limit,
    pageNumber,
    dateRange,
    searchValue,
    sortValue,
    filterValues,
  }: {
    limit?: number;
    pageNumber?: number;
    dateRange?: { from?: Dayjs | null; to?: Dayjs | null };
    searchValue?: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
  } = {}) => {
    const dateRangeValues = dateRange || {
      from: selectedDateRange?.from,
      to: selectedDateRange?.to,
    };
    dispatch(
      getPipelineUsageMetricsAction({
        limit,
        pageNumber,
        dateRange: dateRangeValues,
        searchValue,
        sortValue,
        filterValues,
      }),
    );
  };

  return {
    pipelineUsageMetrics,
    fetchingPipelineUsageMetricsStatus,
    pipelineUsageMetricsSortValue,
    pipelineUsageMetricsFiltersValues,
    getPipelineUsageMetrics,
  };
};

export default usePipelinesUsage;
