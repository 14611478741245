import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { Modal } from 'antd';
import {
  PREVENT_PAGE_EXIT_MODAL_CANCEL_BUTTON_LABEL,
  PREVENT_PAGE_EXIT_MODAL_OK_BUTTON_LABEL,
  PREVENT_PAGE_EXIT_MODAL_TITLE,
} from '@constants/common';

function useConfirmExit(showConfirmMessage: boolean, confirmExit: any, confirmDialog: boolean) {
  const { navigator } = useContext(NavigationContext);
  const pushArgsRef = useRef<Record<string, any>>({});

  useEffect(() => {
    if (!showConfirmMessage) {
      return;
    }

    const { push } = navigator;

    navigator.push = (...args: Parameters<typeof push>) => {
      if (!confirmDialog && showConfirmMessage) {
        confirmExit();
        pushArgsRef.current = args;
      }
      if (!showConfirmMessage) {
        push(...args);
      }
    };

    if (confirmDialog) {
      push(pushArgsRef.current[0]);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, confirmDialog, showConfirmMessage]);
}

export function usePreventPageExitPrompt(message: string, showConfirmMessage: boolean) {
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    if (showConfirmMessage) {
      // eslint-disable-next-line func-names
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, showConfirmMessage]);

  const confirmExit = useCallback(async () => {
    if (!showConfirmMessage) {
      return true;
    }

    const confirm = Modal.confirm({
      title: PREVENT_PAGE_EXIT_MODAL_TITLE,
      content: message,
      okText: PREVENT_PAGE_EXIT_MODAL_OK_BUTTON_LABEL,
      cancelText: PREVENT_PAGE_EXIT_MODAL_CANCEL_BUTTON_LABEL,
      okButtonProps: { type: 'primary', danger: true },
      styles: {
        body: { padding: 12 },
      },
      onOk() {
        Modal.destroyAll();
        setConfirmDialog(true);
      },
      onCancel() {
        Modal.destroyAll();
        setConfirmDialog(false);
      },
    });
    return confirm;
  }, [message, showConfirmMessage]);

  return useConfirmExit(showConfirmMessage, confirmExit, confirmDialog);
}
