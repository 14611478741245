import { createAsyncThunk } from '@reduxjs/toolkit';
import { interpolateString } from '@utils/string';
import { transformAllUsers } from '@utils/transformation/user';
import {
  createWorkspaceApi,
  deleteAPIKeyApi,
  deleteModelRegistryTokenApi,
  deleteUserApi,
  deleteWorkspaceApi,
  generateAPIKeyApi,
  getAPIKeysApi,
  getModelRegistryTokensApi,
  getWorkspacesApi,
  inviteUserApi,
  postModelRegistryTokenApi,
  updateUserRoleApi,
} from '@api/organization';
import { getAllUsersApi } from '@api/users';
import { PEOPLE_BLOCK } from '@constants/common';
import { UserRoles } from '@constants/enum/common';
import {
  ADD_TOKEN_NOTIFICATION_SUCCES,
  ADD_WORKSPACE_NOTIFICATION_SUCCESS,
  CHANGE_USER_ROLE_NOTIFICATION_SUCCESS,
  DELETE_API_KEY_NOTIFICATION_SUCCESS,
  DELETE_TOKEN_NOTIFICATION_SUCCESS,
  DELETE_WORKSPACE_NOTIFICATION_SUCCESS,
  INVITE_USER_NOTIFICATION_SUCCESS,
  REMOVE_USER_NOTIFICATION_SUCCESS,
} from '@constants/organization';
import { MODEL_PROVIDERS_NAMES_MAPPED } from '@constants/settings-page';
import { DEFAULT_WORKSPACE } from '@constants/workspace';
import { persistor } from '@redux/store';
import {
  ADD_WORKSPACE,
  CHANGE_USER_ROLE,
  DELETE_API_KEY,
  DELETE_MODEL_REGISTRY_TOKEN,
  DELETE_WORKSPACE,
  FETCH_API_KEYS,
  FETCH_MODEL_REGISTRY_TOKEN,
  FETCH_USERS,
  FETCH_WORKSPACES,
  GENERATE_API_KEY,
  IInviteUserData,
  INVITE_USER,
  IOrganizationData,
  ModelProviders,
  POST_MODEL_REGISTRY_TOKEN,
  REMOVE_USER,
  RESET_ORGANIZATION_MESSAGE,
  SET_CURRENT_WORKSPACE,
  SET_ORGANIZATION_INFO,
} from '@redux/types/types';
import { addErrorNotification, addSuccessNotification } from './notificationActions';

export const resetMessage = {
  type: RESET_ORGANIZATION_MESSAGE,
};

export function setOrganizationInfo(organization: IOrganizationData) {
  return {
    type: SET_ORGANIZATION_INFO,
    payload: organization,
  };
}

export const fetchUsers = createAsyncThunk(
  FETCH_USERS,
  async (currentPage: number, { dispatch, rejectWithValue }) => {
    try {
      const allUsersData = {
        page_number: currentPage,
        limit: PEOPLE_BLOCK.USERS_PER_PAGE,
      };
      const allUsersResponse = await getAllUsersApi(allUsersData);
      const allUsers = transformAllUsers(allUsersResponse);
      const totalUsers = allUsersResponse?.data?.total;
      return { users: allUsers, totalUsers };
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const inviteUser = createAsyncThunk(
  INVITE_USER,
  async (
    { user, organizationId }: { user: IInviteUserData; organizationId: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const inviteUserResponse = await inviteUserApi(user, organizationId);
      dispatch(addSuccessNotification(INVITE_USER_NOTIFICATION_SUCCESS));
      return inviteUserResponse.data;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const changeUserRole = createAsyncThunk(
  CHANGE_USER_ROLE,
  async ({ userId, role }: { userId: string; role: UserRoles }, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateUserRoleApi(userId, role);
      dispatch(addSuccessNotification(CHANGE_USER_ROLE_NOTIFICATION_SUCCESS));
      return response;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const removeUser = createAsyncThunk(
  REMOVE_USER,
  async (userId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteUserApi(userId);
      dispatch(addSuccessNotification(REMOVE_USER_NOTIFICATION_SUCCESS));
      return response;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const fetchAPIKeys = createAsyncThunk(
  FETCH_API_KEYS,
  async (
    { currentPage, pageSize }: { currentPage: number; pageSize: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const APIKeysParams = {
        page_number: currentPage,
        limit: pageSize,
      };
      const APIKeysResponse = await getAPIKeysApi(APIKeysParams);
      return APIKeysResponse.data;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const deleteAPIKey = createAsyncThunk(
  DELETE_API_KEY,
  async (apiKeyId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteAPIKeyApi(apiKeyId);
      dispatch(addSuccessNotification(DELETE_API_KEY_NOTIFICATION_SUCCESS));
      return response.data;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const generateAPIKey = createAsyncThunk(
  GENERATE_API_KEY,
  async (expiryDate: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await generateAPIKeyApi(expiryDate);
      const { api_token: apiToken } = response.data;
      return apiToken;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const fetchModelRegistryTokens = createAsyncThunk(
  FETCH_MODEL_REGISTRY_TOKEN,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getModelRegistryTokensApi();
      return response.data;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const postModelRegistryToken = createAsyncThunk(
  POST_MODEL_REGISTRY_TOKEN,
  async (
    { modelToken, modelProvider }: { modelToken: string; modelProvider: ModelProviders },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await postModelRegistryTokenApi(modelToken, modelProvider);
      dispatch(
        addSuccessNotification(
          interpolateString(
            ADD_TOKEN_NOTIFICATION_SUCCES,
            { modelProvider: MODEL_PROVIDERS_NAMES_MAPPED[modelProvider] },
            false,
          ) as string,
        ),
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteModelRegistryToken = createAsyncThunk(
  DELETE_MODEL_REGISTRY_TOKEN,
  async (modelProvider: ModelProviders, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteModelRegistryTokenApi(modelProvider);
      dispatch(
        addSuccessNotification(
          interpolateString(
            DELETE_TOKEN_NOTIFICATION_SUCCESS,
            { modelProvider: MODEL_PROVIDERS_NAMES_MAPPED[modelProvider] },
            false,
          ) as string,
        ),
      );
      return response.data;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const getWorkspaces = createAsyncThunk(
  FETCH_WORKSPACES,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getWorkspacesApi();
      return response.data;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const createWorkspace = createAsyncThunk(
  ADD_WORKSPACE,
  async (workspace: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await createWorkspaceApi(workspace);
      dispatch(addSuccessNotification(ADD_WORKSPACE_NOTIFICATION_SUCCESS));
      return response.data;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);

export const setWorkspace = (workpsace: string) => {
  persistor.purge().then(() => {
    return persistor.flush();
  });
  return {
    type: SET_CURRENT_WORKSPACE,
    payload: workpsace,
  };
};

export const deleteWorkspace = createAsyncThunk(
  DELETE_WORKSPACE,
  async (workspace: string, { dispatch, rejectWithValue }) => {
    try {
      await deleteWorkspaceApi(workspace);
      dispatch(getWorkspaces());
      dispatch(setWorkspace(DEFAULT_WORKSPACE));
      dispatch(
        addSuccessNotification(
          interpolateString(DELETE_WORKSPACE_NOTIFICATION_SUCCESS, { workspace }, false) as string,
        ),
      );
      return workspace;
    } catch (error) {
      dispatch(addErrorNotification(error));
      return rejectWithValue(error);
    }
  },
);
