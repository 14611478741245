import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import { StoreValue } from 'antd/es/form/interface';
import _get from 'lodash/get';

import { PEOPLE_BLOCK } from '@constants/common';
import { UserRoles, UserRolesMap } from '@constants/enum/common';
import { inviteUser, resetMessage } from '@redux/actions/organizationActions';
import { organizationSelector } from '@redux/selectors/organizationSelectors';
import { IInviteUserData, IOrganizationData } from '@redux/types/types';
import styles from './peopleBlock.module.scss';

export interface IInvitePeopleModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onInviteUser: () => void;
}

export interface IValues {
  users: [{ name: 0; key: 0; isListField: true }];
  skipValidation: boolean;
}

const InvitePeopleModal = (props: IInvitePeopleModalProps) => {
  const dispatch = useDispatch();
  const { visible, onClose, onSubmit, onInviteUser } = props;
  const [formFilled, setFormFilled] = useState(false);
  const { organizationName, organizationId }: IOrganizationData = useSelector(organizationSelector);
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    dispatch(resetMessage);
  }, [dispatch]);

  const initialValues: IValues = {
    users: [{ name: 0, key: 0, isListField: true }],
    skipValidation: false,
  };

  const handleOk = () => {
    onSubmit();
  };

  const handleCancel = () => {
    onClose();
  };

  const onFormSubmit = async (values: IValues) => {
    const user: IInviteUserData = {
      given_name: _get(values, 'users[0].given_name', '').trim(),
      family_name: _get(values, 'users[0].family_name', '').trim(),
      email: _get(values, 'users[0].email', '').trim(),
      role: _get(values, 'users[0].role', UserRoles.ADMIN),
    };
    await dispatch(inviteUser({ user, organizationId }));
    onInviteUser();
    form.resetFields();
    onClose();
  };

  const handleFormChanged = (_changedValues: IValues, allValues: IValues) => {
    if (
      !!_get(allValues, 'users[0].email') &&
      !!_get(allValues, 'users[0].given_name') &&
      !!_get(allValues, 'users[0].family_name') &&
      !!_get(allValues, 'users[0].role')
    ) {
      setFormFilled(true);
    } else setFormFilled(false);
  };

  const trimInput = (value: StoreValue) => {
    if (value && typeof value === 'string') return value.trim();
    return value;
  };

  const modalProps = {
    open: visible,
    closable: false,
    footer: null,
    onOk: handleOk,
    onCancel: handleCancel,
    centered: true,
  };

  const formProps = {
    form,
    initialValues,
    name: 'invite-people',
    onFinish: onFormSubmit,
    autoComplete: 'off',
    onValuesChange: handleFormChanged,
  };

  return (
    <Modal {...modalProps}>
      <h2
        className={styles.inviteModal_title}
      >{`${PEOPLE_BLOCK.INVITE_MODAL_TITLE} ${organizationName}`}</h2>
      <div className={styles.inviteModal_subtitle}>{PEOPLE_BLOCK.INVITE_MODAL_SUBTITLE}</div>
      <Form {...formProps} className={styles.inviteModal_form}>
        <Form.List name="users">
          {(fields /* { add, remove } */) => {
            return (
              <>
                {fields.map((field) => (
                  <div key={field.key} className={styles.inviteModal_field}>
                    <div className={styles.inviteModal_formItemWrapper}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'given_name']}
                        rules={[{ required: true, message: PEOPLE_BLOCK.INVITE_NAME_ERROR }]}
                        className={styles.form_name}
                      >
                        <Input placeholder={PEOPLE_BLOCK.NAME_PLACEHOLDER} size="large" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'family_name']}
                        rules={[{ required: true, message: PEOPLE_BLOCK.INVITE_SURNAME_ERROR }]}
                        className={styles.form_familyName}
                      >
                        <Input placeholder={PEOPLE_BLOCK.SURNAME_PLACEHOLDER} size="large" />
                      </Form.Item>
                    </div>
                    <div className={styles.inviteModal_formItemWrapper}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'email']}
                        rules={[
                          { required: true, message: PEOPLE_BLOCK.INVITE_EMAIL_ERROR },
                          {
                            type: 'email',
                            message: PEOPLE_BLOCK.INVITE_VALIDATION_EMAIL_ERROR,
                            transform: trimInput,
                          },
                        ]}
                        className={styles.form_email}
                      >
                        <Input
                          placeholder="name@example.com"
                          size="large"
                          prefix={<MailOutlined className={styles.form_email_icon} />}
                        />
                      </Form.Item>
                      <div className={styles.inviteModal_roleItemWrapper}>
                        <UserOutlined className={styles.inviteModal_roleItem_icon} />
                        <Form.Item
                          {...field}
                          name={[field.name, 'role']}
                          rules={[{ required: true, message: PEOPLE_BLOCK.INVITE_ROLE_ERROR }]}
                          className={styles.form_role}
                        >
                          <Select
                            placeholder="Role"
                            size="large"
                            className={styles.inviteModal_roleItem_select}
                          >
                            {Object.entries(UserRolesMap).map(([key, value]) => {
                              return (
                                <Option key={key} value={key}>
                                  {value}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            );
          }}
        </Form.List>
        <div className={styles.inviteModal_actions}>
          <Button onClick={() => handleCancel()}>{PEOPLE_BLOCK.CANCEL_LABEL}</Button>
          <Button
            type="primary"
            onClick={() => handleOk()}
            htmlType="submit"
            disabled={!formFilled}
            data-testid="inviteSend_button"
          >
            {PEOPLE_BLOCK.SEND_INVITE_LABEL}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default InvitePeopleModal;
