import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Tooltip } from 'antd';
import { DropdownProps } from 'antd/lib/dropdown/dropdown.d';
import { formatTestId } from '@utils/test';
import { IUserMenuItem, USER_MENU_SETTINGS } from '@constants/user-menu';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';
import styles from './userMenu.module.scss';

interface IUserMenuProps {
  userMenu?: IUserMenuItem[];
}

function UserMenu(props: IUserMenuProps) {
  const { userMenu } = props;
  const { email, firstName, lastName, fullName, role }: IUserData = useSelector(userSelector);
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState(
    USER_MENU_SETTINGS.map((item) => {
      const ItemIcon = item.icon as ReactNode;
      const access = item.userAccess?.includes(role);
      return access
        ? {
            label: item.link ? <Link to={item.link}> {item.label} </Link> : item.label,
            key: item.key,
            icon:
              item.key === 'logout' ? (
                <LogoutOutlined />
              ) : item.icon ? (
                <span className={styles.optionIcon}>{ItemIcon}</span>
              ) : null,
            'data-testid': formatTestId('userMenu', item.key),
          }
        : null;
    }),
  );

  function handleVisibleChange(flag: boolean) {
    setMenuOpened(flag);
  }

  useEffect(() => {
    if (!userMenu) return;
    setMenuItems(
      userMenu?.map((item) => {
        const ItemIcon = item.icon as ReactNode;
        return {
          label: item.link ? <Link to={item.link}> {item.label} </Link> : item.label,
          key: item.key,
          icon: item.icon ? <span className={styles.optionIcon}>{ItemIcon}</span> : null,
          'data-testid': formatTestId('userMenu', item.key),
        };
      }),
    );
  }, [userMenu]);

  const renderDropdownMenu = (menus: ReactNode) => {
    return (
      <>
        <div className={styles.userInfo}>
          <Avatar className={styles.userInfo_avatar}>
            {firstName[0]}
            {lastName[0]}
          </Avatar>
          <span className={styles.userInfo_details}>
            <span className={styles.userInfo_details_name}>{fullName}</span>
            <span className={styles.userInfo_details_email}>{email}</span>
          </span>
        </div>
        {menus}
      </>
    );
  };

  const dropDownProps: DropdownProps = {
    menu: { items: menuItems },
    trigger: ['click'],
    open: menuOpened,
    onOpenChange: handleVisibleChange,
    dropdownRender: renderDropdownMenu,
    overlayClassName: styles.menuOverlay,
  };

  return (
    <Dropdown {...dropDownProps}>
      <Tooltip title={fullName}>
        <Button
          className={styles.menuButton}
          type="text"
          shape="circle"
          size="small"
          data-testid="userMenu_dropdownButton"
        >
          {firstName[0]}
          {lastName[0]}
        </Button>
      </Tooltip>
    </Dropdown>
  );
}

export default UserMenu;
