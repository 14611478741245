import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const metadataFiltersStoreSelector = (state: RootState) => state.metadataFiltersStore;

export const metadataFiltersSelector = createSelector(
  metadataFiltersStoreSelector,
  (metadataFiltersStore) => metadataFiltersStore.pipelineMeta,
);

export const metadataFiltersValuesSelector = createSelector(
  metadataFiltersStoreSelector,
  (metadataFiltersStore) => metadataFiltersStore.pipelineMetaValues,
);

export const getMetadataValuesStatusSelector = createSelector(
  metadataFiltersStoreSelector,
  (metadataFiltersStore) => metadataFiltersStore.getMetadataValuesStatus,
);

export const getPipelineMetaStatusSelector = createSelector(
  metadataFiltersStoreSelector,
  (metadataFiltersStore) => metadataFiltersStore.getPipelineMetaStatus,
);

export const selectedMetaFilterValuesSelector = createSelector(
  metadataFiltersStoreSelector,
  (metadataFiltersStore) => metadataFiltersStore.selectedMetaFilterValues,
);

export const appliedMetaFilterValuesSelector = createSelector(
  metadataFiltersStoreSelector,
  (metadataFiltersStore) => metadataFiltersStore.appliedMetaFilterValues,
);
