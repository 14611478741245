import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { RELEVANCE_LABEL } from '@constants/search';
import styles from './searchResultScore.module.scss';

interface ISearchResultScoreProps {
  score: number | null;
}

const SearchResultScore = ({ score }: ISearchResultScoreProps) => {
  if (!score) return null;

  return (
    <div className={styles.score} data-testid="searchResult_relevance_score">
      <CheckCircleOutlined className={styles.score_icon} />
      {RELEVANCE_LABEL}: {(score * 100).toString().slice(0, 5)}%
    </div>
  );
};

export default SearchResultScore;
