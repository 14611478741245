import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, InputRef, Modal, Radio, Space, Tag } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { interpolateString, isValidDataName } from '@utils/string';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { COMMON_FORM_ERRORS } from '@constants/common';
import { HTTPStatusCode, StatusCodes } from '@constants/enum/common';
import {
  CREATE_PIPELINE_MORE_INFO,
  CREATE_PIPELINE_VERSION_LABEL,
  DEEPSET_CLOUD_VERSION_RADIO_OPTIONS,
  DOCS_PIPELINES_LINK,
  DOCS_PIPELINES_LINK_LABEL,
  PIPELINE_NAME_LABEL,
  UNIQUE_PIPELINE_NAME_INPUT_PLACEHOLDER,
  VALIDATION_ERRORS,
} from '@constants/pipelines';
import { INTERLEAVE_DOC_SEARCH } from '@constants/pipelineTemplates';
import { DeepsetCloudVersion, IMessage } from '@redux/types/types';
import styles from './namePipelineModal.module.scss';

const { Item } = Form;

interface INamePipelineModalProps {
  openModal: boolean;
  closeModal: () => void;
  modalTitle: string;
  okButtonText: string;
  description?: string;
  pipelineMessage: IMessage;
  resetMessage: () => void;
  defaultPipelineNameValue?: string;
  pipelineName?: string;
  renamePipeline?: (payload: { pipelineName: string; newPipelineName: string }) => void;
  renamePipelineNameStatus?: StatusCodes;
  resetRenamePipelineNameStatus?: () => void;
  onAfterPipelineCreated?: (pipelineName: string) => void;
  onAfterPipelineRenamed?: () => void;
  pipelineCreateUpdatePipelineStatus?: StatusCodes;
  createPipeline?: (payload: {
    code: string;
    pipelineName: string;
    deepsetCloudVersion?: DeepsetCloudVersion;
  }) => void;
  createPipelineFromEmptyYaml?: (
    pipelineName: string,
    deepsetCloudVersion?: DeepsetCloudVersion,
  ) => void;
  okButtonIcon?: React.ReactNode;
  showDeepsetCloudVersionOptions?: boolean;
}

const NamePipelineModal = (props: INamePipelineModalProps) => {
  const {
    openModal,
    closeModal,
    modalTitle,
    okButtonText,
    description,
    pipelineMessage,
    resetMessage,
    pipelineName,
    defaultPipelineNameValue,
    renamePipeline,
    renamePipelineNameStatus,
    resetRenamePipelineNameStatus,
    onAfterPipelineCreated,
    onAfterPipelineRenamed,
    pipelineCreateUpdatePipelineStatus,
    createPipeline,
    createPipelineFromEmptyYaml,
    okButtonIcon,
    showDeepsetCloudVersionOptions,
  } = props;

  const [pipelineNameFormValue, setPipelineNameFormValue] = useState(
    defaultPipelineNameValue || '',
  );
  const [formErrors, setFormErrors] = useState<string | null>(null);
  const [deepsetCloudVersionSelected, setDeepsetCloudVersionSelected] =
    useState<DeepsetCloudVersion>(DEEPSET_CLOUD_VERSION_RADIO_OPTIONS[0].value);
  const pipelineNameInputRef = useRef<InputRef>(null);
  const renameFailed = renamePipelineNameStatus && renamePipelineNameStatus === StatusCodes.ERROR;
  const renameSuccess =
    renamePipelineNameStatus && renamePipelineNameStatus === StatusCodes.SUCCESS;
  const failedCreatePipeline = pipelineCreateUpdatePipelineStatus === StatusCodes.ERROR;
  const creatingPipeline = pipelineCreateUpdatePipelineStatus === StatusCodes.IN_PROGRESS;

  // Form validation methods

  const inlineFormValidationCheck = () => {
    if (!pipelineNameFormValue) {
      setFormErrors(null);
      return;
    }

    if (!isValidDataName(pipelineNameFormValue)) {
      setFormErrors(COMMON_FORM_ERRORS.INVALID_CHARACTERS);
      return;
    }

    setFormErrors(null);
  };

  const beforeSubmitFormValidationCheck = () => {
    if (pipelineNameFormValue === '') setFormErrors(VALIDATION_ERRORS.EMPTY_PIPELINE_NAME);
  };

  useEffect(() => {
    resetMessage();
  }, []);

  useEffect(() => {
    if (
      pipelineNameFormValue &&
      pipelineCreateUpdatePipelineStatus === StatusCodes.SUCCESS &&
      onAfterPipelineCreated
    ) {
      onAfterPipelineCreated(pipelineNameFormValue);
    }
  }, [pipelineCreateUpdatePipelineStatus]);

  useEffect(() => {
    if (resetRenamePipelineNameStatus) resetRenamePipelineNameStatus();
    if (pipelineName) setPipelineNameFormValue(pipelineName);
  }, [pipelineName, openModal]);

  useEffectUpdateOnly(() => {
    if (defaultPipelineNameValue) setPipelineNameFormValue(defaultPipelineNameValue);
  }, [defaultPipelineNameValue]);

  useEffect(() => {
    if (renamePipelineNameStatus && renameFailed && resetRenamePipelineNameStatus)
      resetRenamePipelineNameStatus();
    if (pipelineMessage.status !== HTTPStatusCode.OK) resetMessage();

    inlineFormValidationCheck();
  }, [pipelineNameFormValue]);

  useEffect(() => {
    if (renamePipelineNameStatus && renameSuccess) {
      if (onAfterPipelineRenamed) onAfterPipelineRenamed();
      else closeModal();
    }
  }, [renamePipelineNameStatus]);

  const onClickOkHandler = () => {
    if (!isValidDataName(pipelineNameFormValue)) {
      beforeSubmitFormValidationCheck();
      return;
    }

    if (pipelineName) {
      if (renamePipeline) {
        const payload = {
          pipelineName,
          newPipelineName: pipelineNameFormValue,
        };

        renamePipeline(payload);
      }
    } else if (createPipeline) {
      const payload = {
        code: INTERLEAVE_DOC_SEARCH,
        pipelineName: pipelineNameFormValue,
        deepsetCloudVersion: showDeepsetCloudVersionOptions
          ? deepsetCloudVersionSelected
          : undefined,
      };

      createPipeline(payload);
    } else if (createPipelineFromEmptyYaml) {
      if (showDeepsetCloudVersionOptions)
        createPipelineFromEmptyYaml(pipelineNameFormValue, deepsetCloudVersionSelected);
      else createPipelineFromEmptyYaml(pipelineNameFormValue);
    }
  };

  const onModalVisibilityChange = (openState: boolean) => {
    if (!openState) {
      setPipelineNameFormValue('');
      resetMessage();
    } else
      pipelineNameInputRef.current?.focus({
        cursor: 'end',
      });
  };

  const onChangeDeepsetCloudVersion = (e: RadioChangeEvent) => {
    setDeepsetCloudVersionSelected(e.target.value);
  };

  const getDisabledOkButton = () => {
    if ((pipelineName && pipelineName === pipelineNameFormValue) || creatingPipeline) return true;
    return false;
  };

  const getDescriptionMessage = () => {
    if (renameFailed || failedCreatePipeline) return pipelineMessage.content;
    if (formErrors) return formErrors;
    if (description) return description;
    return '';
  };

  return (
    <Modal
      title={modalTitle}
      open={openModal}
      onCancel={() => closeModal()}
      className={styles.namePipelineModal}
      afterOpenChange={onModalVisibilityChange}
      okText={okButtonText}
      okButtonProps={{
        icon: okButtonIcon,
        disabled: getDisabledOkButton(),
        onClick: () => onClickOkHandler(),
        loading: creatingPipeline,
        'data-testid': 'createPipeline_okButton',
      }}
      cancelButtonProps={{
        disabled: creatingPipeline,
      }}
      centered
      maskClosable={false}
    >
      <Form layout="vertical">
        <Item label={PIPELINE_NAME_LABEL}>
          <Input
            value={pipelineNameFormValue}
            onChange={(e) => setPipelineNameFormValue(e.target.value)}
            placeholder={UNIQUE_PIPELINE_NAME_INPUT_PLACEHOLDER}
            ref={pipelineNameInputRef}
            status={formErrors || renameFailed || failedCreatePipeline ? StatusCodes.ERROR : ''}
            data-testid="pipelineName_textInput"
          />
          <div
            className={`${styles.namePipelineModal_description} ${
              formErrors || renameFailed || failedCreatePipeline ? styles.errorText : ''
            }`}
          >
            {getDescriptionMessage()}
          </div>

          {showDeepsetCloudVersionOptions && (
            <div className={styles.deepsetCloudVersion}>
              <div className={styles.deepsetCloudVersion_title}>
                {CREATE_PIPELINE_VERSION_LABEL}
              </div>
              <Radio.Group
                onChange={onChangeDeepsetCloudVersion}
                value={deepsetCloudVersionSelected}
              >
                <Space direction="vertical">
                  {DEEPSET_CLOUD_VERSION_RADIO_OPTIONS.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      <div>
                        <span className={styles.deepsetCloudVersion_optionTitle}>
                          {option.title}
                        </span>
                        {option.tagLabel && (
                          <span className={styles.deepsetCloudVersion_optionTag}>
                            <Tag bordered={false}>{option.tagLabel}</Tag>
                          </span>
                        )}
                      </div>
                      <div>{option.description}</div>
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
              <div>
                {interpolateString(CREATE_PIPELINE_MORE_INFO, {
                  pipelines: (
                    <a href={DOCS_PIPELINES_LINK} target="_blank" rel="noreferrer">
                      {DOCS_PIPELINES_LINK_LABEL}
                    </a>
                  ),
                })}
              </div>
            </div>
          )}
        </Item>
      </Form>
    </Modal>
  );
};

export default NamePipelineModal;
