import { createAsyncThunk } from '@reduxjs/toolkit';
import { ColumnsType } from 'antd/es/table';
import { Dayjs } from 'dayjs';
import { parseTableDataToCsvString } from '@utils/data';
import { downloadBlobFile } from '@utils/file';
import { getObservabilityMetricDataApi } from '@api/observability';
import { DateRangeInterval, MIMETypes } from '@constants/enum/common';
import {
  ChartExportType,
  DateRangeType,
  EXPORT_OBSERVABILITY_CHART,
  GET_OBSERVABILITY_METRIC_VALUE,
  IDateRange,
  IPipeline,
  ObservabilityMetric,
  RESET_SELECTED_OBSERVABILITY_DATE,
  RESET_SELECTED_OBSERVABILITY_PIPELINE,
  SELECT_OBSERVABILITY_DATE_RANGE,
  SELECT_OBSERVABILITY_PIPELIBNE,
} from '@redux/types/types';

export const resetSelectedObservabilityDate = {
  type: RESET_SELECTED_OBSERVABILITY_DATE,
};

export const resetSelectedObservabilityPipeline = {
  type: RESET_SELECTED_OBSERVABILITY_PIPELINE,
};

export const setObservabilityPipeline = (pipeline: IPipeline | null) => ({
  type: SELECT_OBSERVABILITY_PIPELIBNE,
  payload: pipeline,
});

export const setObservabilityDateRange = ({
  type,
  from,
  to,
}: {
  type: DateRangeType;
  from?: Dayjs | null;
  to?: Dayjs | null;
}) => ({
  type: SELECT_OBSERVABILITY_DATE_RANGE,
  payload: { type, from, to },
});

export const getObservabilityMetricValue = createAsyncThunk(
  GET_OBSERVABILITY_METRIC_VALUE,
  async ({
    pipelineId,
    metric,
    dateRange,
    interval,
  }: {
    pipelineId: string;
    metric: ObservabilityMetric;
    dateRange?: {
      from?: Dayjs | null;
      to?: Dayjs | null;
    };
    interval?: DateRangeInterval | null;
  }) => {
    const {
      data: { metrics },
    } = await getObservabilityMetricDataApi(pipelineId, metric, dateRange, interval);
    return { data: metrics ?? null, metric, interval };
  },
);

export const exportChart = ({
  chartObj,
  mode,
  extraInfo,
}: {
  chartObj: any;
  mode: ChartExportType;
  extraInfo?: {
    name?: string;
    metric?: ObservabilityMetric;
    dateRange?: IDateRange | null;
    columns?: ColumnsType<Record<string, unknown>> | null;
    data?: any;
  };
}) => {
  const { dateRange, name, metric } = extraInfo || {};
  const { from, to } = dateRange || {};

  const formattedFrom = from?.toISOString() ?? '';
  const formattedTo = to?.toISOString() ?? '';
  const fileName = `${name}_${metric}_${formattedFrom}_to_${formattedTo}.${mode}`;

  switch (mode) {
    case ChartExportType.PNG:
      chartObj.download('png', fileName);
      break;
    case ChartExportType.JPEG:
      chartObj.download('jpeg', fileName);
      break;
    case ChartExportType.CSV: {
      const { columns, data } = extraInfo || {};
      const csvString = parseTableDataToCsvString(columns!, data);
      downloadBlobFile(fileName, csvString, MIMETypes.CSV);
      break;
    }
    case ChartExportType.PDF:
      chartObj.download('pdf', fileName);
      break;

    default:
      break;
  }

  return { type: EXPORT_OBSERVABILITY_CHART };
};
