import { get } from 'lodash';
import { HTTPStatusCode } from '@constants/enum/common';

export const normalizeErrorMessage = (error: any) => {
  return get(error, 'response.data.errors[0]', error?.message || 'Unexpected error occured.');
};

export const getStatusCode = (error: any) => {
  return get(error, 'response.status', HTTPStatusCode.INTERNAL_SERVER_ERROR);
};
