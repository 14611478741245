import React from 'react';
import { Tag } from 'antd';
import { sortCollectionAlphabeticallyBy } from '@utils/sort';
import { CREATED_BY_LABEL } from '@constants/details-header';
import { TAGS_LABEL } from '@constants/experiments';
import { ITag } from '@redux/types/types';
import styles from './detailsHeader.module.scss';

// TODO: Use this component in the Pipeline Details and Experiment Details pages

interface IDetailsHeaderProps {
  title: string;
  subtitle: string;
  createdBy?: string;
  tags?: ITag[];
  actions?: React.ReactNode;
  status?: React.ReactNode;
  serviceLevel?: React.ReactNode;
  deepsetCloudVersion?: React.ReactNode;
}

const DetailsHeader = (props: IDetailsHeaderProps) => {
  const { title, subtitle, createdBy, tags, actions, status, serviceLevel, deepsetCloudVersion } =
    props;
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          {serviceLevel}
          <h4 className={styles.header_title}>{title}</h4>
          <div className={styles.header_subtitle}>{subtitle}</div>
          {status && <div>{status}</div>}
          {deepsetCloudVersion && <div>{deepsetCloudVersion}</div>}
        </div>
        {actions && <div className={styles.headerActions}> {actions} </div>}
      </div>
      {createdBy && (
        <section className={styles.infoSection}>
          {CREATED_BY_LABEL} {createdBy}
        </section>
      )}
      {!!tags?.length && (
        <section className={styles.infoSection}>
          <span className={styles.infoSection_label}>{TAGS_LABEL}:</span>
          {sortCollectionAlphabeticallyBy(tags, 'name').map((tag: ITag) => {
            const { tag_id: tagID, name } = tag;
            return (
              <Tag key={tagID} style={{ marginRight: 2 }}>
                {name}
              </Tag>
            );
          })}
        </section>
      )}
    </>
  );
};

export default DetailsHeader;
