import { getWorkspace } from '@utils/store';
import { IAPIPaginationData } from '@redux/types/types';
import { http } from './base-http';

export interface ICustomPromptBody {
  name: string;
  description: string;
  tags: string[];
  text: string;
}

export interface ITemplatesAPIParams {
  limit?: number;
  pageNumber?: number;
  field?: string;
  order?: string;
  select?: string;
  filter?: string;
}

export const getCustomPromptTemplatesApi = <T>(
  params?: ITemplatesAPIParams,
  workspaceName: string = getWorkspace(),
) => {
  const mappedParams = {
    ...(params?.limit && { limit: params.limit }),
    ...(params?.pageNumber && { page_number: params.pageNumber }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
    ...(params?.select && { select: params.select }),
    ...(params?.filter && { filter: params.filter }),
  };
  return http.get<T>(`/api/v1/workspaces/${workspaceName}/prompt_templates`, {
    params: mappedParams,
  });
};

export const creatCustomPromptTemplateApi = (
  promptBody: ICustomPromptBody,
  workspaceName: string = getWorkspace(),
) => {
  return http.post(`/api/v1/workspaces/${workspaceName}/prompt_templates`, promptBody);
};

export const updateCustomPromptTemplateApi = (
  promptTemplateId: string,
  promptBody: ICustomPromptBody,
  workspaceName: string = getWorkspace(),
) => {
  return http.put(
    `/api/v1/workspaces/${workspaceName}/prompt_templates/${promptTemplateId}`,
    promptBody,
  );
};

export const deleteCustomPromptTemplateApi = (
  promptTemplateId: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.delete(`/api/v1/workspaces/${workspaceName}/prompt_templates/${promptTemplateId}`);
};

export const getPublicPromptTemplatesApi = <T>(params?: ITemplatesAPIParams) => {
  const mappedParams = {
    ...(params?.limit && { limit: params.limit }),
    ...(params?.pageNumber && { page_number: params.pageNumber }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
    ...(params?.select && { select: params.select }),
    ...(params?.filter && { filter: params.filter }),
  };
  return http.get<T>(`/api/v1/public_prompt_templates`, { params: mappedParams });
};

export const getRecursivelyPromptTemplatesApi = async <T extends unknown[]>(
  params?: ITemplatesAPIParams,
  apiFnCall = getCustomPromptTemplatesApi,
  workspaceName: string = getWorkspace(),
): Promise<T | unknown[]> => {
  const {
    data: { data, has_more: hasMore },
  } = await apiFnCall<IAPIPaginationData<T>>(params, workspaceName);

  if (!hasMore) return data;

  const nextPage = params?.pageNumber ? params.pageNumber + 1 : 2;
  const nextParams = { ...params, pageNumber: nextPage };

  return [
    ...data,
    ...(await getRecursivelyPromptTemplatesApi(nextParams, apiFnCall, workspaceName)),
  ];
};
