import React, { useState } from 'react';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { CLEAR_FILTER_LABLE } from '@constants/data-page';
import { FilterType, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import styles from './multiSelectFilter.module.scss';

interface IMultiSelectFilterProps {
  filterKey: string;
  title: string;
  style?: React.CSSProperties;
  values: SelectedFilters;
  options: {
    key: string;
    label: string;
  }[];
  onChange: (items: SelectedFilterItem[]) => void;
  onClear: () => void;
}

const MultiSelectFilter = ({
  title,
  filterKey,
  options,
  onChange,
  values,
  onClear,
  style,
}: IMultiSelectFilterProps) => {
  const thereAreItemsSelected = !!values[filterKey]?.length;
  const clearFilterKey = `${filterKey}-clearFilter`;
  const { Option } = Select;

  const [multiSelectSearchValue, setMultiSelectSearchValue] = useState<string>('');

  const onSelectChange = (_: string, option: DefaultOptionType | DefaultOptionType[]) => {
    const mappedValues = option.map(({ key, children, value }: DefaultOptionType) => ({
      key,
      label: children,
      value,
      type: FilterType.MULTI_SELECT,
    }));
    onChange(mappedValues);
  };

  return (
    <Select
      showSearch
      placeholder={title}
      optionFilterProp="children"
      mode="multiple"
      className={`${styles.multiSelect} ${
        thereAreItemsSelected && styles.multiSelect_optionSelected
      }`}
      style={style}
      dropdownStyle={{ minWidth: '140px' }}
      value={values[filterKey] as unknown as string}
      tagRender={() => <>{!multiSelectSearchValue && title}</>}
      onChange={onSelectChange}
      onSelect={(item: string) => item === clearFilterKey && onClear()}
      onSearch={setMultiSelectSearchValue}
      onBlur={() => setMultiSelectSearchValue('')}
      maxTagPlaceholder={<></>}
      maxTagCount={1}
    >
      {thereAreItemsSelected && (
        <Option
          className={styles.multiSelect_clearFilterOption}
          key={clearFilterKey}
          value={clearFilterKey}
        >
          {CLEAR_FILTER_LABLE}
        </Option>
      )}
      {options.map((option: { key: string; label: string }) => (
        <Option key={option.key} value={option.key}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default MultiSelectFilter;
