import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTemplatesSearchValue } from '@redux/actions/pipelineTemplatesActions';
import CategoriesSidebar from './components/CategoriesSidebar';
import TemplatesActionsHeader from './components/TemplatesActionsHeader';
import TemplatesList from './components/TemplatesList';
import styles from './pipelineTemplates.module.scss';

const PipelineTemplatesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTemplatesSearchValue(null));
  }, []);

  return (
    <div className={styles.wrapper} data-testid="templates_page">
      <div className={styles.container}>
        <CategoriesSidebar />
        <div className={styles.content}>
          <TemplatesActionsHeader />
          <TemplatesList />
        </div>
      </div>
    </div>
  );
};

export default PipelineTemplatesPage;
