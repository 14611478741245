import React from 'react';
import { Button } from 'antd';
import CheckCircleFilled from '@assets/icons/CheckCircleFilled.svg?react';
import {
  ALREADY_HAVE_ACCOUNT_DESCRIPTION,
  ALREADY_HAVE_ACCOUNT_TITLE,
  BY_DEEPSET_LABEL,
  CONTACT_SUPPORT_TITLE,
  DEEPSET_SUCCESS_SUGGESTIONS,
  LOGIN_BUTTON_LABEL,
  REGISTRATION_SUCCESS_DESCRIPTION,
  REGISTRATION_SUCCESS_TITLE,
} from '@constants/aws-marketplace';
import DcLogoAvatar from '@components/common/dcLogoAvatar/DcLogoAvatar';
import styles from './registrationSuccessful.module.scss';

interface IAwsMarketplaceRegistrationSuccessfulProps {
  login: () => void;
}

const avatarSize = 'small';

const AwsMarketplaceRegistrationSuccessful = (
  props: IAwsMarketplaceRegistrationSuccessfulProps,
) => {
  const { login } = props;
  const CONTACT_URL = import.meta.env.VITE_CONTACT_DEEPSET_URL;

  const getSuggestioncards = (suggestion: {
    title: string;
    description: string;
    link: string;
    buttonLabel: string;
  }) => {
    return (
      <div key={suggestion.title} className={styles.suggestionCard}>
        <h6>{suggestion.title}</h6>
        <div className={styles.suggestionCard_description}>{suggestion.description}</div>
        <div className={styles.suggestionCard_logo}>
          <DcLogoAvatar size={avatarSize} />
          <span>{BY_DEEPSET_LABEL}</span>
        </div>
        <div className={styles.suggestionCard_actionButton}>
          <a href={suggestion.link} target="_blank" rel="noreferrer">
            <Button type="primary" size="small">
              {suggestion.buttonLabel}
            </Button>
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_section}>
        <CheckCircleFilled className={styles.successIcon} />
        <div className={styles.description}>
          <h4>{REGISTRATION_SUCCESS_TITLE}</h4>
          <div className={styles.description_subtitle}>{REGISTRATION_SUCCESS_DESCRIPTION}</div>
        </div>
        <a href={CONTACT_URL} target="_blank" rel="noreferrer">
          <Button type="primary">{CONTACT_SUPPORT_TITLE}</Button>
        </a>
      </div>
      <div className={styles.container_suggestions}>
        {DEEPSET_SUCCESS_SUGGESTIONS.map((suggestion) => getSuggestioncards(suggestion))}
      </div>
      <div className={styles.container_section}>
        <div className={styles.description}>
          <h4>{ALREADY_HAVE_ACCOUNT_TITLE}</h4>
          <div className={styles.description_subtitle}>{ALREADY_HAVE_ACCOUNT_DESCRIPTION}</div>
        </div>
        <Button onClick={() => login()}>{LOGIN_BUTTON_LABEL}</Button>
      </div>
    </div>
  );
};

export default AwsMarketplaceRegistrationSuccessful;
