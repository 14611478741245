import { DependencyList, MutableRefObject, useEffect } from 'react';

const useScrollToBottomOnChange = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  deps: DependencyList = [],
  observerDisconnectDelay: number = 1000,
) => {
  useEffect(() => {
    const { current: element } = ref;
    if (!element) return () => {};

    let timeoutId: number;

    const observer = new MutationObserver(() => {
      clearTimeout(timeoutId);

      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth',
      });

      timeoutId = window.setTimeout(() => {
        observer.disconnect();
      }, observerDisconnectDelay);
    });

    observer.observe(element, { childList: true, subtree: true });

    return () => {
      clearTimeout(timeoutId);
      observer.disconnect();
    };
  }, deps);
};

export default useScrollToBottomOnChange;
