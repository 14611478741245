import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Switch } from 'antd';
import { SupportedViewerLanguage } from '@constants/enum/common';
import { toggleDebugMode } from '@redux/actions/searchActions';
import {
  chatResultsSelector,
  debugModeSelector,
  searchResultSelector,
} from '@redux/selectors/searchSelectors';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import styles from './debugModal.module.scss';
const CodeViewer = React.lazy(
  () => import(/* webpackChunkName: "CodeViewer" */ '@components/codeViewer/CodeViewer'),
);

interface DebugModalProps {
  // Define the props for the DebugModal component here
}

const DebugModal: React.FC<DebugModalProps> = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [debugLogs, setDebugLogs] = useState<(Record<string, unknown> | null | undefined)[]>([]);
  const queryResults = useSelector(searchResultSelector);
  const chatResult = useSelector(chatResultsSelector);
  const debugMode = useSelector(debugModeSelector);

  const handleOnChange = (checked: boolean) => {
    dispatch(toggleDebugMode(checked));
  };

  const handleViewLogs = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const logs = queryResults._debug || queryResults.debug;
    setDebugLogs([logs]);
  }, [queryResults]);

  useEffect(() => {
    const logs = chatResult.map((chat) => chat.debug || chat._debug);
    setDebugLogs(logs);
  }, [chatResult]);

  return (
    <>
      <div className={styles.debugMode}>
        Enable debug mode <Switch value={debugMode} size="small" onChange={handleOnChange} />
      </div>
      {!!debugMode && (
        <div className={styles.debugMode}>
          <Button size="small" onClick={handleViewLogs} disabled={debugLogs.length < 1}>
            View Logs
          </Button>
        </div>
      )}
      <Modal
        className={styles.modal}
        title="Debug Logs"
        open={isModalOpen}
        onCancel={handleOk}
        cancelText="Done"
        centered
        footer={null}
      >
        <React.Suspense fallback={<LoadingIndicator />}>
          <CodeViewer
            code={JSON.stringify(debugLogs, null, 2)}
            codeLanguage={SupportedViewerLanguage.JSON}
          />
        </React.Suspense>
      </Modal>
    </>
  );
};

export default DebugModal;
