import React from 'react';
import { Button } from 'antd';
import { isNil } from 'lodash';
import { trimContent } from '@utils/string';
import { PREVIEW_BUTTON_LABEL } from '@constants/common';
import styles from './contextRender.module.scss';

const renderTrim = ({
  content,
  maxCharacters = 300,
  previewButtonHandler,
}: {
  content: string;
  maxCharacters?: number;
  previewButtonHandler?: (content: string) => void;
}) => (
  <>
    {trimContent(content, maxCharacters)}
    {previewButtonHandler && content.length > maxCharacters && (
      <Button
        type="link"
        size="small"
        className={styles.previewButton}
        onClick={() => previewButtonHandler(content)}
      >
        {PREVIEW_BUTTON_LABEL}
      </Button>
    )}
  </>
);

export const contextRender = ({
  content,
  answerStart,
  answerEnd,
  maxCharacters = 300,
  previewButtonHandler,
}: {
  content: string;
  answerStart?: number;
  answerEnd?: number;
  maxCharacters?: number;
  previewButtonHandler?: (content: string) => void;
}) => {
  if (!content) return null;
  if (isNil(answerStart) || isNil(answerEnd))
    return renderTrim({ content, maxCharacters, previewButtonHandler });
  const answerDistance = answerEnd - answerStart;
  const offset =
    answerStart && answerEnd ? Math.floor((maxCharacters - answerDistance) / 2) : maxCharacters;
  const preIndex = answerStart - offset < 0 ? 0 : answerStart - offset;
  const postIndex = answerEnd + offset;
  const beforeText = content.slice(preIndex, answerStart);
  const afterText = content.slice(answerEnd, postIndex);
  const answer = content.slice(answerStart, answerEnd);
  return (
    <>
      <span>
        {preIndex > 0 && '...'}
        {beforeText}
      </span>
      <strong>{answer}</strong>
      <span>
        {afterText}
        {postIndex < content.length && '...'}
      </span>
      {previewButtonHandler && content.length > maxCharacters && (
        <Button
          type="link"
          size="small"
          className={styles.previewButton}
          onClick={() => previewButtonHandler(content)}
        >
          {PREVIEW_BUTTON_LABEL}
        </Button>
      )}
    </>
  );
};
