import { PipelineNodeTypes } from '@constants/pipelines';

export const EXPERIMENTS_LABEL = 'Experiments';
export const EXPERIMENTS_DESCRIPTION =
  'Evaluate your pipeline systematically and use the resulting metrics to help you find the optimal pipeline configuration.';
export const EMPTY_EXPERIMENTS_TABLE_LABEL = `Here's an overview of all your experiments in this workspace. Use experiments to evaluate your pipeline.`;
export const EMPTY_STATE_HEADER = "No experiments? Let's create one!";
export const EMPTY_STATE_DEFAULT_DESCRIPTION =
  "First, %uploadFilesLink% and %prepareEvalSetLink%. Then, %creatPipelineLink% to evaluate. Once it's ready, go ahead and create your first experiment. If you need more help, see the %documentationLink%.";
export const EMPTY_STATE_ALL_STEPS_COMPLETED_DESCRIPTION =
  'You have everything you need for an experiment so you can go ahead and create one. All your experiments are displayed on this page. If you need more help, view the %documentationLink%.';
export const EMPTY_STATE_UPLOAD_FILES_LINK = 'Upload Files';
export const EMPTY_STATE_CREATE_PIPELINE_LINK = 'Create Pipeline';
export const EMPTY_STATE_PREPARE_EVAL_SET_LINK = 'Prepare the Evaluation Set';
export const EMPTY_STATE_DOCUMENTATION_LINK = 'documentation';
export const EMPTY_STATE_TOOLTIP_MESSAGE = 'Complete the steps above to create an experiment';
export const PIPELINE_VERSION_NOT_SUPPORTED_ALERT_MESSAGE =
  "We're updating deepset Cloud to bring you more flexible and high-performing pipelines. During this update, some pipelines may be temporarily incompatible with experiments.";
export const EXPERIMENT_CREATE_BUTTON_LABEL = 'New Experiment';
export const EXPERIMENT_RUN_START_LABEL = 'Start';
export const EXPERIMENT_RUN_TRY_AGAIN_LABEL = 'Try Again';
export const PIPELINE_SECTION_TITLE = 'Pipeline';
export const EVALUATION_SECTION_TITLE = 'Evaluation Set';
export const DATASET_SECTION_TITLE = 'Dataset';
export const DATASET_SECTION_DESCRIPTION =
  'These are the files your pipeline will search through during the experiment. The more files you choose, the more difficult a task your pipeline will have.';
export const NAMING_SECTION_TITLE = 'Naming';
export const PIPELINE_SECTION_HEADER = 'Choose Pipeline';
export const EVALUATION_SECTION_HEADER = 'Choose Evaluation Set';
export const DATASET_SECTION_HEADER = 'Choose the Dataset';
export const EDIT_EVALUATION_SECTION_HEADER = 'Change the Evaluation Set';
export const EDIT_PIPELINE_SECTION_HEADER = 'Change the Pipeline';
export const EDIT_EXPERIMENT_HEADER = 'Edit Experiment';
export const NAMING_SECTION_HEADER = 'Name Your Experiment';
export const EXPERIMENT_DRAWER_TITLE = 'Create an Experiment';
export const SAVE_DRAFT_BUTTON_LABEL = 'Save as Draft';
export const SUBMIT_BUTTON_LABEL = 'Start Experiment';
export const DONE_BUTTON_LABEL = 'Done';
export const NEXT_BUTTON_LABEL = 'Next';
export const NAME_INPUT_PLACEHOLDER = 'Give your experiment a name';
export const NAME_INPUT_LABEL = 'Name';
export const TAGS_SELECT_LABEL = 'Tags';
export const TAGS_SELECT_PLACEHOLDER = 'Use keywords to describe your experiment';
export const RUN_DELETE_POPCONFIRM_MESSAGE = 'Delete this experiment?';
export const RUN_DELETE_POPCONFIRM_CONFIRM = 'Yes';
export const RUN_DELETE_POPCONFIRM_DECLINE = 'No';
export const DETAILS_BUTTON_LABEL = 'Show Details';
export const DELETE_BUTTON_LABEL = 'Delete';
export const EDIT_BUTTON_LABEL = 'Edit';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const SAVE_BUTTON_LABEL = 'Save';
export const START_BUTTON_LABEL = 'Start';
export const EXPERIMENT_FILTER_TAGS_LABEL = 'Tags';
export const EXPERIMENT_FILTER_STATUS_LABEL = 'Status';
export const EXPERIMENT_FILTER_USERS_LABEL = 'Created by';
export const ADD_NEW_FILTER_BUTTON_LABEL = 'Add New Filter';

// Experiments Details
export const METRICS_HEADER = 'Metrics';
export const PARAMETERS_HEADER = 'Parameters';
export const NOTES_HEADER = 'Notes';
export const LOGS_HEADER = 'Console';
export const PIPELINE_HEADER = 'Pipeline';
export const PIPELINE_SNAPSHOT_DATE_HEADER = 'Pipeline Snapshot Date';
export const PIPELINE_SAS_MODEL_NAME_HEADER = 'SAS Model Name';
export const PREDICTIONS_HEADER = 'Predictions';
export const EVALUATION_SET_HEADER = 'Evaluation Set';
export const PREVIEW_HEADER = 'Preview';
export const NOTES_INPUT_PLACEHOLDER = 'Enter your notes here.';
export const NAME_LABEL = 'Name';
export const VALUE_LABEL = 'Value';
export const NODE_NAME_LABEL = 'Node Name';
export const NODE_TYPE_LABEL = 'Node Type';
export const TAGS_LABEL = 'Tags';
export const DOWNLOAD_PREDICTION_BUTTON_LABEL = 'Download CSV';
export const FILTERS_HEADER_LABEL = 'Filters applied';
export const FILTERS_LABEL = 'Filters';
export const FILTERS_MODAL_HEADER_LABEL = 'Filters';
export const PIPELINE_YAML_INFO =
  "This is a snapshot of your pipeline taken at %snapshotDate%. Your experiment uses this snapshot so editing the original pipeline %pipelineLink% doesn't affect the experiment.";
export const EXPERIMENT_FILTER_RANK_LABEL = 'Rank';
export const EXPERIMENT_FILTER_EXACT_MATCH_LABEL = 'Exact match';
export const EXPERIMENT_FILTER_F1_SCORE_LABEL = 'F1 score';
export const EXPERIMENT_FILTER_CONTEXT_MATCH_LABEL = 'Context match';
export const EXPERIMENT_FILTER_CONTEXT_SIMILARITY_LABEL = 'Context similarity';
export const EXPERIMENT_FILTER_EVAL_MODE_LABEL = 'Eval mode';
export const EXPERIMENT_FILTER_NO_ANSWER_LABEL = 'No Answer';
export const EXPERIMENT_FILTER_GROUNDEDNESS_LABEL = 'Groundedness';

export enum PredictionType {
  ANSWER = 'answer',
  DOCUMENT = 'document',
}

export enum NewEvalRunDatasetSelectionOption {
  ALL_FILES = 'ALL_FILES',
  SUBSET = 'SUBSET',
}

export const NEW_EVAL_RUN_DATASET_SELECTION_ITEMS = [
  { key: NewEvalRunDatasetSelectionOption.ALL_FILES, label: 'All files in your workspace' },
  { key: NewEvalRunDatasetSelectionOption.SUBSET, label: 'Choose subset of files' },
];

export const MetricShortNames: { [key: string]: string } = {
  // Retriever metrics
  integrated_mean_reciprocal_rank: 'MRR int.',
  integrated_mean_average_precision: 'mAP int.',
  integrated_recall_single_hit: 'Recall SH int.',
  integrated_recall_multi_hit: 'Recall MH int.',
  integrated_normal_discounted_cumulative_gain: 'NDCG int.',
  integrated_precision: 'Precision int.',
  // Extractive metrics
  isolated_exact_match: 'EM iso.',
  integrated_exact_match: 'EM int.',
  isolated_f1: 'F1 iso.',
  integrated_f1: 'F1 int.',
  isolated_sas: 'SAS iso.',
  integrated_sas: 'SAS int.',
  // Generative metrics
  integrated_groundedness: 'Groundedness int.',
  integrated_no_answer_ratio: 'No Answer R. int.',
  integrated_query_latency: 'Query Latency int.',
};

export const MetricNames: { [key: string]: string } = {
  // Retriever metrics
  integrated_mean_reciprocal_rank: 'Mean Reciprocal Rank Integrated',
  integrated_mean_average_precision: 'Mean Average Precision Integrated',
  integrated_recall_single_hit: 'Recall Single Hit Integrated',
  integrated_recall_multi_hit: 'Recall Multi Hit Integrated',
  integrated_normal_discounted_cumulative_gain: 'Normalized Discounted Cumulative Gain Integrated',
  integrated_precision: 'Precision Integrated',
  // Extractive metrics
  isolated_exact_match: 'Exact Match Isolated',
  integrated_exact_match: 'Exact Match Integrated',
  isolated_f1: 'F1 Isolated',
  integrated_f1: 'F1 Integrated',
  isolated_sas: 'SAS Isolated',
  integrated_sas: 'SAS Integrated',
  // Generative metrics
  integrated_groundedness: 'Groundedness Integrated',
  integrated_no_answer_ratio: 'No Answer Ratio Integrated',
};

export const MetricDescriptions: { [key: string]: string } = {
  // Retriever metrics
  integrated_mean_reciprocal_rank:
    'MRR (Mean Reciprocal Rank) measures the position (rank) of the first correctly retrieved document. Values range between 0 (no matches) and 1 (the system retrieved a correct document for all queries as the top result).',
  integrated_mean_average_precision:
    'mAP (Mean Average Precision) measures the average of the precision values for all correctly retrieved documents (at their rank). Values range between 0 and 1: 1 meaning that all correct documents are always on top.',
  integrated_recall_single_hit:
    'Recall Single Hit measures the completeness of correct documents among the retrieved ones. Values range between 0 and 1: 1 meaning that at least one correct document is always retrieved.',
  integrated_recall_multi_hit:
    'Recall Multi Hit measures the completeness of correct documents among the retrieved ones. Values range between 0 and 1: 1 meaning that all correct documents are always retrieved.',
  integrated_normal_discounted_cumulative_gain:
    'NDCG (Normalized Discounted Cumulative Gain) measures the gain obtained by each correctly retrieved document depending on its position (rank). Values range between 0 and 1: 1 meaning that that all correct documents are always on top.',
  integrated_precision:
    'Precision measures how precisely the system retrieves correct documents. Values range between 0 and 1: 1 meaning that only correct documents are always retrieved.',
  // Reader metrics
  isolated_exact_match:
    'EM (Exact Match) measures the fraction of predicted answers being identical to the correct answer. Values range between 0 and 1: 1 meaning that all predicted answers are always identical to the correct ones. Isolated metrics show the upper bound the Reader is able to achieve by simulating a perfect Retriever.',
  integrated_exact_match:
    'EM (Exact Match) measures the fraction of predicted answers being identical to the correct answer. Values range between 0 and 1: 1 meaning that all predicted answers are always identical to the correct ones. Integrated metrics show the actual effectiveness of the Reader in combination with the Retriever.',
  isolated_f1:
    'The F1 score measures the word overlap between the predicted and the correct answer. Values range between 0 and 1: 1 meaning that all predicted words always match the correct ones. Whenever EM is 1, F1 is also 1. Isolated metrics show the upper bound the Reader is able to achieve by simulating a perfect Retriever.',
  integrated_f1:
    'The F1 score measures the word overlap between the predicted and the correct answer. Values range between 0 and 1: 1 meaning that all predicted words always match the correct ones. Whenever EM is 1, F1 is also 1. Integrated metrics show the actual effectiveness of the Reader in combination with the Retriever.',
  isolated_sas:
    'The Semantic Answer Similarity (SAS) tells you how similar the predicted answer is to the gold answer. 1 is very similar, 0 is not similar. Isolated means that it expresses the performance of the Reader node in isolation. The Reader receives only matching documents as input.',
  integrated_sas:
    'The Semantic Answer Similarity (SAS) tells you how similar the predicted answer is to the gold answer. 1 is very similar, 0 is not similar. Integrated means that it expresses the performance of the full pipeline and the Reader receives the documents from a Retriever.',
  integrated_groundedness:
    'Groundedness measures the degree to which an answer is supported by the retrieved documents. Values range between 0 and 1: 1 meaning that all predicted answers are completely supported by the retrieved documents.',
  integrated_no_answer_ratio:
    'The No Answer Ratio measures the fraction of queries for which the pipeline did not find an answer. Values range between 0 and 1: 1 meaning that no answer was found for any query.',
  integrated_query_latency:
    'The Query Latency measures the time it takes for the pipeline to process a query. The time is measured in seconds.',
};

export const PREDICTIONS_EVAL_MODE_NAMES = {
  INTEGRATED: 'Integrated',
  ISOLATED: 'Isolated',
};

// Data table

export const EXPERIMENTS_FILTERS_FIELD_KEYS = {
  TAGS_ID: 'tags/tag_id',
  STATUS: 'status',
  CREATED_BY_USER_ID: 'created_by/user_id',
};
export const PREDICTIONS_FILTERS_FIELD_KEYS = {
  RANK: 'rank',
  EXACT_MATCH: 'exact_match',
  F1: 'f1',
  CONTEXT_MATCH: 'context_match',
  CONTEXT_SIMILARITY: 'context_similarity',
  EVAL_MODE: 'eval_mode',
  GROUNDEDNESS: 'groundedness',
  NO_ANSWER: 'no_answer_ratio',
};
export const PREDICTIONS_FILTERS_OPTIONS_KEYS = {
  INTEGRATED: 'INTEGRATED',
  ISOLATED: 'ISOLATED',
};

export const DEFAULT_SORTING_KEY = 'created_at_desc';
export const DEFAULT_PREDICTIONS_SORTING_KEY = 'rank_asc';

export const EXPERIMENTS_SORTING_DATATABLE_OPTIONS = [
  { key: 'created_at_desc', label: 'Newest to oldest' },
  { key: 'created_at_asc', label: 'Oldest to newest' },
  { key: 'name_asc', label: 'Name A to Z' },
  { key: 'name_desc', label: 'Name Z to A' },
  { key: 'integrated_f1_asc', label: 'Integrated F1 score ascending' },
  { key: 'integrated_f1_desc', label: 'Integrated F1 score descending' },
  { key: 'isolated_f1_asc', label: 'Isolated F1 score ascending' },
  { key: 'isolated_f1_desc', label: 'Isolated F1 score descending' },
  { key: 'integrated_recall_multi_hit_asc', label: 'Recall multi hit ascending' },
  { key: 'integrated_recall_multi_hit_desc', label: 'Recall multi hit descending' },
  { key: 'integrated_mean_reciprocal_rank_asc', label: 'MRR ascending' },
  { key: 'integrated_mean_reciprocal_rank_desc', label: 'MRR descending' },
  { key: 'integrated_groundedness_asc', label: 'Groundedness ascending' },
  { key: 'integrated_groundedness_desc', label: 'Groundedness descending' },
  { key: 'integrated_no_answer_ratio_asc', label: 'No Answer Ratio ascending' },
  { key: 'integrated_no_answer_ratio_desc', label: 'No Answer Ratio descending' },
  { key: 'integrated_query_latency_asc', label: 'Query latency ascending' },
  { key: 'integrated_query_latency_desc', label: 'Query latency descending' },
];

export const PREDICTIONS_SORTING_DATATABLE_OPTIONS_BY_NODE = {
  [PipelineNodeTypes.ANSWER_NODE]: [
    { key: 'rank_asc', label: 'Rank ascending' },
    { key: 'rank_desc', label: 'Rank descending' },
    { key: 'query_asc', label: 'Query A to Z' },
    { key: 'query_desc', label: 'Query Z to A' },
    { key: 'f1_context_scope_asc', label: 'F1 score ascending' },
    { key: 'f1_context_scope_desc', label: 'F1 score descending' },
    { key: 'answer_asc', label: 'Predicted answer A to Z' },
    { key: 'answer_desc', label: 'Predicted answer Z to A' },
  ],
  [PipelineNodeTypes.DOCUMENT_NODE]: [
    { key: 'rank_asc', label: 'Rank ascending' },
    { key: 'rank_desc', label: 'Rank descending' },
    { key: 'query_asc', label: 'Query A to Z' },
    { key: 'query_desc', label: 'Query Z to A' },
    { key: 'context_similarity_asc', label: 'Context similarity ascending' },
    { key: 'context_similarity_desc', label: 'Context similarity descending' },
  ],
  [PipelineNodeTypes.GENERATIVE_NODE]: [
    { key: 'rank_asc', label: 'Rank ascending' },
    { key: 'rank_desc', label: 'Rank descending' },
    { key: 'query_asc', label: 'Query A to Z' },
    { key: 'query_desc', label: 'Query Z to A' },
    { key: 'groundedness_asc', label: 'Groundedness ascending' },
    { key: 'groundedness_desc', label: 'Groundedness descending' },
    { key: 'answer_asc', label: 'Predicted answer A to Z' },
    { key: 'answer_desc', label: 'Predicted answer Z to A' },
  ],
};

export const SORTING_PARAMS_BY_KEY = {
  created_at_desc: {
    field: 'created_at',
    order: 'DESC',
  },
  created_at_asc: {
    field: 'created_at',
    order: 'ASC',
  },
  name_asc: {
    field: 'name',
    order: 'ASC',
  },
  name_desc: {
    field: 'name',
    order: 'DESC',
  },
  f1_context_scope_asc: {
    field: 'f1_context_scope',
    order: 'ASC',
  },
  f1_context_scope_desc: {
    field: 'f1_context_scope',
    order: 'DESC',
  },
  integrated_f1_asc: {
    field: 'integrated_f1',
    order: 'ASC',
  },
  integrated_f1_desc: {
    field: 'integrated_f1',
    order: 'DESC',
  },
  isolated_f1_asc: {
    field: 'isolated_f1',
    order: 'ASC',
  },
  isolated_f1_desc: {
    field: 'isolated_f1',
    order: 'DESC',
  },
  integrated_recall_multi_hit_asc: {
    field: 'integrated_recall_multi_hit',
    order: 'ASC',
  },
  integrated_recall_multi_hit_desc: {
    field: 'integrated_recall_multi_hit',
    order: 'DESC',
  },
  integrated_mean_reciprocal_rank_asc: {
    field: 'integrated_mean_reciprocal_rank',
    order: 'ASC',
  },
  integrated_mean_reciprocal_rank_desc: {
    field: 'integrated_mean_reciprocal_rank',
    order: 'DESC',
  },
  rank_asc: {
    field: 'rank',
    order: 'ASC',
  },
  rank_desc: {
    field: 'rank',
    order: 'DESC',
  },
  query_asc: {
    field: 'query',
    order: 'ASC',
  },
  query_desc: {
    field: 'query',
    order: 'DESC',
  },
  answer_asc: {
    field: 'answer',
    order: 'ASC',
  },
  answer_desc: {
    field: 'answer',
    order: 'DESC',
  },
  context_similarity_asc: {
    field: 'context_similarity',
    order: 'ASC',
  },
  context_similarity_desc: {
    field: 'context_similarity',
    order: 'DESC',
  },
  groundedness_asc: {
    field: 'groundedness',
    order: 'ASC',
  },
  groundedness_desc: {
    field: 'groundedness',
    order: 'DESC',
  },
  integrated_groundedness_asc: {
    field: 'integrated_groundedness',
    order: 'ASC',
  },
  integrated_groundedness_desc: {
    field: 'integrated_groundedness',
    order: 'DESC',
  },
  integrated_no_answer_ratio_asc: {
    field: 'integrated_no_answer_ratio',
    order: 'ASC',
  },
  integrated_no_answer_ratio_desc: {
    field: 'integrated_no_answer_ratio',
    order: 'DESC',
  },
  integrated_query_latency_asc: {
    field: 'integrated_query_latency',
    order: 'ASC',
  },
  integrated_query_latency_desc: {
    field: 'integrated_query_latency',
    order: 'DESC',
  },
};
