import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Modal } from 'antd';
import { interpolateString } from '@utils/string';
import {
  DELETE_PIPELINE_CONFIRMATION_MESSAGE,
  DELETE_PIPELINE_DEV_MODAL_MESSAGE,
  DELETE_PIPELINE_MULTIPLE_MODAL_MESSAGE,
  DELETE_PIPELINE_MULTIPLE_MODAL_MESSAGE_CONFIRMATION,
  DELETE_PIPELINE_PROD_MODAL_MESSAGE,
  DELETE_PIPELINE_PROD_MODAL_MESSAGE_CONFIRMATION,
  DELETE_PIPELINES_CONFIRMATION_MESSAGE,
  PIPELINE_NAME_LABEL,
  UNDEPLOY_PIPELINE_CONFIRMATION_MESSAGE,
  UNDEPLOY_PIPELINE_DEV_MODAL_MESSAGE,
  UNDEPLOY_PIPELINE_PROD_MODAL_MESSAGE,
  UNDEPLOY_PIPELINE_PROD_MODAL_MESSAGE_CONFIRMATION,
} from '@constants/pipelines';
import { IPipeline, PipelineServiceLevel } from '@redux/types/types';
import styles from './pipelineConfirmationModal.module.scss';

interface IPipelineConfirmationModal {
  open: boolean;
  okText: string;
  onOk: () => void;
  cancelText: string;
  onCancel: () => void;
  pipelines: IPipeline[];
  undeployingPipeline?: boolean;
}

interface IProductionPipelinesToDelete extends IPipeline {
  inputValue: string;
}

const PipelineConfirmationModal = (props: IPipelineConfirmationModal) => {
  const { open, okText, onOk, cancelText, onCancel, pipelines, undeployingPipeline } = props;
  const [productionPipelinesToDelete, setProductionPipelinesToDelete] = useState<
    IProductionPipelinesToDelete[]
  >([]);

  useEffect(() => {
    if (pipelines.length > 0) {
      pipelines.forEach((pipeline) => {
        if (
          pipeline.service_level === PipelineServiceLevel.PRODUCTION &&
          productionPipelinesToDelete.find(
            (prodPipeline) => prodPipeline.pipeline_id === pipeline.pipeline_id,
          ) === undefined
        ) {
          setProductionPipelinesToDelete((prevState) => [
            ...prevState,
            { ...pipeline, inputValue: '' },
          ]);
        }
      });
    }
  }, [pipelines]);

  const onCancelHandler = () => {
    setProductionPipelinesToDelete([]);
    onCancel();
  };

  const onOkHandler = () => {
    setProductionPipelinesToDelete([]);
    onOk();
  };

  const getModalTitle = () => {
    if (undeployingPipeline) return UNDEPLOY_PIPELINE_CONFIRMATION_MESSAGE;
    if (pipelines.length > 1) return DELETE_PIPELINES_CONFIRMATION_MESSAGE;
    return DELETE_PIPELINE_CONFIRMATION_MESSAGE;
  };

  const getModalMainMessage = () => {
    if (
      undeployingPipeline &&
      pipelines.length === 1 &&
      pipelines[0].service_level !== PipelineServiceLevel.PRODUCTION
    )
      return UNDEPLOY_PIPELINE_DEV_MODAL_MESSAGE;
    if (pipelines.length > 1) return DELETE_PIPELINE_MULTIPLE_MODAL_MESSAGE;
    if (pipelines.length === 1 && pipelines[0].service_level !== PipelineServiceLevel.PRODUCTION)
      return DELETE_PIPELINE_DEV_MODAL_MESSAGE;
    return '';
  };

  const getModalMessageForProductionPipelines = () => {
    if (undeployingPipeline) return UNDEPLOY_PIPELINE_PROD_MODAL_MESSAGE;
    return DELETE_PIPELINE_PROD_MODAL_MESSAGE;
  };

  const getModalConfirmationMessageForProductionPipelines = () => {
    if (undeployingPipeline) return UNDEPLOY_PIPELINE_PROD_MODAL_MESSAGE_CONFIRMATION;
    if (pipelines.length > 1) return DELETE_PIPELINE_MULTIPLE_MODAL_MESSAGE_CONFIRMATION;
    return DELETE_PIPELINE_PROD_MODAL_MESSAGE_CONFIRMATION;
  };

  const isOkButtonDisabled = () => {
    if (productionPipelinesToDelete.length > 0)
      return productionPipelinesToDelete.some((pipeline) => pipeline.inputValue !== pipeline.name);
    return false;
  };

  const isOkButtonDanger = () => {
    if (
      undeployingPipeline &&
      pipelines.length === 1 &&
      pipelines[0].service_level !== PipelineServiceLevel.PRODUCTION
    )
      return false;
    return true;
  };

  const getModalContent = () => {
    return (
      <>
        <h5>{getModalTitle()}</h5>
        {pipelines.length === 1 &&
          pipelines[0].service_level === PipelineServiceLevel.PRODUCTION &&
          getModalMessageForProductionPipelines()}
        <div
          className={`${
            productionPipelinesToDelete.length > 0 ? styles.confirmationModal_marginList : ''
          }`}
        >
          {interpolateString(getModalMainMessage(), {
            pipelineName: (
              <>
                {pipelines.map((pipeline) => (
                  <span
                    key={pipeline.pipeline_id}
                    className={styles.confirmationModal_pipelineName}
                  >
                    {pipeline.name}
                  </span>
                ))}
              </>
            ),
            pipelineCount: <span>{pipelines?.length}</span>,
            pipelineList: (
              <ul className={styles.confirmationModal_list}>
                {pipelines?.map((pipeline) => (
                  <li key={pipeline.pipeline_id}>
                    <span className={styles.confirmationModal_pipelineName}>{pipeline.name}</span>
                  </li>
                ))}
              </ul>
            ),
          })}
        </div>
        {productionPipelinesToDelete.map((pipeline) => (
          <div key={pipeline.name} className={styles.confirmationModal_prodList}>
            <span>
              {interpolateString(getModalConfirmationMessageForProductionPipelines(), {
                pipelineName: (
                  <span className={styles.confirmationModal_pipelineName}>{pipeline.name}</span>
                ),
              })}
            </span>
            <Input
              value={pipeline.inputValue}
              onChange={(e) => {
                const updatedPipelines = productionPipelinesToDelete.map((prodPipeline) => {
                  if (prodPipeline.pipeline_id === pipeline.pipeline_id) {
                    return { ...prodPipeline, inputValue: e.target.value };
                  }
                  return prodPipeline;
                });
                setProductionPipelinesToDelete(updatedPipelines);
              }}
              className={styles.confirmationModal_pipelineNameInput}
              placeholder={PIPELINE_NAME_LABEL}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <Modal
      open={open}
      onOk={() => onOkHandler()}
      onCancel={() => onCancelHandler()}
      okText={okText}
      cancelText={cancelText}
      className={styles.confirmationModal}
      closeIcon={false}
      centered
      okButtonProps={{
        danger: isOkButtonDanger(),
        disabled: isOkButtonDisabled(),
        'data-testid': 'undeployDeletePipeline_okButton',
      }}
    >
      <div className={styles.confirmationModal_content}>
        <div className={styles.confirmationModal_content_icon}>
          <ExclamationCircleOutlined />
        </div>
        <div>{getModalContent()}</div>
      </div>
    </Modal>
  );
};

export default PipelineConfirmationModal;
