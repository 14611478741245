import { http } from './base-http';

export interface ISecretsData {
  limit?: number;
  page_number?: number;
}

export interface ISecretPayload {
  name: string;
  secret: string;
}

export const getOrgSecretsApi = (params?: ISecretsData) => {
  const mappedParams = {
    ...(params?.limit && { limit: params?.limit }),
    ...(params?.page_number && { page_number: params?.page_number }),
  };
  return http.get(`/api/v2/secrets`, { params: mappedParams });
};

export const createOrgSecretApi = (payload: ISecretPayload) => {
  return http.post(`/api/v2/secrets`, payload);
};

export const deleteOrgSecretApi = (secretId: string) => {
  return http.delete(`/api/v2/secrets/${secretId}`);
};
