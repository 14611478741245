import React, { useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Input, Select, Tooltip } from 'antd';
import { debounce } from 'lodash';
import { SORTING_PLACEHOLDER, SORTING_SELECT_LABEL } from '@constants/data-page';
import {
  FiltersProp,
  FilterType,
  SelectedFilterItem,
  SelectedFilters,
} from '@constants/data-table';
import MultiSelectFilter from './multiSelectFilter/MultiSelectFilter';
import RangeFilter from './rangeFilter/RangeFilter';
import SelectFilter from './selectFilter/SelectFilter';
import ToggleFilter from './toggleFilter/ToggleFilter';
import styles from './dataHeaderActions.module.scss';

const SEARCH_DEBOUNCE_DELAY = 650;

interface IHeaderActions {
  columnOptions?: boolean;
  filters?: FiltersProp;
  filterValues: SelectedFilters;
  loading?: boolean;
  onSearch: (value: string) => void;
  onFilterSelectChange: (filterKey: string, items: SelectedFilterItem[]) => void;
  onSortSelectChange?: (value: string) => void;
  primaryAction?: {
    label: string;
    onClick: () => void;
    secondary?: boolean;
    icon?: React.ReactNode;
    disabled?: boolean;
    testid?: string;
  };
  filtersAction?: {
    label?: string;
    onClick: () => void;
    filtersApplied?: boolean;
    secondary?: boolean;
    icon?: React.ReactNode;
    tooltipMessage?: string;
    disabled?: boolean;
    testid?: string;
  };
  onClickShowMoreFilters?: () => void;
  searchAvailable?: boolean;
  searchPlaceholder?: string;
  sorting?: {
    selectedValue?: string;
    options: {
      key: string;
      label: string;
    }[];
    allowClear?: boolean;
  };
}

const DataHeaderActions = (props: IHeaderActions) => {
  const {
    columnOptions,
    filters,
    filterValues,
    loading,
    onSearch,
    onFilterSelectChange,
    onSortSelectChange,
    primaryAction,
    filtersAction,
    onClickShowMoreFilters,
    searchAvailable,
    searchPlaceholder,
    sorting,
  } = props;

  const { Search } = Input;
  const { Option } = Select;

  const debouncedSearch = useMemo(
    () => debounce((value) => onSearch(value), SEARCH_DEBOUNCE_DELAY),
    [],
  );

  const renderFilters = () => {
    if (!filters?.length) return null;

    return filters.map((filter) => {
      if (filter.type === FilterType.TOGGLE)
        return (
          <ToggleFilter
            key={filter.key}
            filterKey={filter.key}
            title={filter.title}
            option={filter.option}
            filterValues={filterValues}
            style={filter.style}
            onChange={(items: SelectedFilterItem[]) => {
              onFilterSelectChange(filter.key, items);
            }}
          />
        );

      if (filter.type === FilterType.RANGE)
        return (
          <RangeFilter
            key={filter.key}
            filterKey={filter.key}
            title={filter.title}
            rangeBoundaries={filter.rangeBoundaries}
            rangeStep={filter.rangeStep}
            option={filter.option}
            filterValues={filterValues}
            showClearButton={!!filterValues[filter.key]?.length}
            style={filter.style}
            onChange={(items: SelectedFilterItem[]) => {
              onFilterSelectChange(filter.key, items);
            }}
          />
        );

      if (filter.type === FilterType.SELECT)
        return (
          <SelectFilter
            searchable={filter.search}
            key={filter.key}
            filterKey={filter.key}
            title={filter.title}
            options={filter.options}
            values={filterValues}
            style={filter.style}
            listHeight={filter.listHeight}
            onChange={(items: SelectedFilterItem[]) => {
              onFilterSelectChange(filter.key, items);
            }}
          />
        );

      return (
        <MultiSelectFilter
          key={filter.key}
          filterKey={filter.key}
          title={filter.title}
          options={filter.options}
          values={filterValues}
          style={filter.style}
          onChange={(items: SelectedFilterItem[]) => {
            onFilterSelectChange(filter.key, items);
          }}
          onClear={() => {
            onFilterSelectChange(filter.key, []);
          }}
        />
      );
    });
  };

  return (
    <div
      className={styles.headerActions}
      style={{
        justifyContent: searchAvailable || columnOptions ? 'space-between' : 'flex-end',
      }}
    >
      <div className={styles.headerActions_leftSection}>
        {searchAvailable && (
          <Search
            className={styles.fileSearch}
            placeholder={searchPlaceholder}
            allowClear
            onSearch={(value) => onSearch(value)}
            onChange={(event) => debouncedSearch(event.currentTarget.value)}
            onPressEnter={(event) => debouncedSearch(event.currentTarget.value)}
            disabled={loading}
            data-testid="templateName_searchInput"
          />
        )}
        {filters?.length ? (
          <div className={styles.headerActions_leftSection_filters}>{renderFilters()}</div>
        ) : null}
        {filtersAction && (
          <Tooltip title={filtersAction.tooltipMessage}>
            <Badge dot={filtersAction.filtersApplied}>
              <Button
                type={filtersAction.secondary ? 'default' : 'primary'}
                onClick={onClickShowMoreFilters}
                icon={filtersAction.icon}
                disabled={filtersAction.disabled}
                data-testid={filtersAction.testid}
                className={styles.headerActions_leftSection_filtersAction}
              >
                {filtersAction.label}
              </Button>
            </Badge>
          </Tooltip>
        )}
      </div>
      <div className={styles.headerActions_rightSection}>
        {sorting && (
          <div>
            <span className={styles.sortSelect_label}>{SORTING_SELECT_LABEL}</span>
            <Select
              placeholder={SORTING_PLACEHOLDER}
              value={
                sorting.selectedValue && sorting.selectedValue.length > 0
                  ? sorting.selectedValue
                  : undefined
              }
              placement="bottomRight"
              popupMatchSelectWidth={false}
              onChange={onSortSelectChange}
              className={styles.sortSelect_select}
              allowClear={sorting.allowClear}
            >
              {sorting!.options.map((option) => (
                <Option key={option.key} value={option.key}>
                  {option.label}
                </Option>
              ))}
            </Select>
            {primaryAction && <Divider type="vertical" className={styles.verticalDivider} />}
          </div>
        )}
        {primaryAction && (
          <Button
            type={primaryAction.secondary ? 'default' : 'primary'}
            onClick={primaryAction.onClick}
            icon={primaryAction.icon ? primaryAction.icon : <PlusOutlined />}
            disabled={primaryAction.disabled}
            data-testid={primaryAction.testid}
          >
            {primaryAction.label}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DataHeaderActions;
