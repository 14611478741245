import React from 'react';
import { Spin } from 'antd';

import styles from './loadingIndicator.module.scss';

const LoadingIndicator = () => (
  <div className={styles.loadingIndicator}>
    <Spin size="large" />
  </div>
);

export default LoadingIndicator;
