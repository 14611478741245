import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import jwt from 'jwt-decode';
import {
  createExternalUserApi,
  getSharedJobResultApi,
  getSharedPrototypeApi,
} from '@api/external/prototype';
import { DEFAULT_WORKSPACE } from '@constants/workspace';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData, SharedAssetType } from '@redux/types/types';
import styles from './protectedRoute.module.scss';

interface ProtectedRouteProps {
  Component: React.ComponentType;
  type?: SharedAssetType;
}

const ProtectedRoute = ({ Component, type }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('share_token');
  const [authenticated, setAuthenticated] = useState(false);
  const { organization }: IUserData = useSelector(userSelector);

  const getSharedPrototype = async (
    workspaceName: string,
    workspaceID: string,
    shareID: string,
    shareToken: string,
  ) => {
    try {
      const response =
        type === SharedAssetType.JOB
          ? await getSharedJobResultApi(workspaceID, shareID, shareToken)
          : await getSharedPrototypeApi(workspaceName, shareID, shareToken);
      if (response.data) {
        return response.data;
      }
      return response;
    } catch (error) {
      setAuthenticated(false);
      navigate('/not-found-prototype');
      return false;
    }
  };

  const onAuthenticated = async (
    workspaceID: string,
    workspaceName: string,
    shareID: string,
    shareToken: string,
  ) => {
    const { is_revoked: isRevoked } = await getSharedPrototype(
      workspaceName,
      workspaceID,
      shareID,
      shareToken!,
    );
    if (isRevoked) navigate('/not-found-prototype');
    setAuthenticated(true);
  };

  const createExternalUser = async (
    workspaceID: string,
    workspaceName: string,
    shareID: string,
    shareToken: string,
  ) => {
    const prototype = await getSharedPrototype(workspaceName, workspaceID, shareID, token!);
    if (!prototype) return;

    const workspace = type === SharedAssetType.JOB ? prototype.workspace_name : workspaceName;

    const users = JSON.parse(localStorage.getItem('dcExtusers') || '{}');
    const persistedUser = users[workspaceID];
    try {
      // TODO: Get workspace name from shared job info
      const response = await createExternalUserApi(
        workspace,
        shareToken,
        persistedUser ? persistedUser.user_id : undefined,
      );
      if (response.data) {
        localStorage.setItem(
          'dcExtusers',
          JSON.stringify({
            ...users,
            [workspaceID]: response.data,
          }),
        );
        sessionStorage.setItem(
          'dcExtcurrent',
          JSON.stringify({
            workspace: workspaceID,
            ...response.data,
          }),
        );
        setAuthenticated(true);
      }
    } catch (error) {
      navigate('/error');
    }
  };

  useEffect(() => {
    if (!token) {
      navigate('/not-found-prototype');
      return;
    }

    try {
      const {
        share_id: shareID,
        workspace_name: workspace,
        workspace_id: workspaceID,
        organization_id: organizationID,
        shared_job_info: sharedJobInfo,
      } = jwt(token) as {
        share_id: string;
        workspace_name: string;
        workspace_id: string;
        organization_id: string;
        shared_job_info: any;
      };

      const workspaceName = workspace || DEFAULT_WORKSPACE;

      if ((isAuthenticated && organization === organizationID) || type === SharedAssetType.JOB)
        onAuthenticated(
          workspaceID || sharedJobInfo.workspace_id,
          workspaceName,
          shareID || sharedJobInfo.shared_job_id,
          token!,
        );
      else
        createExternalUser(
          workspaceID || sharedJobInfo.workspace_id,
          workspaceName,
          shareID || sharedJobInfo.shared_job_id,
          token!,
        );
    } catch {
      navigate('/not-found-prototype');
    }
  }, []);

  const getLoadingIndicator = () => (
    <div className={styles.protectedRoute_loading_wrapper}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} />
    </div>
  );

  return authenticated ? <Component /> : getLoadingIndicator();
};

export default ProtectedRoute;
