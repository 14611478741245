export const sortCollectionAlphabeticallyBy = <T extends Record<string, any>>(
  collection: T[],
  key: string,
): T[] => {
  if (!collection?.length) return [];
  return [...collection].sort((itemA, itemB) => {
    if (typeof itemA[key] !== 'string' || typeof itemB[key] !== 'string') return -1;
    return itemA[key].localeCompare(itemB[key], undefined, { sensitivity: 'base' });
  });
};
