import produce from 'immer';
import {
  FULFILLED,
  GET_REQUEST_HISTORY,
  GET_SEARCH_COUNT,
  GET_WORKSPACE_STATS,
  PENDING,
  REJECTED,
} from '@redux/types/types';
import {
  IHistoryData,
  ISearchCountData,
  IWorkspaceStatisticData,
  transformHistory,
  transformSearchCount,
  transformStatistic,
} from '@src/utils/transformation/dashboard';

interface IInitialStateProps {
  workspaceStats: IWorkspaceStatisticData;
  searchCount: ISearchCountData[];
  requestHistory: IHistoryData[];
  loadingRequestHistory: boolean;
}

export const initialState: IInitialStateProps = {
  workspaceStats: {
    fileCount: 0,
    documentCount: 0,
    averageResponseTime: 0,
    searchRequestCount: 0,
  },
  searchCount: [],
  requestHistory: [],
  loadingRequestHistory: false,
};

// TODO: Error handling BASIC_REQUEST_ERROR, STATISTIC_REQUEST_ERROR etc.

function dashboardReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case `${GET_WORKSPACE_STATS}/${FULFILLED}`:
        localDraft.workspaceStats = transformStatistic(action.payload);
        break;
      case `${GET_SEARCH_COUNT}/${FULFILLED}`:
        localDraft.searchCount = transformSearchCount(action.payload);
        break;
      case `${GET_REQUEST_HISTORY}/${PENDING}`:
        localDraft.loadingRequestHistory = true;
        break;
      case `${GET_REQUEST_HISTORY}/${FULFILLED}`:
        localDraft.loadingRequestHistory = false;
        localDraft.requestHistory = transformHistory(action.payload);
        break;
      case `${GET_REQUEST_HISTORY}/${REJECTED}`:
        localDraft.loadingRequestHistory = false;
        break;
      default:
        break;
    }
  });
}

export default dashboardReducer;
