import { createSelector } from 'reselect';

export const uploadStoreSelector = (state: any) => state.uploadStore;

export const uploadDataSelector = createSelector(
  uploadStoreSelector,
  (uploadStore) => uploadStore.uploadData,
);

export const isUploadingStartedSelector = createSelector(
  uploadStoreSelector,
  (uploadStore) => uploadStore.isUploadingStarted,
);

export const isUploadingCompletedSelector = createSelector(
  uploadStoreSelector,
  (uploadStore) => uploadStore.isUploadingCompleted,
);
