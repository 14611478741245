import { getWorkspace } from '@utils/store';
import { http } from './base-http';

export const filePreviewApi = (workspace: string, payload: any) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return http.post(`/api/v1/workspaces/${workspace}/file-preview`, payload, { headers });
};

export const uploadFilesApi = (payload: any, config: any, workspace: string = getWorkspace()) => {
  return http.post(`/api/v1/workspaces/${workspace}/files`, payload, config);
};
