import React from 'react';
import { DOCUMENT_METADATA_TITLE } from '@constants/search';
import styles from './searchDocumentMetadata.module.scss';

interface ISearchDocumentMetadataProps {
  metadata: Record<string, unknown>;
}

const SearchDocumentMetadata = ({ metadata }: ISearchDocumentMetadataProps) => {
  return (
    <div className={styles.searchDocumentMetadata}>
      <div className={styles.searchDocumentMetadata_header}>{DOCUMENT_METADATA_TITLE}</div>
      <div className={styles.searchDocumentMetadata_body}>
        <pre>{JSON.stringify(metadata, null, 2)}</pre>
      </div>
    </div>
  );
};

export default SearchDocumentMetadata;
