import { createLink, interpolateString } from '@utils/string';
import {
  ChartExportType,
  IUsageMetricConst,
  IUsageMetrics,
  PipelineUsageServiceLevel,
} from '@redux/types/types';
import { DOCS_URL } from './constant';

export const METERING_PAGE_HEADER = 'Usage';
export const NO_LIMIT_LABEL = 'Unlimited';
export const PIPELINE_USAGE_CYCLE_HEADER = 'Pipelines in current usage cycle';
export const PROD_HOURS = 'Production hours';
export const DEV_HOURS = 'Development hours';
export const PIPELINE_HOURS = 'Pipeline Hours';

export const PIPELINES_TABLE_SEARCH_PLACEHOLDER = 'Find pipelines';
export const PIPELINES_TABLE_COLUMNS_TITLE = {
  NAME: 'Name',
  DEV_HOURS: 'Dev Hours',
  PROD_HOURS: 'Prod Hours',
  STORAGE_UNITS: 'Storage Units',
  WORKSPACE_NAME: 'Workspace',
  DOCUMENTS: 'Documents',
  SERVICE_LEVEL: 'Service Level',
};

export const ORGANIZATION_PLAN_INFO = {
  label: 'Your organization',
  limitLabel: 'Usage cycle (%usageCycle%)',
  targetLabel: `%days% Days left`,
};

export const PIPELINE_USAGE_DOCS_URL = `${DOCS_URL}docs/understand-pipeline-usage`;

export const USAGE_METRIC_MAP: Record<keyof IUsageMetrics, IUsageMetricConst> = {
  document_storage_units: {
    label: 'Storage Units',
    tooltip: interpolateString(
      "Storage units measure your usage of vector storage. It's a reflection of the number of documents your files are chunked into and the dimensionality of your embedding model. To learn more, see %link%.",
      {
        link: createLink(PIPELINE_USAGE_DOCS_URL, 'documentation'),
      },
    ),
    limitLabel: 'Cycle limit',
  },
  development_hours: {
    label: 'Development hours',
    tooltip:
      'Total hours your development pipelines were deployed in this usage cycle, including hours from deleted pipelines and experiments.',
    limitLabel: 'Cycle limit',
    metricUnit: 'h',
  },
  production_hours: {
    label: 'Production hours',
    tooltip:
      'Total hours your production pipelines were deployed in this usage cycle, including hours from deleted pipelines.',
    limitLabel: 'Cycle limit',
    metricUnit: 'h',
  },
  development_pipelines: {
    label: 'Development pipelines',
    tooltip: interpolateString(
      'Pipelines that are currently deployed but not used in production. To learn more, see %link%.',
      {
        link: createLink(PIPELINE_USAGE_DOCS_URL, 'documentation'),
      },
    ),
    limitLabel: 'Plan limit',
  },
  production_pipelines: {
    label: 'Production pipelines',
    tooltip: interpolateString(
      'Pipelines that have been set to a production service level and are currently deployed. To learn more, see %link%.',
      {
        link: createLink(PIPELINE_USAGE_DOCS_URL, 'documentation'),
      },
    ),
    limitLabel: 'Plan limit',
  },
};

export const EXPORT_CHART_OPTIONS = [
  {
    label: 'Export as PNG',
    key: ChartExportType.PNG,
  },
  {
    label: 'Export as JPEG',
    key: ChartExportType.JPEG,
  },
  {
    label: 'Export as PDF',
    key: ChartExportType.PDF,
  },
];

export const PIPELINE_USAGE_SERVICE_LEVEL_TAGS_LABELS = {
  [PipelineUsageServiceLevel.PRODUCTION]: 'Prod',
  [PipelineUsageServiceLevel.DEVELOPMENT]: 'Dev',
  [PipelineUsageServiceLevel.DRAFT]: 'Draft',
};

// Filtering pipeline usage metrics

export const PIPELINE_USAGE_METRICS_FILTERS_SERVICE_LEVEL_LABEL = 'Service Level';
export const PIPELINE_USAGE_METRICS_FILTERS_FIELD_KEYS = {
  SERVICE_LEVEL: 'service_level',
};
export const PIPELINE_USAGE_METRICS_SERVICE_LEVEL_FILTERS_LABELS = {
  DRAFT: 'Draft',
  PRODUCTION: 'Production',
  DEVELOPMENT: 'Development',
};

// Sorting pipeline usage metrics

export const DEFAULT_SORTING_KEY = 'production_hours_desc';
export const PIPELINE_USAGE_METRICS_SORTING_OPTIONS = [
  { key: 'production_hours_asc', label: 'Prod hours ascending' },
  { key: 'production_hours_desc', label: 'Prod hours descending' },
  { key: 'development_hours_asc', label: 'Dev hours ascending' },
  { key: 'development_hours_desc', label: 'Dev hours descending' },
  { key: 'document_storage_units_asc', label: 'Storage units ascending' },
  { key: 'document_storage_units_desc', label: 'Storage units descending' },
];
export const SORTING_PARAMS_BY_KEY = {
  production_hours_asc: {
    field: 'production_hours',
    order: 'ASC',
  },
  production_hours_desc: {
    field: 'production_hours',
    order: 'DESC',
  },
  development_hours_asc: {
    field: 'development_hours',
    order: 'ASC',
  },
  development_hours_desc: {
    field: 'development_hours',
    order: 'DESC',
  },
  document_storage_units_asc: {
    field: 'document_storage_units',
    order: 'ASC',
  },
  document_storage_units_desc: {
    field: 'document_storage_units',
    order: 'DESC',
  },
};

export const UPGRADE_PAGE_SECTION = {
  TITLE: 'Usage',
  DESCRIPTION: [
    {
      paragraph:
        'Track your organization’s usage at a glance. View total pipeline hours, monitor storage usage, and check the pipelines running in the current cycle. Keep an eye on your usage to manage resources efficiently.',
    },
    {
      paragraph: 'For more details, see the %documentationLink%.',
    },
  ],
};
