import produce from 'immer';
import { removeTemplatesTagPrefixes } from '@utils/templates';
import { FiltersProp, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import { DEFAULT_SORTING_KEY } from '@constants/pipeline-templates';
import {
  DeepsetCloudVersion,
  FULFILLED,
  GET_MORE_LIKE_THIS_PIPELINE_TEMPLATES,
  GET_PIPELINE_TEMPLATE_BY_NAME,
  GET_PIPELINE_TEMPLATES,
  GET_PIPELINE_TEMPLATES_BY_CATEGORY,
  GET_PIPELINE_TEMPLATES_FILTER_TAGS,
  IAPIPaginationData,
  IPipelineTemplate,
  ITag,
  PENDING,
  PipelineTemplatesCategory,
  REJECTED,
  RESET_MORE_TEMPLATES_LIKE_THIS,
  RESET_SELECTED_PIPELINE_TEMPLATES_FILTERS,
  RESET_SELECTED_TEMPLATE,
  SELECT_PIPELINE_TEMPLATES_CATEGORY,
  SELECT_PIPELINE_TEMPLATES_FILTERS,
  SELECT_PIPELINE_TEMPLATES_SORT_VALUE,
  SET_ACTIVE_VERSION_TAB_TEMPLATES_LANDING_PAGE,
  SET_SELECTED_TEMPLATE,
  SET_TEMPLATES_SEARCH_VALUE,
} from '@redux/types/types';

interface IInitialStateProps {
  pipelineTemplates: IAPIPaginationData<IPipelineTemplate[]>;
  pipelineTemplatesByCategory: Record<
    PipelineTemplatesCategory,
    IAPIPaginationData<IPipelineTemplate[]>
  >;
  pipelineTemplatesStatus: StatusCodes;
  selectedCategory: PipelineTemplatesCategory | null;
  selectedTemplate: IPipelineTemplate | null;
  moreTemplatesLikeThis: IPipelineTemplate[];
  moreTemplatesLikeThisStatus: StatusCodes;
  pipelineTemplatesSearchValue: string | null;
  pipelineTemplatesFiltersValues: Record<string, FiltersProp | []>;
  selectedPipelineTemplateFilters: SelectedFilters;
  pipelineTemplatesSortValue: string;
  activeVersionTabTemplatesLandingPage: DeepsetCloudVersion;
}

export const initialState: IInitialStateProps = {
  pipelineTemplates: { data: [], has_more: false, total: 0 },
  pipelineTemplatesByCategory: {
    recommended: {
      data: [],
      has_more: false,
      total: 0,
    },
    documentSearch: {
      data: [],
      has_more: false,
      total: 0,
    },
    basicQA: {
      data: [],
      has_more: false,
      total: 0,
    },
    advancedQA: {
      data: [],
      has_more: false,
      total: 0,
    },
    visualQA: {
      data: [],
      has_more: false,
      total: 0,
    },
    conversational: {
      data: [],
      has_more: false,
      total: 0,
    },
    textAnalysis: {
      data: [],
      has_more: false,
      total: 0,
    },
    textToSQL: {
      data: [],
      has_more: false,
      total: 0,
    },
  },
  pipelineTemplatesStatus: StatusCodes.IDLE,
  selectedCategory: null,
  selectedTemplate: null,
  moreTemplatesLikeThis: [],
  moreTemplatesLikeThisStatus: StatusCodes.IDLE,
  pipelineTemplatesSearchValue: null,
  pipelineTemplatesFiltersValues: {
    tags: [],
  },
  selectedPipelineTemplateFilters: {},
  pipelineTemplatesSortValue: DEFAULT_SORTING_KEY,
  activeVersionTabTemplatesLandingPage: DeepsetCloudVersion.V2,
};

const pipelineTemplatesReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_SELECTED_TEMPLATE:
        localDraft.selectedTemplate = null;
        break;
      case RESET_MORE_TEMPLATES_LIKE_THIS:
        localDraft.moreTemplatesLikeThis = [];
        localDraft.moreTemplatesLikeThisStatus = StatusCodes.IDLE;
        break;
      case SET_ACTIVE_VERSION_TAB_TEMPLATES_LANDING_PAGE:
        localDraft.activeVersionTabTemplatesLandingPage = action.payload;
        break;
      case `${SET_SELECTED_TEMPLATE}`:
        localDraft.selectedTemplate = action.payload;
        break;
      case `${SELECT_PIPELINE_TEMPLATES_CATEGORY}`:
        localDraft.selectedCategory = action.payload;
        break;
      case `${RESET_SELECTED_PIPELINE_TEMPLATES_FILTERS}`:
        localDraft.selectedPipelineTemplateFilters = {};
        break;
      case `${GET_PIPELINE_TEMPLATE_BY_NAME}/${FULFILLED}`:
        localDraft.selectedTemplate = action.payload;
        break;
      case `${GET_PIPELINE_TEMPLATES}/${PENDING}`: {
        const { fetchMore } = action.meta.arg;
        if (fetchMore) break;
        localDraft.pipelineTemplatesStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${GET_PIPELINE_TEMPLATES}/${FULFILLED}`: {
        localDraft.pipelineTemplatesStatus = StatusCodes.SUCCESS;
        const { fetchMore } = action.meta.arg;
        if (fetchMore)
          localDraft.pipelineTemplates = {
            ...action.payload,
            data: [...localDraft.pipelineTemplates.data, ...action.payload.data],
          };
        else localDraft.pipelineTemplates = action.payload;
        break;
      }
      case `${GET_PIPELINE_TEMPLATES_BY_CATEGORY}/${PENDING}`:
        localDraft.pipelineTemplatesStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_PIPELINE_TEMPLATES_BY_CATEGORY}/${FULFILLED}`: {
        const {
          category,
          data,
        }: { category: PipelineTemplatesCategory; data: IAPIPaginationData<IPipelineTemplate[]> } =
          action.payload;

        localDraft.pipelineTemplatesStatus = StatusCodes.SUCCESS;
        localDraft.pipelineTemplatesByCategory[category] = data;
        break;
      }
      case `${GET_PIPELINE_TEMPLATES}/${REJECTED}`:
      case `${GET_PIPELINE_TEMPLATES_BY_CATEGORY}/${REJECTED}`:
        localDraft.pipelineTemplatesStatus = StatusCodes.ERROR;
        break;
      case `${GET_MORE_LIKE_THIS_PIPELINE_TEMPLATES}/${FULFILLED}`: {
        localDraft.moreTemplatesLikeThis = action.payload;
        localDraft.moreTemplatesLikeThisStatus = StatusCodes.SUCCESS;
        break;
      }
      case SET_TEMPLATES_SEARCH_VALUE:
        localDraft.pipelineTemplatesSearchValue = action.payload;
        break;

      case SELECT_PIPELINE_TEMPLATES_FILTERS: {
        const { filterKey, items }: { filterKey: string; items: SelectedFilterItem[] } =
          action.payload;
        localDraft.selectedPipelineTemplateFilters[filterKey] = items;
        break;
      }

      case SELECT_PIPELINE_TEMPLATES_SORT_VALUE:
        localDraft.pipelineTemplatesSortValue = action.payload;
        break;

      case `${GET_PIPELINE_TEMPLATES_FILTER_TAGS}/${FULFILLED}`:
        localDraft.pipelineTemplatesFiltersValues = {
          ...localDraft.pipelineTemplatesFiltersValues,
          tags: action.payload.map(({ name: label, tag_id: key }: ITag) => ({
            key,
            label: removeTemplatesTagPrefixes(label),
          })),
        };
        break;
      default:
        break;
    }

    return localDraft;
  });
};

export default pipelineTemplatesReducer;
