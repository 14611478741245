import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { USER_MENU_SETTINGS } from '@constants/user-menu';
import { userSelector } from '@src/redux/selectors/userSelectors';
import { IUserData } from '@src/redux/types/types';
import styles from './TopNavigation.module.scss';

function TopNavigation() {
  const { role }: IUserData = useSelector(userSelector);
  function renderMenu() {
    return (
      <div className={styles.topNavigation_container}>
        {USER_MENU_SETTINGS.filter((item) => item.key !== 'logout').map((item) => {
          const access = item.userAccess?.includes(role);
          return access ? (
            <NavLink
              className={({ isActive }) => `${styles.link} ${isActive && styles.link_active}`}
              to={item.link || ''}
              key={item.key}
            >
              {item.label}
            </NavLink>
          ) : null;
        })}
      </div>
    );
  }

  return <div className={styles.wrapper}>{renderMenu()}</div>;
}

export default TopNavigation;
