import produce from 'immer';
import {
  ADD_NOTIFICATION,
  ADD_SEQUENTIAL_NOTIFICATION,
  INotification,
  RESET_NOTIFICATIONS,
} from '@redux/types/types';

interface IInitialStateProps {
  notifications: INotification[];
  sequentialNotifications: INotification[];
}

export const initialNotificationState: IInitialStateProps = {
  notifications: [],
  sequentialNotifications: [],
};

const notificationReducer = (state = initialNotificationState, action: any) =>
  produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case ADD_NOTIFICATION:
        localDraft.notifications.push(action.payload);
        break;
      case ADD_SEQUENTIAL_NOTIFICATION:
        localDraft.sequentialNotifications.push(action.payload);
        break;
      case RESET_NOTIFICATIONS:
        localDraft.notifications = initialNotificationState.notifications;
        break;
      default:
        break;
    }
    return localDraft;
  });

export default notificationReducer;
