/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import { normalizeErrorMessage } from '@utils/error';
import {
  getODataConjunctionFilterFrom,
  getODataFilterFrom,
  getODataSearchFilterFrom,
} from '@utils/odata';
import { getPipelineUsageMetricsApi, getPricingPlanApi, getUsageMetricsApi } from '@api/metering';
import { SelectedFilters } from '@constants/data-table';
import { DateRangeInterval } from '@constants/enum/common';
import { SORTING_PARAMS_BY_KEY } from '@constants/metering';
import { initialState } from '@redux/rootReducer';
import {
  GET_PIPELINES_USAGE,
  GET_USAGE_HOURS,
  GET_USAGE_LIMITS,
  GET_USAGE_METRICS,
  NotificationType,
  RESET_USAGE_DATA,
  SELECT_PIPELINES_USAGE_SORT_VALUE,
} from '@redux/types/types';
import { addNotification } from './notificationActions';

export const resetUsageData = {
  type: RESET_USAGE_DATA,
};

export const selectPipelinesUsageSortValue = (value: string) => ({
  type: SELECT_PIPELINES_USAGE_SORT_VALUE,
  payload: value,
});

export const getUsageLimits = createAsyncThunk(GET_USAGE_LIMITS, async () => {
  try {
    const { data } = await getPricingPlanApi();
    return data || null;
  } catch {
    return null;
  }
});

export const getUsageMetricValue = createAsyncThunk(
  GET_USAGE_METRICS,
  async (
    {
      dateRange,
    }: {
      dateRange?: {
        from?: Dayjs | null;
        to?: Dayjs | null;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    const params = {
      dateRange,
    };
    try {
      const { data } = await getUsageMetricsApi(params);
      return data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const getPipelineUsageHours = createAsyncThunk(
  GET_USAGE_HOURS,
  async (
    {
      dateRange,
      interval,
    }: {
      dateRange?: {
        from?: Dayjs | null;
        to?: Dayjs | null;
      };
      interval?: DateRangeInterval | null;
    },
    { dispatch, rejectWithValue },
  ) => {
    const params = {
      dateRange,
      interval,
    };
    try {
      const { data } = await getUsageMetricsApi(params);
      return { data, dateRange };
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const getPipelineUsageMetrics = createAsyncThunk(
  GET_PIPELINES_USAGE,
  async (
    {
      dateRange,
      limit,
      pageNumber,
      searchValue,
      sortValue,
      filterValues,
    }: {
      limit?: number;
      pageNumber?: number;
      dateRange?: {
        from?: Dayjs | null;
        to?: Dayjs | null;
      };
      searchValue?: string;
      sortValue?: string;
      filterValues?: SelectedFilters;
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { pipelineUsageMetricsSortValue: defaultSortValue } = (getState() as typeof initialState)
      .usageStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof SORTING_PARAMS_BY_KEY;

    dispatch(selectPipelinesUsageSortValue(sortingKey));

    const { field, order } = SORTING_PARAMS_BY_KEY[sortingKey] || {};
    const searchFilter = searchValue && getODataSearchFilterFrom('pipeline_name', searchValue);
    const filters = filterValues && getODataFilterFrom(filterValues);

    const params = {
      limit,
      pageNumber,
      dateRange,
      filter: getODataConjunctionFilterFrom(searchFilter, filters),
      field,
      order,
    };

    try {
      const { data } = await getPipelineUsageMetricsApi(params);
      return data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);
