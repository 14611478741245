import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { downloadBlob, openFileInNewTab } from '@utils/file';
import {
  EventControlComponent,
  EventControlSearchResultElement,
  UserEventType,
} from '@constants/event';
import { getFileContent, selectFileDocument } from '@redux/actions/fileActions';
import { SearchFileAction } from '@redux/types/types';
import { useUserEvent } from './useUserEvent';

const useSearchFileActions = ({
  resultId,
  isExternal,
}: {
  resultId: string;
  isExternal?: boolean;
}) => {
  const dispatch = useDispatch();
  const [isFileActionTakingPlace, setIsFileActionTakingPlace] = useState<Record<string, boolean>>(
    {},
  );
  const { trackUserEvent } = useUserEvent({ result_id: resultId });

  const downloadFile = async (fileId: string, fileName: string) => {
    const contentResultAction = await dispatch(getFileContent({ fileId, isExternal }));
    const content = unwrapResult(contentResultAction as any);
    if (!content) return;

    downloadBlob(fileName, content);
  };

  const onDownloadLink = async (fileId: string, fileName: string) => {
    setIsFileActionTakingPlace({ ...isFileActionTakingPlace, [fileId]: true });
    await downloadFile(fileId, fileName);
    setIsFileActionTakingPlace({ ...isFileActionTakingPlace, [fileId]: false });
  };

  const onFilePreviewLink = async (fileId: string, name: string) => {
    setIsFileActionTakingPlace({ ...isFileActionTakingPlace, [fileId]: true });
    await dispatch(
      selectFileDocument({
        fileName: name,
        fileId,
        resultId,
        contents: [],
        isDocumentPreview: false,
      }),
    );
    setIsFileActionTakingPlace({ ...isFileActionTakingPlace, [fileId]: false });

    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.SEARCH_RESULT}/${EventControlSearchResultElement.VIEW_FILE_SOURCE}`,
      properties: {
        file_id: fileId,
      },
    });
  };

  const onFileOpen = async (fileId: string) => {
    setIsFileActionTakingPlace({ ...isFileActionTakingPlace, [fileId]: true });
    const contentResultAction = await dispatch(getFileContent({ fileId, isExternal }));
    const content = unwrapResult(contentResultAction as any);
    if (content) {
      openFileInNewTab(content);
    }
    setIsFileActionTakingPlace({ ...isFileActionTakingPlace, [fileId]: false });
  };

  const onDocumentPreview = async (fileId: string, fileName: string) => {
    setIsFileActionTakingPlace({ ...isFileActionTakingPlace, [fileId]: true });
    await dispatch(
      selectFileDocument({
        fileName,
        fileId,
        resultId,
        contents: [],
        isDocumentPreview: true,
      }),
    );
    setIsFileActionTakingPlace({ ...isFileActionTakingPlace, [fileId]: false });

    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.SEARCH_RESULT}/${EventControlSearchResultElement.VIEW_DOC_SOURCE}`,
      properties: {
        file_id: fileId,
      },
    });
  };

  const onFileDropdownItemClick = ({
    key,
    id,
    name,
  }: {
    key: SearchFileAction;
    id: string;
    name: string;
  }) => {
    switch (key) {
      case SearchFileAction.PREVIEW:
        onFilePreviewLink(id, name);
        break;
      case SearchFileAction.OPEN:
        onFileOpen(id);
        break;
      case SearchFileAction.DOWNLOAD:
        onDownloadLink(id, name);
        break;
      case SearchFileAction.DOCUMENT:
        onDocumentPreview(id, name);
        break;
      default:
        break;
    }
  };

  return { onFileDropdownItemClick, onDocumentPreview, isFileActionTakingPlace };
};

export default useSearchFileActions;
