import React, { useCallback, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';

const DEFAULT_ANIMATION_DURATION = 1000;

type IButtonWithClickConfirmationAnimationProps = {
  onClick: () => void;
  confirmationText?: string;
  loadingText?: string;
  confirmationIcon?: React.ReactNode;
} & ButtonProps;

const ButtonWithClickConfirmationAnimation = ({
  icon,
  loading,
  disabled,
  type,
  onClick,
  children,
  loadingText,
  confirmationText,
  confirmationIcon,
  ...params
}: IButtonWithClickConfirmationAnimationProps) => {
  const [clicked, setClicked] = useState(false);
  const ConfirmationIcon = confirmationIcon || <CheckOutlined />;

  const handleClick = () => {
    setClicked(true);
    onClick();
    setTimeout(() => {
      setClicked(false);
    }, DEFAULT_ANIMATION_DURATION);
  };

  const getButtonContent = useCallback(() => {
    if (loading && loadingText) return loadingText;
    if (clicked) return confirmationText;

    return children;
  }, [!clicked && children, !loading]);

  return (
    <Button
      icon={clicked ? ConfirmationIcon : icon}
      disabled={disabled}
      loading={loading}
      type={type}
      onClick={handleClick}
      {...params}
    >
      {getButtonContent()}
    </Button>
  );
};

export default ButtonWithClickConfirmationAnimation;
