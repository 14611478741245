import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyOutlined, MoreOutlined } from '@ant-design/icons';
import { Alert, message as AntdMessage, Button, Dropdown, Tabs, theme } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { get } from 'lodash';
import { getParsedMetaReferences } from '@utils/search';
import useSearchFileActions from '@hooks/useSearchFileActions';
import { useUserEvent } from '@hooks/useUserEvent';
import {
  EventControlComponent,
  EventControlSearchResultElement,
  UserEventType,
} from '@constants/event';
import {
  ANSWER_RESULT_ERROR_ALERT_TITLE,
  COPY_PROMPT_MESSAGE,
  HIDE_PROMPT_BUTTON_LABEL,
  multiFilesDropdownMenuItems,
  REPEAT_QUERY_BUTTON_LABEL,
  SAVE_AS_PROMPT_TEMPLATE_BUTTON_LABEL,
  SHOW_PROMPT_BUTTON_LABEL,
} from '@constants/search';
import { openReferenceDrawer } from '@redux/actions/searchActions';
import { userSelector } from '@redux/selectors/userSelectors';
import {
  IBaseSearchResultComponentProps,
  IMetaAnnotations,
  IPipelineQueryPromptTemplatesParams,
  IReferencesMetaAnnotation,
  ISearchResultAnswer,
  ISearchResultDocument,
  IUserData,
} from '@redux/types/types';
import DcLogoAvatar from '@components/common/dcLogoAvatar/DcLogoAvatar';
import UserAvatar from '@components/common/userAvatar/userAvatar';
import SearchResultMultiFileSource from '@components/search/molecules/files/SearchResultMultiFileSource';
import FormattedResultText from '@components/search/molecules/formattedResultText/FormattedResultText';
import styles from './resultContainer.module.scss';
import SearchResultActions from '../resultActions/SearchResultActions';

const { useToken } = theme;

enum ResultMoreActionsKey {
  REPEAT_QUERY = 'repeatQuery',
  SAVE_PROMPT_TEMPLATE = 'savePromptTemplate',
}

interface IPromptExplorerResultProps extends IBaseSearchResultComponentProps<ISearchResultAnswer> {
  promptTemplates?: IPipelineQueryPromptTemplatesParams;
  query?: string;
  pipelineId: string;
  documentsGroupedByFileId?: Record<string, ISearchResultDocument[]>;
  documents?: ISearchResultDocument[];
  displayMoreOptions: boolean;
  displayTypingEffect: boolean;
  displayReferencesPopover?: boolean;
  searching: boolean;
  moreOptionsDropdownMenuItems: MenuProps['items'];
  onMoreOptionsDropdownMenuItemClick: (info: { key: string }) => void;
  sourcesCollapsed?: boolean;
  setSourcesCollapsed: (value: boolean) => void;
  isExternal?: boolean;
  onRepeatQueryOptionClick?: (query: string) => void;
  onSaveQueryPromptTemplateOptionClick?: (template: string) => void;
  errorMessage?: string;
  styleCustomization?: {
    answerContainer?: string;
  };
}

const PromptExplorerResult = ({
  result,
  queryId,
  pipelineId,
  pipelineName,
  displayFeedbackOptions,
  displayFileSources,
  displayFileOptions,
  displayMoreOptions,
  displayTypingEffect,
  displayReferencesPopover,
  promptTemplates = {},
  query,
  isExternal,
  searching,
  sourcesCollapsed = true,
  setSourcesCollapsed,
  documentsGroupedByFileId,
  documents,
  moreOptionsDropdownMenuItems,
  onMoreOptionsDropdownMenuItemClick,
  onRepeatQueryOptionClick,
  onSaveQueryPromptTemplateOptionClick,
  errorMessage,
  styleCustomization,
}: IPromptExplorerResultProps) => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const { firstName, lastName }: IUserData = useSelector(userSelector);
  const [promptOpened, setPromptOpened] = useState(false);
  const [messageApi, contextHolder] = AntdMessage.useMessage();
  const [selectedPromptTemplateNode, setSelectedPromptTemplateNode] = useState<string | undefined>(
    Object.keys(promptTemplates || {})[0],
  );

  const { result_id: resultId, files, answer, meta } = result || {};
  const [firstFile] = files || [];

  const { trackUserEvent } = useUserEvent({ result_id: resultId });
  const { onFileDropdownItemClick, isFileActionTakingPlace } = useSearchFileActions({
    resultId,
    isExternal,
  });

  const onQueryMoreOptionsDropdownMenuItemClick = ({ key }: { key: string }) => {
    if (key === ResultMoreActionsKey.REPEAT_QUERY && onRepeatQueryOptionClick)
      onRepeatQueryOptionClick(query!);
    if (
      key === ResultMoreActionsKey.SAVE_PROMPT_TEMPLATE &&
      onSaveQueryPromptTemplateOptionClick &&
      selectedPromptTemplateNode
    )
      onSaveQueryPromptTemplateOptionClick(
        promptTemplates[selectedPromptTemplateNode].prompt_template,
      );
  };

  const queryMoreOptionsDropdownMenuItems = [
    ...(onRepeatQueryOptionClick
      ? [
          {
            key: ResultMoreActionsKey.REPEAT_QUERY,
            label: REPEAT_QUERY_BUTTON_LABEL,
          },
        ]
      : []),
    ...(!!selectedPromptTemplateNode && onSaveQueryPromptTemplateOptionClick
      ? [
          {
            key: ResultMoreActionsKey.SAVE_PROMPT_TEMPLATE,
            label: SAVE_AS_PROMPT_TEMPLATE_BUTTON_LABEL,
          },
        ]
      : []),
  ];

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    messageApi.success(COPY_PROMPT_MESSAGE);
  };

  const getParsedReferences = () => {
    const references = get(
      meta as IMetaAnnotations,
      '_references',
      [],
    ) as IReferencesMetaAnnotation[];
    if (!documents || !displayFileOptions) return null;
    return getParsedMetaReferences(references, resultId, documents);
  };

  const handleReferenceButtonClick = (referenceId?: string) => {
    dispatch(openReferenceDrawer(resultId, referenceId));

    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.SEARCH_RESULT}/${EventControlSearchResultElement.VIEW_REFERENCE}`,
      properties: {},
    });
  };

  const getPromptTemplatesNodeTabs = () =>
    Object.keys(promptTemplates).map((node) => ({
      key: node,
      label: node,
    }));

  // Renders

  const renderFeedbackButtons = () => {
    return (
      <div className={styles.result_actions}>
        <SearchResultActions
          resultId={resultId}
          queryId={queryId}
          pipelineId={pipelineId}
          pipelineName={pipelineName}
          copytext={answer}
          isExternal={isExternal}
        />
      </div>
    );
  };

  const renderAnswer = () => (
    <div className={styles.answerBubble_content}>
      <div className={styles.answerBubble_answer}>
        <DcLogoAvatar />
        <span className={styles.flexWrapper}>
          <FormattedResultText
            text={answer}
            withTypingEffect={displayTypingEffect && !resultId}
            references={getParsedReferences()}
            onViewReference={handleReferenceButtonClick}
            displayReferencesPopover={displayReferencesPopover}
          />
          {displayFeedbackOptions && resultId && renderFeedbackButtons()}
          {displayFileSources && (
            <SearchResultMultiFileSource
              resultId={resultId}
              file={firstFile}
              meta={meta}
              isExternal={isExternal}
              searching={searching}
              documentsGroupedByFileId={documentsGroupedByFileId}
              isFileActionTakingPlace={isFileActionTakingPlace}
              onFileDropdownItemClick={onFileDropdownItemClick}
              fileDropdownMenuItems={multiFilesDropdownMenuItems}
              sourcesCollapsed={sourcesCollapsed}
              setSourcesCollapsed={setSourcesCollapsed}
              displayFileOptions={displayFileOptions}
            />
          )}
        </span>
      </div>
      {displayMoreOptions && !!moreOptionsDropdownMenuItems?.length && (
        <Dropdown
          menu={{
            items: moreOptionsDropdownMenuItems,
            onClick: onMoreOptionsDropdownMenuItemClick,
          }}
          trigger={['click']}
        >
          <Button
            type="text"
            onClick={(e) => e.preventDefault()}
            disabled={searching}
            className={styles.answerBubble_moreOptionsButton}
            icon={<MoreOutlined />}
          />
        </Dropdown>
      )}
    </div>
  );

  const renderError = () => (
    <Alert
      className={styles.errorAlert}
      message={ANSWER_RESULT_ERROR_ALERT_TITLE}
      description={errorMessage}
      type="error"
    />
  );

  const renderResult = () => {
    if (result) return renderAnswer();
    return null;
  };

  const renderPromptTemplatesNodeTabs = () => {
    if (!promptTemplates || Object.keys(promptTemplates).length < 2) return null;
    return (
      <div className={styles.queryBubble_prompts_selector}>
        <Tabs
          size="small"
          activeKey={selectedPromptTemplateNode}
          items={getPromptTemplatesNodeTabs()}
          onChange={setSelectedPromptTemplateNode}
          tabBarGutter={16}
        />
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <div className={styles.result}>
        <div className={styles.queryBubble}>
          <div className={styles.queryBubble_content}>
            <div className={styles.queryBubble_query}>
              <UserAvatar
                user={{ given_name: firstName, family_name: lastName, user_id: '' }}
                size="default"
              />
              <span>
                {query}
                {selectedPromptTemplateNode && (
                  <>
                    {promptOpened && (
                      <div className={styles.queryBubble_prompts}>
                        {renderPromptTemplatesNodeTabs()}
                        <code>
                          <pre>{promptTemplates[selectedPromptTemplateNode].prompt_template}</pre>
                          <Button
                            icon={<CopyOutlined />}
                            size="small"
                            onClick={() =>
                              copyText(
                                promptTemplates[selectedPromptTemplateNode].prompt_template || '',
                              )
                            }
                          />
                        </code>
                      </div>
                    )}
                    <div className={styles.queryBubble_actions}>
                      <Button size="small" onClick={() => setPromptOpened(!promptOpened)}>
                        {promptOpened ? HIDE_PROMPT_BUTTON_LABEL : SHOW_PROMPT_BUTTON_LABEL}
                      </Button>
                    </div>
                  </>
                )}
              </span>
            </div>
            {displayMoreOptions && !!queryMoreOptionsDropdownMenuItems.length && (
              <Dropdown
                menu={{
                  items: queryMoreOptionsDropdownMenuItems,
                  onClick: onQueryMoreOptionsDropdownMenuItemClick,
                }}
                trigger={['click']}
              >
                <Button
                  type="text"
                  onClick={(e) => e.preventDefault()}
                  disabled={searching}
                  className={styles.answerBubble_moreOptionsButton}
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            )}
          </div>
        </div>
        {errorMessage ? (
          renderError()
        ) : (
          <div
            className={`${styles.answerBubble} ${styleCustomization?.answerContainer || ''}`}
            style={{ backgroundColor: token.colorPrimaryBg }}
            data-testid="searchResult_content"
          >
            <div className={styles.answerBubble_content}>{renderResult()}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default PromptExplorerResult;
