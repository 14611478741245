import { FiltersProp, SelectedFilters } from '@constants/data-table';

export const initFiltersValues = (
  filters: FiltersProp | undefined,
  filterValues: SelectedFilters,
) => {
  if (!filters) return {};

  const parsedFiltersValues = filters.reduce((acc, { key }) => {
    const defaultValues = filterValues[key];
    if (defaultValues) return { ...acc, [key]: defaultValues };
    return { ...acc, [key]: [] };
  }, {});

  return parsedFiltersValues;
};

export const resetFilterValues = (filters: FiltersProp | undefined) => {
  if (!filters) return {};

  return filters.reduce((acc, { key }) => ({ ...acc, [key]: [] }), {});
};
