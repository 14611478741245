import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { interpolateString } from '@utils/string';
import { DOCUMENTATION_LINK_LABEL } from '@constants/common';
import {
  METERING_PAGE_HEADER,
  PIPELINE_USAGE_DOCS_URL,
  UPGRADE_PAGE_SECTION,
} from '@constants/metering';
import { resetUsageData } from '@redux/actions/usageActions';
import { userSelector } from '@redux/selectors/userSelectors';
import EmptyPageDescription from '@components/emptyPageDescription/EmptyPageDescription';
import TopNavigation from '@components/settings/TopNavigation';
import MeteringUsage from './features/MeteringUsage/MeteringUsage';
import PipelinesUsage from './features/PipelinesUsage/PipelinesUsage';
import PipelinesUsageGraph from './features/PipelinesUsageGraph/PipelinesUsageGraph';
import styles from './meteringPage.module.scss';

const UsageLandingSVG = React.lazy(() => import('@assets/empty/usage-landing.svg?react'));

const MeteringPage = () => {
  const dispatch = useDispatch();
  const { isLimitedUser } = useSelector(userSelector);

  useEffect(() => {
    dispatch(resetUsageData);
  }, []);

  const renderUpgradeState = () => {
    return (
      <EmptyPageDescription
        title={UPGRADE_PAGE_SECTION.TITLE}
        description={UPGRADE_PAGE_SECTION.DESCRIPTION.map(({ paragraph }) => ({
          paragraph: interpolateString(paragraph, {
            documentationLink: (
              <a href={PIPELINE_USAGE_DOCS_URL} target="_blank" rel="noreferrer">
                {DOCUMENTATION_LINK_LABEL}
              </a>
            ),
          }),
        }))}
        image={<UsageLandingSVG />}
        limited
      />
    );
  };

  if (isLimitedUser) return renderUpgradeState();

  return (
    <div className={styles.container}>
      <TopNavigation />
      <div className={styles.content}>
        <div className={styles.section}>
          <h4 className={styles.header}>{METERING_PAGE_HEADER}</h4>
          <MeteringUsage />
        </div>
        <PipelinesUsageGraph />
        <PipelinesUsage />
      </div>
    </div>
  );
};

export default MeteringPage;
