import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import { interpolateString } from '@utils/string';
import { DOCUMENTATION_LINK } from '@constants/pipeline-details';
import {
  DOCUMENTATION_PROD_DEV_PIPELINES_URL,
  PIPELINE_PRODUCTION_DRAFT_TOOLTIP,
  PIPELINE_PRODUCTION_LABEL,
  PIPELINE_PRODUCTION_SWITCH_TO_PROD_DESCRIPTION,
} from '@constants/pipelines';
import { fetchPipeline } from '@redux/actions/pipelineActions';
import { pipelineSelector } from '@redux/selectors/pipelineSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import { IPipeline, IUserData, PipelineServiceLevel } from '@redux/types/types';
import styles from './pipelineServiceLevelSwitchSection.module.scss';
import PipelineServiceLevelSwitch from '../pipelineServiceLevelSwitch/PipelineServiceLevelSwitch';

const PipelineServiceLevelSwitchSection = () => {
  const dispatch = useDispatch();
  const pipeline: IPipeline = useSelector(pipelineSelector);
  const { isLimitedUser }: IUserData = useSelector(userSelector);

  const { name: pipelineName, service_level: serviceLevel } = pipeline;

  if (isLimitedUser) return null;

  return (
    <Tooltip
      placement="bottom"
      title={serviceLevel === PipelineServiceLevel.DRAFT ? PIPELINE_PRODUCTION_DRAFT_TOOLTIP : ''}
    >
      <div
        className={`${styles.productionSection} ${
          serviceLevel === PipelineServiceLevel.DRAFT ? styles.draftPipeline : ''
        }`}
      >
        <div className={styles.productionSection_content}>
          <div className={styles.productionSection_content_avatar}>
            <Avatar icon={<GlobalOutlined />} />
          </div>
          <div>
            <h6>{PIPELINE_PRODUCTION_LABEL}</h6>
            <div className={styles.productionSection_content_description}>
              {interpolateString(PIPELINE_PRODUCTION_SWITCH_TO_PROD_DESCRIPTION, {
                documentation: (
                  <a href={DOCUMENTATION_PROD_DEV_PIPELINES_URL} target="_blank" rel="noreferrer">
                    {DOCUMENTATION_LINK}
                  </a>
                ),
              })}
            </div>
          </div>
        </div>
        <PipelineServiceLevelSwitch
          serviceLevel={serviceLevel}
          pipelineName={pipelineName}
          afterSwitch={() => dispatch(fetchPipeline({ pipelineName }))}
          size="default"
        />
      </div>
    </Tooltip>
  );
};

export default PipelineServiceLevelSwitchSection;
