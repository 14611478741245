import dayjs from 'dayjs';
import produce from 'immer';
import { isUndefined } from 'lodash';
import { calculateDateRangeInterval, calculateStartDateFromType } from '@utils/date';
import { DateRangeInterval } from '@constants/enum/common';
import {
  DateRangeType,
  FULFILLED,
  GET_OBSERVABILITY_METRIC_VALUE,
  IDateRange,
  IPipeline,
  IReferencesPerRank,
  ObservabilityMetric,
  RESET_SELECTED_OBSERVABILITY_DATE,
  RESET_SELECTED_OBSERVABILITY_PIPELINE,
  SELECT_OBSERVABILITY_DATE_RANGE,
  SELECT_OBSERVABILITY_PIPELIBNE,
} from '@redux/types/types';

const getDefaultTimeRange = () => {
  return {
    type: DateRangeType.THIRTY_DAYS,
    from: calculateStartDateFromType(DateRangeType.THIRTY_DAYS),
    to: dayjs(),
    interval: calculateDateRangeInterval(
      calculateStartDateFromType(DateRangeType.THIRTY_DAYS)!,
      dayjs(),
    ),
  };
};

interface IInitialStateProps {
  metricsValues: {
    groundedness: number | null;
    totalqueries: number | null;
    referenceperrank: IReferencesPerRank[] | null;
    totalqueriesOverTime: [string, number][] | null;
    groundednessOverTime: [string, number][] | null;
  };
  selectedPipeline: IPipeline | null;
  selectedDateRange: IDateRange | null;
}

export const initialState: IInitialStateProps = {
  metricsValues: {
    groundedness: null,
    totalqueries: null,
    referenceperrank: null,
    totalqueriesOverTime: null,
    groundednessOverTime: null,
  },
  selectedPipeline: null,
  selectedDateRange: getDefaultTimeRange(),
};

function observabilityReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_SELECTED_OBSERVABILITY_DATE:
        localDraft.selectedDateRange = getDefaultTimeRange();
        break;
      case RESET_SELECTED_OBSERVABILITY_PIPELINE:
        localDraft.selectedPipeline = null;
        break;
      case SELECT_OBSERVABILITY_PIPELIBNE:
        localDraft.selectedPipeline = action.payload;
        localDraft.metricsValues = initialState.metricsValues;
        break;
      case SELECT_OBSERVABILITY_DATE_RANGE: {
        const { type, from, to, interval } = action.payload;
        const fromDate = from || calculateStartDateFromType(type);
        const toDate = to || dayjs();
        localDraft.selectedDateRange = {
          type,
          from: fromDate,
          to: toDate,
          interval: isUndefined(interval) ? calculateDateRangeInterval(fromDate, toDate) : interval,
        };
        break;
      }
      case `${GET_OBSERVABILITY_METRIC_VALUE}/${FULFILLED}`: {
        const { data, metric, interval } = action.payload as {
          data: { timestamp: string; value: number; rank: number }[] | null;
          metric: ObservabilityMetric;
          interval?: DateRangeInterval | null;
        };

        const getOverTimeMetricName = (metricName: string) => {
          return `${metricName}OverTime` as 'groundednessOverTime' | 'totalqueriesOverTime';
        };

        // TODO: Create parser schema for each metric or type
        if (metric === ObservabilityMetric.DOCUMENT_REFERENCES_PER_RANK) {
          const totalReferences = data?.reduce((acc, { value: val }) => acc + val, 0);
          const parsedData: IReferencesPerRank[] | null =
            data
              ?.map(({ rank, value }) => {
                return {
                  rank,
                  amount: value,
                  percent: totalReferences ? (value / totalReferences) * 100 : 0,
                };
              })
              ?.sort((a, b) => a.rank - b.rank) ?? null;
          if (!parsedData?.length) {
            localDraft.metricsValues[metric] = null;
            break;
          }
          localDraft.metricsValues[metric] = parsedData as IReferencesPerRank[];
          break;
        }

        const parsedData: [string, number][] | null =
          data?.map(({ timestamp, value }) => [timestamp, value]) ?? null;

        if (!parsedData?.length) {
          if (!interval) localDraft.metricsValues[metric] = null;
          else localDraft.metricsValues[getOverTimeMetricName(metric)] = null;
          break;
        }

        if (!interval) {
          const [, lastData] = parsedData[parsedData.length - 1];
          localDraft.metricsValues[metric] = lastData;
        } else
          localDraft.metricsValues[getOverTimeMetricName(metric)] = parsedData as [
            string,
            number,
          ][];
        break;
      }
      default:
        break;
    }

    return localDraft;
  });
}

export default observabilityReducer;
