import React from 'react';
import { Tag } from 'antd';
import { DEEPSET_CLOUD_VERSION_TAG_LABELS } from '@constants/pipelines';
import { DeepsetCloudVersion } from '@redux/types/types';
import styles from './pipelineDeepsetCloudVersionTag.module.scss';

interface IPipelineDeepsetCloudVersionTag {
  deepsetCloudVersion: DeepsetCloudVersion;
}

const PipelineDeepsetCloudVersionTag = (props: IPipelineDeepsetCloudVersionTag) => {
  const { deepsetCloudVersion } = props;

  return (
    <Tag
      color={deepsetCloudVersion === DeepsetCloudVersion.V1 ? 'magenta' : 'purple'}
      bordered={false}
      className={styles.deepsetCloudVersionTag}
    >
      {DEEPSET_CLOUD_VERSION_TAG_LABELS[deepsetCloudVersion]}
    </Tag>
  );
};

export default PipelineDeepsetCloudVersionTag;
