import { Dayjs } from 'dayjs';
import { DateRangeInterval } from '@constants/enum/common';
import { http } from './base-http';

interface IUsageData {
  dateRange?: {
    from?: Dayjs | null;
    to?: Dayjs | null;
  };
  interval?: DateRangeInterval | null;
}
interface IPipelinesUsageData {
  limit?: number;
  pageNumber?: number;
  dateRange?: {
    from?: Dayjs | null;
    to?: Dayjs | null;
  };
  field?: string;
  order?: string;
  filter?: string;
}

export const getPricingPlanApi = () => {
  return http.get(`/api/v2/pricing/pricing_plan`);
};

export const getUsageMetricsApi = (params: IUsageData) => {
  const mappedParams = {
    ...(params?.interval && { interval: params.interval }),
    ...(params?.dateRange?.from && { from: params.dateRange.from.toISOString() }),
    ...(params?.dateRange?.to && { until: params.dateRange.to.toISOString() }),
  };
  return http.get(`/api/v2/metering/stats`, {
    params: mappedParams,
  });
};

export const getPipelineUsageMetricsApi = (params: IPipelinesUsageData) => {
  const mappedParams = {
    ...(params?.limit && { limit: params?.limit }),
    ...(params?.pageNumber && { page_number: params?.pageNumber }),
    ...(params?.dateRange?.from && { from: params.dateRange.from.toISOString() }),
    ...(params?.dateRange?.to && { until: params.dateRange.to.toISOString() }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
    ...(params?.filter && { filter: params.filter }),
  };
  return http.get(`/api/v2/metering/pipeline-stats`, {
    params: mappedParams,
  });
};
