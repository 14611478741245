import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { getFeatureTourVisit, USER_PREFERENCES } from '@utils/userPreferences';
import useReadLocalStorage from '@hooks/useReadLocalStorage';
import { UserRoles } from '@constants/enum/common';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';
import { whatsNewNotes } from './notes';
import styles from './releaseNotes.module.scss';

const ReleaseNotes = () => {
  const { role }: IUserData = useSelector(userSelector);

  const [notificationApi, notificationContextHolder] = notification.useNotification({
    maxCount: 1,
  });
  const userPreferences: { featureTour: { navigationTour: boolean } } | null =
    useReadLocalStorage(USER_PREFERENCES);

  const handleNotificationClose = (id: string) => {
    const localList = JSON.parse(localStorage.getItem('dcNotifications') || '[]');
    localStorage.setItem('dcNotifications', JSON.stringify([...localList, id]));
  };

  useEffect(() => {
    if (!userPreferences) return;
    if (role !== UserRoles.ADMIN) return;

    const localList = JSON.parse(localStorage.getItem('dcNotifications') || '[]');
    const { id, title, content } = whatsNewNotes;

    if (localList && localList.includes(id)) {
      return;
    }

    if (!getFeatureTourVisit('navigationTour')) return;

    notificationApi.open({
      message: title,
      description: content,
      duration: 0,
      className: styles.releaseNotes,
      placement: 'topRight',
      onClose: () => handleNotificationClose(id),
    });
  }, [userPreferences?.featureTour?.navigationTour, role]);

  return <> {notificationContextHolder} </>;
};

export default ReleaseNotes;
