const parseStreamText = <T extends unknown[]>(text: string): T => {
  const lines = text
    .trim()
    .split(/(?:\n\n|^)data: /)
    .filter((line) => line.trim() !== '');

  const parsed = lines.map((line: string) => {
    return JSON.parse(line);
  });

  return parsed as T;
};

// TODO: Create our own SSE client
export const handleStreamProgress = <T extends unknown[]>(
  onChunksReceived: (chunks: T) => void,
) => {
  let prevLen = 0;
  let buffer = ''; // Buffer to store incomplete chunks

  return (progressEvent: any) => {
    const xhr = progressEvent.event.target;
    const { responseText } = xhr;
    const chunk = responseText.slice(prevLen);
    prevLen = responseText.length;

    buffer += chunk;

    try {
      const parsedData = parseStreamText<T>(buffer);
      onChunksReceived(parsedData);
      buffer = '';
    } catch (_) {
      // Continue to accumulate imcomplete chunks
    }
  };
};
