import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Switch } from 'antd';
import { interpolateString } from '@utils/string';
import { CANCEL_BUTTON_LABEL } from '@constants/common';
import {
  PIPELINE_PRODUCTION_SWITCH_OFF,
  PIPELINE_PRODUCTION_SWITCH_OFF_MODAL_CONTENT,
  PIPELINE_PRODUCTION_SWITCH_OFF_MODAL_TITLE,
  PIPELINE_PRODUCTION_SWITCH_ON,
  PIPELINE_PRODUCTION_SWITCH_ON_MODAL_CONTENT,
  PIPELINE_PRODUCTION_SWITCH_ON_MODAL_TITLE,
} from '@constants/pipelines';
import { PipelineServiceLevel } from '@redux/types/types';
import usePipelineServiceLevelSwitch from './hooks/usePipelineServiceLevelSwitch';
import styles from './pipelineServiceLevelSwitch.module.scss';

interface IPipelineServiceLevelSwitch {
  serviceLevel: string;
  pipelineName: string;
  afterSwitch?: () => void;
  size: 'small' | 'default';
}

const PipelineServiceLevelSwitch = (props: IPipelineServiceLevelSwitch) => {
  const { serviceLevel, pipelineName, afterSwitch, size } = props;
  const [modal, contextHolder] = Modal.useModal();
  const { changeServiceLevel } = usePipelineServiceLevelSwitch(
    serviceLevel,
    pipelineName,
    afterSwitch,
  );

  const modalHandler = () => {
    modal.confirm({
      title:
        serviceLevel === PipelineServiceLevel.PRODUCTION
          ? PIPELINE_PRODUCTION_SWITCH_OFF_MODAL_TITLE
          : PIPELINE_PRODUCTION_SWITCH_ON_MODAL_TITLE,
      icon: <ExclamationCircleOutlined />,
      onOk: afterSwitch ? changeServiceLevel : () => {},
      content:
        serviceLevel === PipelineServiceLevel.PRODUCTION
          ? interpolateString(PIPELINE_PRODUCTION_SWITCH_OFF_MODAL_CONTENT, {
              pipelineName: <span className={styles.switch_pipelineName}>{pipelineName}</span>,
            })
          : interpolateString(PIPELINE_PRODUCTION_SWITCH_ON_MODAL_CONTENT, {
              pipelineName: <span className={styles.switch_pipelineName}>{pipelineName}</span>,
            }),
      okText:
        serviceLevel === PipelineServiceLevel.PRODUCTION
          ? PIPELINE_PRODUCTION_SWITCH_OFF
          : PIPELINE_PRODUCTION_SWITCH_ON,
      cancelText: CANCEL_BUTTON_LABEL,
    });
  };

  return (
    <div className={styles.switch}>
      <Switch
        size={size}
        value={serviceLevel === PipelineServiceLevel.PRODUCTION}
        onClick={() => modalHandler()}
        disabled={serviceLevel === PipelineServiceLevel.DRAFT}
      />
      {contextHolder}
    </div>
  );
};

export default PipelineServiceLevelSwitch;
