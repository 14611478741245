import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib';
import { COPY_BUTTON_LABEL } from '@constants/common';
import ButtonWithClickConfirmationAnimation from '@components/common/buttonWithClickConfirmationAnimation/ButtonWithClickConfirmationAnimation';

type CopyButtonProps = {
  copytext: string;
} & ButtonProps;

const CopyButton = ({ copytext, ...params }: CopyButtonProps) => (
  <Tooltip title={COPY_BUTTON_LABEL} placement="top">
    <ButtonWithClickConfirmationAnimation
      size="small"
      type="text"
      icon={<CopyOutlined />}
      {...params}
      onClick={() => navigator.clipboard.writeText(copytext)}
    />
  </Tooltip>
);

export default CopyButton;
