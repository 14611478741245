import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const promptExplorerStoreSelector = (state: RootState) => state.promptExplorerStore;

export const promptTemplatesSelector = createSelector(
  promptExplorerStoreSelector,
  (promptExplorerStore) => promptExplorerStore.promptTemplates,
);

export const promptTemplatesStatusSelector = createSelector(
  promptExplorerStoreSelector,
  (promptExplorerStore) => promptExplorerStore.promptTemplatesStatus,
);

export const createPromptTemplateStatusSelector = createSelector(
  promptExplorerStoreSelector,
  (promptExplorerStore) => promptExplorerStore.createPromptTemplateStatus,
);

export const updatePromptTemplateStatusSelector = createSelector(
  promptExplorerStoreSelector,
  (promptExplorerStore) => promptExplorerStore.updatePromptTemplateStatus,
);

export const updatePipelinePromptStatusSelector = createSelector(
  promptExplorerStoreSelector,
  (promptExplorerStore) => promptExplorerStore.updatePipelinePromptStatus,
);

// Sort

export const promptTemplatesSortValueSelector = createSelector(
  promptExplorerStoreSelector,
  (promptExplorerStore) => promptExplorerStore.promptTemplatesSortValue,
);

// Filters

export const promptTemplatesFiltersValuesSelector = createSelector(
  promptExplorerStoreSelector,
  (promptExplorerStore) => promptExplorerStore.filtersValues,
);
