import React from 'react';
import { useDispatch } from 'react-redux';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { get } from 'lodash';
import { getParsedMetaReferences } from '@utils/search';
import useSearchFileActions from '@hooks/useSearchFileActions';
import { useUserEvent } from '@hooks/useUserEvent';
import {
  EventControlComponent,
  EventControlSearchResultElement,
  UserEventType,
} from '@constants/event';
import { multiFilesDropdownMenuItems } from '@constants/search';
import { openReferenceDrawer } from '@redux/actions/searchActions';
import {
  IBaseSearchResultComponentProps,
  IMetaAnnotations,
  IReferencesMetaAnnotation,
  ISearchResultAnswer,
  ISearchResultDocument,
} from '@redux/types/types';
import SearchResultMultiFileSource from '@components/search/molecules/files/SearchResultMultiFileSource';
import FormattedResultText from '@components/search/molecules/formattedResultText/FormattedResultText';
import styles from './resultContainer.module.scss';
import SearchResultActions from '../resultActions/SearchResultActions';

interface IGenerativeResultProps extends IBaseSearchResultComponentProps<ISearchResultAnswer> {
  documentsGroupedByFileId?: Record<string, ISearchResultDocument[]>;
  documents?: ISearchResultDocument[];
  displayMoreOptions: boolean;
  displayReferencesPopover?: boolean;
  searching: boolean;
  moreOptionsDropdownMenuItems: MenuProps['items'];
  onMoreOptionsDropdownMenuItemClick: (info: { key: string }) => void;
  sourcesCollapsed?: boolean;
  setSourcesCollapsed: (value: boolean) => void;
  isExternal?: boolean;
  withTypingEffect?: boolean;
}

const GenerativeResult = ({
  result,
  pipelineId,
  queryId,
  pipelineName,
  withTypingEffect = true,
  displayFeedbackOptions,
  displayFileSources,
  displayMoreOptions,
  displayFileOptions,
  displayReferencesPopover,
  searching,
  isExternal,
  documentsGroupedByFileId,
  documents,
  moreOptionsDropdownMenuItems,
  onMoreOptionsDropdownMenuItemClick,
  sourcesCollapsed,
  setSourcesCollapsed,
}: IGenerativeResultProps) => {
  const dispatch = useDispatch();
  const { result_id: resultId, files, answer: plainAnswer, meta } = result;
  const [firstFile] = files ?? [];
  const { trackUserEvent } = useUserEvent({ result_id: resultId });
  const { onFileDropdownItemClick, isFileActionTakingPlace } = useSearchFileActions({
    resultId,
    isExternal,
  });

  const getParsedReferences = () => {
    const references = get(
      meta as IMetaAnnotations,
      '_references',
      [],
    ) as IReferencesMetaAnnotation[];
    if (!documents || !displayFileOptions) return null;
    return getParsedMetaReferences(references, resultId, documents);
  };

  const handleReferenceButtonClick = (referenceId?: string) => {
    dispatch(openReferenceDrawer(resultId, referenceId));

    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.SEARCH_RESULT}/${EventControlSearchResultElement.VIEW_REFERENCE}`,
      properties: {},
    });
  };

  // Renders

  const renderAnswer = () => {
    return (
      <FormattedResultText
        text={plainAnswer}
        references={getParsedReferences()}
        displayReferencesPopover={displayReferencesPopover}
        onViewReference={handleReferenceButtonClick}
        withTypingEffect={!resultId && withTypingEffect}
      />
    );
  };

  const renderResultBody = () => {
    return (
      <div
        className={`${styles.result_body} ${styles.flex_content}`}
        data-testid="searchResult_content"
      >
        {renderAnswer()}
        {displayMoreOptions && !!moreOptionsDropdownMenuItems?.length && (
          <Dropdown
            menu={{
              items: moreOptionsDropdownMenuItems,
              onClick: onMoreOptionsDropdownMenuItemClick,
            }}
            trigger={['click']}
          >
            <Button
              type="text"
              onClick={(e) => e.preventDefault()}
              disabled={searching}
              icon={<MoreOutlined />}
              data-testid="searchResult_content_moreOptions"
            />
          </Dropdown>
        )}
      </div>
    );
  };

  const renderFeedbackButtons = () => {
    return (
      <div className={styles.result_actions}>
        <SearchResultActions
          resultId={resultId}
          queryId={queryId}
          pipelineId={pipelineId}
          pipelineName={pipelineName}
          isExternal={isExternal}
          copytext={plainAnswer}
        />
      </div>
    );
  };

  return (
    <div className={styles.result}>
      {renderResultBody()}
      {displayFeedbackOptions && resultId && renderFeedbackButtons()}
      {displayFileSources && (
        <SearchResultMultiFileSource
          resultId={resultId}
          file={firstFile}
          meta={meta}
          isExternal={isExternal}
          searching={searching}
          documentsGroupedByFileId={documentsGroupedByFileId}
          isFileActionTakingPlace={isFileActionTakingPlace}
          onFileDropdownItemClick={onFileDropdownItemClick}
          fileDropdownMenuItems={multiFilesDropdownMenuItems}
          sourcesCollapsed={sourcesCollapsed}
          setSourcesCollapsed={setSourcesCollapsed}
          displayFileOptions={displayFileOptions}
        />
      )}
    </div>
  );
};

export default GenerativeResult;
