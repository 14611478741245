import React, { ReactElement } from 'react';
import { Switch } from 'antd';
import styles from './switchWithText.module.scss';

interface ISwitchWithTextProps {
  text: string | ReactElement;
  value: boolean;
  icon?: ReactElement;
  onSwitchChange: (value: boolean) => void;
}

const SwitchWithText = ({ text, value, icon, onSwitchChange }: ISwitchWithTextProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {!!icon && icon}
        <span>{text}</span>
      </div>
      <Switch value={value} onChange={onSwitchChange} size="small" />
    </div>
  );
};

export default SwitchWithText;
