import React, { ReactElement } from 'react';
import { Modal } from 'antd';
import { DONE_BUTTON_LABEL } from '@constants/common';
import styles from './displayDataModal.module.scss';

interface IDisplayDataModalProps {
  children: ReactElement | string;
  header?: ReactElement | string | null;
  title: string;
  open: boolean;
  footer?: ReactElement;
  onCancel?: () => void;
  onOk?: () => void;
}

const DisplayDataModal = ({
  children,
  header,
  title,
  open,
  footer,
  onCancel,
  onOk,
}: IDisplayDataModalProps) => (
  <Modal
    title={title}
    className={styles.displayModal}
    open={open}
    centered
    onCancel={onCancel}
    onOk={onOk}
    okText={DONE_BUTTON_LABEL}
    footer={footer}
    cancelButtonProps={{ style: { display: 'none' } }}
  >
    {header && <div className={styles.header}>{header}</div>}
    <div className={styles.body}>{children}</div>
  </Modal>
);

export default DisplayDataModal;
