import { Dayjs } from 'dayjs';
import { DateRangeInterval } from '@constants/enum/common';
import { ObservabilityMetric } from '@redux/types/types';
import { getCurrentWorkspaceId } from '@src/utils/store';
import { http } from './base-http';

export const getObservabilityMetricDataApi = (
  pipelineId: string,
  metric: ObservabilityMetric,
  dateRange?: {
    from?: Dayjs | null;
    to?: Dayjs | null;
  },
  interval?: DateRangeInterval | null,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const mappedParams = {
    ...(dateRange?.from && { from: dateRange.from.toISOString() }),
    ...(dateRange?.to && { until: dateRange.to.toISOString() }),
    ...(interval && { interval }),
  };
  return http.get(`/api/v2/workspaces/${workspaceId}/pipelines/${pipelineId}/metrics/${metric}`, {
    params: mappedParams,
  });
};
