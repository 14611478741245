import React, { ReactElement } from 'react';
import styles from './contentMessage.module.scss';

interface IContentMessageProps {
  title: string;
  description: string | ReactElement;
  icon: ReactElement;
}

const ContentMessage = ({ title, description, icon }: IContentMessageProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.container_icon}>{icon}</div>
      <div className={styles.container_textWrapper}>
        <h4>{title}</h4>
        <div className={styles.container_textWrapper_description}>{description}</div>
      </div>
    </div>
  );
};

export default ContentMessage;
