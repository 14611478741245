import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getDateAsDayjsInstance } from '@utils/date';
import { selectedDateRangeSelector } from '@redux/selectors/usageSelectors';

const useUsageDateRange = () => {
  const selectedDateRange = useSelector(selectedDateRangeSelector);

  const getCurrentSelectedDateRange = () => {
    if (!selectedDateRange) return null;
    return {
      ...selectedDateRange,
      from: getDateAsDayjsInstance(selectedDateRange.from),
      to: getDateAsDayjsInstance(selectedDateRange.to),
    };
  };

  const getCurrentUsageCycleText = (): string => {
    const selectedDate = getCurrentSelectedDateRange();
    if (!selectedDate) return '';
    const { from, to } = selectedDate;
    return `${from?.format('DD MMM')} - ${to?.format('DD MMM')}`;
  };

  const getCurrentUsageCycleDate = () => {
    const { from, to } = getCurrentSelectedDateRange() || {};
    return {
      from: from?.date() || null,
      current: dayjs().date(),
      to: to?.date() || null,
    };
  };

  return {
    selectedDateRange: getCurrentSelectedDateRange(),
    currentUsageCycleText: getCurrentUsageCycleText(),
    currentUsageCycleDate: getCurrentUsageCycleDate(),
  };
};

export default useUsageDateRange;
