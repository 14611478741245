/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export const whatsNewNotes = {
  id: 'v0.10.15',
  title: (
    <>
      <div> What's new in deepset Cloud?</div>
    </>
  ),
  content: (
    <>
      <ul>
        <li>
          Dive into the new{' '}
          <a href="https://cloud.deepset.ai/settings/usage" target="_blank" rel="noreferrer">
            Usage
          </a>{' '}
          dashboard and gain insights into your pipeline usage. At a glance, you can check the
          number of pipelines you've deployed and the resources and storage they're consuming.
        </li>
        <li>
          We've enhanced the Playground with a chat interface to make it easier and more fun for you
          to test your chat pipelines. Head over to{' '}
          <a href="https://cloud.deepset.ai/playground" target="_blank" rel="noreferrer">
            Playground
          </a>
          , choose a chat pipeline, and try it out!
        </li>
      </ul>
    </>
  ),
};
