import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import { getODataEQFilterFrom } from '@utils/odata';
import { StatusCodes } from '@constants/enum/common';
import { HISTORY_LOG_TITLE } from '@constants/search';
import { getAllPipelineLatestQueries } from '@redux/actions/pipelineActions';
import { getSearchSessions as getSearchSessionsAction } from '@redux/actions/searchActions';
import {
  searchSessionsSelector,
  searchSessionsStatusSelector,
} from '@redux/selectors/searchSelectors';
import QueryHistoryList from '@components/queryHistoryList/QueryHistoryList';
import styles from './searchHistoryLog.module.scss';

const FETCH_LIMIT = 20;

interface ISearchHistoryLogProps {
  currentSessionId: string | null;
  pipelineId?: string;
  pipelineName: string;
  onSessionChange: (sessionId: string) => void;
  onClose: () => void;
}

const SearchHistoryLog = ({
  currentSessionId,
  pipelineId,
  pipelineName,
  onSessionChange,
  onClose,
}: ISearchHistoryLogProps) => {
  const dispatch = useDispatch();
  const searchSessionsStatus = useSelector(searchSessionsStatusSelector);
  const searchSessions = useSelector(searchSessionsSelector);
  const [sessionPage, setSessionPage] = useState(1);

  const getSearchSessions = (
    extraParams: {
      pipelineName?: string;
      limit?: number;
      pageNumber?: number;
      filter?: string;
      fetchMore?: boolean;
    } = {},
  ) => {
    const params = {
      limit: FETCH_LIMIT,
      pageNumber: sessionPage,
      filter: getODataEQFilterFrom('pipeline_id', pipelineId),
      ...extraParams,
    };
    dispatch(getSearchSessionsAction(params));
  };

  useEffect(() => {
    getSearchSessions();
  }, [pipelineName]);

  const getSearchHistory = (sessionId: string) => {
    const params = {
      name: pipelineName,
      limit: FETCH_LIMIT,
      filter: getODataEQFilterFrom('search_session_id', sessionId),
    };
    dispatch(getAllPipelineLatestQueries(params));
  };

  const loadMoreSessions = () => {
    const nextPage = sessionPage + 1;
    setSessionPage(sessionPage + 1);
    getSearchSessions({ pageNumber: nextPage, fetchMore: true });
  };

  // For case, where a session was created but the search resulted in an error
  const getValidSearchSessions = () => {
    return searchSessions.search_sessions.filter(({ search_history: searchHistory }) =>
      dayjs(searchHistory?.created_at).isValid(),
    );
  };

  const getSessionsGroupedByDay = () => {
    const parsedToListItem = getValidSearchSessions().map(
      ({ search_session_id: searchSessionId, search_history: searchHistory }) => ({
        query: searchHistory?.query || '',
        id: searchSessionId,
        createdAt: searchHistory?.created_at || '',
      }),
    );
    const groupedByDate = groupBy(parsedToListItem, ({ createdAt }) =>
      dayjs(createdAt).format('dddd D MMMM YYYY'),
    );
    return groupedByDate;
  };

  const onSessionSelect = (sessionId: string) => {
    onSessionChange(sessionId);
    getSearchHistory(sessionId);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h5>{HISTORY_LOG_TITLE}</h5>
        <Button type="text" size="small" icon={<CloseOutlined />} onClick={onClose} />
      </div>
      <div className={styles.content} id="historyContentListDiv">
        <QueryHistoryList
          isLoading={searchSessionsStatus === StatusCodes.IN_PROGRESS}
          total={searchSessions.total}
          hasMore={searchSessions.has_more}
          onNext={loadMoreSessions}
          scrollableTarget="historyContentListDiv"
          dataGroupedByDate={getSessionsGroupedByDay()}
          isSelected={(item) => item.id === currentSessionId}
          onItemSelect={(item) => onSessionSelect(item.id)}
        />
      </div>
    </div>
  );
};

export default SearchHistoryLog;
