import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const jobsStoreSelector = (state: RootState) => state.jobsStore;

export const jobSelector = createSelector(jobsStoreSelector, (jobsStore) => jobsStore.job);

export const jobsSelector = createSelector(jobsStoreSelector, (jobsStore) => jobsStore.jobs);

export const fetchJobsStatusSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.fetchJobsStatus,
);

export const jobsActionStatusSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.actionStatus,
);

export const jobResultsSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.jobResults,
);

export const fetchJobResultsStatusSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.fetchJobResultsStatus,
);

export const querySetSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.querySet,
);

export const querySetsSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.querySets,
);

export const jobTagsSelector = createSelector(jobsStoreSelector, (jobsStore) => jobsStore.jobTags);

export const createJobStatusSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.createJobStatus,
);

export const currentStepSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.currentStep,
);

export const openCreateJobDrawerSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.openCreateJobDrawer,
);

export const selectedJobTypeSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.selectedJobType,
);

export const selectedQueryRunSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.selectedQueryRun,
);

export const selectedQuerySetSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.selectedQuerySet,
);

export const selectedPipelineSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.selectedPipeline,
);

export const selectedJobNameSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.selectedJobName,
);

export const selectedTagsSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.selectedTags,
);

export const sharedJobResultSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.currentSharedJobResult,
);

export const fetchingSharedJobResultStatusSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.loadingSharedJobResultStatus,
);

export const generateSharedJobResultStatusSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.generateSharedJobResultStatus,
);

export const revokeSharedJobResultStatusSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.revokeSharedJobResultStatus,
);

export const jobListFiltersSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.jobListFiltersValues,
);

export const jobListSortValueSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.jobListSortValue,
);

export const jobResultsFiltersSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.jobResultsFiltersValues,
);

export const jobResultsSortValueSelector = createSelector(
  jobsStoreSelector,
  (jobsStore) => jobsStore.jobResultsSortValue,
);
