import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const secretsManagementStoreSelector = (state: RootState) => state.secretsManagementStore;

export const organizationSecretsSelector = createSelector(
  secretsManagementStoreSelector,
  (secretsManagementStore) => secretsManagementStore.organizationSecrets,
);

export const fetchOrganizationSecretsStatusSelector = createSelector(
  secretsManagementStoreSelector,
  (secretsManagementStore) => secretsManagementStore.fetchOrganizationSecretsStatus,
);

export const addOrganizationSecretStatusSelector = createSelector(
  secretsManagementStoreSelector,
  (secretsManagementStore) => secretsManagementStore.addOrganizationSecretStatus,
);

export const deleteOrganizationSecretStatusSelector = createSelector(
  secretsManagementStoreSelector,
  (secretsManagementStore) => secretsManagementStore.deleteOrganizationSecretStatus,
);
