import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { handleStreamProgress } from '@utils/sse';
import { ClientSourcePathHeaderOptions, CustomHTTPHeaders } from '@constants/http';
import { IQueryStreamRawMessageData } from '@redux/types/types';
import { getWorkspace } from '@src/utils/store';
import { http } from './base-http';

export interface ISearchHistoryAPIParams {
  limit?: number;
  before?: string;
  after?: string;
  pageNumber?: number;
  filter?: string;
}

export interface ISessionBody {
  pipeline_id: string;
}

export interface ISearchBody {
  queries: string[];
  filters?: Record<string, unknown>;
  params?: Record<string, unknown>;
  search_session_id?: string;
  view_prompts?: boolean;
}

export type ISearchStreamBody = Omit<ISearchBody, 'queries'> & {
  include_result?: boolean;
  query: string;
};

export const searchApi = (
  pipelineName: string,
  payload: ISearchBody,
  headers?: RawAxiosRequestHeaders,
  workspace: string = getWorkspace(),
) => {
  return http.post(`/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/search`, payload, {
    headers,
  });
};

export const searchStreamApi = async (
  pipelineName: string,
  payload: ISearchStreamBody,
  onChunkReceived: (chunks: IQueryStreamRawMessageData[]) => void,
  config: AxiosRequestConfig = {},
  workspace: string = getWorkspace(),
) => {
  return http.post(
    `/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/search-stream`,
    {
      include_result: true,
      ...payload,
    },
    {
      ...config,
      onDownloadProgress: handleStreamProgress(onChunkReceived),
    },
  );
};

export const createSearchSessionApi = (
  sessionBody: ISessionBody,
  workspaceName: string = getWorkspace(),
) => http.post(`/api/v1/workspaces/${workspaceName}/search_sessions`, sessionBody);

export const getSearchSessionsApi = (
  params: ISearchHistoryAPIParams,
  workspaceName: string = getWorkspace(),
) => {
  const parameterMap = {
    limit: params?.limit,
    ...(params?.after && { after: params.after }),
    ...(params?.pageNumber && { page_number: params.pageNumber }),
    ...(params?.filter && { filter: params.filter }),
  };
  return http.get(`/api/v1/workspaces/${workspaceName}/search_sessions`, {
    params: parameterMap,
    headers: {
      Accept: 'application/json',
    },
  });
};

export const docsSearchApi = (pipelineName: string, payload: ISearchBody, workspace: string) => {
  const token =
    'prototype_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjk3MDc4NTkuODUxLCJhdWQiOiJleHRlcm5hbCB1c2VyIiwiaXNzIjoiZEMiLCJ3b3Jrc3BhY2VfaWQiOiJjNjk2Mzc0Yy04OWUyLTRiMzktOTA1MC1jNWY0NjA5ZjI4Y2YiLCJ3b3Jrc3BhY2VfbmFtZSI6InJlYWRtZSIsIm9yZ2FuaXphdGlvbl9pZCI6ImNhOWYxNGQ0LWMyYzktNDYwZC04ZDI2LWY4Y2IwYWNhMDI0ZiIsInNoYXJlX2lkIjoiNDY1NzdjNTAtNWI4My00YWJiLWFkOWUtZmQ2NTkwYWNlYmYxIiwidXNlciI6eyJleHRlcm5hbF91c2VyX2lkIjoiZWU0YTdkMjgtNDRmOS00ZTJmLTk4ZTAtMWQ0NTk4Njc1OTA2In19.f4jm9zuRF2rWgRO5qIRZSNu01EcCKFy88iiqLArRuWk';

  return http({
    url: `/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/search`,
    baseURL: 'https://api.cloud.deepset.ai',
    method: `post`,
    headers: {
      Authorization: `Bearer ${token}`,
      [CustomHTTPHeaders.X_CLIENT_SOURCE]: 'deepset-cloud-ui',
      [CustomHTTPHeaders.X_CLIENT_SOURCE_PATH]: ClientSourcePathHeaderOptions.DOC_CHAT,
    },
    data: payload,
  });
};

export const chatQueryApi = (
  pipelineName: string,
  payload: ISearchBody,
  headers?: RawAxiosRequestHeaders,
  workspace: string = getWorkspace(),
) => {
  return http.post(`/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/chat`, payload, {
    headers,
  });
};

export const chatQueryStreamApi = (
  pipelineName: string,
  payload: ISearchStreamBody,
  onChunkReceived: (chunks: IQueryStreamRawMessageData[]) => void,
  config: AxiosRequestConfig = {},
  workspace: string = getWorkspace(),
) => {
  return http.post(
    `/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/chat-stream`,
    {
      include_result: true,
      ...payload,
    },
    {
      ...config,
      onDownloadProgress: handleStreamProgress(onChunkReceived),
    },
  );
};
