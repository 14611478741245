import React, { useState } from 'react';
import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Modal, Radio } from 'antd';
import {
  SAMPLE_FILE_MODAL_DESCRIPTION,
  SAMPLE_FILE_MODAL_OK_TEXT,
  SAMPLE_FILE_MODAL_TITLE,
  UPLOAD_SAMPLE_FILES_BUTTON_LABEL,
} from '@constants/data-page';
import EllipsisMiddle from '@components/common/ellipsisMiddle/EllipsisMiddle';
import useSampleFile from './useSampleFile';
import styles from './sampleFileModal.module.scss';

const SampleFileModal: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { datasetList, importDataset } = useSampleFile();
  const { data: sampleDataList } = datasetList || { data: [] };
  const [selectedCategory, setSelectedCategory] = useState<string>(sampleDataList[0]?.name);

  const handleCategoryChange = (e: any) => {
    setSelectedCategory(e.target.value);
  };

  const handleOK = async () => {
    setModalOpen(false);
    await importDataset(selectedCategory);
  };

  return (
    <>
      <Button onClick={() => setModalOpen(true)} test-id="sampleFile_button">
        {UPLOAD_SAMPLE_FILES_BUTTON_LABEL}
      </Button>
      <Modal
        title={SAMPLE_FILE_MODAL_TITLE}
        open={modalOpen}
        onOk={handleOK}
        onCancel={() => setModalOpen(false)}
        okText={SAMPLE_FILE_MODAL_OK_TEXT}
        centered
      >
        <p>{SAMPLE_FILE_MODAL_DESCRIPTION}</p>

        {sampleDataList?.map((category: { name: string }) => (
          <>
            <Radio.Group
              key={category.name}
              onChange={handleCategoryChange}
              value={selectedCategory}
            >
              <Radio key={category.name} value={category.name}>
                {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
              </Radio>
            </Radio.Group>
          </>
        ))}

        <ul className={styles.fileList}>
          {sampleDataList
            .find((category: { name: string }) => category.name === selectedCategory)
            ?.file_names?.map((file: string) => (
              <li key={file}>
                <PaperClipOutlined />
                <EllipsisMiddle>{file}</EllipsisMiddle>
              </li>
            ))}
        </ul>
      </Modal>
    </>
  );
};

export default SampleFileModal;
