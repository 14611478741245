import dayjs, { Dayjs } from 'dayjs';
import { DateRangeInterval } from '@constants/enum/common';
import { DateRangeType } from '@redux/types/types';

export const calculateStartDateFromType = (type: DateRangeType): Dayjs | null => {
  const mappedDateByType = {
    [DateRangeType.TODAY]: dayjs().startOf('day'),
    [DateRangeType.SEVEN_DAYS]: dayjs().subtract(7, 'day'),
    [DateRangeType.FOURTEEN_DAYS]: dayjs().subtract(14, 'day'),
    [DateRangeType.THIRTY_DAYS]: dayjs().subtract(30, 'day'),
    [DateRangeType.SIXTY_DAYS]: dayjs().subtract(60, 'day'),
    [DateRangeType.CUSTOM]: null,
  };

  return mappedDateByType[type];
};

export const calculateDateRangeInterval = (from: Dayjs, to: Dayjs): DateRangeInterval => {
  const diff = {
    minute: to.diff(from, 'minute'),
    hour: to.diff(from, 'hour'),
    day: to.diff(from, 'day'),
    week: to.diff(from, 'week'),
    month: to.diff(from, 'month'),
    year: to.diff(from, 'year'),
  };

  // Let's keep it interval maximum per day. More: https://deepset-ai.slack.com/archives/C050L3CBABC/p1702030652219039
  const thresholds = [
    { threshold: 60, interval: DateRangeInterval.MINUTE },
    { threshold: 24, interval: DateRangeInterval.HOUR },
    { threshold: 7, interval: DateRangeInterval.DAY },
    // { threshold: 4, interval: DateRangeInterval.WEEK },
    // { threshold: 12, interval: DateRangeInterval.MONTH },
    // { threshold: 1, interval: DateRangeInterval.YEAR },
  ];

  const matchingInterval = thresholds.find(
    ({ threshold, interval }) => diff[interval] <= threshold,
  );

  return matchingInterval ? matchingInterval.interval : DateRangeInterval.DAY;
};

export const getDateAsDayjsInstance = (date?: Dayjs | string | null) => {
  if (typeof date === 'string') return dayjs(date);
  return date || null;
};
