import React, { ChangeEvent, JSX, useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styles from './uploadFiles.module.scss';

interface IUploadFilesProps {
  createCurrentList: (files: FileList) => void;
  buttonDescription: string;
  hintDescription: string | JSX.Element;
  getInputAcceptAttributeFromFileTypes: () => string;
  size: 'small' | 'large';
  expanded?: boolean;
}

const UploadFiles = ({
  createCurrentList,
  buttonDescription,
  hintDescription,
  getInputAcceptAttributeFromFileTypes,
  size,
  expanded,
}: IUploadFilesProps) => {
  const inputFilesRef = useRef<HTMLInputElement | null>(null);

  const handleDrop = (ev: any) => {
    ev.preventDefault();

    if (ev.dataTransfer.files) {
      createCurrentList(ev.dataTransfer.files);
    }
  };

  const handleDragOver = (ev: any) => {
    ev.preventDefault();
  };

  const handleUploadClick = () => {
    inputFilesRef.current?.click();
  };

  const onFileUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) createCurrentList(e.target.files);
  };

  return (
    <div
      className={styles.uploadFiles}
      role="button"
      tabIndex={0}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
    >
      <div
        className={`${styles.uploadFiles_icon} ${
          size === 'small' ? styles.uploadFiles_small_icon : styles.uploadFiles_large_icon
        } ${!expanded ? styles.uploadFiles_notExpanded : ''}`}
      >
        <UploadOutlined onClick={handleUploadClick} />
      </div>
      <Button
        type="link"
        onClick={handleUploadClick}
        className={`${
          size === 'small' ? styles.uploadFiles_small_button : styles.uploadFiles_large_button
        }`}
      >
        {buttonDescription}
      </Button>
      <span
        className={`${styles.uploadFiles_textSupport} ${
          size === 'small'
            ? styles.uploadFiles_small_textSupport
            : styles.uploadFiles_large_textSupport
        }`}
      >
        {hintDescription}
      </span>
      <input
        data-testid="uploadFile_input"
        type="file"
        accept={getInputAcceptAttributeFromFileTypes()}
        ref={inputFilesRef}
        multiple
        onChange={onFileUploadChange}
        className={styles.uploadFiles_input}
      />
    </div>
  );
};

export default UploadFiles;
