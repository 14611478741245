import React, { useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { NO_BUTTON_LABEL, YES_BUTTON_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import {
  ADD_NEW_SECRET_BUTTON_LABEL,
  DELETE_CONFIRMATION_MESSAGE,
  ORG_SECRETS_HEADER,
  SECRETS_TABLE_COLUMNS_TITLE,
} from '@constants/secrets-management';
import { ISecret } from '@redux/types/types';
import DataTable from '@components/dataTable/DataTable';
import styles from './secretsTable.module.scss';
import CreateEditSecretModal from '../components/CreateSecretModal';
import useSecretsManagement from '../hooks/useSecretsManagement';

const PAGE_SIZE = 10;

const SecretsTable = () => {
  const {
    organizationSecrets,
    fetchOrganizationSecretsStatus,
    addOrganizationSecretStatus,
    deleteOrganizationSecretStatus,
    getOrganizationSecrets,
    handleAddNewSecret,
    handleDeleteSecret,
  } = useSecretsManagement();
  const [createSecretModalOpen, setCreateSecretModalOpen] = useState<boolean>(false);

  useEffectUpdateOnly(() => {
    if (
      addOrganizationSecretStatus === StatusCodes.SUCCESS ||
      deleteOrganizationSecretStatus === StatusCodes.SUCCESS
    ) {
      setCreateSecretModalOpen(false);
      getOrganizationSecrets();
    }
  }, [addOrganizationSecretStatus, deleteOrganizationSecretStatus]);

  const columns = [
    {
      title: SECRETS_TABLE_COLUMNS_TITLE.NAME,
      dataIndex: 'name',
      key: 'name',
      width: '90%',
    },
    {
      key: 'action',
      width: '10%',
      render: (_: unknown, secret: ISecret) => (
        <div className={styles.buttonContainer}>
          <Popconfirm
            title={DELETE_CONFIRMATION_MESSAGE}
            placement="top"
            onConfirm={() => handleDeleteSecret(secret.secret_id)}
            okText={YES_BUTTON_LABEL}
            cancelText={NO_BUTTON_LABEL}
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              disabled={deleteOrganizationSecretStatus === StatusCodes.IN_PROGRESS}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <CreateEditSecretModal
        open={createSecretModalOpen}
        onCancel={() => setCreateSecretModalOpen(false)}
        onSubmit={handleAddNewSecret}
        loading={addOrganizationSecretStatus === StatusCodes.IN_PROGRESS}
      />
      <div className={styles.header}>
        <h5>{ORG_SECRETS_HEADER}</h5>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setCreateSecretModalOpen(true)}
        >
          {ADD_NEW_SECRET_BUTTON_LABEL}
        </Button>
      </div>
      <DataTable
        data={organizationSecrets.data}
        total={organizationSecrets.total}
        rowKey="secret_id"
        border
        getData={(currentPage, pageSize) =>
          getOrganizationSecrets({
            pageNumber: currentPage,
            limit: pageSize,
          })
        }
        loading={fetchOrganizationSecretsStatus === StatusCodes.IN_PROGRESS}
        columns={columns}
        searchAvailable={false}
        rowSelection={false}
        pagination={{ pageSize: PAGE_SIZE }}
      />
    </div>
  );
};

export default SecretsTable;
