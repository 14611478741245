/* eslint-disable @typescript-eslint/naming-convention */
import produce from 'immer';
import { parseLabelingLabelsToDocSearchResult } from '@utils/search';
import { StatusCodes } from '@constants/enum/common';
import { ICreatedBy } from '@redux/types/commonTypes';
import {
  CREATE_DOCUMENT_RETRIEVAL_LABEL,
  CREATE_LABELING_PROJECT,
  DELETE_LABELING_PROJECT,
  FULFILLED,
  GET_LABELING_PROJECT,
  GET_LABELING_PROJECT_CREATOR,
  GET_LABELING_PROJECT_LABELS,
  GET_LABELING_PROJECT_LABELS_AS_LABELED_RESULTS,
  GET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT,
  GET_LABELING_PROJECT_LAST_EDITOR,
  GET_LABELING_PROJECT_STATS,
  GET_LABELING_PROJECTS,
  IAPIPaginationData,
  ILabelingProject,
  ILabelingProjectLabel,
  ILabelingProjectStats,
  ISearchResult,
  LabelRelevance,
  PENDING,
  REJECTED,
  RESET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT,
  UPDATE_DOCUMENT_RETRIEVAL_LABEL,
  UPDATE_LABELING_PROJECT,
} from '@redux/types/types';

interface IInitialStateProps {
  projects: IAPIPaginationData<ILabelingProject[]>;
  projectsStatus: StatusCodes;
  createProjectStatus: StatusCodes;
  updateProjectStatus: StatusCodes;
  project: ILabelingProject;
  projectStats: ILabelingProjectStats;
  projectStatus: StatusCodes;
  labeledResults: Record<
    string,
    {
      relevance?: LabelRelevance;
      labelId: string;
    }
  >;
  projectCreator: ICreatedBy;
  projectLastEditor: ICreatedBy;
  labels: IAPIPaginationData<ILabelingProjectLabel[]>;
  labelsStatus: StatusCodes;
  labelsAsSearchResult: ISearchResult | null;
  labelsAsSearchResultStatus: StatusCodes;
}

export const initialState: IInitialStateProps = {
  projects: {
    data: [],
    has_more: false,
    total: 0,
  },
  projectsStatus: StatusCodes.IDLE,
  createProjectStatus: StatusCodes.IDLE,
  updateProjectStatus: StatusCodes.IDLE,
  project: {
    project_id: '',
    name: '',
    pipeline_id: null,
    query_target: null,
    annotation_guideline: null,
    created_at: '',
    created_by: '',
  },
  projectStats: {
    total_labels: 0,
    labeled_queries: 0,
    labels_per_user: [],
    labeled_queries_per_user: [],
  },
  projectCreator: {
    given_name: '',
    family_name: '',
    user_id: '',
  },
  projectLastEditor: {
    given_name: '',
    family_name: '',
    user_id: '',
  },
  projectStatus: StatusCodes.IDLE,
  labeledResults: {},
  labels: {
    data: [],
    has_more: false,
    total: 0,
  },
  labelsStatus: StatusCodes.IDLE,
  labelsAsSearchResult: null,
  labelsAsSearchResultStatus: StatusCodes.IDLE,
};

const labelingReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case `${RESET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT}`:
        localDraft.labelsAsSearchResult = initialState.labelsAsSearchResult;
        localDraft.labelsAsSearchResultStatus = initialState.labelsAsSearchResultStatus;
        break;
      case `${GET_LABELING_PROJECTS}/${PENDING}`:
        localDraft.projectsStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_LABELING_PROJECTS}/${FULFILLED}`:
        localDraft.projectsStatus = StatusCodes.SUCCESS;
        localDraft.projects = action.payload;
        break;
      case `${CREATE_LABELING_PROJECT}/${PENDING}`: {
        localDraft.createProjectStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${CREATE_LABELING_PROJECT}/${FULFILLED}`: {
        localDraft.createProjectStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${CREATE_LABELING_PROJECT}/${REJECTED}`: {
        localDraft.createProjectStatus = StatusCodes.ERROR;
        break;
      }
      case `${GET_LABELING_PROJECT_CREATOR}/${FULFILLED}`: {
        const { given_name, family_name, user_id } = action.payload;
        localDraft.projectCreator = { given_name, family_name, user_id };
        break;
      }
      case `${GET_LABELING_PROJECT_LAST_EDITOR}/${FULFILLED}`: {
        const { given_name, family_name, user_id } = action.payload;
        localDraft.projectLastEditor = { given_name, family_name, user_id };
        break;
      }
      case `${GET_LABELING_PROJECT}/${PENDING}`: {
        localDraft.projectStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${GET_LABELING_PROJECT}/${FULFILLED}`: {
        localDraft.projectStatus = StatusCodes.SUCCESS;
        localDraft.project = action.payload;
        break;
      }
      case `${GET_LABELING_PROJECT_STATS}/${FULFILLED}`:
        localDraft.projectStats = action.payload;
        break;
      case `${UPDATE_LABELING_PROJECT}/${PENDING}`: {
        localDraft.updateProjectStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${UPDATE_LABELING_PROJECT}/${FULFILLED}`: {
        localDraft.updateProjectStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${UPDATE_LABELING_PROJECT}/${REJECTED}`: {
        localDraft.updateProjectStatus = StatusCodes.ERROR;
        break;
      }
      case `${DELETE_LABELING_PROJECT}/${FULFILLED}`:
        localDraft.projectStatus = StatusCodes.SUCCESS;
        break;
      case `${DELETE_LABELING_PROJECT}/${REJECTED}`:
        localDraft.updateProjectStatus = StatusCodes.ERROR;
        break;
      case `${UPDATE_DOCUMENT_RETRIEVAL_LABEL}/${FULFILLED}`:
      case `${CREATE_DOCUMENT_RETRIEVAL_LABEL}/${FULFILLED}`: {
        const { resultId, relevance, labelId } = action.payload;
        localDraft.labeledResults[resultId] = { relevance, labelId };
        break;
      }
      case `${GET_LABELING_PROJECT_LABELS}/${PENDING}`:
      case `${GET_LABELING_PROJECT_LABELS_AS_LABELED_RESULTS}/${PENDING}`: {
        const { fetchMore } = action.meta.arg;
        if (fetchMore) break;
        localDraft.labelsStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${GET_LABELING_PROJECT_LABELS}/${FULFILLED}`: {
        const { fetchMore } = action.meta.arg;
        localDraft.labelsStatus = StatusCodes.SUCCESS;

        if (fetchMore)
          localDraft.labels = {
            ...action.payload,
            data: [...localDraft.labels.data, ...action.payload.data],
          };
        else localDraft.labels = action.payload;
        break;
      }
      case `${GET_LABELING_PROJECT_LABELS}/${REJECTED}`:
      case `${GET_LABELING_PROJECT_LABELS_AS_LABELED_RESULTS}/${REJECTED}`: {
        localDraft.labelsStatus = StatusCodes.ERROR;
        break;
      }
      case `${GET_LABELING_PROJECT_LABELS_AS_LABELED_RESULTS}/${FULFILLED}`: {
        action.payload.forEach((label: ILabelingProjectLabel) => {
          const { search_result_history_id: resultId, relevance, label_id: labelId } = label;
          localDraft.labeledResults[resultId] = { relevance, labelId };
        });
        break;
      }
      case `${GET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT}/${PENDING}`: {
        localDraft.labelsAsSearchResultStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${GET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT}/${FULFILLED}`: {
        localDraft.labelsAsSearchResultStatus = StatusCodes.SUCCESS;
        const labels: ILabelingProjectLabel[] = action.payload;
        const sortedLabels = [...labels].sort((a, b) => a.rank - b.rank);
        const parsedLabelsToSearchResult = parseLabelingLabelsToDocSearchResult(sortedLabels);
        localDraft.labelsAsSearchResult = parsedLabelsToSearchResult;
        break;
      }
      case `${GET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT}/${REJECTED}`: {
        localDraft.labelsAsSearchResultStatus = StatusCodes.ERROR;
        break;
      }
      default:
        break;
    }

    return localDraft;
  });
};

export default labelingReducer;
