import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { SOURCE_FILE_LABEL } from '@constants/search';
import { SearchFileAction, SearchResultMeta } from '@redux/types/types';
import styles from './searchResultFileSource.module.scss';

interface ISearchResultSingleFileSourceProps {
  resultId: string;
  file?: {
    id: string;
    name: string;
  };
  meta: SearchResultMeta;
  isFileActionTakingPlace: Record<string, boolean>;
  searching: boolean;
  fileDropdownMenuItems: MenuProps['items'];
  onFileDropdownItemClick: (params: { key: SearchFileAction; id: string; name: string }) => void;
  displayFileOptions?: boolean;
}

const SearchResultSingleFileSource = ({
  resultId,
  file,
  meta,
  isFileActionTakingPlace,
  searching,
  fileDropdownMenuItems,
  onFileDropdownItemClick,
  displayFileOptions = true,
}: ISearchResultSingleFileSourceProps) => {
  let { id = '', name = '' } = file || {};

  if (!name) return null;

  if (!id) {
    id = resultId;
    name = meta?.name as string;
  }

  const content = !displayFileOptions ? (
    <span className={styles.source_fileName}>{name}</span>
  ) : (
    <Button
      className={styles.source_options}
      type="link"
      onClick={(e) => e.preventDefault()}
      loading={isFileActionTakingPlace[id]}
      disabled={searching}
    >
      {name}
      <DownOutlined className={styles.source_options_icon} />
    </Button>
  );

  return (
    <div className={styles.source} data-testid="searchResult_single_file_source">
      <span>{SOURCE_FILE_LABEL}:</span>
      <Dropdown
        disabled={!displayFileOptions}
        menu={{
          items: fileDropdownMenuItems,
          onClick: ({ key }) =>
            onFileDropdownItemClick({
              key: key as SearchFileAction,
              id,
              name,
            }),
        }}
        trigger={['click']}
      >
        {content}
      </Dropdown>
    </div>
  );
};

export default SearchResultSingleFileSource;
