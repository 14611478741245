import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const pipelineFeedbackSelector = (state: RootState) => state.pipelineFeedbackStore;

export const pipelineFeedbackDataSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineFeedback) => pipelineFeedback.pipelineFeedback,
);

export const getPipelineFeedbackStatusSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineFeedback) => pipelineFeedback.getPipelineFeedbackStatus,
);

export const feedbackSortValueSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineStore) => pipelineStore.feedbackSortValue,
);

export const pipelineFeedbackFilterValuesSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineStore) => pipelineStore.pipelineFeedbackFilterValues,
);

export const providedFeedbackByResultIdSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineFeedback) => pipelineFeedback.providedFeedbackByResultId,
);

export const providePipelineFeedbackStatusSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineFeedback) => pipelineFeedback.providedPipelineFeedbackStatus,
);

export const updatedPipelineFeedbackStatusSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineFeedback) => pipelineFeedback.updatedPipelineFeedbackStatus,
);

export const pipelineFeedbackTagsByPipelineIdSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineFeedback) => pipelineFeedback.pipelineFeedbackTagsByPipelineId,
);

export const createPipelineFeedbackTagStatusSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineFeedback) => pipelineFeedback.createPipelineFeedbackTagStatus,
);

export const deletePipelineFeedbackTagStatusSelector = createSelector(
  pipelineFeedbackSelector,
  (pipelineFeedback) => pipelineFeedback.deletePipelineFeedbackTagStatus,
);
