import React from 'react';
import { useSelector } from 'react-redux';
import { interpolateString } from '@utils/string';
import { ORGANIZATION_PLAN_INFO, USAGE_METRIC_MAP } from '@constants/metering';
import { organizationSelector } from '@redux/selectors/organizationSelectors';
import { IOrganizationData } from '@redux/types/types';
import useUsageDateRange from '@pages/metering/hooks/useUsageDateRange';
import styles from './meteringUsage.module.scss';
import UsageCard from '../../components/UsageCard';
import useMeteringUsage from '../../hooks/useMeteringUsage';

const MeteringUsage = () => {
  const { usageLimits, usageMetrics } = useMeteringUsage();
  const { currentUsageCycleText, currentUsageCycleDate } = useUsageDateRange();
  const { organizationName }: IOrganizationData = useSelector(organizationSelector);
  type UsageMetricsKey = keyof typeof usageMetrics;

  const usageMetricsOrderedKeys: UsageMetricsKey[] = [
    'development_pipelines',
    'production_pipelines',
    'document_storage_units',
    'development_hours',
    'production_hours',
  ];

  return (
    <div className={styles.container}>
      <UsageCard
        key="organization"
        label={ORGANIZATION_PLAN_INFO.label}
        metric={organizationName}
        limit={{
          current: currentUsageCycleDate.current,
          target: currentUsageCycleDate.to,
          name: interpolateString(ORGANIZATION_PLAN_INFO.limitLabel, {
            usageCycle: currentUsageCycleText,
          }) as string,
          targetLabel: currentUsageCycleDate.to
            ? (interpolateString(ORGANIZATION_PLAN_INFO.targetLabel, {
                days: currentUsageCycleDate.to - currentUsageCycleDate.current,
              }) as string)
            : '',
        }}
      />
      {usageMetricsOrderedKeys.map((keyName) => {
        const key = keyName as UsageMetricsKey;
        const metric = usageMetrics[key];
        const metricConsts = USAGE_METRIC_MAP[key];
        return (
          <UsageCard
            key={keyName}
            label={metricConsts.label}
            metric={metric}
            tooltip={metricConsts.tooltip}
            metricSuffix={metricConsts.metricUnit}
            limit={{
              current: metric,
              target: usageLimits?.[key] || null,
              name: metricConsts.limitLabel,
            }}
            showLimitInfo={!!usageLimits}
          />
        );
      })}
    </div>
  );
};

export default MeteringUsage;
