import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import yamlWorker from './yaml.worker?worker';

self.MonacoEnvironment = {
  getWorker(_, label: string) {
    if (label === 'json') return new jsonWorker();
    if (label === 'html') return new htmlWorker();
    if (label === 'yaml') return new yamlWorker();
    return new editorWorker();
  },
};
