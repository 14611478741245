import produce from 'immer';
import { RUN_STATUS_TAG_MAP } from '@constants/common';
import { FiltersProp } from '@constants/data-table';
import { DEFAULT_PREDICTIONS_SORTING_KEY, DEFAULT_SORTING_KEY } from '@constants/experiments';
import { PipelineNodeTypes } from '@constants/pipelines';
import {
  CREATE_EVALUATION_RUN,
  DELETE_EVALUATION_RUN,
  DELETE_MULTIPLE_EVALUATION_RUNS,
  DOWNLOAD_EVALUATION_RUN,
  FULFILLED,
  GET_EVALUATION_RUN,
  GET_EXPERIMENTS,
  GET_FILTER_STATUSES,
  GET_FILTER_TAGS,
  GET_FILTER_USERS,
  GET_RUN_PREDICTIONS,
  GET_TAGS,
  IAPIPaginationData,
  IExperiment,
  IMessage,
  IStatusFilter,
  ITag,
  IUserFilter,
  PENDING,
  REJECTED,
  RESET_CURRENT_EXPERIMENT,
  RESET_EXPERIMENTS_MESSAGE,
  RESET_NEW_EVALUATION_RUN_STATUS,
  RunState,
  SAVE_AND_START_EVALUATION_RUN,
  SELECT_PREDICTIONS_SORT_VALUE,
  SELECT_SORT_VALUE,
  START_EVALUATION_RUN,
  START_MULTIPLE_EVALUATION_RUNS,
  UPDATE_EVALUATION_RUN,
  UPDATE_EVALUATION_RUN_COMMENT,
} from '@redux/types/types';
import { MessageCodes, StatusCodes } from '@src/constants/enum/common';
import { normalizeErrorMessage } from '@src/utils/error';

interface IInitialStateProps {
  experiments: IAPIPaginationData<IExperiment[]>;
  current: {
    evalRun: IExperiment;
    predictions: any;
  };
  filtersValues: Record<string, FiltersProp | []>;
  sortValue: string;
  predictionsSortValueByNode: Record<string, string>;
  workspaceTags: ITag[];
  message: IMessage;
  newEvalRunMessage: IMessage;
  actionStatus: StatusCodes;
  status: StatusCodes;
  newEvalRunStatus: StatusCodes;
}

export const initialState: IInitialStateProps = {
  experiments: {
    data: [],
    has_more: false,
    total: 0,
  },
  filtersValues: {
    tags: [],
    statuses: [],
    users: [],
  },
  sortValue: DEFAULT_SORTING_KEY,
  predictionsSortValueByNode: {
    [PipelineNodeTypes.ANSWER_NODE]: DEFAULT_PREDICTIONS_SORTING_KEY,
    [PipelineNodeTypes.DOCUMENT_NODE]: DEFAULT_PREDICTIONS_SORTING_KEY,
    [PipelineNodeTypes.GENERATIVE_NODE]: DEFAULT_PREDICTIONS_SORTING_KEY,
  },
  current: {
    evalRun: {
      comment: '',
      created_at: '',
      created_by: {
        given_name: '',
        family_name: '',
        user_id: '',
      },
      eval_run_id: '',
      eval_results: [],
      logs: [],
      name: '',
      parameters: {
        pipeline_name: '',
        evaluation_set_name: '',
        pipeline_snapshot_at: '',
        pipeline_snapshot_yaml: '',
      },
      status: RunState.CREATED,
      tags: [],
      last_edited_at: null,
      last_edited_by: null,
    },
    predictions: {},
  },
  workspaceTags: [],
  message: {
    type: MessageCodes.INFO,
    content: '',
  },
  newEvalRunMessage: {
    type: MessageCodes.INFO,
    content: '',
  },
  actionStatus: StatusCodes.IDLE,
  status: StatusCodes.IDLE,
  newEvalRunStatus: StatusCodes.IDLE,
};

function experimentsReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_EXPERIMENTS_MESSAGE:
        localDraft.message = initialState.message;
        localDraft.newEvalRunMessage = initialState.message;
        localDraft.actionStatus = StatusCodes.IDLE;
        localDraft.newEvalRunStatus = StatusCodes.IDLE;
        break;
      case RESET_CURRENT_EXPERIMENT:
        localDraft.current = initialState.current;
        break;
      case RESET_NEW_EVALUATION_RUN_STATUS:
        localDraft.newEvalRunStatus = StatusCodes.IDLE;
        break;
      case SELECT_SORT_VALUE:
        localDraft.sortValue = action.payload;
        break;
      case SELECT_PREDICTIONS_SORT_VALUE: {
        const { nodeType, value } = action.payload;
        localDraft.predictionsSortValueByNode[nodeType] = value;
        break;
      }
      case `${START_EVALUATION_RUN}/${PENDING}`:
      case `${START_MULTIPLE_EVALUATION_RUNS}/${PENDING}`:
      case `${UPDATE_EVALUATION_RUN}/${PENDING}`:
      case `${DELETE_EVALUATION_RUN}/${PENDING}`:
      case `${DELETE_MULTIPLE_EVALUATION_RUNS}/${PENDING}`:
      case `${SAVE_AND_START_EVALUATION_RUN}/${PENDING}`:
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${CREATE_EVALUATION_RUN}/${PENDING}`:
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        localDraft.newEvalRunStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_EVALUATION_RUN}/${FULFILLED}`: {
        localDraft.status = StatusCodes.IDLE;

        const evalRunCompletedSuccessfully =
          localDraft.current.evalRun.status === RunState.STARTED &&
          action.payload.status === RunState.ENDED;
        if (evalRunCompletedSuccessfully)
          localDraft.message = {
            type: MessageCodes.SUCCESS,
            content: 'Evaluation run completed successfully.',
          };

        localDraft.current.evalRun = action.payload;
        break;
      }
      case `${GET_RUN_PREDICTIONS}/${FULFILLED}`:
        localDraft.current.predictions = action.payload;
        break;
      case `${GET_EXPERIMENTS}/${FULFILLED}`:
        localDraft.status = StatusCodes.SUCCESS;
        localDraft.experiments = action.payload;
        break;
      case `${GET_FILTER_TAGS}/${FULFILLED}`:
        localDraft.status = StatusCodes.SUCCESS;
        localDraft.filtersValues = {
          ...localDraft.filtersValues,
          tags: action.payload.map(({ name: label, tag_id: key }: ITag) => ({ key, label })),
        };
        break;
      case `${GET_FILTER_STATUSES}/${FULFILLED}`:
        localDraft.status = StatusCodes.SUCCESS;
        localDraft.filtersValues = {
          ...localDraft.filtersValues,
          statuses: action.payload.map(({ status }: IStatusFilter) => ({
            key: status,
            label: RUN_STATUS_TAG_MAP[status].label,
          })),
        };
        break;
      case `${GET_FILTER_USERS}/${FULFILLED}`:
        localDraft.status = StatusCodes.SUCCESS;
        localDraft.filtersValues = {
          ...localDraft.filtersValues,
          users: action.payload.map(({ given_name: label, user_id: key }: IUserFilter) => ({
            key,
            label,
          })),
        };
        break;
      case `${CREATE_EVALUATION_RUN}/${FULFILLED}`:
        localDraft.newEvalRunStatus = StatusCodes.SUCCESS;
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Evaluation run created successfully.',
        };
        break;
      case `${SAVE_AND_START_EVALUATION_RUN}/${FULFILLED}`:
        localDraft.newEvalRunStatus = StatusCodes.SUCCESS;
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Your experiment is running.',
        };
        break;
      case `${START_EVALUATION_RUN}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Your experiment is running.',
        };
        break;
      case `${START_MULTIPLE_EVALUATION_RUNS}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Evaluation runs started.',
        };
        break;
      case `${UPDATE_EVALUATION_RUN}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Evaluation run updated successfully.',
        };
        break;
      case `${DELETE_EVALUATION_RUN}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Evaluation run deleted successfully.',
        };
        break;
      case `${DELETE_MULTIPLE_EVALUATION_RUNS}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: 'Evaluation runs deleted successfully.',
        };
        break;
      case `${GET_TAGS}/${FULFILLED}`:
        localDraft.workspaceTags = action.payload;
        break;
      case `${CREATE_EVALUATION_RUN}/${REJECTED}`:
      case `${SAVE_AND_START_EVALUATION_RUN}/${REJECTED}`:
        localDraft.actionStatus = StatusCodes.ERROR;
        localDraft.newEvalRunStatus = StatusCodes.ERROR;
        localDraft.newEvalRunMessage = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;
      case `${START_EVALUATION_RUN}/${REJECTED}`:
      case `${START_MULTIPLE_EVALUATION_RUNS}/${REJECTED}`:
      case `${DELETE_EVALUATION_RUN}/${REJECTED}`:
      case `${DELETE_MULTIPLE_EVALUATION_RUNS}/${REJECTED}`:
      case `${UPDATE_EVALUATION_RUN_COMMENT}/${REJECTED}`:
      case `${DOWNLOAD_EVALUATION_RUN}/${REJECTED}`:
      case `${UPDATE_EVALUATION_RUN}/${REJECTED}`:
        localDraft.actionStatus = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;
      case `${GET_EVALUATION_RUN}/${REJECTED}`:
      case `${GET_EXPERIMENTS}/${REJECTED}`:
      case `${GET_FILTER_TAGS}/${REJECTED}`:
      case `${GET_FILTER_STATUSES}/${REJECTED}`:
      case `${GET_FILTER_USERS}/${REJECTED}`:
      case `${GET_RUN_PREDICTIONS}/${REJECTED}`:
        localDraft.status = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;

      default:
        break;
    }

    return localDraft;
  });
}

export default experimentsReducer;
