import { datadogRum } from '@datadog/browser-rum';

const datadogRumAppId = import.meta.env.VITE_DATADOGRUM_APP_ID;
const datadogRumClientToken = import.meta.env.VITE_DATADOGRUM_CLIENT_TOKEN;
const datadogRumSite = import.meta.env.VITE_DATADOGRUM_SITE;
const datadogRumService = import.meta.env.VITE_DATADOGRUM_SERVICE;
const datadogRumEnv = import.meta.env.VITE_DATADOGRUM_ENV;

export const dataDogRumConfig = () => {
  // Check if all required values are set
  if (
    !datadogRumAppId ||
    !datadogRumClientToken ||
    !datadogRumSite ||
    !datadogRumService ||
    !datadogRumEnv
  ) {
    console.log(
      'RUM configuration skipped: One or more required environment variables are not set.',
    );
    return;
  }

  // Initialize Datadog RUM if all values are set
  datadogRum.init({
    applicationId: datadogRumAppId,
    clientToken: datadogRumClientToken,
    site: datadogRumSite,
    service: datadogRumService,
    env: datadogRumEnv,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackFrustrations: true,
  });

  // Start session replay recording
  datadogRum.startSessionReplayRecording();
};
