import React from 'react';
import { Badge, theme, Tooltip } from 'antd';
import { PipelineServiceLevelTooltipLabels } from '@constants/pipelines';
import { PipelineServiceLevel } from '@redux/types/types';

interface IPipelineServiceLevelBadge {
  serviceLevel: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const { useToken } = theme;

const PipelineServiceLevelBadge = (props: IPipelineServiceLevelBadge) => {
  const { serviceLevel, tooltipPosition } = props;
  const { token } = useToken();

  const getTooltipTitle = () => {
    switch (serviceLevel) {
      case PipelineServiceLevel.PRODUCTION:
        return PipelineServiceLevelTooltipLabels.PRODUCTION;
      case PipelineServiceLevel.DEVELOPMENT:
        return PipelineServiceLevelTooltipLabels.DEVELOPMENT;
      default:
        return PipelineServiceLevelTooltipLabels.DRAFT;
    }
  };

  const getBadgeColor = () => {
    switch (serviceLevel) {
      case PipelineServiceLevel.PRODUCTION:
        return token.colorSuccess;
      case PipelineServiceLevel.DEVELOPMENT:
        return token.colorPrimary;
      default:
        return token.colorTextDisabled;
    }
  };

  return (
    <Tooltip placement={tooltipPosition || 'bottom'} title={getTooltipTitle()}>
      <Badge
        color={getBadgeColor()}
        status={serviceLevel === PipelineServiceLevel.PRODUCTION ? 'processing' : 'default'}
      />
    </Tooltip>
  );
};

export default PipelineServiceLevelBadge;
