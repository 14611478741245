import React, { ReactElement } from 'react';
import { Button, Popover } from 'antd';
import { VIEW_REFERENCE_BUTTON_LABEL } from '@constants/search';
import styles from './searchDocumentReferencePopover.module.scss';

interface ISearchDocumentReferencePopoverProps {
  content: string;
  buttonLabel: string;
  displayPopover?: boolean;
  onViewReference?: () => void;
}

const SearchDocumentReferencePopover = ({
  content,
  buttonLabel,
  displayPopover = true,
  onViewReference,
}: ISearchDocumentReferencePopoverProps) => {
  const renderContent = () => {
    return (
      <div className={styles.documentReferencePopover_content}>
        ...{content.slice(0, 500)}...
        {onViewReference && (
          <Button type="link" onClick={onViewReference}>
            {VIEW_REFERENCE_BUTTON_LABEL}
          </Button>
        )}
      </div>
    );
  };

  const withPoppover = (children: ReactElement) => {
    if (!displayPopover) return children;

    return (
      <Popover
        rootClassName={styles.documentReferencePopover_popover}
        content={renderContent()}
        trigger="hover"
        arrow={false}
      >
        {children}
      </Popover>
    );
  };

  return (
    <span className={styles.documentReferencePopover}>
      {withPoppover(
        <Button type="link" onClick={onViewReference}>
          {buttonLabel}
        </Button>,
      )}
    </span>
  );
};

export default SearchDocumentReferencePopover;
