import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ArrowLeftOutlined, WarningFilled } from '@ant-design/icons';
import { Alert, Button, Drawer, Steps } from 'antd';
import { isValidDataName } from '@utils/string';
import { COMMON_FORM_ERRORS } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import {
  DATASET_SECTION_HEADER,
  DATASET_SECTION_TITLE,
  EVALUATION_SECTION_HEADER,
  EVALUATION_SECTION_TITLE,
  EXPERIMENT_DRAWER_TITLE,
  NAME_INPUT_LABEL,
  NAME_INPUT_PLACEHOLDER,
  NAMING_SECTION_HEADER,
  NAMING_SECTION_TITLE,
  NEXT_BUTTON_LABEL,
  PIPELINE_SECTION_HEADER,
  PIPELINE_SECTION_TITLE,
  PIPELINE_VERSION_NOT_SUPPORTED_ALERT_MESSAGE,
  SAVE_DRAFT_BUTTON_LABEL,
  SUBMIT_BUTTON_LABEL,
  TAGS_SELECT_LABEL,
  TAGS_SELECT_PLACEHOLDER,
} from '@constants/experiments';
import {
  DeepsetCloudVersion,
  IAPIPaginationData,
  IEvalset,
  IMessage,
  IPipeline,
  ITag,
} from '@redux/types/types';
import EvalSetSelectionTable from '@components/evalRuns/evalSetSelectionTable/EvalSetSelectionTable';
import PipelineSelectionTable from '@components/pipelineSelectionTable/PipelineSelectionTable';
import RunNamingForm from '@components/runs/runNamingForm/RunNamingForm';
import styles from './newEvalRun.module.scss';
import DatasetSelectionFilters from '../datasetSelectionFilters/DatasetSelectionFilters';

interface INewEvalRunProps {
  pipelines: IAPIPaginationData<IPipeline[]>;
  evalSets: IAPIPaginationData<IEvalset[]>;
  visible: boolean;
  workspaceTags: ITag[];
  newEvalRunStatus: StatusCodes;
  message?: IMessage;
  onClose: () => void;
  onCreateNewRun: (name: string, pipelineName: string, evalsetName: string, tags: string[]) => void;
  onSaveAndStartRun: (
    name: string,
    pipelineName: string,
    evalsetName: string,
    tags: string[],
  ) => void;
  getPipelines: (currentPage: number, pageSize: number, searchValue: string) => void;
  getEvalsets: (currentPage: number, pageSize: number, searchValue: string) => void;
}

const NewEvalRun = (props: INewEvalRunProps) => {
  const {
    pipelines,
    evalSets,
    visible,
    workspaceTags,
    newEvalRunStatus,
    onClose,
    onCreateNewRun,
    onSaveAndStartRun,
    getPipelines,
    getEvalsets,
    message,
  } = props;
  const [searchParams] = useSearchParams();
  const withDatasetSelection = searchParams.get('withDatasetSelection');
  const [currentStep, setCurrentStep] = useState(0);
  const [pipelineName, setPipelineName] = useState('');
  const [evalsetName, setEvalsetName] = useState('');
  const [runName, setRunName] = useState('');
  const [runTags, setRunTags] = useState<string[]>([]);

  useEffect(() => {
    if (newEvalRunStatus === StatusCodes.SUCCESS) {
      onClose();
    }
  }, [newEvalRunStatus]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const onSelectPipeline = (pipeline: IPipeline) => {
    setPipelineName(pipeline.name);
  };

  const onSelectEvalset = (evalset: IEvalset) => {
    setEvalsetName(evalset.name);
  };

  const onSaveDraft = () => {
    onCreateNewRun(runName, pipelineName, evalsetName, runTags);
  };

  const onStart = () => {
    onSaveAndStartRun(runName, pipelineName, evalsetName, runTags);
  };

  const thereArePipelinesV2 = () => {
    return pipelines?.data?.find(
      (pipeline) => pipeline.deepset_cloud_version === DeepsetCloudVersion.V2,
    );
  };

  const onCloseDrawer = () => {
    onClose();
  };

  const renderNoSupportedPipelinesAlert = () => {
    if (thereArePipelinesV2()) {
      return (
        <Alert
          message={PIPELINE_VERSION_NOT_SUPPORTED_ALERT_MESSAGE}
          type="warning"
          showIcon
          icon={<WarningFilled className={styles.warningIcon} />}
          banner
        />
      );
    }

    return null;
  };

  const namingSection = () => {
    const handleChange = (value: string[]) => {
      setRunTags(value);
    };

    return (
      <div className={styles.fullContent}>
        <h4 className={styles.stepsHeader}>
          <Button icon={<ArrowLeftOutlined />} type="text" onClick={() => prevStep()} />
          {NAMING_SECTION_HEADER}
        </h4>
        <div className={styles.namingSectionActions}>
          <RunNamingForm
            nameValue={runName || ''}
            nameInputInfo={{
              testId: 'newEvalRun_set_name_input',
              label: NAME_INPUT_LABEL,
              placeholder: NAME_INPUT_PLACEHOLDER,
              validationErrorText: COMMON_FORM_ERRORS.INVALID_CHARACTERS,
            }}
            onNameInputChange={(e) => setRunName(e.currentTarget.value.replace(/\s/g, ''))}
            validName={!runName || isValidDataName(runName)}
            tagSelectInfo={{
              testId: 'newEvalRun_set_tags_input',
              label: TAGS_SELECT_LABEL,
              placeholder: TAGS_SELECT_PLACEHOLDER,
            }}
            selectedTags={runTags}
            onSelectTags={handleChange}
            tags={workspaceTags}
          />
        </div>
      </div>
    );
  };

  const steps = [
    {
      title: PIPELINE_SECTION_TITLE,
      content: (
        <div className={styles.fullContent}>
          <h4>{PIPELINE_SECTION_HEADER}</h4>
          <PipelineSelectionTable
            pipelines={pipelines}
            selectedPipelineName={pipelineName}
            onSelectPipeline={onSelectPipeline}
            getPipelines={getPipelines}
          />
        </div>
      ),
    },
    {
      title: EVALUATION_SECTION_TITLE,
      content: (
        <EvalSetSelectionTable
          title={EVALUATION_SECTION_HEADER}
          evalSets={evalSets}
          evalSetName={evalsetName}
          onSelectEvalSet={onSelectEvalset}
          onBackButtonPressed={() => prevStep()}
          getEvalSets={getEvalsets}
        />
      ),
    },
    ...[
      withDatasetSelection
        ? {
            title: DATASET_SECTION_TITLE,
            content: (
              <DatasetSelectionFilters
                title={DATASET_SECTION_HEADER}
                onBackButtonPressed={() => prevStep()}
              />
            ),
          }
        : null,
    ],
    {
      title: NAMING_SECTION_TITLE,
      content: namingSection(),
    },
  ].filter(Boolean) as {
    title: string;
    content: React.JSX.Element;
  }[];

  return (
    <Drawer
      title={EXPERIMENT_DRAWER_TITLE}
      size="large"
      onClose={onCloseDrawer}
      open={visible}
      className={styles.container}
      extra={
        <div className={styles.drawer_extra_container}>
          {currentStep < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => nextStep()}
              disabled={!pipelineName || (!evalsetName && currentStep === 1)}
              data-testid="newEvalRun_next_button"
            >
              {NEXT_BUTTON_LABEL}
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <>
              <Button
                className={styles.drawer_saveButton}
                onClick={onSaveDraft}
                disabled={!isValidDataName(runName)}
              >
                {SAVE_DRAFT_BUTTON_LABEL}
              </Button>
              <Button
                type="primary"
                onClick={onStart}
                disabled={!isValidDataName(runName)}
                data-testid="newEvalRun_start_button"
              >
                {SUBMIT_BUTTON_LABEL}
              </Button>
            </>
          )}
        </div>
      }
    >
      {message && message.content && <Alert message={message.content} type={message.type} banner />}
      {renderNoSupportedPipelinesAlert()}
      <div className={styles.body}>
        <Steps current={currentStep} size="small" items={steps} />
        {steps[currentStep].content}
      </div>
    </Drawer>
  );
};

export default NewEvalRun;
