import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { isPipelineDeployed, isPipelineInProduction, pipelineInProgress } from '@utils/pipelines';
import { isValidDataName } from '@utils/string';
import { useCurrentRoute } from '@hooks/useCurrentRoute';
import usePipelineActions from '@hooks/usePipelineActions';
import { usePreventPageExitPrompt } from '@hooks/usePreventPageExitPrompt';
import { CANCEL_BUTTON_LABEL, COMMON_FORM_ERRORS } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import { EventControlStudioElement, UserEventType } from '@constants/event';
import {
  DEPLOY_BUTTON_LABEL,
  DEPLOYING_BUTTON_LABEL,
  DRAFT_LABEL,
  PREVENT_PAGE_EXIT_MESSAGE,
  RESET_BUTTON_LABEL,
  RESET_TOOLTIP_MESSAGE,
  SAVE_BUTTON_LABEL,
  STUDIO_ROUTE_NAME,
  UNDEPLOY_BUTTON_LABEL,
} from '@constants/pipeline-designer';
import {
  CREATE_PIPELINE_MODAL_PIPELINE_NAME_PLACEHOLDER,
  EDIT_PIPELINE_NAME_LABEL,
  PIPELINE_PRODUCTION_LABEL,
  PIPELINE_PRODUCTION_TOOLTIP_LABEL,
  PipelineStatusCodes,
  UNDEPLOYING_BUTTON_LABEL,
} from '@constants/pipelines';
import { userSelector } from '@redux/selectors/userSelectors';
import {
  DeepsetCloudVersion,
  IPipeline,
  IUserData,
  PipelineServiceLevel,
} from '@redux/types/types';
import PipelineConfirmationModal from '@components/pipelines/pipelineConfirmationModal/PipelineConfirmationModal';
import PipelineDeepsetCloudVersionTag from '@components/pipelines/pipelineDeepsetCloudVersionTag/PipelineDeepsetCloudVersionTag';
import PipelineServiceLevelBadge from '@components/pipelines/pipelineServiceLevelBadge/PipelineServiceLevelBadge';
import PipelineServiceLevelSwitch from '@components/pipelines/pipelineServiceLevelSwitch/PipelineServiceLevelSwitch';
import PipelineStatusTag from '@components/pipelineStatusTag/PipelineStatusTag';
import styles from './pipelineHeader.module.scss';
import useCodeEditor from '../../hooks/useCodeEditor';
import usePipelineEditor from '../../hooks/usePipelineEditor';

interface IPipelineEditorHeader {
  savePipeline?: () => void;
}

function PipelineEditorHeader(props: IPipelineEditorHeader) {
  const dispatch = useDispatch();

  const { savePipeline } = props;

  const {
    resetNewPipelineName,
    resetRenamePipelineNameStatus,
    fetchPipelineIndexing,
    deployPipeline,
    undeployPipeline,
    resetPipelineErrors,
    resetFetchPipelineYamlStatus,
    resetPipeline,
    resetPipelineYaml,
    fetchPipeline,
    fetchPipelineYaml,
    resetValidateNewPipelineName,
    startPollingPipelineStatus,
    stopPollingPipelineStatus,
    setPipelineName,
    setDeepsetCloudVersion,
  } = usePipelineActions();

  const { resetActiveTabYamlEditor, resetEditedCode } = useCodeEditor();
  const { isLimitedUser }: IUserData = useSelector(userSelector);

  const {
    pipeline,
    name,
    status,
    indexingYaml,
    queryYaml,
    editedIndexingYaml,
    editedQueryYaml,
    serviceLevel,
    renamePipelineNameStatus,
    fetchPipelineYamlStatus,
    pipelineCreateUpdatePipelineStatus,
    pipelineIndexingData,
    loadingButton,
    setLoadingButton,
    savingPipeline,
    setSavingPipeline,
    editPipelineName,
    setEditPipelineName,
    openPipelineConfirmationModal,
    setOpenPipelineConfirmationModal,
    successfulValidationPipelineName,
    setSuccessfulValidationPipelineName,
    pipelineNameInputRef,
    editPipelineNameButtonRef,
    newPipelineName,
    setNewPipelineName,
    pipelinePendingFileCount,
    getPipelineIssues,
    resetCode,
    onRenamePipeline,
    onSavePipeline,
    getEditedYaml,
    sendMetricsDcStudio,
  } = usePipelineEditor();

  const currentRoute = useCurrentRoute();
  const { pipelineName } = useParams() as { pipelineName: string };
  const isNewPipeline = !name || !pipelineName;

  usePreventPageExitPrompt(PREVENT_PAGE_EXIT_MESSAGE, !!editedIndexingYaml || !!editedQueryYaml);

  useEffect(() => {
    if (currentRoute.header.title === STUDIO_ROUTE_NAME && isNewPipeline) {
      resetPipeline();
      setDeepsetCloudVersion(DeepsetCloudVersion.V2);
    }

    return () => {
      resetNewPipelineName();
      resetPipelineErrors();
      resetFetchPipelineYamlStatus();
      resetPipelineYaml();
      resetActiveTabYamlEditor();
      resetEditedCode();
    };
  }, []);

  useEffect(() => {
    if (pipelineName) {
      fetchPipeline(pipelineName);
      fetchPipelineYaml(pipelineName);
      setNewPipelineName(pipelineName);
      resetValidateNewPipelineName();
      resetRenamePipelineNameStatus();
    }
  }, [dispatch, pipelineName]);

  useEffect(() => {
    if (pipeline.name === pipelineName && fetchPipelineYamlStatus === StatusCodes.SUCCESS)
      getPipelineIssues(pipelineName);
  }, [indexingYaml, queryYaml, pipeline.name, fetchPipelineYamlStatus]);

  useEffect(() => {
    if (newPipelineName && name && newPipelineName !== name) {
      resetValidateNewPipelineName();
      resetRenamePipelineNameStatus();
      setSuccessfulValidationPipelineName(isValidDataName(newPipelineName));
    } else setSuccessfulValidationPipelineName(true);
  }, [newPipelineName]);

  useEffect(() => {
    if (editPipelineName)
      pipelineNameInputRef.current?.focus({
        cursor: 'end',
      });
  }, [editPipelineName]);

  useEffect(() => {
    if (pipelineCreateUpdatePipelineStatus === StatusCodes.SUCCESS) {
      fetchPipeline(name || pipelineName);
      fetchPipelineYaml(name || pipelineName);
    }
  }, [pipelineCreateUpdatePipelineStatus]);

  useEffect(() => {
    if (pipelineInProgress(status) || pipelinePendingFileCount > 0) {
      startPollingPipelineStatus(name);
    } else {
      stopPollingPipelineStatus();
      setLoadingButton(false);
      setSavingPipeline(false);
    }
    return () => {
      stopPollingPipelineStatus();
    };
  }, [name, status, pipelinePendingFileCount, dispatch]);

  return (
    <>
      <div className={styles.settings}>
        <div
          className={`${styles.settings_name} ${
            editPipelineName ? styles.settings_name_editName : ''
          }`}
        >
          <div className={styles.settings_badge}>
            <PipelineServiceLevelBadge serviceLevel={serviceLevel} />
          </div>
          {editPipelineName ? (
            <div className={styles.settings_nameContent}>
              <Input
                placeholder={CREATE_PIPELINE_MODAL_PIPELINE_NAME_PLACEHOLDER}
                defaultValue={pipeline.name}
                className={styles.settings_name_input}
                onChange={(e) => setNewPipelineName(e.target.value)}
                ref={pipelineNameInputRef}
                status={
                  !successfulValidationPipelineName ||
                  renamePipelineNameStatus === StatusCodes.ERROR
                    ? 'error'
                    : ''
                }
                onBlur={(e) => {
                  if (pipeline.pipeline_id) onRenamePipeline();
                  else {
                    setNewPipelineName(e.target.value);
                    setPipelineName(e.target.value);
                  }
                  setEditPipelineName(!editPipelineName);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    pipelineNameInputRef.current?.blur();
                  }
                }}
              />
              {!successfulValidationPipelineName && (
                <div className={styles.settings_name_input_error}>
                  {COMMON_FORM_ERRORS.INVALID_CHARACTERS}
                </div>
              )}
            </div>
          ) : (
            <span>{pipeline.name || DRAFT_LABEL}</span>
          )}
          <div className={styles.settings_icon}>
            <Tooltip title={EDIT_PIPELINE_NAME_LABEL}>
              <Button
                type="text"
                onClick={() => setEditPipelineName(!editPipelineName)}
                ref={editPipelineNameButtonRef}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </div>
          <div className={styles.settings_pipelineStatus}>
            <PipelineStatusTag
              pipeline={pipeline as IPipeline}
              getIndexingDetails={(pipelineNameArg) => fetchPipelineIndexing(pipelineNameArg)}
              indexingData={pipelineIndexingData}
            />
          </div>
          <PipelineDeepsetCloudVersionTag deepsetCloudVersion={pipeline.deepset_cloud_version} />
        </div>
        <div className={styles.settings_actions}>
          {serviceLevel !== PipelineServiceLevel.DRAFT && !isLimitedUser && (
            <span className={styles.switchServiceLevel}>
              <Tooltip placement="bottom" title={PIPELINE_PRODUCTION_TOOLTIP_LABEL}>
                <InfoCircleOutlined className={styles.switchServiceLevel_info} />
              </Tooltip>
              <span>{PIPELINE_PRODUCTION_LABEL}</span>{' '}
              <PipelineServiceLevelSwitch
                serviceLevel={serviceLevel}
                afterSwitch={() => fetchPipeline(name)}
                pipelineName={name}
                size="small"
              />
            </span>
          )}
          {getEditedYaml() && (
            <Tooltip placement="bottom" title={RESET_TOOLTIP_MESSAGE}>
              <Button
                disabled={pipelineInProgress(status)}
                onClick={() => resetCode(pipelineName)}
                type="link"
                className={styles.resetButton}
              >
                {RESET_BUTTON_LABEL}
              </Button>
            </Tooltip>
          )}
          {!isPipelineInProduction(status, serviceLevel) && !loadingButton && (
            <Button
              type={getEditedYaml() || isNewPipeline || savePipeline ? 'primary' : 'default'}
              className={styles.saveButton}
              disabled={
                savePipeline
                  ? false
                  : pipelineInProgress(status) || (!getEditedYaml() && pipeline.pipeline_id !== '')
              }
              data-testid="savePipeline_button"
              onClick={() => {
                if (savePipeline) savePipeline();
                else onSavePipeline(pipelineName);
              }}
            >
              {SAVE_BUTTON_LABEL}
            </Button>
          )}
          {isPipelineDeployed(status) ? (
            <Button
              loading={pipelineInProgress(status) || loadingButton}
              onClick={() => {
                setOpenPipelineConfirmationModal(true);
              }}
              className={styles.deployButton}
            >
              {status === PipelineStatusCodes.UNDEPLOYMENT_IN_PROGRESS ||
              status === PipelineStatusCodes.UNDEPLOYMENT_SCHEDULED
                ? UNDEPLOYING_BUTTON_LABEL
                : UNDEPLOY_BUTTON_LABEL}
            </Button>
          ) : (
            <Button
              type="primary"
              className={styles.deployButton}
              disabled={getEditedYaml() || pipeline.pipeline_id === ''}
              loading={(pipelineInProgress(status) || loadingButton) && !savingPipeline}
              onClick={() => {
                setLoadingButton(true);
                deployPipeline(name);
                if (currentRoute.header.title === STUDIO_ROUTE_NAME)
                  sendMetricsDcStudio(
                    UserEventType.CLICK,
                    EventControlStudioElement.DEPLOY_PIPELINE,
                  );
                startPollingPipelineStatus(name);
              }}
            >
              {((status === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
                status === PipelineStatusCodes.DEPLOYMENT_SCHEDULED) &&
                !savingPipeline) ||
              loadingButton
                ? DEPLOYING_BUTTON_LABEL
                : DEPLOY_BUTTON_LABEL}
            </Button>
          )}
        </div>
      </div>

      <PipelineConfirmationModal
        open={openPipelineConfirmationModal}
        okText={UNDEPLOY_BUTTON_LABEL}
        onOk={async () => {
          setLoadingButton(true);
          await undeployPipeline(name);
          fetchPipeline(name);
          startPollingPipelineStatus(name);
          setOpenPipelineConfirmationModal(false);
        }}
        cancelText={CANCEL_BUTTON_LABEL}
        onCancel={() => {
          setOpenPipelineConfirmationModal(false);
        }}
        pipelines={openPipelineConfirmationModal ? [pipeline] : []}
        undeployingPipeline
      />
    </>
  );
}

export default PipelineEditorHeader;
