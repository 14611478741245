import React, { ReactElement } from 'react';
import styles from './featureDescription.module.scss';

interface IFeatureDescriptionProps {
  title: string;
  subtitle: string;
  options: {
    title: string;
    description: string;
    icon: ReactElement;
  }[];
}

const FeatureDescription = ({ options, title, subtitle }: IFeatureDescriptionProps) => {
  return (
    <div className={styles.description}>
      <div className={styles.description_header}>
        <h3 className={styles.description_header_title}>{title}</h3>
        <div className={styles.description_header_subtitle}>{subtitle}</div>
      </div>
      <div className={styles.description_options}>
        {options.map(({ title: optionTitle, description, icon }) => {
          return (
            <div key={optionTitle} className={styles.description_option}>
              <div className={styles.description_option_icon}>{icon}</div>
              <div className={styles.description_option_textWrapper}>
                <h6 className={styles.description_option_textWrapper_title}>{optionTitle}</h6>
                <div className={styles.description_option_textWrapper_subtitle}>{description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeatureDescription;
