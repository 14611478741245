import produce from 'immer';
import {
  ADD_UPLOAD_FILES,
  FINISH_UPLOADING,
  IUploadData,
  RESET_UPLOAD_DATA,
  START_UPLOADING,
} from '@redux/types/types';

interface IInitialStateProps {
  uploadData: IUploadData[];
  isUploadingStarted: boolean;
  isUploadingCompleted: boolean;
}

export const initialState: IInitialStateProps = {
  uploadData: [],
  isUploadingStarted: false,
  isUploadingCompleted: false,
};

function uploadReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_UPLOAD_DATA:
        return initialState;

      case ADD_UPLOAD_FILES:
        localDraft.uploadData.push(action.payload);
        break;

      case START_UPLOADING:
        localDraft.isUploadingStarted = true;
        localDraft.isUploadingCompleted = false;
        break;

      case FINISH_UPLOADING:
        localDraft.isUploadingCompleted = true;
        break;

      default:
        break;
    }

    return localDraft;
  });
}

export default uploadReducer;
