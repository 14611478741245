import userflow from 'userflow.js';
import { IOrganizationData, IUserData } from '@redux/types/types';

const USERFLOW_TOKEN = import.meta.env.VITE_USERFLOW_TOKEN;

export const setupUserflow = (user: IUserData, organization: IOrganizationData) => {
  if (!USERFLOW_TOKEN || !user) return;
  userflow.init(USERFLOW_TOKEN);

  // Custom components
  userflow.setResourceCenterLauncherHidden(true);

  userflow.identify(
    user.userID,
    {
      email: user.email,
      name: user.firstName,
      lastName: user.lastName,
      orgaID: organization.organizationId,
      orgaName: organization.organizationName,
      role: user.role,
      organizationType: user.organizationType,
    },
    {
      signature: user.userflowSignature,
    },
  );
};
