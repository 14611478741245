import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDocumentsGroupedByFileId } from '@utils/search';
import { StatusCodes } from '@constants/enum/common';
import { PipelineType } from '@constants/pipelines';
import { MORE_OPTIONS_VIEW_PROMPT_LABEL } from '@constants/search';
import { selectedFileDocumentSelector } from '@redux/selectors/fileSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import {
  ISearchResult,
  ISearchResultDocument,
  IUserData,
  SearchResultMoreOption,
} from '@redux/types/types';
import ReferenceDrawer from '@components/referenceDrawer/ReferenceDrawer';
import PromptModal from '@components/search/features/PromptModal/PromptModal';
import useReferences from '@components/search/hooks/useReferences';
import ConversationalInfoText from '@components/search/molecules/conversationalInfoText/ConversationalInfoText';
import ConversationalLoadingBubble from '@components/search/molecules/conversationalLoadingBubble/ConversationalLoadingBubble';
import usePromptHook from '../../features/PromptModal/usePromptHook';
import SearchDocumentPreview from '../documentPreview/SearchDocumentPreview';
import ConversationalResultContainer from '../result/ConversationalResultContainer';

interface IConversationalResultsContainerProps {
  isExternal?: boolean;
  results: ISearchResult[];
  status: StatusCodes;
  query: string;
  pipelineId: string;
  pipelineName: string;
  displayFileOptions?: boolean;
  displayMoreOptions?: boolean;
  displayTypingEffect?: boolean;
  displayReferencesPopover?: boolean;
  resultStyleCustomization?: {
    answerContainer?: string;
    loadingAnswerContainer?: string;
  };
  onRepeatQueryOptionClick?: (query: string) => void;
  onSaveQueryPromptTemplateOptionClick?: (prompt: string) => void;
}

const ConversationalResultsContainer = (props: IConversationalResultsContainerProps) => {
  const {
    isExternal,
    displayFileOptions = true,
    displayMoreOptions = true,
    displayTypingEffect = true,
    displayReferencesPopover,
    results,
    status,
    query,
    pipelineId,
    pipelineName,
    resultStyleCustomization,
    onRepeatQueryOptionClick,
    onSaveQueryPromptTemplateOptionClick,
  } = props;
  const { activeReference } = useReferences();
  const user: IUserData = useSelector(userSelector);
  const selectedFileDocument = useSelector(selectedFileDocumentSelector);
  const { openPromptModal } = usePromptHook();

  const searching = status === StatusCodes.IN_PROGRESS;

  const getResultById = (searchResultId: string): ISearchResult | null => {
    return results?.find(({ answers }) => answers[0]?.result_id === searchResultId) ?? null;
  };

  const getAnswerResultById = (searchResultId: string): ISearchResult | null => {
    if (!searchResultId) return null;
    const matchedResult = getResultById(searchResultId);
    return matchedResult || null;
  };

  const getDocuments = (result: ISearchResult): ISearchResultDocument[] => {
    const { answers, documents } = result;
    const [firstAnswer] = answers;

    if (!documents.length) return documents;

    if (!('document_ids' in firstAnswer) || !firstAnswer?.document_ids) return [];

    return firstAnswer.document_ids!.reduce((acc: ISearchResultDocument[], id: string) => {
      const document = documents.find((doc: { id: string }) => doc.id === id);
      if (document) return [...acc, document];
      return acc;
    }, []);
  };

  // References methods

  const getReferenceResult = () => {
    if (!results.length || !activeReference) return null;

    return results.find((result) => {
      const { answers } = result;
      return answers?.some((ans) => ans.result_id === activeReference.resultId);
    });
  };

  const onMoreOptionsDropdownMenuItemClick = ({ key }: { key: string }, result: ISearchResult) => {
    if (key === SearchResultMoreOption.VIEW_PROMPT) openPromptModal(result.prompts);
  };

  const getMoreOptionsDropdownMenuItems = (searchResult: ISearchResult) => {
    if (!searchResult.prompts) return [];

    return [
      {
        key: SearchResultMoreOption.VIEW_PROMPT,
        label: MORE_OPTIONS_VIEW_PROMPT_LABEL,
      },
    ];
  };

  // Renders

  const resultsMemo = useMemo(() => {
    return (
      results &&
      results.map((searchResult) => {
        const [answer] = searchResult.answers;
        const documents = answer ? getDocuments(searchResult) : [];

        if (searchResult?.infoMessage)
          return (
            <ConversationalInfoText key={searchResult.query_id} text={searchResult.infoMessage} />
          );

        return (
          <ConversationalResultContainer
            key={answer?.result_id}
            isExternal={isExternal}
            searchResult={answer}
            searchStatus={status}
            documents={documents}
            documentsGroupedByFileId={getDocumentsGroupedByFileId(documents)}
            promptTemplates={searchResult?.promptTemplates}
            pipelineId={pipelineId}
            pipelineName={pipelineName}
            displayFileOptions={displayFileOptions}
            displayMoreOptions={displayMoreOptions}
            displayTypingEffect={displayTypingEffect}
            displayReferencesPopover={displayReferencesPopover}
            styleCustomization={resultStyleCustomization}
            sourcesDefaultExpanded={false}
            query={searchResult?.query || query}
            queryId={searchResult?.query_id || ''}
            errorMessage={searchResult?.errorMessage}
            onRepeatQueryOptionClick={onRepeatQueryOptionClick}
            onSaveQueryPromptTemplateOptionClick={onSaveQueryPromptTemplateOptionClick}
            moreOptionsDropdownMenuItems={getMoreOptionsDropdownMenuItems(searchResult)}
            onMoreOptionsDropdownMenuItemClick={(info) =>
              onMoreOptionsDropdownMenuItemClick(info, searchResult)
            }
          />
        );
      })
    );
  }, [results]);

  return (
    <>
      <PromptModal />
      {resultsMemo}
      {searching && (
        <ConversationalLoadingBubble
          query={query}
          user={{ firstName: user.firstName, lastName: user.lastName }}
          styleCustomization={resultStyleCustomization}
        />
      )}
      <SearchDocumentPreview
        pipelineType={PipelineType.GENERATIVE_QUESTION_ANSWERING}
        searchResults={getAnswerResultById(selectedFileDocument?.resultId || '')}
        isExternal={isExternal}
      />
      {!!results?.length && (
        <ReferenceDrawer resultExt={getReferenceResult()} isExternal={isExternal} />
      )}
    </>
  );
};

export default ConversationalResultsContainer;
