import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { handleStreamProgress } from '@utils/sse';
import { ISearchBody, ISearchStreamBody, ISessionBody } from '@api/search';
import { IQueryStreamRawMessageData } from '@redux/types/types';
import { httpExt } from './base-http-external';

export const createSearchSessionExternalApi = (sessionBody: ISessionBody, workspaceName?: string) =>
  httpExt.post(`/api/v1/workspaces/${workspaceName}/search_sessions`, sessionBody);

export const chatQueryExternalApi = (
  pipelineName: string,
  payload: ISearchBody,
  headers?: RawAxiosRequestHeaders,
  workspaceName?: string,
) => {
  return httpExt.post(
    `/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/chat`,
    payload,
    {
      headers,
    },
  );
};

export const chatQueryStreamExternalApi = (
  pipelineName: string,
  payload: ISearchStreamBody,
  onChunkReceived: (chunks: IQueryStreamRawMessageData[]) => void,
  config: AxiosRequestConfig = {},
  workspaceName?: string,
) => {
  return httpExt.post(
    `/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/chat-stream`,
    {
      include_result: true,
      ...payload,
    },
    {
      ...config,
      onDownloadProgress: handleStreamProgress(onChunkReceived),
    },
  );
};

export const searchExternalApi = (
  pipelineName: string,
  payload: ISearchBody,
  headers?: RawAxiosRequestHeaders,
  workspaceName?: string,
) => {
  return httpExt.post(
    `/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/search`,
    payload,
    {
      headers,
    },
  );
};

export const searchStreamExternalApi = async (
  pipelineName: string,
  payload: ISearchStreamBody,
  onChunkReceived: (chunks: IQueryStreamRawMessageData[]) => void,
  config: AxiosRequestConfig = {},
  workspaceName?: string,
) => {
  return httpExt.post(
    `/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/search-stream`,
    {
      include_result: true,
      ...payload,
    },
    {
      ...config,
      onDownloadProgress: handleStreamProgress(onChunkReceived),
    },
  );
};
