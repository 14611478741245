import { useDispatch } from 'react-redux';
import { addNotification as AddNotificationAction } from '@redux/actions/notificationActions';
import { NotificationDuration, NotificationMode, NotificationType } from '@redux/types/types';

const useNotifications = () => {
  const dispatch = useDispatch();

  const addNotification = ({
    content,
    type,
    duration,
    mode,
  }: {
    content: string;
    type?: NotificationType;
    duration?: NotificationDuration;
    mode?: NotificationMode;
  }) => {
    const notificationPayload: any = { content };

    if (type) notificationPayload.type = type;
    if (duration) notificationPayload.duration = duration;
    if (mode) notificationPayload.mode = mode;

    dispatch(AddNotificationAction(notificationPayload));
  };

  return {
    addNotification,
  };
};

export default useNotifications;
