import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const layoutStoreSelector = (state: RootState) => state.layoutStore;

export const layoutSidebarSelector = createSelector(
  layoutStoreSelector,
  (layoutStore) => layoutStore.sidebar,
);

export const layoutRouteNameSelector = createSelector(
  layoutStoreSelector,
  (layoutStore) => layoutStore.routeName,
);
