import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Tag } from 'antd';
import { interpolateString } from '@utils/string';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { OPTIONAL_LABEL, SETTINGS_BUTTON_LABEL, SUBMIT_BUTTON_LABEL } from '@constants/common';
import {
  ADDITIONAL_FEEDBACK_PLACEHODLER,
  FEEDBACK_RATING_MODAL_NO_TAGS,
  FEEDBACK_RATING_MODAL_TITLE,
} from '@constants/search';
import { ITag } from '@redux/types/types';
import styles from './searchFeedbackRatingModal.module.scss';

const { TextArea } = Input;
const { CheckableTag } = Tag;

const INLINE_WIDTH_THRESHOLD = 400;

interface ISearchFeedbackRatingModalProps {
  open: boolean;
  pipelineName: string;
  isAdmin?: boolean;
  tags?: ITag[];
  defaultTags?: ITag[];
  defaultComment?: string;
  onSubmit: (values: { comment: string; tagsId: string[] }) => void;
  onClose: () => void;
  loading?: boolean;
}

const SearchFeedbackRatingModal = ({
  open,
  pipelineName,
  isAdmin,
  tags,
  loading,
  defaultTags,
  defaultComment,
  onClose,
  onSubmit,
}: ISearchFeedbackRatingModalProps) => {
  const [comment, setComment] = useState<string>(defaultComment ?? '');
  const [selectedTagsId, setSelectedTagsId] = useState<string[]>(
    defaultTags?.map((t) => t.tag_id) ?? [],
  );
  const [inline, setInline] = useState<boolean>(false);
  const [modalStyle, setModalStyle] = useState<React.CSSProperties>({});
  const containerRef = useRef<HTMLDivElement>(null);

  const updatePosition = useCallback(() => {
    const parentElement = containerRef.current?.parentElement;
    if (parentElement) {
      const parentWidth = parentElement.offsetWidth;
      setInline(parentWidth > INLINE_WIDTH_THRESHOLD);

      // Calculate position for the modal
      const rect = parentElement.getBoundingClientRect();
      setModalStyle({
        top: rect.bottom,
        left: rect.left,
        margin: 0,
      });
    }
  }, []);

  useEffect(() => {
    updatePosition();

    const resizeObserver = new ResizeObserver(updatePosition);
    const intersectionObserver = new IntersectionObserver(updatePosition, {
      threshold: 0,
    });

    const parentElement = containerRef.current?.parentElement;
    if (parentElement) {
      resizeObserver.observe(parentElement);
      intersectionObserver.observe(parentElement);
    }

    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);

    return () => {
      resizeObserver.disconnect();
      intersectionObserver.disconnect();
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
    };
  }, [updatePosition]);

  useEffectUpdateOnly(() => {
    if (defaultTags) setSelectedTagsId(defaultTags.map((t) => t.tag_id));
    if (defaultComment) setComment(defaultComment);
  }, [defaultTags, defaultComment]);

  useEffectUpdateOnly(() => {
    if (!open) {
      setComment('');
      setSelectedTagsId([]);
    }
  }, [open]);

  const handleTagSelection = (tagId: string, checked: boolean) => {
    if (loading) return;
    const nextSelectedTags = checked
      ? [...selectedTagsId, tagId]
      : selectedTagsId.filter((t) => t !== tagId);
    setSelectedTagsId(nextSelectedTags);
  };

  const handleSubmit = () => {
    if (!onSubmit) return;
    onSubmit({ comment, tagsId: selectedTagsId });
  };

  const handleClose = () => {
    onClose();
  };

  // Renders
  const renderTags = () => {
    if (tags?.length) {
      return (
        <div className={styles.tagsCotainer}>
          {tags.map(({ tag_id: tagId, name }) => (
            <CheckableTag
              className={styles.tag}
              key={tagId}
              checked={selectedTagsId.includes(tagId)}
              onChange={(checked) => handleTagSelection(tagId, checked)}
            >
              {selectedTagsId.includes(tagId) && <CheckOutlined />}
              <span>{name}</span>
            </CheckableTag>
          ))}
        </div>
      );
    }

    if (isAdmin)
      return (
        <div className={styles.tagsCotainer}>
          <span>
            {interpolateString(FEEDBACK_RATING_MODAL_NO_TAGS, {
              settings: (
                <a href={`/pipelines/${pipelineName}`} target="_blank" rel="noreferrer">
                  {SETTINGS_BUTTON_LABEL.toLowerCase()}
                </a>
              ),
            })}
          </span>
        </div>
      );

    return null;
  };

  const renderBodyContent = () => {
    return (
      <div className={styles.content}>
        <div className={styles.header}>
          <h6 className={styles.title}>
            {FEEDBACK_RATING_MODAL_TITLE}
            <span className={styles.optionalText}> ({OPTIONAL_LABEL})</span>
          </h6>
          <Button
            className={styles.closeButton}
            size="small"
            type="text"
            onClick={handleClose}
            icon={<CloseOutlined />}
          />
        </div>
        {renderTags()}
        <TextArea
          disabled={loading}
          className={styles.textArea}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          rows={2}
          placeholder={ADDITIONAL_FEEDBACK_PLACEHODLER}
        />
        <div className={styles.footer}>
          <Button
            onClick={handleSubmit}
            disabled={(!comment && !selectedTagsId.length) || loading}
            loading={loading}
            type="primary"
          >
            {SUBMIT_BUTTON_LABEL}
          </Button>
        </div>
      </div>
    );
  };

  const renderInlineContainer = () => {
    if (!open) return null;

    return <div className={styles.inlineContainer}>{renderBodyContent()}</div>;
  };

  const renderModalContainer = () => {
    return (
      <Modal
        className={styles.modalContainer}
        open={open}
        mask={false}
        closable={false}
        onCancel={handleClose}
        footer={null}
        width={400}
        style={modalStyle}
      >
        {renderBodyContent()}
      </Modal>
    );
  };

  return (
    <div ref={containerRef} className={styles.container}>
      {inline ? renderInlineContainer() : renderModalContainer()}
    </div>
  );
};

export default SearchFeedbackRatingModal;
