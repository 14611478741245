import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BgColorsOutlined,
  CloudUploadOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  InfoCircleOutlined,
  SettingFilled,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  ColorPicker,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Tag,
  theme,
  Tooltip,
  Upload,
  UploadFile,
} from 'antd';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { debounce } from 'lodash';
import { base64ToUploadFile, fileToBase64 } from '@utils/file';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { SETTINGS_LABEL, STYLE_LABEL, UPLOAD_BUTTON_LABEL } from '@constants/common';
import { MIMETypes, StatusCodes } from '@constants/enum/common';
import {
  RESULTS_TITLE_HEADER,
  RESULTS_TITLE_INPUT_PLACEHOLDER,
  SHARE_JOB_MODAL_BANNER,
  SHARE_JOB_MODAL_EXPIRED_BANNER,
  SHARE_JOB_MODAL_TITLE,
} from '@constants/jobs';
import {
  SHARE_PIPELINE_MODAL_ACTIVE_BANNER,
  SHARE_PIPELINE_MODAL_ADD_DESCRIPTION_LABEL,
  SHARE_PIPELINE_MODAL_ADD_DESCRIPTION_LABEL_TOOLTIP,
  SHARE_PIPELINE_MODAL_BANNER,
  SHARE_PIPELINE_MODAL_BRAND_COLOR_DESCRIPTION,
  SHARE_PIPELINE_MODAL_BRAND_COLOR_LABEL,
  SHARE_PIPELINE_MODAL_COPIED_LINK_BUTTON_LABEL,
  SHARE_PIPELINE_MODAL_COPY_LINK_BUTTON_LABEL,
  SHARE_PIPELINE_MODAL_COPY_LINK_MESSAGE,
  SHARE_PIPELINE_MODAL_DELETE_LINK_BUTTON_LABEL,
  SHARE_PIPELINE_MODAL_DELETE_LINK_CANCEL_LABEL,
  SHARE_PIPELINE_MODAL_DELETE_LINK_CONFIRM_LABEL,
  SHARE_PIPELINE_MODAL_DELETE_LINK_CONFIRMATION_MESSAGE,
  SHARE_PIPELINE_MODAL_DELETE_LINK_MESSAGE,
  SHARE_PIPELINE_MODAL_DELETING_LINK_BUTTON_LABEL,
  SHARE_PIPELINE_MODAL_ENABLE_FILES_LABEL,
  SHARE_PIPELINE_MODAL_ENABLE_METADATA_FILTERS_LABEL,
  SHARE_PIPELINE_MODAL_EXPIRATION_DATE_LABEL,
  SHARE_PIPELINE_MODAL_EXPIRATION_DATE_LABEL_TOOLTIP,
  SHARE_PIPELINE_MODAL_EXPIRED_BANNER,
  SHARE_PIPELINE_MODAL_GENERATE_LINK_BUTTON_LABEL,
  SHARE_PIPELINE_MODAL_GENERATED_LINK_BUTTON_LABEL,
  SHARE_PIPELINE_MODAL_GENERATING_LINK_BUTTON_LABEL,
  SHARE_PIPELINE_MODAL_LINK_INPUT_PLACEHOLDER,
  SHARE_PIPELINE_MODAL_LOGO_DESCRIPTION,
  SHARE_PIPELINE_MODAL_LOGO_LABEL,
  SHARE_PIPELINE_MODAL_TITLE,
} from '@constants/pipelines';
import {
  generateSharedJobResultLink,
  getSharedJob,
  revokeJobResultPrototypeLink,
  updateSharedJob,
} from '@redux/actions/jobsActions';
import {
  deletePrototypeLink,
  generatePrototypeLink,
  getPipelinePrototype,
  resetPrototypeLink,
  updateSharedPrototype,
} from '@redux/actions/pipelineActions';
import {
  fetchingSharedJobResultStatusSelector,
  generateSharedJobResultStatusSelector,
  revokeSharedJobResultStatusSelector,
  sharedJobResultSelector,
} from '@redux/selectors/jobsSelectors';
import {
  deleteSharedPrototypeStatusSelector,
  fetchingSharedPrototypeStatusSelector,
  generateSharedPrototypeStatusSelector,
  sharedPrototypeSelector,
} from '@redux/selectors/pipelineSelectors';
import ButtonWithClickConfirmationAnimation from '@components/common/buttonWithClickConfirmationAnimation/ButtonWithClickConfirmationAnimation';
import TextEditor from '@components/textEditor/TextEditor';
import styles from './shareModal.module.scss';

const { useToken } = theme;

enum MenuKey {
  SETTINGS = 'settings',
  STYLE = 'style',
}

interface IShareModalProps {
  pipelineName?: string;
  onCancel: () => void;
  jobId?: string;
}

const DESCRIPTION_DEBOUNCE_DELAY = 750;
const DEFAULT_EXPIRATION_DATE = 30;
const EXPIRATION_OPTIONS = [
  {
    value: 1,
    label: 'in 24 hours',
    'data-testid': 'sharedPrototypeExpirationDate_option_24hours',
  },
  {
    value: 7,
    label: 'in 7 days',
  },
  {
    value: 30,
    label: 'in 30 days',
  },
  {
    value: 60,
    label: 'in 60 days',
  },
  {
    value: 365,
    label: 'in 1 year',
  },
];

// TODO: Seprate component into 2 features for settings and style config
const ShareModal = (props: IShareModalProps) => {
  const { pipelineName, onCancel, jobId } = props;
  dayjs.extend(relativeTime);
  dayjs.extend(LocalizedFormat);

  const dispatch = useDispatch();
  const { token } = useToken();

  const textEditorRef = useRef<React.ElementRef<typeof TextEditor>>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuKey>(MenuKey.SETTINGS);

  // TODO: Centralize shared logic and create hook
  const currentSharedPrototype = useSelector(
    jobId ? sharedJobResultSelector : sharedPrototypeSelector,
  );
  const loadingSharedPrototypeStatus = useSelector(
    jobId ? fetchingSharedJobResultStatusSelector : fetchingSharedPrototypeStatusSelector,
  );
  const generateSharedPrototypeStatus = useSelector(
    jobId ? generateSharedJobResultStatusSelector : generateSharedPrototypeStatusSelector,
  );
  const deleteSharedPrototypeStatus = useSelector(
    jobId ? revokeSharedJobResultStatusSelector : deleteSharedPrototypeStatusSelector,
  );

  // Settings state
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [expirationDate, setExpirationDate] = useState(DEFAULT_EXPIRATION_DATE);
  const [metadataFiltersEnabled, setMetadataFiltersEnabled] = useState(false);
  const [filesEnabled, setFilesEnabled] = useState(false);

  // Style state
  const [brandColor, setBrandColor] = useState(token.colorPrimary);
  const [brandLogo, setBrandLogo] = useState<string | null>();

  const isLoadingSharedPrototype = loadingSharedPrototypeStatus === StatusCodes.IN_PROGRESS;
  const isGeneratingSharedPrototypeStatus =
    generateSharedPrototypeStatus === StatusCodes.IN_PROGRESS;
  const isDeletingSharedPrototypeStatus = deleteSharedPrototypeStatus === StatusCodes.IN_PROGRESS;

  const isExpired = () => {
    if (!currentSharedPrototype) return false;
    const { expiration_date: expirationDateValue, is_revoked: isRevoked } = currentSharedPrototype;
    if (isRevoked) return true;
    return dayjs(expirationDateValue).isBefore(dayjs());
  };

  useEffect(() => {
    dispatch(resetPrototypeLink);
  }, []);

  useEffect(() => {
    if (pipelineName) dispatch(getPipelinePrototype({ pipelineName }));
    if (jobId) dispatch(getSharedJob({ jobId }));
  }, [pipelineName, jobId]);

  useEffect(() => {
    if (currentSharedPrototype) {
      const {
        title: currentPrototypeTitle,
        description: currentPrototypeDescription,
        link: currentPrototypeLink,
        show_metadata_filters: showMetadataFilters,
        show_files: showFiles,
        brand_color: currentBrandColor,
        brand_logo: currentBrandLogo,
      } = currentSharedPrototype;

      if (textEditorRef.current) textEditorRef.current.setHTMLContent(currentPrototypeDescription);
      setDescription(currentPrototypeDescription);
      setMetadataFiltersEnabled(showMetadataFilters);
      setFilesEnabled(showFiles);
      setTitle(currentPrototypeTitle);

      if (currentBrandColor) setBrandColor(currentBrandColor);
      if (currentBrandLogo) setBrandLogo(currentBrandLogo);

      if (isExpired()) setLink('');
      else setLink(currentPrototypeLink);
    } else {
      setLink('');
    }
  }, [currentSharedPrototype]);

  useEffectUpdateOnly(() => {
    if (selectedMenuItem === MenuKey.SETTINGS) {
      textEditorRef.current?.setHTMLContent(description);
    }
  }, [selectedMenuItem]);

  const generateLink = () => {
    const expirationDateValue = dayjs().add(expirationDate, 'day').toDate();

    if (pipelineName)
      dispatch(
        generatePrototypeLink({
          pipelineName,
          expirationDate: expirationDateValue,
          description,
          showMetadataFilters: metadataFiltersEnabled,
          showFiles: filesEnabled,
          brandColor,
          brandLogo,
        }),
      );
    else if (jobId)
      dispatch(
        generateSharedJobResultLink({
          jobId,
          title,
          expirationDate: expirationDateValue,
          description,
          showFiles: filesEnabled,
        }),
      );
  };

  const updatePrototype = ({
    localTitle,
    localDescription,
    localBrandColor,
  }: { localTitle?: string; localDescription?: string; localBrandColor?: string } = {}) => {
    if (!pipelineName && !jobId) return;
    if (!link || !currentSharedPrototype) return;
    const { shared_prototype_id: sharedPrototypeId, shared_job_id: shareJobId } =
      currentSharedPrototype;

    if (pipelineName)
      dispatch(
        updateSharedPrototype({
          sharedPrototypeId,
          description: localDescription ?? description,
          showMetadataFilters: metadataFiltersEnabled,
          showFiles: filesEnabled,
          brandColor: localBrandColor ?? brandColor,
          brandLogo,
        }),
      );
    else if (jobId)
      dispatch(
        updateSharedJob({
          sharedJobPrototypeId: shareJobId,
          title: localTitle ?? title,
          description: localDescription ?? description,
        }),
      );
    // TODO: Add success and error handling (somehow)
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    messageApi.success(SHARE_PIPELINE_MODAL_COPY_LINK_MESSAGE);
  };

  const deleteLink = () => {
    if (!link) return;
    const { shared_prototype_id: sharedPrototypeId, shared_job_id: shareJobId } =
      currentSharedPrototype;
    if (pipelineName) dispatch(deletePrototypeLink(sharedPrototypeId));
    else if (jobId) dispatch(revokeJobResultPrototypeLink(shareJobId));
    // TODO: Handle message async with use efffect once we move the selector to this component
    messageApi.success(SHARE_PIPELINE_MODAL_DELETE_LINK_MESSAGE);
  };

  const debouncedUpdatePrototype = useMemo(
    () =>
      debounce(
        (
          extraParams: {
            localTitle?: string;
            localDescription?: string;
            localBrandColor?: string;
          } = {},
        ) => updatePrototype(extraParams),
        DESCRIPTION_DEBOUNCE_DELAY,
      ),
    [link],
  );

  const handleDateSelectChange = (value: number) => {
    setExpirationDate(value);
  };

  const handleTitleChange = (value: string) => {
    setTitle(value);
  };

  const handleDescriptionChange = () => {
    setDescription(textEditorRef.current?.getHTMLContent() || '');
  };

  const handleMetadataFilterToggleChange = () => {
    setMetadataFiltersEnabled(!metadataFiltersEnabled);
  };

  const handleFilesToggleChange = () => {
    setFilesEnabled(!filesEnabled);
  };

  // For instant updates
  useEffect(() => {
    if (!currentSharedPrototype) return;

    updatePrototype();
  }, [metadataFiltersEnabled, filesEnabled, brandLogo]);

  // For debounced updates
  useEffect(() => {
    if (!currentSharedPrototype) return;

    debouncedUpdatePrototype({
      localTitle: title,
      localDescription: description,
      localBrandColor: brandColor,
    });
  }, [title, description, brandColor]);

  const handleCancel = () => {
    dispatch(resetPrototypeLink);
    onCancel();
  };

  const handleLogoUpload = async (file: File) => {
    fileToBase64(file, (result: string | ArrayBuffer | null) => {
      if (!result || typeof result !== 'string') return;
      const [, encodedBase64] = result.split(',');
      setBrandLogo(encodedBase64);
    });
    // Prevent the default upload action
    return false;
  };

  const getAlertText = () => {
    if (isExpired())
      return jobId ? SHARE_JOB_MODAL_EXPIRED_BANNER : SHARE_PIPELINE_MODAL_EXPIRED_BANNER;
    if (link) return SHARE_PIPELINE_MODAL_ACTIVE_BANNER;

    return jobId ? SHARE_JOB_MODAL_BANNER : SHARE_PIPELINE_MODAL_BANNER;
  };

  const renderMenu = () => {
    const menuItems = [
      {
        key: MenuKey.SETTINGS,
        icon: <SettingFilled />,
        label: SETTINGS_LABEL,
      },
      ...(pipelineName
        ? [
            {
              key: MenuKey.STYLE,
              icon: <BgColorsOutlined />,
              label: STYLE_LABEL,
            },
          ]
        : []),
    ];

    return (
      <Menu
        className={styles.menu}
        selectedKeys={[selectedMenuItem]}
        onClick={({ key }) => setSelectedMenuItem(key as MenuKey)}
        mode="vertical"
        items={menuItems}
      />
    );
  };

  const footer = (
    <div className={styles.footer}>
      <Input placeholder={SHARE_PIPELINE_MODAL_LINK_INPUT_PLACEHOLDER} value={link} />
      {link ? (
        <ButtonWithClickConfirmationAnimation
          type="primary"
          onClick={copyLink}
          confirmationText={SHARE_PIPELINE_MODAL_COPIED_LINK_BUTTON_LABEL}
        >
          {SHARE_PIPELINE_MODAL_COPY_LINK_BUTTON_LABEL}
        </ButtonWithClickConfirmationAnimation>
      ) : (
        <ButtonWithClickConfirmationAnimation
          data-testid="generateLink-button"
          loading={isGeneratingSharedPrototypeStatus}
          disabled={isGeneratingSharedPrototypeStatus}
          type="primary"
          onClick={generateLink}
          confirmationText={SHARE_PIPELINE_MODAL_GENERATED_LINK_BUTTON_LABEL}
          loadingText={SHARE_PIPELINE_MODAL_GENERATING_LINK_BUTTON_LABEL}
        >
          {SHARE_PIPELINE_MODAL_GENERATE_LINK_BUTTON_LABEL}
        </ButtonWithClickConfirmationAnimation>
      )}
    </div>
  );

  // Settings content

  const renderExpirationDatePicker = () => (
    <div className={styles.formSection}>
      <div className={styles.formSection_title_wrapper}>
        <h6> {SHARE_PIPELINE_MODAL_EXPIRATION_DATE_LABEL} </h6>
        <Tooltip title={SHARE_PIPELINE_MODAL_EXPIRATION_DATE_LABEL_TOOLTIP}>
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      {link && currentSharedPrototype ? (
        <div className={styles.expirationContainer}>
          <div>
            <Tag>{dayjs(currentSharedPrototype.expiration_date).fromNow()}</Tag>
            <small> {dayjs(currentSharedPrototype.expiration_date).format('LLL')} </small>
          </div>
          <Popconfirm
            title={
              <div className={styles.expirationContainer_popconfirm_title}>
                {SHARE_PIPELINE_MODAL_DELETE_LINK_CONFIRMATION_MESSAGE}
              </div>
            }
            placement="left"
            okText={SHARE_PIPELINE_MODAL_DELETE_LINK_CONFIRM_LABEL}
            cancelText={SHARE_PIPELINE_MODAL_DELETE_LINK_CANCEL_LABEL}
            onConfirm={deleteLink}
          >
            <Button
              loading={isDeletingSharedPrototypeStatus}
              type="link"
              style={{ paddingRight: 0, paddingLeft: 0 }}
            >
              {isDeletingSharedPrototypeStatus
                ? SHARE_PIPELINE_MODAL_DELETING_LINK_BUTTON_LABEL
                : SHARE_PIPELINE_MODAL_DELETE_LINK_BUTTON_LABEL}
            </Button>
          </Popconfirm>
        </div>
      ) : (
        <Select
          value={expirationDate}
          style={{ width: 120 }}
          onChange={handleDateSelectChange}
          disabled={isLoadingSharedPrototype || isGeneratingSharedPrototypeStatus || !!link}
          options={EXPIRATION_OPTIONS}
          data-testid="sharedPrototypeExpirationDate_select"
        />
      )}
    </div>
  );

  const renderDescription = () => (
    <div className={styles.formSection}>
      <div className={styles.formSection_title_wrapper}>
        <h6> {SHARE_PIPELINE_MODAL_ADD_DESCRIPTION_LABEL} </h6>
        <Tooltip title={SHARE_PIPELINE_MODAL_ADD_DESCRIPTION_LABEL_TOOLTIP}>
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <div>
        <TextEditor
          disabled={isLoadingSharedPrototype || isGeneratingSharedPrototypeStatus}
          ref={textEditorRef}
          onTextChangeByUser={handleDescriptionChange}
        />
      </div>
    </div>
  );

  const renderTitle = () => (
    <div className={styles.formSection}>
      <h6> {RESULTS_TITLE_HEADER} </h6>
      <Input
        value={title}
        placeholder={RESULTS_TITLE_INPUT_PLACEHOLDER}
        onChange={(event) => handleTitleChange(event.target.value)}
        disabled={isLoadingSharedPrototype || isGeneratingSharedPrototypeStatus}
      />
    </div>
  );

  const renderMetadataFiltersToggle = () => (
    <div className={styles.formSection}>
      <div className={styles.formSection_flex}>
        <h6> {SHARE_PIPELINE_MODAL_ENABLE_METADATA_FILTERS_LABEL} </h6>
        <Switch
          data-testid="metadataFilters_toggle"
          disabled={isLoadingSharedPrototype || isGeneratingSharedPrototypeStatus}
          checked={metadataFiltersEnabled}
          onChange={handleMetadataFilterToggleChange}
        />
      </div>
    </div>
  );

  const renderFilesToggle = () => (
    <div className={styles.formSection}>
      <div className={styles.formSection_flex}>
        <h6> {SHARE_PIPELINE_MODAL_ENABLE_FILES_LABEL} </h6>
        <Switch
          data-testid="files_toggle"
          disabled={isLoadingSharedPrototype || isGeneratingSharedPrototypeStatus}
          checked={filesEnabled}
          onChange={handleFilesToggleChange}
        />
      </div>
    </div>
  );

  const renderSettingsContent = () => {
    return (
      <>
        <Alert
          className={styles.share_modal_alert}
          message={<h6>{getAlertText()}</h6>}
          type="info"
          banner
          icon={isExpired() ? <EyeInvisibleFilled /> : <EyeFilled />}
          showIcon={isExpired() || !!link}
        />
        {renderExpirationDatePicker()}
        {jobId && renderTitle()}
        {renderDescription()}
        {pipelineName && renderMetadataFiltersToggle()}
        {/* TODO: Enable for job when it is implemented */}
        {pipelineName && renderFilesToggle()}
      </>
    );
  };

  // Style content

  const renderLogoUpload = () => {
    return (
      <div className={styles.formSection}>
        <div>
          <h6>{SHARE_PIPELINE_MODAL_LOGO_LABEL}</h6>
          <div className={styles.formSection_subtitle}>{SHARE_PIPELINE_MODAL_LOGO_DESCRIPTION}</div>
        </div>
        <Upload
          accept={`${MIMETypes.JPEG}, ${MIMETypes.PNG}, ${MIMETypes.SVG}`}
          beforeUpload={handleLogoUpload}
          onRemove={() => setBrandLogo(null)}
          listType="picture"
          maxCount={1}
          multiple={false}
          defaultFileList={brandLogo ? [base64ToUploadFile(brandLogo) as UploadFile] : []}
        >
          {!brandLogo && <Button icon={<CloudUploadOutlined />}>{UPLOAD_BUTTON_LABEL}</Button>}
        </Upload>
      </div>
    );
  };

  const renderBrandColorPicker = () => {
    return (
      <div className={styles.formSection}>
        <div>
          <h6> {SHARE_PIPELINE_MODAL_BRAND_COLOR_LABEL} </h6>
          <div className={styles.formSection_subtitle}>
            {SHARE_PIPELINE_MODAL_BRAND_COLOR_DESCRIPTION}
          </div>
        </div>
        <div>
          <ColorPicker
            value={brandColor}
            onChange={(_, hex: string) => setBrandColor(hex)}
            showText
          />
        </div>
      </div>
    );
  };

  const renderStyleContent = () => {
    return (
      <>
        {renderLogoUpload()}
        {renderBrandColorPicker()}
      </>
    );
  };

  const renderContentByMenuKey = (key: MenuKey) => {
    const contentMappedByKey = {
      [MenuKey.SETTINGS]: renderSettingsContent,
      [MenuKey.STYLE]: renderStyleContent,
    };
    return contentMappedByKey[key]();
  };

  return (
    <Modal
      title={jobId ? SHARE_JOB_MODAL_TITLE : SHARE_PIPELINE_MODAL_TITLE}
      open
      centered
      footer={footer}
      onCancel={handleCancel}
      maskClosable={false}
      className={styles.modal}
      width={800}
    >
      {contextHolder}
      <div className={styles.body}>
        {renderMenu()}
        <div className={styles.content}>{renderContentByMenuKey(selectedMenuItem)}</div>
      </div>
    </Modal>
  );
};

export default ShareModal;
