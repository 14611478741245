import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const evalsetStoreSelector = (state: RootState) => state.evalsetStore;

export const evalsetSelector = createSelector(
  evalsetStoreSelector,
  (evalsetStore) => evalsetStore.evalsets,
);

export const evalsetMessageSelector = createSelector(
  evalsetStoreSelector,
  (evalsetStore) => evalsetStore.message,
);

export const evalsetActionStatusSelector = createSelector(
  evalsetStoreSelector,
  (evalsetStore) => evalsetStore.actionStatus,
);

export const evalsetFetchStatusSelector = createSelector(
  evalsetStoreSelector,
  (evalsetStore) => evalsetStore.fetchStatus,
);
