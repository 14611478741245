import { getWorkspace } from '@src/utils/store';
import { http } from './base-http';

export const getPipelineMetaApi = (pipelineName: string, workspace: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/meta`);
};

export const getMetadataValuesApi = (
  pipelineName: string,
  fieldName: string,
  query: string,
  limit?: number,
  workspace: string = getWorkspace(),
) => {
  return http.get(`/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/meta/${fieldName}`, {
    params: { query: `*${query}*`, limit },
  });
};

export const getPipelineMinMaxAggregationMetadataApi = (
  pipelineName: string,
  fieldName: string,
  workspace: string = getWorkspace(),
) => {
  return http.get(
    `/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/meta/${fieldName}/aggregation`,
  );
};
