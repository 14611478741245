import { IWorkspaceItem } from '@redux/reducers/organizationReducer';

export const getWorkspaceIdleTimeout = (workspaces: IWorkspaceItem[], currentWorkspace: string) => {
  const currentWorkspaceData = workspaces.find(
    (workspace: IWorkspaceItem) => workspace.name === currentWorkspace,
  );
  const idleTimeout = currentWorkspaceData?.default_idle_timeout_in_seconds;

  return idleTimeout || null;
};
