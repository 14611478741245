import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  Drawer,
  Dropdown,
  Input,
  MenuProps,
  Popconfirm,
  Tabs,
  Tooltip,
} from 'antd';
import { debounce, isNumber } from 'lodash';
import { formatNumberToLocaleString } from '@utils/math';
import { interpolateString } from '@utils/string';
import { getUserFullname } from '@utils/transformation/user';
import MoreOutlined from '@assets/icons/more-outlined.svg?react';
import { SelectedFilters } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import {
  DELETE_BUTTON_LABEL,
  EVALUATION_SET_HEADER,
  EXPERIMENT_RUN_START_LABEL,
  LOGS_HEADER,
  MetricDescriptions,
  METRICS_HEADER,
  NAME_LABEL,
  NODE_NAME_LABEL,
  NODE_TYPE_LABEL,
  NOTES_HEADER,
  NOTES_INPUT_PLACEHOLDER,
  PARAMETERS_HEADER,
  PIPELINE_HEADER,
  PIPELINE_SAS_MODEL_NAME_HEADER,
  PIPELINE_SNAPSHOT_DATE_HEADER,
  PIPELINE_YAML_INFO,
  PREVIEW_HEADER,
  RUN_DELETE_POPCONFIRM_CONFIRM,
  RUN_DELETE_POPCONFIRM_DECLINE,
  RUN_DELETE_POPCONFIRM_MESSAGE,
  VALUE_LABEL,
} from '@constants/experiments';
import {
  deleteEvalRun,
  downloadEvalRunCSV,
  getEvalRun,
  getRunPredictions,
  resetCurrentExperimentData,
  resetExperimentsMessage,
  startEvalRun,
  startPollingRunStatus,
  stopPollingRunStatus,
  updateEvalRunNotes,
} from '@redux/actions/experimentActions';
import {
  experimentCurrentSelector,
  experimentMessageSelector,
  experimentStatusSelector,
  predictionsSortValueByNodeSelector,
} from '@redux/selectors/experimentSelectors';
import { IMessage, RunState } from '@redux/types/types';
import { contextRender } from '@components/common/contextRender/contextRender';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import DataTable from '@components/dataTable/DataTable';
import DetailsHeader from '@components/detailsHeader/detailsHeader';
import Predictions from '@components/predictions/Predictions';
import RunStatusTag from '@components/runStatusTag/RunStatusTag';
import styles from './experimentDetailsPage.module.scss';

const COMMENT_UPDATE_DEBOUNCE_DELAY = 650;

const YamlEditor = React.lazy(
  () => import(/* webpackChunkName: "YamlEditor" */ '@components/yamlEditor/YamlEditor'),
);
const LogViewer = React.lazy(
  () => import(/* webpackChunkName: "LogViewer" */ '@components/logViewer/LogViewer'),
);

const ExperimentDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message: IMessage = useSelector(experimentMessageSelector);
  const status: StatusCodes = useSelector(experimentStatusSelector);
  const { experimentName } = useParams<'experimentName'>() as { experimentName: string };
  const { evalRun, predictions }: any = useSelector(experimentCurrentSelector);
  const selectedPredictionsSortValueByNode: Record<string, string> = useSelector(
    predictionsSortValueByNodeSelector,
  );
  const [detailsDrawerContent, setDetailsDrawerContent] = useState('');
  const [runComment, setRunComment] = useState(evalRun.comment);
  const [runToDelete, setRunToDelete] = useState('');
  const [selectedMetrics, setSelectedMetrics] = useState({ node_name: '' });
  const { TextArea } = Input;

  const debouncedUpdateEvalRunNotes = useMemo(() => {
    return debounce(
      (comment) => dispatch(updateEvalRunNotes({ name: experimentName, comment })),
      COMMENT_UPDATE_DEBOUNCE_DELAY,
    );
  }, []);

  const updateRunComment = (comment: string) => {
    setRunComment(comment);
    debouncedUpdateEvalRunNotes(comment);
  };

  useEffect(() => {
    if (!runComment) setRunComment(evalRun.comment);
  }, [evalRun.comment]);

  useEffect(() => {
    dispatch(resetExperimentsMessage);
    dispatch(getEvalRun(experimentName));
    dispatch(startPollingRunStatus(experimentName));

    return () => {
      dispatch(stopPollingRunStatus());
      dispatch(resetCurrentExperimentData);
    };
  }, [dispatch, experimentName]);

  const getPredictions = (
    nodeName: string,
    nodeType: string,
    currentPage: number,
    pageSize: number,
    searchValue: string,
    sortValue?: string,
    filterValues?: SelectedFilters,
  ) => {
    dispatch(
      getRunPredictions({
        evalRun: experimentName,
        nodeName,
        nodeType,
        currentPage,
        pageSize,
        searchValue,
        sortValue,
        filterValues,
      }),
    );
  };

  const handleDeleteRun = async (name: string) => {
    setRunToDelete('');
    await dispatch(deleteEvalRun(name));
    navigate('/experiments');
  };

  const onStartRun = async () => {
    await dispatch(startEvalRun(experimentName));
    dispatch(getEvalRun(experimentName));
  };

  const previewItemValue = (item: string) => {
    setDetailsDrawerContent(item);
  };

  const renderDetailsDrawer = () => (
    <Drawer open={!!detailsDrawerContent} onClose={() => setDetailsDrawerContent('')} size="large">
      {detailsDrawerContent}
    </Drawer>
  );

  const nameValueTableSection = (data: any) => {
    const result = Object.keys(data).map((key) => {
      return { name: key, value: data[key] };
    });

    const columns = [
      {
        title: NAME_LABEL,
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        render: (text: string) => (
          <>
            {text}
            {MetricDescriptions[text] && (
              <Tooltip
                className={styles.metricDescriptionTooltip}
                title={MetricDescriptions[text]}
                overlayInnerStyle={{ width: '500px' }}
              >
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: VALUE_LABEL,
        dataIndex: 'value',
        key: 'value',
        width: '50%',
        render: (value: string | number) =>
          isNumber(value) ? formatNumberToLocaleString(value) : value,
      },
    ];

    return (
      <DataTable
        data={result}
        columns={columns}
        rowSelection={false}
        rowKey="name"
        searchAvailable={false}
        border
      />
    );
  };

  const metricsSection = (title: string, results: any) => {
    if (!results) return null; // backward compatibility for previous response schema

    const onMetricsTabChange = (key: string) => {
      const selectedResult = results.find((result: any) => result.node_name === key);
      setSelectedMetrics(selectedResult);
    };

    if ((!selectedMetrics || !selectedMetrics.node_name) && results.length > 0)
      setSelectedMetrics(results[results.length - 1]);

    const tabItems = results.map((item: any) => ({ label: item.node_name, key: item.node_name }));

    return (
      <section id="metricsSection" className={styles.section}>
        <h5> {title} </h5>
        <Tabs
          activeKey={selectedMetrics.node_name}
          type="card"
          onChange={onMetricsTabChange}
          items={tabItems}
        />
        {nameValueTableSection(selectedMetrics)}
      </section>
    );
  };

  const predictionsSection = (results: any) => (
    <Predictions
      evalRunId={evalRun.eval_run_id}
      results={results}
      predictions={predictions}
      retrieverNodeTopK={evalRun.pipeline_parameters?.Retriever?.params.top_k}
      pipelineParameters={evalRun.pipeline_parameters}
      getPredictions={getPredictions}
      downloadPredictions={(nodeName: string) =>
        dispatch(
          downloadEvalRunCSV({
            evalRun: experimentName,
            nodeName,
          }),
        )
      }
      downloadDisabled={evalRun.status !== RunState.ENDED}
      selectedSortValueByNode={selectedPredictionsSortValueByNode}
    />
  );

  const onMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'delete') setRunToDelete(experimentName);
  };

  const commentArea = (comment: string) => (
    <section className={styles.section}>
      <h5> {NOTES_HEADER} </h5>
      <TextArea
        rows={4}
        defaultValue={evalRun.comment}
        value={comment}
        placeholder={NOTES_INPUT_PLACEHOLDER}
        onChange={(e) => updateRunComment(e.target.value)}
      />
    </section>
  );

  const namesArea = ({
    pipelineName,
    evaluationName,
    snapshotDate,
    sasModelName,
  }: {
    pipelineName: string;
    evaluationName: string;
    snapshotDate: string;
    sasModelName: string | null;
  }) => (
    <section className={styles.section}>
      <Divider />
      <div className={styles.namesSection_wrapper}>
        <div className={styles.namesSection_wrapper_item}>
          <small className={styles.namesSection_wrapper_item_title}>{PIPELINE_HEADER}</small>
          <span className={styles.namesSection_wrapper_item_name}>{pipelineName}</span>
        </div>
        <Divider type="vertical" style={{ height: 'auto' }} />
        <div className={styles.namesSection_wrapper_item}>
          <small className={styles.namesSection_wrapper_item_title}>
            {PIPELINE_SNAPSHOT_DATE_HEADER}
          </small>
          <span className={styles.namesSection_wrapper_item_name}>
            {new Date(snapshotDate).toLocaleString()}
          </span>
        </div>
        <Divider type="vertical" style={{ height: 'auto' }} />
        <div className={styles.namesSection_wrapper_item}>
          <small className={styles.namesSection_wrapper_item_title}>{EVALUATION_SET_HEADER}</small>
          <span className={styles.namesSection_wrapper_item_name}>{evaluationName}</span>
        </div>
        {sasModelName && (
          <>
            <Divider type="vertical" style={{ height: 'auto' }} />
            <div className={styles.namesSection_wrapper_item}>
              <small className={styles.namesSection_wrapper_item_title}>
                {PIPELINE_SAS_MODEL_NAME_HEADER}
              </small>
              <Tooltip title={sasModelName}>
                <span className={styles.namesSection_wrapper_item_name}>{sasModelName}</span>
              </Tooltip>
            </div>
          </>
        )}
      </div>
      <Divider />
    </section>
  );

  const logsArea = () => (
    <section className={styles.section}>
      <h5> {LOGS_HEADER} </h5>
      <div style={{ height: '260px' }}>
        <React.Suspense fallback={<LoadingIndicator />}>
          <LogViewer logs={evalRun.logs} />
        </React.Suspense>
      </div>
    </section>
  );

  const pipelineArea = (pipelineName: string, code: string, snapshotDate: string) => {
    if (!code) return null;

    const pipelineParams = Object.entries(evalRun.pipeline_parameters)
      .map(([nodeKey, node]: [string, any]) => {
        return Object.entries(node.params).map(([paramKey, param]: [string, any]) => {
          return {
            nodeName: nodeKey,
            nodeType: node.type,
            paramName: paramKey,
            paramValue: JSON.stringify(param),
            key: `${node.type}-${paramKey}`,
          };
        });
      })
      .flat();

    const columns = [
      {
        title: NAME_LABEL,
        dataIndex: 'paramName',
        key: 'paramName',
        render: (text: string) => String(text),
      },
      {
        title: VALUE_LABEL,
        dataIndex: 'paramValue',
        key: 'paramValue',
        render: (text: string) => (
          <div className={styles.pipelineParametersInfo_valueColumn}>
            {contextRender({
              content: text,
              maxCharacters: 250,
              previewButtonHandler: previewItemValue,
            })}
          </div>
        ),
      },
      {
        title: NODE_NAME_LABEL,
        dataIndex: 'nodeName',
        key: 'nodeName',
        render: (text: string) => String(text),
      },
      {
        title: NODE_TYPE_LABEL,
        dataIndex: 'nodeType',
        key: 'nodeType',
        render: (text: string) => String(text),
      },
    ];

    const tabsItems = [
      {
        label: PARAMETERS_HEADER,
        key: 'parameters',
        children: (
          <DataTable
            data={pipelineParams}
            columns={columns}
            rowSelection={false}
            rowKey="key"
            searchAvailable={false}
            scroll={{ x: 'max-content' }}
            border
          />
        ),
      },
      {
        label: PREVIEW_HEADER,
        key: 'yaml',
        children: (
          <>
            <div className={styles.pipelinePreviewInfo}>
              <Alert
                message={interpolateString(PIPELINE_YAML_INFO, {
                  snapshotDate: new Date(snapshotDate).toLocaleString(),
                  pipelineLink: (
                    <Link to={`/pipelines/${pipelineName}`} className={styles.nameLink}>
                      ({pipelineName})
                    </Link>
                  ),
                })}
                type="info"
              />
            </div>
            <div className={styles.yamlContainer}>
              <React.Suspense fallback={<LoadingIndicator />}>
                <YamlEditor code={code} readOnly />
              </React.Suspense>
            </div>
          </>
        ),
      },
    ];

    return (
      <section className={styles.section}>
        <h5> {PIPELINE_HEADER} </h5>
        <Tabs type="card" defaultActiveKey="parameters" items={tabsItems} />
      </section>
    );
  };

  const actionMenuItems = [
    {
      label: DELETE_BUTTON_LABEL,
      key: 'delete',
      danger: true,
      icon: <DeleteOutlined />,
    },
  ];

  const renderActions = () => (
    <div className={styles.headerActions}>
      {evalRun.status === RunState.CREATED && (
        <Button onClick={onStartRun} loading={status === StatusCodes.IN_PROGRESS}>
          {EXPERIMENT_RUN_START_LABEL}
        </Button>
      )}
      <Dropdown
        menu={{ items: actionMenuItems, onClick: onMenuClick }}
        placement="bottomRight"
        trigger={['click']}
      >
        <Button className={styles.moreActions_button} icon={<MoreOutlined />} />
      </Dropdown>
      <Popconfirm
        title={RUN_DELETE_POPCONFIRM_MESSAGE}
        open={runToDelete === experimentName}
        placement="bottomRight"
        okText={RUN_DELETE_POPCONFIRM_CONFIRM}
        cancelText={RUN_DELETE_POPCONFIRM_DECLINE}
        onConfirm={() => {
          handleDeleteRun(runToDelete);
        }}
        onCancel={() => setRunToDelete('')}
      />
    </div>
  );

  return (
    <>
      {message && message.content && <Alert message={message.content} type={message.type} banner />}
      {renderDetailsDrawer()}
      <div className="content-wrapper_padding">
        {evalRun && (
          <>
            <DetailsHeader
              title={evalRun.name}
              subtitle={new Date(evalRun.created_at).toLocaleString()}
              createdBy={getUserFullname(evalRun.created_by)}
              tags={evalRun.tags}
              status={<RunStatusTag status={evalRun.status} />}
              actions={renderActions()}
            />
            {namesArea({
              pipelineName: evalRun.parameters.pipeline_name,
              evaluationName: evalRun.parameters.evaluation_set_name,
              snapshotDate: evalRun.parameters.pipeline_snapshot_at,
              sasModelName: evalRun.parameters.sas_model_name,
            })}
            {commentArea(runComment)}
            {logsArea()}
            {metricsSection(METRICS_HEADER, evalRun.eval_results)}
            {pipelineArea(
              evalRun.parameters.pipeline_name,
              evalRun.parameters.pipeline_snapshot_yaml,
              evalRun.parameters.pipeline_snapshot_at,
            )}
            {predictionsSection(evalRun.eval_results)}
          </>
        )}
      </div>
    </>
  );
};

export default ExperimentDetailsPage;
