import React from 'react';
import { theme } from 'antd';

const { useToken } = theme;

export const ChatBlocksSVG = () => {
  const { token } = useToken();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
    >
      <path
        d="M28.8086 54.375H152.558V94.9999H147.871V105.625L137.246 94.9999H28.8086V54.375Z"
        fill="#E6E6E6"
      />
      <path d="M149.121 58.3301H32.2461V90.83H149.121V58.3301Z" fill="white" />
      <path d="M97.9168 67.8203H41.6445V69.528H97.9168V67.8203Z" fill={token.colorPrimary} />
      <path d="M139.722 73.7285H41.6445V75.4362H139.722V73.7285Z" fill="#E6E6E6" />
      <path d="M139.626 79.6348H41.6445V81.3425H139.626V79.6348Z" fill="#E6E6E6" />
      <path
        d="M130.683 108.75H6.93359V149.375H11.6211V160L22.246 149.375H130.683V108.75Z"
        fill="#E6E6E6"
      />
      <path d="M127.246 112.705H10.3711V145.205H127.246V112.705Z" fill="white" />
      <path d="M76.9803 122.508H20.708V124.216H76.9803V122.508Z" fill={token.colorPrimary} />
      <path d="M118.786 128.416H20.708V130.124H118.786V128.416Z" fill="#E6E6E6" />
      <path d="M118.69 134.322H20.708V136.03H118.69V134.322Z" fill="#E6E6E6" />
      <path
        d="M130.683 0H6.93359V40.6249H11.6211V51.2501L22.246 40.6249H130.683V0Z"
        fill="#E6E6E6"
      />
      <path d="M127.246 3.95508H10.3711V36.455H127.246V3.95508Z" fill="white" />
      <path d="M76.9803 13.7578H20.708V15.4655H76.9803V13.7578Z" fill={token.colorPrimary} />
      <path d="M118.786 19.666H20.708V21.3737H118.786V19.666Z" fill="#E6E6E6" />
      <path d="M118.69 25.5742H20.708V27.2819H118.69V25.5742Z" fill="#E6E6E6" />
    </svg>
  );
};

export default ChatBlocksSVG;
