export const formatDecimalPlaces = (num: number, places = 1): number => {
  if ((!num && num !== 0) || Number.isNaN(num)) return 0;
  return +num.toFixed(places);
};

export const formatNumberToLocaleString = (
  num: number,
  simplified: boolean = false,
  options: Intl.NumberFormatOptions = { minimumFractionDigits: 1, maximumFractionDigits: 3 },
) => {
  if ((!num && num !== 0) || Number.isNaN(num)) {
    return '';
  }
  if (simplified)
    return num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  return num.toLocaleString('en-US', options);
};

const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export const formatOrdinals = (n: number) => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export const kFormatter = (numb: number, compact: boolean = true) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: compact ? 'short' : 'long',
  }).format(numb);
};
