import React, { useEffect, useState } from 'react';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Badge, Button, theme } from 'antd';
import userflow from 'userflow.js';
import { RESOURCE_CENTER_GET_STARTED_LABEL, RESOURCE_CENTER_LABEL } from '@constants/userflow';
import styles from './userflowCenter.module.scss';

const { useToken } = theme;

export interface IUserflowCenterProps {
  collapsed?: boolean;
}

const CustomResourceCenterLauncher = (props: IUserflowCenterProps) => {
  const { collapsed = false } = props;
  const { token } = useToken();
  const [resourceCenterState, setResourceCenterState] = useState(() =>
    userflow.getResourceCenterState(),
  );
  const { uncompletedChecklistTaskCount, unreadAnnouncementCount } = resourceCenterState || {
    uncompletedChecklistTaskCount: 0,
    unreadAnnouncementCount: 0,
  };

  useEffect(() => {
    const onChange = () => {
      setResourceCenterState(userflow.getResourceCenterState());
    };

    userflow.on('resourceCenterChanged', onChange);
    return () => userflow.off('resourceCenterChanged', onChange);
  }, []);

  const renderButtonText = () => {
    if (uncompletedChecklistTaskCount > 0) return RESOURCE_CENTER_GET_STARTED_LABEL;
    return RESOURCE_CENTER_LABEL;
  };

  return (
    <div
      className={`${styles.resourceCenterUserFlow} ${
        collapsed ? styles.resourceCenterUserFlow_collapsed : ''
      }`}
    >
      <Badge
        count={unreadAnnouncementCount}
        size="small"
        className={styles.resourceCenterUserFlow_badge}
      >
        <Button onClick={() => userflow.toggleResourceCenter()} block>
          {!collapsed && (
            <span className={styles.resourceCenterUserFlow_textWrapper}>
              {uncompletedChecklistTaskCount > 0 && (
                <Badge count={uncompletedChecklistTaskCount} color={token.colorInfoBorder} />
              )}
              {renderButtonText()}
            </span>
          )}
          <span className={styles.resourceCenterUserFlow_iconWrapper}>
            <QuestionCircleTwoTone />
          </span>
        </Button>
      </Badge>
    </div>
  );
};

export default CustomResourceCenterLauncher;
