import { useEffect, useState } from 'react';

const MOBILE_MAX_WIDTH = 768;
const TABLET_MAX_WIDTH = 1024;

export const useScreen = () => {
  const getScreen = () => {
    return window?.screen;
  };

  const [screen, setScreen] = useState<Screen>(getScreen());

  const handleSize = () => {
    setScreen(getScreen());
  };

  const isMobileScreen = () => {
    return screen?.width <= MOBILE_MAX_WIDTH;
  };

  const isTabletScreen = () => {
    return screen?.width > MOBILE_MAX_WIDTH && screen?.width <= TABLET_MAX_WIDTH;
  };

  useEffect(() => {
    if (!window?.addEventListener) return () => {};

    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);

  return { screen, isMobileScreen: isMobileScreen(), isTabletScreen: isTabletScreen() };
};
