export const useDeviceDetection = () => {
  const { userAgent } = navigator;
  const isMobile = /Android|iPhone|Windows Phone|BlackBerry|Mobile/i.test(userAgent);
  const isTablet =
    /iPad|Tablet|Playbook|Nexus 7|Nexus 10|SCH-I800|GT-P5210|Xoom|Kindle Fire|Silk|KFAPWI|KFTHWI|KFJWI|KFJWA|KFSOWI|KFTHWA|KFAPWA|KFTT|KFOT|KINDLE|KFASWI|KFSAWA|KFSAWI|KFARWI|Gigaset|Gigaset QV830|Gigaset QV1030|Gigaset QV831|Gigaset QV830|Gigaset QV1030|Gigaset QV831|Touchpad|HP Slate/i.test(
      userAgent,
    );

  return {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet,
  };
};
