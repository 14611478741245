import {
  RESET_ROUTE_NAME,
  UPDATE_COLLAPSED,
  UPDATE_OPEN_KEYS,
  UPDATE_ROUTE_NAME,
} from '@redux/types/types';

export function updateOpenKeys(keys: string[]) {
  return {
    type: UPDATE_OPEN_KEYS,
    payload: keys,
  };
}

export function updateCollapsed(collapsed: boolean) {
  return {
    type: UPDATE_COLLAPSED,
    payload: collapsed,
  };
}

export function updateRouteName(routeName: string) {
  return {
    type: UPDATE_ROUTE_NAME,
    payload: routeName,
  };
}

export function resetRouteName() {
  return {
    type: RESET_ROUTE_NAME,
  };
}
