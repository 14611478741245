import React, { useEffect, useRef, useState } from 'react';
import styles from './ellipsisMiddle.module.scss';

interface IEllipsisMiddleProps {
  children: string;
}

const EllipsisMiddle = ({ children }: IEllipsisMiddleProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [displayText, setDisplayText] = useState(children);

  useEffect(() => {
    const calculateEllipsis = () => {
      if (!textRef.current) return;

      const containerWidth = textRef.current.clientWidth;
      const textWidth = textRef.current.scrollWidth;

      if (textWidth <= containerWidth) {
        setDisplayText(children);
        return;
      }

      let start = 0;
      let end = children.length;
      while (start < end - 1) {
        let middle = Math.floor((start + end) / 2);
        const truncatedText = `${children.slice(0, middle)}...${children.slice(-middle)}`;
        textRef.current.textContent = truncatedText;

        if (textRef.current.scrollWidth > containerWidth) {
          end = middle;
        } else {
          start = middle;
        }
      }

      setDisplayText(`${children.slice(0, start)}...${children.slice(-start)}`);
    };

    const resizeObserver = new ResizeObserver(calculateEllipsis);
    if (textRef.current?.parentElement) resizeObserver.observe(textRef.current.parentElement);

    calculateEllipsis();
    window.addEventListener('resize', calculateEllipsis);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', calculateEllipsis);
    };
  }, [children]);

  return (
    <span ref={textRef} className={styles.textContainer} title={children}>
      {displayText}
    </span>
  );
};

export default EllipsisMiddle;
