import React from 'react';
import {
  AppstoreAddOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeploymentUnitOutlined,
  FileOutlined,
  ProjectOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import airbusSVG from '@assets/icons/partners/airbus.svg';
import bafinSVG from '@assets/icons/partners/bafin.svg';
import infineonSVG from '@assets/icons/partners/infineon.svg';
import manzSVG from '@assets/icons/partners/manz.svg';
import siemensSVG from '@assets/icons/partners/siemens.svg';
import ypulseSVG from '@assets/icons/partners/ypulse.svg';

// LIMITS
export const FREE_TIER_LIMITS = {
  WORKSPACES: 1,
  PIPELINE_HOURS: 100,
  FILES: 50,
  PIPELINES: 2,
};

export const TITLE = 'Streamline AI with an All-in-One Platform';
export const DESCRIPTION =
  'Eliminate the complexity and costs of managing multiple AI tools. With our all-in-one platform, get your AI into production faster than ever.';
export const PRICING_PLANS = [
  {
    title: 'Free',
    description: 'Great for individuals prototyping AI applications',
    price: '$0',
    buttonText: 'Current Plan',
    buttonDisabled: true,
    features: [
      { icon: <AppstoreAddOutlined />, text: '1 Workspace' },
      { icon: <UserOutlined />, text: '1 User' },
      { icon: <ClockCircleOutlined />, text: '100 Pipeline Hours' },
      { icon: <FileOutlined />, text: '50 Files (Max 10MB per File)' },
      { icon: <ProjectOutlined />, text: '2 Development Pipelines' },
      { icon: <DeploymentUnitOutlined />, text: 'Cloud Deployment' },
      { icon: <QuestionCircleOutlined />, text: 'Community Support on Discord' },
    ],
  },
  {
    title: 'Enterprise',
    description: 'Great for teams building production-grade AI applications',
    price: 'Custom',
    buttonText: 'Contact Us',
    buttonDisabled: false,
    features: [
      { icon: <AppstoreAddOutlined />, text: 'Unlimited Workspaces' },
      { icon: <UserOutlined />, text: 'Unlimited Users' },
      { icon: <ClockCircleOutlined />, text: 'Custom Pipeline Hours' },
      { icon: <FileOutlined />, text: 'Unlimited Files (No Max Size)' },
      { icon: <ProjectOutlined />, text: 'Unlimited Development Pipelines' },
      { icon: <ProjectOutlined />, text: 'Unlimited Production (High Availability) Pipelines' },
      { icon: <DeploymentUnitOutlined />, text: 'Cloud or Custom Deployment' },
      {
        icon: <QuestionCircleOutlined />,
        text: 'Dedicated Account Team, Solution Engineers, and Private Slack',
      },
    ],
  },
];

export const LOGOS = [
  { src: airbusSVG, alt: 'Airbus' },
  { src: infineonSVG, alt: 'Infineon' },
  { src: siemensSVG, alt: 'Siemens' },
  { src: bafinSVG, alt: 'BaFin' },
  { src: manzSVG, alt: 'Manz' },
  { src: ypulseSVG, alt: 'Pulse' },
];

// FEATURE COMPARISON

export const FEATURE_COMPARISION_TITLE = 'Compare plans and features';
export const FEATURE_COMPARISION_OPTIONS = [
  { label: 'Free', price: '$0', buttonText: 'Current Plan', buttonDisabled: true },
  { label: 'Enterprise', price: 'Custom', buttonText: 'Contact Sales', buttonDisabled: false },
];

export const FEATURE_COMPARISON_SECTIONS = [
  {
    title: 'Usage',
    features: [
      { label: 'Credit grants', free: '100 credits', enterprise: 'Custom' },
      { label: 'Data', free: '50 files (max 10mb per file)', enterprise: 'Custom' },
      { label: 'Development pipelines', free: '2', enterprise: 'Unlimited' },
      { label: 'Production pipelines', free: <CloseOutlined />, enterprise: 'Unlimited' },
      { label: 'Workspaces', free: '1', enterprise: 'Unlimited' },
      { label: 'Users', free: '1', enterprise: 'Unlimited' },
    ],
  },
  {
    title: 'Features',
    features: [
      { label: 'Studio', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Prompt Studio', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Jobs', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
      {
        label: 'Groundedness Observability',
        free: <CloseOutlined />,
        enterprise: <CheckOutlined />,
      },
      { label: 'Playground', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Search history', free: '14 days', enterprise: 'Unlimited' },
      { label: 'Logs', free: '14 days', enterprise: 'Unlimited' },
      { label: 'Pipeline templates', free: 'Basic', enterprise: 'Basic + Premium' },
      { label: 'Component library', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Custom components', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Shareable prototypes', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Feedback collection', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Integrations', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
      { label: 'API access', free: <CheckOutlined />, enterprise: <CheckOutlined /> },
    ],
  },
  {
    title: 'Infrastructure',
    features: [
      { label: 'Cloud deployment', free: 'Limited to 100 credits in uptime', enterprise: 'Custom' },
      {
        label: 'Other Deployment options',
        free: <CloseOutlined />,
        enterprise: 'VPC (Virtual Private Cloud) / On-Premise',
      },
      { label: 'Dedicated infrastructure', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
    ],
  },
  {
    title: 'Other',
    features: [
      {
        label: 'Support',
        free: 'Community on Discord',
        enterprise: 'Dedicated Account team, Solution Engineers and private Slack',
      },
      { label: 'SSO (Single Sign On)', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
      {
        label: 'Role based access control',
        free: <CloseOutlined />,
        enterprise: <CheckOutlined />,
      },
      { label: 'InfoSec review', free: <CloseOutlined />, enterprise: <CheckOutlined /> },
      { label: 'Compliance', free: 'SOC 2 / GDPR', enterprise: 'SOC 2 / GDPR' },
    ],
  },
];

// CONFIRM MODAL

export const UPGRADE_CONFIRM_MODAL_TITLE = 'Contact Us';
export const UPGRADE_CONFIRM_MODAL_CONTENT =
  'Thank you for showing an interest in the Enterprise plan. Once you confirm, we will reach out via email to schedule a call and discuss your needs.';
export const UPGRADE_CONFIRM_MODAL_OK_TEXT = 'Confirm';
export const UPGRADE_CONFIRM_MODAL_CANCEL_TEXT = 'Cancel';
export const UPGRADE_CONFIRM_MODAL_SUCCESS_NOTIFICATION =
  'Your interest for the deepset Enterprise plan has been submitted successfully.';
