import axios from 'axios';
import { auth } from '@utils/auth';
import { API_URL } from '@constants/constant';
import { CustomHTTPHeaders } from '@constants/http';

const httpExt = axios.create({
  baseURL: API_URL,
  headers: {
    [CustomHTTPHeaders.X_CLIENT_SOURCE]: 'deepset-cloud-ui',
  },
});

httpExt.interceptors.request.use(
  async (config) => {
    const localConfig = config;
    if (localConfig.headers && !localConfig.headers.Authorization) {
      const { user_token: token } = JSON.parse(sessionStorage.getItem('dcExtcurrent') || '{}');
      if (token) localConfig.headers.Authorization = `Bearer ${token}`;
      else {
        try {
          const auht0token = await auth.getAccessTokenSilently({});
          localConfig.headers.Authorization = `Bearer ${auht0token}`;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
    if (localConfig.url) localConfig.url = encodeURI(localConfig.url);
    return localConfig;
  },
  (error) => Promise.reject(error),
);

export { httpExt };
