import React, { useEffect } from 'react';
import TopNavigation from '@components/settings/TopNavigation';
import SecretsTable from './features/SecretsTable';
import useSecretsManagement from './hooks/useSecretsManagement';
import styles from './secretsManagementPage.module.scss';

const SecretsManagementPage = () => {
  const { resetSecretsManagementStatuses } = useSecretsManagement();

  useEffect(() => {
    resetSecretsManagementStatuses();
  }, []);

  return (
    <div className={styles.container}>
      <TopNavigation />
      <div className={styles.content}>
        <SecretsTable />
      </div>
    </div>
  );
};

export default SecretsManagementPage;
