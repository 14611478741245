export const USER_PREFERENCES = 'dcUserPrefences';
export type FeatureCacheKey =
  | 'editPipelineNameTourDetailsPage'
  | 'editPipelineNameTourDesignerPage'
  | 'observabilityTourPipelinesPage'
  | 'observabilityTourDetailsPage';
export type CacheKey =
  | 'experimentColumnSelection'
  | 'feedbackColumnSelection'
  | 'jobResultsColumnSelection'
  | 'navigationTour'
  | FeatureCacheKey;

export const getUserPreference = (key: CacheKey) => {
  const usersPrefences = JSON.parse(localStorage.getItem(USER_PREFERENCES) || '{}');
  return usersPrefences[key];
};

export const setUserPreference = (key: CacheKey, data: any) => {
  const localUsersPrefences = JSON.parse(localStorage.getItem(USER_PREFERENCES) || '{}');
  localStorage.setItem(
    USER_PREFERENCES,
    JSON.stringify({
      ...localUsersPrefences,
      [key]: data,
    }),
  );
  dispatchEvent(new StorageEvent('storage'));
};

export const getFeatureTourVisit = (key: CacheKey) => {
  const usersPrefences = JSON.parse(localStorage.getItem(USER_PREFERENCES) || '{}');
  return usersPrefences.featureTour && usersPrefences.featureTour[key];
};

export const setFeatureTourVisit = (key: CacheKey, data: any) => {
  const localUsersPrefences = JSON.parse(localStorage.getItem(USER_PREFERENCES) || '{}');

  localStorage.setItem(
    USER_PREFERENCES,
    JSON.stringify({
      ...localUsersPrefences,
      featureTour: {
        ...localUsersPrefences.featureTour,
        [key]: data,
      },
    }),
  );
  dispatchEvent(new StorageEvent('storage'));
};
