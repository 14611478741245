import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { kFormatter } from '@utils/math';
import {
  STATISTIC_AVERAGE_RESPONSE_TIME_LABEL,
  STATISTIC_DOCS_LABEL,
  STATISTIC_DOCS_TOOLTIP,
  STATISTIC_FILES_LABEL,
  STATISTIC_FILES_TOOLTIP,
  STATISTIC_REQUESTS_TODAY_LABEL,
  WORKSPACE_STATISTIC_TITLE,
} from '@constants/dashboard';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';
import styles from './dashboard.module.scss';

export interface IWorkspaceStatisticProps {
  requestsToday: number;
  statistic: {
    fileCount: number;
    documentCount: number;
    averageResponseTime: number;
  };
}
function WorkspaceStatistic(props: IWorkspaceStatisticProps) {
  const { requestsToday, statistic } = props;
  const { isLimitedUser }: IUserData = useSelector(userSelector);

  return (
    <section>
      <div className={styles.blockTitle}>{WORKSPACE_STATISTIC_TITLE}</div>
      <div className={styles.statisticsContainer}>
        <div className={styles.statisticItem}>
          <Tooltip placement="top" title={STATISTIC_FILES_TOOLTIP}>
            <div className={styles.statisticValue} data-testid="files">
              {statistic && kFormatter(statistic.fileCount)}
            </div>
            <div className={styles.statisticLabel}>{STATISTIC_FILES_LABEL}</div>
          </Tooltip>
        </div>
        {!isLimitedUser && (
          <div className={styles.statisticItem}>
            <Tooltip placement="top" title={STATISTIC_DOCS_TOOLTIP}>
              <div className={styles.statisticValue} data-testid="documents">
                {statistic && kFormatter(statistic.documentCount)}
              </div>
              <div className={styles.statisticLabel}>{STATISTIC_DOCS_LABEL}</div>
            </Tooltip>
          </div>
        )}
        <div className={styles.statisticItem}>
          <div className={styles.statisticValue} data-testid="average-responce-time">
            {statistic && `${statistic.averageResponseTime}s`}
          </div>
          <div className={styles.statisticLabel}>{STATISTIC_AVERAGE_RESPONSE_TIME_LABEL}</div>
        </div>
        <div className={styles.statisticItem}>
          <div className={styles.statisticValue} data-testid="requests-today">
            {requestsToday}
          </div>
          <div className={styles.statisticLabel}>{STATISTIC_REQUESTS_TODAY_LABEL}</div>
        </div>
      </div>
    </section>
  );
}

export default WorkspaceStatistic;
