import React from 'react';
import { theme } from 'antd';

const { useToken } = theme;

const SearchStarSVG = () => {
  const { token } = useToken();
  return (
    <svg
      width="301"
      height="301"
      viewBox="0 0 301 301"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_395_17016)">
        <path
          d="M268.047 158.417C266.74 173.515 260.158 188.105 250.769 200.172C250.377 200.677 249.98 201.177 249.578 201.672C248.674 202.786 247.746 203.88 246.795 204.952C245.632 206.274 244.434 207.565 243.203 208.825C242.201 209.862 241.175 210.878 240.126 211.871C239.316 212.645 238.497 213.404 237.669 214.149C227.705 223.152 216.259 230.582 204.466 237.08C181.451 249.756 156.224 259.359 129.984 260.757C103.748 262.152 76.3594 254.638 57.1187 236.743C31.1877 212.628 23.9505 172.404 34.4785 138.595C41.4809 116.102 55.7729 95.7538 75.2239 82.4641C94.5066 69.29 118.833 63.3601 141.855 67.2363C160.233 56.0669 184.293 55.8531 204.273 64.1206C214.934 68.533 224.502 75.0938 232.896 83.0213C236.74 86.6594 240.336 90.5501 243.662 94.6674C244.002 95.0844 244.338 95.505 244.671 95.929C246.066 97.6919 247.415 99.4909 248.719 101.326C250.591 103.958 252.368 106.649 254.05 109.398C254.512 110.155 254.972 110.915 255.42 111.683C255.722 112.198 256.026 112.716 256.324 113.239C256.331 113.249 256.336 113.261 256.338 113.274C261.003 121.478 264.816 130.233 266.803 139.383C267.154 141.003 267.448 142.629 267.676 144.269V144.272C267.876 145.664 268.026 147.062 268.128 148.468C268.368 151.781 268.341 155.108 268.047 158.417Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.3"
          d="M199.581 181.242L147.55 137.897L198.097 182.978C198.195 183.143 198.331 183.282 198.493 183.384C198.655 183.486 198.84 183.548 199.031 183.564C199.222 183.581 199.414 183.551 199.592 183.479C199.769 183.406 199.927 183.292 200.051 183.146C200.176 183 200.264 182.827 200.308 182.64C200.352 182.454 200.351 182.259 200.305 182.073C200.259 181.887 200.169 181.715 200.043 181.57C199.917 181.425 199.759 181.313 199.581 181.242Z"
          fill="#F0F0F0"
        />
        <path
          d="M80.2601 159.185C97.4867 159.185 111.452 145.22 111.452 127.993C111.452 110.767 97.4867 96.8018 80.2601 96.8018C63.0334 96.8018 49.0685 110.767 49.0685 127.993C49.0685 145.22 63.0334 159.185 80.2601 159.185Z"
          fill="#F2F2F2"
        />
        <path
          d="M107.947 125.89C107.947 129.618 107.213 133.31 105.787 136.754C104.36 140.199 102.269 143.328 99.633 145.964C96.9969 148.6 93.8674 150.691 90.4231 152.118C86.9788 153.545 83.2872 154.279 79.5592 154.278C76.2364 154.276 72.9393 153.695 69.8162 152.561C64.9328 150.782 60.6311 147.697 57.3794 143.643C54.1277 139.588 52.0507 134.72 51.3744 129.566C50.6981 124.413 51.4484 119.173 53.5438 114.417C55.6392 109.661 58.9992 105.571 63.2582 102.592C67.5171 99.6131 72.5116 97.8597 77.698 97.5227C82.8843 97.1857 88.0638 98.278 92.6724 100.681C97.281 103.083 101.142 106.704 103.835 111.149C106.529 115.594 107.951 120.693 107.947 125.89Z"
          fill="white"
        />
        <path
          d="M74.6526 125.189C79.4916 125.189 83.4143 121.267 83.4143 116.428C83.4143 111.589 79.4916 107.666 74.6526 107.666C69.8137 107.666 65.8909 111.589 65.8909 116.428C65.8909 121.267 69.8137 125.189 74.6526 125.189Z"
          fill="#E4E4E4"
        />
        <path
          d="M106.84 118.015C104.941 118.929 102.776 119.128 100.743 118.575C98.7092 118.022 96.943 116.755 95.7678 115.006C94.5927 113.257 94.0872 111.143 94.3441 109.051C94.601 106.96 95.6031 105.031 97.1666 103.618C101.813 107.291 105.195 112.325 106.84 118.015Z"
          fill="#F2F2F2"
        />
        <path
          d="M89.0218 138.507C90.7638 138.507 92.176 137.095 92.176 135.353C92.176 133.611 90.7638 132.199 89.0218 132.199C87.2798 132.199 85.8676 133.611 85.8676 135.353C85.8676 137.095 87.2798 138.507 89.0218 138.507Z"
          fill="#E4E4E4"
        />
        <path
          d="M76.0545 153.227C76.0542 153.504 76.0177 153.78 75.9458 154.047C73.853 153.785 71.7966 153.287 69.8162 152.561C69.9806 151.8 70.4208 151.127 71.0518 150.671C71.6829 150.215 72.4603 150.008 73.2346 150.091C74.0088 150.173 74.7251 150.539 75.2457 151.118C75.7663 151.697 76.0544 152.448 76.0545 153.227Z"
          fill="#F2F2F2"
        />
        <path
          d="M57.4797 124.489C57.4796 126.089 56.8713 127.629 55.7781 128.798C54.685 129.967 53.1885 130.676 51.5919 130.783C50.8692 126.617 51.0798 122.343 52.2087 118.268C53.6813 118.513 55.0191 119.273 55.9843 120.412C56.9494 121.551 57.4793 122.996 57.4797 124.489Z"
          fill="#F2F2F2"
        />
        <path
          d="M213.043 114.467C213.76 114.467 214.341 113.886 214.341 113.169C214.341 112.453 213.76 111.872 213.043 111.872C212.327 111.872 211.746 112.453 211.746 113.169C211.746 113.886 212.327 114.467 213.043 114.467Z"
          fill={token.colorPrimary}
        />
        <path
          d="M201.073 82.899C201.492 82.899 201.831 82.5596 201.831 82.1409C201.831 81.7222 201.492 81.3828 201.073 81.3828C200.655 81.3828 200.315 81.7222 200.315 82.1409C200.315 82.5596 200.655 82.899 201.073 82.899Z"
          fill="#F0F0F0"
        />
        <path
          d="M79.4611 231.497C79.8798 231.497 80.2192 231.158 80.2192 230.739C80.2192 230.32 79.8798 229.981 79.4611 229.981C79.0424 229.981 78.703 230.32 78.703 230.739C78.703 231.158 79.0424 231.497 79.4611 231.497Z"
          fill="#F0F0F0"
        />
        <path
          d="M205.377 157.074C205.795 157.074 206.135 156.734 206.135 156.316C206.135 155.897 205.795 155.558 205.377 155.558C204.958 155.558 204.619 155.897 204.619 156.316C204.619 156.734 204.958 157.074 205.377 157.074Z"
          fill="#F0F0F0"
        />
        <path
          d="M107.553 164.233C108.269 164.233 108.85 163.652 108.85 162.936C108.85 162.219 108.269 161.638 107.553 161.638C106.836 161.638 106.255 162.219 106.255 162.936C106.255 163.652 106.836 164.233 107.553 164.233Z"
          fill={token.colorPrimary}
        />
        <path
          d="M60.5899 192.194C63.9739 192.194 66.7171 189.45 66.7171 186.066C66.7171 182.682 63.9739 179.939 60.5899 179.939C57.2059 179.939 54.4627 182.682 54.4627 186.066C54.4627 189.45 57.2059 192.194 60.5899 192.194Z"
          fill={token.colorPrimary}
        />
        <path
          d="M94.1807 193.647C94.5994 193.647 94.9388 193.307 94.9388 192.888C94.9388 192.47 94.5994 192.13 94.1807 192.13C93.762 192.13 93.4226 192.47 93.4226 192.888C93.4226 193.307 93.762 193.647 94.1807 193.647Z"
          fill="#F0F0F0"
        />
        <path
          d="M171.284 75.1885C171.702 75.1885 172.042 74.8491 172.042 74.4304C172.042 74.0118 171.702 73.6724 171.284 73.6724C170.865 73.6724 170.525 74.0118 170.525 74.4304C170.525 74.8491 170.865 75.1885 171.284 75.1885Z"
          fill="#F0F0F0"
        />
        <path
          d="M126.073 236.754C126.492 236.754 126.831 236.415 126.831 235.996C126.831 235.577 126.492 235.238 126.073 235.238C125.655 235.238 125.315 235.577 125.315 235.996C125.315 236.415 125.655 236.754 126.073 236.754Z"
          fill="#F0F0F0"
        />
        <path
          d="M114.858 82.1981C115.277 82.1981 115.616 81.8586 115.616 81.44C115.616 81.0213 115.277 80.6819 114.858 80.6819C114.44 80.6819 114.1 81.0213 114.1 81.44C114.1 81.8586 114.44 82.1981 114.858 82.1981Z"
          fill="#F0F0F0"
        />
        <path
          d="M134.484 118.997C134.903 118.997 135.243 118.658 135.243 118.239C135.243 117.82 134.903 117.481 134.484 117.481C134.066 117.481 133.726 117.82 133.726 118.239C133.726 118.658 134.066 118.997 134.484 118.997Z"
          fill="#F0F0F0"
        />
        <path
          d="M104.442 209.293C104.861 209.293 105.2 208.954 105.2 208.535C105.2 208.117 104.861 207.777 104.442 207.777C104.023 207.777 103.684 208.117 103.684 208.535C103.684 208.954 104.023 209.293 104.442 209.293Z"
          fill="#F0F0F0"
        />
        <path
          d="M193.11 214.2C193.529 214.2 193.868 213.861 193.868 213.442C193.868 213.023 193.529 212.684 193.11 212.684C192.692 212.684 192.352 213.023 192.352 213.442C192.352 213.861 192.692 214.2 193.11 214.2Z"
          fill="#F0F0F0"
        />
        <path
          d="M153.858 170.041C154.277 170.041 154.616 169.702 154.616 169.283C154.616 168.864 154.277 168.525 153.858 168.525C153.439 168.525 153.1 168.864 153.1 169.283C153.1 169.702 153.439 170.041 153.858 170.041Z"
          fill="#F0F0F0"
        />
        <path
          d="M173.835 118.873C174.253 118.873 174.593 118.533 174.593 118.115C174.593 117.696 174.253 117.357 173.835 117.357C173.416 117.357 173.077 117.696 173.077 118.115C173.077 118.533 173.416 118.873 173.835 118.873Z"
          fill="#F0F0F0"
        />
        <path
          d="M210.154 144.172C210.984 144.172 211.657 143.499 211.657 142.669C211.657 141.838 210.984 141.166 210.154 141.166C209.324 141.166 208.651 141.838 208.651 142.669C208.651 143.499 209.324 144.172 210.154 144.172Z"
          fill="#FF6584"
        />
        <path
          d="M149.172 94.4054C150.003 94.4054 150.676 93.7324 150.676 92.9023C150.676 92.0721 150.003 91.3992 149.172 91.3992C148.342 91.3992 147.669 92.0721 147.669 92.9023C147.669 93.7324 148.342 94.4054 149.172 94.4054Z"
          fill="#FF6584"
        />
        <path
          d="M149.172 197.793C150.003 197.793 150.676 197.12 150.676 196.29C150.676 195.46 150.003 194.787 149.172 194.787C148.342 194.787 147.669 195.46 147.669 196.29C147.669 197.12 148.342 197.793 149.172 197.793Z"
          fill="#FF6584"
        />
        <path
          d="M242.821 204.952C242.235 204.953 241.666 205.144 241.199 205.497C240.731 205.85 240.392 206.345 240.231 206.908C240.16 207.149 240.125 207.4 240.126 207.651V211.871C241.174 210.879 242.199 209.864 243.203 208.825C244.433 207.563 245.63 206.272 246.795 204.952H242.821Z"
          fill={token.colorPrimary}
        />
        <path
          d="M252.364 226.878C247.838 226.878 242.879 226.707 237.484 226.365C209.582 224.593 173.44 218.433 135.717 209.019C97.9932 199.606 63.1945 188.064 37.7312 176.519C25.3272 170.895 15.8073 165.572 9.43609 160.696C2.69062 155.535 -0.259944 151.036 0.666015 147.325C2.47022 140.095 18.4456 138.513 31.5296 138.46L31.534 139.511C13.5662 139.585 2.96614 142.45 1.68629 147.58C0.055963 154.112 13.3522 164.311 38.1653 175.561C63.5727 187.081 98.3074 198.601 135.971 207.999C173.635 217.398 209.71 223.547 237.551 225.315C264.74 227.042 281.269 224.286 282.899 217.753C284.253 212.329 275.33 204.339 257.774 195.254L258.258 194.32C271.014 200.921 285.82 210.389 283.92 218.008C282.993 221.719 278.275 224.304 269.896 225.691C265.119 226.481 259.252 226.878 252.364 226.878Z"
          fill={token.colorPrimary}
        />
        <path
          d="M250.938 148.496C270.39 148.496 286.16 132.726 286.16 113.274C286.16 93.8212 270.39 78.0518 250.938 78.0518C231.485 78.0518 215.716 93.8212 215.716 113.274C215.716 132.726 231.485 148.496 250.938 148.496Z"
          fill="white"
        />
        <path
          d="M250.938 77.5259C243.867 77.5259 236.956 79.6224 231.077 83.5504C225.199 87.4784 220.617 93.0615 217.911 99.5935C215.205 106.126 214.498 113.313 215.877 120.248C217.256 127.182 220.661 133.552 225.66 138.551C230.66 143.55 237.029 146.955 243.964 148.334C250.898 149.714 258.086 149.006 264.618 146.3C271.15 143.594 276.733 139.013 280.661 133.134C284.589 127.255 286.685 120.344 286.685 113.274C286.702 108.574 285.789 103.918 283.999 99.5735C282.208 95.2288 279.576 91.2813 276.253 87.9584C272.93 84.6356 268.982 82.0031 264.638 80.2126C260.293 78.4221 255.637 77.509 250.938 77.5259ZM250.938 147.97C244.075 147.97 237.367 145.935 231.661 142.122C225.956 138.31 221.509 132.891 218.882 126.551C216.256 120.211 215.569 113.235 216.908 106.505C218.247 99.7742 221.551 93.5919 226.404 88.7396C231.256 83.8872 237.438 80.5827 244.169 79.2439C250.899 77.9052 257.875 78.5923 264.215 81.2184C270.555 83.8444 275.974 88.2915 279.787 93.9973C283.599 99.7031 285.634 106.411 285.634 113.274C285.652 117.835 284.766 122.355 283.029 126.572C281.292 130.79 278.736 134.622 275.511 137.847C272.286 141.072 268.454 143.627 264.236 145.365C260.019 147.102 255.499 147.988 250.938 147.97Z"
          fill="#2F2E41"
        />
        <path
          d="M220.028 121.71C219.867 121.709 219.711 121.654 219.586 121.552C219.461 121.45 219.375 121.308 219.342 121.15C218.335 116.018 218.517 110.723 219.874 105.672C221.231 100.622 223.728 95.9491 227.172 92.0134C227.296 91.8753 227.469 91.792 227.655 91.7816C227.84 91.7712 228.022 91.8347 228.16 91.9582C228.299 92.0816 228.382 92.2549 228.393 92.44C228.404 92.6252 228.342 92.8072 228.219 92.9461C224.918 96.7176 222.526 101.195 221.225 106.035C219.924 110.876 219.75 115.949 220.715 120.867C220.736 120.969 220.734 121.075 220.709 121.176C220.684 121.277 220.637 121.371 220.572 121.451C220.506 121.532 220.423 121.597 220.329 121.642C220.235 121.686 220.132 121.71 220.028 121.71H220.028Z"
          fill="#2F2E41"
        />
        <path
          d="M250.916 145.997C263.28 145.997 273.302 135.974 273.302 123.61C273.302 111.246 263.28 101.223 250.916 101.223C238.552 101.223 228.529 111.246 228.529 123.61C228.529 135.974 238.552 145.997 250.916 145.997Z"
          fill={token.colorPrimary}
        />
        <path
          d="M243.801 132.062C242.349 132.022 240.543 131.971 239.152 130.955C238.738 130.64 238.397 130.238 238.155 129.777C237.912 129.317 237.773 128.809 237.747 128.289C237.722 127.92 237.783 127.549 237.924 127.207C238.066 126.865 238.285 126.56 238.564 126.317C239.291 125.703 240.352 125.559 241.495 125.895L240.311 117.236L241.18 117.117L242.573 127.296L241.847 126.963C241.005 126.577 239.849 126.38 239.131 126.987C238.954 127.146 238.817 127.343 238.729 127.564C238.641 127.784 238.606 128.022 238.625 128.258C238.646 128.645 238.75 129.023 238.931 129.366C239.111 129.709 239.363 130.009 239.67 130.246C240.753 131.037 242.192 131.139 243.825 131.184L243.801 132.062Z"
          fill="#2F2E41"
        />
        <path d="M236.009 117.983H231.281V118.861H236.009V117.983Z" fill="#2F2E41" />
        <path d="M250.934 117.983H246.205V118.861H250.934V117.983Z" fill="#2F2E41" />
        <path
          d="M251.856 151.531H231.564C230.807 151.531 230.082 151.832 229.547 152.367C229.012 152.902 228.712 153.627 228.711 154.383V199.359C228.713 202.407 229.549 205.396 231.128 208.003C232.707 210.61 234.969 212.735 237.669 214.148C238.5 213.405 239.319 212.646 240.126 211.87C241.174 210.879 242.199 209.863 243.203 208.825C244.433 207.563 245.63 206.272 246.795 204.952C247.748 203.88 248.676 202.786 249.578 201.672C249.981 201.178 250.378 200.678 250.769 200.172C260.158 188.105 266.74 173.515 268.048 158.417C265.952 156.238 263.437 154.505 260.655 153.321C257.872 152.138 254.879 151.529 251.856 151.531Z"
          fill="#2F2E41"
        />
        <path
          d="M252.595 160.916C252.113 160.443 251.541 160.071 250.914 159.82C250.286 159.569 249.616 159.445 248.94 159.455C247.766 159.464 246.631 159.88 245.73 160.632C245.586 160.749 245.449 160.874 245.32 161.007L228.711 178.04L216.665 190.394C216.483 190.578 216.331 190.791 216.217 191.025C216.036 191.386 215.944 191.785 215.947 192.188C215.951 192.52 216.02 192.848 216.151 193.153C216.282 193.458 216.473 193.734 216.711 193.965L217.184 194.428L220.619 197.775L220.622 197.771C221.084 198.146 221.661 198.348 222.255 198.342C222.587 198.339 222.915 198.269 223.22 198.138C223.525 198.007 223.801 197.817 224.032 197.578L224.59 197.011L228.578 192.921L228.711 192.784L252.686 168.195C253.639 167.218 254.165 165.902 254.148 164.537C254.131 163.172 253.572 161.869 252.595 160.916L252.595 160.916Z"
          fill={token.colorPrimary}
        />
        <path
          d="M252.427 113.166C253.167 111.6 252.408 109.928 251.108 108.928C249.469 107.668 247.35 107.877 245.486 108.473C243.446 109.126 241.451 110.301 239.247 110.234C238.461 110.22 237.698 109.969 237.057 109.513C236.417 109.058 235.928 108.42 235.656 107.683C234.476 104.655 235.809 101.271 237.864 98.9751C239.024 97.6601 240.458 96.6152 242.066 95.9137C243.673 95.2122 245.414 94.8712 247.167 94.9146C249.168 95.0139 251.114 95.6067 252.83 96.6403C254.546 97.6739 255.98 99.1161 257.004 100.838C257.077 100.956 257.192 101.041 257.326 101.076C257.46 101.111 257.602 101.094 257.723 101.027C259.283 100.33 260.966 99.9484 262.674 99.9055C264.382 99.8625 266.081 100.159 267.674 100.777C270.064 101.751 272.101 103.429 273.514 105.588C274.928 107.747 275.651 110.285 275.588 112.865C275.57 113.542 276.622 113.541 276.639 112.865C276.705 109.258 275.374 105.766 272.925 103.118C271.681 101.797 270.185 100.739 268.526 100.007C266.866 99.2745 265.077 98.8821 263.263 98.8531C261.17 98.8086 259.094 99.2415 257.193 100.119L257.912 100.308C256.964 98.7125 255.691 97.3348 254.175 96.2643C252.66 95.1938 250.936 94.4545 249.115 94.0946C247.313 93.7583 245.459 93.8136 243.68 94.2567C241.901 94.6998 240.238 95.5204 238.803 96.6628C236.104 98.775 234.059 102.053 234.186 105.568C234.241 107.09 234.693 108.651 235.749 109.785C237.102 111.237 239.122 111.488 240.987 111.116C243.467 110.621 245.731 108.99 248.316 109.041C248.823 109.041 249.323 109.149 249.785 109.357C250.247 109.565 250.659 109.869 250.994 110.248C251.564 110.891 251.907 111.815 251.519 112.635C251.231 113.244 252.137 113.778 252.427 113.166L252.427 113.166Z"
          fill="#2F2E41"
        />
        <path
          d="M84.6346 239.643C72.1583 239.644 60.5595 239.216 50.1759 238.352C36.6035 237.222 25.8483 235.407 18.2096 232.956C10.122 230.361 5.83131 227.115 5.45671 223.308C4.72669 215.893 19.242 209.035 31.5474 204.589L31.9047 205.578C15.0063 211.684 5.98516 217.944 6.50298 223.205C7.1625 229.906 23.1124 235.045 50.263 237.304C78.0639 239.617 114.65 238.797 153.282 234.994C191.914 231.192 227.958 224.864 254.774 217.175C280.963 209.665 295.605 201.516 294.946 194.815C294.398 189.252 283.309 184.724 263.722 182.066L263.863 181.024C278.096 182.955 295.223 186.898 295.992 194.712C296.367 198.519 292.791 202.539 285.365 206.661C278.35 210.554 268.156 214.432 255.064 218.185C228.188 225.891 192.078 232.232 153.385 236.041C129.112 238.43 105.635 239.643 84.6346 239.643Z"
          fill={token.colorPrimary}
        />
        <path
          d="M294.352 198.502C297.736 198.502 300.479 195.759 300.479 192.375C300.479 188.991 297.736 186.248 294.352 186.248C290.968 186.248 288.224 188.991 288.224 192.375C288.224 195.759 290.968 198.502 294.352 198.502Z"
          fill={token.colorPrimary}
        />
        <path
          d="M116.665 52.3573C120.049 52.3573 122.792 49.614 122.792 46.23C122.792 42.846 120.049 40.1028 116.665 40.1028C113.281 40.1028 110.537 42.846 110.537 46.23C110.537 49.614 113.281 52.3573 116.665 52.3573Z"
          fill="#E6E6E6"
        />
        <path
          d="M7.31888 198.152C10.7029 198.152 13.4461 195.408 13.4461 192.024C13.4461 188.64 10.7029 185.897 7.31888 185.897C3.9349 185.897 1.19164 188.64 1.19164 192.024C1.19164 195.408 3.9349 198.152 7.31888 198.152Z"
          fill="#E6E6E6"
        />
        <path
          d="M45.1693 81.8735C47.2409 81.8735 48.9202 80.1942 48.9202 78.1226C48.9202 76.0511 47.2409 74.3718 45.1693 74.3718C43.0978 74.3718 41.4185 76.0511 41.4185 78.1226C41.4185 80.1942 43.0978 81.8735 45.1693 81.8735Z"
          fill="#FF6584"
        />
        <path
          d="M27.646 224.163C29.7175 224.163 31.3968 222.484 31.3968 220.412C31.3968 218.341 29.7175 216.661 27.646 216.661C25.5745 216.661 23.8952 218.341 23.8952 220.412C23.8952 222.484 25.5745 224.163 27.646 224.163Z"
          fill="#FF6584"
        />
        <path
          d="M283.487 162.831C285.559 162.831 287.238 161.152 287.238 159.08C287.238 157.009 285.559 155.33 283.487 155.33C281.416 155.33 279.736 157.009 279.736 159.08C279.736 161.152 281.416 162.831 283.487 162.831Z"
          fill="#FF6584"
        />
        <path
          d="M226.711 255.979C230.095 255.979 232.839 253.236 232.839 249.852C232.839 246.468 230.095 243.724 226.711 243.724C223.327 243.724 220.584 246.468 220.584 249.852C220.584 253.236 223.327 255.979 226.711 255.979Z"
          fill="#E6E6E6"
        />
      </g>
      <defs>
        <clipPath id="clip0_395_17016">
          <rect width="300" height="300" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchStarSVG;
