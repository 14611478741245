import React, { useMemo } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import { TAGS_SELECT_DESCRIPTION } from '@constants/common';
import { ITag } from '@redux/types/types';
import styles from './runNamingForm.module.scss';

const { Item } = Form;

interface IRunNamingFormProps {
  nameValue: string;
  nameInputInfo: {
    testId?: string;
    label: string;
    placeholder: string;
    validationErrorText: string;
  };
  onNameInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validName: boolean;
  tagSelectInfo: {
    testId?: string;
    label: string;
    placeholder: string;
  };
  selectedTags: string[];
  onSelectTags?: (value: string[]) => void;
  tags: ITag[];
}

const RunNamingForm = ({
  nameValue,
  nameInputInfo,
  onNameInputChange,
  validName,
  tagSelectInfo,
  selectedTags,
  onSelectTags,
  tags,
}: IRunNamingFormProps) => {
  const tagsOptions = useMemo(
    () =>
      tags?.map((tag) => ({
        label: tag.name,
        value: tag.name,
      })) ?? [],
    [tags],
  );

  return (
    <Form layout="vertical" className={styles.form}>
      <Item label={nameInputInfo.label} className={styles.form_item}>
        <Input
          data-testid={nameInputInfo.testId}
          placeholder={nameInputInfo.placeholder}
          value={nameValue}
          onChange={onNameInputChange}
          status={!validName ? 'error' : ''}
          allowClear={{
            clearIcon: !validName ? <CloseCircleFilled className={styles.errorText} /> : <></>,
          }}
        />
        {!validName && (
          <div className={`${styles.form_item_description} ${styles.errorText}`}>
            {nameInputInfo.validationErrorText}
          </div>
        )}
      </Item>
      {/* TODO: Remove condition when tags implemented for jobs */}
      {onSelectTags && (
        <Item label={tagSelectInfo.label} className={styles.form_item}>
          <Select
            data-testid={tagSelectInfo.testId}
            popupMatchSelectWidth
            placeholder={tagSelectInfo.placeholder}
            value={selectedTags}
            onChange={onSelectTags}
            options={tagsOptions}
            mode="tags"
          />
          <div className={styles.form_item_description}>{TAGS_SELECT_DESCRIPTION}</div>
        </Item>
      )}
    </Form>
  );
};

export default RunNamingForm;
