import { TEMPLATE_CATEGORY_TAGS, TEMPLATES_TAGS } from '@utils/templates';
import { DOCS_URL_V1 } from '@constants/constant';

const haystackVersion = import.meta.env.VITE_HAYSTACK_VERSION || 'unstable';

export enum PipelineTemplateType {
  QuestionAnswering = 'questionAnswering',
  DocumentSearch = 'documentSearch',
  Chat = 'chat',
  TextAnalysis = 'textAnalysis',
}

export const PIPELINE_TEMPLATES_TYPE_LABELS = {
  [PipelineTemplateType.QuestionAnswering]: 'Question Answering',
  [PipelineTemplateType.DocumentSearch]: 'Document Search',
  [PipelineTemplateType.Chat]: 'Chat',
  [PipelineTemplateType.TextAnalysis]: 'Text Analysis',
};

export const PIPELINE_TEMPLATES_TYPE_TAGS = {
  [PipelineTemplateType.QuestionAnswering]: TEMPLATES_TAGS.questionAnswering,
  [PipelineTemplateType.DocumentSearch]: TEMPLATES_TAGS.documentSearch,
  [PipelineTemplateType.Chat]: TEMPLATES_TAGS.chat,
  [PipelineTemplateType.TextAnalysis]: TEMPLATE_CATEGORY_TAGS.textAnalysis,
};

export const EMPTY_YAML_TEMPLATE = `# If you need help with the YAML format, have a look at ${DOCS_URL_V1}docs/create-a-pipeline#create-a-pipeline-using-yaml.
  # This is a friendly editor that helps you create your pipelines with autosuggestions. To use them, press control + space on your keyboard.
  # Whenever you need to specify a model, this editor helps you out as well. Just type your Hugging Face organization and a forward slash (/) to see available models.
                
  #Update the pipeline name configuration:'
  version: '${haystackVersion}'`;

export const QUERY_PIPELINE_EMPTY_YAML_TEMPLATE = `# If you need help with the YAML format, have a look at ${DOCS_URL_V1}docs/create-a-pipeline#create-a-pipeline-using-pipeline-designer.
# This section defines components that you want to use in your pipelines. Each node must have a name and a type. You can also set the component's parameters here.
# The name is up to you, you can give your component a friendly name. You then use components' names when specifying the connections in the pipeline.
# Type is the class path of the component.

# components:
#   component_1: # Give your component a friendly name
#     type: # You can find the component type in documentation on a component's page (here maybe a link to components' docs)
#     init_parameters: # Customize the component's parameters. To use default values, type an empty dictionary like this: '{}'.
#   component_2:
#     type: 
#     init_parameters:
 
# connections:  # Define how the components are connected, you can check components outputs and inputs in documentation
# - sender: component_1.output_name # Here you define the output name this compnent sends to the receiver component
#   receiver: component_2.input_name # Here you define the input name that receives the output of the sender component

# inputs:  # List all components that need query and filters as inputs but aren't getting them from any other component connected to them
#   query:  # These components will receive the query as input
#   - "component_1.question"
#   filters:  # These components will receive a potential query filter as input
#   - "component_1.filters"
# outputs:  # Defines the output of your pipeline, usually the output of the last component
#   documents: "component_2.documents"  # The output of the pipeline is the retrieved documents`;

export const INDEXING_PIPELINE_EMPTY_YAML_TEMPLATE = `# If you need help with the YAML format, have a look at ${DOCS_URL_V1}docs/create-a-pipeline#create-a-pipeline-using-pipeline-designer.
# This section defines components that you want to use in your pipelines. Each node must have a name and a type. You can also set the component's parameters here.
# The name is up to you, you can give your component a friendly name. You then use components' names when specifying the connections in the pipeline.
# Type is the class path of the component.

# components:
#   component_1: # Give your component a friendly name
#     type: # You can find the component type in documentation on a component's page (here maybe a link to components' docs)
#     init_parameters: # Customize the component's parameters. To use default values, type an empty dictionary like this: '{}'.
#   component_2:
#     type: 
#     init_parameters:
 
# connections:  # Define how the components are connected, you can check components outputs and inputs in documentation
# - sender: component_1.output_name # Here you define the output name this compnent sends to the receiver component
#   receiver: component_2.input_name # Here you define the input name that receives the output of the sender component

# inputs:  # List all components that need query and filters as inputs but aren't getting them from any other component connected to them
#   query:  # These components will receive the query as input
#   - "component_1.question"
#   filters:  # These components will receive a potential query filter as input
#   - "component_1.filters"`;

// Private for Labeling projects
// TODO: Move it to templates repository, keep it only visible for labeling - https://github.com/deepset-ai/haystack-hub-ui/issues/2398
export const INTERLEAVE_DOC_SEARCH = `# If you need help with the YAML format, have a look at ${DOCS_URL_V1}docs/create-a-pipeline#create-a-pipeline-using-yaml.
# This is a friendly editor that helps you create your pipelines with autosuggestions. To use them, press Control + Space on your keyboard.
# Whenever you need to specify a model, this editor helps you out as well. Just type your Hugging Face organization and a forward slash (/) to see available models.
version: '${haystackVersion}'

# This section defines nodes that you want to use in your pipelines. Each node must have a name and a type. You can also set the node's parameters here.
# The name is up to you, you can give your component a friendly name. You then use components' names when specifying their order in the pipeline.
# Type is the class name of the component. 
components:
  - name: DocumentStore
    type: DeepsetCloudDocumentStore
    params:
      embedding_dim: 1024
      similarity: cosine

  - name: BM25Retriever
    type: BM25Retriever
    params:
      document_store: DocumentStore
      top_k: 20
  
  - name: EmbeddingRetriever
    type: EmbeddingRetriever
    params:
      document_store: DocumentStore
      embedding_model: intfloat/multilingual-e5-large
      model_format: sentence_transformers
      top_k: 20
      scale_score: false

  - name: InterleaveDocuments
    type: InterleaveDocuments
    params:
      interleaving_mode: random
      top_k_join: 15
      score_mode: none
  
  - name: Preprocessor
    type: PreProcessor
    params:
      split_by: word
      split_length: 200
      split_overlap: 0
      split_respect_sentence_boundary: true

  - name: FileTypeClassifier
    type: FileTypeClassifier

  - name: TextConverter
    type: TextConverter

  - name: PDFConverter
    type: PDFToTextConverter

pipelines:
  - name: query
    nodes:
      - name: BM25Retriever #sparse
        inputs: [Query]
      - name: EmbeddingRetriever # dense
        inputs: [Query]
      - name: InterleaveDocuments
        inputs:
          - BM25Retriever
          - EmbeddingRetriever

  - name: indexing
    nodes:
        - name: FileTypeClassifier
          inputs: [File]
        - name: TextConverter
          inputs: [FileTypeClassifier.output_1]
        - name: PDFConverter
          inputs: [FileTypeClassifier.output_2]
        - name: Preprocessor
          inputs: [TextConverter, PDFConverter]
        - name: EmbeddingRetriever
          inputs: [Preprocessor]
        - name: DocumentStore
          inputs: [EmbeddingRetriever]
`;
