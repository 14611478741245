import QuestionCircleOutlined from '@assets/icons/QuestionCircleOutlined';
import { ChartTimeSeriesEntry } from '@redux/types/types';
import { formatNumberToLocaleString } from './math';

export const mergeTimeSeriesByDate = (
  primarySeries: ChartTimeSeriesEntry[] | null,
  secondarySeries: ChartTimeSeriesEntry[] | null,
): ChartTimeSeriesEntry[] => {
  if (!primarySeries) return [];
  if (!secondarySeries) return primarySeries;

  const secondarySeriesMap = new Map(secondarySeries.map((item) => [item[0], item[1]]));
  const combinedSeries = primarySeries.map(([date, value]) => {
    const secondaryValue = secondarySeriesMap.get(date) || 0;
    return [date, value, secondaryValue];
  });

  return combinedSeries;
};

const createCustomEChartTooltip = ({
  params,
  complementaryDataElement,
}: {
  params: {
    axisValueLabel: string;
    seriesName: string;
    marker?: string;
    value: string | number;
  }[];
  complementaryDataElement?: string;
}) => {
  const { axisValueLabel } = params[0];
  const tooltipDiv = params.map((param: any) => {
    const { marker, seriesName, data: tooltipData } = param;
    if (!tooltipData) return '';
    const [, value] = Array.isArray(tooltipData) ? tooltipData : [null, tooltipData];

    return [
      `<div class="custom-tooltip_singleMetric"><span class="custom-tooltip_singleMetric_label">${
        marker || ''
      }${seriesName}:</span> <strong>${formatNumberToLocaleString(value, true)}</strong></div>`,
    ];
  });

  return [
    '<div class="custom-tooltip">',
    `<div>${axisValueLabel}</div>`,
    ...tooltipDiv,
    complementaryDataElement || '',
    '</div>',
  ].join('');
};

export const getTimeSeriesChartTooltip = (params: any, complementaryLabel?: string) => {
  const { data: tooltipData } = params[0];

  const [, , complementaryValue] = tooltipData;
  const complementaryDataElement = complementaryLabel
    ? `<div class="custom-tooltip_singleMetric"><span class="custom-tooltip_singleMetric_label">${QuestionCircleOutlined}${complementaryLabel}:</span> <strong>${formatNumberToLocaleString(
        complementaryValue,
        true,
      )}</strong></div>`
    : '';

  return createCustomEChartTooltip({
    params,
    complementaryDataElement,
  });
};

export const getCategoricalChartTooltip = (params: any) => {
  return createCustomEChartTooltip({
    params,
  });
};
