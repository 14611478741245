import React from 'react';
import { Select, Switch } from 'antd';
import { FilterType, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import styles from './toggleFilter.module.scss';

interface IToggleFilterProps {
  filterKey: string;
  title: string;
  style?: React.CSSProperties;
  filterValues: SelectedFilters;
  option: {
    key: string;
    label: string;
  };
  onChange: (items: SelectedFilterItem[]) => void;
}

const ToggleFilter = ({
  filterKey,
  title,
  option,
  filterValues,
  style,
  onChange,
}: IToggleFilterProps) => {
  const { value: activeValue } = filterValues[filterKey]?.[0] || {};

  return (
    <Select
      key={filterKey}
      style={style}
      className={styles.toggleFilter}
      placeholder={title}
      dropdownStyle={{ minWidth: '260px', padding: 0 }}
      dropdownRender={() => (
        <div className={styles.toggleFilter_dropdown}>
          <div className={styles.toggleFilter_dropdown_item}>
            <span>{option.label}</span>
            <Switch
              checked={activeValue as boolean}
              onChange={(checked) => {
                if (!checked) return onChange([]);

                return onChange([
                  { key: option.key, label: option.label, value: true, type: FilterType.TOGGLE },
                ]);
              }}
            />
          </div>
        </div>
      )}
    />
  );
};

export default ToggleFilter;
