import {
  ADD_UPLOAD_FILES,
  FINISH_UPLOADING,
  RESET_UPLOAD_DATA,
  START_UPLOADING,
} from '@redux/types/types';

export const addUploadFiles = (uploadFiles: any) => ({
  type: ADD_UPLOAD_FILES,
  payload: uploadFiles,
});

export const startUploading = {
  type: START_UPLOADING,
};

export const finishUploading = {
  type: FINISH_UPLOADING,
};

export const resetUploadData = {
  type: RESET_UPLOAD_DATA,
};
