import React from 'react';
import { Button, Tag } from 'antd';
import { CLEAR_ALL_FILTER_LABLE } from '@constants/data-page';
import { FilterType, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import styles from './appliedFiltersList.module.scss';

interface IAppliedFiltersList {
  filterValues: SelectedFilters;
  onClearAllFilters: () => void;
  onFilterSelectChange: (filterKey: string, items: SelectedFilterItem[]) => void;
}

const AppliedFiltersList = (props: IAppliedFiltersList) => {
  const { filterValues, onClearAllFilters, onFilterSelectChange } = props;
  const allFiltersValues = Object.values(filterValues).flat();

  if (!allFiltersValues?.length) return null;

  const filterKeys = Object.keys(filterValues);

  const getLabelFromFilterItem = (item: SelectedFilterItem) => {
    const parseLabelByFilterType = {
      [FilterType.RANGE]: ({ label, value }: SelectedFilterItem<FilterType.RANGE>) =>
        `${label}: ${value[0]} - ${value[1]}`,
      [FilterType.TOGGLE]: ({ label }: SelectedFilterItem) => label,
      [FilterType.MULTI_SELECT]: ({ label }: SelectedFilterItem) => label,
      [FilterType.DATE]: ({ label }: SelectedFilterItem) => label,
      [FilterType.SELECT]: ({ label, labelPrefix }: SelectedFilterItem) =>
        `${labelPrefix}: ${label}`,
    };

    return parseLabelByFilterType[item.type](item);
  };

  return (
    <div className={styles.tableFilterValues}>
      <div className={styles.tableFilterValues_scrollWrapper}>
        {filterKeys.map((filterKey) =>
          filterValues[filterKey].map((item: SelectedFilterItem, idx: number) => (
            <Tag
              key={item.key}
              closable
              onClose={() => {
                const values = filterValues[filterKey];
                const withRemovedValue = [...values.slice(0, idx), ...values.slice(idx + 1)];
                onFilterSelectChange(filterKey, withRemovedValue);
              }}
            >
              {getLabelFromFilterItem(item)}
            </Tag>
          )),
        )}
      </div>
      <Button
        type="link"
        onClick={onClearAllFilters}
        className={styles.tableFilterValues_clearAll_button}
      >
        {CLEAR_ALL_FILTER_LABLE}
      </Button>
    </div>
  );
};

export default AppliedFiltersList;
