import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { ISecretPayload } from '@api/secrets';
import { ADD_BUTTON_LABEL, CANCEL_BUTTON_LABEL } from '@constants/common';
import {
  CREATE_SECRET_MODAL_NAME_LABEL,
  CREATE_SECRET_MODAL_SECRET_LABEL,
  CREATE_SECRET_MODAL_TITLE,
} from '@constants/secrets-management';
import styles from './createSecretModal.module.scss';

const { useForm } = Form;

interface ICreateSecretModalProps {
  open: boolean;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: (values: ISecretPayload) => void;
}

const CreateSecretModal = ({ open, loading, onCancel, onSubmit }: ICreateSecretModalProps) => {
  const [form] = useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    onSubmit(values as ISecretPayload);
  };

  const onModalVisibilityChange = (openState: boolean) => {
    if (!openState) form.resetFields();
  };

  // Renders

  const renderFooter = () => {
    return (
      <div className={styles.footerWrapper}>
        <Button disabled={loading} onClick={handleCancel}>
          {CANCEL_BUTTON_LABEL}
        </Button>
        <Button disabled={loading} loading={loading} type="primary" onClick={handleSubmit}>
          {ADD_BUTTON_LABEL}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={CREATE_SECRET_MODAL_TITLE}
      className={styles.modal}
      open={open}
      afterOpenChange={onModalVisibilityChange}
      onCancel={handleCancel}
      onOk={handleSubmit}
      footer={renderFooter()}
    >
      <div className={styles.body}>
        <Form form={form} colon={false} layout="vertical">
          <Form.Item
            label={CREATE_SECRET_MODAL_NAME_LABEL}
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={CREATE_SECRET_MODAL_SECRET_LABEL}
            name="secret"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateSecretModal;
