import { isNil } from 'lodash';
import { getODataEQFilterFrom } from '@utils/odata';
import { getCurrentWorkspaceId, getWorkspace } from '@utils/store';
import { MIMETypes } from '@constants/enum/common';
import { IAPIPaginationData, IJobPayload } from '@redux/types/types';
import { http } from './base-http';

export interface IJobsData {
  limit?: number;
  page_number?: number;
  filter?: string;
  select?: string;
  field?: string;
  order?: string;
}

export interface ISharedJobResultsData {
  jobId: string;
  expirationDate: Date;
  title: string;
  description?: string;
  showFiles?: boolean;
}

export interface IPatchSharedJobResultsData {
  title?: string;
  description?: string;
}

export const getJobAPI = (id: string, workspaceId: string = getCurrentWorkspaceId()) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/jobs/${id}`);
};

export const getJobsAPI = <T>(
  params?: IJobsData,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const mappedParams = {
    ...(params?.limit && { limit: params?.limit }),
    ...(params?.page_number && { page_number: params?.page_number }),
    ...(params?.filter && { filter: params?.filter }),
    ...(params?.select && { select: params?.select }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
  };
  return http.get<T>(`/api/v2/workspaces/${workspaceId}/jobs`, { params: mappedParams });
};

export const getJobResultsAPI = <T>(
  id: string,
  params?: IJobsData,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const mappedParams = {
    ...(params?.limit && { limit: params?.limit }),
    ...(params?.page_number && { page_number: params?.page_number }),
    ...(params?.filter && { filter: params?.filter }),
    ...(params?.select && { select: params?.select }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
  };
  return http.get<T>(`/api/v2/workspaces/${workspaceId}/jobs/${id}/results`, {
    params: mappedParams,
  });
};

export const getRecursivelyJobsApi = async <T extends unknown[]>(
  params?: IJobsData,
  workspaceId: string = getCurrentWorkspaceId(),
): Promise<T | unknown[]> => {
  const {
    data: { data, has_more: hasMore },
  } = await getJobsAPI<IAPIPaginationData<T>>(params, workspaceId);

  if (!hasMore) return data;

  const nextPage = params?.page_number ? params.page_number + 1 : 2;
  const nextParams = { ...params, page_number: nextPage };

  return [...data, ...(await getRecursivelyJobsApi(nextParams, workspaceId))];
};

export const getRecursivelyJobResultsApi = async <T extends unknown[]>(
  id: string,
  params?: IJobsData,
  workspaceId: string = getCurrentWorkspaceId(),
): Promise<T | unknown[]> => {
  const {
    data: { data, has_more: hasMore },
  } = await getJobResultsAPI<IAPIPaginationData<T>>(id, params, workspaceId);

  if (!hasMore) return data;

  const nextPage = params?.page_number ? params.page_number + 1 : 2;
  const nextParams = { ...params, page_number: nextPage };

  return [...data, ...(await getRecursivelyJobResultsApi(id, nextParams, workspaceId))];
};

export const getJobResultsCSVApi = (id: string, workspaceId: string = getCurrentWorkspaceId()) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/jobs/${id}/results/export`, {
    responseType: 'blob',
    headers: {
      Accept: MIMETypes.CSV,
    },
  });
};

export const createJobAPI = (
  payload: IJobPayload,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.post(`/api/v2/workspaces/${workspaceId}/jobs`, payload);
};

export const startJobAPI = (jobId: string, workspaceId: string = getCurrentWorkspaceId()) => {
  return http.post(`/api/v2/workspaces/${workspaceId}/jobs/${jobId}/start`);
};

export const deleteJobAPI = (jobId: string, workspaceId: string = getCurrentWorkspaceId()) => {
  return http.delete(`/api/v2/workspaces/${workspaceId}/jobs/${jobId}`);
};

export const getQuerySetAPI = (id: string, workspaceId: string = getCurrentWorkspaceId()) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/query_sets/${id}`);
};

export const getQuerySetsAPI = (
  params?: IJobsData,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const mappedParams = {
    ...(params?.limit && { limit: params?.limit }),
    ...(params?.page_number && { page_number: params?.page_number }),
  };
  return http.get(`/api/v2/workspaces/${workspaceId}/query_sets`, { params: mappedParams });
};

export const uploadQuerySetAPI = (
  payload: any,
  config: any,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.post(`/api/v2/workspaces/${workspaceId}/query_sets/import`, payload, config);
};

// Tags

export const getJobsTagsAPI = (workspaceId: string = getCurrentWorkspaceId()) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/jobs/tags`);
};

/* Creates job shared link */
export function generateSharedResultLinkApi(
  params: ISharedJobResultsData,
  workspaceName: string = getWorkspace(),
  workspaceId: string = getCurrentWorkspaceId(),
) {
  const data = {
    workspace_name: workspaceName,
    job_id: params.jobId,
    expiration_date: params.expirationDate.toISOString(),
    ...(params?.title && { title: params.title }),
    ...(params?.description && { description: params.description }),
    show_files: !!params.showFiles,
  };
  return http.post(`/api/v2/workspaces/${workspaceId}/jobs/share`, data);
}

export const getSharedJobApi = (jobId: string, workspaceId: string = getCurrentWorkspaceId()) => {
  const params = {
    filter: getODataEQFilterFrom('job_id', jobId),
  };
  return http.get(`/api/v2/workspaces/${workspaceId}/shared_jobs`, { params });
};

export const updateSharedJobApi = (
  sharedJobPrototypeId: string,
  patchPayload: IPatchSharedJobResultsData,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const payload = {
    ...(!isNil(patchPayload?.title) && { title: patchPayload.title }),
    ...(!isNil(patchPayload?.description) && { description: patchPayload.description }),
  };
  return http.patch(
    `/api/v2/workspaces/${workspaceId}/shared_jobs/${sharedJobPrototypeId}`,
    payload,
  );
};

export const revokeJobResultPrototypeLinkApi = (
  sharedJobPrototypeId: string,
  workspaceId: string = getCurrentWorkspaceId(),
) => http.post(`/api/v2/workspaces/${workspaceId}/shared_jobs/${sharedJobPrototypeId}/revoke`);
