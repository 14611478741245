import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CheckCircleOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Skeleton, Spin, Tooltip } from 'antd';
import { checkFileLimit } from '@utils/common';
import { interpolateString } from '@utils/string';
import { HTTPStatusCode } from '@constants/enum/common';
import { FILE_SIZE_LIMIT_ERROR, RETRY_UPLOAD_BUTTON } from '@constants/upload';
import { ExtendedUploadFile, FileUploadStatus } from '@redux/types/types';
import styles from './fileUploadList.module.scss';

interface IFileUploadListProps {
  filesList: ExtendedUploadFile[];
  listLength: number;
  isUploadingCompleted?: boolean;
  viewOnly?: boolean;
  onLoadMore?: () => void;
  onRetrySingleFile?: (id: string) => void;
  fileSizeLimitMB?: number;
  size?: 'small' | 'large';
}

const FileUploadList = ({
  filesList,
  listLength,
  isUploadingCompleted,
  onLoadMore = () => {},
  viewOnly,
  onRetrySingleFile,
  fileSizeLimitMB,
  size,
}: IFileUploadListProps) => {
  let listDataAux = filesList;
  if (isUploadingCompleted) {
    listDataAux = [
      ...filesList.filter(
        (file: ExtendedUploadFile) => file.uploadStatus === FileUploadStatus.error,
      ),
      ...filesList.filter(
        (file: ExtendedUploadFile) => file.uploadStatus !== FileUploadStatus.error,
      ),
    ];
  }
  const listData = listDataAux.slice(0, listLength);

  const getTooltipMessage = (file: ExtendedUploadFile) => {
    if (fileSizeLimitMB && !checkFileLimit(file, fileSizeLimitMB)) {
      return interpolateString(FILE_SIZE_LIMIT_ERROR, {
        fileSize: fileSizeLimitMB,
      });
    }
    if (file.uploadStatus === FileUploadStatus.error) {
      return file.errorMessage;
    }
    return '';
  };

  return (
    <div
      id="scrollableFileListDiv"
      className={`${styles.scrollableFileListDiv} ${
        size === 'small' ? styles.scrollableFileListDiv_small : styles.scrollableFileListDiv_large
      }`}
    >
      <InfiniteScroll
        dataLength={listData.length}
        next={onLoadMore}
        hasMore={listData.length < filesList.length}
        loader={<Skeleton paragraph={{ rows: 0 }} active />}
        scrollableTarget="scrollableFileListDiv"
        data-testid="fileUploadList"
      >
        {listData.map((file) => (
          <Tooltip key={file.uid} placement="top" title={getTooltipMessage(file)}>
            <div
              className={`${styles.fileList} ${viewOnly ? styles.expanded : ''}`}
              data-testid={`fileUploadList_item_${file.name}`}
            >
              <span
                className={`${styles.fileList_name} ${
                  file.uploadStatus === FileUploadStatus.error ||
                  (fileSizeLimitMB && !checkFileLimit(file, fileSizeLimitMB))
                    ? styles.error
                    : ''
                }`}
              >
                {file.uploadStatus === FileUploadStatus.uploading ? (
                  <Spin size="small" indicator={<LoadingOutlined />} />
                ) : (
                  <PaperClipOutlined />
                )}
                <span className={styles.fileList_name_label}>{file.name}</span>
              </span>
              {!viewOnly && (
                <span className={styles.fileList_status}>
                  {file.uploadStatus === FileUploadStatus.success && (
                    <CheckCircleOutlined
                      className={styles.success}
                      data-testid={`fileUploadList_item_${file.name}_success`}
                    />
                  )}
                  {file.uploadStatus === FileUploadStatus.error &&
                    file.errorCode !== HTTPStatusCode.CONFLICT && (
                      <>
                        {onRetrySingleFile && (
                          <Button
                            type="link"
                            size="small"
                            onClick={() => onRetrySingleFile(file.uid)}
                            className={styles.retryButton}
                            data-testid={`fileUploadList_item_${file.name}_retryButton`}
                          >
                            {RETRY_UPLOAD_BUTTON}
                          </Button>
                        )}
                        <WarningOutlined className={styles.error} />
                      </>
                    )}
                </span>
              )}
            </div>
          </Tooltip>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default FileUploadList;
