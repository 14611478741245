import { ColumnsType } from 'antd/es/table';
import { isNil } from 'lodash';

export const parseTableDataToCsvString = <T extends Record<string, unknown>>(
  columns: ColumnsType<T>,
  dataSource: T[] | null,
): string => {
  if (!dataSource?.length) return '';

  const nilReplacer = (key: string, value: unknown) => {
    if (isNil(value)) return '';
    return value;
  };

  const headers = columns.map(({ dataIndex }: any) => dataIndex);
  const csvRows = dataSource.map((row) =>
    headers.map((fieldName) => JSON.stringify(row[fieldName], nilReplacer)).join(','),
  );
  const csvString = [headers.join(','), ...csvRows].join('\r\n');

  return csvString;
};
