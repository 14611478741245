import produce from 'immer';
import {
  RESET_LAYOUT_STATE,
  RESET_ROUTE_NAME,
  UPDATE_COLLAPSED,
  UPDATE_OPEN_KEYS,
  UPDATE_ROUTE_NAME,
  UPDATE_SELECTED_KEYS,
} from '../types/types';

interface IInitialStateProps {
  sidebar: {
    collapsed: boolean;
    openKeys: string[];
    selectedKeys: string[];
  };
  currentPage: string;
  routeName: string;
}

export const initialState: IInitialStateProps = {
  sidebar: {
    collapsed: false,
    openKeys: [],
    selectedKeys: [],
  },
  currentPage: '/',
  routeName: '',
};

function layoutReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_LAYOUT_STATE:
        return initialState;
      case UPDATE_OPEN_KEYS:
        localDraft.sidebar.openKeys = action.payload;
        break;
      case UPDATE_SELECTED_KEYS:
        localDraft.sidebar.selectedKeys = action.payload;
        break;
      case UPDATE_COLLAPSED:
        localDraft.sidebar.collapsed = action.payload;
        break;
      case RESET_ROUTE_NAME:
        localDraft.routeName = initialState.routeName;
        break;
      case UPDATE_ROUTE_NAME:
        localDraft.routeName = action.payload;
        break;

      default:
        break;
    }

    return localDraft;
  });
}

export default layoutReducer;
