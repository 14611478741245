import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Alert, Button, Tag, Tooltip } from 'antd';
import { formatNumberToLocaleString } from '@utils/math';
import { sortCollectionAlphabeticallyBy } from '@utils/sort';
import { interpolateString } from '@utils/string';
import { getUserPreference } from '@utils/userPreferences';
import SynchronizeSVG from '@assets/synchronize.svg?react';
import { COMING_SOON_LABEL } from '@constants/common';
import { DOCS_URL } from '@constants/constant';
import {
  DELETE_SELECTED_EXPERIMENTS_PLURAL_LABEL,
  DELETE_SELECTED_EXPERIMENTS_SINGULAR_LABEL,
  DELETE_SELECTED_MESSAGE,
} from '@constants/data-page';
import { FilterProp, FiltersProp, FilterType, SelectedFilters } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import {
  DELETE_BUTTON_LABEL,
  DETAILS_BUTTON_LABEL,
  EDIT_BUTTON_LABEL,
  EMPTY_EXPERIMENTS_TABLE_LABEL,
  EMPTY_STATE_ALL_STEPS_COMPLETED_DESCRIPTION,
  EMPTY_STATE_CREATE_PIPELINE_LINK,
  EMPTY_STATE_DEFAULT_DESCRIPTION,
  EMPTY_STATE_DOCUMENTATION_LINK,
  EMPTY_STATE_HEADER,
  EMPTY_STATE_PREPARE_EVAL_SET_LINK,
  EMPTY_STATE_TOOLTIP_MESSAGE,
  EMPTY_STATE_UPLOAD_FILES_LINK,
  EXPERIMENT_CREATE_BUTTON_LABEL,
  EXPERIMENT_FILTER_STATUS_LABEL,
  EXPERIMENT_FILTER_TAGS_LABEL,
  EXPERIMENT_FILTER_USERS_LABEL,
  EXPERIMENT_RUN_START_LABEL,
  EXPERIMENT_RUN_TRY_AGAIN_LABEL,
  EXPERIMENTS_DESCRIPTION,
  EXPERIMENTS_FILTERS_FIELD_KEYS,
  EXPERIMENTS_LABEL,
  EXPERIMENTS_SORTING_DATATABLE_OPTIONS,
  MetricNames,
  MetricShortNames,
  RUN_DELETE_POPCONFIRM_CONFIRM,
  RUN_DELETE_POPCONFIRM_DECLINE,
  RUN_DELETE_POPCONFIRM_MESSAGE,
  START_BUTTON_LABEL,
} from '@constants/experiments';
import { getWorkspaceEvalsets } from '@redux/actions/evalsetActions';
import {
  createEvalRun,
  deleteEvalRun,
  deleteMultipleEvalRuns,
  getEvalRuns,
  getEvalRunsFilterStatuses,
  getEvalRunsFilterTags,
  getEvalRunsFilterUsers,
  getTags,
  resetExperimentsMessage,
  saveAndStartEvalRun,
  startEvalRun,
  startMultipleEvalRuns,
  startPollingRunsStatus,
  stopPollingRunsStatus,
  updateEvalRun,
} from '@redux/actions/experimentActions';
import { getWorkspaceFiles } from '@redux/actions/fileActions';
import { fetchPipelines } from '@redux/actions/pipelineActions';
import { evalsetSelector } from '@redux/selectors/evalsetSelectors';
import {
  experimentActionStatusSelector,
  experimentMessageSelector,
  experimentsSelector,
  experimentStatusSelector,
  filtersValuesSelector,
  newExperimentMessageSelector,
  newExperimentStatusSelector,
  sortValueSelector,
  tagsSelector,
} from '@redux/selectors/experimentSelectors';
import { filesSelector } from '@redux/selectors/fileSelectors';
import { pipelinesSelector } from '@redux/selectors/pipelineSelectors';
import {
  DeepsetCloudVersion,
  IAPIPaginationData,
  IEvalset,
  IExperiment,
  IMessage,
  IPipeline,
  ITag,
  RunState,
} from '@redux/types/types';
import ExperimentsEmptyStateSVG from '@components/common/Images/ExperimentsEmptyStateSVG';
import UserAvatar from '@components/common/userAvatar/userAvatar';
import DataTable from '@components/dataTable/DataTable';
import DataTableActions from '@components/dataTable/DataTableActions';
import EditEvalRun from '@components/evalRuns/editEvalRun/EditEvalRun';
import NewEvalRun from '@components/evalRuns/newEvalRun/NewEvalRun';
import RunStatusTag from '@components/runStatusTag/RunStatusTag';
import styles from './experimentsPage.module.scss';

enum MenuActions {
  Details = 'DETAILS',
  Delete = 'DELETE',
  Download = 'DOWNLOAD',
  Edit = 'EDIT',
}

const ExperimentsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const EXPERIMENTS_DOCS_URL = `${DOCS_URL}docs/test-the-experiments`;
  const message: IMessage = useSelector(experimentMessageSelector);
  const newExperimentMessage: IMessage = useSelector(newExperimentMessageSelector);
  const status: StatusCodes = useSelector(experimentStatusSelector);
  const newEvalRunStatus: StatusCodes = useSelector(newExperimentStatusSelector);
  const actionStatus: StatusCodes = useSelector(experimentActionStatusSelector);
  const pipelines: IAPIPaginationData<IPipeline[]> = useSelector((state) =>
    pipelinesSelector(state, ''),
  );
  const files = useSelector(filesSelector);
  const evalsets: IAPIPaginationData<IEvalset[]> = useSelector(evalsetSelector);
  const { data, total }: { data: IExperiment[]; total: number } = useSelector(experimentsSelector);
  const workspaceTags: ITag[] = useSelector(tagsSelector);
  const dataTableParams = useRef<{
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
  } | null>(null);
  const [currentEditingExperiment, setCurrentEditingExperiment] = useState<IExperiment | null>(
    null,
  );
  const filtersValues: any = useSelector(filtersValuesSelector);
  const selectedSortValue: string = useSelector(sortValueSelector);
  const [newRunVisible, setNewRunVisible] = useState(false);
  const [isEditRunVisible, setisEditRunVisibleTo] = useState(false);
  const [runToDelete, setRunToDelete] = useState('');
  const [totalSelectedItems, setTotalSelectedItems] = useState(0);
  const loading = status === StatusCodes.IN_PROGRESS;

  const getRuns = (
    currentPage: number,
    pageSize: number,
    searchValue: string,
    sortValue?: string,
    filterValues?: SelectedFilters,
  ) => {
    dispatch(getEvalRuns({ currentPage, pageSize, searchValue, sortValue, filterValues }));
    dataTableParams.current = { currentPage, pageSize, searchValue, sortValue, filterValues };
  };

  const startPolling = (
    currentPage: number,
    pageSize: number,
    searchValue: string,
    sortValue?: string,
    filterValues?: SelectedFilters,
  ) => {
    dispatch(startPollingRunsStatus(currentPage, pageSize, searchValue, sortValue, filterValues));
  };

  const stopPolling = () => {
    dispatch(stopPollingRunsStatus());
  };

  const getPipelines = (currentPage: number, pageSize: number, searchValue: string) => {
    dispatch(
      fetchPipelines({
        currentPage,
        pageSize,
        searchValue,
      }),
    );
  };

  const getEvalsets = (currentPage: number, pageSize: number, searchValue: string) => {
    dispatch(getWorkspaceEvalsets({ currentPage, pageSize, searchValue }));
  };

  useEffect(() => {
    dispatch(resetExperimentsMessage);
    getPipelines(1, 10, '');
    getEvalsets(1, 10, '');
    dispatch(getWorkspaceFiles({ currentPage: 1, pageSize: 1, searchValue: '' }));
    dispatch(getTags());
    dispatch(getEvalRunsFilterTags());
    dispatch(getEvalRunsFilterStatuses());
    dispatch(getEvalRunsFilterUsers());
    getRuns(1, 10, '');
  }, []);

  const getFilters = (): FiltersProp | [] => {
    const { tags, statuses, users } = filtersValues;

    const tagsFilter = {
      type: FilterType.MULTI_SELECT,
      key: EXPERIMENTS_FILTERS_FIELD_KEYS.TAGS_ID,
      title: EXPERIMENT_FILTER_TAGS_LABEL,
      options: tags,
    } as FilterProp<FilterType.MULTI_SELECT>;
    const statusFilter = {
      type: FilterType.MULTI_SELECT,
      key: EXPERIMENTS_FILTERS_FIELD_KEYS.STATUS,
      title: EXPERIMENT_FILTER_STATUS_LABEL,
      style: { minWidth: '87px' },
      options: statuses,
    } as FilterProp<FilterType.MULTI_SELECT>;
    const createdByFilter = {
      type: FilterType.MULTI_SELECT,
      key: EXPERIMENTS_FILTERS_FIELD_KEYS.CREATED_BY_USER_ID,
      title: EXPERIMENT_FILTER_USERS_LABEL,
      style: { minWidth: '120px' },
      options: users,
    } as FilterProp<FilterType.MULTI_SELECT>;

    return [
      ...(tags?.length ? [tagsFilter] : []),
      ...(statuses?.length ? [statusFilter] : []),
      ...(users?.length ? [createdByFilter] : []),
    ];
  };

  const thereAreActiveFilters = () =>
    !!Object.values(dataTableParams.current?.filterValues || {}).flat().length ||
    !!dataTableParams.current?.searchValue;

  const thereArePipelinesV1 = () => {
    return pipelines?.data?.find(
      (pipeline) => pipeline.deepset_cloud_version === DeepsetCloudVersion.V1,
    );
  };

  const isStartMultipleRunsButtonVisible = (selectedEvalRuns: string[]) =>
    !selectedEvalRuns.find((evalRunName: string) => {
      const selectedEvalRun = data.find((evalRun) => evalRun.name === evalRunName);

      return (
        selectedEvalRun?.status !== RunState.CREATED && selectedEvalRun?.status !== RunState.FAILED
      );
    });

  const onCreateNewButtonClick = () => {
    dispatch(resetExperimentsMessage);
    setNewRunVisible(true);
  };

  const onCreateNewRun = async (
    name: string,
    pipelineName: string,
    evalsetName: string,
    tags: string[],
  ) => {
    dispatch(resetExperimentsMessage);
    await dispatch(createEvalRun({ name, pipelineName, evalsetName, tags }));
    if (total === 0) getRuns(1, 10, '');
  };

  const onSaveAndStartRun = async (
    name: string,
    pipelineName: string,
    evalsetName: string,
    tags: string[],
  ) => {
    dispatch(resetExperimentsMessage);
    await dispatch(saveAndStartEvalRun({ name, pipelineName, evalsetName, tags }));
    if (total === 0) getRuns(1, 10, '');
  };

  const onUpdateRun = async (
    name: string,
    tags: string[],
    pipelineName?: string,
    evalSetName?: string,
  ) => {
    dispatch(resetExperimentsMessage);
    await dispatch(updateEvalRun({ name, tags, pipelineName, evalSetName }));

    const { currentPage, pageSize, searchValue, filterValues } = dataTableParams.current || {
      currentPage: 1,
      pageSize: 10,
      searchValue: '',
    };
    getRuns(currentPage, pageSize, searchValue, '', filterValues);
    dispatch(getEvalRunsFilterTags());
  };

  const onStartRun = async (evalRun: string) => {
    dispatch(resetExperimentsMessage);
    await dispatch(startEvalRun(evalRun));
  };

  const onEditExperimentButtonPressed = (eval_run_id: string) => {
    const editingExperiment =
      data.find((experiment: IExperiment) => experiment.eval_run_id === eval_run_id) || null;
    setCurrentEditingExperiment(editingExperiment);
    setisEditRunVisibleTo(true);
  };

  const handleMoreActionClick = ({
    key,
    name,
    eval_run_id,
  }: {
    key: string;
    name: string;
    eval_run_id: string;
  }) => {
    if (key === MenuActions.Details) navigate(name);
    if (key === MenuActions.Delete) setRunToDelete(name);
    if (key === MenuActions.Edit) onEditExperimentButtonPressed(eval_run_id);
  };

  const handleDeleteRun = async (name: string) => {
    setRunToDelete('');
    dispatch(deleteEvalRun(name));
  };

  const handleDeleteMultipleEvalRuns = (evalRunsNames: string[]) => {
    dispatch(resetExperimentsMessage);
    dispatch(deleteMultipleEvalRuns(evalRunsNames));
  };

  const handleStartMultipleEvalRuns = (evalRunsNames: string[]) => {
    dispatch(resetExperimentsMessage);
    dispatch(startMultipleEvalRuns(evalRunsNames));
  };

  const getEmpetyStateContentProps = () => {
    const thereAreCreatedPipelines = !!pipelines?.data?.length;
    const thereAreEvalSetsPrepared = !!evalsets?.data?.length;
    const thereAreUploadedFiles = !!files?.data?.length;

    const allStepsCompleted =
      thereAreCreatedPipelines && thereAreEvalSetsPrepared && thereAreUploadedFiles;

    if (allStepsCompleted)
      return {
        title: <h2>{EMPTY_STATE_HEADER}</h2>,
        subtitle: (
          <p className={styles.emptyState_subtitle}>
            {interpolateString(EMPTY_STATE_ALL_STEPS_COMPLETED_DESCRIPTION, {
              documentationLink: (
                <a href={EXPERIMENTS_DOCS_URL} target="_blank" rel="noreferrer">
                  {EMPTY_STATE_DOCUMENTATION_LINK}
                </a>
              ),
            })}
          </p>
        ),
        button: (
          <Button
            type="primary"
            onClick={onCreateNewButtonClick}
            data-testid="experiments_emptyState_newExperiment_button"
          >
            <PlusOutlined /> {EXPERIMENT_CREATE_BUTTON_LABEL}
          </Button>
        ),
      };

    return {
      title: <h2 className={styles.emptyState_title}>{EMPTY_STATE_HEADER}</h2>,
      subtitle: (
        <p className={styles.emptyState_subtitle}>
          {interpolateString(EMPTY_STATE_DEFAULT_DESCRIPTION, {
            uploadFilesLink: (
              <Button type="link" onClick={() => navigate('/files')}>
                {EMPTY_STATE_UPLOAD_FILES_LINK}
              </Button>
            ),
            creatPipelineLink: (
              <Button type="link" onClick={() => navigate('/pipelines/templates')}>
                {EMPTY_STATE_CREATE_PIPELINE_LINK}
              </Button>
            ),
            prepareEvalSetLink: (
              <Button type="link" onClick={() => navigate('/evalset')}>
                {EMPTY_STATE_PREPARE_EVAL_SET_LINK}
              </Button>
            ),
            documentationLink: (
              <a href={EXPERIMENTS_DOCS_URL} target="_blank" rel="noreferrer">
                {EMPTY_STATE_DOCUMENTATION_LINK}
              </a>
            ),
          })}
        </p>
      ),
      button: (
        <Tooltip title={EMPTY_STATE_TOOLTIP_MESSAGE}>
          <Button
            type="primary"
            disabled
            data-testid="experiments_emptyState_newExperiment_button_disabled"
          >
            <PlusOutlined /> {EXPERIMENT_CREATE_BUTTON_LABEL}
          </Button>
        </Tooltip>
      ),
    };
  };

  const renderComingSoonState = () => {
    return (
      <div className={styles.emptyState} data-testid="experiments_commingSoonState">
        <SynchronizeSVG />
        <div className={styles.emptyState_textWrapper}>
          <Tag bordered={false} color="warning">
            {COMING_SOON_LABEL}
          </Tag>
          <h2>{EXPERIMENTS_LABEL}</h2>
          <p className={styles.emptyState_subtitle}>{EXPERIMENTS_DESCRIPTION}</p>
        </div>
      </div>
    );
  };

  const emptyState = () => {
    const thereAreCreatedPipelines = !!pipelines?.data?.length;
    const thereAreEvalSetsPrepared = !!evalsets?.data?.length;
    const thereAreUploadedFiles = !!files?.data?.length;

    if (!thereAreCreatedPipelines || !thereArePipelinesV1()) return renderComingSoonState();

    const { title, subtitle, button } = getEmpetyStateContentProps();

    return (
      <div className={styles.emptyState}>
        <ExperimentsEmptyStateSVG
          isCreatePipelineCompleted={thereAreCreatedPipelines}
          isEvalSetCompleted={thereAreEvalSetsPrepared}
          isUploadFilesCompleted={thereAreUploadedFiles}
          onUploadFileSectionClick={() => navigate('/files')}
          onPrepareEvalSetSectionClick={() => navigate('/evalset')}
          onCreatePipelineSectionClick={() => navigate('/pipelines/templates')}
        />
        <div className={styles.emptyState_textWrapper}>
          {title}
          {subtitle}
        </div>
        {button}
      </div>
    );
  };

  const defaultColumns = getUserPreference('experimentColumnSelection') || [
    'tags',
    'created_at',
    'created_by',
    'integrated_recall_multi_hit',
    'integrated_f1',
    'integrated_groundedness',
  ];

  const optionalColumns = [
    { key: 'tags', title: 'Tags' },
    { key: 'created_at', title: 'Created At' },
    { key: 'created_by', title: 'User' },
  ];
  const metricsColumns =
    data.length > 0
      ? Object.keys(MetricShortNames).map((key: any) => {
          optionalColumns.push({ key, title: MetricShortNames[key] });

          return {
            title: () => (
              <span>
                {MetricShortNames[key]}{' '}
                <Tooltip title={MetricNames[key]}>
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            ),
            key,
            width: 120,
            render: (_: any, record: any) => {
              const evalResults = record.eval_results ? [...record.eval_results] : [];
              let metricValue;
              // Iterate over metrics till found one
              while (!metricValue && evalResults.length > 0) {
                const result = evalResults.pop();
                if (result[key] !== undefined && result[key] !== null) {
                  metricValue = result[key];
                }
              }

              return metricValue === null || metricValue === undefined
                ? ''
                : formatNumberToLocaleString(metricValue);
            },
          };
        })
      : [];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 400,
      render: (name: string) => (
        <Link to={name} className={styles.nameLink}>
          {name}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (runStatus: RunState) => <RunStatusTag status={runStatus} />,
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      width: 120,
      render: (tags: ITag[]) => (
        <>
          {tags.length > 0 &&
            sortCollectionAlphabeticallyBy(tags, 'name').map((tag: ITag) => {
              const { tag_id: tagID, name } = tag;
              return (
                <Tag key={tagID} style={{ marginRight: 2 }}>
                  {name}
                </Tag>
              );
            })}
        </>
      ),
    },
    ...metricsColumns,
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: (created_at: string) => new Date(created_at).toLocaleString(),
    },
    {
      title: 'User',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 80,
      render: (created_by: any) => <UserAvatar user={created_by} />,
    },
    {
      key: 'action',
      width: '8%',
      align: 'right' as const,
      fixed: 'right' as const,
      render: (_: any, record: IExperiment) => {
        const items: any[] = [
          {
            label: EDIT_BUTTON_LABEL,
            key: MenuActions.Edit,
          },
          {
            label: DETAILS_BUTTON_LABEL,
            key: MenuActions.Details,
          },
          {
            label: DELETE_BUTTON_LABEL,
            key: MenuActions.Delete,
            danger: true,
            icon: <DeleteOutlined />,
          },
        ];
        const isEvalRunCreated = record.status === RunState.CREATED;

        return (
          <DataTableActions
            menu={{
              items,
              onClick: ({ key }) =>
                handleMoreActionClick({
                  key,
                  name: record.name,
                  eval_run_id: record.eval_run_id,
                }),
              'data-testid': 'experiments_tableRow_moreActions_button',
            }}
            item={record.name}
            itemToDelete={runToDelete}
            onDelete={handleDeleteRun}
            onCancelDelete={() => setRunToDelete('')}
            deleteConfirmationMessage={RUN_DELETE_POPCONFIRM_MESSAGE}
            confirmButtonLabel={RUN_DELETE_POPCONFIRM_CONFIRM}
            cancelButtonLabel={RUN_DELETE_POPCONFIRM_DECLINE}
            primaryButton={{
              label: isEvalRunCreated ? EXPERIMENT_RUN_START_LABEL : EXPERIMENT_RUN_TRY_AGAIN_LABEL,
              action: onStartRun,
              visible: record.status !== RunState.ENDED && record.status !== RunState.STARTED,
              icon: !isEvalRunCreated ? <ReloadOutlined /> : null,
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      {message && message.content && <Alert message={message.content} type={message.type} banner />}
      <div className="content-wrapper" style={{ height: '100%' }}>
        {!total && !thereAreActiveFilters() ? (
          emptyState()
        ) : (
          <DataTable
            testId="experiments_table"
            data={data}
            total={total}
            loading={loading}
            refetch={
              newEvalRunStatus === StatusCodes.SUCCESS || actionStatus === StatusCodes.SUCCESS
            }
            columns={columns}
            getData={getRuns}
            locale={{ emptyText: EMPTY_EXPERIMENTS_TABLE_LABEL }}
            sorting={{
              selectedValue: selectedSortValue,
              options: EXPERIMENTS_SORTING_DATATABLE_OPTIONS,
            }}
            filters={getFilters()}
            rowKey="name"
            primaryAction={{
              label: EXPERIMENT_CREATE_BUTTON_LABEL,
              onClick: onCreateNewButtonClick,
            }}
            scroll={{ x: 'max-content' }}
            columnsConfig={{
              optionalColumns,
              defaultColumns,
              cacheLocation: 'experimentColumnSelection',
            }}
            polling={{
              enabled: true,
              startPolling,
              stopPolling,
            }}
            selectActions={[
              {
                type: 'primary',
                label: START_BUTTON_LABEL,
                onClick: handleStartMultipleEvalRuns,
                isVisible: isStartMultipleRunsButtonVisible,
              },
              {
                type: 'default',
                danger: true,
                label: (totalSelectedItems === 1
                  ? interpolateString(DELETE_SELECTED_EXPERIMENTS_SINGULAR_LABEL, {
                      total: totalSelectedItems,
                    })
                  : interpolateString(DELETE_SELECTED_EXPERIMENTS_PLURAL_LABEL, {
                      total: totalSelectedItems,
                    })) as string,
                onClick: handleDeleteMultipleEvalRuns,
                popconfirm: {
                  title: DELETE_SELECTED_MESSAGE,
                },
              },
            ]}
            setTotalSelectedItems={(totalFilesSelected) =>
              setTotalSelectedItems(totalFilesSelected)
            }
          />
        )}
      </div>
      {newRunVisible && (
        <NewEvalRun
          data-testid="experiments_newEvalRun_drawer"
          pipelines={pipelines}
          evalSets={evalsets}
          onClose={() => setNewRunVisible(false)}
          visible={newRunVisible}
          getEvalsets={getEvalsets}
          getPipelines={getPipelines}
          onCreateNewRun={onCreateNewRun}
          onSaveAndStartRun={onSaveAndStartRun}
          workspaceTags={workspaceTags}
          newEvalRunStatus={newEvalRunStatus}
          message={newExperimentMessage}
        />
      )}
      {isEditRunVisible && (
        <EditEvalRun
          experiment={currentEditingExperiment!}
          evalSets={evalsets}
          pipelines={pipelines}
          isVisible={isEditRunVisible}
          workspaceTags={workspaceTags}
          getEvalsets={getEvalsets}
          getPipelines={getPipelines}
          onClose={() => setisEditRunVisibleTo(false)}
          onSave={onUpdateRun}
        />
      )}
    </>
  );
};

export default ExperimentsPage;
