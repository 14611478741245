import React from 'react';
import { CodeOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { STUDIO_LABEL } from '@constants/pipelines';

export const CREATED_BY_LABEL = 'Created by';
export const DELETE_BUTTON_LABEL = 'Delete';
export const DUPLICATE_BUTTON_LABEL = 'Duplicate';
export const EDIT_MENU_LABEL = 'Edit';
export const UNDEPLOY_BUTTON_LABEL = 'Undeploy';
export const SHARE_BUTTON_LABEL = 'Share';
export const DEPLOY_BUTTON_LABEL = 'Deploy';
export const TRY_AGAIN_BUTTON_LABEL = 'Try again';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const LOGS_LINK = 'logs';
export const STATISTIC_HEADERS = {
  total_queries: 'Total Queries',
  manual_feedback_input: 'Feedback Items',
  experiments_created: 'Experiments Created',
  users_provided_feedback: 'Feedback Contributors',
  avg_feedback_per_query: 'Avg. Feedback Per Query',
  indexed_files: 'Indexed Files',
  total_documents: 'Total Documents',
};
export const INDEXING_HEADERS = {
  pending_tasks: 'Pending tasks',
  failed_files: 'Failed files',
  skipped_files: 'Skipped files',
  indexed_files: 'Indexed files',
  total_documents: 'Total documents',
};
export const INDEXING_HEADERS_POPOVER_CONTENT = {
  pending_tasks: `Shows the count of ongoing tasks, like adding files to the document store or updating metadata.`,
  failed_files: `The count of files that encountered errors during indexing. For details, see %logs%.`,
  skipped_files: `The number of files the pipeline ignored and didn't index. For details, see %logs%.`,
  indexed_files: `The number of files successfully indexed into the document store.`,
  total_documents: `The count of documents created from the files and indexed into the document store.`,
};
export const NUMBER_OF_LATEST_QUERIES_PER_PAGE = 5;
export const OBSERVABILITY_DASHBOARD_TOOLTIP = 'Groundedness Observability';

export enum EditPipelineOptionsKeysType {
  YAML = 'yaml',
  STUDIO = 'studio',
}

export const EDIT_PIPELINE_OPTIONS = [
  {
    label: (
      <>
        <CodeOutlined /> YAML
      </>
    ),
    key: EditPipelineOptionsKeysType.YAML,
  },
  {
    label: (
      <>
        <NodeIndexOutlined /> {STUDIO_LABEL}{' '}
        <Tag color="warning" bordered={false}>
          Beta
        </Tag>
      </>
    ),
    key: EditPipelineOptionsKeysType.STUDIO,
  },
];
